import React, { Fragment, memo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';

import { notificationAPI } from '@/api/notificationAPI';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';

import PrintModal from './PrintModal/PrintModal';

function PrintMenu() {
  const { selectedPersons } = useStore('personsStore');
  const { pdfFormTypes, getPDFFormTypes } = useStore('dictionariesStore');

  const [isPrintModalOpen, , , togglePrintModal] = useToggle();

  async function fetchPdfFormTypes() {
    try {
      await getPDFFormTypes();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (pdfFormTypes.length === 0) {
      fetchPdfFormTypes();
    }
  }, []);

  return (
    <Fragment>
      <Button
        color="primary"
        disabled={selectedPersons.size === 0}
        onClick={togglePrintModal}
        size="small"
        variant="outlined"
      >
        Печать
      </Button>

      <PrintModal onClose={togglePrintModal} open={isPrintModalOpen} />
    </Fragment>
  );
}

export default memo(observer(PrintMenu));
