import styled from '@mui/material/styles/styled';

export const ProtectedTextRoot = styled('div')`
  position: relative;
`;

export const ProtectedTextOverlay = styled('div')`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-size: contain;
  cursor: pointer;
`;
