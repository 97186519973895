import React, { ChangeEvent, memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { Address, Street } from '@/entities/address';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField } from '@/ui-kit/components';

import { DEFAULT_SEARCH_TIMEOUT } from '../AddressFormOld.constant';

interface StreetControlProps {
  region: Nullable<Address>;
  settlement: Nullable<Address>;
  city: Nullable<Address>;
  street: Nullable<Street>;
  error?: string;
  onChange: (name: string, street: Nullable<Street>) => void;
  disabled?: boolean;
  readonly?: boolean;
  variant?: TextFieldProps['variant'];
  margin?: TextFieldProps['margin'];
}

let requestTimeout: NodeJS.Timeout | undefined;

function StreetControl(props: StreetControlProps) {
  const {
    region,
    settlement,
    city,
    street,
    error,
    onChange,
    disabled = false,
    readonly = false,
    variant,
    margin,
  } = props;
  const { streets, getStreets } = useStore('dictionariesStore');

  const onChangeRequest = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      if (currentTarget.value.length <= 2 || !region) {
        return;
      }

      let parentId: number;

      if (settlement) {
        parentId = settlement.id;
      } else if (city) {
        parentId = city.id;
      }

      requestTimeout && clearTimeout(requestTimeout);
      requestTimeout = setTimeout(() => {
        getStreets(currentTarget.value, region.region_code, parentId);
      }, DEFAULT_SEARCH_TIMEOUT);
    },
    [region?.region_code, settlement?.id, city?.id],
  );

  const onStreetChangeHandler = useCallback(
    (street: unknown) => {
      onChange('street', street as Street);
    },
    [onChange],
  );

  const renderOption = useCallback(
    ({ short_name, name, full_name }: Street) => full_name || `${short_name}. ${name}`,
    [],
  );

  const renderFilterOption = useCallback(
    (options: Street[], { inputValue }: FilterOptionsState<Street>) =>
      options.filter(({ name }: Street) => name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
    [],
  );

  return (
    <AutocompleteField
      disabled={disabled || readonly}
      filterOptions={renderFilterOption}
      getOptionLabel={renderOption}
      helperText={error}
      isError={!!error}
      label="Улица"
      margin={margin}
      name="street"
      onChange={onStreetChangeHandler}
      onInputChange={onChangeRequest}
      options={[...streets]}
      value={street}
      variant={variant}
    />
  );
}

export default memo(observer(StreetControl));
