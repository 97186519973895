import React, { ChangeEvent, memo, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { fetchApi } from '@/api/fetchApi';
import { notificationAPI } from '@/api/notificationAPI';
import mobileMockup from '@/assets/mobile-mockup.png';
import { useToggle } from '@/hooks/useToggle/useToggle';
import SRMInfoModal from '@/shared/SRMInfoModal/SRMInfoModal';
import SRMStatus from '@/shared/SRMStatus/SRMStatus';
import { NotificationDecision, NotificationDelivery, NotificationType, PersonDetail } from '@/stores/PersonsStore';
import { store } from '@/stores/Store';
import { useStore } from '@/stores/StoreProvider';
import { ContentModal, ContentModalProps, InputField } from '@/ui-kit/components';

import {
  SendNotificationAdditional,
  SendNotificationBody,
  SendNotificationContent,
  SendNotificationFields,
  SendNotificationMockup,
  SendNotificationMockupContent,
  SendNotificationMockupContentInner,
  SendNotificationMockupImage,
  SendNotificationPreview,
  SendNotificationPreviewTitle,
  SendNotificationRoot,
  SendNotificationSettings,
  SendNotificationTemplate,
  SendNotificationTemplateContent,
  SendNotificationTemplateOverlay,
} from './SendNotificationModal.style';
import { NotificationInfo, NotificationProperty } from './SendNotificationModal.types';

interface SendNotificationModalProps extends ContentModalProps {
  persons: PersonDetail[];
  onSuccess?: (type: NotificationType, method: NotificationDelivery, decision: NotificationDecision) => void;
}

function SendNotificationModal(props: SendNotificationModalProps) {
  const { open, onClose, persons, onSuccess } = props;
  const { userData, userCompany } = useStore('signInStore');

  const isSinglePerson = persons.length === 1;

  const [informProps, setInformProps] = useState<Nullable<NotificationProperty>>(null);
  const [confirmProps, setConfirmProps] = useState<Nullable<NotificationProperty>>(null);
  const [confirm2Props, setConfirm2Props] = useState<Nullable<NotificationProperty>>(null);

  const [notificationMethod] = useState<NotificationDelivery>(NotificationDelivery.Email);
  const [notificationType, setNotificationType] = useState<NotificationType>(NotificationType.Inform);
  const [notificationDecision, setNotificationDecision] = useState<NotificationDecision>(NotificationDecision.Success);

  const [isLoading, enableLoading, disableLoading] = useToggle();

  const [isSRMInfoModalOpen, , , toggleSRMInfoModalOpen] = useToggle();

  const properties = useMemo(() => {
    if (notificationType === NotificationType.Inform) {
      return informProps;
    }

    if (notificationDecision === NotificationDecision.Success) {
      return confirmProps;
    }

    return confirm2Props;
  }, [informProps, confirmProps, confirm2Props, notificationType, notificationDecision]);

  const isFormValid = !!properties?.header_text && !!properties?.body_text && !!properties?.footer_text;

  /* eslint-disable */
  function interpolatePreview() {
    if (properties) {
      let result = properties?.html ?? '';

      result = result
        .replaceAll('{message_header}', properties.header_text)
        .replaceAll('{message_body}', properties.body_text)
        .replaceAll('{message_footer}', properties.footer_text);

      if (persons.length === 1) {
        result = result.replaceAll('${fname}', persons[0].fname).replaceAll('${lname}', persons[0].lname);
      }

      if (userCompany?.company?.name) {
        result = result.replaceAll('${companyName}', userCompany?.company?.name);
      }

      return result;
    }

    return '';
  }
  /* eslint-enable */

  function onCloseHandler(_?: SyntheticEvent, reason?: string) {
    if (reason === 'backdropClick') {
      return;
    }

    onClose?.();
  }

  async function onSend() {
    enableLoading();

    try {
      if (properties) {
        let uType = notificationType;

        if (notificationType === NotificationType.Confirm && notificationDecision === NotificationDecision.Decline) {
          uType = NotificationType.Confirm2;
        }

        await fetchApi.post(`/api/srm/${userData?.company_id}/`, {
          body: {
            message_header: properties.header_text,
            message_body: properties.body_text,
            message_footer: properties.footer_text,
            u_method: notificationMethod,
            u_type: uType,
            person: persons.map((person) => `${person.id}`),
          },
          base: import.meta.env.EMIS_SRM_DOMAIN,
        });

        notificationAPI.success('Сообщение успешно отправлено');

        onClose();

        onSuccess?.(notificationType, notificationMethod, notificationDecision);
      }
    } catch (e) {
      notificationAPI.error('Возникла ошибка при отправке уведомления');
    }

    disableLoading();
  }

  function onChangeNotificationType({ currentTarget }: ChangeEvent<HTMLInputElement>) {
    setNotificationType(currentTarget.value as unknown as NotificationType);

    if (currentTarget.value === NotificationType.Confirm) {
      setNotificationDecision(NotificationDecision.Success);
    }
  }

  function onChangeInput({ target }: ChangeEvent<HTMLInputElement>) {
    if (notificationType === NotificationType.Inform && informProps) {
      setInformProps({
        ...informProps,
        [target.name]: target.value,
      });
    }

    if (notificationType === NotificationType.Confirm) {
      if (notificationDecision === NotificationDecision.Success && confirmProps) {
        setConfirmProps({
          ...confirmProps,
          [target.name]: target.value,
        });
      }

      if (notificationDecision === NotificationDecision.Decline && confirm2Props) {
        setConfirm2Props({
          ...confirm2Props,
          [target.name]: target.value,
        });
      }
    }
  }

  async function getNotificationData() {
    enableLoading();

    try {
      let inform = null;
      let confirm = null;
      let confirm2 = null;

      const notificationInfo = await fetchApi.get<NotificationInfo>(
        `/api/srm/${store.signInStore.userData?.company_id}/`,
        {},
        {
          base: import.meta.env.EMIS_SRM_DOMAIN,
        },
      );

      notificationInfo.company.email_props.forEach((property: NotificationProperty) => {
        switch (property.name) {
          case 'inform':
            inform = property;

            break;
          case 'confirm':
            confirm = property;

            break;
          case 'confirm2':
            confirm2 = property;

            break;
          default:
            break;
        }
      });

      setInformProps(inform);
      setConfirmProps(confirm);
      setConfirm2Props(confirm2);
    } catch (e) {
      notificationAPI.error('Недоступно для вашей компании');

      onClose();
    }

    disableLoading();
  }

  useEffect(() => {
    if (open) {
      getNotificationData();
    }
  }, [open]);

  const headerText = useMemo(() => {
    if (persons.length === 1) {
      /* eslint-disable */
      return properties?.header_text?.replaceAll('${fname}', persons[0].fname).replaceAll('${lname}', persons[0].lname);
      /* eslint-enable */
    }

    return properties?.header_text;
  }, [properties?.header_text, persons.length]);

  const bodyText = useMemo(() => {
    if (userCompany?.company?.name) {
      /* eslint-disable */
      return properties?.body_text?.replaceAll('${companyName}', userCompany?.company?.name);
      /* eslint-enable */
    }

    return properties?.body_text;
  }, [properties?.body_text, userCompany?.company?.name]);

  const footerText = useMemo(() => {
    if (userCompany?.company?.name) {
      /* eslint-disable */
      return properties?.footer_text?.replaceAll('${companyName}', userCompany?.company?.name);
      /* eslint-enable */
    }

    return properties?.footer_text;
  }, [properties?.footer_text, userCompany?.company?.name]);

  return (
    <SendNotificationRoot
      disableEscapeKeyDown={false}
      isLoading={isLoading}
      maxWidth="xl"
      onClose={onCloseHandler}
      open={open}
    >
      <ContentModal.Header onClose={onClose}>Отправить уведомление</ContentModal.Header>

      <SendNotificationBody>
        <SendNotificationContent>
          <SendNotificationPreview>
            <SendNotificationPreviewTitle gutterBottom variant="subtitle2">
              Предпросмотр
            </SendNotificationPreviewTitle>

            <SendNotificationTemplate>
              <SendNotificationMockup>
                <SendNotificationMockupImage alt="Mobile mockup" src={mobileMockup} />

                <SendNotificationMockupContent>
                  <SendNotificationMockupContentInner>
                    <SendNotificationTemplateContent dangerouslySetInnerHTML={{ __html: interpolatePreview() }} />

                    <SendNotificationTemplateOverlay />
                  </SendNotificationMockupContentInner>
                </SendNotificationMockupContent>
              </SendNotificationMockup>
            </SendNotificationTemplate>
          </SendNotificationPreview>

          <SendNotificationFields>
            <Typography gutterBottom variant="subtitle2">
              Текст сообщения
            </Typography>

            <Typography variant="body2">Заголовок</Typography>

            <InputField
              error={!headerText}
              multiline
              name="header_text"
              onChange={onChangeInput}
              rows={4}
              size="small"
              value={headerText}
              variant="outlined"
            />

            <Typography variant="body2">Основной текст</Typography>

            <InputField
              error={!bodyText}
              multiline
              name="body_text"
              onChange={onChangeInput}
              rows={10}
              size="small"
              value={bodyText}
              variant="outlined"
            />

            <Typography variant="body2">Подпись</Typography>

            <InputField
              error={!footerText}
              multiline
              name="footer_text"
              onChange={onChangeInput}
              rows={4}
              size="small"
              value={footerText}
              variant="outlined"
            />

            <Typography gutterBottom variant="subtitle2">
              Настройки
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FormLabel>Способ отправки</FormLabel>

                  <RadioGroup value={notificationMethod}>
                    <FormControlLabel
                      control={<Radio />}
                      label="Электронная почта"
                      value={NotificationDelivery.Email}
                    />

                    <FormControlLabel
                      control={<Radio />}
                      disabled
                      label="Телефон (смс)"
                      value={NotificationDelivery.Phone}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <FormLabel>Тип уведомления</FormLabel>

                  <RadioGroup onChange={onChangeNotificationType} value={notificationType}>
                    <FormControlLabel
                      control={<Radio />}
                      disabled={!informProps}
                      label="Информирование"
                      value={NotificationType.Inform}
                    />

                    <FormControlLabel
                      control={<Radio />}
                      disabled={!confirmProps}
                      label="Подтверждение"
                      value={NotificationType.Confirm}
                    />
                  </RadioGroup>
                </FormControl>

                {notificationType === NotificationType.Confirm && (
                  <ToggleButtonGroup
                    color="primary"
                    exclusive
                    onChange={(_, value: NotificationDecision) => setNotificationDecision(value)}
                    value={notificationDecision}
                  >
                    <ToggleButton value={NotificationDecision.Success}>Успешно</ToggleButton>

                    <ToggleButton value={NotificationDecision.Decline}>Отказ</ToggleButton>
                  </ToggleButtonGroup>
                )}
              </Grid>
            </Grid>
          </SendNotificationFields>

          <SendNotificationSettings>
            <Typography gutterBottom variant="subtitle2">
              <span>Текущий статус</span>

              {isSinglePerson && (
                <Tooltip title="Подробнее о статусах">
                  <IconButton color="primary" onClick={toggleSRMInfoModalOpen} size="small">
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>

            {!isSinglePerson && <Typography>Выбрана массовая отправка</Typography>}

            {isSinglePerson && <SRMStatus status={persons?.[0]?.srm_info?.status} />}

            <SendNotificationAdditional>
              <Typography gutterBottom variant="subtitle2">
                Дополнительно
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Button variant="outlined">Статистика использования</Button>
                </Grid>

                <Grid item xs={12}>
                  <Button variant="outlined">История отправок</Button>
                </Grid>
              </Grid>
            </SendNotificationAdditional>
          </SendNotificationSettings>
        </SendNotificationContent>
      </SendNotificationBody>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose}>
          Отмена
        </Button>

        <Button disabled={!isFormValid} onClick={onSend} type="button">
          Отправить
        </Button>
      </ContentModal.Footer>

      <SRMInfoModal onClose={toggleSRMInfoModalOpen} open={isSRMInfoModalOpen} />
    </SendNotificationRoot>
  );
}

export default memo(observer(SendNotificationModal));
