import React, { memo } from 'react';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import { Retention } from '@/entities/retention';
import { useToggle } from '@/hooks/useToggle/useToggle';
import RetentionPersonsModal from '@/shared/RetentionData/RetentionRow/RetentionPersonsModal/RetentionPersonsModal';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader } from '@/ui-kit/components';

interface RetentionRowProps {
  retention: Retention;
}

function RetentionRow(props: RetentionRowProps) {
  const { retention } = props;
  const { isFetchingAct, getRetentionAct, isFetchingJournal, getRetentionJournal } = useStore('reportsStore');

  const [isPersonsModalOpen, openPersonsModal, closePersonModals] = useToggle();

  return (
    <TableRow>
      <TableCell>
        <div>{retention.userName}</div>

        <div>{retention.userEmail}</div>
      </TableCell>

      <TableCell>
        <div>{retention.deletedAtDate}</div>

        <div>{retention.deletedAtTime}</div>
      </TableCell>

      <TableCell>{retention.months}</TableCell>

      <TableCell>
        <Button onClick={openPersonsModal} variant="outlined">
          Показать
        </Button>
      </TableCell>

      <TableCell>
        <ButtonLoader isLoading={isFetchingAct} onClick={() => getRetentionAct(retention.id)} variant="outlined">
          Скачать
        </ButtonLoader>
      </TableCell>

      <TableCell>
        <ButtonLoader
          isLoading={isFetchingJournal}
          onClick={() => getRetentionJournal(retention.id)}
          variant="outlined"
        >
          Скачать
        </ButtonLoader>
      </TableCell>

      <RetentionPersonsModal onClose={closePersonModals} open={isPersonsModalOpen} retentionId={retention.id} />
    </TableRow>
  );
}

export default memo(RetentionRow);
