import styled from '@mui/material/styles/styled';

export const TechSupportRoot = styled('div')`
  flex-grow: 1;
  line-height: 20px;

  & a {
    display: flex;
    align-items: center;
    color: inherit;
    cursor: default;
    text-decoration: none;
  }

  & svg {
    display: block;
  }
`;

export const TechSupportContacts = styled('div')`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 16px;
  }
`;
