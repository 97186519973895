import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { css, styled } from '@mui/material/styles';

export const SelectFieldRoot = styled(FormControl)`
  position: relative;
`;

export const SelectFieldField = styled(Select)(({ clearable }: { clearable?: boolean }) => {
  if (!clearable) {
    return;
  }

  return css`
    position: absolute;
    bottom: 1px;
    right: 24px;
  `;
});

export const SelectFieldResetIcon = styled('div')`
  position: absolute;
  bottom: 1px;
  right: 24px;
`;
