import React, { memo, useState } from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { fetchApi } from '@/api/fetchApi';
import { notificationAPI } from '@/api/notificationAPI';
import { ButtonLoader, FileField } from '@/ui-kit/components';

import { VerificationForm } from '../Verification.types';

import { KonturFile, KonturPaper, KonturSubmit } from './Kontur.style';

interface CompareProps {
  shouldStylish?: boolean;
}

function Kontur({ shouldStylish = true }: CompareProps) {
  const [isLoading, setIsLoading] = useState(false);

  const verificationForm = useFormik<VerificationForm>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      kontur: null,
      registration: null,
      migration: null,
      opera: null,
    },
    onSubmit: async (values: VerificationForm) => {
      setIsLoading(true);

      const formData = new FormData();

      if (values.kontur) {
        formData.append('fileKontur', values.kontur);
      }

      if (values.opera) {
        formData.append('fileOpera', values.opera);
      }

      try {
        await fetchApi.download('/compare-kontur-opera/', { body: formData });
      } catch (e) {
        notificationAPI.error(e);
      }

      setIsLoading(false);
    },
  });

  const isCompareAvailable = verificationForm.values.kontur && verificationForm.values.opera;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <KonturPaper elevation={0} shouldStyle={shouldStylish} variant={shouldStylish ? 'outlined' : 'elevation'}>
          <Typography gutterBottom variant="subtitle2">
            Отчеты из &quot;Контур&quot;
          </Typography>

          <KonturFile>
            <FileField
              accept=".xlsx"
              disabled={isLoading}
              fullWidth
              label="Контур XLSX"
              name="kontur"
              onChange={verificationForm.handleChange}
              value={verificationForm.values.kontur}
            />
          </KonturFile>
        </KonturPaper>
      </Grid>

      <Grid item xs={6}>
        <KonturPaper elevation={0} shouldStyle={shouldStylish} variant={shouldStylish ? 'outlined' : 'elevation'}>
          <Typography gutterBottom variant="subtitle2">
            Отчеты из Opera
          </Typography>

          <KonturFile>
            <FileField
              accept="text/xml"
              disabled={isLoading}
              fullWidth
              label="Opera xml"
              name="opera"
              onChange={verificationForm.handleChange}
              value={verificationForm.values.opera}
            />
          </KonturFile>
        </KonturPaper>
      </Grid>

      <KonturSubmit item xs={12}>
        <ButtonLoader
          disabled={!isCompareAvailable}
          isLoading={isLoading}
          onClick={verificationForm.submitForm}
          size={shouldStylish ? 'large' : 'medium'}
        >
          Сравнить
        </ButtonLoader>
      </KonturSubmit>
    </Grid>
  );
}

export default memo(Kontur);
