import grey from '@mui/material/colors/grey';
import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import { TextFieldProps } from '@mui/material/TextField/TextField';

export const InputRoot = styled('div')(({ isClearable }: { isClearable: boolean }) => {
  const clearableCss = css`
    & input {
      padding-right: 40px;
    }
  `;

  return css`
    position: relative;
    display: flex;
    align-items: center;

    ${isClearable && clearableCss}

    & svg {
      fill: ${grey['500']};
    }
  `;
});

export const InputResetIcon = styled('div')(({ variant }: { variant: TextFieldProps['variant'] }) => {
  if (variant === 'outlined') {
    return css`
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    `;
  }

  return css`
    position: absolute;
    bottom: 0;
    right: 0;
  `;
});
