import React, { ChangeEvent, memo, useCallback } from 'react';
import { FormikState } from 'formik';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { VisaTemplateForm } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { DatepickerField, InputField, SelectField } from '@/ui-kit/components';

interface VisaFormProps {
  form: FormikState<VisaTemplateForm>;
  onChange: (field: string, value: Nullable<string>) => void;
}

const VisaForm = observer((props: VisaFormProps) => {
  const { form, onChange } = props;
  const { docVisaTypes, docVisaMultiTypes, docVisaVisitTypes } = useStore('dictionariesStore');

  const onFormInputChangeHandler = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    onChange(currentTarget.name, currentTarget.value);
  }, []);
  const onDateIssueChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange('doc_issue_date', target.value);
  }, []);
  const onDateStartChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange('doc_start_date', target.value);
  }, []);
  const onDateExpireChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange('doc_expire_date', target.value);
  }, []);
  const onVisaTypeChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange('doc_visa_type_id', target.value as string);
    onChange('doc_visa_visit_type_id', '');
  }, []);
  const onVisaVisitTypeChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange('doc_visa_visit_type_id', target.value);
  }, []);
  const onVisaMultiTypeChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange('doc_visa_multi_type_id', target.value);
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid alignItems="flex-end" container spacing={2}>
          <Grid item xs={4}>
            <InputField
              error={!!form.errors.doc_serial}
              helperText={form.errors.doc_serial}
              label="Серия"
              name="doc_serial"
              onChange={onFormInputChangeHandler}
              size="small"
              value={form.values.doc_serial}
            />
          </Grid>

          <Grid item xs={4}>
            <DatepickerField
              disabled={false}
              error={!!form.errors.doc_issue_date}
              helperText={form.errors.doc_issue_date}
              label="Дата выдачи"
              onChange={onDateIssueChangeHandler}
              size="small"
              value={form.values.doc_issue_date}
            />
          </Grid>

          <Grid item xs={4}>
            <DatepickerField
              disabled={false}
              error={!!form.errors.doc_start_date}
              helperText={form.errors.doc_start_date}
              label="Период действия с"
              onChange={onDateStartChangeHandler}
              size="small"
              value={form.values.doc_start_date}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid alignItems="flex-end" container spacing={2}>
          <Grid item xs={4}>
            <DatepickerField
              disabled={false}
              error={!!form.errors.doc_expire_date}
              helperText={form.errors.doc_expire_date}
              label="Период действия по"
              onChange={onDateExpireChangeHandler}
              size="small"
              value={form.values.doc_expire_date}
            />
          </Grid>

          <Grid item xs={4}>
            <SelectField
              disabled={docVisaTypes.length === 0}
              label="Вид (категория)"
              onChange={onVisaTypeChangeHandler}
              value={form.values.doc_visa_type_id}
            >
              {docVisaTypes.map(({ id, name_ru }) => (
                <MenuItem key={id} value={id}>
                  {name_ru}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={4}>
            <SelectField
              disabled={
                docVisaVisitTypes.length === 0 || !form.values.doc_visa_type_id || form.values.doc_visa_type_id === 0
              }
              label="Цель поездки"
              onChange={onVisaVisitTypeChangeHandler}
              value={form.values.doc_visa_visit_type_id}
            >
              {docVisaVisitTypes
                .filter(({ doc_visa_type_id }) => doc_visa_type_id === form.values.doc_visa_type_id)
                .map(({ id, name_ru }) => (
                  <MenuItem key={id} value={id}>
                    {name_ru}
                  </MenuItem>
                ))}
            </SelectField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid alignItems="flex-end" container spacing={2}>
          <Grid item xs={4}>
            <SelectField
              disabled={docVisaMultiTypes.length === 0}
              label="Кратность визы"
              onChange={onVisaMultiTypeChangeHandler}
              value={form.values.doc_visa_multi_type_id}
            >
              {docVisaMultiTypes.map(({ id, name_ru }) => (
                <MenuItem key={id} value={id}>
                  {name_ru}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>

          <Grid item xs={4}>
            <InputField
              disabled={false}
              error={!!form.errors.visit_side}
              helperText={form.errors.visit_side}
              label="Принимающая организация"
              name="visit_side"
              onChange={onFormInputChangeHandler}
              size="small"
              value={form.values.visit_side}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default memo(VisaForm);
