import React from 'react';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent/DialogContent';

function ContentModalBody(props: DialogContentProps) {
  const { children, ...rest } = props;

  return <DialogContent {...rest}>{children}</DialogContent>;
}

export default ContentModalBody;
