import React, { Fragment, memo, useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';

import { fetchApi } from '@/api/fetchApi';
import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { Citizen, DocumentType } from '@/constants';
import { DEFAULT_EMPTY_PERSON, DEFAULT_ID_CARD, PersonDetail } from '@/stores/PersonsStore';
import { BackdropLoader } from '@/ui-kit/components';

import Citizenship from './Citizenship/Citizenship';
import DocImages from './DocImages/DocImages';
import Finish from './Finish/Finish';
import Greeting from './Greeting/Greeting';
import { CheckinStep } from './SelfCheckInMobile.constants';
import { SelfCheckInMobileNext, SelfCheckInMobileRoot, SelfCheckInMobileSteps } from './SelfCheckInMobile.style';

interface PersonResponse {
  person: PersonDetail;
}

function SelfCheckInMobile() {
  const query = queryAPI.params;

  const [step, setStep] = useState<CheckinStep>(CheckinStep.Greeting);
  const [passportPage1, setPassportPage1] = useState<Nullable<string>>(null);
  const [passportPage2, setPassportPage2] = useState<Nullable<string>>(null);
  const [person, setPerson] = useState<Nullable<PersonDetail>>(null);

  async function updatePersonValues() {
    try {
      if (person) {
        const personData = person;

        const currentIdDocumentIndex = personData.documents.findIndex(
          ({ document_type_enum }) => document_type_enum.category === DocumentType.IdCard,
        );

        const images = personData.documents[currentIdDocumentIndex].images ?? [];

        if (passportPage1) {
          images.push({
            data: passportPage1,
            image_upload_type: 3,
            not_export: false,
          });
        }

        if (passportPage2) {
          images.push({
            data: passportPage2,
            image_upload_type: 3,
            not_export: false,
          });
        }

        const newIdCard = {
          ...personData.documents[currentIdDocumentIndex],
          images,
        };

        personData.documents = [newIdCard];

        await fetchApi.put('/api/srm/guest/', {
          body: { ...personData },
          base: import.meta.env.EMIS_SRM_DOMAIN,
          headers: {
            token: query.token,
          },
        });

        setStep(CheckinStep.Finish);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function onPrevStep() {
    setStep((prevStep: CheckinStep) => {
      switch (prevStep) {
        case CheckinStep.DocImages1:
          setPassportPage1(null);

          return CheckinStep.Citizenship;
        case CheckinStep.DocImages2:
          setPassportPage2(null);

          return CheckinStep.DocImages1;
        default:
          return CheckinStep.Greeting;
      }
    });
  }

  function onNextStep() {
    if (step === CheckinStep.DocImages2) {
      updatePersonValues();

      return;
    }

    setStep((prevStep: CheckinStep) => {
      switch (prevStep) {
        case CheckinStep.Citizenship:
          return CheckinStep.DocImages1;
        case CheckinStep.DocImages1:
          return CheckinStep.DocImages2;
        case CheckinStep.DocImages2:
          return CheckinStep.Finish;
        default:
          return CheckinStep.Greeting;
      }
    });
  }

  function onSelectCitizenship() {
    setPerson((prevValues: Nullable<PersonDetail>) => ({
      ...(prevValues as PersonDetail),
      citizenship: Citizen.Russia,
    }));

    onNextStep();
  }

  function onUploadPassportPage1(value: Nullable<string>) {
    setPassportPage1(value);
  }

  function onUploadPassportPage2(value: Nullable<string>) {
    setPassportPage2(value);
  }

  function detectDisabledNextButton(): boolean {
    if (step === CheckinStep.Citizenship) {
      return !person?.citizenship;
    }

    if (step === CheckinStep.DocImages1) {
      return !passportPage1;
    }

    if (step === CheckinStep.DocImages2) {
      return !passportPage2;
    }

    return false;
  }

  async function init() {
    if (query.token) {
      try {
        // тут у нас временный домен, потом его надо будет поменять
        const { person } = await fetchApi.get<PersonResponse>(
          '/api/srm/guest/',
          {
            token: query.token,
          },
          {
            base: import.meta.env.EMIS_SRM_DOMAIN,
          },
        );

        setPerson({
          ...DEFAULT_EMPTY_PERSON,
          ...person,
          documents: [
            {
              birth_country_id: null,
              citizenship: DEFAULT_ID_CARD.citizenship,
              document_type_enum_id: DEFAULT_ID_CARD.document_type_enum_id,
              document_type_enum: {
                id: DEFAULT_ID_CARD.document_type_enum.id,
                category: DEFAULT_ID_CARD.document_type_enum.category,
              },
              images: [],
              gender: DEFAULT_ID_CARD.gender,
            } as never,
          ],
        });
      } catch (e) {
        notificationAPI.error(e);
      }
    }
  }

  useEffect(() => {
    init();
  }, []);

  if (!query.token) {
    return <p>Ошибка получения токена. Обратитесь к администратору отеля.</p>;
  }

  return (
    <SelfCheckInMobileRoot>
      <BackdropLoader isLoading={!person} />

      {person && (
        <Fragment>
          {step === CheckinStep.Greeting && <Greeting name={`${person.fname} ${person.lname}`} onNextStep={setStep} />}

          {step === CheckinStep.Citizenship && <Citizenship onSelectCitizenship={onSelectCitizenship} />}

          {step === CheckinStep.DocImages1 && (
            <DocImages
              mode="first-page"
              onUpload={onUploadPassportPage1}
              passportPage={passportPage1}
              subTitle="Разворот страницы паспорта с фотографией"
            />
          )}

          {step === CheckinStep.DocImages2 && (
            <DocImages
              mode="second-page"
              onUpload={onUploadPassportPage2}
              passportPage={passportPage2}
              subTitle="Страница с пропиской"
            />
          )}

          {step === CheckinStep.Finish && <Finish />}

          {step !== CheckinStep.Greeting && step !== CheckinStep.Citizenship && (
            <SelfCheckInMobileSteps>
              {step !== CheckinStep.Finish && (
                <Fragment>
                  <Button onClick={onPrevStep} variant="outlined">
                    Назад
                  </Button>

                  <SelfCheckInMobileNext>
                    <Button
                      disabled={detectDisabledNextButton()}
                      endIcon={step !== CheckinStep.DocImages2 ? <ArrowForwardIcon /> : undefined}
                      onClick={onNextStep}
                      variant="contained"
                    >
                      {step === CheckinStep.DocImages2 ? 'Завершить' : 'Далее'}
                    </Button>
                  </SelfCheckInMobileNext>
                </Fragment>
              )}
            </SelfCheckInMobileSteps>
          )}
        </Fragment>
      )}
    </SelfCheckInMobileRoot>
  );
}

export default memo(SelfCheckInMobile);
