import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { CompanyModel } from '@/entities/company';
import Users from '@/shared/Users/Users';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import { CompanyUsersHeader, CompanyUsersList, CompanyUsersRoot } from './CompanyUsers.style';

interface CompanyUsersProps {
  companyId: number;
}

function CompanyUsers({ companyId }: CompanyUsersProps) {
  const { userData } = useStore('signInStore');
  const { getCompanyById, isFetchingCompanyById } = useStore('companiesStore');

  const companyForm = useFormik({
    initialValues: {} as CompanyModel,
    onSubmit: () => undefined,
  });

  async function fetchCompanyById() {
    try {
      if (userData) {
        const company = await getCompanyById(userData.company_id);

        companyForm.resetForm({
          values: { ...company },
        });
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    fetchCompanyById();

    return () => {
      companyForm.resetForm();
    };
  }, []);

  return (
    <CompanyUsersRoot>
      <BackdropLoader isLoading={isFetchingCompanyById} />

      {companyForm.values.users && (
        <CompanyUsersHeader>
          <Typography component="p">
            <span>Всего: </span>

            <span>{companyForm.values.users.length}/</span>

            {companyForm.values.max_users}
          </Typography>

          <span>&nbsp;&nbsp;</span>

          <Divider flexItem orientation="vertical" variant="fullWidth" />

          <span>&nbsp;&nbsp;</span>

          <Typography component="p">
            <span>Онлайн: </span>

            <span>{companyForm.values.users.filter(({ online }) => online).length}/</span>

            {companyForm.values.max_online_users}
          </Typography>
        </CompanyUsersHeader>
      )}

      <CompanyUsersList>
        <Users companyId={companyId} />
      </CompanyUsersList>
    </CompanyUsersRoot>
  );
}

export default memo(CompanyUsers);
