import React, { memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { isBefore, isValid } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { Citizen, DocumentType, NEW_PERSON_ID, RUSSIA_FOUNDATION_DAY } from '@/constants';
import {
  DEFAULT_EMPTY_PERSON,
  DEFAULT_ID_CARD,
  DEFAULT_MIGRATION_CARD,
  DEFAULT_RIGHT_OF_STAY,
  DocumentImage,
  OperationType,
  PersonDetail,
  PersonDocument,
  PersonStatus,
  SocketOperation,
  SocketOperationError,
} from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';
import { getDocumentByType, pureStructure } from '@/utils';

import { PersonPageType } from '../PersonPage.constant';
import { PersonFormRouteState, PersonPollingEvent } from '../PersonPage.types';
import { usePersonPageStore } from '../PersonPageStore';

import AttachUnrecognizedDocModal from './AttachUnrecognizedDocModal/AttachUnrecognizedDocModal';
import BasicPersonData from './BasicPersonData/BasicPersonData';
import DifferentDocNumberModal from './DifferentDocNumberModal/DifferentDocNumberModal';
import DocumentsPersonData from './DocumentsPersonData/DocumentsPersonData';
import ImagesPanel from './ImagesPanel/ImagesPanel';
import {
  PersonFormColumn,
  PersonFormContainer,
  PersonFormContent,
  PersonFormData,
  PersonFormRoot,
} from './PersonForm.style';

function PersonForm() {
  const { pathname } = window.location;
  const state = history.state;
  const {
    isRussianCitizenship,
    birthDate,
    getValuesFromDocument,
    documents,
    personId,
    personPageType,
    pendingScanId,
    onCreatePerson,
    onUpdatePerson,
    setPersonValues,
    onRefetchPersonData,
    navigate,
    imagesToRemove,
    isSaving,
    setSavingState,
    idCard,
    idCardIndex,
    rightOfStay,
    rightOfStayIndex,
    migrationCard,
    migrationCardIndex,
    getPersonUrl,
    tab,
    setActiveTab,
    checkForBirthdate,
    checkForExpiration,
    setPendingScanId,
  } = usePersonPageStore();
  const socket = useStore('socket');
  const { setScanningState, getDocumentByOperationId, removeImageInDocumentById, isScanning, isFetchingLogus } =
    useStore('personsStore');
  const { userData } = useStore('signInStore');
  const { getRegions, getStreets } = useStore('dictionariesStore');

  const [unrecognizedDoc, setUnrecognizedDoc] =
    useState<Nullable<Pick<PersonDocument, 'device_op_id' | 'images'>>>(null);

  const [idCardTemp, setIdCardTemp] = useState<Nullable<PersonDocument>>(null);

  const onUpdatePersonValues = useCallback((values: Partial<PersonDetail>) => {
    setPersonValues({
      ...values,
      status: PersonStatus.Pending,
    });
  }, []);

  useEffect(() => {
    /*
     * Если в стейте уже лежит `idCardTemp`, значит карточка была скопирована из модалки `DifferentDocNumberModal`,
     * в этом случае заполняем данные с нуля
     * */
    if (personPageType === PersonPageType.Create && (state as PersonFormRouteState)?.usr?.idCardTemp) {
      setPersonValues(
        {
          ...getValuesFromDocument((state as PersonFormRouteState).usr.idCardTemp as PersonDocument),
        },
        state as PersonFormRouteState,
      );

      // сбрасывает стейт роута после его использования, иначе значение всегда будет в стейте
      navigate(`${pathname}${window.location.search}`, {
        replace: true,
        state: null,
      });
    }
  }, [userData?.default_group_id]);

  function onCreateNewPerson() {
    setIdCardTemp(null);

    if (personId) {
      navigate(
        `${pathname.replace(personId, NEW_PERSON_ID)}${queryAPI.generateQuery({
          ...queryAPI.params,
          page: 0, // при копировании всегда сбрасывать страницу
        })}`,
        {
          // при `replace: true` фактической смены роута не будет, данные обновятся в фоновом режиме
          replace: true,
          state: {
            idCardTemp,
          },
        },
      );

      // имей ввиду что эта функция вызывается в нескольких местах в этом файле
      if (idCardTemp?.document_type?.pages === 2) {
        notificationAPI.warning('Данный документ содержит две страницы');
      }
    }
  }

  function manualIdDocumentUpdate(idCard: PersonDocument) {
    setIdCardTemp(null);

    const personValues = {
      documents: pureStructure(documents),
      ...getValuesFromDocument(idCard),
    };

    // Если удостоверения личности не было, то добавляем его в массив документов
    if (idCardIndex === -1) {
      personValues.documents.push(idCard);
    } else {
      // Если удостоверение личности есть - то перезаписываем все данные
      personValues.documents[idCardIndex] = {
        ...idCard,
      };
    }

    onUpdatePersonValues(personValues);

    // имей ввиду что эта функция вызывается в нескольких местах в этом файле
    if (idCard.document_type?.pages === 2) {
      notificationAPI.warning('Данный документ содержит две страницы');
    }
  }

  function onConfirmDifferentDocNumberModal() {
    if (idCardTemp) {
      const images = [...(idCardIndex !== -1 ? documents?.[idCardIndex]?.images : []), ...(idCardTemp.images ?? [])];

      /*
       * если Удостоверение личности уже присутствует и входящие данные противоречат текущим,
       * то мы заполним только те поля, которые пустые, те поля что уже заполнены оставляем как есть
       * */
      const newIdCard = documents?.[idCardIndex];

      if (newIdCard) {
        Object.keys(idCardTemp).forEach((key: string) => {
          if (!newIdCard[key]) {
            newIdCard[key] = idCardTemp[key];
          }
        });

        manualIdDocumentUpdate({
          ...newIdCard,
          images,
        });
      }
    }
  }

  function attachUnrecognizedDoc(type: DocumentType) {
    if (!unrecognizedDoc?.device_op_id) {
      return;
    }

    const newDocument: Partial<PersonDocument> = {
      ...unrecognizedDoc,
    };

    delete newDocument.document_type_enum;
    delete newDocument.document_type_enum_id;

    onCloseDocumentAttaching();
    setActiveTab(type);

    const newPerson = {
      documents: pureStructure(documents),
      ...getValuesFromDocument(unrecognizedDoc),
    };
    const [document = {}, documentIndex] = getDocumentByType(newPerson.documents, type);

    let mergedDocument = {};

    if (type === DocumentType.IdCard) {
      mergedDocument = {
        ...DEFAULT_ID_CARD,
        ...document,
        ...newDocument,
      };
    }

    if (type === DocumentType.RightOfStay) {
      mergedDocument = {
        ...DEFAULT_RIGHT_OF_STAY,
        ...document,
        ...newDocument,
      };
    }

    if (type === DocumentType.Migration) {
      mergedDocument = {
        ...DEFAULT_MIGRATION_CARD,
        ...document,
        ...newDocument,
      };
    }

    if (documentIndex === -1) {
      newPerson.documents.push({
        ...mergedDocument,
        images: newDocument.images,
      } as PersonDocument);
    } else {
      const formImages: Partial<DocumentImage>[] = newPerson.documents[documentIndex].images
        ? [...newPerson.documents[documentIndex].images]
        : [];

      newPerson.documents[documentIndex] = {
        ...mergedDocument,
        images: formImages.concat(newDocument.images ?? []),
      } as PersonDocument;
    }

    onUpdatePersonValues(newPerson);
  }

  const onCloseDocumentAttaching = useCallback(() => setUnrecognizedDoc(null), []);

  /*
   * Заполнять форму в UI мы начнем только после получения изображения,
   * тут мы получим изображение и ID операции, по этому ID заберем с бека данные документа
   * */
  async function onReceiveImageFromScan(operationId: number, opType: OperationType) {
    let personValues = {
      reg_info: DEFAULT_EMPTY_PERSON.reg_info,
      documents: pureStructure(documents),
    };

    const newImage: DocumentImage = {
      device_op_id: operationId,
      not_export: opType === OperationType.ScanSingleImage,
    };

    try {
      const newDocument = await getDocumentByOperationId(operationId);

      setPendingScanId(null);

      if (!newDocument) {
        notificationAPI.error('Документ не найден на сервере! Обратитесь к администратору!');

        return;
      }

      if (newDocument.document_type_enum) {
        // когда получили Удостоверение личности
        if (newDocument.document_type_enum.category === DocumentType.IdCard) {
          let newIdCard = {
            ...newDocument,
          };

          // `birth_country_id` не должен быть переписан, однако может быть кейс, при котором в документе 2 страницы
          // и при сканировании 2й страницы `birth_country_id` может быть пустым или отутствовать
          if (newDocument.birth_country_id) {
            newIdCard.birth_country_id = newDocument.birth_country_id;
          }

          // `doc_issue_authority_organ.code` не должен быть переписан, однако может быть кейс, при котором в документе 2 страницы
          // и при сканировании 2й страницы `doc_issue_authority_organ.code` может быть пустым или отутствовать
          if (newDocument.doc_issue_authority_organ?.code) {
            newIdCard.doc_issue_code = newDocument.doc_issue_authority_organ?.code ?? '';

            if (newDocument.doc_issue_authority) {
              newIdCard.doc_issue_authority = newDocument.doc_issue_authority;
            }
          }

          /*
           * Если текущий `doc_number` не совпадает с пришедшим,
           * кладем новые данные в отдельное храналищие и завершаем выполнении функции, чтобы затем провести с ними манипуляции
           *
           * Когда кладем данные через `setIdCardTemp`, то покажется модалка с набором действий
           * */
          if (
            personValues.documents.length > 0 &&
            personValues.documents?.[idCardIndex]?.doc_number &&
            newIdCard?.doc_number &&
            `${personValues.documents?.[idCardIndex]?.doc_number}`.trim() !== `${newIdCard.doc_number}`.trim()
          ) {
            setScanningState(false);

            setIdCardTemp({
              ...DEFAULT_ID_CARD,
              ...newIdCard,
              images: [newImage],
            });

            return;
          }

          // если Удостоверение личности уже есть, мержим все данные в том числе картинки, если нет, то создаем из дефолтных значений
          if (idCardIndex === -1) {
            newIdCard = {
              ...DEFAULT_ID_CARD,
              ...newIdCard,
              images: [newImage],
            };
          } else {
            newIdCard = {
              ...idCard,
              ...newIdCard,
              images: (idCard?.images ?? []).concat(newImage),
            };
          }

          if (newDocument.document_type?.code === 'Russian Federation - Registration Stamp #1') {
            if (newDocument.address_state && newDocument.address_city) {
              const regions = await getRegions(
                `${newDocument.address_state} ${newDocument.address_city}`.replaceAll('.', '').replaceAll(',', ''),
              );

              if (Array.isArray(regions) && regions.length > 0) {
                let regInfo = {
                  ...personValues.reg_info,
                  fias_code: regions[0].ao_id,
                  fias_code2: regions[0].ao_guid,
                  region_code: regions[0].region_code,
                };

                if (newDocument.address_street) {
                  const streets = await getStreets(
                    newDocument.address_street,
                    regions[0].region_code,
                    regions[0].parent,
                  );

                  if (Array.isArray(streets) && streets.length > 0) {
                    regInfo = {
                      ...regInfo,
                      fias_code: streets[0].ao_id,
                      fias_code2: streets[0].ao_guid,
                      region_code: streets[0].region_code,
                    };
                  }
                }

                personValues.reg_info = {
                  ...personValues.reg_info,
                  ...regInfo,
                };
              }
            }
          }

          if (idCardIndex === -1) {
            personValues.documents.push(newIdCard);
          } else {
            personValues.documents[idCardIndex] = {
              ...idCard,
              ...newIdCard,
            };
          }
        }

        // когда получили Право пребывания
        if (newDocument.document_type_enum.category === DocumentType.RightOfStay) {
          if (rightOfStayIndex === -1) {
            personValues.documents.push({
              ...DEFAULT_RIGHT_OF_STAY,
              ...newDocument,
              images: [newImage],
            });
          } else {
            personValues.documents[rightOfStayIndex] = {
              ...rightOfStay,
              ...newDocument,
              images: (rightOfStay?.images ?? []).concat(newImage),
            };
          }
        }

        // когда получили Миграционную карту
        if (newDocument.document_type_enum.category === DocumentType.Migration) {
          if (migrationCardIndex === -1) {
            personValues.documents.push({
              ...DEFAULT_MIGRATION_CARD,
              ...newDocument,
              images: [newImage],
            });
          } else {
            personValues.documents[migrationCardIndex] = {
              ...migrationCard,
              ...newDocument,
              images: (migrationCard?.images ?? []).concat(newImage),
            };
          }
        }

        if (opType === OperationType.Scan || opType === OperationType.Recognize) {
          personValues = {
            ...personValues,
            ...getValuesFromDocument(newDocument),
          };
        }

        onUpdatePersonValues(personValues);

        setActiveTab(newDocument.document_type_enum.category);

        if (newDocument.document_type_enum.category === DocumentType.IdCard) {
          // показать уведомления, если нужно обратить внимание на ДР персона
          checkForBirthdate();
        }

        // показать уведомление для документов
        checkForExpiration(newDocument.document_type_enum_id);
      } else {
        if (opType === OperationType.ScanSingleImage && tab) {
          const [, index] = getDocumentByType(personValues.documents, tab);

          personValues.documents[index] = {
            ...personValues.documents[index],
            images: personValues.documents[index].images.concat(newImage),
          };

          onUpdatePersonValues(personValues);
        } else {
          setUnrecognizedDoc({
            ...newDocument,
            images: [newImage],
          });
        }

        setScanningState(false);

        return;
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    setScanningState(false);
  }

  // после нажатия кнопки "Начать сканирование" получаем данные и выполняем эту функцию
  function onWebsocketMessage(message: MessageEvent | PersonPollingEvent) {
    const data = JSON.parse((message as MessageEvent).data || (message as PersonPollingEvent).detail);

    if (!data) {
      return;
    }

    if (pendingScanId !== data.id) {
      return;
    }

    if (data.error === SocketOperationError.Timeout) {
      notificationAPI.error('Операция не удалась');

      setScanningState(false);

      return;
    }

    if (data.type === SocketOperation.Dop) {
      if (data.error === SocketOperationError.NotDetected) {
        if (data.op_type === OperationType.Recognize) {
          notificationAPI.error('Документ не распознан');

          setScanningState(false);

          return;
        }
      }
    }

    // когда сканируем документ мы всегда ожидаем что придет картинка
    // и только после этого мы сможешь взять данные документа с сервера
    if (data.type === SocketOperation.Img) {
      onReceiveImageFromScan(data.id, data.op_type);
    }
  }

  /*
   * Делаем принудительную типизацию, потому что это наш кастомный евент
   * */
  function onPollingMessage(message: unknown) {
    onWebsocketMessage(message as PersonPollingEvent);
  }

  useEffect(() => {
    socket.addEventListener('message', onWebsocketMessage);
    window.addEventListener('polling-event', onPollingMessage);

    return () => {
      socket.removeEventListener('message', onWebsocketMessage);
      window.removeEventListener('polling-event', onPollingMessage);
    };
  }, [onWebsocketMessage, onPollingMessage]);

  useEffect(() => {
    if (!tab) {
      setActiveTab(
        idCardIndex !== -1
          ? DocumentType.IdCard
          : rightOfStayIndex !== -1
            ? DocumentType.RightOfStay
            : migrationCardIndex !== -1
              ? DocumentType.Migration
              : null,
      );
    }
  }, [idCardIndex, rightOfStayIndex, migrationCardIndex]);

  async function onFormSubmit(e: SyntheticEvent) {
    e.preventDefault();

    if (isRussianCitizenship && documents?.[0]?.birth_country_id === Citizen.Russia && birthDate) {
      /*
       * Если заполнено значение `birth_date` и гражданство РОССИЯ и дата рождения ДО развала СССР,
       * то отправляем гражданство СССР на сервер
       * */
      const birthDateValue = new Date(birthDate);

      if (isValid(birthDateValue) && isBefore(birthDateValue, RUSSIA_FOUNDATION_DAY)) {
        documents[0].birth_country_id = Citizen.USSR;
      }
    }

    try {
      if (imagesToRemove.length > 0) {
        await Promise.all(
          imagesToRemove.map(([docId, imageId]: [number, number]) => removeImageInDocumentById(docId, imageId)),
        );
      }

      if (personPageType === PersonPageType.Create) {
        const newPerson = await onCreatePerson();

        if (newPerson) {
          navigate(getPersonUrl(newPerson.id));
        }
      }

      if (personPageType === PersonPageType.Edit && personId) {
        setSavingState(true);

        await onUpdatePerson();

        onRefetchPersonData();

        notificationAPI.success('Карта гостя была обновлена');
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    setSavingState(false);
  }

  return (
    <PersonFormRoot id="person-page-form" onSubmit={onFormSubmit}>
      {(isSaving || isScanning || isFetchingLogus) && <BackdropLoader isLoading />}

      <PersonFormContent>
        <PersonFormContainer container spacing={2}>
          <PersonFormColumn item xs={5}>
            <ImagesPanel />
          </PersonFormColumn>

          <PersonFormColumn item xs={7}>
            <PersonFormData>
              <BasicPersonData />

              <DocumentsPersonData />
            </PersonFormData>
          </PersonFormColumn>
        </PersonFormContainer>
      </PersonFormContent>

      <AttachUnrecognizedDocModal
        isLoading={unrecognizedDoc?.images ? unrecognizedDoc?.images.length === 0 : false}
        onAttach={attachUnrecognizedDoc}
        onDecline={onCloseDocumentAttaching}
        open={!!unrecognizedDoc}
      />

      <DifferentDocNumberModal
        onConfirm={onConfirmDifferentDocNumberModal}
        onCreateNew={onCreateNewPerson}
        open={!!idCardTemp}
      />
    </PersonFormRoot>
  );
}

export default memo(observer(PersonForm));
