import React, { createContext, PropsWithChildren, useContext } from 'react';

import { Store, store } from './Store';

const StoreContext = createContext<Store>(store);

export function StoreProvider({ children }: PropsWithChildren<unknown>) {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}

export function useStore<T extends keyof Store>(key: T): Store[T] {
  const context = useContext(StoreContext);

  const storeByKey = context[key];

  if (!storeByKey) {
    console.error(`"${key}" not exists in Store`);

    return {} as never;
  }

  return storeByKey;
}
