import styled from '@mui/material/styles/styled';

export const OperatorPanelRoot = styled('div')`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const OperatorPanelRoutes = styled('div')`
  flex-grow: 1;
  overflow: hidden;
`;
