import { isObject } from './isObject';

export function isString(value: unknown) {
  try {
    if (typeof value === 'string') {
      return true;
    }

    return isObject(value) && Object.prototype.toString.call(value) === '[object String]';
  } catch (e) {
    return false;
  }
}
