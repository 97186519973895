import { EulaCard, EulaCardModel } from '@/entities/eualCard';
import { Face, FaceModel } from '@/entities/face';
import { IpAddress, IpAddressModel } from '@/entities/ipAddress';
import { KSDocTemplate, KSDocTemplateCategory, KSDocTemplateModel } from '@/entities/KSDocTemplate';
import { User, UserModel } from '@/entities/user';
import { pureStructure } from '@/utils';

export class CompanyModel {
  id!: number;
  name!: string;
  address!: string;
  region_code!: string;
  fias_code!: string;
  fias_code2!: string;
  housing!: string;
  housing2!: string;
  housing3!: string;
  flat!: string;
  ur_name!: string;
  ur_fias_code!: string;
  ur_fias_code2!: string;
  ur_housing!: string;
  ur_housing2!: string;
  ur_housing3!: string;
  ur_region_code!: string;
  geo!: string;
  tel!: string;
  email!: string;
  utc!: number;
  inn!: string;
  kpp!: string;
  bank!: string;
  bik!: string;
  correspondent_account!: string;
  ogrn!: string;
  payment_account!: string;
  max_users!: number;
  max_online_users!: number;
  hms!: number;
  webhook!: string;
  webhook_local!: string;
  webhook_header_name!: string;
  webhook_header_value!: string;
  hms_link!: string;
  hms_user!: string;
  hms_pass!: string;
  apart_doc!: string;
  allow_export!: number;
  stay_days!: number;
  has_eula!: number;
  mail_login!: string;
  mail_smtp_host!: string;
  mail_smtp_port!: number;
  mail_password!: string;
  users!: UserModel[];
  ip!: IpAddressModel[];
  faces!: FaceModel[];
  eulas!: EulaCardModel[];
  notice!: string;
  testing!: boolean;
  payment_debt!: boolean;
  doc_templates!: KSDocTemplateModel[];
  ks?: boolean;
  ais_url?: string;
  ais_user?: string;
  ais_pass?: string;
}

export class Company extends CompanyModel {
  users: User[] = [];

  constructor(company: CompanyModel) {
    super();

    Object.keys(company).forEach((key) => {
      switch (key) {
        case 'users':
          if (Array.isArray(company.users)) {
            this.users = company.users.map((user) => new User(user));
          }

          break;
        case 'ip':
          if (Array.isArray(company.ip)) {
            this.ip = company.ip.map((ipAddress) => new IpAddress(ipAddress));
          }

          break;
        case 'eulas':
          if (Array.isArray(company.eulas)) {
            this.eulas = company.eulas.map((eulaCard) => new EulaCard(eulaCard));
          }

          break;
        case 'faces':
          if (Array.isArray(company.faces)) {
            this.faces = company.faces.map((face) => new Face(face));
          }

          break;
        case 'ks':
          this.ks = Boolean(company[key]);

          break;
        case 'doc_templates':
          if (Array.isArray(company.doc_templates)) {
            this.doc_templates = company.doc_templates.map((docTemplate) => new KSDocTemplate(docTemplate));
          }

          break;
        case 'ais_pass':
          this.ais_pass = company.ais_pass ?? '';

          break;
        default:
          this[key] = company[key];

          break;
      }
    });
  }

  get hasHMSStatus() {
    return this.hms !== 0;
  }

  get paidDocTemplates() {
    return this.doc_templates?.filter((docTemplate) => docTemplate.category === KSDocTemplateCategory.Payments) ?? [];
  }

  get rejectByGuestDocTemplates() {
    return (
      this.doc_templates?.filter((docTemplate) => docTemplate.category === KSDocTemplateCategory.RejectByGuest) ?? []
    );
  }

  get rejectByHotelDocTemplates() {
    return (
      this.doc_templates?.filter((docTemplate) => docTemplate.category === KSDocTemplateCategory.RejectByHotel) ?? []
    );
  }

  toJS(): CompanyModel {
    return pureStructure({ ...this });
  }
}
