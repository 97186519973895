import React, { ChangeEvent, memo, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import FormControl from '@mui/material/FormControl';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

import { notificationAPI } from '@/api/notificationAPI';
import { DEFAULT_EMPTY_CITIZENSHIP } from '@/constants';
import { DictionaryBasicWithCode, DocIssueAuthority } from '@/entities/dictionary';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField } from '@/ui-kit/components';

interface CitizenshipSelectProps {
  value: unknown;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  isError?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  fullWidth?: boolean;
  size?: BaseTextFieldProps['size'];
  variant?: BaseTextFieldProps['variant'];
  margin?: BaseTextFieldProps['margin'];
}

function CitizenshipSelect(props: CitizenshipSelectProps) {
  const {
    value,
    name,
    onChange,
    disabled = false,
    isError = false,
    isClearable = true,
    fullWidth = true,
    ...rest
  } = props;
  const { getCountries, countries } = useStore('dictionariesStore');

  const preparedCountries = useMemo(() => countries.concat([DEFAULT_EMPTY_CITIZENSHIP]), [JSON.stringify(countries)]);

  const renderOption = useCallback(({ name_ru }: DocIssueAuthority) => `${name_ru}`, []);

  function onChangeHandler(option: unknown, e: SyntheticEvent) {
    onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: (option as DictionaryBasicWithCode)?.id ?? null,
      } as unknown as EventTarget,
    } as ChangeEvent<HTMLInputElement>);
  }

  async function fetchInitData() {
    try {
      if (countries.length === 0) {
        await getCountries();
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    fetchInitData();
  }, []);

  return (
    <FormControl fullWidth={fullWidth}>
      <AutocompleteField
        disableClearable={!isClearable}
        disabled={disabled}
        getOptionLabel={renderOption}
        isError={isError}
        label="Гражданство"
        name={name}
        onChange={onChangeHandler}
        options={[...preparedCountries]}
        value={preparedCountries.find(({ id }) => id === value) ?? null}
        {...rest}
      />
    </FormControl>
  );
}

export default memo(observer(CitizenshipSelect));
