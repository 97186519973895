import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';

export const ButtonRoot = styled(Button)`
  position: relative;
`;

export const ButtonLoaderIndicator = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
