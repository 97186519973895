import React, { memo } from 'react';
import { useFormik } from 'formik';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { fetchApi } from '@/api/fetchApi';
import { notificationAPI } from '@/api/notificationAPI';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { ButtonLoader, FileField } from '@/ui-kit/components';

import { VerificationForm } from '../Verification.types';

import { ElpostFile, ElpostPaper, ElpostSubmit } from './Elpost.style';

interface CompareProps {
  shouldStylish?: boolean;
}

function Elpost({ shouldStylish = true }: CompareProps) {
  const [isLoading, , , toggleLoading] = useToggle();

  const verificationForm = useFormik<VerificationForm>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      kontur: null,
      registration: null,
      migration: null,
      opera: null,
    },
    onSubmit: async (values: VerificationForm) => {
      toggleLoading();

      const formData = new FormData();

      if (values.registration) {
        formData.append('fileReg', values.registration);
      }

      if (values.migration) {
        formData.append('fileMig', values.migration);
      }

      if (values.opera) {
        formData.append('fileOpera', values.opera);
      }

      try {
        await fetchApi.download('/compare-elpost-opera/', { body: formData });
      } catch (e) {
        notificationAPI.error(e);
      }

      toggleLoading();
    },
  });

  const isCompareAvailable =
    (verificationForm.values.registration || verificationForm.values.migration) && verificationForm.values.opera;

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <ElpostPaper elevation={0} shouldStyle={shouldStylish} variant={shouldStylish ? 'outlined' : 'elevation'}>
          <Typography gutterBottom variant="subtitle2">
            Отчеты из &quot;АИС Элпост&quot;
          </Typography>

          <ElpostFile>
            <FileField
              accept=".csv"
              disabled={isLoading}
              fullWidth
              label="Регистрационный учет"
              name="registration"
              onChange={verificationForm.handleChange}
              value={verificationForm.values.registration}
            />
          </ElpostFile>

          <ElpostFile>
            <FileField
              accept=".csv"
              disabled={isLoading}
              fullWidth
              label="Миграционный учет"
              name="migration"
              onChange={verificationForm.handleChange}
              value={verificationForm.values.migration}
            />
          </ElpostFile>
        </ElpostPaper>
      </Grid>

      <Grid item xs={6}>
        <ElpostPaper elevation={0} shouldStyle={shouldStylish} variant={shouldStylish ? 'outlined' : 'elevation'}>
          <Typography gutterBottom variant="subtitle2">
            Отчеты из Opera
          </Typography>

          <ElpostFile>
            <FileField
              accept="text/xml"
              disabled={isLoading}
              fullWidth
              label="Opera xml"
              name="opera"
              onChange={verificationForm.handleChange}
              value={verificationForm.values.opera}
            />
          </ElpostFile>
        </ElpostPaper>
      </Grid>

      <ElpostSubmit item xs={12}>
        <ButtonLoader
          disabled={!isCompareAvailable}
          isLoading={isLoading}
          onClick={verificationForm.submitForm}
          size={shouldStylish ? 'large' : 'medium'}
        >
          Сравнить
        </ButtonLoader>
      </ElpostSubmit>
    </Grid>
  );
}

export default memo(Elpost);
