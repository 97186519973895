import React, { ReactElement, SyntheticEvent } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog/Dialog';
import { DialogActionsProps } from '@mui/material/DialogActions/DialogActions';

import { BackdropLoader } from '@/ui-kit/components';

import ContentModalBody from './ContentModalBody/ContentModalBody';
import ContentModalFooter from './ContentModalFooter/ContentModalFooter';
import ContentModalHeader, { ContentModalHeaderProps } from './ContentModalHeader/ContentModalHeader';

type ContentModalProps<T = unknown> = T &
  Omit<DialogProps, 'onClose' | 'role'> & {
    isLoading?: boolean;
    onClose: (e?: SyntheticEvent, reason?: string) => void;
  };

function ContentModalRoot(props: ContentModalProps) {
  const { children, isLoading = false, maxWidth = 'sm', onClose, ...rest } = props;

  return (
    <Dialog fullWidth maxWidth={maxWidth} onClose={onClose as DialogProps['onClose']} {...rest} scroll="paper">
      <BackdropLoader isLoading={isLoading} />

      {children}
    </Dialog>
  );
}

const ContentModal = ContentModalRoot as ((props: ContentModalProps) => ReactElement) & {
  Header: (props: ContentModalHeaderProps) => ReactElement;
  Body: (props: DialogActionsProps) => ReactElement;
  Footer: (props: DialogActionsProps) => ReactElement;
};

ContentModal.Header = ContentModalHeader;
ContentModal.Body = ContentModalBody;
ContentModal.Footer = ContentModalFooter;

export type { ContentModalProps };
export default ContentModal;
