import React, { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { parseISO } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import Tooltip from '@mui/material/Tooltip';
import { DatePicker, DatePickerProps, DatePickerSlotsComponentsProps } from '@mui/x-date-pickers/DatePicker';

import { DEPRECATED_DEFAULT_DATE } from '@/constants';
import { getValidDateValue } from '@/utils';
import { deepMerge } from '@/utils/deepMerge';

import { DatepickerFieldResetIcon, DatepickerFieldRoot } from './DatepickerField.style';

interface FormDatepickerMemoizedProps
  extends Omit<DatePickerProps<Nullable<Date | string> | undefined>, 'onChange' | 'renderInput' | 'slotProps'> {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  invalidDateMessage?: string;
  helperText?: string;
  name?: string;
  error?: boolean;
  size?: BaseTextFieldProps['size'];
  variant?: BaseTextFieldProps['variant'];
  margin?: BaseTextFieldProps['margin'];
  color?: BaseTextFieldProps['color'];
  focused?: BaseTextFieldProps['focused'];
  InputProps?: Partial<StandardInputProps>;
  tooltip?: string;
  clearable?: boolean;
  onClear?: (e: ChangeEvent<HTMLInputElement>) => void;
  slotProps?: DatePickerSlotsComponentsProps<Nullable<string | Date>>;
}

function DatepickerField(props: FormDatepickerMemoizedProps) {
  const {
    value,
    invalidDateMessage = 'Неверный формат даты',
    error,
    name,
    onChange,
    helperText,
    size,
    color = 'primary',
    variant = 'standard',
    margin = 'none',
    focused,
    tooltip,
    minDate,
    clearable,
    onClear,
    slotProps = {},
    ...rest
  } = props;

  const inputValue = useMemo(() => {
    const result = value === DEPRECATED_DEFAULT_DATE ? null : value instanceof Date ? value.toISOString() : value;

    return result ? parseISO(result) : null;
  }, [value]);

  const minDateValue = useMemo(() => (minDate ? new Date(minDate) : undefined), [minDate]);

  const onChangeHandler = useCallback(
    (date: unknown) => {
      onChange?.({
        target: {
          name,
          value: getValidDateValue(date as Date),
        },
      } as unknown as ChangeEvent<HTMLInputElement>);
    },
    [name, onChange],
  );

  const onReset = useCallback(() => {
    const resetEvent = {
      target: {
        name: name ?? '',
        value: '',
      },
      currentTarget: {
        name: name ?? '',
        value: '',
      },
    } as ChangeEvent<HTMLInputElement>;

    onChange?.(resetEvent);
    onClear?.(resetEvent);
  }, [name, onChange, onClear]);

  const slotPropsParams = deepMerge<DatePickerSlotsComponentsProps<Nullable<string | Date> | undefined>>(
    {
      field: {
        InputProps: rest.InputProps,
      },
      textField: {
        color: tooltip ? 'warning' : color,
        error,
        focused: !!tooltip || focused,
        helperText: error ? invalidDateMessage : helperText,
        placeholder: 'ДД.ММ.ГГГГ',
        margin,
        size,
        variant,
        fullWidth: true,
      },
    },
    slotProps,
  );

  return (
    <Tooltip placement="top" title={tooltip ?? ''}>
      <DatepickerFieldRoot>
        <DatePicker
          minDate={minDateValue}
          onChange={onChangeHandler}
          slotProps={slotPropsParams}
          value={inputValue}
          {...rest}
        />

        {Boolean(clearable && value) && (
          <DatepickerFieldResetIcon>
            <IconButton onClick={onReset} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </DatepickerFieldResetIcon>
        )}
      </DatepickerFieldRoot>
    </Tooltip>
  );
}

export default memo(DatepickerField);
