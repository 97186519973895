import { format, isValid } from 'date-fns';
import { action, makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import {
  BIRTH_DATE_PART_DEFAULT,
  DATE_FOR_SERVER_FORMAT,
  DEFAULT_PAGINATION_LIMIT,
  DEPRECATED_DEFAULT_DATE,
  DocumentTypeEnumId,
} from '@/constants';
import { KSTransactionModel } from '@/entities/KSTransaction';
import { Store, store } from '@/stores/Store';
import { base64toBlob, getValidDateValue } from '@/utils';

import {
  CLEARABLE_FIELDS,
  DEFAULT_ID_CARD,
  DEFAULT_MIGRATION_CARD,
  DEFAULT_RIGHT_OF_STAY,
  EXPORT_POLLING_TIMEOUT,
  Gender,
  OperationType,
  PersonStatus,
  PrintImageType,
  RecognizeImageFieldName,
} from './PersonsStore.constants';
import {
  CopyPersonResponse,
  CreatePersonResponse,
  DocumentImage,
  ExportToXlsxValues,
  GetPersonsArguments,
  GroupTemplateValues,
  HMSLogusPerson,
  MigrationTemplateForm,
  OperationResponse,
  Person,
  PersonDetail,
  PersonDocument,
  PersonsResponse,
  PersonTemplate,
  RecognizedImageData,
  RecognizeImageResponse,
  StayInfo,
  VisaTemplateForm,
} from './PersonsStore.types';

export class PersonsStore {
  store: Store;
  selectedPersons = new Map();
  persons: Person[] = [];
  totalCount = 0;
  legalRepresentativePersons: Person[] = [];

  selectedDevice = 0;

  isScanning = false;
  isFetching = true;
  isExporting = false;
  isSaving = false;
  isPrinting = false;
  isCreatingTemplate = false;
  isRestoring = false;
  isFetchingLogus = false;

  private getPersonsAbortController?: AbortController = undefined;

  constructor(store: Store) {
    this.store = store;

    makeAutoObservable(this);
  }

  get total() {
    return this.totalCount;
  }

  get selectedIds() {
    return [...this.selectedPersons.keys()];
  }

  getValidFilterData = (query: Partial<Record<keyof GetPersonsArguments, string>>) => {
    const validatedFilterData: Partial<GetPersonsArguments> = {};

    const limit = query.limit && !isNaN(+query.limit) ? +query.limit : DEFAULT_PAGINATION_LIMIT;
    const page = query.page && !isNaN(+query.page) ? +query.page : 0;

    if (query.name) {
      validatedFilterData.name = query.name;
    }

    if (query.status && !isNaN(+query.status)) {
      if (
        +query.status === PersonStatus.Pending ||
        +query.status === PersonStatus.Accepted ||
        +query.status === PersonStatus.Exported
      ) {
        validatedFilterData.status = +query.status;
      }
    }

    if (query.citizenship) {
      validatedFilterData.citizenship = +query.citizenship;
    }

    if (query.creationFrom) {
      const creationFromDate = new Date(query.creationFrom);

      if (isValid(creationFromDate)) {
        const validCreationFromDate = getValidDateValue(creationFromDate);

        if (validCreationFromDate) {
          validatedFilterData.creationFrom = new Date(validCreationFromDate);
        }
      }
    }

    if (query.creationTo) {
      const creationToDate = new Date(query.creationTo);

      if (isValid(creationToDate)) {
        const validCreationToDate = getValidDateValue(creationToDate);

        if (validCreationToDate) {
          validatedFilterData.creationTo = new Date(validCreationToDate);
        }
      }
    }

    if (query.stayFrom) {
      const stayFromDate = new Date(query.stayFrom);

      if (isValid(stayFromDate)) {
        const validStayFromDate = getValidDateValue(stayFromDate);

        if (validStayFromDate) {
          validatedFilterData.stayFrom = new Date(validStayFromDate);
        }
      }
    }

    if (query.stayTo) {
      const stayToDate = new Date(query.stayTo);

      if (isValid(stayToDate)) {
        const validStayToDate = getValidDateValue(stayToDate);

        if (validStayToDate) {
          validatedFilterData.stayTo = new Date(validStayToDate);
        }
      }
    }

    if (query.doc_number) {
      validatedFilterData.doc_number = query.doc_number;
    }

    if (query.room_number) {
      validatedFilterData.room_number = query.room_number;
    }

    if (query.groupId) {
      validatedFilterData.groupId = query.groupId;
    }

    return {
      limit,
      page,
      ...validatedFilterData,
    } as GetPersonsArguments;
  };

  prepareDocumentBody = (document: Partial<PersonDocument>) => {
    const documentBody: Partial<PersonDocument> = {};

    Object.keys(document).forEach((documentKey: string) => {
      if (document[documentKey] || (Array.isArray(document[documentKey]) && document[documentKey].length > 0)) {
        if (documentKey === 'other') {
          return;
        }

        documentBody[documentKey] = document[documentKey];
      }
    });

    return documentBody as PersonDocument;
  };

  preparePersonBody = (values: PersonDetail) => {
    // данные скрипт нужно чтобы удалить незаполненные поля из реквеста, однако есть случаи когда
    // пустое поле должно уйти на бекенд, например когда мы очищаем заполненое поле

    const body: Partial<PersonDetail> = {};

    Object.keys(values).forEach((key: string) => {
      if (key === 'documents') {
        body.documents = values.documents.map(this.prepareDocumentBody);
      } else {
        if (values[key]) {
          switch (key) {
            case 'hotel_person_id':
              // всегда должен быть строкой
              body[key] = `${values[key]}`;

              break;
            case 'birth_date_part':
              if (values[key] !== BIRTH_DATE_PART_DEFAULT) {
                body[key] = values[key];
              }

              break;
            case 'stay_info':
              body[key] = values[key];

              if (!values[key].date_entry_rf && body[key]) {
                (body[key] as StayInfo).date_entry_rf = DEPRECATED_DEFAULT_DATE;
              }

              if (!values[key].visit_purpose_id) {
                (body[key] as StayInfo).visit_purpose_id = null;
              }

              break;
            default:
              body[key] = values[key];
          }
        } else {
          // поля в этой ветке условий могут быть отправлены пустыми, например когда очищаем поля и сохраняем
          if (CLEARABLE_FIELDS.has(key)) {
            body[key] = values[key];
          }
        }
      }
    });

    return body;
  };

  selectReservation = (id: number) => {
    const newSelected = new Map(this.selectedPersons);

    newSelected.has(id) ? newSelected.delete(id) : newSelected.set(id, true);

    this.selectedPersons = newSelected;
  };

  selectAllReservation = (settings?: { withEmail?: boolean }) => {
    const map = new Map();

    if (this.selectedPersons.size !== 0) {
      this.selectedPersons = map;

      return;
    } else {
      this.persons.forEach(({ id, email }: Person) => {
        if (settings?.withEmail && !email) {
          return;
        }

        map.set(id, true);
      });
    }

    this.selectedPersons = map;
  };

  resetAllSelectedPersons = () => {
    this.selectedPersons = new Map();
  };

  abortGetPersons = () => {
    this.getPersonsAbortController?.abort();
  };

  getPersons = (values: Partial<GetPersonsArguments>) => {
    const {
      groupId,
      limit,
      page,
      status,
      hms_status,
      name,
      creationFrom,
      creationTo,
      stayFrom,
      stayTo,
      room_number,
      doc_number,
      citizenship,
      show_deleted = false,
      hms_status_not_in,
    } = values;

    this.isFetching = true;
    this.getPersonsAbortController = new AbortController();

    return fetchApi
      .get<PersonsResponse>(
        '/persons/',
        {
          group_id: groupId,
          limit,
          offset: page && limit ? page * limit : undefined,
          status,
          hms_status,
          name,
          created_from: creationFrom ? format(new Date(creationFrom), DATE_FOR_SERVER_FORMAT) : undefined,
          created_to: creationTo ? format(new Date(creationTo), DATE_FOR_SERVER_FORMAT) : undefined,
          stay_from: stayFrom ? format(new Date(stayFrom), DATE_FOR_SERVER_FORMAT) : undefined,
          stay_to: stayTo ? format(new Date(stayTo), DATE_FOR_SERVER_FORMAT) : undefined,
          show_deleted,
          hms_status_not_in,
          country_id: citizenship,
          room_number,
          doc_number,
        },
        { abortController: this.getPersonsAbortController },
      )
      .then(
        action((response) => {
          this.persons = response.data;
          this.totalCount = response.count;

          return response;
        }),
      )
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  getPersonsPure = (values: Partial<GetPersonsArguments>) => {
    const {
      groupId,
      limit,
      page,
      status,
      hms_status,
      name,
      creationFrom,
      creationTo,
      stayFrom,
      stayTo,
      room_number,
      doc_number,
      citizenship,
      show_deleted = false,
      hms_status_not_in,
      legal_representative,
      shared_group_id,
    } = values;

    this.isFetching = true;

    return fetchApi
      .get<PersonsResponse>('/persons/', {
        group_id: groupId,
        limit,
        offset: page && limit ? page * limit : undefined,
        status,
        hms_status,
        name,
        created_from: creationFrom ? format(new Date(creationFrom), DATE_FOR_SERVER_FORMAT) : undefined,
        created_to: creationTo ? format(new Date(creationTo), DATE_FOR_SERVER_FORMAT) : undefined,
        stay_from: stayFrom ? format(new Date(stayFrom), DATE_FOR_SERVER_FORMAT) : undefined,
        stay_to: stayTo ? format(new Date(stayTo), DATE_FOR_SERVER_FORMAT) : undefined,
        show_deleted,
        hms_status_not_in,
        country_id: citizenship,
        room_number,
        doc_number,
        legal_representative,
        shared_group_id,
      })
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  setPersons = (persons: Person[]) => {
    this.persons = persons;
  };

  resetPersons = () => {
    this.setPersons([]);
  };

  getLegalRepresentativePersons = (groupId?: number) => {
    this.isFetching = true;

    return fetchApi
      .get<PersonsResponse>('/persons/', {
        group_id: groupId,
        legal_representative: true,
      })
      .then((response: PersonsResponse) => {
        runInAction(() => {
          this.legalRepresentativePersons = response.data;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  setSelectedStatus = (statusId: number) => {
    this.isExporting = true;

    return fetchApi
      .put(`/persons-status/${statusId}/`, {
        query: {
          id: this.selectedIds,
        },
      })
      .then(() => {
        runInAction(() => {
          this.persons = [...this.persons].map((person: Person) => {
            if (this.selectedPersons.has(person.id)) {
              person.status = statusId;

              return person;
            }

            return person;
          });

          this.selectedPersons = new Map();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isExporting = false;
        });
      });
  };

  restoreSelectedPersons = () => {
    this.isRestoring = true;

    const restorePromiseArray: Promise<void>[] = [];

    this.selectedIds.forEach((id: number) => {
      restorePromiseArray.push(fetchApi.patch(`/persons/${id}/`));
    });

    return Promise.all(restorePromiseArray)
      .then(() => {
        runInAction(() => {
          this.persons = [...this.persons].filter(({ id }: Person) => !this.selectedPersons.has(id));

          this.selectedPersons = new Map();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isRestoring = false;
        });
      });
  };

  deletePersons = (ids: number[] = []) => {
    this.isSaving = true;

    const deletionPromiseArray: Promise<void>[] = [];

    ids.forEach((id: number) => {
      deletionPromiseArray.push(fetchApi.remove(`/persons/${id}/`));
    });

    return Promise.all(deletionPromiseArray)
      .finally(() => {
        runInAction(() => {
          this.resetAllSelectedPersons();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  restorePersons = (ids: number[] = []) => {
    this.isRestoring = true;

    const restorePromiseArray: Promise<void>[] = [];

    ids.forEach((id: number) => {
      restorePromiseArray.push(fetchApi.patch(`/persons/${id}/`));
    });

    return Promise.all(restorePromiseArray)
      .finally(() => {
        runInAction(() => {
          this.resetAllSelectedPersons();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isRestoring = false;
        });
      });
  };

  printSelectedPersonsDocs = (typeIds: number[]) => {
    const printRequests = typeIds.map((id: number) =>
      fetchApi.print(`/persons-form-pdf/${id}/`, {
        query: {
          id: this.selectedIds,
        },
      }),
    );

    return Promise.all(printRequests).then();
  };

  printSelectedPersonsImages = (typeIds: number[]) => {
    const isHaveIdCard = typeIds.includes(PrintImageType.IdCard);
    const isHaveRightOfStay = typeIds.includes(PrintImageType.RightOfStay);
    const isHaveMigrationCard = typeIds.includes(PrintImageType.Migration);

    let id;

    if (isHaveIdCard) {
      id = PrintImageType.IdCard;
    }

    if (isHaveRightOfStay) {
      id = PrintImageType.RightOfStay;
    }

    if (isHaveMigrationCard) {
      id = PrintImageType.Migration;
    }

    if (isHaveIdCard && isHaveRightOfStay) {
      id = 6;
    }

    if (isHaveIdCard && isHaveMigrationCard) {
      id = 10;
    }

    if (isHaveRightOfStay && isHaveMigrationCard) {
      id = 12;
    }

    if (isHaveIdCard && isHaveRightOfStay && isHaveMigrationCard) {
      id = 14;
    }

    return fetchApi
      .print(`/persons-form-image-pdf/${id}/`, {
        query: {
          id: this.selectedIds,
        },
      })
      .then();
  };

  printImagesByPersonId = (id: string) => {
    this.isPrinting = true;

    return fetchApi
      .print('/persons-form-image-pdf/14/', {
        query: {
          id,
        },
      })
      .finally(() => {
        runInAction(() => {
          this.isPrinting = false;
        });
      });
  };

  printDocByPersonId = (personId: string, docId: StringOrNumber) => {
    this.isPrinting = true;

    return fetchApi
      .print(`/persons-form-pdf/${docId}/`, {
        query: {
          id: personId,
        },
      })
      .finally(() => {
        runInAction(() => {
          this.isPrinting = false;
        });
      });
  };

  setSelectedDevice = (deviceId: number) => {
    this.selectedDevice = deviceId;
  };

  getPersonById = async (id: number) =>
    Promise.all([
      fetchApi.get<PersonDetail>(`/persons/${id}/`),
      fetchApi.get<PersonDocument[]>(`/persons/${id}/documents/`),
    ]).then(([person, documents]) => ({
      ...(person as PersonDetail),
      documents: (documents as PersonDocument[]).map((document) => {
        if (document.document_type_enum_id === DEFAULT_ID_CARD.document_type_enum_id) {
          return {
            ...DEFAULT_ID_CARD,
            ...document,
          };
        }

        if (document.document_type_enum_id === DEFAULT_RIGHT_OF_STAY.document_type_enum_id) {
          return {
            ...DEFAULT_RIGHT_OF_STAY,
            ...document,
          };
        }

        return {
          ...DEFAULT_MIGRATION_CARD,
          ...document,
        };
      }),
    }));

  getManyDocumentsByPersonIds = async (ids: number[]) => {
    const documents = await Promise.all(ids.map((id: number) => this.getDocumentsById(id)));

    const result = {};

    documents.forEach((personDocs: PersonDocument[]) => {
      personDocs.forEach((document: PersonDocument) => {
        if (document.person_id) {
          if (result[document.person_id]) {
            result[document.person_id].push(document);
          } else {
            result[document.person_id] = [document];
          }
        }
      });
    });

    return result;
  };

  getPersonsByHisIdInHotel = (personHotelId: string) =>
    fetchApi
      .get<PersonsResponse>('/persons/', {
        offset: 0,
        limit: 1,
        hotel_person_id: personHotelId,
      })
      .then((persons: PersonsResponse) =>
        persons?.data?.find(({ hotel_person_id }: Person) => hotel_person_id === personHotelId),
      );

  getPersonHistory = (personHotelId: string) =>
    fetchApi.get<PersonsResponse>('/persons/', {
      hotel_person_id: personHotelId,
    });

  getDocumentsById = (id: number) => {
    this.isFetching = true;

    return fetchApi.get<PersonDocument[]>(`/persons/${id}/documents/`).finally(() => {
      runInAction(() => {
        this.isFetching = false;
      });
    });
  };

  getDocumentByOperationId = (documentId: number) =>
    fetchApi.get<PersonDocument>(`/operations/${documentId}/document/`);

  getImageById = (documentId: number, imageId: number) => {
    this.isFetching = true;

    return fetchApi
      .get<[Blob, Response]>(`/documents/${documentId}/images/${imageId}/`, {}, { responseType: 'blob' })
      .then(([image]: [Blob, Response]) => image)
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  removeImageInDocumentById = (documentId: number, imageId: number) =>
    fetchApi.remove(`/documents/${documentId}/images/${imageId}/`);

  getImageByOperationId = (id: number) =>
    fetchApi
      .get<[Blob, Response]>(`/operations/${id}/image/`, {}, { responseType: 'blob' })
      .then(([image]: [Blob, Response]) => image);

  createPerson = (values: PersonDetail) => {
    this.isSaving = true;

    return fetchApi
      .post<CreatePersonResponse>('/persons/', {
        query: {
          status_id: values.status,
        },
        body: { ...this.preparePersonBody(values) },
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  copyPerson = (id: number) => {
    this.isSaving = true;

    return fetchApi.post<CopyPersonResponse>(`/persons/${id}/copy/`).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };

  updatePerson = async (id: StringOrNumber, values: PersonDetail) => {
    this.isSaving = true;

    return fetchApi
      .put<CreatePersonResponse>(`/persons/${id}/`, { body: { ...this.preparePersonBody(values) } })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  setStatus = (id: number, statusId: number) => {
    this.isSaving = true;

    return fetchApi
      .put(`/persons/${id}/status/${statusId}/`)
      .then(() => {
        runInAction(() => {
          this.persons = [...this.persons].map((person: Person) => {
            if (person.id === id) {
              person.status = statusId;
            }

            return person;
          });
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  setStatusForManyPersons = (ids: number[], statusId: number) =>
    Promise.all(ids.map((id: number) => this.setStatus(id, statusId)));

  createGroupTemplate = (values: GroupTemplateValues) => {
    this.isCreatingTemplate = true;

    const fetchArray: Promise<void>[] = [];

    values.images.forEach((image: string) => {
      fetchArray.push(
        fetchApi.put('/persons-template/', {
          query: {
            id: this.selectedIds,
          },
          body: {
            document_type_enum_id: values.document_type_enum_id,
            image,
          },
        }),
      );
    });

    return Promise.all(fetchArray)
      .then(() => {
        runInAction(() => {
          this.selectedPersons = new Map();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isCreatingTemplate = false;
        });
      });
  };

  updatePersonsFromTemplate = (body: Partial<PersonTemplate>) => {
    this.isCreatingTemplate = true;

    return fetchApi
      .put('/persons-template/', {
        query: {
          id: this.selectedIds,
        },
        body,
      })
      .then(() => {
        runInAction(() => {
          this.selectedPersons = new Map();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isCreatingTemplate = false;
        });
      });
  };

  createTemplate = (values: GroupTemplateValues | VisaTemplateForm | MigrationTemplateForm) => {
    this.isCreatingTemplate = true;

    const body = { ...values };

    Object.keys(body).forEach((key: string) => {
      if (!body[key]) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete body[key];
      }
    });

    return fetchApi
      .put('/persons-template/', {
        query: {
          id: this.selectedIds,
        },
        body,
      })
      .then(() => {
        runInAction(() => {
          this.selectedPersons = new Map();
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isCreatingTemplate = false;
        });
      });
  };

  exportSelections = () =>
    fetchApi
      .download('/persons-export/', {
        query: {
          id: this.selectedIds,
        },
        method: 'GET',
      })
      .then(() => {
        this.persons.forEach((reservation) => {
          if (this.selectedPersons.has(reservation.id) && reservation.status !== PersonStatus.Forbidden) {
            reservation.status = PersonStatus.Exported;
          }
        });

        runInAction(() => {
          this.selectedPersons = new Map();
        });
      });

  setScanningState = (state: boolean) => {
    this.isScanning = state;
  };

  scan = async (opType: OperationType, image?: DocumentImage) => {
    if (opType === OperationType.Recognize && image?.data) {
      const formData = new FormData();
      const blob = await base64toBlob(image.data);

      if (blob) {
        formData.append('file', blob);

        return fetchApi
          .post<OperationResponse>(`/user/devices/${this.selectedDevice}/scan/`, {
            query: {
              op_type: opType,
            },
            body: formData,
            headers: { 'Content-Type': null },
          })
          .then(({ id }: OperationResponse) => id);
      }
    } else {
      return fetchApi
        .post<OperationResponse>(`/user/devices/${this.selectedDevice}/scan/`, {
          query: {
            op_type: opType,
          },
        })
        .then(({ id }: OperationResponse) => id);
    }

    return null;
  };

  operationPolling = (id: number, type: OperationType): Promise<OperationResponse> =>
    new Promise((resolve) => {
      function getOperationStatus(id: number) {
        setTimeout(async () => {
          const operationResponse = await fetchApi.get<OperationResponse>(`/operations/${id}/`);

          if (operationResponse.op_result === 1) {
            getOperationStatus(operationResponse.id);

            return;
          }

          if (type === OperationType.ScanSingleImage) {
            const [image] = await fetchApi.get<[Blob, Response]>(
              `/operations/${id}/image/`,
              {},
              { responseType: 'blob' },
            );

            resolve({
              ...operationResponse,
              file: image,
            });

            return;
          }

          resolve(operationResponse);
        }, EXPORT_POLLING_TIMEOUT);
      }

      getOperationStatus(id);
    });

  exportPersonsToXlsx = (values: ExportToXlsxValues) => {
    this.isExporting = true;

    return fetchApi.download('/persons-export-xlsx/', { query: { ...values }, method: 'GET' }).finally(() => {
      runInAction(() => {
        this.isExporting = false;
      });
    });
  };

  /*
   * Ниже в названиях функций Logus это имя отельной системы, с которой интегрируемся
   * */
  getLogusPerson = (personId: string) => {
    this.isFetchingLogus = true;

    return fetchApi.get<HMSLogusPerson[]>(`/persons/${personId}/logus-guests/`).finally(() => {
      runInAction(() => {
        this.isFetchingLogus = false;
      });
    });
  };

  syncLogusPerson = (personId: string, logusPerson: HMSLogusPerson) =>
    fetchApi.put<Response>(`/persons/${personId}/logus-guests/`, {
      body: { ...logusPerson },
    });

  printDocTemplate = (personId: string, docId: number, transactionId?: string) =>
    fetchApi.print(`/persons/${personId}/form-doc/${docId}/`, { query: { transaction_id: transactionId } });

  getTransactions = (personId: string) => fetchApi.get<KSTransactionModel[]>(`/ks-payment-transactions/${personId}/`);

  makeKSTransaction = (personId: StringOrNumber, id: number, amount: number) =>
    fetchApi.post<{ uid: string }>(`/ks-payment-transactions/${personId}/`, {
      body: { amount: amount, transaction_type: id },
    });

  recognizeImage = async (imageBase64: string) => {
    this.setScanningState(true);

    const response = await fetchApi.post<RecognizeImageResponse>('/api/process', {
      body: {
        processParam: {
          scenario: 'FullProcess',
          resultTypeOutput: [36, 37, 9],
          doublePageSpread: true,
          measureSystem: 0,
          dateFormat: 'yyyy-MM-dd',
        },
        List: [
          {
            ImageData: {
              image: imageBase64,
            },
            light: 6,
            page_idx: 0,
          },
        ],
      },
      headers: { 'Content-Type': 'application/json' },
      base: 'https://api.regulaforensics.com',
    });

    const result: Partial<RecognizedImageData> = {};

    if (response?.ContainerList?.List?.[0]?.OneCandidate?.FDSIDList?.dDescription) {
      if (response.ContainerList.List[0].OneCandidate.FDSIDList.dDescription === 'Certificate Of Citizenship') {
        result.document_type_enum_id = DocumentTypeEnumId.BirthCertificate; // свидетельство о рождении
      }
    }

    if (
      ['Russian Federation - eVisa Notification #1', 'Russian Federation - eVisa Notification #2'].includes(
        response?.ContainerList?.List?.[0]?.OneCandidate?.DocumentName,
      )
    ) {
      result.document_type_enum_id = DocumentTypeEnumId.DigitalVisa; // электронная виза
    }

    if (response?.ContainerList?.List?.[1]?.Text?.fieldList) {
      response.ContainerList.List[1].Text.fieldList
        .filter(({ fieldName }) => Object.values(RecognizeImageFieldName).includes(fieldName))
        // символ '^' - это перенос строки, мы заменяем их просто на пробелы
        .map((field) => ({ ...field, value: field.value.replaceAll('^', ' ') }))
        .forEach(({ fieldName, value, lcidName }) => {
          switch (fieldName) {
            case RecognizeImageFieldName.DateOfBirth:
              if (value && isValid(new Date(value))) {
                result.birth_date = getValidDateValue(new Date(value));
              }

              break;
            case RecognizeImageFieldName.DocumentNumber:
              /*
               * В строке удаляем все символы пробелов и тире (-),
               * потому что у разных документов приходят разные форматы значений
               * например:
               * - паспорт ХХХХХХХХХХ (значения серии и номера слитно)
               * - свидетельство о рождении ХХ-ХХ ХХХХХХ (значения серия и номер разделены)
               * */
              result.doc_number = value.replace('-', '').replace(' ', '').substring(4, value.length);

              if (lcidName === 'Russian') {
                result.doc_serial = value.split(' ')[0];
              }

              break;
            case RecognizeImageFieldName.DocumentSeries:
              if (!result.doc_serial) {
                result.doc_serial = value;
              }

              break;
            case RecognizeImageFieldName.GivenNames:
              if (lcidName === 'Russian') {
                result.fname = value;
              } else {
                result.fname_lat = value;
              }

              break;
            case RecognizeImageFieldName.FathersName:
              if (lcidName === 'Russian') {
                result.mname = value;
              } else {
                result.mname_lat = value;
              }

              break;
            case RecognizeImageFieldName.Surname:
              if (lcidName === 'Russian') {
                result.lname = value;
              } else {
                result.lname_lat = value;
              }

              break;
            case RecognizeImageFieldName.Sex:
              const valueLowerCase = value.toLowerCase();

              if (['жен', 'ж', 'женский', 'f'].includes(valueLowerCase)) {
                result.gender = Gender.Female;
              }

              if (['муж', 'м', 'мужской', 'm'].includes(valueLowerCase)) {
                result.gender = Gender.Male;
              }

              break;
            case RecognizeImageFieldName.DateOfIssue:
              if (value && isValid(new Date(value))) {
                result.doc_issue_date = getValidDateValue(new Date(value)) as string;
              }

              break;
            case RecognizeImageFieldName.Nationality:
              if (lcidName === 'Russian') {
                const countryByName = this.store.dictionariesStore.countries.find(
                  ({ name_ru }) => `${name_ru}`.toLowerCase() === `${value}`.toLowerCase(),
                );

                if (countryByName) {
                  result.citizenship = countryByName.id;
                }
              }

              break;
            case RecognizeImageFieldName.NationalityCode:
              const countryByCode = this.store.dictionariesStore.countries.find(({ code }) => code === value);

              if (countryByCode) {
                result.citizenship = countryByCode.id;
              }

              break;
            case RecognizeImageFieldName.PlaceOfBirth:
              result.birth_place = value;

              break;
            case RecognizeImageFieldName.AuthorityCode:
              result.doc_issue_code = value;

              break;
            case RecognizeImageFieldName.Authority:
            case RecognizeImageFieldName.PlaceOfRegistration:
              if (!result.doc_issue_authority) {
                result.doc_issue_authority = value;
              }

              break;
            case RecognizeImageFieldName.VisaNumber:
              result.doc_number = value;

              break;
            case RecognizeImageFieldName.VisaValidFrom:
              if (value && isValid(new Date(value))) {
                result.doc_start_date = getValidDateValue(new Date(value)) as string;
              }

              break;
            case RecognizeImageFieldName.DurationOfStay:
              result.visit_days = value;

              break;
            case RecognizeImageFieldName.Other:
              break;
            case RecognizeImageFieldName.VisaID:
              result.doc_id = value;

              break;
            case RecognizeImageFieldName.PurposeOfEntry:
              if (lcidName === 'Russian') {
                const visitPurpose = store.dictionariesStore.docVisaVisitTypes.find(
                  ({ name_ru }) => `${name_ru}`.toLowerCase() === `${value}`.toLowerCase(),
                );

                if (visitPurpose) {
                  result.visit_purpose_id = visitPurpose.id;
                }
              }

              break;
            case RecognizeImageFieldName.VisaType:
              if (lcidName === 'Russian') {
                const docVisaType = store.dictionariesStore.docVisaTypes.find(
                  ({ name_ru }) => `${name_ru}`.toLowerCase() === `${value}`.toLowerCase(),
                );

                if (docVisaType) {
                  result.doc_visa_type_id = docVisaType.id;
                }
              }

              break;
            case RecognizeImageFieldName.NumberOfEntries:
              if (lcidName === 'Russian') {
                const docVisaMultiType = store.dictionariesStore.docVisaMultiTypes.find(
                  ({ name_ru }) => `${name_ru}`.toLowerCase() === `${value}`.toLowerCase(),
                );

                if (docVisaMultiType) {
                  result.doc_visa_multi_type_id = docVisaMultiType.id;
                }
              }

              break;
            default:
              break;
          }
        });
    }

    if (response?.ContainerList?.List?.[2]?.Images?.fieldList) {
      response.ContainerList.List[2].Images.fieldList
        .filter(({ fieldName }) => Object.values(RecognizeImageFieldName).includes(fieldName))
        .forEach(({ valueList }) => {
          result.images = valueList.map(({ value }) => value);
        });
    }

    this.setScanningState(false);

    return result;
  };
}
