import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

export const TemplateModalTitle = styled(Typography)`
  padding: 0 24px;
  flex-grow: 1;
`;

export const TemplateModalContent = styled('div')`
  margin-top: 75px;
  padding: 8px;
  overflow: auto;
`;
