import React, { Fragment, memo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { observer } from 'mobx-react-lite';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { Address } from '@/entities/address';
import { OutcomingReport } from '@/shared/PrintForms/OutcomingReport/OutcomingReport';
import { useStore } from '@/stores/StoreProvider';

import { usePersonPageStore } from '../../../PersonPageStore';

function OutcomingReportButton() {
  const { person, stayInfo, isRussianCitizenship } = usePersonPageStore();
  const { userCompany } = useStore('signInStore');
  const { getStreetObjectById } = useStore('dictionariesStore');

  const componentRef = useRef(null);
  const onPrintHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [urAddress, setUrAddress] = useState<Nullable<Address>>(null);
  const [factAddress, setFactAddress] = useState<Nullable<Address>>(null);

  async function onPrint() {
    if (isRussianCitizenship) {
      return;
    }

    setIsLoading(true);

    try {
      if (userCompany?.company && stayInfo?.company_id) {
        const urAddress = await getStreetObjectById(
          userCompany.company.ur_fias_code2,
          userCompany.company.ur_region_code,
        );
        const factAddress = await getStreetObjectById(userCompany.company.fias_code2, userCompany.company.region_code);

        setUrAddress(urAddress);
        setFactAddress(factAddress);

        setTimeout(() => {
          onPrintHandler?.();

          // ждем пока проставятся все стейты и только потом запускаем принт
        }, 0);
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    setIsLoading(false);
  }

  return (
    <Fragment>
      <Tooltip
        title={isRussianCitizenship ? 'Доступно только для иностранных граждан' : 'Печать уведомления об убытии'}
      >
        <IconButton disabled={isLoading} onClick={onPrint}>
          <ExitToAppIcon />
        </IconButton>
      </Tooltip>

      <div style={{ display: 'none' }}>
        <OutcomingReport
          company={userCompany?.company ?? null}
          factAddress={factAddress}
          ref={componentRef}
          urAddress={urAddress}
          values={person}
        />
      </div>
    </Fragment>
  );
}

export default memo(observer(OutcomingReportButton));
