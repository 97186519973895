import grey from '@mui/material/colors/grey';
import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';

import { EMIS_THEME } from '@/App.style';

export const DocImagesRoot = styled('div')`
  flex-grow: 1;
`;

export const DocImagesControls = styled('div')`
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DocImagesImage = styled('div')(({ isHoverable }: { isHoverable?: boolean }) => {
  const hoverableStyles = css`
    &:hover {
      border-color: ${grey[900]};
    }
  `;

  return css`
    border: 1px solid ${grey[400]};
    border-radius: 7px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    width: 24%;
    height: 300px;

    ${EMIS_THEME.breakpoints.down('md')} {
      width: 48%;
      height: 450px;

      & img {
        width: 80%;
        height: auto;
      }
    }

    ${EMIS_THEME.breakpoints.down('sm')} {
      width: 100%;
      height: 450px;

      & img {
        width: auto;
        height: 80%;
      }
    }

    ${isHoverable && hoverableStyles}
  `;
});

export const DocImagesImageWrapper = styled('div')`
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const DocImagesDelete = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
`;
