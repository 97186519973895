import React, { ChangeEvent, Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import ReportsIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import ViewListIcon from '@mui/icons-material/ViewList';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { ROUTE_COMPANIES } from '@/constants';
import { Company } from '@/entities/company';
import Devices from '@/shared/Devices/Devices';
import GeneralCompanyInfo from '@/shared/GeneralCompanyInfo/GeneralCompanyInfo';
import RetentionData from '@/shared/RetentionData/RetentionData';
import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';
import Settings from '@/shared/Settings/Settings';
import Stats from '@/shared/Stats/Stats';
import Users from '@/shared/Users/Users';
import { useStore } from '@/stores/StoreProvider';

import { CompanyStats } from './CompanyPage.style';

function CompanyPage() {
  const { id } = useParams();
  const { companyByIdMap, getCompanyById } = useStore('companiesStore');

  const company: Company | undefined = id ? companyByIdMap.get(+id) : undefined;

  const statsWrapperRef = useRef<HTMLDivElement>(null);

  const [tab, setActiveTab] = useState(0);

  const [isCompanyFormTouched, setCompanyFormTouched] = useState(false);

  async function fetchCompanyById() {
    try {
      if (id) {
        await getCompanyById(id);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onChangeTab = useCallback(
    (e: ChangeEvent<unknown>, i: number) => {
      e.preventDefault();

      if (
        isCompanyFormTouched &&
        tab === 0 &&
        i !== 0 &&
        !confirm('Не сохраненные данные будут потеряны! Покинуть вкладку?')
      ) {
        return;
      }

      setActiveTab(i);
    },
    [tab, isCompanyFormTouched],
  );

  useEffect(() => {
    if (!company) {
      fetchCompanyById();
    }
  }, [company]);

  return (
    <RouteConstructor
      breadcrumbs={
        <BreadcrumbsWrapper
          crumbs={
            company
              ? [
                  {
                    title: 'Компании',
                    path: `${ROUTE_COMPANIES}${queryAPI.getQuery()}#company-${company.id}`,
                  },
                  `${company.name} ${company.ur_name && `(${company.ur_name})`}`,
                ]
              : null
          }
        />
      }
      content={
        company && (
          <Fragment>
            {tab === 0 && (
              <GeneralCompanyInfo company={company} isAdmin onToggleCompanyFormTouched={setCompanyFormTouched} />
            )}

            {tab === 1 && <Devices companyId={company.id} isAdmin />}

            {tab === 2 && <Users companyId={company.id} isAdmin />}

            {tab === 3 && (
              <CompanyStats ref={statsWrapperRef}>
                <Stats companyId={company.id} wrapperRef={statsWrapperRef} />
              </CompanyStats>
            )}

            {tab === 4 && <Settings companyId={company.id} />}

            {tab === 5 && <RetentionData companyId={company.id} />}
          </Fragment>
        )
      }
      header={
        <AppBar color="default" position="static">
          <Tabs indicatorColor="primary" onChange={onChangeTab} textColor="primary" value={tab}>
            <Tab icon={<ViewListIcon />} iconPosition="start" label="О компании" />

            <Tab icon={<ReportsIcon />} iconPosition="start" label="Устройства" />

            <Tab icon={<GroupIcon />} iconPosition="start" label="Пользователи" />

            <Tab icon={<ReportsIcon />} iconPosition="start" label="Статистика" />

            <Tab icon={<SecurityIcon />} iconPosition="start" label="Настройки" />

            <Tab icon={<StorageIcon />} iconPosition="start" label="Данные" />
          </Tabs>
        </AppBar>
      }
      isLoading={!company}
    />
  );
}

export default memo(observer(CompanyPage));
