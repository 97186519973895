import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import styled from '@mui/material/styles/styled';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

import { DatepickerField, SelectField } from '@/ui-kit/components';

export const FilterRawRoot = styled(TableCell)`
  background-color: ${common.white};
  border-bottom: 1px solid ${grey[300]};
`;

export const FilterRawStatus = styled(SelectField)`
  width: 90%;
  margin-right: 16px;
  margin-top: 14px;
`;

export const FilterRawStay = styled(DatepickerField)`
  margin-bottom: 5px;
`;

export const FilterCaption = styled(Typography)`
  padding: 12px;
`;
