import React, { ChangeEvent, memo, useCallback, useMemo } from 'react';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { observer } from 'mobx-react-lite';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { FIRST_NAME_IS_EMPTY_LABEL, LAST_NAME_IS_EMPTY_LABEL } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import GenderSelect from '@/shared/GenderSelect/GenderSelect';
import { Person, PersonDetail } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { DatepickerField, InputField, SelectField } from '@/ui-kit/components';
import { pluralizer } from '@/utils/pluralizer';

import { usePersonPageStore } from '../../PersonPageStore';

import {
  BasicPersonCaption,
  BasicPersonCell,
  BasicPersonLegalLabelIcon,
  BasicPersonSummary,
  BasicPersonTransliteration,
} from './BasicPersonData.style';
import BirthdayIcon from './BirthdayIcon/BirthdayIcon';
import LegalRepresentativeModal from './LegalRepresentativeModal/LegalRepresentativeModal';
import LegalRepresentativePerson from './LegalRepresentativePerson/LegalRepresentativePerson';

function BasicPersonData() {
  const {
    lastName,
    lastNameLat,
    firstName,
    firstNameLat,
    middleName,
    middleNameLat,
    birthDate,
    isLegalRepresentative,
    representPerson,
    legalRepresentativeTypeId,
    gender,
    hotelPersonId,
    stayFrom,
    stayTill,
    roomNumber,
    phone,
    email,
    visitPurposeId,
    isExported,
    isDeleted,
    warnings,
    personName,
    personAge,
    errors,
    personId,
    setPersonValues,
  } = usePersonPageStore();

  const { visitGoals } = useStore('dictionariesStore');

  const [expanded, , , onToggleExpanded] = useToggle(true);
  const [isLegalModal, , , onToggleLegalRepresentativeModal] = useToggle();

  const birthDateLabel = useMemo(() => {
    const result = 'Дата рождения';

    // возраст может быть 0, поэтому проверяем только "null"
    if (personAge === null) {
      return result;
    }

    return `${result} (${personAge} ${pluralizer(personAge, ['год', 'года', 'лет'])})`;
  }, [personAge]);

  const onToggleLegalRepresentative = useCallback(() => {
    const nextState = !isLegalRepresentative;

    setPersonValues({
      legal_representative: nextState,
      legal_representative_type_id: !nextState ? 0 : legalRepresentativeTypeId,
    });
  }, [isLegalRepresentative, legalRepresentativeTypeId]);

  const onSelectLegalRepresentative = useCallback((values: Nullable<Person>) => {
    if (values) {
      setPersonValues({
        legal_representative_id: values.id,
        represent_person: values,
      });
    }

    onToggleLegalRepresentativeModal();
  }, []);

  const onDeleteLegalRepresentative = useCallback(() => {
    setPersonValues({
      legal_representative_id: 0,
      represent_person: null,
    });
  }, []);

  const onFormInputChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;

    const nextValues = {
      [name]: value,
    } as Partial<PersonDetail>;

    if (name === 'birth_date') {
      nextValues.birth_date_part = '';
    }

    if (name === 'birth_date_part') {
      nextValues.birth_date = null;
    }

    if (name === 'stay_info.visit_purpose_id') {
      nextValues[name] = value ? +value : undefined;
    }

    setPersonValues(nextValues);
  }, []);

  const onTransliterateLastName = useCallback(() => {
    if (!lastName) {
      return;
    }

    setPersonValues({
      lname_lat: CyrillicToTranslit().transform(lastName).toUpperCase(),
    });
  }, [lastName]);

  const onTransliterateFirstName = useCallback(() => {
    if (!firstName) {
      return;
    }

    setPersonValues({
      fname_lat: CyrillicToTranslit().transform(firstName).toUpperCase(),
    });
  }, [firstName]);

  const onTransliterateMiddleName = useCallback(() => {
    if (!middleName) {
      return;
    }

    setPersonValues({
      mname_lat: CyrillicToTranslit().transform(middleName).toUpperCase(),
    });
  }, [middleName]);

  function onReverseTransliterateLastName() {
    setPersonValues({
      lname: CyrillicToTranslit({ preset: 'ru' }).reverse(lastNameLat).toUpperCase(),
    });
  }

  function onReverseTransliterateFirstName() {
    setPersonValues({
      fname: CyrillicToTranslit({ preset: 'ru' }).reverse(firstNameLat).toUpperCase(),
    });
  }

  function onReverseTransliterateMiddleName() {
    setPersonValues({
      mname: CyrillicToTranslit({ preset: 'ru' }).reverse(middleNameLat).toUpperCase(),
    });
  }

  return (
    <Accordion expanded={expanded} onChange={onToggleExpanded}>
      <BasicPersonSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Общие данные</Typography>

        {!expanded && personName.length > 0 && (
          <BasicPersonCaption>
            <Typography variant="caption">{personName}</Typography>
          </BasicPersonCaption>
        )}
      </BasicPersonSummary>

      <AccordionDetails>
        <fieldset disabled={isExported || isDeleted}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <BasicPersonCell item xs={3}>
                  <InputField
                    error={errors.lname}
                    label="Фамилия (рус)"
                    name="lname"
                    onChange={onFormInputChange}
                    value={lastName}
                  />

                  {!isDeleted && lastName.length > 0 && (
                    <BasicPersonTransliteration>
                      <Tooltip title="Транслит">
                        <IconButton onClick={onTransliterateLastName} size="small" type="button">
                          <KeyboardArrowDownIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </BasicPersonTransliteration>
                  )}
                </BasicPersonCell>

                <BasicPersonCell item xs={3}>
                  <InputField
                    error={errors.fname}
                    fullWidth
                    label="Имя (рус)"
                    name="fname"
                    onChange={onFormInputChange}
                    value={firstName}
                  />

                  {!isDeleted && firstName.length > 0 && (
                    <BasicPersonTransliteration>
                      <Tooltip title="Транслит">
                        <IconButton onClick={onTransliterateFirstName} size="small" type="button">
                          <KeyboardArrowDownIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </BasicPersonTransliteration>
                  )}
                </BasicPersonCell>

                <BasicPersonCell item xs={3}>
                  <InputField
                    error={errors.mname}
                    fullWidth
                    label="Отчество (рус)"
                    name="mname"
                    onChange={onFormInputChange}
                    value={middleName}
                  />

                  {!isDeleted && middleName.length > 0 && (
                    <BasicPersonTransliteration>
                      <Tooltip title="Транслит">
                        <IconButton onClick={onTransliterateMiddleName} size="small" type="button">
                          <KeyboardArrowDownIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </BasicPersonTransliteration>
                  )}
                </BasicPersonCell>

                <Grid item xs={3}>
                  <DatepickerField
                    InputProps={{
                      startAdornment: <BirthdayIcon />,
                    }}
                    disableFuture
                    disabled={isExported || isDeleted}
                    error={errors.birth_date}
                    label={birthDateLabel}
                    name="birth_date"
                    onChange={onFormInputChange}
                    tooltip={warnings.birth_date}
                    value={birthDate}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <BasicPersonCell item xs={3}>
                  <InputField
                    error={errors.lname_lat}
                    fullWidth
                    label="Фамилия (лат)"
                    name="lname_lat"
                    onChange={onFormInputChange}
                    value={lastNameLat === LAST_NAME_IS_EMPTY_LABEL ? '' : lastNameLat}
                  />

                  {!isDeleted && lastNameLat !== LAST_NAME_IS_EMPTY_LABEL && lastNameLat.length > 0 && (
                    <BasicPersonTransliteration>
                      <Tooltip title="Транслит">
                        <IconButton onClick={onReverseTransliterateLastName} size="small" type="button">
                          <KeyboardArrowUpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </BasicPersonTransliteration>
                  )}
                </BasicPersonCell>

                <BasicPersonCell item xs={3}>
                  <InputField
                    error={errors.fname_lat}
                    label="Имя (лат)"
                    name="fname_lat"
                    onChange={onFormInputChange}
                    value={firstNameLat === FIRST_NAME_IS_EMPTY_LABEL ? '' : firstNameLat}
                  />

                  {!isDeleted && firstNameLat !== FIRST_NAME_IS_EMPTY_LABEL && firstNameLat && (
                    <BasicPersonTransliteration>
                      <Tooltip title="Транслит">
                        <IconButton onClick={onReverseTransliterateFirstName} size="small" type="button">
                          <KeyboardArrowUpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </BasicPersonTransliteration>
                  )}
                </BasicPersonCell>

                <BasicPersonCell item xs={3}>
                  <InputField
                    error={errors.mname_lat}
                    label="Отчество (лат)"
                    name="mname_lat"
                    onChange={onFormInputChange}
                    value={middleNameLat}
                  />

                  {!isDeleted && middleNameLat.length > 0 && (
                    <BasicPersonTransliteration>
                      <Tooltip title="Транслит">
                        <IconButton onClick={onReverseTransliterateMiddleName} size="small" type="button">
                          <KeyboardArrowUpIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </BasicPersonTransliteration>
                  )}
                </BasicPersonCell>

                <Grid item xs={3}>
                  <GenderSelect
                    disabled={isExported || isDeleted}
                    error={errors.gender}
                    onChange={onFormInputChange}
                    value={gender}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <DatepickerField
                    disabled={isExported || isDeleted}
                    error={errors.stay_from}
                    label="Дата заезда"
                    name="stay_info.stay_from"
                    onChange={onFormInputChange}
                    tooltip={warnings.stay_from}
                    value={stayFrom}
                  />
                </Grid>

                <Grid item xs={3}>
                  <DatepickerField
                    disabled={isExported || isDeleted}
                    error={errors.stay_from}
                    label="Время заезда"
                    name="stay_info.stay_from"
                    onChange={() => ({})}
                    tooltip={warnings.stay_from}
                    value={stayFrom}
                  />
                </Grid>

                <Grid item xs={3}>
                  <DatepickerField
                    disabled={isExported || isDeleted}
                    error={errors.stay_till}
                    label="Дата выезда"
                    name="stay_info.stay_till"
                    onChange={onFormInputChange}
                    tooltip={warnings.stay_till}
                    value={stayTill}
                  />
                </Grid>

                <Grid item xs={3}>
                  <DatepickerField
                    disabled={isExported || isDeleted}
                    error={errors.stay_till}
                    label="Время выезда"
                    name="stay_info.stay_till"
                    onChange={() => ({})}
                    tooltip={warnings.stay_till}
                    value={stayTill}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <InputField
                    error={errors.room_number}
                    label="Номер комнаты"
                    maxLength={9}
                    name="stay_info.room_number"
                    onChange={onFormInputChange}
                    value={roomNumber}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    error={errors.tel}
                    label="Телефон"
                    name="tel"
                    onChange={onFormInputChange}
                    value={phone}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    error={errors.email}
                    label="Email"
                    name="email"
                    onChange={onFormInputChange}
                    value={email}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    clearable
                    error={errors.hotel_person_id}
                    label="Системный ID"
                    name="hotel_person_id"
                    onChange={onFormInputChange}
                    value={hotelPersonId}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <SelectField
                    clearable
                    disabled={visitGoals.length === 0}
                    label="Цель поездки"
                    name="stay_info.visit_purpose_id"
                    onChange={onFormInputChange}
                    value={visitPurposeId ?? ''}
                  >
                    {visitGoals.map(({ id, name_ru }) => (
                      <MenuItem key={id} value={id}>
                        {name_ru}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>

                <Grid item xs={3}>
                  <DatepickerField
                    label="Дата въезда в РК"
                    name="stay_info.date_entry_rf"
                    onChange={() => ({})}
                    tooltip={warnings.date_entry_rf}
                  />
                </Grid>

                <Grid item xs={3}>
                  <DatepickerField
                    label="Дата выезда из РК"
                    name="stay_info.date_entry_rf"
                    onChange={() => ({})}
                    tooltip={warnings.date_entry_rf}
                  />
                </Grid>

                {!isDeleted && !isExported && (
                  <Grid item xs={3}>
                    <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                      <Grid item xs={4}>
                        <Tooltip
                          title={
                            representPerson
                              ? 'Для данного гостя уже выбран законный представитель'
                              : 'Является законным представителем'
                          }
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isLegalRepresentative}
                                color="primary"
                                disabled={!!representPerson}
                                name="represent_person"
                                onClick={onToggleLegalRepresentative}
                              />
                            }
                            label={<BasicPersonLegalLabelIcon color="primary" />}
                          />
                        </Tooltip>
                      </Grid>

                      {!isLegalRepresentative && !representPerson && (
                        <Grid item xs={2}>
                          <Tooltip title="Добавить законного представителя">
                            <IconButton color="primary" onClick={onToggleLegalRepresentativeModal} size="small">
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}

                      {isLegalRepresentative && (
                        <Grid item xs={8}>
                          <SelectField
                            error={errors.legal_representative_type_id}
                            label="Кем приходится"
                            name="legal_representative_type_id"
                            onChange={onFormInputChange}
                            value={legalRepresentativeTypeId}
                          >
                            <MenuItem value={0}>-</MenuItem>

                            <MenuItem value={1}>Мать</MenuItem>

                            <MenuItem value={2}>Отец</MenuItem>

                            <MenuItem value={3}>Другая степень отношения</MenuItem>

                            <MenuItem value={4}>Опекун</MenuItem>

                            <MenuItem value={5}>Попечитель</MenuItem>
                          </SelectField>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                {!isDeleted && !isExported && (
                  <Grid item xs={12}>
                    {representPerson && (
                      <LegalRepresentativePerson
                        onDelete={onDeleteLegalRepresentative}
                        onEdit={onToggleLegalRepresentativeModal}
                        person={representPerson}
                      />
                    )}

                    <LegalRepresentativeModal
                      currentPersonId={personId}
                      legalRepresentative={representPerson}
                      onClose={onToggleLegalRepresentativeModal}
                      onSelect={onSelectLegalRepresentative}
                      open={isLegalModal}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </fieldset>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(observer(BasicPersonData));
