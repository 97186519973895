import React, { ChangeEvent, memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { DATE_HUMAN_FORMAT } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { Person } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, ContentModal, DatepickerField, InputField } from '@/ui-kit/components';

import { usePersonPageStore } from '../../../PersonPageStore';

import { PersonsList, PersonsRaw } from './LegalRepresentativeModal.style';

interface LegalRepresentativeModalProps {
  currentPersonId?: string;
  open: boolean;
  onClose: () => void;
  onSelect: (person: Nullable<Person>) => void;
  legalRepresentative?: Nullable<Person>;
}

function LegalRepresentativeModal(props: LegalRepresentativeModalProps) {
  const { currentPersonId, open, onClose, legalRepresentative, onSelect } = props;
  const { roomNumber, stayFrom, stayTill } = usePersonPageStore();

  const { getPersonsPure } = useStore('personsStore');

  const [isFetching, , , toggleFetching] = useToggle();
  const [values, setValues] = useState({
    name: '',
    room: roomNumber ?? '',
    stayFrom: stayFrom ?? null,
    stayTo: stayTill ?? null,
  });

  const [legalPersons, setLegalPersons] = useState<Person[]>([]);

  async function fetchPersons(values: Record<string, string | null>) {
    toggleFetching();

    try {
      const response = await getPersonsPure({
        name: values.name as string,
        stayFrom: values.stayFrom ? new Date(values.stayFrom as string) : null,
        stayTo: values.stayTo ? new Date(values.stayTo as string) : null,
        room_number: values.room as string,
        limit: 50,
        page: 0,
        legal_representative: true,
      });

      setLegalPersons(response.data);
    } catch (e) {
      notificationAPI.error(e);
    }

    toggleFetching();
  }

  function onSubmit(e: SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();

    fetchPersons(values);
  }

  function onReset(e: SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();

    setValues({
      name: '',
      room: '',
      stayFrom: null,
      stayTo: null,
    });

    setLegalPersons([]);
  }

  const onChangeInput = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setValues((prevValues) => ({
      ...prevValues,
      [target.name]: target.value,
    }));
  }, []);

  const onResetInput = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      fetchPersons({
        ...values,
        [target.name]: ['stayFrom', 'stayTo'].includes(target.name) ? null : '',
      });
    },
    [values, fetchPersons],
  );

  useEffect(() => {
    if (open) {
      fetchPersons(values);
    }
  }, [open]);

  return (
    <ContentModal disableEscapeKeyDown={false} maxWidth="md" onClose={onClose} open={open}>
      <ContentModal.Header onClose={onClose}>Добавить законного представителя</ContentModal.Header>

      <ContentModal.Body>
        {open && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <form onReset={onReset} onSubmit={onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <InputField
                      clearable
                      label="Фамилия Имя"
                      name="name"
                      onChange={onChangeInput}
                      onClear={onResetInput}
                      value={values.name}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <InputField
                      clearable
                      label="Комната"
                      name="room"
                      onChange={onChangeInput}
                      onClear={onResetInput}
                      value={values.room}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <DatepickerField
                      clearable
                      disableFuture
                      label="Срок пребывания, от"
                      name="stayFrom"
                      onChange={onChangeInput}
                      onClear={onResetInput}
                      value={values.stayFrom}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <DatepickerField
                      clearable
                      label="Срок пребывания, до"
                      minDate={values.stayFrom}
                      name="stayTo"
                      onChange={onChangeInput}
                      onClear={onResetInput}
                      value={values.stayTo}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton size="small" type="submit">
                      <SearchIcon fontSize="small" />
                    </IconButton>

                    <IconButton size="small" type="reset">
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <PersonsList item xs={12}>
              <Grid container>
                <BackdropLoader isLoading={isFetching} />

                {legalPersons.length === 0 && (
                  <Grid item xs={12}>
                    <Typography align="center">Нет данных</Typography>
                  </Grid>
                )}

                {legalPersons
                  .filter(({ id }: Person) => (currentPersonId ? id !== +currentPersonId : true))
                  .map((legalPerson: Person) => (
                    <PersonsRaw
                      isActive={legalRepresentative?.id === legalPerson.id}
                      item
                      key={legalPerson.id}
                      onClick={() => onSelect(legalPerson)}
                      xs={12}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <span>{legalPerson.fname}</span>
                          &nbsp;
                          <span>{legalPerson.lname}</span>
                        </Grid>

                        <Grid item xs={2}>
                          {legalPerson.stay_info.room_number}
                        </Grid>

                        <Grid item xs={3}>
                          {legalPerson.stay_info.stay_from
                            ? format(new Date(legalPerson.stay_info.stay_from), DATE_HUMAN_FORMAT)
                            : null}
                        </Grid>

                        <Grid item xs={1}>
                          {legalPerson.stay_info.stay_till
                            ? format(new Date(legalPerson.stay_info.stay_till), DATE_HUMAN_FORMAT)
                            : null}
                        </Grid>
                      </Grid>
                    </PersonsRaw>
                  ))}
              </Grid>
            </PersonsList>
          </Grid>
        )}
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose}>
          Отмена
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(LegalRepresentativeModal));
