import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ROUTE_MAIN } from '@/constants';

function NotFound() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Запрашиваемая вами страница не найдена. Перейти на <Link to={ROUTE_MAIN}>главную</Link>.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo(NotFound);
