import styled from '@mui/material/styles/styled';

import { EMIS_THEME } from '@/App.style';

export const SelfCheckInRoot = styled('div')`
  margin: 0 auto;
  padding: 84px 16px 90px;
  position: relative;
  width: 100%;
  max-width: 1024px;

  ${EMIS_THEME.breakpoints.up('md')} {
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
`;

export const SelfCheckInControl = styled('div')`
  margin-top: 60px;
`;

export const SelfCheckInSteps = styled('div')`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelfCheckInStepsAction = styled('div')`
  display: flex;
  flex-grow: 1;
`;

export const SelfCheckInNext = styled('div')`
  margin-left: 34px;
`;
