import { makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import { License, LicenseModel } from '@/entities/license';

export class DeviceLicensesStore {
  licenses: License[] = [];
  isFetching = false;
  isSaving = false;

  constructor() {
    makeAutoObservable(this);
  }

  getLicenses = (companyId?: number) => {
    this.isFetching = true;

    return fetchApi
      .get<LicenseModel[]>('/device-licenses/', {
        company_id: companyId,
      })
      .then((response) => {
        response.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;

          return 0;
        });

        runInAction(() => {
          this.licenses = response.map((license) => new License(license));
        });

        return response;
      })
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  addLicense = (company_id: number, serial_number: string, serial_code: string) => {
    this.isSaving = true;

    return fetchApi
      .post('/device-licenses/', {
        body: {
          company_id,
          serial_number,
          serial_code,
        },
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  deleteLicense = (licenseId: number) => {
    this.isSaving = true;

    return fetchApi.remove(`/device-licenses/${licenseId}/`).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };
}
