import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';

import { ConfirmModalProps } from './ConfirmModal';

export const ConfirmModalActions = styled('div')(({
  actionsAlign,
}: {
  actionsAlign: ConfirmModalProps['actionsAlign'];
}) => {
  const commonStyles = css`
    width: 100%;
    display: flex;
    align-items: center;
  `;

  if (actionsAlign === 'left') {
    return css`
      ${commonStyles};
      justify-content: flex-start;

      & > * {
        margin-right: 12px;
      }
    `;
  }

  if (actionsAlign === 'right') {
    return css`
      ${commonStyles};
      justify-content: flex-end;

      & > * {
        margin-left: 12px;
      }
    `;
  }

  return css`
    ${commonStyles};
    justify-content: center;

    & > .MuiButton-root {
      margin-right: 24px;
      margin-left: 24px;
    }
  `;
});
