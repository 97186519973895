import React, { PureComponent } from 'react';
import { format, isValid } from 'date-fns';

import { Citizen, DATE_HUMAN_FORMAT, DEPRECATED_DEFAULT_DATE, DocumentType } from '@/constants';
import { Address, AddressLevel } from '@/entities/address';
import { Company } from '@/entities/company';
import { DictionaryBasicWithCode } from '@/entities/dictionary';
import { Face } from '@/entities/face';
import { Gender, PersonDetail } from '@/stores/PersonsStore';
import { getDocumentByType } from '@/utils';

import { PRINT_STYLES } from './IncomingReport.constants';

interface ReportToPrintContentProps {
  values: Nullable<PersonDetail>;
  company?: Nullable<Company>;
  urAddress?: Nullable<Address>;
  factAddress?: Nullable<Address>;
  prevAddress?: Nullable<Address>;
  citizenship?: DictionaryBasicWithCode;
  face?: Nullable<Face>;
}

// библиотека `react-to-print` работает только с классами, поэтому отключаем тут правило линтера
// eslint-disable-next-line react/prefer-stateless-function
class IncomingReport extends PureComponent<ReportToPrintContentProps> {
  render() {
    const { values, company, urAddress, citizenship, factAddress, face } = this.props;

    if (!values) {
      return null;
    }

    const {
      fname,
      lname,
      mname,
      birth_date,
      birth_date_part,
      stay_info,
      gender,
      country,
      documents,
      tel,
      profession,
      represent_person,
      // reg_info,
      arrival_form_number,
    } = values;

    const isRussian = citizenship?.id === Citizen.Russia || citizenship?.id === Citizen.USSR;

    const [idCard] = getDocumentByType(documents, DocumentType.IdCard);
    const [rightOfStay] = getDocumentByType(documents, DocumentType.RightOfStay);
    const [migrationCard] = getDocumentByType(documents, DocumentType.Migration);

    const dateOfBirth = birth_date ? new Date(birth_date) : null;
    const stayFromDate =
      stay_info?.date_entry_rf && stay_info.date_entry_rf !== DEPRECATED_DEFAULT_DATE
        ? new Date(stay_info.date_entry_rf)
        : null;
    const stayTillDate =
      stay_info?.stay_till && stay_info.stay_till !== DEPRECATED_DEFAULT_DATE ? new Date(stay_info.stay_till) : null;
    const room = stay_info?.room_number ?? '';

    // собираем данные для ЮРИДИЧЕСКОГО адреса отеля
    // const urRegion = urAddress?.parents?.find((address: Address) =>
    //   address.ao_level === AddressLevel.Region ? address : undefined
    // );
    // const urRegionValue = urRegion ? `${urRegion.name}` : null;

    // const urPlace = urAddress?.parents?.find((address: Address) =>
    //   address.ao_level === AddressLevel.Area ? address : undefined
    // );
    // const urPlaceValue = urPlace ? `${urPlace.short_name} ${urPlace.name}` : null;

    // const urCity = urAddress?.parents?.find((address: Address) =>
    //   address.ao_level === AddressLevel.City ? address : undefined
    // );
    // const urCityValue = urCity ? `${urCity.short_name} ${urCity.name}` : null;
    //
    // const urStreetValue = urAddress ? `${urAddress?.short_name} ${urAddress?.name}` : null;

    // собираем данные для ФАКТИЧЕСКОГО адреса отеля
    const factRegion = factAddress?.parents?.find((address: Address) =>
      address.ao_level === AddressLevel.Region ? address : undefined,
    );
    const isMoscowHotel = factRegion?.region_code === '77';
    const isStPetersburgHotel = factRegion?.region_code === '78';
    const factRegionValue = factRegion && !isMoscowHotel && !isStPetersburgHotel ? `${factRegion.name}` : null;

    const factPlace = factAddress?.parents?.find((address: Address) =>
      address.ao_level === AddressLevel.Area ? address : undefined,
    );
    const factPlaceValue = factPlace ? `${factPlace.short_name} ${factPlace.name}` : null;

    const factCity = factAddress?.parents?.find((address: Address) =>
      address.ao_level === AddressLevel.City ? address : undefined,
    );

    let factCityValue = factCity ? `${factCity.short_name}. ${factCity.name}` : null;

    if (isMoscowHotel) {
      factCityValue = 'Москва';
    }

    if (isStPetersburgHotel) {
      factCityValue = 'Санкт-Петербург';
    }

    const factStreetValue = factAddress ? `${factAddress?.short_name}. ${factAddress?.name}` : null;

    const birthPlace = idCard?.birth_place ?? idCard?.birth_place2 ?? '';
    const representPersonValue = (() => {
      let result = '';

      if (represent_person?.lname) {
        result += represent_person.lname;
      }

      if (represent_person?.fname) {
        result += ` ${represent_person.fname}`;
      }

      if (represent_person?.mname) {
        result += ` ${represent_person.mname}`;
      }

      if (represent_person?.birth_date && isValid(new Date(represent_person?.birth_date))) {
        result += `, ${format(new Date(represent_person.birth_date), DATE_HUMAN_FORMAT)}`;
      } else if (birth_date_part) {
        result += `, ${parseInt(
          `${birth_date_part[3]}${birth_date_part[4]}${birth_date_part[6]}${birth_date_part[7]}${birth_date_part[8]}${birth_date_part[9]}`,
        )}`;
      }

      return result;
    })();

    const fullAddressValue = `${urAddress?.full_name} ${company?.ur_housing ? `Д. ${company?.ur_housing}` : ''}${
      company?.ur_housing2 ? `, КОР. ${company?.ur_housing2}` : ''
    }${company?.housing3 ? `, СТР. ${company?.housing3}` : ''}`;

    // const prevAddressValue = (() => {
    //   let result = '';
    //
    //   if (prevAddress?.full_name) {
    //     result += prevAddress?.full_name;
    //   }
    //
    //   if (reg_info?.prev_housing) {
    //     result += ` Д. ${reg_info?.prev_housing}`;
    //   }
    //
    //   if (reg_info?.prev_housing2) {
    //     result += ` КОР. ${reg_info?.prev_housing2}`;
    //   }
    //
    //   if (reg_info?.prev_flat) {
    //     result += ` КВ. ${reg_info?.prev_flat}`;
    //   }
    //
    //   return result;
    // })();
    const prevAddressValue = '';

    const visitPurposeId = migrationCard ? migrationCard.visit_purpose_id : stay_info?.visit_purpose_id;

    const passport = isRussian ? 'паспорт' : 'ин.паспорт';

    const apart_doc = company?.apart_doc ? company?.apart_doc : '';

    let phone = tel;

    if (phone) {
      if (phone.startsWith('+7')) {
        phone = phone.substring(2);
      } else if (phone.startsWith('8') && phone.length === 11) {
        phone = phone.substring(1);
      }
    }

    let companyTel = company?.tel;

    if (companyTel) {
      if (companyTel.startsWith('+7')) {
        companyTel = companyTel.substring(2);
      } else if (companyTel.startsWith('8') && companyTel.length === 11) {
        companyTel = companyTel.substring(1);
      }
    }

    return (
      <div>
        {/* СТРАНИЦА 1 */}

        <div style={PRINT_STYLES.page}>
          {/* памятка в верху справа */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontSize: 11,
            }}
          >
            <div>
              <div>
                <span>Приложение №4</span>

                <br />

                <span>к приказу МВД России</span>

                <br />

                <span>от 10.12.2020 № 856</span>
              </div>

              <div style={{ marginTop: 5 }}>
                <span>
                  (в ред. Приказа МВД России
                  <br />
                  от 16.11.2022 №867)
                </span>
              </div>
            </div>
          </div>

          {/* заголовки */}

          <h1
            style={{
              ...PRINT_STYLES.title1,
              textAlign: 'right',
              margin: '15px 0',
            }}
          >
            &nbsp;
          </h1>

          <h2 style={PRINT_STYLES.title1}>
            <span>УВЕДОМЛЕНИЕ О ПРИБЫТИИ ИНОСТРАННОГО ГРАЖДАНИНА</span>

            <br />

            <span>
              <span>ИЛИ ЛИЦА БЕЗ ГРАЖДАНСТВА В МЕСТО ПРЕБЫВАНИЯ </span>

              <span style={PRINT_STYLES.bottomLineCell}>{arrival_form_number}</span>
            </span>
          </h2>

          {/* сведения о лице, подлежащем снятию с учета по месту пребывания */}

          {/* заголовок */}

          <h2
            style={{
              ...PRINT_STYLES.title2,
              margin: '15px 0 12px',
              whiteSpace: 'nowrap',
            }}
          >
            1. Сведения о лице, подлежащем постановке на учет по месту пребывания
          </h2>

          <div style={PRINT_STYLES.pageContent}>
            {/* фамилия */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 69,
                }}
              >
                Фамилия
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 27 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {lname[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* имя */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 69,
                }}
              >
                Имя
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 27 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {fname[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* отчество */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                }}
              >
                <span>Отчество</span>

                <br />

                <span>(при их наличии)</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 24 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {mname[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* гражданство, подданство */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>Гражданство,</span>

                <br />

                <span>подданство</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {citizenship ? citizenship.name_ru[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* блок с датой рождения и полом */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 115,
                }}
              >
                Дата рождения:
              </div>

              <div style={PRINT_STYLES.cell}>число</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 2 }, (_, i: number) => {
                  const date = dateOfBirth?.getDate() ?? null;

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -2,
                }}
              >
                месяц
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 2 }, (_, i: number) => {
                  // если dateOfBirth не указан, проверяем наличие birth_date_part
                  // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                  // на символы 3 и 4 мы берем значение месяца
                  let month: Nullable<number> = null;

                  if (dateOfBirth) {
                    month = dateOfBirth.getMonth();
                  } else if (birth_date_part) {
                    month = parseInt(`${birth_date_part[3]}${birth_date_part[4]}`) - 1;
                  }

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -3,
                }}
              >
                год
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -2,
                }}
              >
                {Array.from({ length: 4 }, (_, i: number) => {
                  // если dateOfBirth не указан, проверяем наличие birth_date_part
                  // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                  // на символы 6, 7, 8, и 9 мы берем значение года
                  let year: Nullable<number> = null;

                  if (dateOfBirth) {
                    year = dateOfBirth.getFullYear();
                  } else if (birth_date_part) {
                    year = parseInt(
                      `${birth_date_part[6]}${birth_date_part[7]}${birth_date_part[8]}${birth_date_part[9]}`,
                    );
                  }

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {year ? `${year}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -3,
                }}
              >
                Пол:
              </div>

              <div style={PRINT_STYLES.cell}>мужской</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -2,
                }}
              >
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {gender === Gender.Male && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 2,
                }}
              >
                женский
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 3,
                }}
              >
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {gender === Gender.Female && <span>&#10004;</span>}
                  </div>
                ))}
              </div>
            </div>

            {/* место рождения */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                }}
              >
                <span>Место рождения:</span>

                <br />

                <span>государство</span>

                <br />

                <span>город или другой</span>

                <br />

                <span>населенный пункт</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                {/* на первой линии печатается только страна */}

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {country?.name_ru?.[i] ?? ''}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {birthPlace[i] ?? ''}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {/* `24` - потому что предыдущая линия составила 24 ячейки */}

                      {birthPlace[24 + i] ?? ''}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* документ, удостоверяющий личность */}

            <h3 style={PRINT_STYLES.title3}>Документ, удостоверяющий личность:</h3>

            {/* вид, серия, номер */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 48,
                }}
              >
                вид
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 10 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {passport[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>серия</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 1,
                }}
              >
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {idCard?.doc_serial?.[i] ?? ''}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>№</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginRight: -1,
                }}
              >
                {Array.from({ length: 11 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {idCard?.doc_number?.[i] ?? ''}
                  </div>
                ))}
              </div>
            </div>

            {/* дата выдачи и срок действия */}

            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ width: '44%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Дата выдачи:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      width: 48,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = idCard?.doc_issue_date ? new Date(idCard.doc_issue_date).getDate() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = idCard?.doc_issue_date ? new Date(idCard.doc_issue_date).getMonth() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -2,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = idCard?.doc_issue_date ? new Date(idCard.doc_issue_date).getFullYear() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div style={{ width: '56%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Срок действия до:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: 10,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = idCard?.doc_expire_date ? new Date(idCard.doc_expire_date).getDate() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = idCard?.doc_expire_date ? new Date(idCard.doc_expire_date).getMonth() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = idCard?.doc_expire_date ? new Date(idCard.doc_expire_date).getFullYear() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    textAlign: 'center',
                    marginLeft: -40,
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                  }}
                >
                  (заполняется в случае ограничения срока действия документа)
                </div>
              </div>
            </div>

            {/* документ о праве прибывания */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  fontSize: 14,
                }}
              >
                Вид и реквизиты документа, подтверждающего право на пребывание (проживание) в Российской Федерации:
              </div>
            </div>

            {/* право прибывания */}

            <div style={PRINT_STYLES.line}>
              <div style={PRINT_STYLES.cell}>Виза</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {(rightOfStay?.document_type_enum_id === 33 || rightOfStay?.document_type_enum_id === 43) && (
                      <span>&#10004;</span>
                    )}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Вид на жительство</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {(rightOfStay?.document_type_enum_id === 31 || rightOfStay?.document_type_enum_id === 32) && (
                      <span>&#10004;</span>
                    )}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Разрешение на временное пребывание</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {(rightOfStay?.document_type_enum_id === 34 || rightOfStay?.document_type_enum_id === 35) && (
                      <span>&#10004;</span>
                    )}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Разрешение на временное проживание в целях получения образования</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            {/* серия и номер права прибывания */}

            <div style={PRINT_STYLES.line}>
              <div style={PRINT_STYLES.cell}>серия</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {rightOfStay?.doc_serial?.[i] ?? ''}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  margin: '0 4px',
                }}
              >
                №
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 15 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {rightOfStay?.doc_number?.[i] ?? ''}
                  </div>
                ))}
              </div>
            </div>

            {/* дата выдачи и срок действия */}

            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ width: '44%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Дата выдачи:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      width: 48,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = rightOfStay?.doc_issue_date ? new Date(rightOfStay.doc_issue_date).getDate() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = rightOfStay?.doc_issue_date
                        ? new Date(rightOfStay.doc_issue_date).getMonth()
                        : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -2,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = rightOfStay?.doc_issue_date
                        ? new Date(rightOfStay.doc_issue_date).getFullYear()
                        : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div style={{ width: '56%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Срок действия до:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: 10,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = rightOfStay?.doc_expire_date
                        ? new Date(rightOfStay.doc_expire_date).getDate()
                        : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = rightOfStay?.doc_expire_date
                        ? new Date(rightOfStay.doc_expire_date).getMonth()
                        : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = rightOfStay?.doc_expire_date
                        ? new Date(rightOfStay.doc_expire_date).getFullYear()
                        : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    textAlign: 'center',
                    marginLeft: -40,
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                  }}
                >
                  (заполняется в случае ограничения срока действия документа)
                </div>
              </div>
            </div>

            {/* цель вьезда */}

            <div style={PRINT_STYLES.line}>
              <div style={PRINT_STYLES.cell}>Цель въезда:</div>

              <div style={PRINT_STYLES.cell}>служебная</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {visitPurposeId === 1 && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>туризм</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {visitPurposeId === 2 && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>деловая</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {visitPurposeId === 3 && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>учеба</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {visitPurposeId === 4 && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>работа</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {visitPurposeId === 5 && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>частная</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {visitPurposeId === 6 && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>транзит</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {visitPurposeId === 7 && <span>&#10004;</span>}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 60,
                }}
              >
                гуманитарная
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {visitPurposeId === 8 && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>иная</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {[11, 13, 14, 15, 10, 9].includes(visitPurposeId as number) && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 65,
                }}
              >
                Телефон (при наличии)
              </div>

              <div style={PRINT_STYLES.cell}>
                <span>+7&nbsp;</span>

                {Array.from({ length: 10 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {phone?.[i] ?? ''}
                  </div>
                ))}
              </div>
            </div>

            {/* профессия */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 90,
                }}
              >
                <span>Профессия</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 26 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {profession[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* дата вьезда и срок пребывания до */}

            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ width: '44%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Дата въезда:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      width: 48,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = stayFromDate?.getDate();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = stayFromDate?.getMonth();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -2,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = stayFromDate?.getFullYear();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div style={{ width: '56%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Срок пребывания до:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: 10,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = stayTillDate?.getDate();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = stayTillDate?.getMonth();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = stayTillDate?.getFullYear();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            {/* миграционная карта */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 179,
                }}
              >
                Миграционная карта:
              </div>

              <div style={PRINT_STYLES.cell}>серия</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {migrationCard?.doc_serial?.[i] ?? ''}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>№</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 11 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {migrationCard?.doc_number?.[i] ?? ''}
                  </div>
                ))}
              </div>
            </div>

            {/* сведенья о законных представителях */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>Сведения о</span>

                <br />

                <span>законных</span>

                <br />

                <span>представителях</span>

                <br />

                <span>(родителях,</span>

                <br />

                <span>усыновителях</span>

                <br />

                <span>попечителях)</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {representPersonValue[i]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {representPersonValue[i + 25]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {representPersonValue[i + 50]}
                    </div>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: 34,
                    textAlign: 'center',
                  }}
                >
                  (при заполнении указываются фамилия, имя, отчество (при их наличии), дата рождения)
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={PRINT_STYLES.pageBreak} />

        {/* СТРАНИЦА 2 */}

        <div style={PRINT_STYLES.page}>
          {/* памятка в верху справа */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontSize: 12,
            }}
          >
            <div>Оборотная сторона первого листа</div>
          </div>

          <div style={PRINT_STYLES.pageContent}>
            {/* адрес прежнего места пребывания */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Адрес прежнего места</span>

                <br />

                <span>пребывания</span>

                <br />

                <span>в Российской</span>

                <br />

                <span>Федерации</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {prevAddressValue[i]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {prevAddressValue[i + 24]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {prevAddressValue[i + 24 * 2]}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 30 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {prevAddressValue[i + 24 * 3]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    fontSize: 12,
                  }}
                >
                  (заполняется в случае прибытия в новое место пребывания в период последнего въезда в Российскую
                  Федерацию)
                </div>
              </div>
            </div>

            {/* сведения о месте пребывания */}

            {/* заголовок */}

            <h2
              style={{
                ...PRINT_STYLES.title2,
                margin: '10px 0 5px',
              }}
            >
              2. Сведения о месте пребывания
            </h2>

            {/* область */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Область, край,</span>

                <br />

                <span>республика</span>

                <br />

                <span>автономный округ</span>

                <br />

                <span>(область)</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {factRegionValue?.[i]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {factRegionValue?.[i + 25]}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* район */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Район</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factPlaceValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* город */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Город или другой</span>

                <br />

                <span>населенный пункт</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 24 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factCityValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* улица */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Улица</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factStreetValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* дом, строение */}

            <div style={PRINT_STYLES.line}>
              <div style={PRINT_STYLES.cell}>
                <div
                  style={{
                    ...PRINT_STYLES.charSquare,
                    width: 152,
                  }}
                >
                  Дом
                </div>

                {Array.from({ length: 8 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing[i]}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -4,
                }}
              >
                Корпус
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -3,
                }}
              >
                {Array.from({ length: 5 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing2[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Строение</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 5 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing3?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* подпись */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    fontSize: 12,
                  }}
                >
                  Дом, участок, владение и иное (заполнить согласно документу, подтверждающему право собственности)
                </div>
              </div>
            </div>

            {/* пустое поле */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div style={PRINT_STYLES.cell}>
                <div
                  style={{
                    ...PRINT_STYLES.charSquare,
                    width: 175,
                  }}
                >
                  комната
                </div>

                {Array.from({ length: 5 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {stay_info?.room_number?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* подпись */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    fontSize: 12,
                  }}
                >
                  Квартира, комната, офис и иное (заполнить согласно документу, подтверждающему право собственности)
                </div>
              </div>
            </div>

            {/* место пребывания */}

            <div style={PRINT_STYLES.line}>
              <div style={PRINT_STYLES.cell}>Место пребывания:</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Жилое помещение</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Иное помещение</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    &#10004;
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Организация</div>
            </div>

            {/* подпись */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    fontSize: 12,
                  }}
                >
                  (необходимо выбрать нужное)
                </div>
              </div>
            </div>

            {/* документ права пользования */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 195,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Наименование и реквизиты</span>

                <br />

                <span>документа, подтверждающего</span>

                <br />

                <span>право пользования</span>

                <br />

                <span>помещением (строением,</span>

                <br />

                <span>сооружение) (указывается</span>

                <br />

                <span>при наличии)</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 21 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {apart_doc[i]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 21 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {apart_doc[i + 21]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 21 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {apart_doc[i + 42]}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* фактическое место нахождения */}

            <h3 style={PRINT_STYLES.title3}>Фактическое место нахождения</h3>

            {/* подпись */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    fontSize: 12,
                  }}
                >
                  <span>(заполняется в случае, предусмотренном частью 2 статьи 21 Федерального закона</span>

                  <br />

                  <span>
                    &quot;О миграционном учете иностранных граждан и лиц без гражданства в Российской Федерации&quot;)
                  </span>
                </div>
              </div>
            </div>

            {/* область */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Область, край,</span>

                <br />

                <span>республика</span>

                <br />

                <span>автономный округ</span>

                <br />

                <span>(область)</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>
              </div>
            </div>

            {/* район */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Район</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            {/* город */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 174,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Город, сельское поселение,</span>

                <br />

                <span>иной населенный пункт</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 22 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            {/* кадастровый номер */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 174,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Кадастровый номер</span>

                <br />

                <span>земельного или лесного</span>

                <br />

                <span>участка (указывается</span>

                <br />

                <span>при наличии)</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 22 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 22 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>
              </div>
            </div>

            {/* отметка о подтверждении */}

            <div
              style={{
                ...PRINT_STYLES.line,
                justifyContent: 'flex-end',
                marginRight: 14,
              }}
            >
              <div style={PRINT_STYLES.cell}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 360,
                    alignContent: 'stretch',
                  }}
                >
                  <div
                    style={{
                      ...PRINT_STYLES.charSquare,
                      height: 175,
                      width: 'auto',
                    }}
                  />

                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: 'center',
                      fontSize: 12,
                    }}
                  >
                    <span>Отметка о подтверждении выполнения принимающей</span>

                    <br />

                    <span>стороной и иностранным гражданином или лицом без</span>

                    <br />

                    <span>гражданства действий, необходимых для его постановки</span>

                    <br />

                    <span>на учет по месту пребывания</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={PRINT_STYLES.pageBreak} />

        {/* СТРАНИЦА 3 */}

        <div style={PRINT_STYLES.page}>
          <div style={PRINT_STYLES.pageContent}>
            {/* сведения о принимающей стороне */}

            {/* заголовок */}

            <h2 style={PRINT_STYLES.title2}>3. Сведения о принимающей стороне</h2>

            {/* подпись */}

            <div
              style={{
                ...PRINT_STYLES.line,
                marginTop: -7,
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    fontSize: 12,
                  }}
                >
                  <span>
                    (не заполняется в случае, предусмотренном частью 3.1 статьи 22 Федерального закона &quot;О
                    миграционном учете
                  </span>

                  <br />

                  <span>иностранных граждан и лиц без гражданства в Российской Федерации&quot;)</span>
                </div>
              </div>
            </div>

            {/* тип принимающего лица */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  fontSize: 12,
                }}
              >
                Для принимающей стороны - организация заполняется ответственным лицом&nbsp;&nbsp;
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  fontSize: 12,
                }}
              >
                Организация
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    &#10004;
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  fontSize: 12,
                }}
              >
                Физическое лицо
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            {/* фамилия */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 69,
                }}
              >
                Фамилия
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 27 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_lname[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* имя */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 69,
                }}
              >
                Имя
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 27 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_fname[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* отчество */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 174,
                }}
              >
                Отчество (при их наличии)
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 22 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_mname[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* документ, удостоверяющий личность */}

            <div
              style={{
                ...PRINT_STYLES.line,
                textAlign: 'center',
              }}
            >
              <div style={PRINT_STYLES.cell}>Документ, удостоверяющий личность:</div>
            </div>

            {/* вид, серия, номер */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 48,
                }}
              >
                вид
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 10 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {'паспорт'[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>серия</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 1,
                }}
              >
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_doc_serial[i] : ''}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>№</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginRight: -1,
                }}
              >
                {Array.from({ length: 11 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_doc_number[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* дата выдачи и срок действия */}

            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ width: '44%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Дата выдачи:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      width: 48,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = face?.face_doc_issue_date ? new Date(face?.face_doc_issue_date).getDate() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      let month: Nullable<number> = null;

                      if (face?.face_doc_issue_date) {
                        month = new Date(face?.face_doc_issue_date).getMonth();
                      }

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -2,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const date = face?.face_doc_issue_date ? new Date(face?.face_doc_issue_date) : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date.getFullYear()}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div style={{ width: '56%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Срок действия до:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      width: 48,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => (
                      // const date = face?.face_doc_valid_date ? new Date(face?.face_doc_valid_date).getDate() : null;

                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {/* {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''} */}
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => (
                      // let month = null;

                      // if (face?.face_doc_valid_date) {
                      //   month = new Date(face?.face_doc_valid_date).getMonth();
                      // }

                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {/* {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}*/}
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -2,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => (
                      // const date = face?.face_doc_valid_date ? new Date(face?.face_doc_valid_date) : null;

                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {/* {date ? `${date.getFullYear()}`[i] : ''} */}
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    textAlign: 'center',
                    marginLeft: -40,
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                  }}
                >
                  (заполняется в случае ограничения срока действия документа)
                </div>
              </div>
            </div>

            {/* сведения о месте жительства */}

            {/* заголовок */}

            <h3 style={PRINT_STYLES.title3}>Место жительства</h3>

            {/* область */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Область, край,</span>

                <br />

                <span>республика</span>

                <br />

                <span>автономный округ</span>

                <br />

                <span>(область)</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {face?.face_regplace1?.[i]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {face?.face_regplace1?.[i + 25]}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* район */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Район</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face?.face_regplace2?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* город */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Город или другой</span>

                <br />

                <span>населенный пункт</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 24 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face?.face_regplace3?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* улица */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Улица</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face?.face_regstreet?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* дом, строение */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -4,
                }}
              >
                Дом
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -3,
                }}
              >
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face?.face_reghouse1?.[i]}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -4,
                }}
              >
                Корпус
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -3,
                }}
              >
                {Array.from({ length: 5 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face?.face_reghouse2?.[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Строение</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face?.face_reghouse3?.[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Квартира</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face?.face_regflat?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* линия отрыва */}

            <div>
              <div
                style={{
                  border: '2px dashed black',
                  margin: '4px 0 2px',
                }}
              />

              <div style={{ marginTop: -15, textAlign: 'center' }}>
                <span
                  style={{
                    backgroundColor: 'white',
                    padding: '0 10px',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  Линия отрыва
                </span>
              </div>
            </div>

            {/* подтверждение */}

            {/* подпись */}

            <div style={PRINT_STYLES.line}>
              <div style={PRINT_STYLES.cell}>Настоящим подтверждается, что:</div>
            </div>

            {/* фамилия */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 69,
                }}
              >
                Фамилия
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 27 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {lname[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* имя */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 69,
                }}
              >
                Имя
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 27 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {fname[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* отчество */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 174,
                }}
              >
                Отчество (при их наличии)
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 22 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {mname[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* гражданство, подданство */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>Гражданство,</span>

                <br />

                <span>подданство</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {citizenship ? citizenship.name_ru[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* блок с датой рождения и полом */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 115,
                }}
              >
                Дата рождения:
              </div>

              <div style={PRINT_STYLES.cell}>число</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 2 }, (_, i: number) => {
                  const date = dateOfBirth?.getDate() ?? null;

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -2,
                }}
              >
                месяц
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 2 }, (_, i: number) => {
                  // если dateOfBirth не указан, проверяем наличие birth_date_part
                  // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                  // на символы 3 и 4 мы берем значение месяца
                  let month: Nullable<number> = null;

                  if (dateOfBirth) {
                    month = dateOfBirth.getMonth();
                  } else if (birth_date_part) {
                    month = parseInt(`${birth_date_part[3]}${birth_date_part[4]}`) - 1;
                  }

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -3,
                }}
              >
                год
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -2,
                }}
              >
                {Array.from({ length: 4 }, (_, i: number) => {
                  // если dateOfBirth не указан, проверяем наличие birth_date_part
                  // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                  // на символы 6, 7, 8, и 9 мы берем значение года
                  let year: Nullable<number> = null;

                  if (dateOfBirth) {
                    year = dateOfBirth.getFullYear();
                  } else if (birth_date_part) {
                    year = parseInt(
                      `${birth_date_part[6]}${birth_date_part[7]}${birth_date_part[8]}${birth_date_part[9]}`,
                    );
                  }

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {year ? `${year}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -3,
                }}
              >
                Пол:
              </div>

              <div style={PRINT_STYLES.cell}>мужской</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -2,
                }}
              >
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {gender === Gender.Male && <span>&#10004;</span>}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 2,
                }}
              >
                женский
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 3,
                }}
              >
                {Array.from({ length: 1 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {gender === Gender.Female && <span>&#10004;</span>}
                  </div>
                ))}
              </div>
            </div>

            {/* место рождения */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                }}
              >
                <span>Место рождения:</span>

                <br />

                <span>государство</span>

                <br />

                <span>город или другой</span>

                <br />

                <span>населенный пункт</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                {/* на первой линии печатается только страна */}

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {country?.name_ru?.[i] ?? ''}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {birthPlace[i] ?? ''}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 24 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {/* `24` - потому что предыдущая линия составила 24 ячейки */}

                      {birthPlace[24 + i] ?? ''}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* документ, удостоверяющий личность */}

            <h3
              style={{
                ...PRINT_STYLES.title3,
                marginTop: -3,
              }}
            >
              Документ, удостоверяющий личность
            </h3>

            {/* вид, серия, номер */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 48,
                }}
              >
                вид
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 10 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {passport[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>серия</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 1,
                }}
              >
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {idCard?.doc_serial?.[i] ?? ''}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>№</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginRight: -1,
                }}
              >
                {Array.from({ length: 11 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {idCard?.doc_number?.[i] ?? ''}
                  </div>
                ))}
              </div>
            </div>

            {/* дата выдачи и срок действия */}

            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ width: '44%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Дата выдачи:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      width: 48,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = idCard?.doc_issue_date ? new Date(idCard.doc_issue_date).getDate() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = idCard?.doc_issue_date ? new Date(idCard.doc_issue_date).getMonth() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -2,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = idCard?.doc_issue_date ? new Date(idCard.doc_issue_date).getFullYear() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div style={{ width: '56%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Срок действия до:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: 10,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = idCard?.doc_expire_date ? new Date(idCard.doc_expire_date).getDate() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = idCard?.doc_expire_date ? new Date(idCard.doc_expire_date).getMonth() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = idCard?.doc_expire_date ? new Date(idCard.doc_expire_date).getFullYear() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{
                    textAlign: 'center',
                    marginLeft: -40,
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                  }}
                >
                  (заполняется в случае ограничения срока действия документа)
                </div>
              </div>
            </div>

            {/* место пребывания */}

            <div
              style={{
                ...PRINT_STYLES.line,
                textAlign: 'center',
              }}
            >
              <div style={PRINT_STYLES.cell}>
                в установленном порядке уведомил о прибытии в место пребывания по адресу:
              </div>
            </div>

            {/* область */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Область, край,</span>

                <br />

                <span>республика</span>

                <br />

                <span>автономный округ</span>

                <br />

                <span>(область)</span>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                }}
              >
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {factRegionValue?.[i]}
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 25 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {factRegionValue?.[i + 25]}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* район */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Район</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factPlaceValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* город */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Город или другой</span>

                <br />

                <span>населенный пункт</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 24 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factCityValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* улица */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                  whiteSpace: 'nowrap',
                }}
              >
                <span>Улица</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factStreetValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* дом, строение */}

            <div style={PRINT_STYLES.line}>
              <div style={PRINT_STYLES.cell}>
                <div
                  style={{
                    ...PRINT_STYLES.charSquare,
                    width: 152,
                  }}
                >
                  Дом
                </div>

                {Array.from({ length: 8 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing[i]}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -4,
                }}
              >
                Корпус
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: -3,
                }}
              >
                {Array.from({ length: 5 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing2[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Строение</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 5 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing3?.[i]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    fontSize: 12,
                  }}
                >
                  Дом, участок, владение и иное (заполнить согласно документу, подтверждающему право собственности)
                </div>
              </div>
            </div>

            {/* пустое поле */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div style={PRINT_STYLES.cell}>
                <div
                  style={{
                    ...PRINT_STYLES.charSquare,
                    width: 175,
                  }}
                >
                  Комната
                </div>

                {Array.from({ length: 5 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {room[i]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    fontSize: 12,
                  }}
                >
                  Квартира, комната, офис и иное (заполнить согласно документу, подтверждающему право собственности)
                </div>
              </div>
            </div>

            {/* дата выдачи и срок действия */}

            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ width: '56%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    textAlign: 'center',
                  }}
                >
                  Заявленный срок пребывания до:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: 10,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = stayTillDate?.getDate();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    месяц
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -1,
                    }}
                  >
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const month = stayTillDate?.getMonth();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    год
                  </div>

                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginLeft: -3,
                    }}
                  >
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const year = stayTillDate?.getFullYear();

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {year ? `${year}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <h1
              style={{
                ...PRINT_STYLES.title2,
                textAlign: 'center',
              }}
            >
              отрывная часть бланка уведомления о прибытии иностранного гражданина или лица без гражданства в место
              пребывания
            </h1>
          </div>
        </div>

        <div style={PRINT_STYLES.pageBreak} />

        {/* СТРАНИЦА 4 */}

        <div style={PRINT_STYLES.page}>
          {/* памятка в верху справа */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontSize: 12,
            }}
          >
            <div>Оборотная сторона второго листа</div>
          </div>

          <div style={PRINT_STYLES.pageContent}>
            {/* телефон */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <div>
                  <span>Телефон</span>

                  <br />

                  <span>(при наличии)</span>
                </div>
              </div>

              <div style={PRINT_STYLES.cell}>
                <div>+7&nbsp;</div>

                {Array.from({ length: 10 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {companyTel?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* организация */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>Наименование организации</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.ur_name[i]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 17 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.ur_name[i + 25]}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 10,
                }}
              >
                <span>ИНН</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 11 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.inn[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* Адрес организации */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>Адрес организации</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {fullAddressValue[i]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 30 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {fullAddressValue[i + 25]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 30 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {fullAddressValue[i + 25 + 30]}
                  </div>
                ))}
              </div>
            </div>

            {/* блок для печатей */}

            <div
              style={{
                ...PRINT_STYLES.line,
                marginTop: 10,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                  marginRight: 3,
                  width: '58%',
                }}
              >
                <div
                  style={{
                    ...PRINT_STYLES.title1,
                    fontWeight: 'bold',
                  }}
                >
                  <span>ДОСТОВЕРНОСТЬ ПРЕДОСТАВЛЕННЫХ СВЕДЕНИЙ,</span>

                  <br />

                  <span>А ТАКЖЕ СОГЛАСИЕ НА ФАКТИЧЕСКОЕ ПРОЖИВАНИЕ</span>

                  <br />

                  <span>(НАХОЖДЕНИЕ) У МЕНЯ ИНОСТРАННОГО ГРАЖДАНИНА</span>

                  <br />

                  <span>ПОДТВЕРЖДАЮ:</span>
                </div>

                <div
                  style={{
                    ...PRINT_STYLES.charSquare,
                    height: 50,
                    margin: '0 auto',
                    width: '70%',
                  }}
                />

                <div style={{ textAlign: 'center' }}>
                  <span>Подпись принимающей стороны или иностранного гражданина</span>

                  <br />

                  <span>или лица без гражданства, в случаях, предусмотренных</span>

                  <br />

                  <span>частями 3.1, 3.2, 4 статьи 22 Федерального закона о</span>

                  <br />

                  <span>
                    &quot;О миграционном учете иностранных граждан и лиц без гражданства в Российской Федерации&quot;
                  </span>
                </div>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                  width: '38.75%',
                }}
              >
                <div
                  style={{
                    ...PRINT_STYLES.charSquare,
                    height: 125,
                    width: '100%',
                  }}
                />

                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    fontSize: 12,
                  }}
                >
                  <span>Печать организации</span>

                  <br />

                  <span>(при наличии)</span>
                </div>
              </div>
            </div>

            {/* линия отрыва */}

            <div
              style={{
                margin: '50px 0 0',
              }}
            >
              <div
                style={{
                  border: '2px dashed black',
                  margin: '5px 0',
                }}
              />

              <div style={{ marginTop: -15, textAlign: 'center' }}>
                <span
                  style={{
                    backgroundColor: 'white',
                    padding: '0 10px',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  Линия отрыва
                </span>
              </div>
            </div>

            <h3 style={PRINT_STYLES.title3}>
              <span>Для принимающей стороны либо иностранного гражданина или лица без гражданства в случае,</span>

              <br />

              <span>предусмотренном часть 3.1 статьи 22 Федерального закона &quot;О миграционном учете</span>

              <br />

              <span>иностранных граждан и лиц без гражданства в Российской Федерации&quot;</span>
            </h3>

            {/* данные принимающей стороны */}

            {/* фамилия */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 69,
                }}
              >
                Фамилия
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 27 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_lname[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* имя */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 69,
                }}
              >
                Имя
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 27 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_fname[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* отчество */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 132,
                }}
              >
                <span>Отчество</span>

                <br />

                <span>(при их наличии)</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 24 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_mname[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* наименование организации */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>Наименование</span>

                <br />

                <span>организации</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.ur_name[i]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 17 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.ur_name[i + 25]}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 10,
                }}
              >
                <span>ИНН</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 11 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.inn[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* отметка о подтверждении */}

            <div
              style={{
                ...PRINT_STYLES.line,
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginRight: 14,
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    ...PRINT_STYLES.charSquare,
                    width: '95%',
                    height: 50,
                  }}
                />

                <div style={{ textAlign: 'center', width: '80%' }}>
                  <span>Подпись принимающей стороны либо</span>

                  <br />

                  <span>иностранного гражданина или лица без</span>

                  <br />

                  <span>гражданства, в случаях, предусмотренных</span>

                  <br />

                  <span>частями 3.1, 3.2, 4 статьи 22 Федерального</span>

                  <br />

                  <span>закона о &quot;О миграционном учете иностранных</span>

                  <br />

                  <span>граждан и лиц без гражданства в Российской Федерации&quot;</span>
                </div>

                <div
                  style={{
                    ...PRINT_STYLES.charSquare,
                    width: '95%',
                    height: 150,
                  }}
                />

                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    fontSize: 12,
                    width: '80%',
                  }}
                >
                  <span>Печать организации</span>

                  <br />

                  <span>(при наличии)</span>
                </div>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  flexShrink: 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 335,
                    alignContent: 'stretch',
                  }}
                >
                  <div
                    style={{
                      ...PRINT_STYLES.charSquare,
                      height: 175,
                      width: 'auto',
                    }}
                  />

                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: 'center',
                      fontSize: 12,
                    }}
                  >
                    <span>Отметка о подтверждении выполнения принимающей</span>

                    <br />

                    <span>стороной и иностранным гражданином или лицом без</span>

                    <br />

                    <span>гражданства действий, необходимых для его постановки</span>

                    <br />

                    <span>на учет по месту пребывания</span>
                  </div>
                </div>
              </div>
            </div>

            <h1
              style={{
                ...PRINT_STYLES.title1,
                textAlign: 'center',
              }}
            >
              отрывная часть бланка уведомления о прибытии иностранного гражданина или лица без гражданства в место
              пребывания
            </h1>
          </div>
        </div>

        <div style={PRINT_STYLES.pageBreak} />
      </div>
    );
  }
}

export { IncomingReport };
