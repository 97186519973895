import React, { memo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Person } from '@/stores/PersonsStore';
import { getPersonName } from '@/utils/getPersonName';

interface LegalRepresentativePersonProps {
  person: Person;
  onEdit: () => void;
  onDelete: () => void;
}

function LegalRepresentativePerson(props: LegalRepresentativePersonProps) {
  const { person, onDelete, onEdit } = props;

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item xs="auto">
        Законный представитель:{' '}

        <Tooltip title="Открыть в новой вкладке">
          <a href={`/person/${person.id}`} rel="noopener noreferrer" target="_blank">
            {getPersonName(person)}
          </a>
        </Tooltip>
      </Grid>

      <Grid item xs="auto">
        <Tooltip title="Изменить законного представителя">
          <IconButton onClick={onEdit} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Удалить законного представителя">
          <IconButton onClick={onDelete} size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default memo(LegalRepresentativePerson);
