import styled from '@mui/material/styles/styled';

export const DocImagesRoot = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
`;

export const DocImagesUpload = styled('div')`
  text-align: center;
  margin: 24px 0;
  overflow: hidden;
  position: relative;
`;

export const DocImagesContainer = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const DocImagesContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const DocImagesContentHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DocImagesExample = styled('div')`
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
`;
