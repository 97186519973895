import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';

export const BreakfastRoot = styled('div')`
  position: relative;
  height: 100%;
  padding: 24px;
`;

export const BreakfastContainer = styled(Grid)`
  height: 100%;
`;

export const BreakfastPersons = styled(Grid)`
  height: 100%;
`;

export const BreakfastPersonsWrapper = styled('div')`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const BreakfastPersonsContent = styled('div')`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`;

export const BreakfastPersonsFooter = styled('div')`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 8px 0;
`;

export const BreakfastPersonsList = styled('div')`
  height: 100%;
  overflow: hidden;
`;

export const BreakfastPersonsScroll = styled('div')`
  height: 100%;
  overflow: auto;
  padding: 4px 8px;
`;
