import styled from '@mui/material/styles/styled';
import Table from '@mui/material/Table';

export const BookingsTableRoot = styled('div')`
  display: flex;
  overflow: hidden;
  height: 100%;
`;

export const BookingsTableContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BookingsTableWrapper = styled('div')`
  overflow: auto;
  flex-grow: 1;
`;

export const BookingsTableTable = styled(Table)`
  table-layout: fixed;
`;

export const TablePaginationContent = styled('div')`
  flex-shrink: 0;
`;
