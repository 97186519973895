import React, { memo } from 'react';
import Button from '@mui/material/Button';

import { DocumentType } from '@/constants';
import { ContentModal } from '@/ui-kit/components';

import { AttachButton } from './AttachUnrecognizedDocModal.style';

interface AttachUnrecognizedDocModalProps {
  open: boolean;
  onDecline: () => void;
  onAttach: (type: DocumentType) => void;
  title?: string;
  isLoading?: boolean;
}

function AttachUnrecognizedDocModal(props: AttachUnrecognizedDocModalProps) {
  const { title = 'Документ не распознан, выберите категорию', isLoading = false, open, onAttach, onDecline } = props;

  return (
    <ContentModal isLoading={isLoading} onClose={() => null} open={open}>
      <ContentModal.Header onClose={onDecline}>{title}:</ContentModal.Header>

      <ContentModal.Body>
        <AttachButton>
          <Button color="primary" fullWidth onClick={() => onAttach(DocumentType.IdCard)} variant="contained">
            Удостоверение личности
          </Button>
        </AttachButton>

        <AttachButton>
          <Button color="primary" fullWidth onClick={() => onAttach(DocumentType.RightOfStay)} variant="contained">
            Право пребывания
          </Button>
        </AttachButton>

        <AttachButton>
          <Button color="primary" fullWidth onClick={() => onAttach(DocumentType.Migration)} variant="contained">
            Миграционная карта
          </Button>
        </AttachButton>
      </ContentModal.Body>
    </ContentModal>
  );
}

export default memo(AttachUnrecognizedDocModal);
