import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { Company } from '@/entities/company';
import { IpAddress } from '@/entities/ipAddress';
import { useToggle } from '@/hooks/useToggle/useToggle';
import IpAddressFormModal from '@/shared/IpAddressFormModal/IpAddressFormModal';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import { IpAddressContent, IpAddressHeader, IpAddressRoot, IpAddressTable } from './IpAddressList.style';
import IpAddressRow from './IpAddressRaw/IpAddressRaw';

interface IpAddressProps {
  companyId: number;
}

function IpAddressList(props: IpAddressProps) {
  const { companyId } = props;
  const { isFetchingCompanyById, companyByIdMap, getCompanyById } = useStore('companiesStore');

  const [isAddIpAddressOpen, openAddIpAddress, closeAddIpAddress] = useToggle();
  const [ipAddresses, setIpAddresses] = useState<IpAddress[]>([]);

  const company: Company | undefined = companyByIdMap.get(companyId);

  async function fetchCompanyById() {
    try {
      await getCompanyById(companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (company) {
      setIpAddresses(company.ip ?? []);
    } else {
      fetchCompanyById();
    }
  }, [JSON.stringify(company)]);

  return (
    <IpAddressRoot>
      <BackdropLoader isLoading={isFetchingCompanyById} />

      <IpAddressHeader>
        <Typography>Сети для IP-whitelist</Typography>

        <Button color="primary" onClick={openAddIpAddress} size="small" variant="contained">
          Добавить IP-адрес
        </Button>
      </IpAddressHeader>

      <IpAddressContent>
        <IpAddressTable>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>

                <TableCell>IP-адрес</TableCell>

                <TableCell width="20" />
              </TableRow>
            </TableHead>

            <TableBody>
              {ipAddresses.length > 0 &&
                ipAddresses.map((ipAddress: IpAddress) => (
                  <IpAddressRow
                    companyId={companyId}
                    ipAddress={ipAddress}
                    key={ipAddress.id}
                    onAfterChange={fetchCompanyById}
                  />
                ))}

              {ipAddresses.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography align="center">IP-адреса отсутствуют</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <IpAddressFormModal
            companyId={companyId}
            onAfterChange={fetchCompanyById}
            onClose={closeAddIpAddress}
            open={isAddIpAddressOpen}
            type="create"
          />
        </IpAddressTable>
      </IpAddressContent>
    </IpAddressRoot>
  );
}

export default memo(observer(IpAddressList));
