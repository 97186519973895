import React, { Fragment, memo } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ArrowIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import RestoreIcon from '@mui/icons-material/Restore';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import yellow from '@mui/material/colors/yellow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { ROUTE_REGISTRATIONS } from '@/constants';
import { Group } from '@/entities/group';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';
import DropdownMenu, { DropdownMenuOption } from '@/ui-kit/components/DropdownMenu/DropdownMenu';

import GroupFormModal from './GroupFormModal/GroupFormModal';
import { GroupFormType } from './GroupItem.constants';
import {
  GroupItemChildren,
  GroupItemCollapse,
  GroupItemIcon,
  GroupItemLink,
  GroupItemLoader,
  GroupItemName,
  GroupItemRoot,
  GroupItemToolbar,
} from './GroupItem.style';

interface GroupItemProps {
  group: Group;
  onSelect: (group: Group) => void;
  editable: boolean;
  restorable?: boolean;
}

function GroupItem(props: GroupItemProps) {
  const { group, editable, restorable, onSelect } = props;
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { getGroupsById, deleteGroup, isSaving, collapseGroup, isRestoring, restoreGroup, getGroups } =
    useStore('groupsStore');
  const { updateUser } = useStore('usersStore');
  const { userData, setUserData } = useStore('signInStore');

  const [isFetchingChild, enableFetchingChild, disableFetchingChild] = useToggle();
  const [isUpdatingDefaultGroup, enableUpdatingDefaultGroup, disableUpdatingDefaultGroup] = useToggle();
  const [isDeleteOpen, openDeleteModal, closeDeleteModal] = useToggle();
  const [isEditOpen, openEditModal, closeEditModal] = useToggle();
  const [isNewOpen, openNewModal, closeNewModal] = useToggle();
  const [isRestoreOpen, , , toggleRestoreModal] = useToggle();

  async function onCollapseHandler() {
    if (!isFetchingChild) {
      if (!group.collapsed) {
        enableFetchingChild();

        await getGroupsById(group.id);

        disableFetchingChild();
      }

      collapseGroup(group.id);
    }
  }

  async function onDelete() {
    try {
      if (group.id) {
        /*
         * Если группа является группой по умолчанию у пользователя,
         * то перед удалением ее сначала нужно открепить как группу по умолчанию
         * */
        if (group.id === userData?.default_group_id) {
          await updateUser({ default_group_id: 0 }, userData?.id);
        }

        await deleteGroup(group.id, group.parent_id);

        closeDeleteModal();

        notificationAPI.success('Группа удалена');

        if (groupId && +groupId === +group.id) {
          navigate(ROUTE_REGISTRATIONS);
        }
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function onRestore() {
    try {
      if (group.id) {
        await restoreGroup(group.id);

        toggleRestoreModal();

        notificationAPI.success('Группа восстановлена');

        getGroups(true);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function onSetDefaultGroup() {
    enableUpdatingDefaultGroup();

    try {
      if (userData && group) {
        await updateUser({ default_group_id: group.id }, userData?.id);

        setUserData('default_group_id', group.id);

        notificationAPI.success(`Группа "${group.name}" выбрана по умолчанию`);
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    disableUpdatingDefaultGroup();
  }

  async function onResetDefaultGroup() {
    enableUpdatingDefaultGroup();

    try {
      if (userData && group) {
        await updateUser({ default_group_id: 0 }, userData?.id);

        setUserData('default_group_id', 0);

        notificationAPI.success('Группа по умолчанию откреплена');
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    disableUpdatingDefaultGroup();
  }

  function renderContextMenuButtons() {
    const result: DropdownMenuOption[] = [];

    result.push({
      label: 'Добавить дочернюю группу',
      icon: <AddIcon fontSize="large" htmlColor={green['500']} />,
      onClick: openNewModal,
    });

    // если не группа по умолчанию
    if (group?.id !== 1) {
      result.push({
        icon: <EditIcon fontSize="large" htmlColor={blue['500']} />,
        label: 'Редактировать группу',
        onClick: openEditModal,
      });

      if (!group?.has_child_groups && group?.persons_count === 0) {
        result.push({
          icon: <DeleteIcon fontSize="large" htmlColor={red['500']} />,
          label: 'Удалить группу',
          onClick: openDeleteModal,
        });
      }

      // если группа помечена как дефолтная: `default_group_id` из авторизационных данных - рендерим кнопку `Открепить`
      if (userData?.default_group_id === group?.id) {
        result.push({
          icon: <StarBorderIcon fontSize="large" htmlColor={yellow['500']} />,
          label: 'Открепить',
          onClick: onResetDefaultGroup,
          disabled: isUpdatingDefaultGroup,
        });
      } else {
        // если группа не помечена как дефолтная: `default_group_id` из авторизационных данных - рендерим кнопку `Сделать по умолчанию`
        result.push({
          icon: <StarIcon fontSize="large" htmlColor={yellow['500']} />,
          label: 'Сделать по умолчанию',
          onClick: onSetDefaultGroup,
          disabled: isUpdatingDefaultGroup,
        });
      }
    }

    return result;
  }

  return (
    <div>
      <GroupItemRoot isSelected={Boolean(groupId && group.id === +groupId)}>
        <GroupItemLink>
          <GroupItemIcon>
            {group.has_child_groups && (
              <GroupItemCollapse isCollapsed={group.collapsed}>
                <IconButton onClick={onCollapseHandler} size="small">
                  <ArrowIcon fontSize="small" />
                </IconButton>
              </GroupItemCollapse>
            )}
          </GroupItemIcon>

          <GroupItemName onClick={() => onSelect(group)}>
            {userData?.default_group_id === group?.id && <StarIcon fontSize="small" htmlColor={yellow['500']} />}

            <Chip color="primary" label={group.persons_count} size="small" variant="outlined" />

            <span>&nbsp;{group.name}</span>
          </GroupItemName>
        </GroupItemLink>

        <GroupItemToolbar className="groupItemToolbar">
          {group.has_child_groups && isFetchingChild && (
            <GroupItemLoader>
              <CircularProgress size="15" />
            </GroupItemLoader>
          )}

          {editable && (
            <DropdownMenu
              keepMounted
              opener={
                <IconButton>
                  <MoreIcon />
                </IconButton>
              }
              options={renderContextMenuButtons()}
            />
          )}

          {restorable && (
            <Tooltip title="Восстановить группу">
              <IconButton onClick={toggleRestoreModal}>
                <RestoreIcon />
              </IconButton>
            </Tooltip>
          )}
        </GroupItemToolbar>
      </GroupItemRoot>

      {group.has_child_groups && group.children.length > 0 && (
        <GroupItemChildren isCollapsed={group.collapsed}>
          {group.children.map((groupItem) => (
            <GroupItem editable={editable} group={groupItem} key={groupItem.id} onSelect={onSelect} />
          ))}
        </GroupItemChildren>
      )}

      {editable && group && (
        <Fragment>
          <GroupFormModal group={group} onClose={closeNewModal} open={isNewOpen} type={GroupFormType.Create} />

          <GroupFormModal group={group} onClose={closeEditModal} open={isEditOpen} type={GroupFormType.Edit} />

          <ConfirmModal
            confirmButtonProps={{
              color: 'error',
            }}
            description={`Вы уверены, что хотите удалить ${group.name}?`}
            isLoading={isSaving}
            onClose={closeDeleteModal}
            onConfirm={onDelete}
            onDecline={closeDeleteModal}
            open={isDeleteOpen}
            title="Удалить группу"
          />
        </Fragment>
      )}

      {restorable && group && (
        <ConfirmModal
          confirmLabel="Восстановить"
          description={`Восстановить группу ${group.name}?`}
          isLoading={isRestoring}
          onClose={toggleRestoreModal}
          onConfirm={onRestore}
          onDecline={toggleRestoreModal}
          open={isRestoreOpen}
          title="Восстановление группы"
        />
      )}
    </div>
  );
}

export default memo(observer(GroupItem));
