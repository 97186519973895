import { Device } from '@/entities/device';

export class LicenseModel {
  id!: number;
  company_id!: number;
  serial_code!: string;
  serial_number!: string;
  warranty_start!: string;
  warranty_end!: string;
  created_at!: string;
  devices!: Nullable<Device[]>;
}

export class License extends LicenseModel {
  constructor(license: LicenseModel) {
    super();

    Object.keys(license).forEach((key) => {
      this[key] = license[key];
    });
  }
}
