import React, { memo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';

import OperatorPanelTabs from '../OperatorPanelTabs/OperatorPanelTabs';

import Elpost from './Elpost/Elpost';
import Export from './Export/Export';
import Kontur from './Kontur/Kontur';
import { VerificationTab } from './Verification.constants';
import { VerificationContent, VerificationSidebar } from './Verification.style';

function Verification() {
  const [tab, setTab] = useState(VerificationTab.Elpost);

  function onChangeTab(_: unknown, newTab: VerificationTab) {
    setTab(newTab);
  }

  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Верификация']} />}
      content={
        <VerificationContent container spacing={2}>
          <Grid item xs={2}>
            <VerificationSidebar>
              <Tabs onChange={onChangeTab} orientation="vertical" value={tab}>
                <Tab label="Элпост - Opera" />

                <Tab label="Контур - Opera" />

                <Tab label="Opera - EMIS" />
              </Tabs>
            </VerificationSidebar>
          </Grid>

          <Grid item xs={10}>
            {tab === VerificationTab.Elpost && <Elpost />}

            {tab === VerificationTab.Kontur && <Kontur />}

            {tab === VerificationTab.Emis && <Export />}
          </Grid>
        </VerificationContent>
      }
      header={<OperatorPanelTabs />}
    />
  );
}

export default memo(Verification);
