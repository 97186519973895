import React, { memo } from 'react';

import Groups from '@/shared/Groups/Groups';
import Persons from '@/shared/Persons/Persons';
import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';

import OperatorPanelTabs from '../OperatorPanelTabs/OperatorPanelTabs';

import { RemovedContent } from './Removed.style';

function Removed() {
  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Регистрации']} />}
      content={
        <RemovedContent>
          <Groups mode="deleted" />

          <Persons mode="deleted" />
        </RemovedContent>
      }
      contentSettings={{
        overflow: 'hidden',
      }}
      header={<OperatorPanelTabs />}
    />
  );
}

export default memo(Removed);
