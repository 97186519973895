import React, { Fragment, memo, SyntheticEvent, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { KSDocTemplateModel } from '@/entities/KSDocTemplate';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';

interface DocTemplatesProps {
  docTemplate: KSDocTemplateModel;
  companyId: number;
  onAfterChange: () => void;
}

function DocTemplatesRow(props: DocTemplatesProps) {
  const { docTemplate, companyId, onAfterChange } = props;
  const { removeDocTemplate, printDocTemplate, isSavingDocTemplate } = useStore('companiesStore');

  const [isDeleteDocTemplateModalOpen, , , toggleDeleteDocTemplateModal] = useToggle();

  async function onClickHandler() {
    try {
      if (docTemplate?.id) {
        await printDocTemplate(companyId, docTemplate.id);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function onDeleteHandler() {
    try {
      if (docTemplate?.id) {
        await removeDocTemplate(companyId, docTemplate.id);

        notificationAPI.success('Шаблон удален');

        onAfterChange();
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onToggleDeleteDocTemplateModal = useCallback((e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    toggleDeleteDocTemplateModal();
  }, []);

  return (
    <Fragment>
      <TableRow hover onClick={onClickHandler}>
        <TableCell>{docTemplate.name}</TableCell>

        <TableCell>{docTemplate.category}</TableCell>

        <TableCell align="center">
          <Tooltip title="Удалить шаблон">
            <IconButton onClick={onToggleDeleteDocTemplateModal} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={
          <Typography variant="body1">
            <span>Вы действительно хотите удалить шаблон </span>

            <span>&quot;{docTemplate.name}&quot; </span>

            <span>({docTemplate.category})?</span>
          </Typography>
        }
        isLoading={isSavingDocTemplate}
        onClose={toggleDeleteDocTemplateModal}
        onConfirm={onDeleteHandler}
        onDecline={toggleDeleteDocTemplateModal}
        open={isDeleteDocTemplateModalOpen}
        title="Удалить шаблон документов"
      />
    </Fragment>
  );
}

export default memo(observer(DocTemplatesRow));
