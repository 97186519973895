import { makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import { Company, CompanyModel } from '@/entities/company';
import { Face } from '@/entities/face';

import {
  AddCompanyDocTemplateForm,
  AddCompanyForm,
  AddCompanyIpAddressForm,
  CreateEulaFormValuesType,
} from './CompaniesStore.types';

export class CompaniesStore {
  companies: Company[] = [];
  companyByIdMap: Map<number, Company> = new Map();
  isFetching = false;
  isSaving = false;
  isFetchingCompanyById = false;
  isSavingIpAddress = false;
  isAttachingIpAddresses = false;
  isSavingFace = false;
  isSavingEulaCard = false;
  isSavingDocTemplate = false;

  constructor() {
    makeAutoObservable(this);
  }

  getCompanies = () => {
    this.isFetching = true;

    return fetchApi
      .get<CompanyModel[]>('/companies/')
      .then((response) => {
        response.sort((a, b) => {
          if (!a.id || !b.id) {
            return 0;
          }

          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;

          return 0;
        });

        const companies = response.map((company) => new Company(company));

        runInAction(() => {
          this.companies = companies;
        });

        return companies;
      })
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  getCompanyById = (id: StringOrNumber) => {
    this.isFetchingCompanyById = true;

    return fetchApi
      .get<CompanyModel>(`/companies/${id}/`)
      .then((company) => {
        const map = new Map(this.companyByIdMap);

        map.set(+id, new Company(company));

        runInAction(() => {
          this.companyByIdMap = map;
        });

        return new Company(company);
      })
      .finally(() => {
        runInAction(() => {
          this.isFetchingCompanyById = false;
        });
      });
  };

  addCompany = (body: AddCompanyForm) => {
    this.isSaving = true;

    return fetchApi.post('/companies/', { body }).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };

  updateCompany = (id: number, body: Partial<CompanyModel>) => {
    this.isSaving = true;

    return fetchApi.put(`/companies/${id}/`, { body }).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };

  removeCompany = (id: number) => {
    this.isSaving = true;

    return fetchApi.remove(`/companies/${id}/`).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };

  addIpAddress = (companyId: number, body: AddCompanyIpAddressForm) => {
    this.isSavingIpAddress = true;

    return fetchApi.post(`/companies/${companyId}/ip/`, { body }).finally(() => {
      runInAction(() => {
        this.isSavingIpAddress = false;
      });
    });
  };

  updateIpAddress = (companyId: number, idIp: number, body: AddCompanyIpAddressForm) => {
    this.isSavingIpAddress = true;

    return fetchApi.put(`/companies/${companyId}/ip/${idIp}/`, { body }).finally(() => {
      runInAction(() => {
        this.isSavingIpAddress = false;
      });
    });
  };

  removeIpAddress = (companyId: number, idIp: number) => {
    this.isSavingIpAddress = true;

    return fetchApi.remove(`/companies/${companyId}/ip/${idIp}/`).finally(() => {
      runInAction(() => {
        this.isSavingIpAddress = false;
      });
    });
  };

  addDocTemplate = (companyId: number, values: AddCompanyDocTemplateForm) => {
    this.isSavingDocTemplate = true;

    const formData = new FormData();

    if (values.file) {
      formData.append('file', values.file);
    }

    return fetchApi
      .post(`/companies/${companyId}/doc-templates/`, {
        query: values,
        body: formData,
        headers: { 'Content-Type': null },
      })
      .finally(() => {
        runInAction(() => {
          this.isSavingDocTemplate = false;
        });
      });
  };

  removeDocTemplate = (companyId: number, docTemplateIp: number) => {
    this.isSavingDocTemplate = true;

    return fetchApi.remove(`/companies/${companyId}/doc-templates/${docTemplateIp}/`).finally(() => {
      runInAction(() => {
        this.isSavingDocTemplate = false;
      });
    });
  };

  printDocTemplate = (companyId: number, docTemplateIp: number) =>
    fetchApi.print(`/companies/${companyId}/doc-templates/${docTemplateIp}/`);

  attachIpAddressesToUser = (companyId: number, userId: number, ids: number[]) => {
    this.isAttachingIpAddresses = true;

    const requests = ids.map((id) => fetchApi.put(`/companies/${companyId}/ip/${id}/user/${userId}/`));

    return Promise.all(requests).finally(() => {
      runInAction(() => {
        this.isAttachingIpAddresses = false;
      });
    });
  };

  deAttachIpAddressesFromUser = (companyId: number, userId: number, ids: number[]) => {
    this.isAttachingIpAddresses = true;

    const requests = ids.map((id) => fetchApi.remove(`/companies/${companyId}/ip/${id}/user/${userId}/`));

    return Promise.all(requests).finally(() => {
      runInAction(() => {
        this.isAttachingIpAddresses = false;
      });
    });
  };

  addCompanyFace = (companyId: number, body: Face) => {
    this.isSavingFace = true;

    return fetchApi.post(`/companies/${companyId}/faces/`, { body }).finally(() => {
      runInAction(() => {
        this.isSavingFace = false;
      });
    });
  };

  updateCompanyFace = (companyId: number, faceId: number, body: Face) => {
    this.isSavingFace = true;

    return fetchApi.put(`/companies/${companyId}/faces/${faceId}/`, { body }).finally(() => {
      runInAction(() => {
        this.isSavingFace = false;
      });
    });
  };

  removeCompanyFace = (companyId: number, faceId: number) => {
    this.isSavingFace = true;

    return fetchApi.remove(`/companies/${companyId}/faces/${faceId}/`).finally(() => {
      runInAction(() => {
        this.isSavingFace = false;
      });
    });
  };

  addCompanyEulaCard = (companyId: number, values: CreateEulaFormValuesType) => {
    this.isSavingEulaCard = true;

    const formData = new FormData();

    if (values.file) {
      formData.append('file', values.file);
    }

    return fetchApi
      .post(`/companies/${companyId}/eulas/`, {
        query: {
          name: values.name,
        },
        body: formData,
        headers: { 'Content-Type': null },
      })
      .finally(() => {
        runInAction(() => {
          this.isSavingEulaCard = false;
        });
      });
  };

  removeCompanyEulaCard = (companyId: number, idEula: number) => {
    this.isSavingEulaCard = true;

    return fetchApi.remove(`/companies/${companyId}/eulas/${idEula}/`).finally(() => {
      runInAction(() => {
        this.isSavingEulaCard = false;
      });
    });
  };

  printPersonEulaCard = (id: number, idEula: number) =>
    fetchApi.print('/persons-form-pdf/4/', { query: { id, eula_id: idEula } });
}
