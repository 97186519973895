import { formatAmountRUS } from '@/utils/formatMoney';

export class KSReportModel {
  total!: number;
  cntrus!: number;
  paid!: number;
  sumfact!: number;
  sumexpect!: number;
  reason1!: number;
  reason2!: number;
  reason3!: number;
  reason4!: number;
  reason5!: number;
  reason6!: number;
  reason7!: number;
  reason8!: number;
  reason9!: number;
  reason10!: number;
  reason11!: number;
  reason12!: number;
  reason13!: number;
  reason14!: number;
  reason15!: number;
  reason16!: number;
  reason17!: number;
  reason18!: number;
  reason19!: number;
  reason20!: number;
  reason21!: number;
  reason22!: number;
  reason23!: number;
  reason24!: number;
}

export class KSReport extends KSReportModel {
  constructor(ksReport: KSReportModel) {
    super();

    Object.keys(ksReport).forEach((key) => {
      this[key] = ksReport[key];
    });
  }

  get foreigners() {
    if (isNaN(+this.total)) {
      return 0;
    }

    if (isNaN(+this.cntrus)) {
      return 0;
    }

    return this.total - this.cntrus;
  }

  get isAmountEqual() {
    if (isNaN(+this.sumfact)) {
      return false;
    }

    if (isNaN(+this.sumexpect)) {
      return false;
    }

    return this.sumfact === this.sumexpect;
  }

  get calculatedSum() {
    if (isNaN(+this.sumexpect)) {
      return formatAmountRUS(0);
    }

    return formatAmountRUS(this.sumexpect);
  }

  get paidSum() {
    if (isNaN(+this.sumfact)) {
      return formatAmountRUS(0);
    }

    return formatAmountRUS(this.sumfact);
  }

  get reasonData() {
    return Object.keys(this)
      .filter((key) => key.includes('reason'))
      .map((key) => ({
        label: key,
        value: this[key],
        id: key.match(/\d+/g)?.[0],
      }));
  }

  getReasonDataById(id: StringOrNumber) {
    return this[`reason${id}`] ?? null;
  }
}
