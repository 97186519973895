import React, { memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { DocumentType } from '@/constants';
import { DEFAULT_ID_CARD, DEFAULT_MIGRATION_CARD, DEFAULT_RIGHT_OF_STAY, DocumentImage } from '@/stores/PersonsStore';

import { usePersonPageStore } from '../../PersonPageStore';
import DocumentsTabs from '../DocumentsTabs/DocumentsTabs';

import DocumentImages from './DocumentImages/DocumentImages';
import { ImagesPanelRoot, ImagesPanelTabs } from './ImagesPanel.style';

function ImagesPanel() {
  const { idCard, setIdCard, rightOfStay, setRightOfStay, migrationCard, setMigrationCard, status, citizenship, tab } =
    usePersonPageStore();

  const onCopyImageHandler = useCallback(
    (target: DocumentType, image: DocumentImage) => {
      if (target === DocumentType.IdCard) {
        if (idCard) {
          idCard.images = [...(idCard.images ?? []), image];
          setIdCard(idCard);
        } else {
          setIdCard({
            ...DEFAULT_ID_CARD,
            images: [image],
          });
        }
      }

      if (target === DocumentType.RightOfStay) {
        if (rightOfStay) {
          rightOfStay.images = [...(rightOfStay.images ?? []), image];
          setRightOfStay(rightOfStay);
        } else {
          setRightOfStay({
            ...DEFAULT_RIGHT_OF_STAY,
            images: [image],
          });
        }
      }

      if (target === DocumentType.Migration) {
        if (migrationCard) {
          migrationCard.images = [...(migrationCard.images ?? []), image];
          setMigrationCard(migrationCard);
        } else {
          setMigrationCard({
            ...DEFAULT_MIGRATION_CARD,
            images: [image],
          });
        }
      }
    },
    [JSON.stringify(idCard), JSON.stringify(rightOfStay), JSON.stringify(migrationCard)],
  );

  return (
    <ImagesPanelRoot>
      <ImagesPanelTabs>
        <DocumentsTabs />
      </ImagesPanelTabs>

      {idCard && tab === DocumentType.IdCard && (
        <DocumentImages
          citizenship={citizenship}
          currentTab={tab}
          onCopyImage={onCopyImageHandler}
          setValues={setIdCard}
          status={status}
          values={idCard}
        />
      )}

      {rightOfStay && tab === DocumentType.RightOfStay && (
        <DocumentImages
          citizenship={citizenship}
          currentTab={tab}
          onCopyImage={onCopyImageHandler}
          setValues={setRightOfStay}
          status={status}
          values={rightOfStay}
        />
      )}

      {migrationCard && tab === DocumentType.Migration && (
        <DocumentImages
          citizenship={citizenship}
          currentTab={tab}
          onCopyImage={onCopyImageHandler}
          setValues={setMigrationCard}
          status={status}
          values={migrationCard}
        />
      )}
    </ImagesPanelRoot>
  );
}

export default memo(observer(ImagesPanel));
