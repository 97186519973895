import React, { memo } from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

import { ContentModal, ContentModalProps } from '@/ui-kit/components';

interface DeleteConfirmModalProps extends ContentModalProps {
  onDelete: () => void;
}

function DeleteImageConfirmModal(props: DeleteConfirmModalProps) {
  const { open, onDelete, onClose } = props;

  return (
    <ContentModal maxWidth="xs" onClose={onClose} open={open}>
      <ContentModal.Header onClose={onClose}>Удалить изображение</ContentModal.Header>

      <ContentModal.Body>
        <DialogContentText>Вы уверены, что хотите удалить изображение?</DialogContentText>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose}>
          Отмена
        </Button>

        <Button color="error" onClick={onDelete} variant="contained">
          Удалить
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(DeleteImageConfirmModal);
