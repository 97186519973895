import React, { Attributes, ForwardedRef, forwardRef, HTMLAttributes, memo, ReactNode, SyntheticEvent } from 'react';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import { AutocompleteProps, AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

import { InputField, InputFieldProps } from '@/ui-kit/components';

interface AutocompleteFieldProps
  extends Omit<AutocompleteProps<Any, boolean, boolean, boolean>, 'onInputChange' | 'onChange' | 'renderInput'> {
  isError?: boolean;
  helperText?: ReactNode;
  label?: string;
  name?: string;
  tooltip?: string;
  tooltipColor?: InputFieldProps['tooltipColor'];
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
  onInputChange?: StandardInputProps['onChange'];
  variant?: BaseTextFieldProps['variant'];
  margin?: BaseTextFieldProps['margin'];
  onChange?: (option: unknown, e: SyntheticEvent) => void;
  inputProps?: InputFieldProps['inputProps'];
  InputProps?: InputFieldProps['InputProps'];
}

const AutocompleteField = forwardRef((props: AutocompleteFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    blurOnSelect = true,
    label,
    name,
    renderInput,
    helperText,
    isError,
    onInputChange,
    filterSelectedOptions = true,
    renderOption,
    variant = 'standard',
    margin = 'none',
    onChange,
    tooltip,
    tooltipColor,
    inputProps,
    InputProps,
    ...rest
  } = props;

  function renderInputHandler(props: AutocompleteRenderInputParams): ReactNode {
    if (renderInput) {
      return renderInput(props);
    }

    const { InputProps: AutocompleteInputProps, inputProps: AutocompleteFieldProps } = props;

    return (
      <InputField
        {...props}
        InputProps={{
          ...AutocompleteInputProps,
          ...InputProps,
        }}
        error={isError}
        helperText={helperText}
        inputProps={{
          ...AutocompleteFieldProps,
          ...inputProps,
        }}
        inputRef={ref}
        label={label}
        margin={margin}
        name={name}
        onChange={onInputChange}
        tooltip={tooltip}
        tooltipColor={tooltipColor}
        variant={variant}
      />
    );
  }

  function renderOptionHandler(
    props: HTMLAttributes<HTMLLIElement>,
    option: unknown,
    state: AutocompleteRenderOptionState,
  ): ReactNode {
    if (renderOption) {
      return renderOption(props, option, state, {} as never);
    }

    const { id, key, ...rest } = props as unknown as HTMLAttributes<HTMLLIElement> & Attributes;

    return (
      <li key={id} {...rest}>
        {`${key}`}
      </li>
    );
  }

  function onChangeHandler(e: SyntheticEvent, option: unknown) {
    e.preventDefault();

    onChange?.(option, e);
  }

  return (
    <Autocomplete
      blurOnSelect={blurOnSelect}
      filterSelectedOptions={filterSelectedOptions}
      noOptionsText="Нет данных"
      onChange={onChangeHandler}
      renderInput={renderInputHandler}
      renderOption={renderOptionHandler}
      {...rest}
    />
  );
});

export default memo(AutocompleteField);
