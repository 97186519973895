export interface FormatMoneyParams {
  currency?: string;
  locale?: string;
  fractionDigits?: number;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  showSymbol?: boolean;
  /*
   * We can pass `signDisplay` property as boolean or one of values from `Intl.NumberFormat`
   *
   * If we pass `signDisplay: true` -> sign will always be displayed, both + and -, but not on 0
   * If we pass `signDisplay: 'auto'` -> sign will always be displayed as `Intl.NumberFormat` render it
   * If this property not passed -> no sign will be displayed
   * */
  signDisplay?: Intl.NumberFormatOptions['signDisplay'] | boolean;
}

export function formatMoney(amount: StringOrNumber, formatMoneyParams: FormatMoneyParams) {
  const {
    currency,
    locale,
    fractionDigits,
    minFractionDigits = 2,
    maxFractionDigits = 4,
    showSymbol = true,
    signDisplay,
  } = formatMoneyParams;

  let amountValue = amount;

  if (
    (typeof amount !== 'string' && typeof amount !== 'number') ||
    (typeof amount === 'string' && amount.length === 0) ||
    Number.isNaN(+amount)
  ) {
    amountValue = 0;
  }

  const options: Intl.NumberFormatOptions = {
    useGrouping: true,
    minimumFractionDigits: fractionDigits ?? minFractionDigits,
    maximumFractionDigits: fractionDigits ?? maxFractionDigits,
  };

  if (showSymbol) {
    options.style = 'currency';
    options.currency = currency;
  } else {
    options.style = 'decimal';
  }

  if (signDisplay) {
    if (signDisplay === true) {
      options.signDisplay = 'exceptZero';
    } else {
      options.signDisplay = signDisplay;
    }
  }

  /*
   * We have custom format of rendering amount values:
   * it should look like "9 999 999.99 $" for all countries and values (only currency symbol is different)
   *
   * We can't get it work with built-in tools, this is why we implement logic below
   * */
  const resultParts = new Intl.NumberFormat(locale, options).formatToParts(+amountValue);
  const resultCurrency = resultParts.find((part) => part.type === 'currency');

  let result = resultParts.reduce((acc, value) => {
    if (value.type === 'group') {
      return `${acc} `;
    }

    if (value.type === 'decimal') {
      return `${acc}.`;
    }

    if (
      value.type === 'integer' ||
      value.type === 'fraction' ||
      value.type === 'minusSign' ||
      value.type === 'plusSign'
    ) {
      return acc + value.value;
    }

    return `${acc}`;
  }, '');

  if (resultCurrency?.value) {
    result += ` ${resultCurrency.value}`;
  }

  return result;
}

export function formatAmountRUS(amount: number) {
  return `${formatMoney(amount, { showSymbol: false, locale: 'RUS', fractionDigits: 0 })} руб.`;
}
