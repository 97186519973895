import { User, UserModel } from '@/entities/user';
import { PersonDocument } from '@/stores/PersonsStore';

export class OpModel {
  id!: number;
  device_id!: number;
  user!: UserModel;
  op_type!: number;
  op_start!: string;
  op_end!: string;
  op_result!: number;
  op_error!: string;
  document!: PersonDocument;
}

export class Op extends OpModel {
  constructor(op: OpModel) {
    super();

    Object.keys(op).forEach((key) => {
      switch (key) {
        case 'user':
          if (op.user) {
            this.user = new User(op.user);
          }

          break;
        default:
          this[key] = op[key];

          break;
      }
    });
  }
}
