import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';

import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';

import OperatorPanelTabs from '../OperatorPanelTabs/OperatorPanelTabs';

import BookingsTable from './BookingsTable/BookingsTable';

function Bookings() {
  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Бронирования']} />}
      content={<BookingsTable />}
      contentSettings={{
        overflow: 'hidden',
      }}
      header={<OperatorPanelTabs />}
      isLoading={false}
    />
  );
}

export default memo(observer(Bookings));
