import React, { Fragment, memo, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { EMAIL_REGEX } from '@/constants';
import { User } from '@/entities/user';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import {
  ButtonLoader,
  CheckboxField,
  ConfirmModal,
  ContentModal,
  ContentModalProps,
  InputField,
  SelectField,
  SwitchField,
} from '@/ui-kit/components';

import IpWhitelistElement from './IpWhitelistElement/IpWhitelistElement';
import { DEFAULT_USER_FORM } from './UserFormModal.constants';
import { HidePasswordIcon, PasswordInput, PasswordWrapper, ViewPasswordIcon } from './UserFormModal.style';
import UserRolesModal from './UserRolesModal/UserRolesModal';

interface UsersFormModalProps extends ContentModalProps {
  user: User;
  type: 'create' | 'edit';
  companyId: number;
  disableSuperUserSelect?: boolean;
  disableExternalAccess?: boolean;
}

interface UserForm {
  fname: string;
  lname: string;
  email: string;
  password: string;
  company_id: number;
  position: string;
  active: boolean;
  external_access_only: boolean;
  token_keep: boolean;
  rwr: boolean;
  company_superuser: boolean;
  use_ip_whitelist: boolean;
  token_lifetime_min: string;
  role_id?: number;
}

function UserFormModal(props: UsersFormModalProps) {
  const { user, type, companyId, open, onClose } = props;
  const { roles } = useStore('rolesStore');
  const { getUsersByCompany, isSaving, createUser, updateUser, forceExitUserById } = useStore('usersStore');
  const { userData } = useStore('signInStore');

  const isGlobalAdmin = userData?.id === 1;

  const initialFormValues = useMemo(
    () => ({
      fname: user.fname,
      lname: user.lname,
      email: user.email,
      password: user.password || '',
      position: user.position,
      company_id: companyId,
      role_id: user.role?.id ?? undefined,
      active: user.active || false,
      token_lifetime_min: user.token_lifetime_min ? `${user.token_lifetime_min}` : '',
      use_ip_whitelist: user.use_ip_whitelist || false,
      company_superuser: user.company_superuser || false,
      external_access_only: user.external_access_only || false,
      token_keep: user.token_keep || false,
      rwr: user.rwr || false,
    }),
    [JSON.stringify(user)],
  );

  const userForm = useFormik<UserForm>({
    validateOnMount: false,
    initialValues: initialFormValues,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (type === 'create') {
          await createUser(values);
        }

        if (type === 'edit' && user.id) {
          await updateUser(values, user.id);
        }

        resetForm();

        notificationAPI.success(`Пользователь ${type === 'create' ? 'добавлен' : 'обновлен'} `);

        if (companyId) {
          await getUsersByCompany(companyId);
        }
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    onReset: (_, { setValues }) => {
      setValues(initialFormValues);
      onClose();
    },
    validate: ({ fname, lname, email }) => {
      const errors: Partial<UserForm> = {};

      if (fname.length === 0) {
        errors.fname = 'Обязательно к заполнению';
      }

      if (lname.length === 0) {
        errors.lname = 'Обязательно к заполнению';
      }

      if (email.length === 0) {
        errors.email = 'Обязательно к заполнению';
      }

      if (email.length > 0 && !EMAIL_REGEX.test(email)) {
        errors.email = 'Неверный формат электронной почты';
      }

      return errors;
    },
  });

  const [isPasswordRender, , , togglePasswordRender] = useToggle(type === 'create');
  const [isShowPassword, , , toggleShowPassword] = useToggle();
  const [isConfirmExitModalOpen, openConfirmExitModal, closeConfirmExitModal] = useToggle();
  const [isRolesModalOpen, , , toggleRolesModal] = useToggle();

  const passwordValidity = useMemo((): Record<string, boolean> => {
    const valid: Record<string, boolean> = {};

    if (!/[0-9]/.test(userForm.values.password)) {
      valid.hasNumber = true;
    }

    if (!/[a-z]/.test(userForm.values.password)) {
      valid.hasLower = true;
    }

    if (!/[A-Z]/.test(userForm.values.password)) {
      valid.hasUpper = true;
    }

    if (/\s/.test(userForm.values.password)) {
      valid.noSpace = true;
    }

    if (userForm.values.password.length < 8) {
      valid.minLength = true;
    }

    return valid;
  }, [userForm.values.password]);

  const isPasswordValid = useMemo(() => Object.keys(passwordValidity).length === 0, [passwordValidity]);

  async function onExitUser() {
    try {
      if (user.id) {
        await forceExitUserById(user.id);

        closeConfirmExitModal();

        notificationAPI.success(`Пользователь "${userForm.values.fname} ${userForm.values.lname}" отключен`);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (open) {
      userForm.setValues(initialFormValues);
    }
  }, [open, JSON.stringify(initialFormValues)]);

  return (
    <ContentModal disableEscapeKeyDown={isSaving} maxWidth="md" onClose={userForm.handleReset} open={open}>
      <form onReset={userForm.handleReset} onSubmit={userForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSaving} onClose={userForm.handleReset}>
          <span>{type === 'edit' && 'Обновить '}</span>

          <span>{type === 'create' && 'Создать '}</span>

          <span>пользователя</span>
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputField
                    autoFocus
                    disabled={isSaving}
                    error={!!userForm.errors.fname}
                    helperText={userForm.errors.fname}
                    label="Имя"
                    name="fname"
                    onChange={userForm.handleChange}
                    value={userForm.values.fname}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputField
                    disabled={isSaving}
                    error={!!userForm.errors.lname}
                    helperText={userForm.errors.lname}
                    label="Фамилия"
                    name="lname"
                    onChange={userForm.handleChange}
                    value={userForm.values.lname}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputField
                    disabled={isSaving}
                    error={!!userForm.errors.email}
                    helperText={userForm.errors.email}
                    label="E-mail"
                    name="email"
                    onChange={userForm.handleChange}
                    type="email"
                    value={userForm.values.email}
                  />
                </Grid>

                {user.id !== 1 && (
                  <Grid item xs={12}>
                    {type === 'create' || isPasswordRender ? (
                      <Fragment>
                        <PasswordWrapper>
                          <PasswordInput
                            disabled={isSaving}
                            error={userForm.values.password.length > 0 && !isPasswordValid}
                            helperText={!isPasswordValid ? 'Пароль не соответствует требованиям' : ''}
                            label="Пароль"
                            name="password"
                            onChange={userForm.handleChange}
                            type={isShowPassword ? 'type' : 'password'}
                            value={userForm.values.password}
                          />

                          {type === 'edit' && (
                            <HidePasswordIcon>
                              <IconButton onClick={togglePasswordRender} size="small">
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </HidePasswordIcon>
                          )}

                          <ViewPasswordIcon>
                            <IconButton onClick={toggleShowPassword} size="small">
                              {!isShowPassword ? (
                                <VisibilityIcon fontSize="small" />
                              ) : (
                                <VisibilityOffIcon fontSize="small" />
                              )}
                            </IconButton>
                          </ViewPasswordIcon>
                        </PasswordWrapper>

                        <div>
                          <div>
                            <Checkbox checked={!passwordValidity.hasNumber} color="primary" readOnly size="small" />

                            <span> - цифра</span>
                          </div>

                          <div>
                            <Checkbox checked={!passwordValidity.hasLower} color="primary" readOnly size="small" />

                            <span> - маленькая буква</span>
                          </div>

                          <div>
                            <Checkbox checked={!passwordValidity.hasUpper} color="primary" readOnly size="small" />

                            <span> - большая буква</span>
                          </div>

                          <div>
                            <Checkbox checked={!passwordValidity.noSpace} color="primary" readOnly size="small" />

                            <span> - без пробелов</span>
                          </div>

                          <div>
                            <Checkbox checked={!passwordValidity.minLength} color="primary" readOnly size="small" />

                            <span> - минимум 8 символов</span>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Button
                        color="primary"
                        disabled={isSaving}
                        fullWidth
                        onClick={togglePasswordRender}
                        size="small"
                        variant="contained"
                      >
                        Изменить пароль
                      </Button>
                    )}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <InputField
                    disabled={isSaving}
                    error={!!userForm.errors.position}
                    helperText={userForm.errors.position}
                    label="Должность"
                    name="position"
                    onChange={userForm.handleChange}
                    value={userForm.values.position}
                  />
                </Grid>

                {user?.id !== 1 && (
                  <Grid item xs={12}>
                    <Grid alignItems="flex-end" container spacing={2}>
                      <Grid item xs={6}>
                        <SelectField
                          disabled={roles.length === 0}
                          label="Роль"
                          name="role_id"
                          onChange={userForm.handleChange}
                          value={userForm.values.role_id}
                        >
                          {roles.map((role) => (
                            <MenuItem key={role.id} value={role.id}>
                              {role.name}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>

                      <Grid item xs={6}>
                        <Button fullWidth onClick={toggleRolesModal} size="small" type="button" variant="contained">
                          Доступные роли
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs="auto">
                  <SwitchField
                    checked={userForm.values.active}
                    disabled={isSaving}
                    label="Активен"
                    name="active"
                    onChange={userForm.handleChange}
                  />
                </Grid>

                {isGlobalAdmin && (
                  <Grid item xs="auto">
                    <SwitchField
                      checked={userForm.values.company_superuser}
                      disabled={isSaving}
                      label="Админ компании"
                      name="company_superuser"
                      onChange={userForm.handleChange}
                    />
                  </Grid>
                )}

                <Grid item xs="auto">
                  <SwitchField
                    checked={userForm.values.external_access_only}
                    disabled={isSaving}
                    label="External access"
                    name="external_access_only"
                    onChange={userForm.handleChange}
                  />
                </Grid>

                <Grid item xs="auto">
                  <SwitchField
                    checked={userForm.values.token_keep}
                    disabled={isSaving}
                    label="Keep token"
                    name="token_keep"
                    onChange={userForm.handleChange}
                  />
                </Grid>

                {isGlobalAdmin && (
                  <Grid item xs="auto">
                    <SwitchField
                      checked={userForm.values.rwr}
                      disabled={isSaving}
                      label="Web распознавание"
                      name="rwr"
                      onChange={userForm.handleChange}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <InputField
                    disabled={isSaving}
                    error={!!userForm.errors.token_lifetime_min}
                    helperText={userForm.errors.token_lifetime_min}
                    label="Время жизни токена, мин"
                    name="token_lifetime_min"
                    onChange={userForm.handleChange}
                    value={userForm.values.token_lifetime_min}
                  />
                </Grid>

                {type === 'edit' && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <CheckboxField
                          checked={userForm.values.use_ip_whitelist}
                          label="Проверка по IP-адресу"
                          name="use_ip_whitelist"
                          onChange={userForm.handleChange}
                        />
                      </Grid>

                      {userForm.values.use_ip_whitelist && (
                        <IpWhitelistElement companyId={companyId} userId={user.id as number} />
                      )}
                    </Grid>
                  </Grid>
                )}

                {user?.id !== 1 && type === 'edit' && (
                  <Grid item xs={12}>
                    <Button
                      color="error"
                      fullWidth
                      onClick={openConfirmExitModal}
                      size="small"
                      type="button"
                      variant="contained"
                    >
                      Принудительный выход
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSaving} type="reset">
            Отмена
          </Button>

          <ButtonLoader disabled={!(isPasswordRender ? isPasswordValid : true)} isLoading={isSaving} type="submit">
            {type === 'edit' && 'Обновить'}

            {type === 'create' && 'Создать'}
          </ButtonLoader>
        </ContentModal.Footer>

        <ConfirmModal
          confirmButtonProps={{
            color: 'error',
          }}
          confirmLabel="Отключить"
          description={
            <Typography variant="body1">
              <span>Вы действительно хотите отключить от сервиса EMIS пользователя </span>

              <span>&quot;{userForm.values.fname} </span>

              <span>{userForm.values.lname}&quot;?</span>
            </Typography>
          }
          onClose={closeConfirmExitModal}
          onConfirm={onExitUser}
          onDecline={closeConfirmExitModal}
          open={isConfirmExitModalOpen}
          title="Принудительный выход"
        />

        <UserRolesModal onClose={toggleRolesModal} open={isRolesModalOpen} />
      </form>
    </ContentModal>
  );
}

export { DEFAULT_USER_FORM };
export default memo(observer(UserFormModal));
