import React, { memo, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';
import Stats from '@/shared/Stats/Stats';
import { useStore } from '@/stores/StoreProvider';

import AdminPanelTabs from '../AdminPanelTabs/AdminPanelTabs';

import { ReportContent } from './Reports.style';

function Reports() {
  const { userData } = useStore('signInStore');

  const statsWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Статистика']} />}
      content={
        <ReportContent ref={statsWrapperRef}>
          {userData?.company_id && <Stats wrapperRef={statsWrapperRef} />}
        </ReportContent>
      }
      header={<AdminPanelTabs />}
    />
  );
}

export default memo(observer(Reports));
