import { differenceInCalendarDays, isAfter, isBefore } from 'date-fns';

import { DocumentType } from '@/constants';
import { StayInfo } from '@/stores/PersonsStore';

export function getDocumentLabel(type: DocumentType) {
  switch (type) {
    case DocumentType.IdCard:
      return 'Удостоверение личности';
    case DocumentType.RightOfStay:
      return 'Право пребывания';
    case DocumentType.Migration:
      return 'Миграционная карта';
    default:
      return '';
  }
}

export function getKSAmount(stayInfo: StayInfo) {
  if (!stayInfo) {
    return 0;
  }

  const { stay_from, stay_till } = stayInfo;

  if (!stay_from || !stay_till) {
    return 0;
  }

  const stayFromDate = new Date(stay_from);
  const stayTillDate = new Date(stay_till);

  const firstOfApril = new Date('04.01.2024');

  const isFromBeforeFirstOfApril = isBefore(stayFromDate, firstOfApril);
  const isTillAfterFirstOfApril = isAfter(stayTillDate, firstOfApril);

  // если даты вьезда и выезда до 1го апреля 2024 - не считаем курортный сбор
  if (isFromBeforeFirstOfApril && !isTillAfterFirstOfApril) {
    return 0;
  }

  let stayDays = differenceInCalendarDays(stayTillDate, stayFromDate);

  // если дата вьезда до 1 апреля 2024, а дата выезда после 1го апреля:
  // из дней пребывания вычитаем дни ДО 1 апреля 2024
  if (isFromBeforeFirstOfApril && isTillAfterFirstOfApril) {
    let beforeAprilDays = differenceInCalendarDays(firstOfApril, stayFromDate);

    // если время пребывания больше суток - вычитаем один день, так как за первый день КС не берется
    if (stayDays > 1) {
      beforeAprilDays -= 1;
    }

    stayDays -= beforeAprilDays;
  }

  return 100 * stayDays;
}
