import React, { ChangeEvent, memo, SyntheticEvent, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import red from '@mui/material/colors/red';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { AddCompanyDocTemplateForm } from '@/stores/CompaniesStore';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, FileField, InputField } from '@/ui-kit/components';

interface IpAddressFormProps extends ContentModalProps {
  companyId: number;
  docTemplate?: AddCompanyDocTemplateForm & { id?: number };
  onAfterChange?: () => void;
}

function DocTemplatesFormModal(props: IpAddressFormProps) {
  const { open, onClose, docTemplate, companyId, onAfterChange } = props;
  const { isSavingIpAddress, addDocTemplate } = useStore('companiesStore');

  const docTemplateForm = useFormik<AddCompanyDocTemplateForm>({
    validateOnMount: false,
    initialValues: {
      name: docTemplate?.name ?? '',
      category: docTemplate?.category ?? '',
      file: null,
    },
    onSubmit: async (values) => {
      try {
        await addDocTemplate(companyId, values);

        onClose();

        notificationAPI.success('Шаблон успешно добавлен');

        onAfterChange?.();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    onReset: () => {},
    validate: ({ name, category, file }) => {
      const errors: Partial<Record<keyof AddCompanyDocTemplateForm, string>> = {};

      if (name.length === 0) {
        errors.name = 'Обязательно к заполнению';
      }

      if (category.length === 0) {
        errors.category = 'Обязательно к заполнению';
      }

      if (!file) {
        errors.file = 'Обязательно к заполнению';
      }

      return errors;
    },
  });

  function onCloseHandler(e?: SyntheticEvent) {
    e?.stopPropagation();
    e?.preventDefault();

    onClose(e);

    docTemplateForm.resetForm();
  }

  const onUploadHandler = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    docTemplateForm.setFieldValue('file', currentTarget.value);
  }, []);

  useEffect(() => {
    if (open) {
      docTemplateForm.resetForm({
        values: {
          name: docTemplate?.name ?? '',
          category: docTemplate?.category ?? '',
          file: null,
        },
      });
    }
  }, [open]);

  return (
    <ContentModal disableEscapeKeyDown={isSavingIpAddress} onClose={onCloseHandler} open={open}>
      <form onReset={onCloseHandler} onSubmit={docTemplateForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSavingIpAddress} onClose={onCloseHandler}>
          Создать шаблон документа
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                autoFocus
                disabled={isSavingIpAddress}
                error={!!docTemplateForm.errors.name}
                helperText={docTemplateForm.errors.name}
                label="Название"
                name="name"
                onChange={docTemplateForm.handleChange}
                value={docTemplateForm.values.name}
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                disabled={isSavingIpAddress}
                error={!!docTemplateForm.errors.category}
                helperText={docTemplateForm.errors.category}
                label="Категория"
                name="category"
                onChange={docTemplateForm.handleChange}
                value={docTemplateForm.values.category}
              />
            </Grid>

            <Grid item xs={12}>
              <FileField
                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                label="Прикрепить шаблон"
                name="file"
                onChange={onUploadHandler}
                value={docTemplateForm.values.file}
              />

              {docTemplateForm.errors.file && (
                <Typography color={red['800']} component="div" variant="caption">
                  {docTemplateForm.errors.file}
                </Typography>
              )}

              <div />
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSavingIpAddress} type="reset">
            Отмена
          </Button>

          <ButtonLoader isLoading={isSavingIpAddress} type="submit">
            Создать
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(DocTemplatesFormModal));
