import React, { Fragment, memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ListItemText, MenuList } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { notificationAPI } from '@/api/notificationAPI';
import { EulaCard } from '@/entities/eualCard';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { usePersonPageStore } from '@/routes/Main/OperatorPanel/PersonPage/PersonPageStore';
import { useStore } from '@/stores/StoreProvider';
import { ContentModal, ContentModalProps } from '@/ui-kit/components';

interface PrintEulasModalProps extends ContentModalProps {
  companyId: number;
}

function PrintEulasModal(props: PrintEulasModalProps) {
  const { open, onClose, companyId } = props;
  const { getCompanyById, printPersonEulaCard } = useStore('companiesStore');
  const { setGlobalFetchingState } = useStore('signInStore');
  const { person } = usePersonPageStore();

  const [isLoading, enableLoading, disableLoading] = useToggle();

  const [eulas, setEulas] = useState<EulaCard[]>([]);

  async function fetchCompanyById() {
    enableLoading();

    try {
      const company = await getCompanyById(companyId);

      if (company?.eulas) {
        setEulas(company.eulas);
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    disableLoading();
  }

  async function printCompanyEulaDoc(eulaId: number) {
    setGlobalFetchingState(true);

    try {
      await printPersonEulaCard(person?.id, eulaId);

      onClose();
    } catch (e) {
      notificationAPI.error(e);
    }

    setGlobalFetchingState(false);
  }

  function renderEulaItem(eula: EulaCard) {
    function onClickOnEula() {
      printCompanyEulaDoc(eula.id);
    }

    return (
      <MenuItem key={eula.id} onClick={onClickOnEula}>
        <ListItemText>{eula.name}</ListItemText>
      </MenuItem>
    );
  }

  useEffect(() => {
    if (open) {
      setEulas([]);

      fetchCompanyById();
    }
  }, [open]);

  useEffect(() => {
    if (eulas.length === 1) {
      printCompanyEulaDoc(eulas[0].id);
    }
  }, [eulas.length]);

  return (
    <ContentModal disableEscapeKeyDown={false} isLoading={isLoading} onClose={onClose} open={open}>
      <ContentModal.Header onClose={onClose}>Шаблоны печатных документов</ContentModal.Header>

      <ContentModal.Body>
        {!isLoading && (
          <Fragment>
            {eulas.length === 0 && <span>Шаблоны документов отсутствуют</span>}

            {eulas.length > 1 && <MenuList>{eulas.map(renderEulaItem)}</MenuList>}
          </Fragment>
        )}
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose}>
          Закрыть
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(PrintEulasModal));
