import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router';
import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { Group } from '@/entities/group';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps } from '@/ui-kit/components';

import GroupSelectorInput from '../GroupSelectorInput/GroupSelectorInput';

interface ReplaceGroupForm {
  group: Partial<Group>;
}

interface ReplaceGroupFormErrors {
  group: string;
}

function ReplaceGroupModal({ open, onClose }: ContentModalProps) {
  const { groupId } = useParams();
  const { isSaving, replaceInGroup } = useStore('groupsStore');
  const { selectedPersons, resetAllSelectedPersons, getPersons, getValidFilterData } = useStore('personsStore');

  const groupForm = useFormik<ReplaceGroupForm>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    initialValues: {
      group: {
        name: '',
        parent_id: 1,
      },
    },
    onSubmit: async ({ group }: ReplaceGroupForm, { resetForm }: FormikHelpers<ReplaceGroupForm>) => {
      if (group.id) {
        try {
          // если группы нет, `groupId === undefined`, то ставим 1 - группа по умолчанию
          await replaceInGroup(group.id, selectedPersons, groupId ? +groupId : 1);

          notificationAPI.success('Записи перемещены');

          resetAllSelectedPersons();

          resetForm();

          const validFilterData = getValidFilterData(queryAPI.params);

          await getPersons({
            ...validFilterData,
            groupId,
          });
        } catch (e) {
          notificationAPI.error(e);
        }
      }
    },
    onReset: () => onClose(),
    validate: ({ group }: ReplaceGroupForm) => {
      const errors: Partial<ReplaceGroupFormErrors> = {};

      if (group.name && group.name.length === 0) {
        errors.group = 'Обязательно к заполнению';
      }

      return errors;
    },
  });

  const onSelectGroup = useCallback((group: Group) => groupForm.setFieldValue('group', group), []);

  return (
    <ContentModal disableEscapeKeyDown={isSaving} maxWidth="xs" onClose={groupForm.handleReset} open={open}>
      <form onReset={groupForm.handleReset} onSubmit={groupForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSaving} onClose={groupForm.handleReset}>
          Переместить в группу
        </ContentModal.Header>

        <ContentModal.Body>
          <GroupSelectorInput
            disabled={isSaving}
            error={!!groupForm.errors.group}
            helperText={groupForm.errors.group as string}
            label="Группа"
            name="group"
            onSelectGroup={onSelectGroup}
            value={groupForm.values.group.name}
          />
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSaving} type="reset">
            Отмена
          </Button>

          <ButtonLoader disabled={!(groupForm.dirty && groupForm.isValid)} isLoading={isSaving} type="submit">
            Переместить
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(ReplaceGroupModal));
