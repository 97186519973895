import styled from '@mui/material/styles/styled';
import Tab from '@mui/material/Tab';

export const DocumentsTabsHeader = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;

export const DocumentsTabsTab = styled(Tab)`
  max-width: 270px;
`;

export const DocumentsTabsContent = styled('div')`
  display: flex;
  align-items: center;

  & span {
    width: 150px;
    padding-right: 15px;
  }
`;
