import AppBar from '@mui/material/AppBar';
import styled from '@mui/material/styles/styled';
import Tab from '@mui/material/Tab';

export const ProfileRoot = styled(AppBar)`
  flex-shrink: 0;
`;

export const ProfileTab = styled(Tab)`
  min-height: auto;
`;
