import { action, makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import { storageAPI } from '@/api/storageAPI';
import { NEW_PERSON_ID, ROUTE_HMS_PERSON } from '@/constants';
import { Address } from '@/entities/address';
import { Store } from '@/stores/Store';
import { getSignInPath } from '@/utils';

import { WEBSOCKET_HANDSHAKE_MAX_TRIES } from './SignInStore.constants';
import { PasswordRecoveryForm, RestorePasswordForm, SignInForm, UserCompanyData, UserData } from './SignInStore.types';

export class SignInStore {
  isIntegration = false;
  userToken = storageAPI.get('token');
  isGlobalFetching = false;
  isFetching = false;
  user = storageAPI.get('authData');
  userCompany: Nullable<UserCompanyData> = null;

  private readonly store: Store;
  private websocketHandshakeTryout = 0;

  constructor(store: Store) {
    makeAutoObservable(this);

    this.store = store;

    this.checkIntegration();
  }

  get userData() {
    return this.user;
  }

  get isSignedIn() {
    return Boolean(!!this.userToken || !!storageAPI.get('etoken'));
  }

  checkIntegration = () => {
    /*
     * В рамках интеграции пользователь может зарегистрировать персона без прохождения авторизации и навигации до страницы
     *
     * Храним это значение глобально в сторе, потому что используется в разных частях системы
     * */
    this.isIntegration = window.location.pathname.includes(`${ROUTE_HMS_PERSON}/${NEW_PERSON_ID}`);
  };

  subscribeToSocket = () => {
    try {
      const authData = storageAPI.get('authData');

      if (authData) {
        const { id } = authData;

        if (this.websocketHandshakeTryout !== WEBSOCKET_HANDSHAKE_MAX_TRIES) {
          this.store.socket.send(`{"id":${id}}`);
        }
      }
    } catch (e) {
      console.warn('Ошибка подключения к websocket', { e });

      this.websocketHandshakeTryout += 1;

      setTimeout(() => {
        this.subscribeToSocket();
      }, 1000);
    }
  };

  setGlobalFetchingState = (nextState: boolean) => {
    if (nextState) {
      this.isGlobalFetching = true;
    } else {
      // если `nextState === false` делаем задержку для закрытия,
      // чтобы не было моргания, и анимация отработала плавно
      setTimeout(() => {
        runInAction(() => {
          this.isGlobalFetching = false;
        });
      }, 1000);
    }
  };

  signIn = (body: SignInForm, shouldShowRender = true) => {
    if (shouldShowRender) {
      this.isFetching = true;
    }

    return fetchApi
      .post<UserData>('/user/auth/', { body })
      .then(
        action((userData) => {
          storageAPI.set('authData', userData);
          storageAPI.set('token', userData.token);

          this.user = userData;
          this.userToken = userData.token;

          return userData;
        }),
      )
      .finally(
        action(() => {
          this.isFetching = false;
        }),
      );
  };

  signOut = () => {
    this.isFetching = true;

    return fetchApi.post('/user/logout/').then(() => {
      storageAPI.remove('authData');
      storageAPI.remove('token');

      window.location.href = getSignInPath();
    });
  };

  restorePassword = (body: RestorePasswordForm) => {
    this.isFetching = true;

    return fetchApi.post('/user/recover-password/', { body }).finally(() => {
      this.isFetching = false;
    });
  };

  getUserByToken = (body: { token: string }) => {
    this.isFetching = true;

    return fetchApi
      .post<UserData>('/user/recover-token/', { body })
      .then((userData) => {
        storageAPI.set('recoverToken', userData.token);

        return userData;
      })
      .finally(() => {
        this.isFetching = false;
      });
  };

  recoverPassword = (id: number, body: Pick<PasswordRecoveryForm, 'password'>) => {
    this.isFetching = true;

    return fetchApi
      .put(`/users/${id}/`, {
        body,
        headers: {
          'user-token': storageAPI.get('recoverToken'),
        },
      })
      .finally(() => {
        this.isFetching = false;
      });
  };

  setUserData = (key: keyof UserData, value: StringOrNumber | boolean) => {
    const userData: Nullable<Record<keyof UserData, StringOrNumber | boolean>> = this.userData;

    if (userData) {
      userData[key] = value;

      storageAPI.set('authData', userData);

      this.user = userData;
    }
  };

  getUserCompany = async () => {
    if (this.userCompany) {
      return;
    }

    const companyId = this.userData?.company_id;

    if (companyId) {
      const company = await this.store.companiesStore.getCompanyById(companyId);
      let urAddress: Address | undefined;
      let factAddress: Address | undefined;

      try {
        if (company.ur_fias_code2 && company.ur_region_code) {
          urAddress = await this.store.dictionariesStore.getStreetObjectById(
            company.ur_fias_code2,
            company.ur_region_code,
          );
        }

        if (company.fias_code2 && company.region_code) {
          factAddress = await this.store.dictionariesStore.getStreetObjectById(company.fias_code2, company.region_code);
        }
      } catch (e) {
        // do nothing
      }

      runInAction(() => {
        this.userCompany = {
          company,
          urAddress,
          factAddress,
        };
      });
    }
  };
}
