import { isValid } from 'date-fns';

import { DEFAULT_PAGINATION_LIMIT } from '@/constants';
import { GetPersonsArguments, PersonStatus } from '@/stores/PersonsStore';
import { getValidDateValue } from '@/utils';

export function getValidFilterData(query: Partial<Record<keyof GetPersonsArguments, string>>): GetPersonsArguments {
  const validatedFilterData: Partial<GetPersonsArguments> = {};

  const limit = query.limit && !isNaN(+query.limit) ? +query.limit : DEFAULT_PAGINATION_LIMIT;
  const page = query.page && !isNaN(+query.page) ? +query.page : 0;

  if (query.name) {
    validatedFilterData.name = query.name;
  }

  if (query.status && !isNaN(+query.status)) {
    if (
      +query.status === PersonStatus.Pending ||
      +query.status === PersonStatus.Accepted ||
      +query.status === PersonStatus.Exported
    ) {
      validatedFilterData.status = +query.status;
    }
  }

  if (query.hms_status && !isNaN(+query.hms_status)) {
    validatedFilterData.hms_status = +query.hms_status;
  }

  if (query.citizenship) {
    validatedFilterData.citizenship = +query.citizenship;
  }

  if (query.creationFrom) {
    const creationFromDate = new Date(query.creationFrom);

    if (isValid(creationFromDate)) {
      const validCreationFromDate = getValidDateValue(creationFromDate);

      if (validCreationFromDate) {
        validatedFilterData.creationFrom = new Date(validCreationFromDate);
      }
    }
  }

  if (query.creationTo) {
    const creationToDate = new Date(query.creationTo);

    if (isValid(creationToDate)) {
      const validCreationToDate = getValidDateValue(creationToDate);

      if (validCreationToDate) {
        validatedFilterData.creationTo = new Date(validCreationToDate);
      }
    }
  }

  if (query.stayFrom) {
    const stayFromDate = new Date(query.stayFrom);

    if (isValid(stayFromDate)) {
      const validStayFromDate = getValidDateValue(stayFromDate);

      if (validStayFromDate) {
        validatedFilterData.stayFrom = new Date(validStayFromDate);
      }
    }
  }

  if (query.stayTo) {
    const stayToDate = new Date(query.stayTo);

    if (isValid(stayToDate)) {
      const validStayToDate = getValidDateValue(stayToDate);

      if (validStayToDate) {
        validatedFilterData.stayTo = new Date(validStayToDate);
      }
    }
  }

  if (query.doc_number) {
    validatedFilterData.doc_number = query.doc_number;
  }

  if (query.room_number) {
    validatedFilterData.room_number = query.room_number;
  }

  return {
    limit,
    page,
    ...validatedFilterData,
  } as GetPersonsArguments;
}
