import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { Device } from '@/entities/device';
import { User } from '@/entities/user';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import AttachUserModal from './AttachUserModal/AttachUserModal';
import UserRow from './UserRaw/UserRaw';
import { UsersList, UsersListContent, UsersRoot } from './Users.style';

interface UsersProps {
  device: Device;
}

function Users(props: UsersProps) {
  const { device } = props;
  const { isAttaching, detach, getDevices } = useStore('devicesStore');

  const [isAttachOpen, , , toggleAttachModal] = useToggle();

  async function onDetachUser(user: User) {
    try {
      if (user.id) {
        await detach(device.id, user.id);

        notificationAPI.success(`Пользователь "${user.name}" откреплен от "${device.name}"`);

        await getDevices(false, device.companyId);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  return (
    <UsersRoot>
      <Typography gutterBottom variant="subtitle2">
        Пользователи
      </Typography>

      {device.users && (
        <UsersList>
          <BackdropLoader isLoading={isAttaching} />

          <UsersListContent>
            {device.users
              .filter(({ company_id }) => company_id === device.companyId)
              .map((user) => (
                <UserRow key={user.id} onDetach={onDetachUser} user={user} />
              ))}
          </UsersListContent>
        </UsersList>
      )}

      <div>
        <Button onClick={toggleAttachModal} size="small" startIcon={<AddIcon />} variant="outlined">
          Прикрепить
        </Button>
      </div>

      <AttachUserModal device={device} onClose={toggleAttachModal} open={isAttachOpen} />
    </UsersRoot>
  );
}

export default memo(observer(Users));
