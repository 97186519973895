import styled from '@mui/material/styles/styled';
import TableCell from '@mui/material/TableCell';

import { DatepickerField, SelectField } from '@/ui-kit/components';

export const FilterRowCell = styled(TableCell)`
  border-bottom-width: 0;
`;

export const FilterRowStatus = styled(SelectField)`
  width: 90%;
  margin-right: 16px;
  margin-top: 14px;
`;

export const FilterRowDatepicker = styled(DatepickerField)`
  margin-bottom: 5px;
`;
