import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import AssignmentIcon from '@mui/icons-material/Assignment';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { usePersonPageStore } from '../../../PersonPageStore';

interface PrintDocButtonProps {
  docId: StringOrNumber;
}

function PrintRegForm({ docId }: PrintDocButtonProps) {
  const { printDocById } = usePersonPageStore();

  return (
    <Tooltip title="Печать регистрационной карты">
      <IconButton onClick={() => printDocById(docId)}>
        <AssignmentIcon />
      </IconButton>
    </Tooltip>
  );
}

export default memo(observer(PrintRegForm));
