import styled from '@mui/material/styles/styled';

export const EulaCardsRoot = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const EulaCardsHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EulaCardsContent = styled('div')`
  flex: 1;
  overflow: hidden;
`;

export const EulaCardsList = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
