import React, { memo } from 'react';

import DevicesList from '@/shared/Devices/DevicesList/DevicesList';
import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';

import AdminPanelTabs from '../AdminPanelTabs/AdminPanelTabs';

function AllDevices() {
  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Все устройства']} />}
      content={<DevicesList isAdmin />}
      contentSettings={{
        overflow: 'hidden',
      }}
      header={<AdminPanelTabs />}
    />
  );
}

export default memo(AllDevices);
