import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { Company } from '@/entities/company';
import { KSDocTemplateModel } from '@/entities/KSDocTemplate';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import { DocTemplatesContent, DocTemplatesHeader, DocTemplatesRoot, DocTemplatesTable } from './DocTemplates.style';
import DocTemplatesFormModal from './DocTemplatesFormModal/DocTemplatesFormModal';
import DocTemplatesRow from './DocTemplatesRow/DocTemplatesRow';

interface DocTemplatesProps {
  companyId: number;
}

function DocTemplates(props: DocTemplatesProps) {
  const { companyId } = props;
  const { isFetchingCompanyById, companyByIdMap, getCompanyById } = useStore('companiesStore');

  const [isDocTemplatesModalOpen, openDocTemplatesModal, closeDocTemplatesModal] = useToggle();
  const [docTemplates, setDocTemplates] = useState<KSDocTemplateModel[]>([]);

  const company: Company | undefined = companyByIdMap.get(companyId);

  async function fetchCompanyById() {
    try {
      await getCompanyById(companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (company) {
      setDocTemplates(company.doc_templates ?? []);
    } else {
      fetchCompanyById();
    }
  }, [JSON.stringify(company)]);

  return (
    <DocTemplatesRoot>
      <BackdropLoader isLoading={isFetchingCompanyById} />

      <DocTemplatesHeader>
        <Typography>Шаблоны документов</Typography>

        <Button color="primary" onClick={openDocTemplatesModal} size="small" variant="contained">
          Добавить шаблон
        </Button>
      </DocTemplatesHeader>

      <DocTemplatesContent>
        <DocTemplatesTable>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>

                <TableCell>Категория</TableCell>

                <TableCell width="20" />
              </TableRow>
            </TableHead>

            <TableBody>
              {docTemplates.length > 0 &&
                docTemplates.map((docTemplate: KSDocTemplateModel) => (
                  <DocTemplatesRow
                    companyId={companyId}
                    docTemplate={docTemplate}
                    key={docTemplate.id}
                    onAfterChange={fetchCompanyById}
                  />
                ))}

              {docTemplates.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Typography align="center">Шаблоны отсутствуют</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <DocTemplatesFormModal
            companyId={companyId}
            onAfterChange={fetchCompanyById}
            onClose={closeDocTemplatesModal}
            open={isDocTemplatesModalOpen}
          />
        </DocTemplatesTable>
      </DocTemplatesContent>
    </DocTemplatesRoot>
  );
}

export default memo(observer(DocTemplates));
