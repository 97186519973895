import React, { SyntheticEvent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogTitleProps } from '@mui/material/DialogTitle/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { ContentModalHeaderClose, ContentModalHeaderContent } from './ContentModalHeader.style';

export interface ContentModalHeaderProps extends DialogTitleProps {
  onClose?: (e?: SyntheticEvent, reason?: string) => void;
  isCloseDisabled?: boolean;
  align?: 'left' | 'center' | 'right';
}

function ContentModalHeader(props: ContentModalHeaderProps) {
  const { children, onClose, isCloseDisabled = false, align = 'left', ...rest } = props;

  return (
    <DialogTitle {...rest}>
      <ContentModalHeaderContent align={align} hasCloseButton={Boolean(onClose)}>
        {children}
      </ContentModalHeaderContent>

      {onClose && (
        <ContentModalHeaderClose>
          <IconButton disabled={isCloseDisabled} onClick={onClose} type="button">
            <CloseIcon />
          </IconButton>
        </ContentModalHeaderClose>
      )}
    </DialogTitle>
  );
}

export default ContentModalHeader;
