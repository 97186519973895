import { differenceInCalendarDays, isFuture } from 'date-fns';

import { DocType } from '@/entities/dictionary';
import { PersonDocument } from '@/stores/PersonsStore';

import { docsIds1, docsIds2, docsIds3, docsIds4 } from './RightOfStay.constant';

export function filterDocumentsByCitizenship(docs: DocType[], citizenshipId: number): DocType[] {
  // IDs стран
  // 253 - Лицо без гражданства
  // 254 - Не гражданин Латвии
  // 255 - Не гражданин Эстонии

  // IDs документов
  // 31 - Вид на жительство ИГ
  // 32 - Вид на жительство ЛБГ
  // 33 - Виза
  // 34 - Разрешение на временное проживание ИГ
  // 35 - Разрешение на временное проживание ЛБГ
  // 43 - Электронная виза
  // 44 - Разрешение на временное проживание в целях получения образования

  const noCitizenshipIDsMap = new Map([
    [32, true],
    [33, true],
    [35, true],
    [43, true],
  ]);

  const notLatviaCitizenIDsMap = new Map([
    [31, true],
    [33, true],
    [34, true],
    [43, true],
    [44, true],
  ]);

  const otherCitizenshipIDsMap = new Map([
    [31, true],
    [33, true],
    [34, true],
    [43, true],
    [44, true],
  ]);

  if (citizenshipId === 253) {
    return docs.filter(({ id }) => noCitizenshipIDsMap.has(id));
  }

  if (citizenshipId === 254 || citizenshipId === 255) {
    return docs.filter(({ id }) => notLatviaCitizenIDsMap.has(id));
  }

  return docs.filter(({ id }) => otherCitizenshipIDsMap.has(id));
}

export function validateRightOfStayValues(values?: Partial<PersonDocument>): Record<string, boolean> {
  const errors: Record<string, boolean> = {};

  if (!values?.document_type_enum_id) {
    return errors;
  }

  if (docsIds1.includes(+values.document_type_enum_id)) {
    errors.doc_serial = values.doc_serial?.length === 0 ?? false;
    errors.doc_number = values.doc_number?.length === 0 ?? false;
    errors.doc_issue_date = !values.doc_issue_date;
  }

  if (docsIds2.includes(+values.document_type_enum_id) && values.document_type_enum_id !== 33) {
    errors.doc_issue_authority = values.doc_issue_authority?.length === 0 ?? false;
    errors.doc_decision_number = values.doc_decision_number?.length === 0 ?? false;
  }

  if (docsIds3.includes(+values.document_type_enum_id)) {
    errors.doc_start_date = !values.doc_start_date;
    errors.doc_expire_date = !values.doc_expire_date;
  }

  if (docsIds4.includes(+values.document_type_enum_id)) {
    errors.doc_id = values.doc_id?.length === 0 ?? false;
    errors.doc_issue_date = !values.doc_issue_date;
    errors.doc_visa_type_id = !values.doc_visa_type_id;
    errors.doc_visa_visit_type_id = !values.doc_visa_visit_type_id;
    errors.doc_visa_multi_type_id = !values.doc_visa_multi_type_id;
  }

  return errors;
}

export function checkRightOfStayData(values?: Partial<PersonDocument>): Record<string, string> {
  const newErrors: Record<string, string> = {};

  if (!values) {
    return newErrors;
  }

  // виза
  if (values.document_type_enum_id === 33) {
    if (values.doc_number && (isNaN(+values.doc_number) || `${values.doc_number}`.length !== 7)) {
      newErrors.doc_number = 'Длина поля должна быть равна 7 символам и содержать только цифры';
    }

    if (values.doc_issue_date) {
      if (isFuture(new Date(values.doc_issue_date))) {
        newErrors.doc_issue_date = 'Дата выдачи не может быть позднее текущей даты';
      }

      if (values.doc_start_date) {
        if (differenceInCalendarDays(new Date(values.doc_issue_date), new Date(values.doc_start_date)) > 0) {
          newErrors.doc_start_date = 'Срок действия не может быть ранее даты выдачи';
        }
      }

      if (values.doc_expire_date) {
        if (differenceInCalendarDays(new Date(values.doc_issue_date), new Date(values.doc_expire_date)) > 0) {
          newErrors.doc_expire_date = 'Срок действия не может быть ранее даты выдачи';
        }
      }
    }
  }

  // вид на жительство ИГ
  if (values.document_type_enum_id === 31) {
    if (values.doc_serial && (+values.doc_serial < 82 || +values.doc_serial > 83)) {
      newErrors.doc_serial = 'Значение должно быть равным 82 или 83';
    }

    if (values.doc_number && (isNaN(+values.doc_number) || `${values.doc_number}`.length !== 7)) {
      newErrors.doc_number = 'Длина поля должна быть равна 7 символам и содержать только цифры';
    }

    if (values.doc_issue_date) {
      if (isFuture(new Date(values.doc_issue_date))) {
        newErrors.doc_issue_date = 'Дата выдачи не может быть позднее текущей даты';
      }

      if (values.doc_expire_date) {
        if (differenceInCalendarDays(new Date(values.doc_issue_date), new Date(values.doc_expire_date)) > 0) {
          newErrors.doc_expire_date = 'Срок действия не может быть ранее даты выдачи';
        }
      }
    }

    if (values.doc_start_date) {
      if (isFuture(new Date(values.doc_start_date))) {
        newErrors.doc_start_date = 'Дата решения не может быть позднее текущей даты';
      }
    }
  }

  // разрешение на временное проживание ИГ
  if (values.document_type_enum_id === 34 || values.document_type_enum_id === 44) {
    if (values.doc_issue_date) {
      if (isFuture(new Date(values.doc_issue_date))) {
        newErrors.doc_issue_date = 'Дата выдачи не может быть позднее текущей даты';
      }

      if (values.doc_expire_date) {
        if (differenceInCalendarDays(new Date(values.doc_issue_date), new Date(values.doc_expire_date)) > 0) {
          newErrors.doc_expire_date = 'Срок действия не может быть ранее даты выдачи';
        }
      }
    }
  }

  // электронная виза
  if (values.document_type_enum_id === 43) {
    if (values.doc_issue_date) {
      if (isFuture(new Date(values.doc_issue_date))) {
        newErrors.doc_issue_date = 'Дата выдачи не может быть позднее текущей даты';
      }

      if (values.doc_expire_date) {
        if (differenceInCalendarDays(new Date(values.doc_issue_date), new Date(values.doc_expire_date)) > 0) {
          newErrors.doc_expire_date = 'Срок действия не может быть ранее даты выдачи';
        }
      }
    }

    if (values.doc_start_date) {
      if (isFuture(new Date(values.doc_start_date))) {
        newErrors.doc_start_date = 'Дата решения не может быть позднее текущей даты';
      }
    }

    if (values.doc_issue_date) {
      if (isFuture(new Date(values.doc_issue_date))) {
        newErrors.doc_issue_date = 'Дата решения не может быть позднее текущей даты';
      }
    }
  }

  return newErrors;
}
