import { useCallback, useState } from 'react';

type UseToggleResult = [
  boolean, // состояние
  () => void, // для переключения состояние в true
  () => void, // для переключения состояние в false
  () => void, // переключатель с true->false и false->true
];

export function useToggle(initialState = false): UseToggleResult {
  const [state, setState] = useState(initialState);

  const setTrueState = useCallback(() => setState(true), []);
  const setFalseState = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState((currentState: boolean) => !currentState), []);

  return [state, setTrueState, setFalseState, toggle];
}
