import React, { Fragment, memo } from 'react';
import Button from '@mui/material/Button';

import { useToggle } from '@/hooks/useToggle/useToggle';
import RoleFormModal from '@/shared/RoleFormModal/RoleFormModal';
import RolesTable from '@/shared/RolesTable/RolesTable';
import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';

import AdminPanelTabs from '../AdminPanelTabs/AdminPanelTabs';

import { RolesSubHeader } from './Roles.style';

function Roles() {
  const [isCreateRoleOpen, , , onToggleAddRoleModalHandler] = useToggle();

  return (
    <Fragment>
      <RouteConstructor
        breadcrumbs={<BreadcrumbsWrapper crumbs={['Роли']} />}
        content={<RolesTable isAdmin />}
        header={<AdminPanelTabs />}
        subHeader={
          <RolesSubHeader>
            <Button color="primary" onClick={onToggleAddRoleModalHandler} size="small" variant="contained">
              Создать роль
            </Button>
          </RolesSubHeader>
        }
      />

      <RoleFormModal onClose={onToggleAddRoleModalHandler} open={isCreateRoleOpen} type="create" />
    </Fragment>
  );
}

export default memo(Roles);
