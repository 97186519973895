import {
  ROUTE_COMPANY_DATA_PATH,
  ROUTE_COMPANY_DEVICES_PATH,
  ROUTE_COMPANY_GENERAL_PATH,
  ROUTE_COMPANY_SETTINGS_PATH,
  ROUTE_COMPANY_USERS_PATH,
} from '@/constants';

export const COMPANY_DATA_ROUTES_MAP = new Map([
  [0, ROUTE_COMPANY_GENERAL_PATH],
  [1, ROUTE_COMPANY_DEVICES_PATH],
  [2, ROUTE_COMPANY_USERS_PATH],
  [3, ROUTE_COMPANY_SETTINGS_PATH],
  [4, ROUTE_COMPANY_DATA_PATH],
]);
