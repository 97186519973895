import { format, isValid } from 'date-fns';

import { DATE_HUMAN_FORMAT } from '@/constants';

export abstract class RetentionPersonModel {
  created_at!: string;
  fname!: string;
  fname_lat!: string;
  hotel_person_id!: string;
  lname!: string;
  lname_lat!: string;
  mname!: string;
  mname_lat!: string;
  person_id!: number;
  reason!: number;
  stay_from!: string;
  stay_till!: string;
}

export class RetentionPerson extends RetentionPersonModel {
  constructor(person: RetentionPersonModel) {
    super();

    Object.keys(person).forEach((key) => {
      switch (key) {
        default:
          this[key] = person[key];

          break;
      }
    });
  }

  get personName() {
    return `${this.fname} ${this.lname}`;
  }

  get personNameLat() {
    return `${this.fname_lat} ${this.lname_lat}`;
  }

  get stayFrom() {
    const date = new Date(this.stay_from);

    return isValid(date) ? format(date, DATE_HUMAN_FORMAT) : '';
  }

  get stayTill() {
    const date = new Date(this.stay_till);

    return isValid(date) ? format(date, DATE_HUMAN_FORMAT) : '';
  }
}
