import React, { ChangeEvent, memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import ImageIcon from '@mui/icons-material/Image';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import { KSStatusValue, KSVisibleValue } from '@/stores/ReportsStore';
import { useStore } from '@/stores/StoreProvider';
import { CheckboxField, DatepickerField, InputField, SelectField } from '@/ui-kit/components';

function KSListFilter() {
  const { ksListFilter, setKSFilter, selectedKSItem, exportSelectedKSItems, exportSelectedKSItemsWithImages } =
    useStore('reportsStore');

  const onChangeFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setKSFilter(
      {
        [e.target.name]: e.target.value,
        page: 0, // сбрасываем страницу при применении фильтра
      },
      // если меняем поле ФИО и оно не пустое - снова запрашиваем данные через секунду
      e.target.name === 'name' && e.target.value ? 1000 : 0,
    );
  }, []);

  const onChangeEquality = useCallback(() => {
    setKSFilter({
      not_equal: !ksListFilter.not_equal,
      page: 0, // сбрасываем страницу при применении фильтра
    });
  }, [ksListFilter.not_equal]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid alignItems="flex-end" container spacing={2}>
          <Grid item xs={2}>
            <DatepickerField
              name="timeline"
              onChange={onChangeFilter}
              slotProps={{
                textField: {
                  // по факту работает, но на типы ругается
                  // @ts-ignore
                  readOnly: true,
                },
              }}
              value={ksListFilter.timeline}
              views={['month', 'year']}
            />
          </Grid>

          <Grid item xs={2}>
            <SelectField label="Статус" name="status" onChange={onChangeFilter} value={ksListFilter.status}>
              <MenuItem value={KSStatusValue.None}>-</MenuItem>

              {/* <MenuItem value={KSStatusValue.Unknown}>Значение не указано</MenuItem> */}

              {/* <MenuItem value={KSStatusValue.NotPaid}>Не оплачено</MenuItem> */}

              <MenuItem value={KSStatusValue.Paid}>Плательщик</MenuItem>

              <MenuItem value={KSStatusValue.UnderAge}>Несовершеннолетний гость</MenuItem>

              <MenuItem value={KSStatusValue.ShortStay}>Срок проживания менее суток</MenuItem>

              <MenuItem value={KSStatusValue.Decline}>Отказ от оплаты</MenuItem>
            </SelectField>
          </Grid>

          <Grid item xs={2}>
            <SelectField
              label="Выгружен в АИС КС"
              name="ais_sync"
              onChange={onChangeFilter}
              value={ksListFilter.ais_sync}
            >
              <MenuItem value={KSVisibleValue.All}>Показать всех</MenuItem>

              <MenuItem value={KSVisibleValue.Exported}>Только выгруженные</MenuItem>

              <MenuItem value={KSVisibleValue.NotExported}>Не выгружены</MenuItem>
            </SelectField>
          </Grid>

          <Grid item xs={2}>
            <InputField clearable label="ФИО" name="name" onChange={onChangeFilter} value={ksListFilter.name} />
          </Grid>

          <Grid item xs="auto">
            <CheckboxField
              checked={ksListFilter.not_equal}
              color="primary"
              label="Содержит ошибки"
              onChange={onChangeEquality}
            />
          </Grid>

          <Grid item marginLeft="auto" xs="auto">
            <ButtonGroup disabled={selectedKSItem.size === 0} variant="contained">
              <Button onClick={() => exportSelectedKSItems()}>Выгрузить в АИС КС</Button>

              <Tooltip title="Выгрузить + подтверждающие документы">
                <Button onClick={() => exportSelectedKSItemsWithImages()} size="small">
                  <ImageIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(observer(KSListFilter));
