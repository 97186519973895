import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';

import { notificationAPI } from '@/api/notificationAPI';
import { Person } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, InputField } from '@/ui-kit/components';

import LegalRepresentativeRow from './LegalRepresentativeRaw/LegalRepresentativeRaw';
import { PersonsList } from './LegalRepresentativeSelector.style';

interface LegalRepresentativeSelectorProps {
  filteredPersonId?: string;
  onSelect: (person: Nullable<Person>) => void;
  legalRepresentativeId?: number;
}

function LegalRepresentativeSelector(props: LegalRepresentativeSelectorProps) {
  const { filteredPersonId, legalRepresentativeId, onSelect } = props;
  const { isFetching, legalRepresentativePersons, getLegalRepresentativePersons } = useStore('personsStore');

  const [filter, setFilter] = useState('');

  async function fetchLegalRepresentativePersons() {
    try {
      await getLegalRepresentativePersons();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    fetchLegalRepresentativePersons();
  }, []);

  const onChangeFilter = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => setFilter(currentTarget.value),
    [],
  );

  const onSelectHandler = useCallback(
    (person: Person) => {
      onSelect(legalRepresentativeId === person.id ? null : person);
    },
    [legalRepresentativeId],
  );

  const persons = useMemo(() => {
    let result = [...legalRepresentativePersons];

    if (filter.length > 0) {
      result = legalRepresentativePersons.filter((person: Person) =>
        `${person.lname} ${person.mname} ${person.fname} ${person.lname_lat} ${person.fname_lat} ${person.mname_lat}`
          .toLowerCase()
          .includes(filter),
      );
    } else {
      result = legalRepresentativePersons.slice(0, 3);
    }

    return result.filter((person: Person) => (filteredPersonId ? person.id !== +filteredPersonId : true));
  }, [filter, legalRepresentativePersons.length]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputField clearable onChange={onChangeFilter} placeholder="Фамилия Имя" value={filter} />
      </Grid>

      <PersonsList item xs={12}>
        <BackdropLoader isLoading={isFetching} />

        {persons.map((person: Person) => (
          <LegalRepresentativeRow
            key={person.id}
            onSelect={onSelectHandler}
            person={person}
            selected={legalRepresentativeId === person.id}
          />
        ))}
      </PersonsList>
    </Grid>
  );
}

export default memo(observer(LegalRepresentativeSelector));
