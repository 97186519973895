import styled from '@mui/material/styles/styled';

export const EulaFileWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  min-height: 31px;
  display: flex;
  align-items: center;

  & input {
    position: absolute;
    top: -1000px;
    left: -1000px;
    opacity: 0
  },
`;
