import React, { ChangeEvent, Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { useMount } from '@/hooks/useMount/useMount';
import { useScroll } from '@/hooks/useScroll/useScroll';
import { useToggle } from '@/hooks/useToggle/useToggle';
import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';
import { useStore } from '@/stores/StoreProvider';
import { CheckboxField, InputField } from '@/ui-kit/components';

import AdminPanelTabs from '../AdminPanelTabs/AdminPanelTabs';

import { CompaniesFilter, CompaniesSubHeader, IntegrationFilter } from './Companies.style';
import CompanyTableRow from './CompanyTableRow/CompanyTableRaw';
import CreateCompanyModal from './CreateCompanyModal/CreateCompanyModal';

function Companies() {
  const { scrollToAnchor } = useScroll();
  const { hash } = useLocation();
  const { isFetching, companies, getCompanies } = useStore('companiesStore');
  const { hmsList } = useStore('dictionariesStore');

  const [filter, setFilter] = useState(queryAPI.getKey('filter') ?? '');
  const [withDebt, , , toggleWithDebt] = useToggle(queryAPI.has('withDebt'));
  const [withKS, , , toggleKS] = useToggle(queryAPI.has('ks'));
  const [withTesting, , , toggleWithTesting] = useToggle(queryAPI.has('withTesting'));
  const [hmsFilter, setHMSFilter] = useState<StringOrNumber>(
    queryAPI.has('hmsFilter') ? +queryAPI.getKey('hmsFilter') : '',
  );

  const [isAddCompanyOpen, openAddCompanyModal, closeAddCompanyModal] = useToggle();

  async function fetchCompaniesData() {
    try {
      if (!isFetching) {
        await getCompanies();

        scrollToAnchor(hash);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function updateQueryString() {
    queryAPI.set({
      filter,
      hmsFilter,
      withDebt: withDebt ? '1' : null,
      withTesting: withTesting ? '1' : null,
      ks: withKS ? '1' : null,
    });
  }

  useMount(fetchCompaniesData);

  useEffect(() => {
    updateQueryString();
  }, [filter, withDebt, withKS, withTesting, hmsFilter]);

  const onChangeFilter = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => setFilter(currentTarget.value),
    [],
  );

  const filteredCompanies = useMemo(() => {
    const result = companies
      .filter(({ payment_debt }) => (withDebt ? Boolean(payment_debt) : true))
      .filter(({ testing }) => (withTesting ? Boolean(testing) : true))
      .filter(({ ks }) => (withKS ? Boolean(ks) : true))
      .filter(({ hms }) => (hmsFilter !== '' ? hms === hmsFilter : true));

    if (!filter) {
      return result;
    }

    return result.filter(
      ({ name, ur_name }) =>
        name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
        ur_name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()),
    );
  }, [JSON.stringify(companies), filter, withDebt, withKS, withTesting, hmsFilter]);

  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Компании']} />}
      content={
        <Fragment>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width="22%">Юридическое наименование</TableCell>

                <TableCell width="22%">Торговое наименование</TableCell>

                <TableCell width="19%">Выгрузка</TableCell>

                <TableCell width="8%">Тестирование</TableCell>

                <TableCell width="8%">Долг по оплате</TableCell>

                <TableCell width="5%" />
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredCompanies.map((company) => (
                <CompanyTableRow company={company} key={company.id} />
              ))}
            </TableBody>
          </Table>

          <CreateCompanyModal onClose={closeAddCompanyModal} open={isAddCompanyOpen} />
        </Fragment>
      }
      header={<AdminPanelTabs />}
      isLoading={isFetching}
      subHeader={
        <CompaniesSubHeader>
          <CompaniesFilter>
            <InputField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="inherit" />
                  </InputAdornment>
                ),
              }}
              clearable
              name="filter"
              onChange={onChangeFilter}
              size="small"
              value={filter}
            />

            <CheckboxField checked={withTesting} color="primary" label="Тестирование" onChange={toggleWithTesting} />

            <CheckboxField checked={withDebt} color="primary" label="Долг по оплате" onChange={toggleWithDebt} />

            <CheckboxField checked={withKS} color="primary" label="КС" onChange={toggleKS} />

            <FormControlLabel
              control={
                <IntegrationFilter
                  fullWidth={false}
                  name="hms"
                  onChange={({ target }) => setHMSFilter(target.value)}
                  value={hmsFilter}
                >
                  <MenuItem value="">-</MenuItem>

                  <MenuItem value={0}>Без интеграции</MenuItem>

                  {hmsList.map((hmsType) => (
                    <MenuItem key={hmsType.id} value={hmsType.id}>
                      {hmsType.name}
                    </MenuItem>
                  ))}
                </IntegrationFilter>
              }
              label="Интеграция"
              labelPlacement="start"
            />
          </CompaniesFilter>

          <Button color="primary" onClick={openAddCompanyModal} size="small" variant="contained">
            Добавить компанию
          </Button>
        </CompaniesSubHeader>
      }
    />
  );
}

export default memo(observer(Companies));
