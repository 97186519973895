import {
  ROUTE_BOOKINGS,
  ROUTE_KS_LIST,
  ROUTE_KS_REPORT,
  ROUTE_REGISTRATIONS,
  ROUTE_REMOVED,
  ROUTE_VERIFICATION,
} from '@/constants';

export enum WorkplaceRoute {
  Registrations = 0,
  Bookings = 1,
  Verification = 2,
  KSList = 3,
  KSReport = 4,
  Removed = 5,
}

export const WORKPLACE_ROUTES_MAPPING = new Map([
  [WorkplaceRoute.Registrations, ROUTE_REGISTRATIONS],
  [WorkplaceRoute.Bookings, ROUTE_BOOKINGS],
  [WorkplaceRoute.Verification, ROUTE_VERIFICATION],
  [WorkplaceRoute.KSList, ROUTE_KS_LIST],
  [WorkplaceRoute.KSReport, ROUTE_KS_REPORT],
  [WorkplaceRoute.Removed, ROUTE_REMOVED],
]);
