import { CSSProperties } from 'react';

export const PRINT_STYLES: Record<string, CSSProperties> = {
  page: {
    padding: '4% 8%',
  },
  pageContent: {
    margin: '0',
    paddingLeft: '10px',
    overflow: 'hidden',
  },
  // заголовок первого уровня
  title1: {
    fontSize: 15,
    margin: '0 0 2px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  // заголовок второго уровня
  title2: {
    fontSize: 15,
    textAlign: 'center',
    textTransform: 'uppercase',
    margin: '0px',
  },
  title3: {
    fontSize: 13,
    textAlign: 'center',
    margin: '0px',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 2px 1px',
    flexWrap: 'wrap',
    fontSize: 13,
    letterSpacing: '-1px',
    lineHeight: 1.05,
  },
  charSquare: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '1px',
    width: 19,
    height: 18.5,
    border: '1px solid black',
    textTransform: 'capitalize',
    fontSize: 12,
    boxSizing: 'border-box',
  },
  pageBreak: {
    pageBreakAfter: 'always',
  },
  bottomLineCell: {
    display: 'inline-block',
    borderBottom: '1px solid black',
    padding: '0 10px',
    minWidth: 50,
  },
};
