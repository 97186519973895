import {
  ID_CARD_DOCS_IDS_1,
  ID_CARD_DOCS_IDS_2,
  ID_CARD_DOCS_IDS_3,
  ID_CARD_DOCS_IDS_4,
  ID_CARD_DOCS_IDS_5,
  ID_CARD_DOCS_IDS_6,
  ID_CARD_DOCS_IDS_7,
  PersonDocument,
} from '@/stores/PersonsStore';

export function validateIdCardValues(values?: Partial<PersonDocument>): Record<string, boolean> {
  const newErrors: Record<string, boolean> = {};

  if (!values?.document_type_enum_id) {
    return newErrors;
  }

  newErrors.doc_number = values.doc_number?.length === 0 ?? false;

  if (ID_CARD_DOCS_IDS_3.includes(+values.document_type_enum_id)) {
    newErrors.doc_decision_number = !values.doc_decision_number;
    newErrors.doc_start_date = !values.doc_start_date;
  }

  if (ID_CARD_DOCS_IDS_2.includes(+values.document_type_enum_id)) {
    newErrors.doc_issue_code = values.doc_issue_code?.length === 0 ?? false;
    newErrors.doc_organ_id = !values.doc_organ_id;
  }

  if (!ID_CARD_DOCS_IDS_4.includes(+values.document_type_enum_id)) {
    newErrors.doc_issue_date = !values.doc_issue_date;
  }

  if (!ID_CARD_DOCS_IDS_5.includes(+values.document_type_enum_id)) {
    newErrors.doc_serial =
      !ID_CARD_DOCS_IDS_6.includes(+values.document_type_enum_id) && (values.doc_serial?.length === 0 ?? false);

    newErrors.doc_issue_authority =
      !ID_CARD_DOCS_IDS_7.includes(+values.document_type_enum_id) &&
      (values.doc_issue_authority?.length === 0 ?? false);

    newErrors.doc_issue_authority_organ =
      !ID_CARD_DOCS_IDS_7.includes(+values.document_type_enum_id) &&
      ((!values.doc_issue_authority_organ || values.doc_issue_authority_organ?.name_ru?.length === 0) ?? false);

    newErrors.doc_expire_date =
      !ID_CARD_DOCS_IDS_1.includes(+values.document_type_enum_id) &&
      values.document_type_enum_id !== 20 &&
      !values.doc_expire_date;
  }

  if (ID_CARD_DOCS_IDS_6.includes(+values.document_type_enum_id)) {
    newErrors.doc_issue_authority =
      !ID_CARD_DOCS_IDS_7.includes(+values.document_type_enum_id) &&
      (values.doc_issue_authority?.length === 0 ?? false);

    newErrors.doc_issue_authority_organ =
      !ID_CARD_DOCS_IDS_7.includes(+values.document_type_enum_id) &&
      ((!values.doc_issue_authority_organ || values.doc_issue_authority_organ?.name_ru?.length === 0) ?? false);
  }

  return newErrors;
}
