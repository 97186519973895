export function dotStringToObject(string: string, value: unknown): Record<string, unknown> {
  const [first, ...rest] = string.split('.');

  if (rest.length === 0) {
    return {
      [first]: value,
    };
  }

  return {
    [first]: dotStringToObject(rest.join('.'), value),
  };
}
