export const DEFAULT_SEARCH_TIMEOUT = 1000;

export const DEFAULT_ADDRESS_LOCAL = {
  country: null,
  region: null,
  place: null,
  city: null,
  settlement: null,
  street: null,
  parents: [],
};
