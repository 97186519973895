import AccordionSummary from '@mui/material/AccordionSummary';
import styled from '@mui/material/styles/styled';

export const DocumentsPersonSummary = styled(AccordionSummary)`
  min-height: 0;
  height: 48px;

  &.Mui-expanded {
    min-height: 0;
    height: 48px;
  }

  &.MuiAccordionSummary-content {
    align-items: center;
  }
`;

export const DocumentsPersonContent = styled('fieldset')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
