import React, { Fragment, memo, useCallback, useEffect, useRef, useState } from 'react';
import Lightbox from 'react-18-image-lightbox';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/Delete';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ErrorIcon from '@mui/icons-material/Error';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import RedoIcon from '@mui/icons-material/Redo';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { DocumentType } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { DocumentImage, PersonStatus } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';
import DropdownMenu, { DropdownMenuOption } from '@/ui-kit/components/DropdownMenu/DropdownMenu';
import { convertToClearBase64, convertToImageBase64, generateBase64FromBlob } from '@/utils';

import { usePersonPageStore } from '../../../../PersonPageStore';

import DeleteImageConfirmModal from './DeleteImageConfirmModal/DeleteImageConfirmModal';
import {
  ImageBlockActions,
  ImageBlockCopy,
  ImageBlockError,
  ImageBlockErrorIcon,
  ImageBlockExportSwitcher,
  ImageBlockFooter,
  ImageBlockImg,
  ImageBlockOpen,
  ImageBlockOverlay,
  ImageBlockRoot,
  ImageBlockRotate,
  ImageBlockWrapper,
} from './ImageBlock.style';

interface ImageBlockProps {
  index: number;
  image: DocumentImage;
  disableImageCopy: boolean;
  currentTab: Nullable<DocumentType>;
  onDeleteImage: (index: number) => void;
  onChangeExport: (index: number, checked: boolean) => void;
  onEditImage: (index: number, imageData: string) => void;
  onCopyImage: (target: DocumentType, image: DocumentImage) => void;
  status: PersonStatus;
}

function ImageBlock(props: ImageBlockProps) {
  const { index, image, onDeleteImage, onChangeExport, onEditImage, currentTab, onCopyImage, disableImageCopy } = props;
  const { recognizeImage, scanQrCode, updateImagesToRemove, isDeleted, isExported } = usePersonPageStore();
  const { getImageByOperationId, getImageById } = useStore('personsStore');
  const { userData } = useStore('signInStore');

  const [imageBase64, setImage] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isDeleteImageOpen, openDeleteImageModal, closeDeleteImageModal] = useToggle();
  // isRemoveOpen - временная реализация, чтобы протестировать концепт
  const [isRemoveOpen, openRemoveModal, closeRemoveModal] = useToggle();

  const imageBlockRef = useRef<Nullable<HTMLDivElement>>(null);

  async function fetchImage(documentId: number, imageId: number) {
    try {
      const imageFile = await getImageById(documentId, imageId);

      const imageBase64String = await generateBase64FromBlob(imageFile);

      setImage(imageBase64String);
    } catch (e) {
      notificationAPI.error('Не удалось загрузить изображение');
    }

    setIsImageLoading(false);
  }

  async function fetchImageByOperationId(operationId: number) {
    try {
      const imageByOperationId = await getImageByOperationId(operationId);

      const imageBase64String = await generateBase64FromBlob(imageByOperationId);

      setImage(imageBase64String);
    } catch (e) {
      notificationAPI.error('Не удалось загрузить изображение');
    }

    setIsImageLoading(false);
  }

  useEffect(() => {
    if (image.data) {
      setImage(convertToClearBase64(image.data));
    } else if (image.id && image.document_id) {
      fetchImage(image.document_id, image.id);
    } else if (image.device_op_id) {
      fetchImageByOperationId(image.device_op_id);
    }
  }, [image.data, image.id, image.document_id]);

  useEffect(() => {
    if (imageBase64.length > 0) {
      setIsImageLoading(false);
    }
  }, [imageBase64]);

  const onToggleImageOpen = useCallback(() => setIsImageOpen((isImageOpen: boolean) => !isImageOpen), []);

  const onChangeExportHandler = useCallback(
    () => onChangeExport(index, !image.not_export),
    [index, image.not_export, onChangeExport],
  );

  const onCopyToIdCard = useCallback(() => {
    onCopyImage(DocumentType.IdCard, {
      document_id: image.document_id,
      not_export: image.not_export,
      data: imageBase64,
    });
  }, [onCopyImage, imageBase64]);
  const onCopyToRightOfStay = useCallback(() => {
    onCopyImage(DocumentType.RightOfStay, {
      document_id: image.document_id,
      not_export: image.not_export,
      data: imageBase64,
    });
  }, [onCopyImage, imageBase64]);
  const onCopyToMigrationCard = useCallback(() => {
    onCopyImage(DocumentType.Migration, {
      document_id: image.document_id,
      not_export: image.not_export,
      data: imageBase64,
    });
  }, [onCopyImage, imageBase64]);

  const onDeleteImageHandler = useCallback(() => {
    onDeleteImage(index);
    closeDeleteImageModal();
  }, [onDeleteImage, index]);

  const onRemoveImage = useCallback(() => {
    if (currentTab) {
      if (image.id) {
        updateImagesToRemove(currentTab, image.id);
      }

      onDeleteImage(index);
      closeRemoveModal();
    }
  }, [onDeleteImage, currentTab, image.id, index]);

  const rotateBase64Image = useCallback(
    (isClockwise: boolean) => {
      const offScreenCanvas = document.createElement('canvas');
      const offScreenCanvasCtx = offScreenCanvas.getContext('2d');

      const img = new Image();

      img.src = convertToImageBase64(imageBase64);

      offScreenCanvas.height = img.width;
      offScreenCanvas.width = img.height;

      if (offScreenCanvasCtx) {
        if (isClockwise) {
          offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
          offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
        } else {
          offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
          offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
        }

        offScreenCanvasCtx.drawImage(img, 0, 0);

        const imageData = convertToClearBase64(offScreenCanvas.toDataURL('image/jpeg', 100));

        setImage(imageData);
        onEditImage(index, imageData);
      }
    },
    [setImage, onEditImage, imageBase64],
  );

  function getTabOptions() {
    const result: DropdownMenuOption[] = [];

    if (currentTab !== DocumentType.IdCard) {
      result.push({
        label: 'Удостоверение личности',
        value: DocumentType.IdCard,
        onClick: onCopyToIdCard,
      });
    }

    if (currentTab !== DocumentType.RightOfStay) {
      result.push({
        label: 'Право пребывания',
        value: DocumentType.RightOfStay,
        onClick: onCopyToRightOfStay,
      });
    }

    if (currentTab !== DocumentType.Migration) {
      result.push({
        label: 'Миграционная карта',
        value: DocumentType.Migration,
        onClick: onCopyToMigrationCard,
      });
    }

    return result;
  }

  return (
    <ImageBlockRoot ref={imageBlockRef}>
      <BackdropLoader isLoading={isImageLoading} />

      {isImageOpen && (
        <Lightbox
          mainSrc={convertToImageBase64(imageBase64)}
          onCloseRequest={onToggleImageOpen}
          reactModalStyle={{
            overlay: {
              zIndex: 1400,
            },
          }}
        />
      )}

      {/* Если загрузка изображения не удалась, показываем иконку ошибку и кнопку удаления */}

      {!imageBase64 && !isImageLoading && (
        <ImageBlockError>
          <ImageBlockActions>
            {!isDeleted && (
              <Button color="error" onClick={openDeleteImageModal} variant="contained">
                <Tooltip title="Удалить">
                  <DeleteIcon />
                </Tooltip>
              </Button>
            )}
          </ImageBlockActions>

          <ImageBlockErrorIcon>
            <ErrorIcon />
          </ImageBlockErrorIcon>
        </ImageBlockError>
      )}

      {imageBase64 && (
        <Fragment>
          <ImageBlockWrapper>
            <ImageBlockCopy className="imageCopy">
              {!isDeleted && !disableImageCopy && (
                <DropdownMenu
                  opener={
                    <Button color="primary" variant="contained">
                      <Tooltip title="Копировать">
                        <RedoIcon />
                      </Tooltip>
                    </Button>
                  }
                  options={getTabOptions()}
                />
              )}
            </ImageBlockCopy>

            {!isDeleted && !isExported && (
              <ImageBlockActions className="imageButtons">
                {userData?.rwr && (
                  <div>
                    <Button color="primary" onClick={() => recognizeImage(imageBase64)} variant="contained">
                      <Tooltip title="Распознать">
                        <DocumentScannerIcon />
                      </Tooltip>
                    </Button>
                  </div>
                )}

                <div>
                  <Button color="primary" onClick={() => scanQrCode(imageBase64)} variant="contained">
                    <Tooltip title="Сканировать QR-код">
                      <QrCode2Icon />
                    </Tooltip>
                  </Button>
                </div>

                <div>
                  <Tooltip title="Удалить изображение">
                    <Button color="error" onClick={openRemoveModal} variant="contained">
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </div>
              </ImageBlockActions>
            )}

            <ImageBlockImg alt="doc image" src={convertToImageBase64(imageBase64)} />

            {!isDeleted && (
              <ImageBlockOverlay className="imageOverlay">
                <ImageBlockRotate direction="left" onClick={() => rotateBase64Image(false)}>
                  <RotateLeftIcon />
                </ImageBlockRotate>

                <ImageBlockOpen onClick={onToggleImageOpen}>
                  <ZoomInIcon fontSize="inherit" />
                </ImageBlockOpen>

                <ImageBlockRotate direction="right" onClick={() => rotateBase64Image(true)}>
                  <RotateRightIcon />
                </ImageBlockRotate>
              </ImageBlockOverlay>
            )}
          </ImageBlockWrapper>

          {!isDeleted && !isExported && (
            <ImageBlockFooter>
              <ImageBlockExportSwitcher>
                <FormControlLabel
                  control={
                    <Switch checked={!image.not_export} color="primary" onClick={onChangeExportHandler} size="small" />
                  }
                  label="Экспортировать"
                />
              </ImageBlockExportSwitcher>
            </ImageBlockFooter>
          )}
        </Fragment>
      )}

      <DeleteImageConfirmModal
        onClose={closeDeleteImageModal}
        onDelete={onDeleteImageHandler}
        open={isDeleteImageOpen}
      />

      <DeleteImageConfirmModal onClose={closeRemoveModal} onDelete={onRemoveImage} open={isRemoveOpen} />
    </ImageBlockRoot>
  );
}

export default memo(observer(ImageBlock));
