import React, { memo, SyntheticEvent, useEffect } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { notificationAPI } from '@/api/notificationAPI';
import { AddCompanyIpAddressForm } from '@/stores/CompaniesStore';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, InputField } from '@/ui-kit/components';

interface IpAddressFormProps extends ContentModalProps {
  type: 'create' | 'edit';
  companyId: number;
  ipAddress?: AddCompanyIpAddressForm & { id?: number };
  onAfterChange?: () => void;
}

function IpAddressFormModal(props: IpAddressFormProps) {
  const { open, onClose, type, ipAddress, companyId, onAfterChange } = props;
  const { isSavingIpAddress, addIpAddress, updateIpAddress } = useStore('companiesStore');

  const ipAddressForm = useFormik<AddCompanyIpAddressForm>({
    validateOnMount: false,
    initialValues: {
      name: ipAddress?.name ?? '',
      ip: ipAddress?.ip ?? '',
    },
    onSubmit: async (values) => {
      try {
        if (type === 'create') {
          await addIpAddress(companyId, values);
        }

        if (type === 'edit' && ipAddress?.id) {
          await updateIpAddress(companyId, ipAddress.id, values);
        }

        onClose();

        notificationAPI.success(`IP-адрес ${type === 'create' ? 'добвлен' : 'обновлен'}`);

        onAfterChange?.();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    onReset: () => {},
    validate: ({ name, ip }) => {
      const errors: Partial<Record<keyof AddCompanyIpAddressForm, string>> = {};

      if (name.length === 0) {
        errors.name = 'Обязательно к заполнению';
      }

      if (ip.length === 0) {
        errors.ip = 'Обязательно к заполнению';
      }

      return errors;
    },
  });

  function onCloseHandler(e?: SyntheticEvent) {
    e?.stopPropagation();
    e?.preventDefault();

    onClose(e);

    ipAddressForm.resetForm();
  }

  useEffect(() => {
    if (open) {
      ipAddressForm.resetForm({
        values: {
          name: ipAddress?.name ?? '',
          ip: ipAddress?.ip ?? '',
        },
      });
    }
  }, [open]);

  return (
    <ContentModal disableEscapeKeyDown={isSavingIpAddress} onClose={onCloseHandler} open={open}>
      <form onReset={onCloseHandler} onSubmit={ipAddressForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSavingIpAddress} onClose={onCloseHandler}>
          <span>{type === 'edit' && 'Обновить '}</span>

          <span>{type === 'create' && 'Создать '}</span>

          <span>IP-адрес</span>
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                autoFocus
                disabled={isSavingIpAddress}
                error={!!ipAddressForm.errors.name}
                helperText={ipAddressForm.errors.name}
                label="Название"
                name="name"
                onChange={ipAddressForm.handleChange}
                value={ipAddressForm.values.name}
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                disabled={isSavingIpAddress}
                error={!!ipAddressForm.errors.ip}
                helperText={ipAddressForm.errors.ip}
                label="IP-адрес"
                name="ip"
                onChange={ipAddressForm.handleChange}
                value={ipAddressForm.values.ip}
              />
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSavingIpAddress} type="reset">
            Отмена
          </Button>

          <ButtonLoader isLoading={isSavingIpAddress} type="submit">
            {type === 'edit' && 'Обновить '}

            {type === 'create' && 'Создать '}
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(IpAddressFormModal));
