import React, { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { DATE_HUMAN_FORMAT, TIME_HUMAN_FORMAT_FULL } from '@/constants';
import { BackdropLoader, InputField } from '@/ui-kit/components';

import {
  BreakfastContainer,
  BreakfastPersons,
  BreakfastPersonsContent,
  BreakfastPersonsFooter,
  BreakfastPersonsList,
  BreakfastPersonsScroll,
  BreakfastPersonsWrapper,
  BreakfastRoot,
} from './Breakfast.styles';
import { useBreakfastStore, withBreakfastStore } from './BreakfastStore';
import PersonBlock from './PersonBlock/PersonBlock';

function Breakfast() {
  const { isFetching, roomNumber, setRoomNumber, persons, setPersons, selectedPersons } = useBreakfastStore();

  const [currentDate, setCurrentDate] = useState(new Date());

  const onRoomNumberChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      return setPersons([]);
    }

    setRoomNumber(e.target.value);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => setCurrentDate(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <BreakfastRoot>
      <BreakfastContainer container spacing={2}>
        <Grid item xs={4}>
          <InputField
            autoFocus
            clearable
            fullWidth
            label="Номер комнаты"
            maxLength={12}
            name="roomNumber"
            onChange={onRoomNumberChange}
            type="number"
            value={roomNumber}
            variant="outlined"
          />
        </Grid>

        <BreakfastPersons item xs={8}>
          <BreakfastPersonsWrapper>
            <BreakfastPersonsList>
              <BreakfastPersonsContent>
                <BreakfastPersonsScroll>
                  <BackdropLoader isLoading={isFetching} />

                  {persons.length > 0 && (
                    <Grid container spacing={2}>
                      {persons.map((person) => (
                        <Grid item key={person.id} xs={6}>
                          <PersonBlock person={person} />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </BreakfastPersonsScroll>
              </BreakfastPersonsContent>
            </BreakfastPersonsList>

            <BreakfastPersonsFooter>
              <Typography variant="h5">
                <span>Сегодня: </span>

                <span>{format(currentDate, DATE_HUMAN_FORMAT)}</span>

                <span> | </span>

                <span>{format(currentDate, TIME_HUMAN_FORMAT_FULL)}</span>
              </Typography>

              <Button disabled={selectedPersons.length === 0} size="large" variant="outlined">
                Подтвердить
              </Button>
            </BreakfastPersonsFooter>
          </BreakfastPersonsWrapper>
        </BreakfastPersons>
      </BreakfastContainer>
    </BreakfastRoot>
  );
}

export default memo(withBreakfastStore(observer(Breakfast)));
