type StorageKey = 'token' | 'etoken' | 'authData' | 'recoverToken' | 'inactivity-time-mark' | 'company' | string;
type StorageType = 'local' | 'session' | 'variable';

class StorageAPI {
  private storedValues: Record<string, string | unknown> = {};

  get values() {
    return this.storedValues;
  }

  set(key: StorageKey, value: string | unknown, type: StorageType = 'local') {
    return this.writeValue(type, key, value);
  }

  get(key: StorageKey, type: StorageType = 'local') {
    return this.getValue(type, key);
  }

  remove(key: StorageKey, type: StorageType = 'local') {
    return this.removeValue(type, key);
  }

  has(key: StorageKey, type: StorageType = 'local') {
    if (type === 'variable') {
      return Boolean(this.storedValues[key]);
    }

    return !!this.storage(type).getItem(key);
  }

  private writeValue(type: StorageType, key: string, value: string | unknown) {
    if (type === 'variable') {
      this.storedValues[key] = value;

      return;
    }

    let preparedValue = value;

    if (typeof preparedValue !== 'string') {
      preparedValue = JSON.stringify(preparedValue);
    }

    return this.storage(type).setItem(key, preparedValue as string);
  }

  private getValue(type: StorageType, key: string) {
    const value: string | unknown = type === 'variable' ? this.storedValues[key] : this.storage(type).getItem(key);

    if (!value) {
      return null;
    }

    try {
      return JSON.parse(value as string);
    } catch (e) {
      return value;
    }
  }

  private removeValue(type: StorageType, key: string) {
    if (type === 'variable') {
      if (!this.storedValues[key]) {
        return;
      }

      delete this.storedValues[key];

      return;
    }

    return this.storage(type).removeItem(key);
  }

  private storage(type: StorageType) {
    if (type === 'session') {
      return sessionStorage;
    }

    return localStorage;
  }
}

export const storageAPI = new StorageAPI();
