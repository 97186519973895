import React, { ChangeEvent, memo, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { endOfDay, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { DEFAULT_EMPTY_CITIZENSHIP, DEFAULT_NOT_RUSSIAN_CITIZENSHIP } from '@/constants';
import { DictionaryBasicWithCode } from '@/entities/dictionary';
import { Group } from '@/entities/group';
import { GetPersonsArguments, PersonStatus } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField, DatepickerField, InputField } from '@/ui-kit/components';

import { DEFAULT_FILTER_FORM_VALUES } from './FilterRaw.constants';
import { FilterCaption, FilterRawRoot, FilterRawStatus, FilterRawStay } from './FilterRaw.style';

interface FilterProps {
  filterData: Partial<GetPersonsArguments>;
  onFilter: (values: Partial<GetPersonsArguments>) => void;
}

function FilterRaw(props: FilterProps) {
  const { filterData, onFilter } = props;
  const { groupId } = useParams();
  const { countries } = useStore('dictionariesStore');
  const { getSingleGroupById } = useStore('groupsStore');
  const { userCompany } = useStore('signInStore');

  const [currentGroup, setCurrentGroup] = useState<Nullable<Group>>(null);

  const filterForm = useFormik<Partial<GetPersonsArguments>>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { ...DEFAULT_FILTER_FORM_VALUES, ...filterData },
    onSubmit: onFilter,
    onReset: () => onFilter(DEFAULT_FILTER_FORM_VALUES),
  });

  async function getCurrentGroup() {
    if (!groupId) {
      setCurrentGroup(null);

      return;
    }

    try {
      setCurrentGroup(await getSingleGroupById(+groupId));
    } catch (e) {
      // do not do anything
    }
  }

  useEffect(() => {
    filterForm.setValues({ ...DEFAULT_FILTER_FORM_VALUES, ...filterData });

    getCurrentGroup();
  }, [JSON.stringify(filterData), groupId]);

  const onChangeCreationFrom = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      filterForm.setFieldValue('creationFrom', startOfDay(new Date(target.value)));
    }
  }, []);
  const onChangeCreationTo = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      filterForm.setFieldValue('creationTo', endOfDay(new Date(target.value)));
    }
  }, []);
  const onChangeStayFrom = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      filterForm.setFieldValue('stayFrom', startOfDay(new Date(target.value)));
    }
  }, []);
  const onChangeStayTo = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      filterForm.setFieldValue('stayTo', endOfDay(new Date(target.value)));
    }
  }, []);

  const preparedCountries = useMemo(() => {
    const countriesList = countries.concat([DEFAULT_EMPTY_CITIZENSHIP]);

    return [DEFAULT_NOT_RUSSIAN_CITIZENSHIP].concat(countriesList);
  }, []);

  const onCountryChange = useCallback((country: unknown, e: SyntheticEvent) => {
    e.preventDefault();

    filterForm.setFieldValue('citizenship', country ? (country as DictionaryBasicWithCode).id : null);
  }, []);

  const renderOption = useCallback(({ name_ru }: DictionaryBasicWithCode) => `${name_ru}`, []);

  const tableColumnsCount = userCompany?.company?.hasHMSStatus ? 11 : 10;

  return (
    <TableRow>
      <FilterRawRoot colSpan={tableColumnsCount} padding="none">
        <form onReset={filterForm.handleReset} onSubmit={filterForm.handleSubmit}>
          <Table size="small" stickyHeader>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} padding="none" width="60">
                  <FilterRawStatus name="status" onChange={filterForm.handleChange} value={filterForm.values.status}>
                    <MenuItem value={PersonStatus.All}>Все статусы</MenuItem>

                    <MenuItem value={PersonStatus.Pending}>В ожидании</MenuItem>

                    <MenuItem value={PersonStatus.Accepted}>Подтвержден</MenuItem>

                    <MenuItem value={PersonStatus.Exported}>Выгружен</MenuItem>
                  </FilterRawStatus>
                </TableCell>

                <TableCell colSpan={2} width="20%">
                  <InputField
                    clearable
                    label="Фамилия Имя"
                    name="name"
                    onChange={filterForm.handleChange}
                    size="small"
                    value={filterForm.values.name}
                  />
                </TableCell>

                <TableCell width="9%">
                  <InputField
                    clearable
                    label="№ комнаты"
                    name="room_number"
                    onChange={filterForm.handleChange}
                    size="small"
                    value={filterForm.values.room_number}
                  />
                </TableCell>

                <TableCell width="11%">
                  <FormControl fullWidth>
                    <AutocompleteField
                      getOptionLabel={renderOption}
                      label="Гражданство"
                      name="citizenship"
                      onChange={onCountryChange}
                      options={[...preparedCountries]}
                      value={preparedCountries.find(({ id }) => id === filterForm.values.citizenship) ?? null}
                    />
                  </FormControl>
                </TableCell>

                <TableCell width="10%">
                  <InputField
                    clearable
                    label="№ документа"
                    maxLength={16}
                    name="doc_number"
                    onChange={filterForm.handleChange}
                    size="small"
                    value={filterForm.values.doc_number}
                  />
                </TableCell>

                <TableCell width="14%">
                  <FilterRawStay
                    disableFuture
                    label="Дата создания, от"
                    name="creationFrom"
                    onChange={onChangeCreationFrom}
                    value={filterForm.values.creationFrom}
                  />

                  <DatepickerField
                    disableFuture
                    label="Дата создания, до"
                    minDate={filterForm.values.creationFrom ? filterForm.values.creationFrom : undefined}
                    name="creationTo"
                    onChange={onChangeCreationTo}
                    value={filterForm.values.creationTo}
                  />
                </TableCell>

                <TableCell width="16%">
                  <FilterRawStay
                    disableFuture
                    label="Срок пребывания, от"
                    name="stayFrom"
                    onChange={onChangeStayFrom}
                    value={filterForm.values.stayFrom}
                  />

                  <DatepickerField
                    label="Срок пребывания, до"
                    minDate={filterForm.values.stayFrom ? filterForm.values.stayFrom : undefined}
                    name="stayTo"
                    onChange={onChangeStayTo}
                    value={filterForm.values.stayTo}
                  />
                </TableCell>

                <TableCell width="9%">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>

                  <IconButton type="reset">
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>

        <Divider />

        <Grid container spacing={8}>
          <Grid item xs={12}>
            <FilterCaption align="center" variant="subtitle2">
              <span>Поиск будет выполнен </span>

              <span>{currentGroup ? `в группе "${currentGroup.name}"` : 'по всем регистрациям'}</span>
            </FilterCaption>
          </Grid>
        </Grid>
      </FilterRawRoot>
    </TableRow>
  );
}

export default memo(observer(FilterRaw));
