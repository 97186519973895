import { makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import { User, UserModel } from '@/entities/user';

export class UsersStore {
  users: User[] = [];
  usersByCompany: User[] = [];

  isFetching = true;
  isSaving = false;
  isFetchingUsersByCompany = true;
  isFetchingUserById = true;
  isExiting = true;

  constructor() {
    makeAutoObservable(this);
  }

  private prepareUserBody(values: Partial<User>) {
    return {
      ...values,
      token_lifetime_min: values.token_lifetime_min ? +values.token_lifetime_min : undefined,
      password: values.password && values.password.length > 0 ? values.password : undefined,
    };
  }

  getUsers = () => {
    this.isFetching = true;

    return fetchApi
      .get<UserModel[]>('/users/')
      .then((response) => {
        runInAction(() => {
          this.users = response.map((user) => new User(user));
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  getUserById = (id: number) => {
    this.isFetchingUserById = true;

    return fetchApi
      .get<UserModel>(`/users/${id}/`)
      .then((user) => new User(user))
      .finally(() => {
        runInAction(() => {
          this.isFetchingUserById = false;
        });
      });
  };

  getUsersByCompany = (id: number) => {
    this.isFetchingUsersByCompany = true;

    return fetchApi
      .get<UserModel[]>('/users/', { company_id: id })
      .then((response) => {
        runInAction(() => {
          this.usersByCompany = response.map((user) => new User(user));
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isFetchingUsersByCompany = false;
        });
      });
  };

  createUser = (values: Partial<User>) => {
    this.isSaving = true;

    return fetchApi
      .post('/users/', {
        body: this.prepareUserBody(values),
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  updateUser = (values: Partial<User>, id: number) => {
    this.isSaving = true;

    return fetchApi
      .put(`/users/${id}/`, {
        body: this.prepareUserBody(values),
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  deleteUser = (id: number) => {
    this.isSaving = true;

    return fetchApi.remove(`/users/${id}/`).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };

  forceExitUserById = (id: number) => {
    this.isExiting = true;

    return fetchApi.post(`/users/${id}/logout/`).finally(() => {
      runInAction(() => {
        this.isExiting = false;
      });
    });
  };
}
