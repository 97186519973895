import React, { Fragment, HTMLAttributes, memo } from 'react';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import RuleIcon from '@mui/icons-material/Rule';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';

import { SRMInfoStatus } from '@/stores/PersonsStore';

import { SRMStatusRoot } from './SRMStatus.style';

interface SRMStatusProps extends HTMLAttributes<HTMLDivElement> {
  status?: SRMInfoStatus;
}

function SRMStatus(props: SRMStatusProps) {
  const { status, ...rest } = props;

  return (
    <SRMStatusRoot {...rest}>
      {(status === SRMInfoStatus.Waiting || !status) && (
        <Fragment>
          <EditIcon color="error" fontSize="small" />
          &nbsp;
          <Typography fontSize="smaller">Ожидается отправка уведомления</Typography>
        </Fragment>
      )}

      {status === SRMInfoStatus.SentByEmail && (
        <Fragment>
          <SendIcon color="success" fontSize="small" />
          &nbsp;
          <Typography fontSize="smaller">Уведомление отправлено (email)</Typography>
        </Fragment>
      )}

      {status === SRMInfoStatus.SentBySMS && (
        <Fragment>
          <SendIcon color="success" fontSize="small" />
          &nbsp;
          <Typography fontSize="smaller">Уведомление отправлено (sms)</Typography>
        </Fragment>
      )}

      {status === SRMInfoStatus.WaitingForConfirm && (
        <Fragment>
          <RuleIcon color="warning" fontSize="small" />
          &nbsp;
          <Typography fontSize="smaller">Ожидает подтверждения</Typography>
        </Fragment>
      )}

      {status === SRMInfoStatus.ConfirmByEmail && (
        <Fragment>
          <DoneAllIcon color="success" fontSize="small" />
          &nbsp;
          <Typography fontSize="smaller">Подтверждение отправлено (email)</Typography>
        </Fragment>
      )}

      {status === SRMInfoStatus.ConfirmBySMS && (
        <Fragment>
          <DoneAllIcon color="success" fontSize="small" />
          &nbsp;
          <Typography fontSize="smaller">Подтверждение отправлено (sms)</Typography>
        </Fragment>
      )}

      {status === SRMInfoStatus.Manual && (
        <Fragment>
          <InfoIcon color="error" fontSize="small" />
          &nbsp;
          <Typography fontSize="smaller">Требуется обработка документов вручную при заезде</Typography>
        </Fragment>
      )}
    </SRMStatusRoot>
  );
}

export default memo(SRMStatus);
