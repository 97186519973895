import Button from '@mui/material/Button';
import styled from '@mui/material/styles/styled';

export const UploadImagesRoot = styled('div')`
  padding: 12px;
  height: 100%;
  overflow: hidden;
`;

export const UploadImagesWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const UploadImagesEmpty = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const UploadImagesSuccess = styled('div')`
  text-align: center;
`;

export const UploadImagesContent = styled('div')`
  flex-grow: 1;
  overflow: auto;
  text-align: center;
`;

export const UploadImagesActions = styled('div')`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
`;

export const UploadImagesItem = styled('div')`
  position: relative;
  max-width: 300px;
  margin: 0 auto 12px;

  img {
    width: 100%;
  }
`;

export const UploadImagesItemContent = styled('div')`
  position: relative;

  img {
    width: 100%;
  }
`;

export const UploadImagesRemove = styled(Button)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
