import React, { memo } from 'react';
import { format } from 'date-fns';
import Grid from '@mui/material/Grid';

import { DATE_HUMAN_FORMAT } from '@/constants';
import { HMSLogusPerson } from '@/stores/PersonsStore';

import { LogusPersonsRawRoot } from './LogusPersonsRaw.style';

interface LogusPersonsRawProps {
  logusPerson: HMSLogusPerson;
  onClick: (logusPerson: HMSLogusPerson) => void;
}

function LogusPersonsRaw({ logusPerson, onClick }: LogusPersonsRawProps) {
  return (
    <LogusPersonsRawRoot container onClick={() => onClick(logusPerson)}>
      <Grid item xs={12}>
        <div>
          <strong>
            <span>{logusPerson.LastName}</span>

            {logusPerson.MiddleName && <span>&nbsp;{logusPerson.MiddleName}</span>}

            {logusPerson.FirstName && <span>&nbsp;{logusPerson.FirstName}</span>}
          </strong>

          {logusPerson.BirthDate && (
            <span>
              <span>&nbsp;{format(new Date(logusPerson.BirthDate), DATE_HUMAN_FORMAT)}</span>

              <span>&nbsp;г.р.</span>
            </span>
          )}
        </div>

        {logusPerson.ArrivalDate && logusPerson.DepartureDate && (
          <div>
            <span>Сроки проживания:</span>

            <span>&nbsp;{format(new Date(logusPerson.ArrivalDate), DATE_HUMAN_FORMAT)}</span>

            <span>&nbsp;-&nbsp;</span>

            <span>{format(new Date(logusPerson.DepartureDate), DATE_HUMAN_FORMAT)}</span>
          </div>
        )}

        {logusPerson.RoomNo && (
          <div>
            <span>Комната:</span>

            <span>&nbsp;{logusPerson.RoomNo}</span>
          </div>
        )}
      </Grid>

      {/* НИЖЕ СТАРАЯ РЕАЛИЗАЦИЯ*/}

      {/* <Grid item xs={6}>*/}

      {/*  <span>{person.LastName}</span>*/}

      {/*  {person.MiddleName && <span>&nbsp;{person.MiddleName}</span>}*/}

      {/*  {person.FirstName && <span>&nbsp;{person.FirstName}</span>}*/}

      {/* </Grid>*/}

      {/* <Grid item xs={6}>*/}

      {/*  {person.BirthDate && format(new Date(person.BirthDate), DATE_HUMAN_FORMAT)}*/}

      {/* </Grid>*/}
    </LogusPersonsRawRoot>
  );
}

export default memo(LogusPersonsRaw);
