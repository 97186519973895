import { alpha } from '@mui/material';
import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';

export const ImageBlockRoot = styled('div')`
  position: relative;
  min-height: 48px;
  margin-bottom: 24px;
`;

export const ImageBlockError = styled('div')`
  background-color: ${grey[200]};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
`;

export const ImageBlockActions = styled('div')`
  display: flex;
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 5px;
  opacity: 0;

  & > * {
    margin-left: 12px;
  }
`;

export const ImageBlockErrorIcon = styled('div')`
  & svg {
    fill: ${grey[500]};
  }
`;

export const ImageBlockWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover .imageButtons {
    opacity: 1;
  }

  ,
  &:hover .imageOverlay {
    opacity: 1;
  }

  &:hover .imageCopy {
    opacity: 1;
  }
`;

export const ImageBlockCopy = styled('div')`
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 5px;
  opacity: 0;
`;

export const ImageBlockImg = styled('img')`
  width: 100%;
`;

export const ImageBlockOverlay = styled('div')`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const ImageBlockRotate = styled('div')(({ direction }: { direction: 'left' | 'right' }) => {
  const leftDirection = css`
    background-image: linear-gradient(to left, transparent 0, ${alpha(common.black, 0.5)} 100%);

    &:hover {
      background-image: linear-gradient(to left, transparent 0, ${alpha(common.black, 0.75)} 100%);
    },
  `;
  const rightDirection = css`
    background-image: linear-gradient(to right, transparent 0, ${alpha(common.black, 0.5)} 100%);

    &:hover {
      background-image: linear-gradient(to right, transparent 0, ${alpha(common.black, 0.75)} 100%);
    }
  `;

  return css`
    color: ${common.white};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 14%;

    ${direction === 'left' ? leftDirection : rightDirection}
  `;
});

export const ImageBlockOpen = styled('div')`
  font-size: 62px;
  color: ${common.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageBlockFooter = styled('div')`
  margin-top: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ImageBlockExportSwitcher = styled('div')`
  margin-left: auto;
`;
