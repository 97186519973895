import React, { Fragment, memo, SyntheticEvent, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { EulaCard } from '@/entities/eualCard';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';

interface EulaCardRowProps {
  eulaCard: EulaCard;
  companyId: number;
  onAfterChange: () => void;
}

function EulaCardRaw(props: EulaCardRowProps) {
  const { eulaCard, companyId, onAfterChange } = props;
  const { removeCompanyEulaCard, isSavingEulaCard } = useStore('companiesStore');

  const [isDeleteEulaCardModalOpen, setDeleteEulaCardModalOpen] = useState(false);

  async function onDeleteHandler() {
    try {
      if (eulaCard?.id) {
        await removeCompanyEulaCard(companyId, eulaCard.id);

        notificationAPI.success('Регистрационная карта удалена');

        onAfterChange();
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onToggleDeleteEulaCardModal = useCallback((e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    setDeleteEulaCardModalOpen((isDeleteEulaCardModalOpen: boolean) => !isDeleteEulaCardModalOpen);
  }, []);

  return (
    <Fragment>
      <TableRow hover>
        <TableCell>{eulaCard.name}</TableCell>

        <TableCell align="center">
          <Tooltip title="Удалить регистрационную карту">
            <IconButton onClick={onToggleDeleteEulaCardModal} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={
          <Typography variant="body1">
            Вы действительно хотите удалить регистрационную карту &quot;
            {eulaCard.name}&quot;?
          </Typography>
        }
        isLoading={isSavingEulaCard}
        onClose={onToggleDeleteEulaCardModal}
        onConfirm={onDeleteHandler}
        onDecline={onToggleDeleteEulaCardModal}
        open={isDeleteEulaCardModalOpen}
        title="Удалить регистрационную карту"
      />
    </Fragment>
  );
}

export default memo(observer(EulaCardRaw));
