import grey from '@mui/material/colors/grey';
import yellow from '@mui/material/colors/yellow';
import { css, styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';

export const CompanyTableRawRoot = styled(TableRow, { shouldForwardProp: (name) => name !== 'isActive' })(
  ({ isActive }: { isActive?: boolean }) => css`
  background-color: ${isActive ? yellow['100'] : '#fff'} ;
  transition: background-color 3500ms linear;
  height: 42px;
  cursor: pointer;

  &:nth-of-type(odd) {
    background-color: ${isActive ? yellow['100'] : grey['50']} ;
  },
`,
);
