import React, { Fragment, memo, PropsWithChildren, SyntheticEvent, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import {
  DATE_HUMAN_FORMAT,
  DEFAULT_PAGINATION_LIMIT,
  DEPRECATED_DEFAULT_DATE,
  FIRST_NAME_IS_EMPTY_LABEL,
  LAST_NAME_IS_EMPTY_LABEL,
  ROUTE_BOOKINGS,
  ROUTE_FALLBACK_LABEL,
  ROUTE_PERSON,
  TIME_HUMAN_FORMAT_FULL,
} from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import SRMStatus from '@/shared/SRMStatus/SRMStatus';
import { Person } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';
import AnimatedLongValue from '@/ui-kit/components/AnimatedLongValue/AnimatedLongValue';

import { SRM_STATUS_MAP } from '../BookingsTable.constants';

import {
  BookingRowAction,
  BookingRowName,
  BookingRowNameWrapper,
  BookingRowRoot,
  BookingRowSubName,
} from './BookingRow.style';
import NotificationHistory from './NotificationHistory/NotificationHistory';

interface PersonRowProps {
  index: number;
  person: Person;
}

function BookingRow(props: PersonRowProps) {
  const navigate = useNavigate();
  const { index, person } = props;
  const { search, hash } = useLocation();
  const { isSaving, deletePersons, getPersons, selectedPersons, selectReservation } = useStore('personsStore');

  const [isHistoryOpen, openHistory, closeHistory] = useToggle();
  const [isDeleteOpen, , closeDeleteOpen, toggleDeleteOpen] = useToggle();

  const query = queryAPI.params;

  const limit = useMemo(
    () => (query.limit && !isNaN(+query.limit) ? +query.limit : DEFAULT_PAGINATION_LIMIT),
    [query.limit],
  );
  const page = useMemo(() => (query.page && !isNaN(+query.page) ? +query.page : 0), [query.page]);

  const onNavigateToPersonPage = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      const params = new URLSearchParams(search);

      params.append(ROUTE_FALLBACK_LABEL, ROUTE_BOOKINGS);

      const url = `${ROUTE_PERSON}/${person.id}?${params.toString()}`;

      navigate(url);
    },
    [search],
  );

  async function onDeletePerson() {
    try {
      await deletePersons([person.id]);

      await getPersons({
        hms_status_not_in: true,
        limit,
        page,
      });

      notificationAPI.success(`Запись "${person.fname} ${person.lname}" удалена`);

      closeDeleteOpen();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function onSelectPerson() {
    selectReservation(person.id);
  }

  // TODO: https://github.com/mui-org/material-ui/issues/4535
  // если вешать клик на строку и при это иметь клики внутри отдельных ячеек,
  // то не будет работать stopPropagation и будут вызваны оба хандлера (на TableRow и на кнопки внутри ячеек)
  function TableCellOpener({ children }: PropsWithChildren<unknown>) {
    return <TableCell onClick={onNavigateToPersonPage}>{children}</TableCell>;
  }

  return (
    <BookingRowRoot hover id={`#person-${person.id}`} isHighlighted={hash === `#person-${person.id}`}>
      <TableCell padding="none">
        <Checkbox
          checked={selectedPersons.has(person.id)}
          color="primary"
          disabled={!person.email}
          onChange={onSelectPerson}
        />
      </TableCell>

      <TableCellOpener>{index + page * limit}</TableCellOpener>

      <TableCellOpener>
        {person?.stay_info?.hms_status && SRM_STATUS_MAP[person.stay_info.hms_status]}

        {/* <Tooltip*/}

        {/*  title={*/}

        {/*    <div>*/}

        {/*      <div>1 - reserved</div>*/}

        {/*      <div>2 - due in</div>*/}

        {/*      <div>3 - checked in</div>*/}

        {/*      <div>4 - due out</div>*/}

        {/*      <div>5 - checked out</div>*/}

        {/*      <div>6 - cancelled</div>*/}

        {/*    </div>*/}

        {/*  }*/}

        {/* >*/}

        {/*  <span>{person.stay_info.hms_status}</span>*/}

        {/* </Tooltip>*/}
      </TableCellOpener>

      <TableCellOpener>
        <BookingRowName>
          {person.lname && (
            <Fragment>
              <BookingRowNameWrapper>
                <AnimatedLongValue title={person.lname} />
              </BookingRowNameWrapper>

              {person.lname_lat && person.lname_lat !== LAST_NAME_IS_EMPTY_LABEL && (
                <BookingRowSubName>{person.lname_lat}</BookingRowSubName>
              )}
            </Fragment>
          )}

          {!person.lname && person.lname_lat && (
            <div>{person.lname_lat !== LAST_NAME_IS_EMPTY_LABEL ? person.lname_lat : <i>Отсутствует</i>}</div>
          )}
        </BookingRowName>
      </TableCellOpener>

      <TableCellOpener>
        <BookingRowName>
          {person.fname && (
            <Fragment>
              <BookingRowNameWrapper>
                <AnimatedLongValue title={person.fname} />
              </BookingRowNameWrapper>

              {person.fname_lat && person.fname !== FIRST_NAME_IS_EMPTY_LABEL && (
                <BookingRowSubName>{person.fname_lat}</BookingRowSubName>
              )}
            </Fragment>
          )}

          {!person.fname && person.fname_lat && (
            <div>{person.fname_lat !== FIRST_NAME_IS_EMPTY_LABEL ? person.fname_lat : <i>Отсутствует</i>}</div>
          )}
        </BookingRowName>
      </TableCellOpener>

      <TableCellOpener>
        <BookingRowName>
          {format(new Date(person.created_at), DATE_HUMAN_FORMAT)}

          <BookingRowSubName>{format(new Date(person.created_at), TIME_HUMAN_FORMAT_FULL)}</BookingRowSubName>
        </BookingRowName>
      </TableCellOpener>

      <TableCellOpener>
        {person.stay_info?.stay_from && (
          <Fragment>
            {format(new Date(person.stay_info.stay_from), DATE_HUMAN_FORMAT)}

            {person.stay_info?.stay_till && person.stay_info.stay_till !== DEPRECATED_DEFAULT_DATE && (
              <Fragment>
                {' - '}

                {format(new Date(person.stay_info.stay_till), DATE_HUMAN_FORMAT)}
              </Fragment>
            )}
          </Fragment>
        )}
      </TableCellOpener>

      <TableCellOpener>
        <SRMStatus status={person?.srm_info?.status} />
      </TableCellOpener>

      <TableCell>
        <Tooltip title="История отправки">
          <BookingRowAction className="personRowActionButton" onClick={openHistory}>
            <RestoreIcon />
          </BookingRowAction>
        </Tooltip>

        <Tooltip title="Удалить карточку">
          <BookingRowAction className="personRowActionButton" onClick={toggleDeleteOpen}>
            <DeleteIcon />
          </BookingRowAction>
        </Tooltip>
      </TableCell>

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={`Вы уверены, что хотите удалить карточку гостя "${person.fname} ${person.lname}"?`}
        isLoading={isSaving}
        onClose={closeDeleteOpen}
        onConfirm={onDeletePerson}
        onDecline={closeDeleteOpen}
        open={isDeleteOpen}
        title="Удалить карточку"
      />

      <NotificationHistory onClose={closeHistory} open={isHistoryOpen} person={person} />
    </BookingRowRoot>
  );
}

export default memo(observer(BookingRow));
