import React, { memo, SyntheticEvent, useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import BusinessIcon from '@mui/icons-material/Business';
import ViewListIcon from '@mui/icons-material/ViewList';
import Tabs from '@mui/material/Tabs';

import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';
import { useStore } from '@/stores/StoreProvider';

import { PROFILE_ROUTES_MAP } from './Profile.constants';
import { ProfileRoot, ProfileTab } from './Profile.style';
import { getActiveTabIndex } from './Profile.utils';

function Profile() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userData } = useStore('signInStore');

  const onChangeTab = useCallback((e: SyntheticEvent, newValue: number) => {
    e.preventDefault();

    const targetRoute = PROFILE_ROUTES_MAP.get(newValue);

    if (targetRoute) {
      navigate(targetRoute);
    }
  }, []);

  const superUser = userData?.company_superuser ?? false;

  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={[userData ? `${userData.fname} ${userData.lname}` : 'Профиль']} />}
      content={<Outlet />}
      header={
        <ProfileRoot color="default" position="static">
          <Tabs indicatorColor="primary" onChange={onChangeTab} textColor="primary" value={getActiveTabIndex(pathname)}>
            <ProfileTab icon={<ViewListIcon />} iconPosition="start" label="Профиль" />

            {superUser && <ProfileTab icon={<BusinessIcon />} iconPosition="start" label="Компания" />}
          </Tabs>
        </ProfileRoot>
      }
    />
  );
}

export default memo(observer(Profile));
