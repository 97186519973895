import { DOC_ISSUE_SHORT_CODE_LENGTH } from '@/constants';

export function parseDocIssueCode(value: string) {
  const code = value.match(/\d/g)?.join('');

  if (code && code.length === DOC_ISSUE_SHORT_CODE_LENGTH) {
    return code.slice(0, 3) + '-' + code.slice(3);
  }

  return '';
}
