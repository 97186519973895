export class HMSTypeModel {
  id!: number;
  name!: string;
}

export class HMSType extends HMSTypeModel {
  constructor(hmsType: HMSTypeModel) {
    super();

    Object.keys(hmsType).forEach((key) => {
      this[key] = hmsType[key];
    });
  }
}
