import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { ContentModal } from '@/ui-kit/components';

export const SendNotificationRoot = styled(ContentModal)`
  & .MuiDialog-paper {
    height: 100%;
    max-height: 975px;
  }
`;

export const SendNotificationBody = styled(ContentModal.Body)`
  overflow: hidden;
  height: 100%;
  position: relative;
`;

export const SendNotificationContent = styled('div')`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
`;

export const SendNotificationPreview = styled('div')`
  padding: 0 8px;
  width: 30%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const SendNotificationPreviewTitle = styled(Typography)`
  flex-shrink: 0;
`;

export const SendNotificationFields = styled('div')`
  padding: 0 8px;
  width: 35%;
  flex-shrink: 0;
  height: 100%;
  overflow: auto;

  & > * {
    margin-bottom: 8px;
  }
`;

export const SendNotificationSettings = styled('div')`
  padding: 0 8px;
  width: 35%;
  flex-shrink: 0;
  height: 100%;
  overflow: auto;

  & > * {
    margin-bottom: 8px;
  }
`;

export const SendNotificationAdditional = styled('div')`
  margin-top: 24px;
`;

export const SendNotificationTemplate = styled('div')`
  position: relative;
  flex-grow: 1;
`;

export const SendNotificationMockup = styled('div')`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
`;

export const SendNotificationMockupImage = styled('img')`
  height: 100%;
`;

export const SendNotificationMockupContent = styled('div')`
  position: absolute;
  top: 21px;
  left: 23px;
  right: 21px;
  bottom: 19px;
  overflow: hidden;
  border-radius: 42px;
`;

export const SendNotificationMockupContentInner = styled('div')`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SendNotificationTemplateContent = styled('div')`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SendNotificationTemplateOverlay = styled('div')`
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
