import React, { ChangeEvent, memo, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { format, isValid } from 'date-fns';
import { observer } from 'mobx-react-lite';
import brown from '@mui/material/colors/brown';
import green from '@mui/material/colors/green';
import indigo from '@mui/material/colors/indigo';
import orange from '@mui/material/colors/orange';
import purple from '@mui/material/colors/purple';
import red from '@mui/material/colors/red';
import teal from '@mui/material/colors/teal';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { notificationAPI } from '@/api/notificationAPI';
import { DATE_HUMAN_FORMAT } from '@/constants';
import { StatisticTimeline } from '@/stores/ReportsStore/ReportsStore.constants';
import { StatisticByTimeline } from '@/stores/ReportsStore/ReportsStore.types';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, SelectField } from '@/ui-kit/components';

interface StatsProps {
  companyId?: number;
  wrapperRef: MutableRefObject<Nullable<HTMLDivElement>>;
}

interface StatisticsData {
  labels: string[];
  companies: number[];
  devices: number[];
  documents: number[];
  ops: number[];
  persons: number[];
  personsRus: number[];
  users: number[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Stats(props: StatsProps) {
  const { companyId, wrapperRef } = props;
  const { statistics, getStatisticData, isFetching } = useStore('reportsStore');

  const [timeline, setTimeline] = useState<StatisticTimeline>(StatisticTimeline.Day);

  async function getStatistics() {
    try {
      await getStatisticData(timeline, companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    getStatistics();
  }, [timeline]);

  const onChangeTimeline = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    setTimeline(value as StatisticTimeline);
  }, []);

  const statisticsDataForChart = useMemo(
    () =>
      statistics.reduce(
        (acc: StatisticsData, statistic: StatisticByTimeline) => {
          const labels = acc.labels ?? [];
          const companies = acc.companies ?? [];
          const devices = acc.devices ?? [];
          const documents = acc.documents ?? [];
          const ops = acc.ops ?? [];
          const persons = acc.persons ?? [];
          const personsRus = acc.personsRus ?? [];
          const users = acc.users ?? [];

          const timelineValue = new Date(statistic.timeline);

          if (isValid(timelineValue)) {
            labels.push(format(timelineValue, DATE_HUMAN_FORMAT));
          }

          companies.push(statistic.companies ?? 0);
          devices.push(statistic.devices ?? 0);
          documents.push(statistic.documents ?? 0);
          ops.push(statistic.ops ?? 0);
          persons.push(statistic.persons ?? 0);
          personsRus.push(statistic.persons_rus ?? 0);
          users.push(statistic.users ?? 0);

          return {
            labels,
            companies,
            devices,
            documents,
            ops,
            persons,
            personsRus,
            users,
          };
        },
        {
          labels: [],
          companies: [],
          devices: [],
          documents: [],
          ops: [],
          persons: [],
          personsRus: [],
          users: [],
        },
      ),
    [JSON.stringify(statistics)],
  );

  /*
   * `aspectRatio` нужен, чтобы автоматически рассчитать ширину и высоту диаграммы в зависимости от ширины/высоты экрана
   *
   * При смене ширины экрана - нужно обновить страницу, чтобы пересчитать ширины/высоту
   * */
  let aspectRatio = 1;

  if (wrapperRef.current?.offsetHeight && wrapperRef.current?.offsetWidth) {
    // 150 - высота блока `RetentionData` + паддинги и отступы
    aspectRatio = wrapperRef.current.offsetWidth / (wrapperRef.current.offsetHeight - 150);
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <BackdropLoader isLoading={isFetching} />

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectField name="timeline" onChange={onChangeTimeline} value={timeline}>
                <MenuItem value={StatisticTimeline.Day}>День</MenuItem>

                <MenuItem value={StatisticTimeline.Week}>Неделя</MenuItem>

                <MenuItem value={StatisticTimeline.Month}>Месяц</MenuItem>

                <MenuItem value={StatisticTimeline.Year}>Год</MenuItem>
              </SelectField>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Bar
            data={{
              labels: statisticsDataForChart.labels,

              datasets: [
                {
                  label: 'Компаний: ',

                  data: statisticsDataForChart.companies,

                  backgroundColor: red['100'],
                },

                {
                  label: 'Устройств: ',

                  data: statisticsDataForChart.devices,

                  backgroundColor: purple['100'],
                },

                {
                  label: 'Документов: ',

                  data: statisticsDataForChart.documents,

                  backgroundColor: indigo['100'],
                },

                {
                  label: 'Операций: ',

                  data: statisticsDataForChart.ops,

                  backgroundColor: teal['100'],
                },

                {
                  label: 'Гостей: ',

                  data: statisticsDataForChart.persons,

                  backgroundColor: green['100'],
                },

                {
                  label: 'Гостей РФ: ',

                  data: statisticsDataForChart.personsRus,

                  backgroundColor: orange['100'],
                },

                {
                  label: 'Пользователей: ',

                  data: statisticsDataForChart.users,

                  backgroundColor: brown['100'],
                },
              ],
            }}
            options={{
              aspectRatio,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(observer(Stats));
