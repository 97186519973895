import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import AccordionSummary from '@mui/material/AccordionSummary';
import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';

export const BasicPersonSummary = styled(AccordionSummary)`
  min-height: 0;
  height: 48px;

  &.Mui-expanded {
    min-height: 0;
    height: 48px;
  }

  &.MuiAccordionSummary-content {
    align-items: center;
  }
`;

export const BasicPersonCaption = styled('div')`
  margin-left: 12px;
  color: ${grey['400']};
`;

export const BasicPersonCell = styled(Grid)`
  position: relative;
`;

export const BasicPersonLegalLabelIcon = styled(EscalatorWarningIcon)`
  margin-top: 8px;
`;

export const BasicPersonDivider = styled('div')`
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 12px 0;
`;

export const BasicPersonDividerLine = styled(Divider)`
  position: absolute;
  width: 100%;
  top: 16px;
`;

export const BasicPersonDividerChip = styled('div')`
  z-index: 2;
  padding: 0 10px;
  background-color: ${common.white};
`;

export const BasicPersonTransliteration = styled('div')`
  top: 34px;
  right: 0;
  position: absolute;
  z-index: 100;
`;

export const BasicPersonHistory = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const BasicPersonCenteredGrid = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
