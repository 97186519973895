import React, { Fragment, memo } from 'react';
import { observer } from 'mobx-react-lite';
import CakeIcon from '@mui/icons-material/Cake';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { MIN_PERSON_AGE } from '../../../PersonPage.constant';
import { usePersonPageStore } from '../../../PersonPageStore';

function BirthdayIcon() {
  const { isBirthdate, personAge } = usePersonPageStore();

  if (personAge && personAge < MIN_PERSON_AGE) {
    return (
      <Fragment>
        <InfoIcon color="warning" fontSize="small" />
        &nbsp;
      </Fragment>
    );
  }

  if (isBirthdate) {
    return (
      <Fragment>
        <Tooltip title="У гостя день рождения">
          <CakeIcon color="success" fontSize="small" />
        </Tooltip>
        &nbsp;
      </Fragment>
    );
  }

  return null;
}

export default memo(observer(BirthdayIcon));
