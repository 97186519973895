import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import { css, styled } from '@mui/material/styles';

export const LegalRepresentativeRawRoot = styled(Grid, {
  shouldForwardProp: (name) => name !== 'isSelected',
})(
  ({ isSelected }: { isSelected?: boolean }) => css`
    padding: 8px;
    cursor: pointer;
    background-color: ${isSelected ? grey[200] : 'initial'};

    &:hover {
      background-color: ${grey[100]};
    }
  `,
);
