export function isArray(value: unknown) {
  if (value === null || typeof value === 'undefined') {
    return false;
  }

  const length = (value as never[]).length;
  const isValidLength = typeof length === 'number' && length > -1 && length % 1 === 0;

  return typeof value !== 'function' && typeof value !== 'string' && isValidLength;
}
