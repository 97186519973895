import React, { memo } from 'react';
import { format } from 'date-fns';
import Switch from '@mui/material/Switch';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { DATE_HUMAN_FORMAT } from '@/constants';
import { UserDevice } from '@/entities/userDevice';

interface UserDevicesRowProps {
  device: UserDevice;
  setDefault: (id: number) => void;
}

function UserDevicesRow(props: UserDevicesRowProps) {
  const { device, setDefault } = props;

  function onChangeHandler() {
    setDefault(device.id);
  }

  return (
    <TableRow>
      <TableCell>{device.name}</TableCell>

      <TableCell>Создано {format(new Date(device.created_at), DATE_HUMAN_FORMAT)}</TableCell>

      <TableCell>
        <Switch
          checked={device.is_default}
          color="primary"
          disableRipple
          onChange={onChangeHandler}
          readOnly
          size="small"
        />
      </TableCell>
    </TableRow>
  );
}

export default memo(UserDevicesRow);
