export function pluralizer(count: number, values: string[]) {
  count = Math.abs(count) % 100;

  const n1 = count % 10;

  if (count > 10 && count < 20) {
    return values[2];
  }

  if (n1 > 1 && n1 < 5) {
    return values[1];
  }

  if (n1 === 1) {
    return values[0];
  }

  return values[2];
}
