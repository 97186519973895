import { UserModel } from '@/entities/user';

export const DEFAULT_USER_FORM: UserModel = {
  fname: '',
  lname: '',
  email: '',
  password: '',
  position: '',
  company_id: 0,
  active: true,
  company_superuser: false,
  use_ip_whitelist: false,
  external_access_only: false,
  token_keep: false,
  rwr: false,
  role: undefined,
  token_lifetime_min: '',
};
