export enum AddressLevel {
  Region = 1,
  Area = 3,
  City = 4,
  Place = 6,
  Street = 7,
}

export class AddressModel {
  id!: number;
  parent!: number;
  ao_id!: string;
  ao_guid!: string;
  name!: string;
  short_name!: string;
  ao_level!: AddressLevel;
  region_code!: string;
  area_code!: string;
  city_code!: string;
  place_code!: string;
  full_name!: string;
  parents?: AddressModel[];
}

export class StreetModel {
  ao_id!: string;
  ao_guid!: string;
  region_code!: string;
  name!: string;
  short_name!: string;
  full_name!: string;
}

export class Address extends AddressModel {
  constructor(company: AddressModel) {
    super();

    Object.keys(company).forEach((key) => {
      switch (key) {
        case 'parents':
          if (Array.isArray(company.parents) && company.parents.length > 0) {
            this.parents = company.parents.map((address) => new Address(address));
          }

          break;
        default:
          this[key] = company[key];

          break;
      }
    });
  }
}

export class Street extends StreetModel {
  constructor(street: StreetModel) {
    super();

    Object.keys(street).forEach((key) => {
      this[key] = street[key];
    });
  }
}
