import React, { memo } from 'react';
import { useNavigate, useParams } from 'react-router';

import PersonPage from './PersonPage';

function PersonRouteWrapper() {
  const { id, groupId } = useParams();
  const navigate = useNavigate();

  return <PersonPage groupId={groupId} id={id} navigate={navigate} />;
}

export default memo(PersonRouteWrapper);
