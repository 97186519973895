import React, { ChangeEvent, Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { observer } from 'mobx-react-lite';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Tooltip from '@mui/material/Tooltip';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { Citizen, DocumentType, DocumentTypeEnumId } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { PersonDocument, PersonStatus } from '@/stores/PersonsStore';
import {
  ID_CARD_DOCS_IDS_1,
  ID_CARD_DOCS_IDS_2,
  ID_CARD_DOCS_IDS_3,
} from '@/stores/PersonsStore/PersonsStore.constants';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField, DatepickerField, InputField, SelectField } from '@/ui-kit/components';
import { parseDocIssueCode } from '@/utils/parseDocIssueCode';

import { DocIssueType } from './IdCardForm.constants';
import { AuthorityOrganWarningIcon } from './IdCardForm.style';
import { checkIdCardData, filterDocumentsByCitizenship } from './IdCardForm.utils';

interface IdCardFormProps {
  idCard: PersonDocument;
  setIdCardValues: (values: PersonDocument) => void;
  citizenship: Nullable<number>;
  disabled?: boolean;
  isDeleted?: boolean;
  status?: PersonStatus;
  variant?: 'standard' | 'outlined' | 'filled';
  errors?: Record<string, boolean>;
  // `mode` нужен для генерации ворнингов в форме,
  // они должны генерироваться только для уже созданных персонов,
  // соответственно нам нужен механизм проверки на уже созданного персона
  mode: 'create' | 'edit';
}

// вынесено в shared, потому что используется в двух местах:
// на странице регистрации и на странице
function IdCardForm(props: IdCardFormProps) {
  const {
    idCard,
    setIdCardValues,
    isDeleted,
    disabled = false,
    status,
    citizenship,
    variant,
    errors,
    mode = 'create',
  } = props;
  const { docTypes, getDocTypes, docIssueAuthorityOrgans, getDocIssueAuthorityOrgans } = useStore('dictionariesStore');

  const isExported = status === PersonStatus.Exported;
  const shouldShowDocIssueType = [DocumentTypeEnumId.RusPassport].includes(+idCard.document_type_enum_id);

  const [isShowNoOrgan, openNoOrgan, closeNoOrgan] = useToggle();
  const [isAuthorityOrganTooltipOpen, openAuthorityOrganTooltip, closeAuthorityOrganTooltip] = useToggle();
  const [isAuthorityOrganDropdownOpen, openAuthorityOrganDropdown, closeAuthorityOrganDropdown] = useToggle();
  const [docIssueType, setDocIssueType] = useState(DocIssueType.Dictionary);

  const warning = useMemo(
    () => (mode === 'edit' && status !== PersonStatus.Exported ? checkIdCardData(idCard) : {}),
    [mode, status, JSON.stringify(idCard)],
  );

  const docTypesFiltered = useMemo(
    () =>
      (citizenship ? filterDocumentsByCitizenship(docTypes, citizenship) : docTypes).filter(
        ({ category }) => category === DocumentType.IdCard,
      ),
    [citizenship, JSON.stringify(docTypes)],
  );

  const docIssueAuthorityOptions = useMemo(
    () => [...docIssueAuthorityOrgans.map(({ name_ru }) => name_ru)],
    [JSON.stringify(docIssueAuthorityOrgans)],
  );

  async function fetchAuthorityOrgan(code: string) {
    const organs = await getDocIssueAuthorityOrgans(code);
    let organ = organs?.[0];

    if (organs && idCard.doc_issue_authority) {
      const selectedOrgan = organs.find(({ name_ru }) => name_ru === idCard.doc_issue_authority);

      if (selectedOrgan) {
        organ = selectedOrgan;
      }
    }

    if (organ) {
      setIdCardValues({
        ...idCard,
        doc_issue_authority_organ_id: organ.id,
        doc_issue_authority_organ: organ,
        doc_issue_authority: organ.name_ru,
        doc_issue_code: organ.code,
      });
    } else {
      openNoOrgan();
    }
  }

  const onChangeIdCardValue = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target;

      setIdCardValues({
        ...idCard,
        [name]: value,
      });
    },
    [JSON.stringify(idCard)],
  );

  const onIssueAuthorityCodeChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const docIssueCode = target.value.replace(' - ', '-').replace('_', '').trim();

      setIdCardValues({
        ...idCard,
        doc_issue_code: docIssueCode,
      });

      const code = parseDocIssueCode(docIssueCode);

      if (code) {
        fetchAuthorityOrgan(code);
      }
    },
    [JSON.stringify(idCard)],
  );

  const onAuthorityOrganSelect = useCallback(
    (name: Nullable<string>) => {
      const organ = docIssueAuthorityOrgans.find(({ name_ru }) => name_ru === name);

      if (organ) {
        setIdCardValues({
          ...idCard,
          doc_issue_authority_organ_id: organ.id,
          doc_issue_authority_organ: organ,
        });
      }
    },
    [setIdCardValues, JSON.stringify(idCard), JSON.stringify(docIssueAuthorityOrgans)],
  );

  const onAuthorityOrganChangeHandler = useCallback(
    (authorityOrgan: unknown) => {
      onAuthorityOrganSelect(authorityOrgan as Nullable<string>);
      closeAuthorityOrganDropdown();
    },
    [onAuthorityOrganSelect],
  );

  function renderAuthorityOrganIcon() {
    if (docIssueAuthorityOptions.length > 1 && !isExported && !isDeleted) {
      return (
        <Fragment>
          <Tooltip title="Найдено несколько значений">
            <AuthorityOrganWarningIcon fontSize="small" />
          </Tooltip>
          &nbsp;
        </Fragment>
      );
    }

    return null;
  }

  const filterAuthorityOptions = useCallback(
    (option: string[], { inputValue }: FilterOptionsState<string>) =>
      option.filter((name: string) => name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
    [],
  );

  useEffect(() => {
    if (
      docTypesFiltered.length > 0 &&
      (!idCard.document_type_enum_id || !docTypesFiltered.some(({ id }) => id === idCard.document_type_enum_id))
    ) {
      setIdCardValues({
        ...idCard,
        // 20 - иностранный паспорт, ставится по умолчанию, если гражданство не россия
        document_type_enum_id:
          citizenship !== Citizen.Russia ? DocumentTypeEnumId.ForeignPassport : docTypesFiltered[0].id,
      });
    }
  }, [citizenship]);

  useEffect(() => {
    if (docTypes.length === 0) {
      getDocTypes();
    }
  }, []);

  useEffect(() => {
    if (idCard.doc_issue_code) {
      const code = parseDocIssueCode(idCard.doc_issue_code);

      if (code) {
        getDocIssueAuthorityOrgans(code, idCard.doc_issue_authority);
      }
    }
  }, [idCard.doc_issue_code]);

  return (
    <fieldset disabled={disabled}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SelectField
            disabled={docTypes.length === 0 || isExported || isDeleted}
            label="Тип документа"
            name="document_type_enum_id"
            onChange={onChangeIdCardValue}
            value={idCard.document_type_enum_id}
            variant={variant}
          >
            {docTypesFiltered.map(({ id, name_ru }) => (
              <MenuItem key={id} value={id}>
                {name_ru}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        {idCard.document_type_enum_id && (
          <Grid item xs={12}>
            <Grid container spacing={2} wrap="wrap">
              <Grid item xs={3}>
                <InputField
                  error={errors?.doc_serial}
                  label="Серия"
                  name="doc_serial"
                  onChange={onChangeIdCardValue}
                  tooltip={warning.doc_serial}
                  value={idCard.doc_serial}
                  variant={variant}
                />
              </Grid>

              <Grid item xs={3}>
                <InputField
                  error={errors?.doc_number}
                  label="Номер"
                  name="doc_number"
                  onChange={onChangeIdCardValue}
                  tooltip={warning.doc_number}
                  value={idCard.doc_number}
                  variant={variant}
                />
              </Grid>

              <Grid item xs={3}>
                <DatepickerField
                  disabled={isExported || isDeleted}
                  error={errors?.doc_issue_date}
                  label="Дата выдачи"
                  name="doc_issue_date"
                  onChange={onChangeIdCardValue}
                  tooltip={warning.doc_issue_date}
                  value={idCard.doc_issue_date}
                  variant={variant}
                />
              </Grid>

              {!ID_CARD_DOCS_IDS_1.includes(+idCard.document_type_enum_id) && (
                <Fragment>
                  {(citizenship === Citizen.Russia || citizenship === Citizen.USSR) && (
                    <Grid item xs={3}>
                      <DatepickerField
                        disabled={isExported || isDeleted}
                        error={errors?.doc_start_date}
                        label="Срок действия c"
                        name="doc_start_date"
                        onChange={onChangeIdCardValue}
                        tooltip={warning.doc_start_date}
                        value={idCard.doc_start_date}
                        variant={variant}
                      />
                    </Grid>
                  )}

                  <Grid item xs={3}>
                    <DatepickerField
                      disabled={isExported || isDeleted}
                      error={errors?.doc_expire_date}
                      label="Срок действия по"
                      name="doc_expire_date"
                      onChange={onChangeIdCardValue}
                      tooltip={warning.doc_expire_date}
                      value={idCard.doc_expire_date}
                      variant={variant}
                    />
                  </Grid>
                </Fragment>
              )}

              {ID_CARD_DOCS_IDS_3.includes(+idCard.document_type_enum_id) && (
                <Fragment>
                  <Grid item xs={3}>
                    <InputField
                      error={errors?.doc_decision_number}
                      label="Номер решения"
                      name="doc_decision_number"
                      onChange={onChangeIdCardValue}
                      value={idCard.doc_decision_number}
                      variant={variant}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <DatepickerField
                      disabled={isExported || isDeleted}
                      error={errors?.doc_start_date}
                      label="Дата решения"
                      name="doc_start_date"
                      onChange={onChangeIdCardValue}
                      value={idCard.doc_start_date}
                      variant={variant}
                    />
                  </Grid>
                </Fragment>
              )}

              <Grid item xs={12}>
                <Grid container spacing={2} wrap="wrap">
                  {ID_CARD_DOCS_IDS_2.includes(+idCard.document_type_enum_id) && (
                    <Tooltip
                      onClick={closeNoOrgan}
                      open={isShowNoOrgan}
                      placement="top"
                      title="Нет подразделений соответствующих данному коду"
                    >
                      <Grid item xs={3}>
                        <InputMask
                          mask="999 - 999"
                          maskPlaceholder={null}
                          name="doc_issue_code"
                          onBlur={closeNoOrgan}
                          onChange={onIssueAuthorityCodeChange}
                          onFocus={() => setDocIssueType(DocIssueType.Dictionary)}
                          value={idCard.doc_issue_code}
                        >
                          {/* @ts-ignore */}

                          {() => (
                            <InputField error={errors?.doc_issue_code} label="Код подразделения" variant={variant} />
                          )}
                        </InputMask>
                      </Grid>
                    </Tooltip>
                  )}

                  {shouldShowDocIssueType && (
                    <Fragment>
                      <Grid item xs={9}>
                        <RadioGroup
                          name="order"
                          onChange={({ target }) => setDocIssueType(target.value as DocIssueType)}
                          row
                          value={docIssueType}
                        >
                          <FormControlLabel
                            control={<Radio color="primary" />}
                            disabled={isExported || isDeleted}
                            label="По справочнику"
                            value={DocIssueType.Dictionary}
                          />

                          <FormControlLabel
                            control={<Radio color="primary" />}
                            disabled={isExported || isDeleted}
                            label="Как в паспорте"
                            value={DocIssueType.Passport}
                          />
                        </RadioGroup>
                      </Grid>

                      {docIssueType === DocIssueType.Dictionary && (
                        <Tooltip
                          open={isAuthorityOrganTooltipOpen}
                          placement="top"
                          title={
                            shouldShowDocIssueType && !isExported && !isDeleted
                              ? 'Редактирование данного поля доступно только в режиме "Как в паспорте"'
                              : null
                          }
                        >
                          <Grid item xs={12}>
                            <AutocompleteField
                              InputProps={{
                                startAdornment: renderAuthorityOrganIcon(),
                              }}
                              disableClearable
                              disabled={isExported || isDeleted}
                              filterOptions={filterAuthorityOptions}
                              inputProps={{
                                onMouseEnter: () => {
                                  if (!isAuthorityOrganDropdownOpen) {
                                    openAuthorityOrganTooltip();
                                  }
                                },
                                onMouseLeave: () => closeAuthorityOrganTooltip(),
                              }}
                              isError={errors?.doc_issue_authority_organ}
                              label="Кем выдан"
                              name="doc_issue_authority_organ"
                              noOptionsText="Нет данных"
                              onBlur={closeAuthorityOrganDropdown}
                              onChange={onAuthorityOrganChangeHandler}
                              onFocus={() => {
                                openAuthorityOrganDropdown();
                                closeAuthorityOrganTooltip();
                              }}
                              onOpen={closeAuthorityOrganTooltip}
                              open={isAuthorityOrganDropdownOpen}
                              openOnFocus
                              options={[...docIssueAuthorityOrgans.map(({ name_ru }) => name_ru)]}
                              popupIcon={null}
                              value={idCard.doc_issue_authority_organ?.name_ru ?? ''}
                              variant={variant}
                            />
                          </Grid>
                        </Tooltip>
                      )}
                    </Fragment>
                  )}

                  {(docIssueType === DocIssueType.Passport || !shouldShowDocIssueType) && (
                    <Grid item xs={12}>
                      <InputField
                        error={errors?.doc_issue_authority}
                        label="Кем выдан"
                        name="doc_issue_authority"
                        onChange={onChangeIdCardValue}
                        value={idCard.doc_issue_authority}
                        variant={variant}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </fieldset>
  );
}

export default memo(observer(IdCardForm));
