import ButtonGroup from '@mui/material/ButtonGroup';
import common from '@mui/material/colors/common';
import styled from '@mui/material/styles/styled';

export const CameraSelector = styled('div')`
  margin-bottom: 24px;
`;

export const CameraPane = styled('div')`
  position: relative;
`;

export const CameraVideoWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  margin: 0 auto;
`;

export const CameraVideoContainer = styled('div')`
  position: absolute;
  z-index: 1010; // значение больше чем у \`BackdropLoader\`
`;

export const CameraVideoCanvas = styled('video')`
  width: 100%;
  height: 100%;
`;

export const CameraToolbar = styled('div')`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1011; // значение больше чем у \`CameraVideoContainer\`
  background-color: ${common.white};
  width: 70px;
`;

export const CameraZoom = styled(ButtonGroup)`
  margin-top: 24px;
`;
