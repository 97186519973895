import styled from '@mui/material/styles/styled';

export const AnimatedLongValueRoot = styled('div')`
  position: relative;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  min-height: 26px;
`;

export const AnimatedLongValueContent = styled('div')`
  line-height: 26px;
  position: absolute;
  left: 0;
  top: 0;
  transition: 2s;
`;
