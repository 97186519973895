import React, {
  Fragment,
  memo,
  MouseEvent,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import {
  DATE_HUMAN_FORMAT,
  DEFAULT_PAGINATION_LIMIT,
  DEPRECATED_DEFAULT_DATE,
  FIRST_NAME_IS_EMPTY_LABEL,
  LAST_NAME_IS_EMPTY_LABEL,
  ROUTE_FALLBACK_LABEL,
  ROUTE_PERSON,
  ROUTE_REGISTRATIONS,
  TIME_HUMAN_FORMAT_FULL,
} from '@/constants';
import { Group } from '@/entities/group';
import ChangePersonStatusIcon from '@/shared/ChangePersonStatusIcon/ChangePersonStatusIcon';
import { HMS_STATUS_MAP } from '@/shared/Persons/PersonRaw/PersonRaw.constants';
import ProtectedText from '@/shared/ProtectedText/ProtectedText';
import { Person } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';
import AnimatedLongValue from '@/ui-kit/components/AnimatedLongValue/AnimatedLongValue';

import {
  PersonRowActionButton,
  PersonRowLongValueWrapper,
  PersonRowName,
  PersonRowRoot,
  PersonRowSubName,
  PersonStayTill,
} from './PersonRaw.style';

interface PersonRowProps {
  person: Person;
  mode: 'general' | 'deleted';
}

function PersonRaw(props: PersonRowProps) {
  const { person, mode } = props;
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { search, hash } = useLocation();
  const {
    selectedPersons,
    getPersons,
    selectReservation,
    deletePersons,
    restorePersons,
    isSaving,
    isRestoring,
    setStatus,
  } = useStore('personsStore');
  const { reloadGroupData, getSingleGroupById, isFetchingSingleGroup } = useStore('groupsStore');
  const { userCompany } = useStore('signInStore');

  const [personGroup, setPersonGroup] = useState<Nullable<Group>>(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDescriptionTooltipOpen, setIsDescriptionTooltipOpen] = useState(false);
  const [isRestoreOpen, setIsRestoreOpen] = useState(false);

  const query = queryAPI.params;

  const limit = useMemo(
    () => (query.limit && !isNaN(+query.limit) ? +query.limit : DEFAULT_PAGINATION_LIMIT),
    [query.limit],
  );
  const page = useMemo(() => (query.page && !isNaN(+query.page) ? +query.page : 0), [query.page]);

  async function onSelectAction({ currentTarget }: MouseEvent<HTMLLIElement>) {
    try {
      await setStatus(person.id, currentTarget.value);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function onDeletePerson() {
    try {
      await deletePersons([person.id]);

      await reloadGroupData(person.group_id);

      notificationAPI.success(`Запись "${person.fname} ${person.lname}" удалена`);

      setIsDeleteOpen(false);

      await getPersons({
        groupId,
        limit,
        page,
      });
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function onRestorePerson() {
    try {
      await restorePersons([person.id]);

      notificationAPI.success(`Карточка гостя "${person.fname} ${person.lname}" восстановлена`);

      setIsRestoreOpen(false);

      await getPersons({
        groupId,
        limit,
        page,
        show_deleted: mode === 'deleted',
      });
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function onSelectPerson() {
    selectReservation(person.id);
  }

  const onNavigateToPersonPage = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      const params = new URLSearchParams(search);

      params.append(ROUTE_FALLBACK_LABEL, ROUTE_REGISTRATIONS);

      let url = `${ROUTE_PERSON}/${person.id}`;

      if (groupId) {
        url += `/${groupId}`;
      }

      url += `?${params.toString()}`;

      navigate(url);
    },
    [groupId, search, mode],
  );
  const onToggleDeletePersonModal = useCallback(() => {
    setIsDeleteOpen((isDeleteOpen: boolean) => !isDeleteOpen);
  }, []);
  const onToggleDeleteModalOpen = useCallback(() => setIsDeleteOpen((isDeleteOpen: boolean) => !isDeleteOpen), []);

  // TODO: https://github.com/mui-org/material-ui/issues/4535
  // если вешать клик на строку и при это иметь клики внутри отдельных ячеек,
  // то не будет работать stopPropagation и будут вызваны оба хандлера (на TableRow и на кнопки внутри ячеек)
  function TableCellOpener({ children }: PropsWithChildren<unknown>) {
    return <TableCell onClick={onNavigateToPersonPage}>{children}</TableCell>;
  }

  async function onOpenContext(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    setIsDescriptionTooltipOpen(true);

    if (!personGroup) {
      const group = await getSingleGroupById(person.group_id);

      setPersonGroup(group);
    }

    return false;
  }

  function onBlurTableRow() {
    setIsDescriptionTooltipOpen(false);
  }

  function onToggleRestoreModal() {
    setIsRestoreOpen((isRestoreOpen: boolean) => !isRestoreOpen);
  }

  useEffect(() => {
    window.addEventListener('scroll', onBlurTableRow);

    return () => {
      window.removeEventListener('scroll', onBlurTableRow);
    };
  }, []);

  return (
    <Tooltip
      TransitionProps={{
        timeout: 0,
      }}
      enterDelay={0}
      leaveDelay={0}
      open={isDescriptionTooltipOpen}
      title={isFetchingSingleGroup ? 'загрузка...' : `Расположение: “${personGroup?.name}”`}
    >
      <PersonRowRoot
        hover
        id={`#person-${person.id}`}
        isHighlighted={hash === `#person-${person.id}`}
        onContextMenu={onOpenContext}
        onMouseLeave={onBlurTableRow}
      >
        <TableCell padding="none">
          <Checkbox checked={selectedPersons.has(person.id)} color="primary" onChange={onSelectPerson} />
        </TableCell>

        <TableCell padding="none">
          {mode === 'general' && (
            <ChangePersonStatusIcon
              onSetPersonStatus={onSelectAction}
              status={person.status}
              title="Изменить статус карточки"
            />
          )}
        </TableCell>

        <TableCellOpener>
          <PersonRowName>
            {person.lname && (
              <Fragment>
                <PersonRowLongValueWrapper>
                  <AnimatedLongValue title={person.lname} />
                </PersonRowLongValueWrapper>

                {person.lname_lat && person.lname_lat !== LAST_NAME_IS_EMPTY_LABEL && (
                  <PersonRowSubName>{person.lname_lat}</PersonRowSubName>
                )}
              </Fragment>
            )}

            {!person.lname && person.lname_lat && (
              <div>{person.lname_lat !== LAST_NAME_IS_EMPTY_LABEL ? person.lname_lat : <i>Отсутствует</i>}</div>
            )}
          </PersonRowName>
        </TableCellOpener>

        <TableCellOpener>
          <PersonRowName>
            {person.fname && (
              <Fragment>
                <PersonRowLongValueWrapper>
                  <AnimatedLongValue title={person.fname} />
                </PersonRowLongValueWrapper>

                {person.fname_lat && person.fname !== FIRST_NAME_IS_EMPTY_LABEL && (
                  <PersonRowSubName>{person.fname_lat}</PersonRowSubName>
                )}
              </Fragment>
            )}

            {!person.fname && person.fname_lat && (
              <div>{person.fname_lat !== FIRST_NAME_IS_EMPTY_LABEL ? person.fname_lat : <i>Отсутствует</i>}</div>
            )}
          </PersonRowName>
        </TableCellOpener>

        <TableCellOpener>{person.stay_info?.room_number}</TableCellOpener>

        <TableCellOpener>
          {person.country && (
            <PersonRowLongValueWrapper>
              <AnimatedLongValue title={person.country?.name_ru} />
            </PersonRowLongValueWrapper>
          )}
        </TableCellOpener>

        <TableCellOpener>
          <ProtectedText
            title={
              <Fragment>
                <span>{person.doc_serial} </span>

                <span>{person.doc_number}</span>
              </Fragment>
            }
          />
        </TableCellOpener>

        <TableCellOpener>
          <PersonRowName>
            {format(new Date(person.created_at), DATE_HUMAN_FORMAT)}

            <PersonRowSubName>{format(new Date(person.created_at), TIME_HUMAN_FORMAT_FULL)}</PersonRowSubName>
          </PersonRowName>
        </TableCellOpener>

        <TableCellOpener>
          {person.stay_info?.stay_from && (
            <Fragment>
              <div>{format(new Date(person.stay_info.stay_from), DATE_HUMAN_FORMAT)}</div>

              {person.stay_info?.stay_till && person.stay_info.stay_till !== DEPRECATED_DEFAULT_DATE && (
                <PersonStayTill>{format(new Date(person.stay_info.stay_till), DATE_HUMAN_FORMAT)}</PersonStayTill>
              )}
            </Fragment>
          )}
        </TableCellOpener>

        {userCompany?.company?.hasHMSStatus && (
          <TableCell>{person?.stay_info?.hms_status && HMS_STATUS_MAP[person.stay_info.hms_status]}</TableCell>
        )}

        <TableCell align="center" padding="none">
          {mode === 'general' && (
            <Tooltip title="Удалить карточку">
              <PersonRowActionButton className="personRowActionButton" onClick={onToggleDeletePersonModal}>
                <DeleteIcon />
              </PersonRowActionButton>
            </Tooltip>
          )}

          {mode === 'deleted' && (
            <Tooltip title="Восстановить карточку гостя">
              <PersonRowActionButton className="personRowActionButton" onClick={onToggleRestoreModal}>
                <RestoreIcon />
              </PersonRowActionButton>
            </Tooltip>
          )}

          <ConfirmModal
            confirmButtonProps={{
              color: 'error',
            }}
            description={`Вы уверены, что хотите удалить карточку гостя "${person.fname} ${person.lname}"?`}
            isLoading={isSaving}
            onClose={onToggleDeleteModalOpen}
            onConfirm={onDeletePerson}
            onDecline={onToggleDeleteModalOpen}
            open={isDeleteOpen}
            title="Удалить карточку"
          />

          <ConfirmModal
            confirmLabel="Восстановить"
            description={`Вы уверены, что хотите восстановить карточку гостя "${person.fname} ${person.lname}"?`}
            isLoading={isRestoring}
            onClose={onToggleRestoreModal}
            onConfirm={onRestorePerson}
            onDecline={onToggleRestoreModal}
            open={isRestoreOpen}
            title="Восстановление карточки гостя"
          />
        </TableCell>
      </PersonRowRoot>
    </Tooltip>
  );
}

export default memo(observer(PersonRaw));
