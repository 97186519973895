import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import g1logo from '@/assets/g1-logo.png';
import sokoslogo from '@/assets/sokos-logo.png';
import policy from '@/assets/terms-and-policy.pdf';

import { CheckinStep } from '../SelfCheckIn.constants';

import {
  GreetingEnglish,
  GreetingFooter,
  GreetingFooterDescription,
  GreetingFooterImage,
  GreetingFooterImages,
  GreetingNext,
  GreetingRoot,
} from './Greeting.style';

interface GreetingProps {
  onNextStep: (step: CheckinStep) => void;
}

function Greeting({ onNextStep }: GreetingProps) {
  function onClickContinue() {
    onNextStep(CheckinStep.Citizenship);
  }

  return (
    <GreetingRoot>
      <div>
        <Typography align="center" gutterBottom variant="h5">
          Добро пожаловать, Петров Иван!
        </Typography>

        <Typography align="center" variant="h6">
          Для самостоятельного заселения в отель &quot;Sokos Olympia Garden&quot; нажмите кнопку &quot;Продолжить&quot;
        </Typography>

        <GreetingNext>
          <Button onClick={onClickContinue} size="large" variant="contained">
            Продолжить*
          </Button>

          <GreetingEnglish>
            <Button size="small" variant="text">
              CONTINUE IN ENGLISH
            </Button>
          </GreetingEnglish>
        </GreetingNext>
      </div>

      <GreetingFooter>
        <GreetingFooterDescription>
          <Typography align="center" variant="caption">
            <span>
              *Нажимая кнопку &quot;Продолжить&quot; Вы даете согласие на обработку файлов Cookies и других
              пользовательских данных в соответствии с{' '}
            </span>

            <Link target="_blank" to={policy}>
              Политикой обработки персональных данных
            </Link>

            <span>.</span>
          </Typography>
        </GreetingFooterDescription>

        <GreetingFooterImages>
          <GreetingFooterImage alt="emis logo" height="30" src={g1logo} width="150" />

          <GreetingFooterImage alt="sokos logo" height="34" src={sokoslogo} width="200" />
        </GreetingFooterImages>
      </GreetingFooter>
    </GreetingRoot>
  );
}

export default memo(Greeting);
