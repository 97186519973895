import React, { Fragment, memo, SyntheticEvent } from 'react';
import { format, isValid } from 'date-fns';
import { observer } from 'mobx-react-lite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import StarsIcon from '@mui/icons-material/Stars';
import green from '@mui/material/colors/green';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { DATE_HUMAN_FORMAT_FULL, DEPRECATED_DEFAULT_DATE } from '@/constants';
import { User } from '@/entities/user';
import { useToggle } from '@/hooks/useToggle/useToggle';
import UserFormModal from '@/shared/UserFormModal/UserFormModal';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';

import { UserName } from './UserTableRaw.style';

interface UserTableRowProps {
  index: number;
  user: User;
  companyId: number;
  readonly?: boolean;
}

function UserTableRaw(props: UserTableRowProps) {
  const { user, companyId, readonly = false, index } = props;
  const { isSaving, getUsersByCompany, deleteUser } = useStore('usersStore');

  const [isUserModalOpen, , , toggleEditUserModal] = useToggle();
  const [isDeleteUserModalOpen, , , toggleDeleteUserModal] = useToggle();

  function onOpenDeleteUser(e: SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();

    toggleDeleteUserModal();
  }

  async function onDeleteHandler() {
    try {
      if (user?.id) {
        await deleteUser(user.id);

        notificationAPI.success('Пользователь удален');

        await getUsersByCompany(companyId);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  return (
    <Fragment>
      <TableRow hover onClick={toggleEditUserModal}>
        <TableCell>{index + 1}</TableCell>

        <TableCell>
          <UserName>
            <span>{user.lname}&nbsp;</span>

            {user.company_superuser && (
              <Tooltip title="Администратор компании">
                <StarsIcon color="primary" fontSize="small" />
              </Tooltip>
            )}
          </UserName>
        </TableCell>

        <TableCell>{user.fname}</TableCell>

        <TableCell>{user.email}</TableCell>

        <TableCell>{user.active ? 'Активен' : 'Не активен'}</TableCell>

        <TableCell>{user.role?.name}</TableCell>

        <TableCell>{user.id}</TableCell>

        <TableCell>
          {user.last_seen_at === DEPRECATED_DEFAULT_DATE && <span>Не было авторизаций</span>}

          {user.last_seen_at &&
            user.last_seen_at !== DEPRECATED_DEFAULT_DATE &&
            isValid(new Date(user.last_seen_at)) &&
            format(new Date(user.last_seen_at), DATE_HUMAN_FORMAT_FULL)}
        </TableCell>

        <TableCell align="center">
          {user.online && (
            <Tooltip title="Пользователь онлайн">
              <CheckCircleIcon fontSize="small" htmlColor={green['700']} />
            </Tooltip>
          )}
        </TableCell>

        {!readonly && (
          <TableCell align="right">
            {user.id !== 1 && (
              <Tooltip title="Удалить пользователя">
                <IconButton onClick={onOpenDeleteUser}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        )}
      </TableRow>

      <UserFormModal
        companyId={companyId}
        disableExternalAccess={readonly}
        disableSuperUserSelect={readonly}
        onClose={toggleEditUserModal}
        open={isUserModalOpen}
        type="edit"
        user={user}
      />

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={
          <Typography variant="body1">
            <span>Вы действительно хотите удалить пользователя </span>

            <span>&quot;{user.fname} </span>

            <span>{user.lname}&quot;?</span>
          </Typography>
        }
        isLoading={isSaving}
        onClose={toggleDeleteUserModal}
        onConfirm={onDeleteHandler}
        onDecline={toggleDeleteUserModal}
        open={isDeleteUserModalOpen}
        title="Удалить пользователя"
      />
    </Fragment>
  );
}

export default memo(observer(UserTableRaw));
