import React, { ChangeEvent, memo } from 'react';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import Tooltip from '@mui/material/Tooltip';
import { MobileTimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { MobileTimePickerProps } from '@mui/x-date-pickers/MobileTimePicker/MobileTimePicker.types';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { DEPRECATED_DEFAULT_DATE } from '@/constants';
import { getValidDateValue } from '@/utils';

import { TimepickerFieldRoot } from './TimepickerField.style';

interface FormDatepickerMemoizedProps
  extends Omit<TimePickerProps<Nullable<Date | string> | undefined>, 'onChange' | 'renderInput'> {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  invalidDateMessage?: string;
  helperText?: string;
  name?: string;
  fullWidth?: boolean;
  error?: boolean;
  size?: BaseTextFieldProps['size'];
  variant?: BaseTextFieldProps['variant'];
  margin?: BaseTextFieldProps['margin'];
  color?: BaseTextFieldProps['color'];
  focused?: BaseTextFieldProps['focused'];
  InputProps?: Partial<StandardInputProps>;
  tooltip?: string;
  style?: Record<string, unknown>;
  type?: 'desktop' | 'mobile';
}

function TimepickerField(props: FormDatepickerMemoizedProps) {
  const {
    value,
    invalidDateMessage = 'Неверный формат даты',
    error,
    name,
    onChange,
    helperText,
    size,
    color = 'primary',
    variant = 'standard',
    margin = 'none',
    style: inputStyle,
    focused,
    tooltip,
    type = 'desktop',
    ...rest
  } = props;

  const inputValue = value === DEPRECATED_DEFAULT_DATE ? null : value;

  function onChangeHandler(date: unknown) {
    onChange?.({
      target: {
        name,
        value: getValidDateValue(date as Date),
      },
    } as unknown as ChangeEvent<HTMLInputElement>);
  }

  const componentProps = {
    slotProps: {
      field: {
        InputProps: rest.InputProps,
      },
      textField: {
        color: tooltip ? 'warning' : color,
        error,
        focused: !!tooltip || focused,
        helperText: error ? invalidDateMessage : helperText,
        placeholder: '00:00',
        margin,
        size,
        style: inputStyle,
        variant,
      },
    },
    onChange: onChangeHandler,
    value: inputValue,
    ...rest,
  };

  return (
    <Tooltip placement="top" title={tooltip ?? ''}>
      <TimepickerFieldRoot>
        {type === 'desktop' && <TimePicker {...componentProps} />}

        {type === 'mobile' && <MobileTimePicker {...(componentProps as MobileTimePickerProps<Date>)} />}
      </TimepickerFieldRoot>
    </Tooltip>
  );
}

export default memo(TimepickerField);
