import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';

export const ExportOperaFile = styled('div')`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  & input {
    position: absolute;
    top: -1000px;
    left: -1000px;
    opacity: 0;
  },
`;

export const ExportOperaWrapper = styled(Grid)`
  margin-bottom: 24px;
`;

export const ExportAction = styled(Grid)`
  text-align: center;
`;
