export class EulaCardModel {
  id!: number;
  name!: string;
  file!: Nullable<File>;
}

export class EulaCard extends EulaCardModel {
  constructor(eulaCard: EulaCardModel) {
    super();

    Object.keys(eulaCard).forEach((key) => {
      this[key] = eulaCard[key];
    });
  }
}
