import React, { memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import ReportsIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import ViewListIcon from '@mui/icons-material/ViewList';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { DASHBOARD_ROUTES_MAP } from './AdminPanelTabs.constant';
import { getActiveTabIndex } from './AdminPanelTabs.utils';

function AdminPanelTabs() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [tab, setActiveTab] = useState(() => getActiveTabIndex(pathname));

  const onChangeTab = useCallback((e: SyntheticEvent, value: number) => {
    e.preventDefault();

    const targetRoute = DASHBOARD_ROUTES_MAP.get(value);

    if (targetRoute) {
      navigate(targetRoute);
    }

    setActiveTab(value);
  }, []);

  useEffect(() => {
    setActiveTab(getActiveTabIndex(pathname));
  }, [pathname]);

  return (
    <AppBar color="default" position="static">
      <Tabs indicatorColor="primary" onChange={onChangeTab} textColor="primary" value={tab}>
        <Tab icon={<ViewListIcon />} iconPosition="start" label="Компании" />

        <Tab icon={<ReportsIcon />} iconPosition="start" label="Все устройства" />

        <Tab icon={<GroupIcon />} iconPosition="start" label="Роли" />

        <Tab icon={<ReportsIcon />} iconPosition="start" label="Статистика" />
      </Tabs>
    </AppBar>
  );
}

export default memo(AdminPanelTabs);
