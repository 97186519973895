import { format } from 'date-fns';

import { DATE_HUMAN_FORMAT, TIME_HUMAN_FORMAT_FULL } from '@/constants';
import { User, UserModel } from '@/entities/user';

export abstract class RetentionModel {
  id!: number;
  company_id!: number;
  created_at!: string;
  deleted_at!: string;
  deleted_persons!: number;
  months!: number;
  user!: UserModel;
  email!: string;
  fname!: string;
  lname!: string;
  position!: string;
  tel!: string;
  user_id!: number;
}

export class Retention extends RetentionModel {
  constructor(retention: RetentionModel) {
    super();

    Object.keys(retention).forEach((key) => {
      switch (key) {
        case 'user':
          if (retention.user) {
            this.user = new User(retention.user);
          }

          break;
        default:
          this[key] = retention[key];

          break;
      }
    });
  }

  get isEmisService() {
    if (this.user?.id === 1) {
      return true;
    }

    return false;
  }

  get userName() {
    if (!this.user) {
      return '';
    }

    if (this.isEmisService) {
      return 'EMIS Service';
    }

    return `${this.user.fname} ${this.user.lname}`;
  }

  get userEmail() {
    if (!this.user) {
      return '';
    }

    if (this.isEmisService) {
      return '';
    }

    return this.user.email;
  }

  get deletedAt() {
    return new Date(this.created_at);
  }

  get deletedAtDate() {
    return format(this.deletedAt, DATE_HUMAN_FORMAT);
  }

  get deletedAtTime() {
    return format(this.deletedAt, TIME_HUMAN_FORMAT_FULL);
  }
}
