import React, { memo, ReactNode } from 'react';
import Button, { ButtonOwnProps } from '@mui/material/Button';

import { ButtonLoader, ContentModalProps } from '@/ui-kit/components';
import { ConfirmModalActions } from '@/ui-kit/components/ConfirmModal/ConfirmModal.styles';

import { ContentModal } from '../index';

interface ConfirmModalProps {
  description: ReactNode | string;
  onConfirm: () => void;
  onDecline: () => void;
  isLoading?: boolean;
  confirmLabel?: string;
  declineLabel?: string;
  confirmButtonProps?: Omit<ButtonOwnProps, 'onClick'>;
  declineButtonProps?: Omit<ButtonOwnProps, 'disabled' | 'onClick'>;
  actionsAlign?: 'left' | 'center' | 'right';
}

function ConfirmModal(props: ContentModalProps<ConfirmModalProps>) {
  const {
    open,
    onConfirm,
    onDecline,
    onClose,
    title,
    description,
    confirmLabel = 'Удалить',
    confirmButtonProps = {},
    declineLabel = 'Отмена',
    declineButtonProps = {},
    maxWidth = 'xs',
    isLoading = false,
    actionsAlign = 'right',
  } = props;
  const { color: confirmColor = 'primary', disabled: confirmDisable, ...confirmRest } = confirmButtonProps;
  const { color: declineColor = 'primary', ...declineRest } = declineButtonProps;

  return (
    <ContentModal disableEscapeKeyDown={isLoading} maxWidth={maxWidth} onClose={onClose} open={open}>
      <ContentModal.Header isCloseDisabled={isLoading} onClose={onClose}>
        {title}
      </ContentModal.Header>

      <ContentModal.Body>{description}</ContentModal.Body>

      <ContentModal.Footer>
        <ConfirmModalActions actionsAlign={actionsAlign}>
          <Button color={declineColor} disabled={isLoading} onClick={onDecline} {...declineRest}>
            {declineLabel}
          </Button>

          <ButtonLoader
            color={confirmColor}
            disabled={confirmDisable}
            isLoading={isLoading}
            onClick={onConfirm}
            {...confirmRest}
          >
            {confirmLabel}
          </ButtonLoader>
        </ConfirmModalActions>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export type { ConfirmModalProps };
export default memo(ConfirmModal);
