import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';

export const GroupFormImageRoot = styled('div')`
  position: relative;
  margin-right: 12px;
`;

export const GroupFormImageRemove = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
