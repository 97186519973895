import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import g1logo from '@/assets/g1-logo.png';
import policy from '@/assets/terms-and-policy.pdf';

import { CheckinStep } from '../SelfCheckInMobile.constants';

import { GreetingFooter, GreetingFooterDescription, GreetingRoot } from './Greeting.style';

interface GreetingProps {
  name: string;
  onNextStep: (step: CheckinStep) => void;
}

function Greeting({ name, onNextStep }: GreetingProps) {
  function onClickContinue() {
    onNextStep(CheckinStep.Citizenship);
  }

  return (
    <GreetingRoot>
      <div>
        <Typography gutterBottom variant="h6">
          Добро пожаловать, {name}!
        </Typography>

        <Typography variant="subtitle2">
          Для самостоятельного заселения в отель нажмите кнопку &quot;Продолжить&quot;
        </Typography>
      </div>

      <Button onClick={onClickContinue} size="large" variant="contained">
        Продолжить*
      </Button>

      <GreetingFooter>
        <GreetingFooterDescription>
          <Typography variant="caption">
            <span>
              *Нажимая кнопку &quot;Продолжить&quot; Вы даете согласие на обработку файлов Cookies и других
              пользовательских данных в соответствии с{' '}
            </span>

            <Link target="_blank" to={policy}>
              Политикой обработки персональных данных
            </Link>

            <span>.</span>
          </Typography>
        </GreetingFooterDescription>

        <img alt="emis logo" height="30" src={g1logo} width="150" />
      </GreetingFooter>
    </GreetingRoot>
  );
}

export default memo(Greeting);
