import { alpha } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import common from '@mui/material/colors/common';
import styled from '@mui/material/styles/styled';
import Table from '@mui/material/Table';

export const PersonsContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const PersonsTableWrapper = styled('div')`
  overflow: auto;
  flex-grow: 1;
`;

export const PersonsTable = styled(Table)`
  table-layout: fixed;
`;

export const PersonsPagination = styled('div')`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PersonsPaginationSelected = styled('div')`
  margin-right: auto;
`;

export const PersonsSortButton = styled(Button)<ButtonProps & { component: string }>`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  text-transform: none;
  color: ${alpha(common.black, 0.8)};
  padding: 0;
  min-width: auto;
`;
