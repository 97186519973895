import { DeviceInfo, DeviceInfoModel } from '@/entities/deviceInfo';
import { Op, OpModel } from '@/entities/op';
import { User, UserModel } from '@/entities/user';

export class DeviceModel {
  id!: number;
  name!: string;
  model!: string;
  created_at!: string;
  is_default!: boolean;
  is_connected!: boolean;
  is_operating!: boolean;
  info!: DeviceInfoModel;
  ops!: OpModel[];
  users!: UserModel[];
}

export class Device extends DeviceModel {
  users: User[] = [];

  constructor(device: DeviceModel) {
    super();

    Object.keys(device).forEach((key) => {
      switch (key) {
        case 'users':
          if (Array.isArray(device.users)) {
            this.users = device.users.map((user) => new User(user));
          }

          break;
        case 'ops':
          if (Array.isArray(device.ops)) {
            this.ops = device.ops.map((op) => new Op(op));
          }

          break;
        case 'info':
          if (device.info) {
            this.info = new DeviceInfo(device.info);
          }

          break;
        default:
          this[key] = device[key];

          break;
      }
    });
  }

  get isActive() {
    return Boolean(this.info?.active);
  }

  get license() {
    return this.info?.license;
  }

  get serialNumber() {
    return this.license?.serial_number;
  }

  get serialCode() {
    return this.license?.serial_code;
  }

  get companyId() {
    return this.license?.company_id;
  }

  get version() {
    return this.info?.version;
  }

  get dataBaseVersion() {
    return this.info?.db_version;
  }

  get appVersion() {
    return this.info?.app_version;
  }
}
