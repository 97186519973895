import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

interface UseScrollResult {
  scrollToAnchor: (id: string) => void;
}

export function useScroll(): UseScrollResult {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const scrollToAnchor = useCallback((id: string) => {
    // Ждем пока отрендерится экран  и только потом ищем ноду с айди
    setTimeout(() => {
      const targetNode = document.getElementById(id);

      if (targetNode) {
        targetNode?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });

        navigate(`${pathname}${search}`);
      }
    }, 200);
  }, []);

  return {
    scrollToAnchor,
  };
}
