import styled from '@mui/material/styles/styled';

export const FileFieldRoot = styled('div')`
  display: inline-flex;
  overflow: hidden;
  position: relative;

  & input {
    position: absolute;
    top: -1000px;
    left: -1000px;
    opacity: 0;
  },
`;

export const FileFieldLabel = styled('label')`
  display: inline-flex;
  align-items: center;
  width: 100%;
`;
