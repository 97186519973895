import { CSSProperties } from 'react';

export const PRINT_STYLES: Record<string, CSSProperties> = {
  page: {
    // padding: '12%'
  },
  pageContent: {
    margin: '0',
    paddingLeft: '10px',
    fontSize: 11,
    overflow: 'hidden',
    lineHeight: '14px',
  },
  title1: {
    fontSize: 12,
    margin: '0 0 3px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
  },
  borderField: {
    display: 'inline-block',
    borderBottom: '1px solid black',
    padding: '0 2px',
    height: 16,
    lineHeight: '16px',
    minWidth: 10,
  },
};

export const DOCS_NAME_MAP = new Map([
  [4, 'Паспорт РФ'],
  [8, 'Загранпаспорт'],
  [3, 'Загранпаспорт'],
  [7, 'Загранпаспорт'],
  [1, 'Св-во о рождении'],
  [6, 'Св-во о рождении'],
  [39, 'Временное уд. личности'],
]);
