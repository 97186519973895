import React, { Fragment, memo } from 'react';
import { observer } from 'mobx-react-lite';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { notificationAPI } from '@/api/notificationAPI';
import { useMount } from '@/hooks/useMount/useMount';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import RoleTableRow from './RoleTableRow/RoleTableRow';

interface RolesTableProps {
  isAdmin?: boolean;
}

function RolesTable({ isAdmin = false }: RolesTableProps) {
  const { isFetching, roles, getRoles } = useStore('rolesStore');

  async function fetchRoles() {
    try {
      await getRoles();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useMount(fetchRoles);

  return (
    <Fragment>
      <BackdropLoader isLoading={isFetching} />

      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell width="20%">Название</TableCell>

            <TableCell align="center">Сканирование</TableCell>

            <TableCell align="center">Создание</TableCell>

            <TableCell align="center">Редактирование</TableCell>

            <TableCell align="center">Удаление</TableCell>

            <TableCell align="center">Управление группами</TableCell>

            {isAdmin && <TableCell />}
          </TableRow>
        </TableHead>

        <TableBody>
          {roles.map((role) => (
            <RoleTableRow key={role.id} onRoleDelete={getRoles} readonly={!isAdmin} role={role} />
          ))}
        </TableBody>
      </Table>
    </Fragment>
  );
}

export default memo(observer(RolesTable));
