export class DictionaryBasicModel {
  id!: number;
  name_ru!: string;
  name_en!: string;
}

export class DictionaryBasicWithCodeModel extends DictionaryBasicModel {
  code!: StringOrNumber;
}

export class DocVisaVisitTypeModel extends DictionaryBasicModel {
  doc_visa_type_id!: number;
}

export class DocTypeModel extends DictionaryBasicModel {
  category!: number;
}

export class DocIssueAuthorityModel extends DictionaryBasicModel {
  code!: string;
}

export class DictionaryBasic extends DictionaryBasicModel {
  constructor(model: DictionaryBasicModel) {
    super();

    Object.keys(model).forEach((key) => {
      this[key] = model[key];
    });
  }
}

export class DictionaryBasicWithCode extends DictionaryBasicWithCodeModel {
  constructor(model: DictionaryBasicModel) {
    super();

    Object.keys(model).forEach((key) => {
      this[key] = model[key];
    });
  }
}

export class DocVisaVisitType extends DocVisaVisitTypeModel {
  constructor(model: DocVisaVisitTypeModel) {
    super();

    Object.keys(model).forEach((key) => {
      this[key] = model[key];
    });
  }
}

export class DocType extends DocTypeModel {
  constructor(model: DocTypeModel) {
    super();

    Object.keys(model).forEach((key) => {
      this[key] = model[key];
    });
  }
}

export class DocIssueAuthority extends DocIssueAuthorityModel {
  constructor(model: DocIssueAuthorityModel) {
    super();

    Object.keys(model).forEach((key) => {
      this[key] = model[key];
    });
  }
}
