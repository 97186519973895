export * from './date';
export * from './dictionaries';
export * from './routes';

export const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const NEW_PERSON_ID = 'new';
export const INTEGRATION_DATA_KEY = 'integrationData';
export const EMIS_COMPANY_ID = 1;

export enum StateCode {
  Unauthorized = 401,
}

export const DOC_ISSUE_SHORT_CODE_LENGTH = 6;
export const DOC_ISSUE_CODE_LENGTH = 7;

export const FIRST_NAME_IS_EMPTY_LABEL = 'noname';
export const LAST_NAME_IS_EMPTY_LABEL = 'nolname';

export const DEFAULT_PAGINATION_LIMIT = 10;

export const INACTIVITY_STORAGE_KEY = 'inactivity-time-mark';

export const GROUPS_STORAGE_KEY = 'groups';

export const AUTH_TOKEN_KEY = 'token';
export const AUTH_ETOKEN_KEY = 'etoken';
export const AUTH_DATA_KEY = 'authData';

export const CONTENT_TYPE_MAP = {
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};
