import { Face } from '@/entities/face';

export const DEFAULT_EMPTY_COMPANY_FACE: Face = {
  face_regflat: '',
  face_reghouse1: '',
  face_reghouse2: '',
  face_reghouse3: '',
  face_regplace1: '',
  face_regplace2: '',
  face_regplace3: '',
  face_regstreet: '',
  face_fname: '',
  face_mname: '',
  face_lname: '',
  face_birth_date: null,
  face_birth_place: '',
  face_doc_serial: '',
  face_doc_number: '',
  face_doc_issue_date: null,
  face_doc_issue_code: '',
  face_doc_issue_authority: '',
  face_doc_issue_authority_organ_id: 4,
  face_doc_valid_date: null,
  face_position: '',
  face_pow_type: '',
  face_pow_serial: '',
  face_pow_number: '',
  face_pow_issue_date: null,
  face_pow_valid_date: null,
};
