import React, { Fragment, memo, SyntheticEvent, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { Role } from '@/entities/role';
import { useToggle } from '@/hooks/useToggle/useToggle';
import RoleFormModal from '@/shared/RoleFormModal/RoleFormModal';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';

interface RoleTableRowProps {
  role: Role;
  onRoleDelete: () => void;
  readonly?: boolean;
}

function RoleTableRow(props: RoleTableRowProps) {
  const { role, onRoleDelete, readonly = false } = props;
  const { deleteRole, isSaving } = useStore('rolesStore');

  const [isEditRole, , , toggleEditRole] = useToggle();
  const [isDeleteRoleModalOpen, openDeleteRoleModal, closeDeleteRoleModal] = useToggle();

  function renderPermissionIcon(checked: boolean) {
    return (
      <Tooltip title={checked ? 'Доступно' : 'Не доступно'}>
        <CheckCircleIcon fontSize="small" htmlColor={checked ? green['700'] : red['500']} />
      </Tooltip>
    );
  }

  async function onDeleteHandler() {
    try {
      await deleteRole(role.id);

      notificationAPI.success('Роль удалена');

      onRoleDelete();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onToggleRoleModalHandler = useCallback(() => {
    if (!readonly) {
      toggleEditRole();
    }
  }, [readonly]);

  const onOpenDeleteRoleModal = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    openDeleteRoleModal();
  }, []);

  return (
    <Fragment>
      <TableRow hover={!readonly} onClick={onToggleRoleModalHandler}>
        <TableCell>{role.name}</TableCell>

        <TableCell align="center">{renderPermissionIcon(role.allow_scan)}</TableCell>

        <TableCell align="center">{renderPermissionIcon(role.allow_create)}</TableCell>

        <TableCell align="center">{renderPermissionIcon(role.allow_edit)}</TableCell>

        <TableCell align="center">{renderPermissionIcon(role.allow_delete)}</TableCell>

        <TableCell align="center">{renderPermissionIcon(role.allow_manage_groups)}</TableCell>

        {!readonly && (
          <TableCell align="right">
            <Tooltip title="Удалить роль">
              <IconButton onClick={onOpenDeleteRoleModal}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>

      <RoleFormModal onClose={onToggleRoleModalHandler} open={isEditRole} role={role} type="edit" />

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={
          <Typography variant="body1">Вы действительно хотите удалить роль &quot;{role.name}&quot;?</Typography>
        }
        isLoading={isSaving}
        onClose={closeDeleteRoleModal}
        onConfirm={onDeleteHandler}
        onDecline={closeDeleteRoleModal}
        open={isDeleteRoleModalOpen}
        title="Удалить роль"
      />
    </Fragment>
  );
}

export default memo(observer(RoleTableRow));
