import React, { ChangeEvent, memo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { Address } from '@/entities/address';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField } from '@/ui-kit/components';

import { DEFAULT_SEARCH_TIMEOUT } from '../AddressFormOld.constant';

interface SettlementControlProps {
  region: Nullable<Address>;
  place: Nullable<Address>;
  settlement: Nullable<Address>;
  error?: string;
  onChange: (name: string, settlement: Nullable<Address>) => void;
  disabled?: boolean;
  readonly?: boolean;
  variant?: TextFieldProps['variant'];
  margin?: TextFieldProps['margin'];
}

let requestTimeout: NodeJS.Timeout | undefined;

function SettlementControl(props: SettlementControlProps) {
  const { region, place, settlement, error, onChange, disabled = false, readonly = false, variant, margin } = props;
  const { settlements, getSettlements } = useStore('dictionariesStore');

  const onChangeRequest = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      if (currentTarget.value.length > 2) {
        requestTimeout && clearTimeout(requestTimeout);
        requestTimeout = setTimeout(() => {
          getSettlements(currentTarget.value, region ? region.region_code : null, place ? place.id : null);
        }, DEFAULT_SEARCH_TIMEOUT);
      }
    },
    [region?.region_code, place?.id],
  );

  const onSettlementChangeHandler = useCallback(
    (settlement: unknown) => {
      onChange('settlement', settlement as Address);
    },
    [onChange],
  );

  const renderOption = useCallback(
    ({ short_name, name, full_name }: Address) => full_name || `${short_name}. ${name}`,
    [],
  );

  const renderFilterOption = useCallback(
    (options: Address[], { inputValue }: FilterOptionsState<Address>) =>
      options.filter(({ name }: Address) => name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
    [],
  );

  return (
    <AutocompleteField
      disabled={disabled || readonly}
      filterOptions={renderFilterOption}
      getOptionLabel={renderOption}
      helperText={error}
      isError={!!error}
      label="Населенный пункт"
      margin={margin}
      name="settlement"
      onChange={onSettlementChangeHandler}
      onInputChange={onChangeRequest}
      options={[...settlements]}
      value={settlement}
      variant={variant}
    />
  );
}

export default memo(observer(SettlementControl));
