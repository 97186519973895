import React, { memo, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import logo from '@/assets/big-logo.png';
import { ROUTE_MAIN, ROUTE_SIGNIN } from '@/constants';
import { useMount } from '@/hooks/useMount/useMount';
import { PasswordRecoveryForm, UserData } from '@/stores/SignInStore';
import { useStore } from '@/stores/StoreProvider';
import { InputField } from '@/ui-kit/components';

import {
  PasswordRecoveryActions,
  PasswordRecoveryContent,
  PasswordRecoveryError,
  PasswordRecoveryLogo,
  PasswordRecoveryRoot,
} from './PasswordRecovery.style';

function PasswordRecovery() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { isSignedIn, isFetching, recoverPassword, getUserByToken } = useStore('signInStore');

  const [user, setUser] = useState<Nullable<UserData>>(null);

  const recoverForm = useFormik<PasswordRecoveryForm>({
    validateOnMount: false,
    initialValues: {
      password: '',
      passwordRepeat: '',
    },
    onSubmit: async (values) => {
      try {
        if (user) {
          await recoverPassword(user.id, { password: values.password });

          navigate(ROUTE_SIGNIN);
        }
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    validate: ({ password, passwordRepeat }) => {
      const errors: Partial<Record<keyof PasswordRecoveryForm, string>> = {};

      if (password.length === 0) {
        errors.password = 'Обязательно к заполнению';
      }

      if (passwordRepeat.length === 0) {
        errors.passwordRepeat = 'Обязательно к заполнению';
      }

      if (passwordRepeat !== password) {
        errors.passwordRepeat = 'Пароли не совпадают';
      }

      return errors;
    },
  });

  async function init() {
    try {
      if (token.length > 0) {
        const data = await getUserByToken({ token });

        setUser(data as UserData);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useMount(init);

  if (isSignedIn) {
    return <Navigate to={{ pathname: ROUTE_MAIN }} />;
  }

  let token = '';

  if (search.split('=')[1]) {
    token = search.split('=')[1];
  }

  const passwordValidity = ((): Record<string, boolean> => {
    const valid: Record<string, boolean> = {};

    if (!/[0-9]/.test(recoverForm.values.password)) {
      valid.hasNumber = true;
    }

    if (!/[a-z]/.test(recoverForm.values.password)) {
      valid.hasLower = true;
    }

    if (!/[A-Z]/.test(recoverForm.values.password)) {
      valid.hasUpper = true;
    }

    if (/\s/.test(recoverForm.values.password)) {
      valid.noSpace = true;
    }

    if (recoverForm.values.password.length < 8) {
      valid.minLength = true;
    }

    return valid;
  })();

  return (
    <PasswordRecoveryRoot>
      <PasswordRecoveryContent>
        <header>
          <PasswordRecoveryLogo>
            <img alt="emis logo" height="65" src={logo} width="210" />
          </PasswordRecoveryLogo>
        </header>

        {token.length === 0 && (
          <PasswordRecoveryError>Токен не был предоставлен! Невозможно сменить пароль!</PasswordRecoveryError>
        )}

        {!user && !isFetching && (
          <PasswordRecoveryError>Не удалось загрузить данные пользователя</PasswordRecoveryError>
        )}

        <form onSubmit={recoverForm.handleSubmit}>
          <InputField
            autoFocus
            disabled={isFetching || token.length === 0}
            error={recoverForm.values.password?.length > 0 && Object.keys(passwordValidity).length > 0}
            helperText={recoverForm.errors.password}
            label="Пароль"
            margin="normal"
            name="password"
            onChange={recoverForm.handleChange}
            type="password"
            value={recoverForm.values.password}
          />

          <InputField
            disabled={isFetching || token.length === 0}
            error={!!recoverForm.errors.passwordRepeat}
            helperText={recoverForm.errors.passwordRepeat}
            label="Повторите пароль"
            margin="normal"
            name="passwordRepeat"
            onChange={recoverForm.handleChange}
            type="password"
            value={recoverForm.values.passwordRepeat}
          />

          <div>
            <div>
              <Checkbox checked={!passwordValidity.hasNumber} color="primary" readOnly size="small" />

              <span> - цифра</span>
            </div>

            <div>
              <Checkbox checked={!passwordValidity.hasLower} color="primary" readOnly size="small" />

              <span> - маленькая буква</span>
            </div>

            <div>
              <Checkbox checked={!passwordValidity.hasUpper} color="primary" readOnly size="small" />

              <span> - большая буква</span>
            </div>

            <div>
              <Checkbox checked={!passwordValidity.noSpace} color="primary" readOnly size="small" />

              <span> - без пробелов</span>
            </div>

            <div>
              <Checkbox checked={!passwordValidity.minLength} color="primary" disableRipple readOnly size="small" />

              <span> - минимум 8 символов</span>
            </div>
          </div>

          <PasswordRecoveryActions>
            <Link to={ROUTE_SIGNIN}>Авторизоваться</Link>

            <Button
              color="primary"
              disabled={isFetching || Object.keys(passwordValidity).length > 0 || token.length === 0 || !user}
              type="submit"
              variant="contained"
            >
              Восстановить
            </Button>
          </PasswordRecoveryActions>
        </form>

        <Typography variant="caption">© {new Date().getFullYear()} G1 SOFTWARE.</Typography>
      </PasswordRecoveryContent>
    </PasswordRecoveryRoot>
  );
}

export default memo(observer(PasswordRecovery));
