import React from 'react';
import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions/DialogActions';

function ContentModalFooter(props: DialogActionsProps) {
  const { children, ...rest } = props;

  return <DialogActions {...rest}>{children}</DialogActions>;
}

export default ContentModalFooter;
