import React, { ChangeEvent, Fragment, memo, useEffect, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { DocumentType } from '@/constants';
import { Address, Street } from '@/entities/address';
import AddressForm from '@/routes/SelfCheckIn/AddressForm/AddressForm';
import { DEFAULT_EMPTY_PERSON, DEFAULT_ID_CARD, PersonDetail, PersonDocument } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import BasicInfo from './BasicInfo/BasicInfo';
import Citizenship from './Citizenship/Citizenship';
import DocImages from './DocImages/DocImages';
import DocInfo from './DocInfo/DocInfo';
import Finish from './Finish/Finish';
import Greeting from './Greeting/Greeting';
import { CheckinStep } from './SelfCheckIn.constants';
import {
  SelfCheckInControl,
  SelfCheckInNext,
  SelfCheckInRoot,
  SelfCheckInSteps,
  SelfCheckInStepsAction,
} from './SelfCheckIn.style';
import { PassportKeysType } from './SelfCheckIn.types';

function SelfCheckIn() {
  const { updatePerson, getPersonById, getDocumentsById } = useStore('personsStore');

  const query = queryAPI.params;

  const [step, setStep] = useState<CheckinStep>(CheckinStep.Greeting);
  const [passportPage1, setPassportPage1] = useState<Nullable<string>>(null);
  const [passportPage2, setPassportPage2] = useState<Nullable<string>>(null);
  const [person, setPerson] = useState<Nullable<PersonDetail>>(null);
  const [idCard, setIdCard] = useState<Nullable<PersonDocument>>(null);

  async function getPerson() {
    try {
      if (query.personId) {
        const currentPerson = await getPersonById(+query.personId);
        const currentPersonDocuments = await getDocumentsById(+query.personId);

        setPerson({
          ...DEFAULT_EMPTY_PERSON,
          ...currentPerson,
          documents: currentPersonDocuments ?? [],
        });

        const currentIdCardIndex = currentPersonDocuments.findIndex(
          ({ document_type_enum }: PersonDocument) => document_type_enum.category === DocumentType.IdCard,
        );

        setIdCard({
          ...DEFAULT_ID_CARD,
          ...(currentPersonDocuments[currentIdCardIndex] ?? {}),
        });
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function updatePersonValues() {
    try {
      if (person && idCard && query.personId) {
        const personData = person;

        const currentIdDocumentIndex = personData.documents.findIndex(
          ({ document_type_enum }) => document_type_enum.category === DocumentType.IdCard,
        );

        const images = idCard.images ?? [];

        if (passportPage1) {
          images.push({
            data: passportPage1,
            image_upload_type: 3,
            not_export: false,
          });
        }

        if (passportPage2) {
          images.push({
            data: passportPage2,
            image_upload_type: 3,
            not_export: false,
          });
        }

        const newIdCard = {
          ...idCard,
          images,
        };

        if (currentIdDocumentIndex !== -1) {
          personData.documents[currentIdDocumentIndex] = newIdCard;
        } else {
          personData.documents = [newIdCard];
        }

        await updatePerson(+query.personId, personData);

        setStep(CheckinStep.Finish);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function onSelectCitizenship({ target }: ChangeEvent<HTMLInputElement>) {
    setPerson((prevValues: Nullable<PersonDetail>) => ({
      ...(prevValues as PersonDetail),
      citizenship: target.value ? parseInt(target.value) : null,
    }));
  }

  function onUploadPassport(key: PassportKeysType, value: Nullable<string>) {
    if (key === 'passportPage1') {
      setPassportPage1(value);
    }

    if (key === 'passportPage2') {
      setPassportPage2(value);
    }
  }

  function onInputChange({ target }: ChangeEvent<HTMLInputElement>) {
    const { name, value } = target;

    setPerson((prevValues: Nullable<PersonDetail>) => ({
      ...(prevValues as PersonDetail),
      [name]: value,
    }));
  }

  function onChangeRegistrationAddress(
    field: string,
    value: Nullable<Address | Street | string>,
    isStreetEmpty?: boolean,
  ) {
    if (field === 'street') {
      setPerson((prevValues: Nullable<PersonDetail>) => ({
        ...(prevValues as PersonDetail),
        reg_info: {
          ...(prevValues as PersonDetail).reg_info,
          empty_street: !!isStreetEmpty,
          fias_code: (value as Street).ao_id,
          fias_code2: (value as Street).ao_guid,
          region_code: (value as Street).region_code,
        },
      }));
    } else {
      setPerson((prevValues: Nullable<PersonDetail>) => ({
        ...(prevValues as PersonDetail),
        reg_info: {
          ...(prevValues as PersonDetail).reg_info,
          empty_street: !!isStreetEmpty,
          [field]: value,
        },
      }));
    }
  }

  function onPrevStep() {
    setStep((prevStep: CheckinStep) => {
      switch (prevStep) {
        case CheckinStep.DocImages:
          return CheckinStep.Citizenship;
        case CheckinStep.BasicInfo:
          return CheckinStep.DocImages;
        case CheckinStep.DocInfo:
          return CheckinStep.BasicInfo;
        case CheckinStep.Address:
          return CheckinStep.DocInfo;
        default:
          return CheckinStep.Greeting;
      }
    });
  }

  function onNextStep() {
    if (step === CheckinStep.Address) {
      updatePersonValues();

      return;
    }

    setStep((prevStep: CheckinStep) => {
      switch (prevStep) {
        case CheckinStep.Citizenship:
          return CheckinStep.DocImages;
        case CheckinStep.DocImages:
          return CheckinStep.BasicInfo;
        case CheckinStep.BasicInfo:
          return CheckinStep.DocInfo;
        case CheckinStep.DocInfo:
          return CheckinStep.Address;
        case CheckinStep.Address:
          return CheckinStep.Finish;
        default:
          return CheckinStep.Greeting;
      }
    });
  }

  function detectDisabledNextButton(): boolean {
    if (step === CheckinStep.Citizenship) {
      return !person?.citizenship;
    }

    if (step === CheckinStep.DocImages) {
      return !(passportPage1 && passportPage2);
    }

    if (step === CheckinStep.BasicInfo) {
      return !(person?.lname && person.fname && person.birth_date && person.gender);
    }

    return false;
  }

  useEffect(() => {
    if (query.token) {
      localStorage.setItem('token', query.token);
    }

    if (query.personId) {
      getPerson();
    }
  }, []);

  if (!query.token || !query.personId) {
    return <p>Ошибка получения токена. Обратитесь к администратору отеля.</p>;
  }

  return (
    <SelfCheckInRoot>
      <BackdropLoader isLoading={!person || !idCard} />

      {person && idCard && (
        <Fragment>
          {step === CheckinStep.Greeting && <Greeting onNextStep={setStep} />}

          {step === CheckinStep.Citizenship && (
            <Citizenship citizenship={person.citizenship} onSelectCitizenship={onSelectCitizenship} />
          )}

          {step === CheckinStep.DocImages && (
            <DocImages onUpload={onUploadPassport} passportPage1={passportPage1} passportPage2={passportPage2} />
          )}

          {step === CheckinStep.BasicInfo && (
            <BasicInfo
              birthDate={person.birth_date}
              fname={person.fname}
              gender={person.gender}
              lname={person.lname}
              mname={person.mname}
              onChange={onInputChange}
            />
          )}

          {step === CheckinStep.DocInfo && (
            <DocInfo citizenship={person.citizenship} idCard={idCard} setIdCardValues={setIdCard} />
          )}

          {step === CheckinStep.Address && (
            <SelfCheckInControl>
              <AddressForm
                fiasCode={person.reg_info.fias_code2}
                flat={person.reg_info.flat}
                housing={person.reg_info.housing}
                housing2={person.reg_info.housing2}
                isStreetEmpty={person.reg_info.empty_street}
                onChange={onChangeRegistrationAddress}
                regionCode={person.reg_info.region_code}
              />
            </SelfCheckInControl>
          )}

          {step === CheckinStep.Finish && <Finish />}

          {step !== CheckinStep.Greeting && (
            <SelfCheckInSteps>
              <Stepper activeStep={step}>
                <Step active={step === CheckinStep.Citizenship} completed={step > CheckinStep.Citizenship}>
                  <StepLabel />
                </Step>

                <Step active={step === CheckinStep.DocImages} completed={step > CheckinStep.DocImages}>
                  <StepLabel />
                </Step>

                <Step active={step === CheckinStep.BasicInfo} completed={step > CheckinStep.BasicInfo}>
                  <StepLabel />
                </Step>

                <Step active={step === CheckinStep.DocInfo} completed={step > CheckinStep.DocInfo}>
                  <StepLabel />
                </Step>

                <Step active={step === CheckinStep.Address} completed={step > CheckinStep.Address}>
                  <StepLabel />
                </Step>
              </Stepper>

              {step !== CheckinStep.Finish && (
                <SelfCheckInStepsAction>
                  <Button
                    disabled={step === CheckinStep.Citizenship}
                    onClick={onPrevStep}
                    startIcon={<ArrowBackIcon />}
                  >
                    Назад
                  </Button>

                  <SelfCheckInNext>
                    <Button
                      disabled={detectDisabledNextButton()}
                      endIcon={<ArrowForwardIcon />}
                      onClick={onNextStep}
                      variant="contained"
                    >
                      Далее
                    </Button>
                  </SelfCheckInNext>
                </SelfCheckInStepsAction>
              )}
            </SelfCheckInSteps>
          )}
        </Fragment>
      )}
    </SelfCheckInRoot>
  );
}

export default memo(SelfCheckIn);
