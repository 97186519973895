import React, { memo, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { observer } from 'mobx-react-lite';
import Alert from '@mui/material/Alert/Alert';

import { notificationAPI } from '@/api/notificationAPI';
import {
  ROUTE_BOOKINGS,
  ROUTE_BREAKFAST,
  ROUTE_COMPANY,
  ROUTE_COMPANY_DATA,
  ROUTE_COMPANY_DEVICES,
  ROUTE_COMPANY_GENERAL,
  ROUTE_COMPANY_SETTINGS,
  ROUTE_COMPANY_USERS,
  ROUTE_HMS_PERSON,
  ROUTE_KS_LIST,
  ROUTE_KS_REPORT,
  ROUTE_MAIN,
  ROUTE_PERSON,
  ROUTE_PROFILE,
  ROUTE_REGISTRATIONS,
  ROUTE_REMOVED,
  ROUTE_USER_DATA,
  ROUTE_VERIFICATION,
} from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import AboutCompany from '@/routes/Main/OperatorPanel/Profile/CompanyData/AboutCompany/AboutCompany';
import CompanyData from '@/routes/Main/OperatorPanel/Profile/CompanyData/CompanyData';
import CompanyUsers from '@/routes/Main/OperatorPanel/Profile/CompanyData/CompanyUsers/CompanyUsers';
import UserData from '@/routes/Main/OperatorPanel/Profile/UserData/UserData';
import Devices from '@/shared/Devices/Devices';
import RetentionData from '@/shared/RetentionData/RetentionData';
import Settings from '@/shared/Settings/Settings';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import Bookings from './Bookings/Bookings';
import Breakfast from './Breakfast/Breakfast';
import HotelReport from './HotelReport/HotelReport';
import KSList from './KSList/KSList';
import { OperatorPanelRoot, OperatorPanelRoutes } from './OperatorPanel.style';
import PersonRouteWrapper from './PersonPage/PersonRouteWrapper';
import Profile from './Profile/Profile';
import Registration from './Registration/Registration';
import Removed from './Removed/Removed';
import Verification from './Verification/Verification';

function OperatorPanel() {
  const {
    countries,
    getCountries,
    personCategories,
    getPersonCategories,
    docTypes,
    getDocTypes,
    entryGates,
    getEntryGates,
    visitGoals,
    getVisitGoals,
    docVisaTypes,
    getDocVisaTypes,
    docVisaVisitTypes,
    getDocVisaVisitTypes,
    docVisaMultiTypes,
    getDocVisaMultiTypes,
    ksReasons,
    getKSReasons,
  } = useStore('dictionariesStore');
  const { isFetching, getUserCompany, userCompany } = useStore('signInStore');

  const [isLoading, enableLoading, disableLoading] = useToggle(true);

  async function fetchInitData() {
    enableLoading();

    try {
      await getUserCompany();

      if (countries.length === 0) {
        await getCountries();
      }

      if (personCategories.length === 0) {
        await getPersonCategories();
      }

      if (docTypes.length === 0) {
        await getDocTypes();
      }

      if (entryGates.length === 0) {
        await getEntryGates();
      }

      if (visitGoals.length === 0) {
        await getVisitGoals();
      }

      if (docVisaTypes.length === 0) {
        await getDocVisaTypes();
      }

      if (docVisaVisitTypes.length === 0) {
        await getDocVisaVisitTypes();
      }

      if (docVisaMultiTypes.length === 0) {
        await getDocVisaMultiTypes();
      }

      if (ksReasons.length === 0) {
        await getKSReasons();
      }

      disableLoading();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    fetchInitData();
  }, []);

  if (isLoading || isFetching) {
    return <BackdropLoader isLoading />;
  }

  return (
    <OperatorPanelRoot>
      {userCompany?.company?.notice && <Alert severity="warning">{userCompany.company.notice}</Alert>}

      <OperatorPanelRoutes>
        <Routes>
          <Route element={<Profile />} path={ROUTE_PROFILE}>
            <Route element={<UserData />} index />

            <Route element={<UserData />} path={ROUTE_USER_DATA} />

            {userCompany?.company.id && (
              <Route element={<CompanyData />} path={ROUTE_COMPANY}>
                <Route element={<AboutCompany companyId={userCompany.company.id} />} index />

                <Route element={<AboutCompany companyId={userCompany.company.id} />} path={ROUTE_COMPANY_GENERAL} />

                <Route element={<Devices companyId={userCompany.company.id} />} path={ROUTE_COMPANY_DEVICES} />

                <Route element={<CompanyUsers companyId={userCompany.company.id} />} path={ROUTE_COMPANY_USERS} />

                <Route element={<Settings companyId={userCompany.company.id} />} path={ROUTE_COMPANY_SETTINGS} />

                <Route element={<RetentionData companyId={userCompany.company.id} />} path={ROUTE_COMPANY_DATA} />
              </Route>
            )}
          </Route>

          <Route element={<Verification />} path={ROUTE_VERIFICATION} />

          <Route element={<Registration />} path={ROUTE_REGISTRATIONS} />

          <Route element={<Bookings />} path={ROUTE_BOOKINGS} />

          <Route element={<KSList />} path={ROUTE_KS_LIST} />

          <Route element={<Registration />} path={`${ROUTE_REGISTRATIONS}/:groupId`} />

          <Route element={<Removed />} path={ROUTE_REMOVED} />

          <Route element={<Removed />} path={`${ROUTE_REMOVED}/:groupId`} />

          <Route element={<PersonRouteWrapper />} path={`${ROUTE_HMS_PERSON}/:id`} />

          <Route element={<PersonRouteWrapper />} path={`${ROUTE_PERSON}/:id`} />

          <Route element={<PersonRouteWrapper />} path={`${ROUTE_BOOKINGS}/:id`} />

          <Route element={<PersonRouteWrapper />} path={`${ROUTE_PERSON}/:id/:groupId`} />

          <Route element={<HotelReport />} path={ROUTE_KS_REPORT} />

          <Route element={<Registration />} path={ROUTE_MAIN} />

          <Route element={<Breakfast />} path={ROUTE_BREAKFAST} />

          <Route element={<Navigate to="/" />} path="*" />
        </Routes>
      </OperatorPanelRoutes>
    </OperatorPanelRoot>
  );
}

export default memo(observer(OperatorPanel));
