import React, { memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { GroupFormImageRemove, GroupFormImageRoot } from './GroupFormImage.styles';

interface GroupFormImageProps {
  image: string;
  index: number;
  onDelete: (index: number) => void;
}

function GroupFormImage(props: GroupFormImageProps) {
  const { image, index, onDelete } = props;

  function onDeleteHandler() {
    onDelete(index);
  }

  return (
    <GroupFormImageRoot>
      <img alt="" src={`data:image/jpeg;base64,${image}`} width="300" />

      <GroupFormImageRemove onClick={onDeleteHandler} size="small">
        <CloseIcon fontSize="small" />
      </GroupFormImageRemove>
    </GroupFormImageRoot>
  );
}

export default memo(GroupFormImage);
