import React, { memo } from 'react';
import { format } from 'date-fns';
import Grid from '@mui/material/Grid';

import { DATE_HUMAN_FORMAT } from '@/constants';
import { Person } from '@/stores/PersonsStore';
import { getPersonName } from '@/utils/getPersonName';

import { LegalRepresentativeRawRoot } from './LegalRepresentativeRaw.style';

interface LegalRepresentativeRawProps {
  person: Person;
  onSelect: (person: Person) => void;
  selected: boolean;
}

function LegalRepresentativeRaw(props: LegalRepresentativeRawProps) {
  const { person, onSelect, selected } = props;

  function onClickHandler() {
    onSelect(person);
  }

  return (
    <LegalRepresentativeRawRoot container isSelected={selected} onClick={onClickHandler}>
      <Grid item xs={6}>
        {getPersonName(person)}
      </Grid>

      <Grid item xs={6}>
        Дата создания: {format(new Date(person.created_at), DATE_HUMAN_FORMAT)}
      </Grid>
    </LegalRepresentativeRawRoot>
  );
}

export default memo(LegalRepresentativeRaw);
