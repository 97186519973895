import React, { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { observer } from 'mobx-react-lite';

import { notificationAPI } from '@/api/notificationAPI';
import { ROUTE_ALL_DEVICES, ROUTE_COMPANIES, ROUTE_MAIN, ROUTE_REPORTS, ROUTE_ROLES } from '@/constants';
import { useMount } from '@/hooks/useMount/useMount';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import AllDevices from './AllDevices/AllDevices';
import Companies from './Companies/Companies';
import CompanyPage from './CompanyPage/CompanyPage';
import Reports from './Reports/Reports';
import Roles from './Roles/Roles';

function AdminPanel() {
  const { exportTypes, getExportTypes, countries, getCountries, hmsList, getHMSList } = useStore('dictionariesStore');
  const { roles, getRoles } = useStore('rolesStore');
  const { isFetching } = useStore('signInStore');

  const [isLoading, enableLoading, disableLoading] = useToggle(true);

  async function fetchInitData() {
    try {
      enableLoading();

      const requests = [];

      if (exportTypes.length === 0) {
        requests.push(getExportTypes());
      }

      if (countries.length === 0) {
        requests.push(getCountries());
      }

      if (roles.length === 0) {
        requests.push(getRoles());
      }

      if (hmsList.length === 0) {
        requests.push(getHMSList());
      }

      await Promise.all(requests);

      disableLoading();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useMount(fetchInitData);

  if (isLoading || isFetching) {
    return <BackdropLoader isLoading />;
  }

  return (
    <Routes>
      <Route element={<Roles />} path={ROUTE_ROLES} />

      <Route element={<Reports />} path={ROUTE_REPORTS} />

      <Route element={<AllDevices />} path={ROUTE_ALL_DEVICES} />

      <Route element={<Companies />} path={ROUTE_COMPANIES} />

      <Route element={<CompanyPage />} path={`${ROUTE_COMPANIES}/:id`} />

      <Route element={<Companies />} path={ROUTE_MAIN} />

      <Route element={<Navigate to="/" />} path="*" />
    </Routes>
  );
}

export default memo(observer(AdminPanel));
