import styled from '@mui/material/styles/styled';

export const CitizenshipRoot = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CitizenshipActions = styled('div')`
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CitizenshipButton = styled('div')`
  margin-bottom: 24px;
`;
