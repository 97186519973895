import React, { Fragment, memo, SyntheticEvent, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { IpAddress } from '@/entities/ipAddress';
import { useToggle } from '@/hooks/useToggle/useToggle';
import IpAddressFormModal from '@/shared/IpAddressFormModal/IpAddressFormModal';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';

interface IpAddressRowProps {
  ipAddress: IpAddress;
  companyId: number;
  onAfterChange: () => void;
}

function IpAddressRaw(props: IpAddressRowProps) {
  const { ipAddress, companyId, onAfterChange } = props;
  const { removeIpAddress, isSavingIpAddress } = useStore('companiesStore');

  const [isEditIpAddressOpen, , , toggleEditIpAddressModal] = useToggle();
  const [isDeleteIpAddressModalOpen, , , toggleDeleteIpAddressModal] = useToggle();

  async function onDeleteHandler() {
    try {
      if (ipAddress?.id) {
        await removeIpAddress(companyId, ipAddress.id);

        notificationAPI.success('IP-адрес удален');

        onAfterChange();
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onToggleDeleteIpAddressModal = useCallback((e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    toggleDeleteIpAddressModal();
  }, []);

  return (
    <Fragment>
      <TableRow hover onClick={toggleEditIpAddressModal}>
        <TableCell>{ipAddress.name}</TableCell>

        <TableCell>{ipAddress.ip}</TableCell>

        <TableCell align="center">
          <Tooltip title="Удалить IP-адрес">
            <IconButton onClick={onToggleDeleteIpAddressModal} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <IpAddressFormModal
        companyId={companyId}
        ipAddress={ipAddress}
        onAfterChange={onAfterChange}
        onClose={toggleEditIpAddressModal}
        open={isEditIpAddressOpen}
        type="edit"
      />

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={
          <Typography variant="body1">
            <span>Вы действительно хотите удалить IP-адрес </span>

            <span>&quot;{ipAddress.name}&quot; </span>

            <span>({ipAddress.ip})?</span>
          </Typography>
        }
        isLoading={isSavingIpAddress}
        onClose={toggleDeleteIpAddressModal}
        onConfirm={onDeleteHandler}
        onDecline={toggleDeleteIpAddressModal}
        open={isDeleteIpAddressModalOpen}
        title="Удалить IP-адрес"
      />
    </Fragment>
  );
}

export default memo(observer(IpAddressRaw));
