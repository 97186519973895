export class IpAddressModel {
  id!: number;
  name!: string;
  ip!: string;
}

export class IpAddress extends IpAddressModel {
  constructor(ipAddress: IpAddressModel) {
    super();

    Object.keys(ipAddress).forEach((key) => {
      this[key] = ipAddress[key];
    });
  }
}
