import React, { memo, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';

import { notificationAPI } from '@/api/notificationAPI';
import { Group } from '@/entities/group';
import GroupSelectorInput from '@/shared/Groups/GroupSelectorInput/GroupSelectorInput';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, InputField } from '@/ui-kit/components';
import { pureStructure } from '@/utils';

import { GroupFormType } from '../GroupItem.constants';

interface GroupFormModalProps extends ContentModalProps {
  type: GroupFormType;
  group: Group;
}

interface AddGroupForm {
  name: string;
  parent: Partial<Group>;
}

function GroupFormModal(props: GroupFormModalProps) {
  const { group, type, open, onClose } = props;
  const { isSaving, groups, addGroup, updateGroup } = useStore('groupsStore');

  const findParentGroup = useCallback((groups: Group[], id: number) => {
    // `!id` значит была выбрана группа по умолчанию, у нее `parent_id: 0`
    // поэтому принудительно ставим ей 1 - это айди группы по-умолчанию
    const groupId = !id ? 1 : id;
    let result: Partial<Group> = {};

    function searcher(groups: Group[], targetId: number) {
      groups.forEach((group: Group) => {
        if (group.id === targetId) {
          result = group;
        }

        if (group.has_child_groups) {
          searcher(group.children, targetId);
        }
      });
    }

    searcher(groups, groupId);

    return result;
  }, []);

  const onSelectGroup = useCallback((group: Group) => groupForm.setFieldValue('parent', group), []);

  const groupForm = useFormik<AddGroupForm>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    initialValues: {
      name: type === GroupFormType.Edit ? group.name : '',
      parent: findParentGroup(groups, type === GroupFormType.Edit ? group.parent_id : group.id),
    },
    onSubmit: async ({ name, parent }: AddGroupForm) => {
      const nextGroup = pureStructure(group);

      try {
        if (type === GroupFormType.Create && parent.id) {
          await addGroup(name, parent.id);
        }

        if (type === GroupFormType.Edit && nextGroup.id) {
          await updateGroup(nextGroup.id, nextGroup.parent_id, {
            name,
            parent_id: parent.id,
          });

          nextGroup.name = name;
          nextGroup.parent_id = parent.id as number;
        }

        notificationAPI.success(`Группа ${type === GroupFormType.Edit ? 'обновлена' : 'создана'}`);

        onClose();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    onReset: () => onClose(),
    validate: ({ name }: AddGroupForm) => {
      const errors: Partial<AddGroupForm> = {};

      if (name.length === 0) {
        errors.name = 'Обязательно к заполнению';
      }

      return errors;
    },
  });

  useEffect(() => {
    groupForm.setValues({
      name: type === GroupFormType.Edit ? group.name : '',
      parent: findParentGroup(groups, type === GroupFormType.Edit ? group.parent_id : group.id),
    });
  }, [group]);

  return (
    <ContentModal disableEscapeKeyDown={isSaving} maxWidth="xs" onClose={onClose} open={open}>
      <form onReset={groupForm.handleReset} onSubmit={groupForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSaving} onClose={onClose}>
          <span>{type === GroupFormType.Edit && 'Редактировать '}</span>

          <span>{type === GroupFormType.Create && 'Создать '}</span>

          <span>группу</span>
        </ContentModal.Header>

        <ContentModal.Body>
          <InputField
            autoFocus
            disabled={isSaving}
            error={!!groupForm.errors.name}
            helperText={groupForm.errors.name}
            label="Название"
            name="name"
            onChange={groupForm.handleChange}
            value={groupForm.values.name}
          />

          {groupForm.values.parent && (
            <GroupSelectorInput
              disabled={isSaving || type === GroupFormType.Create}
              label="Родительская группа"
              name="parent"
              onSelectGroup={onSelectGroup}
              value={groupForm.values.parent.name}
            />
          )}
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSaving} type="reset">
            Отмена
          </Button>

          <ButtonLoader
            disabled={
              !(
                groupForm.dirty &&
                groupForm.isValid &&
                (type === GroupFormType.Edit ? group.id !== groupForm.values.parent.id : true)
              )
            }
            isLoading={isSaving}
            type="submit"
          >
            {type === GroupFormType.Edit && 'Обновить'}

            {type === GroupFormType.Create && 'Создать'}
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(GroupFormModal));
