import { makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import { Device, DeviceModel } from '@/entities/device';
import { DeviceInfo } from '@/entities/deviceInfo';
import { UserDevice, UserDeviceModel } from '@/entities/userDevice';

export class DevicesStore {
  devices: Device[] = [];
  userDevices: UserDevice[] = [];
  cameras: MediaDeviceInfo[] = [];
  isFetching = false;
  isSaving = false;
  isUpdating = false;
  isAttaching = false;

  constructor() {
    makeAutoObservable(this);

    this.getDeviceCameras();

    /*
     * Подписываемся на изменения в подключенных камерах
     * Это нужно на случай, если пользователь подключит или отключить устройство
     * */
    if (navigator.mediaDevices?.ondevicechange) {
      navigator.mediaDevices.ondevicechange = () => {
        this.getDeviceCameras();
      };
    }
  }

  getDeviceCameras = async () => {
    if ('mediaDevices' in navigator && navigator.mediaDevices.enumerateDevices) {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();

      this.cameras = mediaDevices.filter(({ kind }) => kind === 'videoinput');
    }
  };

  getDevices = (withLoader = true, companyId?: number) => {
    if (withLoader) {
      this.isFetching = withLoader;
    }

    return fetchApi
      .get<DeviceModel[]>('/devices/', { company_id: companyId })
      .then((response) => {
        response.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;

          return 0;
        });

        runInAction(() => {
          this.devices = response.map((device) => new Device(device));
        });

        return this.devices;
      })
      .finally(() => {
        if (withLoader) {
          runInAction(() => {
            this.isFetching = false;
          });
        }
      });
  };

  getUserDevices = () => {
    this.isFetching = true;

    return fetchApi
      .get<UserDeviceModel[]>('/user/devices/')
      .then((devices) => {
        devices.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;

          return 0;
        });

        runInAction(() => {
          this.userDevices = devices.map((device) => new UserDevice(device));
        });

        return this.userDevices;
      })
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  activate = (id: number, active: boolean) => {
    this.isSaving = true;

    return fetchApi
      .put(`/devices/${id}/`, {
        body: { active },
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  update = (id: number, body: Partial<DeviceInfo>) => {
    this.isUpdating = true;

    return fetchApi.put(`/devices/${id}/`, { body }).finally(() => {
      runInAction(() => {
        this.isUpdating = false;
      });
    });
  };

  attach = (deviceId: number, usersId: number[]) => {
    this.isAttaching = true;

    const requestArray = usersId.map((userId) => fetchApi.put(`/devices/${deviceId}/user/${userId}/`));

    return Promise.all(requestArray).finally(() => {
      runInAction(() => {
        this.isAttaching = false;
      });
    });
  };

  detach = (deviceId: number, userId: number) => {
    this.isAttaching = true;

    return fetchApi.remove(`/devices/${deviceId}/user/${userId}/`).finally(() => {
      runInAction(() => {
        this.isAttaching = false;
      });
    });
  };

  deleteDeviceById = (deviceId: number) => {
    this.isSaving = true;

    return fetchApi.remove(`/devices/${deviceId}/`).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };

  setDefaultDevice = (id: number) => {
    this.isFetching = true;

    return fetchApi.put(`/user/devices/${id}/default/`).finally(() => {
      runInAction(() => {
        this.isFetching = false;
      });
    });
  };
}
