import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';

export const LogusPersonsRawRoot = styled(Grid)`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${grey[100]};
  }
`;
