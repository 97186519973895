import styled from '@mui/material/styles/styled';

export const CompanyUsersRoot = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CompanyUsersHeader = styled('div')`
  display: flex;
  align-items: center;
`;

export const CompanyUsersList = styled('div')`
  margin-top: 32px;
`;
