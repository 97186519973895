import { Link } from 'react-router-dom';
import styled from '@mui/material/styles/styled';

import { EMIS_BRAND_COLOR } from '@/ui-kit/global-styles';

export const BreadcrumbsLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  &:hover {
    color: ${EMIS_BRAND_COLOR};
  }
`;
