import React, { ChangeEvent, Fragment, memo, useCallback, useMemo } from 'react';
import InputMask from 'react-input-mask';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import Tooltip from '@mui/material/Tooltip';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { DocumentType, DocumentTypeEnumId } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { AuthorityOrganWarningIcon } from '@/shared/IdCardForm/IdCardForm.style';
import { PersonDocument } from '@/stores/PersonsStore';
import { store } from '@/stores/Store';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField, DatepickerField, InputField, SelectField } from '@/ui-kit/components';
import { parseDocIssueCode } from '@/utils/parseDocIssueCode';

import { usePersonPageStore } from '../../../PersonPageStore';

import { docsIds1, docsIds2, docsIds3, docsIds4, docsIds5 } from './RightOfStay.constant';
import { checkRightOfStayData, filterDocumentsByCitizenship, validateRightOfStayValues } from './RightOfStay.utils';

function RightOfStay() {
  const { rightOfStay, setRightOfStay, citizenship, isExported, isDeleted } = usePersonPageStore();
  const {
    docTypes,
    docVisaTypes,
    docVisaVisitTypes,
    docVisaMultiTypes,
    docIssueAuthorityOrgans,
    getDocIssueAuthorityOrgans,
  } = useStore('dictionariesStore');

  const [isShowNoOrgan, openNoOrgan, closeNoOrgan] = useToggle();
  const [isAuthorityOrganDropdownOpen, openAuthorityOrganDropdown, closeAuthorityOrganDropdown] = useToggle();

  const docIssueAuthorityOptions = useMemo(
    () => [...docIssueAuthorityOrgans.map(({ name_ru }) => name_ru)],
    [JSON.stringify(docIssueAuthorityOrgans)],
  );

  function renderAuthorityOrganIcon() {
    if (docIssueAuthorityOptions.length > 1 && !isExported && !isDeleted) {
      return (
        <Fragment>
          <Tooltip title="Найдено несколько значений">
            <AuthorityOrganWarningIcon fontSize="small" />
          </Tooltip>
          &nbsp;
        </Fragment>
      );
    }

    return null;
  }

  const filterAuthorityOptions = useCallback(
    (option: string[], { inputValue }: FilterOptionsState<string>) =>
      option.filter((name: string) => name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
    [],
  );

  async function fetchAuthorityOrgan(code: string) {
    const organs = await getDocIssueAuthorityOrgans(code);
    let organ = organs?.[0];

    if (organs && rightOfStay!.doc_issue_authority) {
      const selectedOrgan = organs.find(({ name_ru }) => name_ru === rightOfStay!.doc_issue_authority);

      if (selectedOrgan) {
        organ = selectedOrgan;
      }
    }

    if (organ) {
      setRightOfStay({
        ...rightOfStay,
        doc_issue_authority_organ_id: organ.id,
        doc_issue_authority_organ: organ,
        doc_issue_authority: organ.name_ru,
        doc_issue_code: organ.code,
      });
    } else {
      openNoOrgan();
    }
  }

  const onIssueAuthorityCodeChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const docIssueCode = target.value.replace(' - ', '-').replace('_', '').trim();

      setRightOfStay({
        ...rightOfStay,
        doc_issue_code: docIssueCode,
      });

      const code = parseDocIssueCode(docIssueCode);

      if (code) {
        fetchAuthorityOrgan(code);
      }
    },
    [setRightOfStay, JSON.stringify(rightOfStay)],
  );

  const docTypesFiltered = useMemo(
    () =>
      (citizenship ? filterDocumentsByCitizenship(docTypes, citizenship) : docTypes).filter(
        ({ category }) => category === DocumentType.RightOfStay,
      ),
    [citizenship],
  );

  const onChangeRightOfStayValue = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;

    setRightOfStay({
      [name]: value,
    } as Partial<PersonDocument>);
  }, []);

  const onChangeDocIssueDate = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setRightOfStay({
      doc_issue_date: target.value,
    });
  }, []);

  const onChangeDocStartDate = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setRightOfStay({
      doc_start_date: target.value,
    });
  }, []);

  const onChangeExpire = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setRightOfStay({
      doc_expire_date: target.value,
    });
  }, []);

  const onChangeDocTypeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setRightOfStay({
      doc_issue_authority:
        +target.value === DocumentTypeEnumId.DigitalVisa ? 'Санкт-Петербург и Ленинградская область' : target.value,
    });
  }, []);

  const onChangeDocVisaTypeHandler = useCallback(({ target }: SelectChangeEvent<HTMLElement>) => {
    setRightOfStay({
      doc_visa_type_id: +target.value,
    });
  }, []);

  const errors = useMemo(() => validateRightOfStayValues(rightOfStay), [JSON.stringify(rightOfStay)]);
  const warning = useMemo(() => checkRightOfStayData(rightOfStay), [JSON.stringify(rightOfStay)]);

  if (!rightOfStay) {
    return null;
  }

  const shouldShowDocIssueType = [DocumentTypeEnumId.TempPermissionRightOfStay].includes(
    +rightOfStay.document_type_enum_id,
  );

  return (
    <fieldset disabled={isExported || isDeleted}>
      <Grid container spacing={2} wrap="wrap">
        <Grid item xs={3}>
          <SelectField
            disabled={docTypes.length === 0 || isExported || isDeleted}
            label="Тип документа"
            name="document_type_enum_id"
            onChange={onChangeRightOfStayValue}
            value={rightOfStay.document_type_enum_id}
          >
            {docTypesFiltered.map(({ id, name_ru }) => (
              <MenuItem key={id} value={id}>
                {name_ru}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        {docsIds1.includes(+rightOfStay.document_type_enum_id) && (
          <Fragment>
            <Grid item xs={3}>
              <InputField
                error={errors.doc_serial}
                label="Серия"
                name="doc_serial"
                onChange={onChangeRightOfStayValue}
                tooltip={warning.doc_serial}
                value={rightOfStay.doc_serial}
              />
            </Grid>

            <Grid item xs={3}>
              <InputField
                error={errors.doc_number}
                label="Номер"
                name="doc_number"
                onChange={onChangeRightOfStayValue}
                tooltip={warning.doc_number}
                value={rightOfStay.doc_number}
              />
            </Grid>
          </Fragment>
        )}

        {docsIds2.includes(+rightOfStay.document_type_enum_id) && (
          <Fragment>
            <Grid item xs={3}>
              <DatepickerField
                disabled={isExported || isDeleted}
                error={errors.doc_issue_date}
                label="Дата выдачи"
                onChange={onChangeDocIssueDate}
                tooltip={warning.doc_issue_date}
                value={rightOfStay.doc_issue_date}
              />
            </Grid>

            {![DocumentTypeEnumId.Visa, DocumentTypeEnumId.DigitalVisa].includes(
              +rightOfStay.document_type_enum_id,
            ) && (
              <Grid item xs={3}>
                <InputField
                  error={errors.doc_decision_number}
                  label="Номер решения"
                  name="doc_decision_number"
                  onChange={onChangeRightOfStayValue}
                  value={rightOfStay.doc_decision_number}
                />
              </Grid>
            )}
          </Fragment>
        )}

        {docsIds3.includes(+rightOfStay.document_type_enum_id) && (
          <Fragment>
            <Grid item xs={3}>
              <DatepickerField
                disabled={isExported || isDeleted}
                error={errors.doc_start_date}
                label={
                  rightOfStay.document_type_enum_id === DocumentTypeEnumId.DigitalVisa ||
                  rightOfStay.document_type_enum_id === 33
                    ? 'Срок действия с'
                    : 'Дата решения'
                }
                onChange={onChangeDocStartDate}
                tooltip={warning.doc_start_date}
                value={rightOfStay.doc_start_date}
              />
            </Grid>

            <Grid item xs={3}>
              <DatepickerField
                disabled={isExported || isDeleted}
                error={errors.doc_expire_date}
                label="Срок действия до"
                onChange={onChangeExpire}
                tooltip={warning.doc_expire_date}
                value={rightOfStay.doc_expire_date}
              />
            </Grid>
          </Fragment>
        )}

        {docsIds4.includes(+rightOfStay.document_type_enum_id) && (
          <Fragment>
            <Grid item xs={3}>
              <InputField
                error={errors.doc_id}
                label="Идентификатор"
                maxLength={12}
                name="doc_id"
                onChange={onChangeRightOfStayValue}
                tooltip={
                  store.signInStore.userCompany?.company.allow_export === 1 && rightOfStay.doc_id.length > 9
                    ? 'Обратите внимание, Элпост принимает максимум 9 символов'
                    : undefined
                }
                value={rightOfStay.doc_id}
              />
            </Grid>

            {+rightOfStay.document_type_enum_id !== DocumentTypeEnumId.Visa && (
              <Fragment>
                {+rightOfStay.document_type_enum_id !== DocumentTypeEnumId.DigitalVisa && (
                  <Grid item xs={3}>
                    <InputField
                      error={errors.doc_number}
                      label="Номер решения"
                      name="doc_number"
                      onChange={onChangeRightOfStayValue}
                      value={rightOfStay.doc_number}
                    />
                  </Grid>
                )}

                <Grid item xs={3}>
                  <DatepickerField
                    disabled={isExported || isDeleted}
                    error={errors.doc_issue_date}
                    label="Дата решения"
                    onChange={onChangeDocIssueDate}
                    tooltip={warning.doc_issue_date}
                    value={rightOfStay.doc_issue_date}
                  />
                </Grid>
              </Fragment>
            )}

            {![DocumentTypeEnumId.DigitalVisa].includes(+rightOfStay.document_type_enum_id) && (
              <Grid item xs={3}>
                <SelectField
                  disabled={docVisaTypes.length === 0 || isExported || isDeleted}
                  error={errors.doc_visa_type_id}
                  label="Вид (категория)"
                  onChange={onChangeDocVisaTypeHandler}
                  value={rightOfStay.doc_visa_type_id}
                >
                  {docVisaTypes.map(({ id, name_ru }) => (
                    <MenuItem key={id} value={id}>
                      {name_ru}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
            )}

            {![DocumentTypeEnumId.DigitalVisa].includes(+rightOfStay.document_type_enum_id) && (
              <Grid item xs={3}>
                <SelectField
                  disabled={docVisaVisitTypes.length === 0 || !rightOfStay.doc_visa_type_id || isExported || isDeleted}
                  error={errors.doc_visa_visit_type_id}
                  label="Цель поездки"
                  name="doc_visa_visit_type_id"
                  onChange={onChangeRightOfStayValue}
                  value={rightOfStay.doc_visa_visit_type_id}
                >
                  {docVisaVisitTypes
                    .filter(({ doc_visa_type_id }) => doc_visa_type_id === rightOfStay.doc_visa_type_id)
                    .map(({ id, name_ru }) => (
                      <MenuItem key={id} value={id}>
                        {name_ru}
                      </MenuItem>
                    ))}
                </SelectField>
              </Grid>
            )}

            <Grid item xs={3}>
              <SelectField
                disabled={docVisaMultiTypes.length === 0 || isExported || isDeleted}
                error={errors.doc_visa_multi_type_id}
                label="Кратность визы"
                name="doc_visa_multi_type_id"
                onChange={onChangeRightOfStayValue}
                value={rightOfStay.doc_visa_multi_type_id}
              >
                {docVisaMultiTypes.map(({ id, name_ru }) => (
                  <MenuItem key={id} value={id}>
                    {name_ru}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
          </Fragment>
        )}

        {shouldShowDocIssueType && (
          <Fragment>
            <Tooltip
              onClick={closeNoOrgan}
              open={isShowNoOrgan}
              placement="top"
              title="Нет подразделений соответствующих данному коду"
            >
              <Grid item xs={3}>
                <InputMask
                  mask="999 - 999"
                  maskPlaceholder={null}
                  name="doc_issue_code"
                  onBlur={closeNoOrgan}
                  onChange={onIssueAuthorityCodeChange}
                  value={rightOfStay.doc_issue_code}
                >
                  {/* @ts-ignore */}

                  {() => <InputField error={errors?.doc_issue_code} label="Код подразделения" />}
                </InputMask>
              </Grid>
            </Tooltip>

            <Grid item xs={6}>
              <AutocompleteField
                InputProps={{
                  startAdornment: renderAuthorityOrganIcon(),
                }}
                disableClearable
                disabled={isExported || isDeleted}
                filterOptions={filterAuthorityOptions}
                inputProps={{
                  readOnly: true,
                }}
                isError={errors?.doc_issue_authority_organ}
                label="Кем выдан"
                name="doc_issue_authority_organ"
                noOptionsText="Нет данных"
                onBlur={closeAuthorityOrganDropdown}
                onFocus={openAuthorityOrganDropdown}
                open={isAuthorityOrganDropdownOpen}
                openOnFocus
                options={[...docIssueAuthorityOrgans.map(({ name_ru }) => name_ru)]}
                popupIcon={null}
                value={rightOfStay.doc_issue_authority_organ?.name_ru ?? ''}
              />
            </Grid>
          </Fragment>
        )}

        {!shouldShowDocIssueType &&
          (docsIds5.includes(+rightOfStay.document_type_enum_id) || isExported || isDeleted) && (
            <Grid item xs={3}>
              <InputField
                disabled={isExported || isDeleted}
                error={errors.doc_issue_authority}
                label={
                  rightOfStay.document_type_enum_id === DocumentTypeEnumId.DigitalVisa
                    ? 'Территория посещения'
                    : 'Кем выдан'
                }
                name="doc_issue_authority"
                onChange={onChangeDocTypeHandler}
                value={rightOfStay.doc_issue_authority}
              />
            </Grid>
          )}

        {rightOfStay.document_type_enum_id === DocumentTypeEnumId.DigitalVisa && (
          <Grid item xs={3}>
            <InputField
              error={errors.visit_days}
              label="Срок пребывания (дней)"
              name="visit_days"
              onChange={onChangeRightOfStayValue}
              type="number"
              value={rightOfStay.visit_days}
            />
          </Grid>
        )}
      </Grid>
    </fieldset>
  );
}

export default memo(observer(RightOfStay));
