import React, { memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { BackdropLoaderRoot } from './BackdropLoader.style';

interface BackdropLoaderProps {
  isLoading: boolean;
  size?: number;
}

function BackdropLoader(props: BackdropLoaderProps) {
  const { isLoading, size = 48 } = props;

  if (!isLoading) {
    return null;
  }

  return (
    <BackdropLoaderRoot>
      <CircularProgress disableShrink size={size} variant="indeterminate" />
    </BackdropLoaderRoot>
  );
}

export default memo(BackdropLoader);
