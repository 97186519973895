import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { format } from 'date-fns';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import { fetchApi } from '@/api/fetchApi';
import { notificationAPI } from '@/api/notificationAPI';
import { DATE_FOR_SERVER_FORMAT } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { ButtonLoader, DatepickerField, FileField } from '@/ui-kit/components';

import { ExportAction, ExportOperaFile, ExportOperaWrapper } from './Export.style';

function Export() {
  const [isLoading, , , toggleLoading] = useToggle();

  const [dateFrom, setDateFrom] = useState<Nullable<Date>>(null);
  const [dateTo, setDateTo] = useState<Nullable<Date>>(null);

  const [isMigrationReportChecked, , , toggleMigrationReportChecked] = useToggle(true);
  const [isRegisterReportChecked, , , toggleRegisterReportChecked] = useToggle(true);

  const [operaFile, setOperaFile] = useState<Nullable<File>>(null);

  const onChangeFrom = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => setDateFrom(new Date(target.value)),
    [],
  );
  const onChangeTo = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => setDateTo(new Date(target.value)), []);

  async function onCompare() {
    toggleLoading();

    try {
      if (dateFrom && dateTo) {
        const dateFromFormatted = format(new Date(dateFrom), DATE_FOR_SERVER_FORMAT);
        const dateToFormatted = format(new Date(dateTo), DATE_FOR_SERVER_FORMAT);

        let migrationReport: Blob | undefined;
        let registerReport: Blob | undefined;

        if (isMigrationReportChecked) {
          const [migrationReportFile] = await fetchApi.post<[Blob, Response]>('/convert-emis-elpost/', {
            query: {
              from: dateFromFormatted,
              to: dateToFormatted,
              mig: true,
            },
            responseType: 'blob',
          });

          migrationReport = migrationReportFile;
        }

        if (isRegisterReportChecked) {
          const [registerFile] = await fetchApi.post<[Blob, Response]>('/convert-emis-elpost/', {
            query: {
              from: dateFromFormatted,
              to: dateToFormatted,
              mig: false,
            },
            responseType: 'blob',
          });

          registerReport = registerFile;
        }

        const formData = new FormData();

        if (registerReport) {
          formData.append('fileReg', registerReport);
        }

        if (migrationReport) {
          formData.append('fileMig', migrationReport);
        }

        if (operaFile) {
          formData.append('fileOpera', operaFile);
        }

        await fetchApi.download('/compare-elpost-opera/', { body: formData });
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    toggleLoading();
  }

  function onAttachOperaFile({ target }: ChangeEvent<HTMLInputElement>) {
    setOperaFile(target.value as unknown as File);
  }

  const isCompareAvailable = (isMigrationReportChecked || isRegisterReportChecked) && dateFrom && dateTo && operaFile;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stepper activeStep={-1} nonLinear orientation="vertical">
          <Step active expanded>
            <StepLabel>
              <Typography variant="h6">Заполните интервал проживания гостей для формирования отчета</Typography>
            </StepLabel>

            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatepickerField
                    disabled={isLoading}
                    label="Период проживания, от"
                    onChange={onChangeFrom}
                    value={dateFrom}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatepickerField
                    disabled={isLoading}
                    label="Период проживания, до"
                    onChange={onChangeTo}
                    value={dateTo}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step active expanded>
            <StepLabel>
              <Typography variant="h6">Выберите необходимые отчеты</Typography>
            </StepLabel>

            <StepContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isMigrationReportChecked}
                        color="primary"
                        onClick={toggleMigrationReportChecked}
                      />
                    }
                    label="Миграционный учет"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isRegisterReportChecked}
                        color="primary"
                        onClick={toggleRegisterReportChecked}
                      />
                    }
                    label="Регистрационный учет"
                  />
                </Grid>
              </Grid>
            </StepContent>
          </Step>

          <Step active expanded>
            <StepLabel>
              <Typography variant="h6">Загрузите отчет из Opera</Typography>
            </StepLabel>

            <StepContent>
              <ExportOperaWrapper container spacing={2}>
                <Grid item xs={12}>
                  <ExportOperaFile>
                    <FileField
                      accept="text/xml"
                      disabled={isLoading}
                      label="Opera xml"
                      name="opera"
                      onChange={onAttachOperaFile}
                      value={operaFile}
                    />
                  </ExportOperaFile>
                </Grid>
              </ExportOperaWrapper>
            </StepContent>
          </Step>
        </Stepper>

        <Grid container spacing={2}>
          <ExportAction item xs={12}>
            <ButtonLoader disabled={!isCompareAvailable} isLoading={isLoading} onClick={onCompare} size="medium">
              Сравнить
            </ButtonLoader>
          </ExportAction>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(Export);
