import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  Fragment,
  memo,
  ReactNode,
  SyntheticEvent,
  useMemo,
  useRef,
} from 'react';
import { v4 as uuid } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import Button, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { FileFieldLabel, FileFieldRoot } from './FileField.style';

interface FileFieldProps {
  accept: string;
  label: ReactNode;
  value?: Nullable<File | File[]>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
  multiple?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  hideList?: boolean;
  size?: ButtonProps['size'];
  startIcon?: ButtonProps['startIcon'];
  variant?: ButtonProps['variant'];
}

const FileField = forwardRef((props: FileFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    id,
    accept,
    name,
    value,
    label,
    onChange,
    multiple,
    disabled,
    fullWidth,
    hideList,
    size = 'small',
    startIcon,
    variant = 'outlined',
  } = props;

  const uniqueId = useMemo(() => id ?? uuid(), [id]);
  const fileFieldRef = useRef<Nullable<HTMLInputElement>>(ref as unknown as HTMLInputElement);

  function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    let files = null;

    if (e.target.files) {
      if (multiple) {
        files = e.target.files;
      } else {
        files = e.target.files[0];
      }
    }

    onChange?.({
      ...e,
      target: {
        name: name ?? '',
        value: files,
      },
      currentTarget: {
        name: name ?? '',
        value: files,
      },
    } as unknown as ChangeEvent<HTMLInputElement>);
  }

  function onRemoveSingleFile(i: number, e: SyntheticEvent) {
    const files = (value as File[]).splice(i, 1);

    onChangeHandler({
      ...e,
      target: {
        ...e.target,
        name: name ?? '',
        value: files,
      },
      currentTarget: {
        ...e.currentTarget,
        name: name ?? '',
        value: files,
      },
    } as unknown as ChangeEvent<HTMLInputElement>);
  }

  function onResetHandler(e: SyntheticEvent) {
    if (fileFieldRef.current) {
      fileFieldRef.current.value = '';
    }

    onChange?.({
      ...e,
      target: {
        name: name ?? '',
        value: null,
      },
      currentTarget: {
        name: name ?? '',
        value: null,
      },
    } as unknown as ChangeEvent<HTMLInputElement>);
  }

  return (
    <FileFieldRoot>
      <input
        accept={accept}
        id={uniqueId}
        multiple={multiple}
        name={name}
        onChange={onChangeHandler}
        ref={fileFieldRef}
        type="file"
      />

      {!value && (
        <FileFieldLabel htmlFor={uniqueId}>
          <Button
            color="primary"
            component="span"
            disabled={disabled}
            fullWidth={fullWidth}
            size={size}
            startIcon={startIcon}
            variant={variant}
          >
            {label}
          </Button>
        </FileFieldLabel>
      )}

      {!hideList && value && (
        <Fragment>
          {multiple &&
            (value as File[]).length > 0 &&
            (value as File[]).map(({ name }, i) => (
              <div key={name} title={name}>
                {name}

                <IconButton onClick={(e) => onRemoveSingleFile(i, e)} size="small">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            ))}

          {!multiple && (
            <div title={(value as File).name}>
              {(value as File).name}

              <IconButton onClick={onResetHandler} size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          )}
        </Fragment>
      )}
    </FileFieldRoot>
  );
});

export default memo(FileField);
