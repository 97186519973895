import React, { ChangeEvent, Fragment, memo, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { storageAPI } from '@/api/storageAPI';
import { DocumentType } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { UploadImage } from '@/routes/UploadImages/UploadImages.types';
import ImageTypeModal from '@/shared/KSImageTypeModal/KSImageTypeModal';
import { DEFAULT_ID_CARD, KS_IMAGE_TYPE_NAME_MAP, KSImageType, PersonDetail } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, ButtonLoader, FileField } from '@/ui-kit/components';
import { convertToImageBase64, generateBase64FromBlob, getDocumentByType } from '@/utils';

import {
  UploadImagesActions,
  UploadImagesContent,
  UploadImagesEmpty,
  UploadImagesItem,
  UploadImagesItemContent,
  UploadImagesRemove,
  UploadImagesRoot,
  UploadImagesSuccess,
  UploadImagesWrapper,
} from './UploadImages.style';

function UploadImages() {
  const { personId } = useParams();
  const { getPersonById, updatePerson } = useStore('personsStore');

  const { token, type, reasonId } = queryAPI.params;

  const imagesRef: RefObject<HTMLInputElement> = useRef<Nullable<HTMLInputElement>>(null);

  const [isSuccess, showSuccess, hideSuccess] = useToggle();
  const [isUploading, showLoader, hideLoader] = useToggle();
  const [person, setPerson] = useState<Nullable<PersonDetail>>(null);
  const [images, setImages] = useState<UploadImage[]>([]);

  const [uploadedImageBase64, setUploadedImageBase64] = useState<Nullable<string>>(null);

  const hasImages = images.length > 0;
  const isKS = type === 'ks' && reasonId;

  async function onUpload() {
    if (!personId || !person) {
      return;
    }

    showLoader();

    try {
      if (isKS) {
        person.ks_images = (person.ks_images ?? []).concat(
          images.map((image: UploadImage) => ({
            data: image.data,
            ks_image_type: image.ks_image_type,
          })),
        );
      } else {
        const [isCard = { ...DEFAULT_ID_CARD }, index] = getDocumentByType(person.documents, DocumentType.IdCard);

        isCard.images = (isCard.images ?? []).concat(
          images.map((image: UploadImage) => ({
            data: image.data,
            not_export: false,
            image_upload_type: 3,
          })),
        );

        if (index === -1) {
          person.documents = [...person.documents, isCard];
        } else {
          person.documents[index] = isCard;
        }
      }

      await updatePerson(+personId, person);

      showSuccess();

      if (imagesRef.current) {
        imagesRef.current.value = '';
      }

      setImages([]);
    } catch (e) {
      notificationAPI.error(e);
    }

    hideLoader();
  }

  function onSelectType(type: KSImageType) {
    if (!uploadedImageBase64) {
      return;
    }

    setImages((images) => [
      ...images,
      {
        data: uploadedImageBase64,
        ks_image_type: type,
      },
    ]);

    setUploadedImageBase64(null);
  }

  async function getPersonData() {
    try {
      const person = await getPersonById(+personId!);

      setPerson(person);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function renderInput() {
    return (
      <FileField
        accept="image/png, image/jpeg, image/jpg"
        disabled={isUploading}
        label={
          <Fragment>
            {!isSuccess && <span>{hasImages ? 'Добавить ещё' : 'Загрузить изображения'}</span>}

            {isSuccess && 'Загрузить еще'}
          </Fragment>
        }
        multiple
        onChange={onAddImages}
        ref={imagesRef}
        size={hasImages ? 'small' : 'large'}
      />
    );
  }

  useEffect(() => {
    if (personId && token) {
      storageAPI.set('token', token);

      getPersonData();
    }

    return () => {
      storageAPI.remove('token');
    };
  }, []);

  const onAddImages = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      hideSuccess();

      if (currentTarget.value && currentTarget.value.length > 0) {
        [...currentTarget.value].forEach(async (file) => {
          const data = await generateBase64FromBlob(file as unknown as Blob);

          if (isKS) {
            setUploadedImageBase64(data);
          } else {
            setImages((images) => [...images, { data }]);
          }
        });
      }
    },
    [isKS],
  );

  const removeImageByIcon = useCallback((index: number) => {
    setImages((images) => {
      const nextImages = [...images];

      nextImages.splice(index, 1);

      return nextImages;
    });
  }, []);

  const onCloseSelectTypeModal = useCallback(() => setUploadedImageBase64(null), []);

  if (!personId || !token) {
    return <UploadImagesRoot>Ошибка</UploadImagesRoot>;
  }

  if (!person) {
    return <BackdropLoader isLoading />;
  }

  return (
    <UploadImagesRoot>
      {isSuccess && (
        <UploadImagesSuccess>
          <p>Изображения добавлены</p>

          {renderInput()}
        </UploadImagesSuccess>
      )}

      {!isSuccess && (
        <Fragment>
          {!hasImages && <UploadImagesEmpty>{renderInput()}</UploadImagesEmpty>}

          {hasImages && (
            <UploadImagesWrapper>
              <UploadImagesContent>
                {images.map(({ data, ks_image_type }, i) => (
                  <UploadImagesItem key={data}>
                    {isKS && ks_image_type && <p>{KS_IMAGE_TYPE_NAME_MAP[ks_image_type]}</p>}

                    <UploadImagesItemContent>
                      <UploadImagesRemove
                        color="error"
                        disabled={isUploading}
                        onClick={() => removeImageByIcon(i)}
                        size="small"
                        variant="contained"
                      >
                        <DeleteIcon />
                      </UploadImagesRemove>

                      <img alt="" src={convertToImageBase64(data)} width="300" />
                    </UploadImagesItemContent>
                  </UploadImagesItem>
                ))}
              </UploadImagesContent>

              <UploadImagesActions>
                {renderInput()}

                <ButtonLoader
                  color="primary"
                  isLoading={isUploading}
                  onClick={onUpload}
                  size="small"
                  variant="contained"
                >
                  Отправить
                </ButtonLoader>
              </UploadImagesActions>
            </UploadImagesWrapper>
          )}
        </Fragment>
      )}

      {isKS && (
        <ImageTypeModal
          onClose={onCloseSelectTypeModal}
          onSelectType={onSelectType}
          open={!!uploadedImageBase64}
          reasonId={+reasonId!}
        />
      )}
    </UploadImagesRoot>
  );
}

export default memo(UploadImages);
