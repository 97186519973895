import React, { Fragment, memo, MouseEvent, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Popover from '@mui/material/Popover';

import { Group } from '@/entities/group';
import GroupItem from '@/shared/Groups/GroupItem/GroupItem';
import { useStore } from '@/stores/StoreProvider';
import { InputField, InputFieldProps } from '@/ui-kit/components';

import { GroupPopover } from './GroupSelectorInput.style';

type GroupSelectorInputProps = InputFieldProps & {
  onSelectGroup: (group: Group) => void;
  selectable?: boolean;
};

function GroupSelectorInput(props: GroupSelectorInputProps) {
  const { selectable = true, onSelectGroup, ...rest } = props;
  const { groups } = useStore('groupsStore');

  const [anchorPopover, setAnchorPopover] = useState<Nullable<HTMLElement>>(null);

  const onGroupClose = useCallback(() => setAnchorPopover(null), []);

  function onGroupClick({ currentTarget }: MouseEvent<HTMLDivElement>) {
    if (selectable) {
      setAnchorPopover(currentTarget);
    }
  }

  function onSelectGroupHandler(group: Group) {
    setAnchorPopover(null);
    onSelectGroup(group);
  }

  return (
    <Fragment>
      <InputField onClick={onGroupClick} readOnly {...rest} />

      <Popover
        anchorEl={anchorPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={onGroupClose}
        open={!!anchorPopover}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <GroupPopover>
          {groups.map((group) => (
            <GroupItem editable={false} group={group} key={group.id} onSelect={onSelectGroupHandler} />
          ))}
        </GroupPopover>
      </Popover>
    </Fragment>
  );
}

export default memo(observer(GroupSelectorInput));
