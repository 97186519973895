import React, { ChangeEvent, ForwardedRef, forwardRef, KeyboardEvent, memo, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { InputResetIcon, InputRoot } from './InputField.style';

export type InputFieldProps = Omit<TextFieldProps, 'ref'> & {
  clearable?: boolean;
  onClear?: (e: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  value?: StringOrNumber;
  maxLength?: number;
  step?: number;
  min?: number;
  max?: number;
  /*
   * "onChange" передает только евент события
   * "onChangeValue" передает только новое значение и евент события
   *
   * TODO удалить onChangeValue везде
   * */
  onChangeValue?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
  tooltip?: string;
  tooltipColor?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  pattern?: string;
};

const InputField = forwardRef((props: InputFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
  const {
    clearable,
    label,
    value,
    name,
    onChange,
    onChangeValue,
    onClear,
    readOnly,
    maxLength,
    variant = 'standard',
    color = 'primary',
    fullWidth = true,
    margin = 'none',
    focused,
    tooltip,
    tooltipColor = 'warning',
    pattern,
    step,
    min,
    max,
    ...rest
  } = props;

  function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    onChange?.(e);

    onChangeValue?.(e.target.value, e);
  }

  const onReset = useCallback(() => {
    const resetEvent = {
      target: {
        name: name ?? '',
        value: '',
      },
      currentTarget: {
        name: name ?? '',
        value: '',
      },
    } as ChangeEvent<HTMLInputElement>;

    onChange?.(resetEvent);
    onClear?.(resetEvent);
  }, [name, onChange, onClear]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.code === 'Escape') {
        onReset();
      }
    },
    [onReset],
  );

  const inputProps: TextFieldProps = ((): TextFieldProps => {
    const result: TextFieldProps = {
      color: tooltip ? tooltipColor : color,
      focused: !!tooltip || focused,
      fullWidth,
      inputProps: {
        readOnly,
        maxLength,
        pattern,
        name,
        step,
        min,
        max,
      },
      margin,
      name,
      variant,
      label,
      onKeyDown,
      autoComplete: 'off',
      ...rest,
    };

    if (typeof value !== 'undefined') {
      result.value = `${value}`;
    }

    if (onChange) {
      result.onChange = onChangeHandler;
    }

    if (result.value) {
      const availableLength = result.value && maxLength ? ` ${(result.value as string).length}/${maxLength}` : '';

      result.label = label ? `${label}${availableLength}` : '';
    }

    return result;
  })();

  const isClearable = Boolean(clearable && inputProps.value);

  return (
    <InputRoot isClearable={isClearable}>
      <Tooltip placement="top" title={tooltip ?? ''}>
        <TextField ref={ref} {...inputProps} />
      </Tooltip>

      {isClearable && (
        <InputResetIcon variant={variant}>
          <IconButton onClick={onReset} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </InputResetIcon>
      )}
    </InputRoot>
  );
});

export default memo(InputField);
