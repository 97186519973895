import React, { memo } from 'react';
import Grid from '@mui/material/Grid';

import CompanyFaces from './CompanyFaces/CompanyFaces';
import DocTemplates from './DocTemplates/DocTemplates';
import EulaCards from './EulaCards/EulaCards';
import IpAddressList from './IpAddressList/IpAddressList';

interface SettingsProps {
  companyId: number;
}

function Settings({ companyId }: SettingsProps) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <IpAddressList companyId={companyId} />
      </Grid>

      <Grid item xs={12}>
        <DocTemplates companyId={companyId} />
      </Grid>

      <Grid item xs={12}>
        <EulaCards companyId={companyId} />
      </Grid>

      <Grid item xs={12}>
        <CompanyFaces companyId={companyId} />
      </Grid>
    </Grid>
  );
}

export default memo(Settings);
