import React, { memo, SyntheticEvent, useCallback } from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

import { DocumentType } from '@/constants';
import { ContentModal, ContentModalProps } from '@/ui-kit/components';

interface DeleteConfirmModalProps extends ContentModalProps {
  tab: Nullable<DocumentType>;
  onConfirm: (tab: Nullable<DocumentType>) => void;
}

function DeleteDocumentConfirmModal(props: DeleteConfirmModalProps) {
  const { open, onConfirm, onClose, tab } = props;

  const onConfirmHandler = useCallback(() => onConfirm(tab), [tab]);

  const onCloseHandler = useCallback(
    (_?: SyntheticEvent, reason?: string) => {
      if (reason === 'backdropClick') {
        return;
      }

      onClose?.();
    },
    [onClose],
  );

  return (
    <ContentModal disableEscapeKeyDown={false} onClose={onCloseHandler} open={open}>
      <ContentModal.Header onClose={onCloseHandler}>Удалить документ</ContentModal.Header>

      <ContentModal.Body>
        <DialogContentText>Вы уверены, что хотите удалить документ?</DialogContentText>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onCloseHandler}>
          Отмена
        </Button>

        <Button color="error" onClick={onConfirmHandler} variant="contained">
          Удалить
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(DeleteDocumentConfirmModal);
