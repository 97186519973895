import React, { memo, useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';
import { useStore } from '@/stores/StoreProvider';

import OperatorPanelTabs from '../OperatorPanelTabs/OperatorPanelTabs';

import { HotelReportTab } from './HotelReport.constants';
import { HotelReportContent, HotelReportSidebar } from './HotelReport.style';
import ReportForm from './ReportForm/ReportForm';

function HotelReport() {
  const { isExporting } = useStore('personsStore');

  const [tab, setTab] = useState(HotelReportTab.Report);

  const onChangeTab = useCallback((_: unknown, newTab: HotelReportTab) => setTab(newTab), []);

  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Отчет']} />}
      content={
        <HotelReportContent container spacing={2}>
          <Grid item xs={2}>
            <HotelReportSidebar>
              <Tabs onChange={onChangeTab} orientation="vertical" value={tab}>
                <Tab label="Отчет из EMIS Service" />
              </Tabs>
            </HotelReportSidebar>
          </Grid>

          <Grid item xs={10}>
            {tab === HotelReportTab.Report && <ReportForm />}
          </Grid>
        </HotelReportContent>
      }
      header={<OperatorPanelTabs />}
      isLoading={isExporting}
    />
  );
}

export default memo(HotelReport);
