import React, { ChangeEvent, Fragment, memo, MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import FilterIcon from '@mui/icons-material/FilterList';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { useToggle } from '@/hooks/useToggle/useToggle';
import SRMInfoModal from '@/shared/SRMInfoModal/SRMInfoModal';
import { GetPersonsArguments, Person } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import BookingRow from './BookingRow/BookingRow';
import {
  BookingsTableContent,
  BookingsTableRoot,
  BookingsTableTable,
  BookingsTableWrapper,
  TablePaginationContent,
} from './BookingsTable.style';
import { getValidFilterData } from './BookingsTable.utils';
import FilterRow from './FilterRow/FilterRow';

function BookingsTable() {
  const { groupId } = useParams();
  const { search, hash, pathname } = useLocation();
  const navigate = useNavigate();
  const {
    isFetching,
    isSaving,
    total,
    persons,
    selectedPersons,
    resetAllSelectedPersons,
    selectAllReservation,
    getPersons,
    abortGetPersons,
    resetPersons,
  } = useStore('personsStore');

  const [isSRMInfoModalOpen, , , toggleSRMInfoModalOpen] = useToggle();
  const [isFilterOpen, openFilterRow, , toggleFilterOpen] = useToggle();

  const filterData: GetPersonsArguments = useMemo(() => {
    const query = queryAPI.params as Record<keyof GetPersonsArguments, string>;
    const validFilterData = getValidFilterData(query);

    if (Object.keys(validFilterData).length > 0) {
      const filterOnlyKeys = Object.keys(validFilterData).filter((key: string) => key !== 'limit' && key !== 'page');

      if (filterOnlyKeys.length > 0) {
        openFilterRow();
      }
    }

    return {
      ...validFilterData,
      groupId,
    };
  }, [groupId, search]);

  async function fetchPersons(args: GetPersonsArguments) {
    try {
      await getPersons({
        hms_status_not_in: true,
        ...args,
      });

      /*
       * Ждем пока отрендерится экран с персонами и только потом ищем ноду с айди
       * */
      setTimeout(() => {
        const targetNode = document.getElementById(hash);

        if (targetNode) {
          targetNode?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });

          navigate(`${pathname}${search}`);
        }
      }, 200);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function onFilter(filter: Partial<GetPersonsArguments>) {
    navigate({
      pathname,
      search: queryAPI.generateQuery({
        ...filterData,
        ...filter,
        page: 0, // при фильтрации всегда сбрасывать страницу
      }),
    });
  }

  useEffect(() => {
    resetAllSelectedPersons();

    fetchPersons(filterData);
  }, [filterData]);

  useEffect(
    () => () => {
      resetPersons();
      abortGetPersons();
      resetAllSelectedPersons();
    },
    [],
  );

  function onChangePage(e: Nullable<MouseEvent>, newPage: number) {
    e?.preventDefault();

    navigate({
      pathname,
      search: queryAPI.generateQuery({
        ...filterData,
        page: newPage,
      }),
    });
  }

  function onChangeCount(e: ChangeEvent<HTMLInputElement>) {
    e?.preventDefault();

    const limit = +e.target.value;

    navigate({
      pathname,
      search: queryAPI.generateQuery({
        ...filterData,
        limit: limit > 0 ? limit : undefined,
        page: 0, // при смене количество записей всегда сбрасывать страницу
      }),
    });
  }

  const renderPaginationLabel = useCallback(({ from, to, count }: LabelDisplayedRowsArgs) => {
    if (!from || !to || !count) {
      return null;
    }

    return `${from}-${to} из ${count}`;
  }, []);

  return (
    <BookingsTableRoot>
      <BackdropLoader isLoading={isFetching || isSaving} />

      <BookingsTableContent>
        <BookingsTableWrapper>
          <BookingsTableTable data-testid="person-table" size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="none" width="20">
                  <Tooltip title="Выделить все карточки">
                    <Checkbox
                      checked={selectedPersons.size === persons.length && persons.length > 0}
                      color="primary"
                      onChange={() => selectAllReservation({ withEmail: true })}
                    />
                  </Tooltip>
                </TableCell>

                <TableCell padding="none" width="4%" />

                <TableCell width="8%">HMS Статус</TableCell>

                <TableCell width="15%">Имя</TableCell>

                <TableCell width="15%">Фамилия</TableCell>

                <TableCell width="10%">Дата создания</TableCell>

                <TableCell width="15%">Сроки пребывания</TableCell>

                <TableCell width="25%">
                  <span>SRM Статус</span>

                  <Tooltip title="Подробнее о статусах">
                    <IconButton color="primary" onClick={toggleSRMInfoModalOpen} size="small">
                      <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell width="10%">
                  <Tooltip title="Поиск">
                    <IconButton onClick={toggleFilterOpen}>
                      <FilterIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isFilterOpen && <FilterRow filterData={filterData} onFilter={onFilter} />}

              {!isFetching && (
                <Fragment>
                  {persons.length > 0 &&
                    persons.map((personEntity: Person, i) => (
                      <BookingRow index={i + 1} key={personEntity.id} person={personEntity} />
                    ))}

                  {persons.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">Бронирования отсутствуют</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </BookingsTableTable>
        </BookingsTableWrapper>

        <TablePaginationContent>
          <TablePagination
            component="div"
            count={total}
            labelDisplayedRows={renderPaginationLabel}
            labelRowsPerPage="Записей на странице"
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeCount}
            page={filterData.page}
            rowsPerPage={filterData.limit}
            rowsPerPageOptions={[10, 25, 50, 75, 100]}
          />
        </TablePaginationContent>
      </BookingsTableContent>

      <SRMInfoModal onClose={toggleSRMInfoModalOpen} open={isSRMInfoModalOpen} />
    </BookingsTableRoot>
  );
}

export default memo(observer(BookingsTable));
