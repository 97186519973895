import React, { ChangeEvent, memo, ReactNode } from 'react';
import { v4 as uuid } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import { SelectProps } from '@mui/material/Select';
import { SelectVariants } from '@mui/material/Select/Select';

import { SelectFieldField, SelectFieldResetIcon, SelectFieldRoot } from './SelectField.style';

interface SelectFieldProps extends Omit<SelectProps, 'onChange' | 'variant'> {
  onChange?: (e: ChangeEvent<HTMLInputElement>, child: ReactNode) => void;
  clearable?: boolean;
  variant?: SelectVariants;
}

function SelectField(props: SelectFieldProps) {
  const {
    children,
    label,
    fullWidth = true,
    clearable,
    value,
    name,
    onChange,
    margin,
    variant = 'standard',
    id = uuid(),
    multiple,
    ...rest
  } = props;

  function onReset() {
    onChange?.(
      {
        target: {
          name: name ?? '',
          value: '',
        },
      } as unknown as ChangeEvent<HTMLInputElement>,
      null,
    );
  }

  function onChangeHandler({ target }: ChangeEvent<HTMLInputElement>) {
    onChange?.(
      {
        target: {
          name: name ?? '',
          value: target.value,
        },
      } as unknown as ChangeEvent<HTMLInputElement>,
      null,
    );
  }

  const shouldRenderClearIcon =
    clearable && ((Array.isArray(value) && (value as unknown[]).length > 0) || (typeof value === 'string' && !!value));

  return (
    <SelectFieldRoot fullWidth={fullWidth} margin={margin} variant={variant}>
      {label && <InputLabel htmlFor={id}>{label}</InputLabel>}

      <SelectFieldField
        inputProps={{ id }}
        multiple={multiple}
        onChange={onChangeHandler as SelectProps['onChange']}
        value={value}
        {...rest}
      >
        {children}
      </SelectFieldField>

      {shouldRenderClearIcon && (
        <SelectFieldResetIcon>
          <IconButton onClick={onReset} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </SelectFieldResetIcon>
      )}
    </SelectFieldRoot>
  );
}

export default memo(SelectField);
