import AccordionSummary from '@mui/material/AccordionSummary';
import grey from '@mui/material/colors/grey';
import styled from '@mui/material/styles/styled';

export const BirthplaceSummary = styled(AccordionSummary)`
  min-height: 0;
  height: 48px;

  &.Mui-expanded {
    min-height: 0;
    height: 48px;
  }

  &.MuiAccordionSummary-content {
    align-items: center;
  }
`;

export const BirthplaceSummaryCaption = styled('div')`
  margin-left: 12px;
  color: ${grey['400']};
`;
