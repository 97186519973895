import React, { memo } from 'react';
import AccountIcon from '@mui/icons-material/AccountCircleRounded';

import { User } from '@/entities/user';

import { UserRowRoot, UserRowUserDetach, UserRowUserName } from './UserRaw.style';

interface UserRowProps {
  user: User;
  onDetach: (user: User) => void;
}

function UserRaw(props: UserRowProps) {
  const { user, onDetach } = props;

  function onDetachHandler() {
    onDetach(user);
  }

  return (
    <UserRowRoot>
      <UserRowUserName>
        <AccountIcon fontSize="small" />

        <span>{user.name}</span>
      </UserRowUserName>

      <UserRowUserDetach className="userRowDetach" onClick={onDetachHandler} size="small">
        Открепить
      </UserRowUserDetach>
    </UserRowRoot>
  );
}

export default memo(UserRaw);
