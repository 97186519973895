import { PROFILE_ROUTES_MAP } from './Profile.constants';

export function getActiveTabIndex(pathname: string) {
  let index = 0;

  PROFILE_ROUTES_MAP.forEach((value: string, key: number) => {
    if (pathname.includes(value)) {
      index = key;
    }
  });

  return index;
}
