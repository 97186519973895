import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import logo from '@/assets/g1_w.png';
import { ROUTE_MAIN, ROUTE_PROFILE } from '@/constants';
import TechSupport from '@/shared/TechSupport/TechSupport';
import { useStore } from '@/stores/StoreProvider';

import {
  HeaderLogo,
  HeaderRoot,
  HeaderUser,
  HeaderUserAccountEmail,
  HeaderUserAccountIcon,
  HeaderUserInfo,
  HeaderUserInfoLink,
} from './Header.style';

function Header() {
  const { userData, signOut } = useStore('signInStore');

  if (!userData) {
    return null;
  }

  const { fname, lname, email, is_admin } = userData;

  function renderUserName() {
    return (
      <HeaderUserInfo>
        <HeaderUserAccountIcon />

        <span>{fname}&nbsp;</span>

        <span>{lname} </span>

        <HeaderUserAccountEmail>({email})</HeaderUserAccountEmail>
      </HeaderUserInfo>
    );
  }

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <HeaderRoot>
          <HeaderLogo href={ROUTE_MAIN}>
            <img alt="emis logo" height="54" src={logo} />
          </HeaderLogo>

          <div>
            <TechSupport />
          </div>

          <HeaderUser>
            {is_admin && renderUserName()}

            {/*

              тут мы используем тэг "a" вместо Link, потому что хидер может использоваться на страницах интеграции (например, "/hms/person/new?hp_id=368321&stay_till=11-feb-2024"),
              а они находятся вне контекста роутера, потому что роутер переписывает историю и тогда не будет работать "windows.close()"

            */}
            {!is_admin && <HeaderUserInfoLink href={ROUTE_PROFILE}>{renderUserName()}</HeaderUserInfoLink>}

            <Button color="inherit" data-testid="logout-button" onClick={signOut}>
              Выход
            </Button>
          </HeaderUser>
        </HeaderRoot>
      </Container>
    </AppBar>
  );
}

export default memo(observer(Header));
