export enum KSDocTemplateCategory {
  Reject = 'ks_reject',
  RejectByGuest = 'ks_reject_guest',
  RejectByHotel = 'ks_reject_hotel',
  Payments = 'ks_payments',
}

export class KSDocTemplateModel {
  id!: number;
  name!: string;
  category!: KSDocTemplateCategory | string;
}

export class KSDocTemplate extends KSDocTemplateModel {
  constructor(ksDocTemplate: KSDocTemplateModel) {
    super();

    Object.keys(ksDocTemplate).forEach((key) => {
      this[key] = ksDocTemplate[key];
    });
  }
}
