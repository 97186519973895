import styled from '@mui/material/styles/styled';

import { EMIS_THEME } from '@/App.style';

export const DocInfoRoot = styled('div')`
  ${EMIS_THEME.breakpoints.up('md')} {
    height: 100%;
  }
`;

export const DocInfoControls = styled('div')`
  margin-top: 60px;
`;
