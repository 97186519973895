import styled from '@mui/material/styles/styled';
import Tabs from '@mui/material/Tabs';

export const OperatorPanelContent = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
`;

export const OperatorTabs = styled(Tabs)`
  flex-grow: 1;
`;

export const OperatorButtons = styled('div')`
  margin-right: 16px;
  flex-shrink: 0;
  & button {
    margin-left: 12px;
  }
`;
