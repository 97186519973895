import React, { memo } from 'react';

import { TechSupportContacts, TechSupportRoot } from './TechSupport.style';

function TechSupport() {
  return (
    <TechSupportRoot>
      <div>Техническая поддержка</div>

      <TechSupportContacts>
        <a rel="noopener noreferrer">XXX</a>
      </TechSupportContacts>

      <div>
        <a href="mailto:support@g1sw.ru">support@g1sw.ru</a>
      </div>
    </TechSupportRoot>
  );
}

export default memo(TechSupport);
