import React, { CSSProperties, memo, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import Header from './Header/Header';
import {
  RouteBackdrop,
  RouteBackdropContent,
  RouteBreadcrumbs,
  RouteContainer,
  RouteContainerInner,
  RouteContentBox,
  RouteContentBoxInner,
  RouteContentBoxMain,
  RouteContentBoxWrapper,
  RouteContentHeader,
  RouteContentPaper,
  RouteContentPaperInner,
  RouteContentSubHeader,
  RouteContentWrapper,
  RouteRoot,
  RouteWrapper,
} from './RouteConstructor.style';

type Crumbs = Partial<{ path: string; title: string }> | string;

interface RouteConstructorProps {
  breadcrumbs?: ReactNode;
  header?: ReactNode;
  subHeader?: ReactNode;
  content?: ReactNode;
  contentSettings?: CSSProperties;
  isLoading?: boolean;
}

function RouteConstructor(props: RouteConstructorProps) {
  const {
    header: RouteHeader,
    breadcrumbs,
    content: RouteContent,
    contentSettings,
    subHeader: RouteSubHeader,
    isLoading = false,
  } = props;

  const { isGlobalFetching } = useStore('signInStore');

  function renderRouteHeader(): ReactNode {
    return <RouteContentHeader>{RouteHeader}</RouteContentHeader>;
  }

  function renderRouteContent(): ReactNode {
    return (
      <RouteContentBoxWrapper>
        <RouteContentBoxMain style={contentSettings}>{RouteContent}</RouteContentBoxMain>
      </RouteContentBoxWrapper>
    );
  }

  function renderRouteSubHeader(): ReactNode {
    return <RouteContentSubHeader>{RouteSubHeader}</RouteContentSubHeader>;
  }

  return (
    <RouteRoot>
      {/*
      временно отключаем индикатор не активности пока не найдем лучшее решение
      {isSignedIn && <InactivityHandler />}*/}

      <div>
        <Header />
      </div>

      <RouteWrapper>
        <RouteContainer disableGutters maxWidth={false}>
          <RouteContainerInner>
            {breadcrumbs !== null && <RouteBreadcrumbs>{breadcrumbs}</RouteBreadcrumbs>}

            <RouteContentWrapper>
              <RouteContentPaper>
                <RouteContentPaperInner>
                  {RouteHeader && renderRouteHeader()}

                  <RouteContentBox>
                    <BackdropLoader isLoading={isLoading} />

                    <RouteContentBoxInner>
                      {!!RouteSubHeader && renderRouteSubHeader()}

                      {renderRouteContent()}
                    </RouteContentBoxInner>
                  </RouteContentBox>
                </RouteContentPaperInner>
              </RouteContentPaper>
            </RouteContentWrapper>
          </RouteContainerInner>
        </RouteContainer>
      </RouteWrapper>

      <RouteBackdrop open={isGlobalFetching}>
        <RouteBackdropContent>
          <Typography gutterBottom variant="h6">
            Данные формируются. Пожалуйста, подождите.
          </Typography>

          <CircularProgress color="inherit" />
        </RouteBackdropContent>
      </RouteBackdrop>
    </RouteRoot>
  );
}

export type { Crumbs };
export default memo(observer(RouteConstructor));
