import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { Company } from '@/entities/company';
import { EulaCard } from '@/entities/eualCard';
import { useToggle } from '@/hooks/useToggle/useToggle';
import EulaCardRow from '@/shared/Settings/EulaCards/EulaCardRaw/EulaCardRaw';
import {
  EulaCardsContent,
  EulaCardsHeader,
  EulaCardsList,
  EulaCardsRoot,
} from '@/shared/Settings/EulaCards/EulaCards.style';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import EulaCardModal from './EulaCardModal/EulaCardModal';

interface EulaCardsProps {
  companyId: number;
}

function EulaCards(props: EulaCardsProps) {
  const { companyId } = props;
  const { isFetchingCompanyById, companyByIdMap, getCompanyById } = useStore('companiesStore');

  const [isAddCardOpen, openAddCardModal, closeAddCardModal] = useToggle();
  const [eulaCards, setEulaCards] = useState<EulaCard[]>([]);

  const company: Company | undefined = companyByIdMap.get(companyId);

  async function fetchCompanyById() {
    try {
      await getCompanyById(companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (company) {
      setEulaCards(company.eulas ?? []);
    } else {
      fetchCompanyById();
    }
  }, [JSON.stringify(company)]);

  return (
    <EulaCardsRoot>
      <BackdropLoader isLoading={isFetchingCompanyById} />

      <EulaCardsHeader>
        <Typography>Регистрационная карта</Typography>

        <Button color="primary" onClick={openAddCardModal} size="small" variant="contained">
          Добавить карту
        </Button>
      </EulaCardsHeader>

      <EulaCardsContent>
        <EulaCardsList>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Наименование</TableCell>

                <TableCell width="20" />
              </TableRow>
            </TableHead>

            <TableBody>
              {eulaCards.length > 0 &&
                eulaCards.map((card: EulaCard) => (
                  <EulaCardRow companyId={companyId} eulaCard={card} key={card.id} onAfterChange={fetchCompanyById} />
                ))}

              {eulaCards.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography align="center">Регистрационные карты отсутствуют</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <EulaCardModal
            companyId={companyId}
            onAfterChange={fetchCompanyById}
            onClose={closeAddCardModal}
            open={isAddCardOpen}
          />
        </EulaCardsList>
      </EulaCardsContent>
    </EulaCardsRoot>
  );
}

export default memo(observer(EulaCards));
