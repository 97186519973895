import React, { memo } from 'react';
import { useNavigate } from 'react-router';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { ROUTE_COMPANY_DATA_PATH } from '@/constants';
import { useMount } from '@/hooks/useMount/useMount';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import { RetentionRoot } from './Retention.styles';

function Retention() {
  const { deletePersonsByToken } = useStore('reportsStore');
  const navigate = useNavigate();
  const token = queryAPI.getKey('token');

  async function deletePersons() {
    if (!token) {
      notificationAPI.error('Токен не найден');

      return;
    }

    try {
      await deletePersonsByToken(token);

      navigate(ROUTE_COMPANY_DATA_PATH);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useMount(deletePersons);

  return (
    <RetentionRoot>
      <BackdropLoader isLoading />
    </RetentionRoot>
  );
}

export default memo(Retention);
