import React, { PureComponent } from 'react';

import { DEPRECATED_DEFAULT_DATE } from '@/constants';
import { Address, AddressLevel } from '@/entities/address';
import { Company } from '@/entities/company';
import { PersonDetail } from '@/stores/PersonsStore';

import { PRINT_STYLES } from './OutcomingReport.constants';

interface ReportToPrintContentProps {
  values: Nullable<PersonDetail>;
  company?: Nullable<Company>;
  urAddress?: Nullable<Address>;
  factAddress?: Nullable<Address>;
}

// библиотека `react-to-print` работает только с классами, поэтому отключаем тут правило линтера
// eslint-disable-next-line react/prefer-stateless-function
class OutcomingReport extends PureComponent<ReportToPrintContentProps> {
  render() {
    const { values, company, urAddress, factAddress } = this.props;

    if (!values) {
      return null;
    }

    const { fname, lname, mname, birth_date, birth_date_part, stay_info, arrival_form_number } = values;

    const fio = `${lname} ${fname} ${mname}`;

    const dateOfBirth = birth_date ? new Date(birth_date) : null;

    const stayTillDate =
      stay_info?.stay_till && stay_info.stay_till !== DEPRECATED_DEFAULT_DATE ? new Date(stay_info.stay_till) : null;

    // собираем данные для ЮРИДИЧЕСКОГО адреса отеля
    // const urRegion = urAddress?.parents?.find((address: Address) =>
    //   address.ao_level === AddressLevel.Region ? address : undefined
    // );
    // const urRegionValue = urRegion ? `${urRegion.name}` : null;
    //
    // const urPlace = urAddress?.parents?.find((address: Address) =>
    //   address.ao_level === AddressLevel.Area ? address : undefined
    // );
    // const urPlaceValue = urPlace ? `${urPlace.short_name} ${urPlace.name}` : null;
    //
    // const urCity = urAddress?.parents?.find((address: Address) =>
    //   address.ao_level === AddressLevel.City ? address : undefined
    // );
    // const urCityValue = urCity ? `${urCity.short_name} ${urCity.name}` : null;
    //
    // const urStreetValue = urAddress ? `${urAddress?.short_name} ${urAddress?.name}` : null;

    // собираем данные для ФАКТИЧЕСКОГО адреса отеля
    const factRegion = factAddress?.parents?.find((address: Address) =>
      address.ao_level === AddressLevel.Region ? address : undefined,
    );
    const factRegionValue = factRegion ? `${factRegion.name}` : null;

    const factPlace = factAddress?.parents?.find((address: Address) =>
      address.ao_level === AddressLevel.Area ? address : undefined,
    );
    const factPlaceValue = factPlace ? `${factPlace.short_name} ${factPlace.name}` : null;

    const factCity = factAddress?.parents?.find((address: Address) =>
      address.ao_level === AddressLevel.City ? address : undefined,
    );

    const factCityValue = factCity ? `${factCity.short_name} ${factCity.name}` : null;

    const factStreetValue = factAddress ? `${factAddress?.short_name} ${factAddress?.name}` : null;

    const face = company?.faces?.[0] ?? null;

    const fullAddressValue = `${urAddress?.full_name} ${company?.ur_housing ? `Д. ${company?.ur_housing}, ` : ''}${
      company?.ur_housing2 ? `КОР. ${company?.ur_housing2}, ` : ''
    }${company?.housing3 ? `СТР. ${company?.housing3}` : ''} `;

    return (
      <div>
        {/* СТРАНИЦА 1 */}

        <div style={PRINT_STYLES.page}>
          {/* памятка в верху справа */}

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontSize: 11,
            }}
          >
            <div>
              <span>Приложение №1</span>

              <br />

              <span>к приказу МВД России</span>

              <br />

              <span>от 14.09.2020 № 641</span>
            </div>
          </div>

          {/* заголовки */}

          <h1
            style={{
              ...PRINT_STYLES.title1,
              margin: '6px 0 4px',
            }}
          >
            ФОРМА
          </h1>

          <h2 style={{ ...PRINT_STYLES.title1, ...PRINT_STYLES.mainTitle }}>
            <span>УВЕДОМЛЕНИЕ ОБ УБЫТИИ ИНОСТРАННОГО ГРАЖДАНИНА</span>

            <br />

            <span>
              <span>ИЛИ ЛИЦА БЕЗ ГРАЖДАНСТВА ИЗ МЕСТА ПРЕБЫВАНИЯ </span>

              <span style={PRINT_STYLES.bottomLineCell}>{arrival_form_number}</span>
            </span>
          </h2>

          {/* сведения о лице, подлежащем снятию с учета по месту пребывания */}

          <h2 style={PRINT_STYLES.title2}>1. Сведения о лице, подлежащем снятию с учета по месту пребывания:</h2>

          <div style={PRINT_STYLES.pageContent}>
            {/* фамилия */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 155,
                }}
              >
                Фамилия (при наличии)
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 23 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {lname[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* имя, отчество */}

            <div
              style={{
                ...PRINT_STYLES.line,
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 155,
                }}
              >
                <span>имя (при наличии)</span>

                <br />

                <span>отчество (при наличии)</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 23 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {`${fname} ${mname}`[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* дата рождения, дата убытия */}

            <div style={PRINT_STYLES.line}>
              {/* дата рождения */}

              <div style={PRINT_STYLES.cell}>Дата рождения:</div>

              <div style={PRINT_STYLES.cell}>число</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 2 }, (_, i: number) => {
                  const date = dateOfBirth?.getDate() ?? null;

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div style={PRINT_STYLES.cell}>месяц</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 2 }, (_, i: number) => {
                  // если dateOfBirth не указан, проверяем наличие birth_date_part
                  // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                  // на символы 3 и 4 мы берем значение месяца
                  let month: Nullable<number> = null;

                  if (dateOfBirth) {
                    month = dateOfBirth.getMonth();
                  } else if (birth_date_part) {
                    month = parseInt(`${birth_date_part[3]}${birth_date_part[4]}`) - 1;
                  }

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div style={PRINT_STYLES.cell}>год</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 4 }, (_, i: number) => {
                  // если dateOfBirth не указан, проверяем наличие birth_date_part
                  // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                  // на символы 6, 7, 8, и 9 мы берем значение года
                  let year: Nullable<number> = null;

                  if (dateOfBirth) {
                    year = dateOfBirth.getFullYear();
                  } else if (birth_date_part) {
                    year = parseInt(
                      `${birth_date_part[6]}${birth_date_part[7]}${birth_date_part[8]}${birth_date_part[9]}`,
                    );
                  }

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {year ? `${year}`[i] : ''}
                    </div>
                  );
                })}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              {/* дата убытия*/}

              <div style={PRINT_STYLES.cell}>Дата убытия:</div>

              <div style={PRINT_STYLES.cell}>число</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 2 }, (_, i: number) => {
                  const date = stayTillDate?.getDate();

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div style={PRINT_STYLES.cell}>месяц</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 2 }, (_, i: number) => {
                  const month = stayTillDate?.getMonth();

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                    </div>
                  );
                })}
              </div>

              <div style={PRINT_STYLES.cell}>год</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 4 }, (_, i: number) => {
                  const year = stayTillDate?.getFullYear();

                  return (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {year ? `${year}`[i] : ''}
                    </div>
                  );
                })}
              </div>
            </div>

            {/* сведения о месте пребывания */}

            <h2
              style={{
                ...PRINT_STYLES.title2,
                margin: '3px 0',
              }}
            >
              2. Сведения о месте пребывания:
            </h2>

            {/* область */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'flex-start',
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 155,
                  alignItems: 'center',
                }}
              >
                <span>Область, край, республика</span>

                <br />

                <span>автономный округ (область)</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                <div style={{ display: 'flex' }}>
                  {Array.from({ length: 23 }, (_, i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {factRegionValue?.[i]}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* район */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'center',
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 50,
                }}
              >
                <span>Район</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 28 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factPlaceValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* город */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'center',
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 113,
                }}
              >
                <span>Город или другой</span>

                <br />

                <span>населенный пункт</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factCityValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* улица */}

            <div
              style={{
                ...PRINT_STYLES.line,
                alignItems: 'center',
                marginBottom: 5,
              }}
            >
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 50,
                }}
              >
                <span>Улица</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 28 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {factStreetValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* дом, строение */}

            <div style={PRINT_STYLES.line}>
              <div style={PRINT_STYLES.cell}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    width: 29,
                  }}
                >
                  Дом
                </div>

                {Array.from({ length: 7 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing[i]}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 17,
                }}
              >
                Корпус
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 3 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing2[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Строение</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 7 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.housing3?.[i]}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>Квартира</div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.flat?.[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* сведения о принимающей стороне */}

            <h2
              style={{
                ...PRINT_STYLES.title2,
                margin: '3px 0',
              }}
            >
              3. Сведения о принимающей стороне
            </h2>

            {/* фамилия */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 92,
                }}
              >
                Фамилия
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 26 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_lname[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* имя, отчество */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 92,
                }}
              >
                <span>Имя, отчество</span>

                <br />

                <span>(при наличии)</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 26 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? `${face?.face_fname} ${face?.face_mname}`[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* документ, удостоверяющий личность */}

            <div
              style={{
                ...PRINT_STYLES.line,
                justifyContent: 'center',
                margin: '5px 0 0',
              }}
            >
              <div style={PRINT_STYLES.cell}>Документ, удостоверяющий личность:</div>
            </div>

            {/* вид, серия, номер */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 50,
                }}
              >
                вид
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 10 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {/* все по умолчанию ставится паспорт */}

                    {'паспорт'[i]}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 2,
                }}
              >
                серия
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 2,
                }}
              >
                {Array.from({ length: 4 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_doc_serial[i] : ''}
                  </div>
                ))}
              </div>

              <div style={PRINT_STYLES.cell}>№</div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginRight: 2,
                }}
              >
                {Array.from({ length: 11 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {face ? face?.face_doc_number[i] : ''}
                  </div>
                ))}
              </div>
            </div>

            {/* дата выдачи и срок действия */}

            <div
              style={{
                display: 'flex',
              }}
            >
              <div style={{ width: '50%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    margin: '5px 0',
                  }}
                >
                  Дата выдачи:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      width: 50,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      const date = face?.face_doc_issue_date ? new Date(face?.face_doc_issue_date).getDate() : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div style={PRINT_STYLES.cell}>месяц</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => {
                      let month: Nullable<number> = null;

                      if (face?.face_doc_issue_date) {
                        month = new Date(face?.face_doc_issue_date).getMonth();
                      }

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>

                  <div style={PRINT_STYLES.cell}>год</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 4 }, (_, i: number) => {
                      const date = face?.face_doc_issue_date ? new Date(face?.face_doc_issue_date) : null;

                      return (
                        <div key={i} style={PRINT_STYLES.charSquare}>
                          {date ? `${date.getFullYear()}`[i] : ''}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div style={{ width: '50%' }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    margin: '5px 0',
                  }}
                >
                  Срок действия до:
                </div>

                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      width: 48,
                    }}
                  >
                    число
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => (
                      // const date = face?.face_doc_valid_date ? new Date(face?.face_doc_valid_date).getDate() : null;

                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {/* {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''} */}
                      </div>
                    ))}
                  </div>

                  <div style={PRINT_STYLES.cell}>месяц</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => (
                      // let month = null;

                      // if (face?.face_doc_valid_date) {
                      //   month = new Date(face?.face_doc_valid_date).getMonth();
                      // }

                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {/* {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}*/}
                      </div>
                    ))}
                  </div>

                  <div style={PRINT_STYLES.cell}>год</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 4 }, (_, i: number) => (
                      // const date = face?.face_doc_valid_date ? new Date(face?.face_doc_valid_date) : null;

                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {/* {date ? `${date.getFullYear()}`[i] : ''} */}
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    textAlign: 'center',
                    marginLeft: -40,
                    fontSize: 12,
                    whiteSpace: 'nowrap',
                  }}
                >
                  (заполняется в случае ограничения срока действия документа)
                </div>
              </div>
            </div>

            {/* телефон */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  textAlign: 'center',
                  width: 100,
                }}
              >
                <span>Телефон</span>

                <br />

                <span>(при наличии)</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                <div style={{ padding: '0 10px' }}>+7</div>

                {Array.from({ length: 10 }, (_, i: number) => i).map((i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {`${company?.tel ?? ''}`[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* линия отрыва */}

            <div>
              <div
                style={{
                  border: '2px dashed black',
                  margin: '5px 0',
                }}
              />

              <div style={{ marginTop: -15, textAlign: 'center' }}>
                <span
                  style={{
                    backgroundColor: 'white',
                    padding: '0 10px',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}
                >
                  Линия отрыва
                </span>
              </div>
            </div>

            {/* подтверждение на получение уведомления об убытии */}

            <h2 style={{ ...PRINT_STYLES.title1, ...PRINT_STYLES.sectionTitle }}>
              Настоящим подтверждается, что получено уведомление об убытии из места пребывания иностранного гражданина
              или лица без гражданства
            </h2>

            {/* блок с фио */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 155,
                }}
              >
                <span>Фамилия (при наличии),</span>

                <br />

                <span>имя (при наличии),</span>

                <br />

                <span>отчество (при наличии)</span>
              </div>

              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 23 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {fio[i]}
                    </div>
                  ))}
                </div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 23 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {fio[23 + i]}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* блок с датой рождения */}

            <div
              style={{
                ...PRINT_STYLES.line,
                justifyContent: 'space-between',
              }}
            >
              <div style={PRINT_STYLES.line}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    width: 119,
                  }}
                >
                  Дата рождения:
                </div>

                <div style={PRINT_STYLES.cell}>число</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => {
                    const date = dateOfBirth?.getDate() ?? null;

                    return (
                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {date ? `${date && date < 10 ? 0 : ''}${date}`[i] : ''}
                      </div>
                    );
                  })}
                </div>

                <div style={PRINT_STYLES.cell}>месяц</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => {
                    // если dateOfBirth не указан, проверяем наличие birth_date_part
                    // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                    // на символы 3 и 4 мы берем значение месяца
                    let month: Nullable<number> = null;

                    if (dateOfBirth) {
                      month = dateOfBirth.getMonth();
                    } else if (birth_date_part) {
                      month = parseInt(`${birth_date_part[3]}${birth_date_part[4]}`) - 1;
                    }

                    return (
                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}`[i] : ''}
                      </div>
                    );
                  })}
                </div>

                <div style={PRINT_STYLES.cell}>год</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 4 }, (_, i: number) => i).map((i: number) => {
                    // если dateOfBirth не указан, проверяем наличие birth_date_part
                    // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                    // на символы 6, 7, 8, и 9 мы берем значение года
                    let year: Nullable<number> = null;

                    if (dateOfBirth) {
                      year = dateOfBirth.getFullYear();
                    } else if (birth_date_part) {
                      year = parseInt(
                        `${birth_date_part[6]}${birth_date_part[7]}${birth_date_part[8]}${birth_date_part[9]}`,
                      );
                    }

                    return (
                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {year ? `${year}`[i] : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div
              style={{
                ...PRINT_STYLES.line,
                textAlign: 'center',
                justifyContent: 'center',
                padding: '3px 75px',
                fontSize: 12,
              }}
            >
              <span>
                Должностное лицо, принявшее уведомление об убытии иностранного гражданина или лица без гражданства из
                места пребывания
              </span>
            </div>

            {/* должностное лицо */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 93,
                }}
              >
                Должность
              </div>

              <div style={PRINT_STYLES.line}>
                {Array.from({ length: 26 }, (_, i: number) => i).map((i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            <div
              style={{
                ...PRINT_STYLES.line,
                marginLeft: 9,
              }}
            >
              {Array.from({ length: 30 }, (_, i: number) => i).map((i: number) => (
                <div key={i} style={PRINT_STYLES.charSquare} />
              ))}
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 93,
                }}
              >
                Фамилия
              </div>

              <div style={PRINT_STYLES.line}>
                {Array.from({ length: 26 }, (_, i: number) => i).map((i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 93,
                }}
              >
                <div>
                  <span>Имя,</span>

                  <br />

                  <span>отчество</span>

                  <br />

                  <span>(при наличии)</span>
                </div>
              </div>

              <div style={PRINT_STYLES.line}>
                {Array.from({ length: 26 }, (_, i: number) => i).map((i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            <div
              style={{
                ...PRINT_STYLES.cell,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginRight: 2,
              }}
            >
              <div style={PRINT_STYLES.line}>
                <div style={PRINT_STYLES.cell}>число</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>

                <div style={PRINT_STYLES.cell}>месяц</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>

                <div style={PRINT_STYLES.cell}>год</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 4 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>
              </div>

              <div
                style={{
                  marginRight: 15,
                }}
              >
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    width: 215,
                    height: 45,
                    border: '1px solid black',
                  }}
                />

                <div
                  style={{
                    textAlign: 'center',
                  }}
                >
                  Подпись должностного лица
                </div>
              </div>
            </div>

            <h2
              style={{
                ...PRINT_STYLES.title2,
                fontSize: 9,
                textAlign: 'center',
                margin: '10px 0 0',
              }}
            >
              Отрывная часть уведомления об убытии иностранного гражданина или лица без гражданства из места пребывания
            </h2>

            {/* /!**/}

            {/*    пример ячейки с галочкой */}

            {/*  *!/ */}

            {/* <div style={PRINT_STYLES.line}> */}

            {/*  <div style={PRINT_STYLES.charSquare}>&#10004;</div> */}

            {/* </div> */}
          </div>
        </div>

        <div style={PRINT_STYLES.pageBreak} />

        {/* СТРАНИЦА 2 */}

        <div style={PRINT_STYLES.page}>
          <div style={PRINT_STYLES.pageContent}>
            {/* памятка в верху справа */}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                fontSize: 12,
              }}
            >
              <div>Оборотная сторона</div>
            </div>

            {/* организация */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  textAlign: 'center',
                  width: 111,
                }}
              >
                <span>
                  Наименование
                  <br />
                  организации
                </span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.ur_name[i]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 30 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.ur_name[i + 25]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 15 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.ur_name[i + 55]}
                  </div>
                ))}
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  textAlign: 'center',
                  marginLeft: 8,
                }}
              >
                <span>
                  ИНН
                  <br />
                  организации
                </span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 11 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {company?.inn[i]}
                  </div>
                ))}
              </div>
            </div>

            {/* Адрес организации */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  textAlign: 'center',
                  width: 111,
                }}
              >
                <span>
                  Адрес
                  <br />
                  организации
                </span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {fullAddressValue?.[i]}
                  </div>
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 30 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare}>
                    {fullAddressValue?.[i + 25]}
                  </div>
                ))}
              </div>
            </div>

            {/* документ, подтверждающий полномочия */}

            <h2
              style={{
                ...PRINT_STYLES.title2,
                textAlign: 'left',
                paddingLeft: '25px',
                fontSize: 9,
              }}
            >
              Документ, подтверждающий полномочия представителя организации
            </h2>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                marginRight: 18,
              }}
            >
              <div>
                <div style={PRINT_STYLES.line}>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      textAlign: 'center',
                      width: 92,
                    }}
                  >
                    <span>
                      Вид
                      <br />
                      документа
                    </span>
                  </div>

                  <div style={PRINT_STYLES.line}>
                    {Array.from({ length: 17 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {face ? face.face_pow_type?.[i] : ''}
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    ...PRINT_STYLES.line,

                    marginLeft: 8,
                  }}
                >
                  {Array.from({ length: 21 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare}>
                      {face ? face.face_pow_type?.[i + 17] : ''}
                    </div>
                  ))}
                </div>

                <div style={PRINT_STYLES.line}>
                  <div style={{ ...PRINT_STYLES.cell, width: 92 }}>
                    <div style={{ textAlign: 'center' }}>
                      <span>
                        серия
                        <br />
                        (при наличии)
                      </span>
                    </div>
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 5 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {face ? face.face_pow_serial?.[i] : ''}
                      </div>
                    ))}
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    <div style={{ textAlign: 'center' }}>
                      <span>
                        №<br />
                        (при наличии)
                      </span>
                    </div>
                  </div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 8 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {face ? face.face_pow_number?.[i] : ''}
                      </div>
                    ))}
                  </div>
                </div>

                <div style={PRINT_STYLES.line}>
                  <div style={{ ...PRINT_STYLES.cell, width: 105 }}>Дата выдачи:</div>

                  <div style={PRINT_STYLES.cell}>число</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare} />
                    ))}
                  </div>

                  <div style={PRINT_STYLES.cell}>месяц</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare} />
                    ))}
                  </div>

                  <div style={PRINT_STYLES.cell}>год</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 4 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare} />
                    ))}
                  </div>
                </div>

                <div style={PRINT_STYLES.line}>
                  <div style={{ ...PRINT_STYLES.cell, width: 105 }}>Срок действия до:</div>

                  <div style={PRINT_STYLES.cell}>число</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare} />
                    ))}
                  </div>

                  <div style={PRINT_STYLES.cell}>месяц</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare} />
                    ))}
                  </div>

                  <div style={PRINT_STYLES.cell}>год</div>

                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 4 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare} />
                    ))}
                  </div>
                </div>

                <div style={PRINT_STYLES.line}>
                  <div style={PRINT_STYLES.cell}>
                    {Array.from({ length: 10 }, (_, i: number) => i).map((i: number) => (
                      <div key={i} style={PRINT_STYLES.charSquare}>
                        {'Бессрочный'[i]}
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    ...PRINT_STYLES.line,
                    margin: '5px 0',
                    flexGrow: 1,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      flexGrow: 1,
                    }}
                  >
                    <div
                      style={{
                        ...PRINT_STYLES.cell,
                        justifyContent: 'center',
                        flexGrow: 1,
                      }}
                    >
                      <div
                        style={{
                          width: 185,
                          textAlign: 'center',
                        }}
                      >
                        <b>
                          Достоверность представленных сведений об убытии иностранного гражданина или лица без
                          гражданства из места пребывания подтверждаю
                        </b>
                      </div>
                    </div>

                    <div style={PRINT_STYLES.cell}>
                      <div>
                        <div
                          style={{
                            ...PRINT_STYLES.cell,
                            width: 175,
                            height: 80,
                            border: '1px solid black',
                          }}
                        />

                        <div
                          style={{
                            textAlign: 'center',
                            fontSize: 9,
                          }}
                        >
                          <span>Подпись</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginTop: 2,
                }}
              >
                <div>
                  <div
                    style={{
                      ...PRINT_STYLES.cell,
                      marginTop: 2,
                      width: 175,
                      height: 223,
                      border: '1px solid black',
                    }}
                  />

                  <div
                    style={{
                      textAlign: 'center',
                      fontSize: 9,
                    }}
                  >
                    <span>Печать организации</span>

                    <br />

                    <span>(при наличии)</span>
                  </div>
                </div>
              </div>
            </div>

            <h2
              style={{
                ...PRINT_STYLES.title2,
                fontSize: 9,
              }}
            >
              Должностное лицо, принявшее уведомление об убытии иностранного гражданина или лица без гражданства из
              места пребывания
            </h2>

            {/* должностное лицо */}

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>Должность</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  marginLeft: 6,
                }}
              >
                {Array.from({ length: 30 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>Фамилия</span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div
                style={{
                  ...PRINT_STYLES.cell,
                  width: 111,
                }}
              >
                <span>
                  <span>Имя,</span>

                  <br />

                  <span>отчество</span>

                  <br />

                  <span>(при наличии)</span>
                </span>
              </div>

              <div style={PRINT_STYLES.cell}>
                {Array.from({ length: 25 }, (_, i: number) => (
                  <div key={i} style={PRINT_STYLES.charSquare} />
                ))}
              </div>
            </div>

            <div
              style={{
                ...PRINT_STYLES.cell,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginRight: 10,
              }}
            >
              <div style={PRINT_STYLES.line}>
                <div style={PRINT_STYLES.cell}>число</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>

                <div style={PRINT_STYLES.cell}>месяц</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 2 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>

                <div style={PRINT_STYLES.cell}>год</div>

                <div style={PRINT_STYLES.cell}>
                  {Array.from({ length: 4 }, (_, i: number) => i).map((i: number) => (
                    <div key={i} style={PRINT_STYLES.charSquare} />
                  ))}
                </div>
              </div>

              <div style={{ marginRight: 8 }}>
                <div
                  style={{
                    ...PRINT_STYLES.cell,
                    width: 215,
                    height: 75,
                    border: '1px solid black',
                  }}
                />

                <div
                  style={{
                    textAlign: 'center',
                    fontSize: 9,
                  }}
                >
                  <span>Подпись должностного лица</span>
                </div>
              </div>
            </div>

            {/* линия отрыва */}

            <div>
              <div
                style={{
                  border: '2px dashed black',
                  margin: '10px 0',
                }}
              />

              <div style={{ marginTop: -20, textAlign: 'center' }}>
                <span
                  style={{
                    backgroundColor: 'white',
                    padding: '0 10px',
                    fontSize: 12,
                  }}
                >
                  Линия отрыва
                </span>
              </div>
            </div>
          </div>
        </div>

        <div style={PRINT_STYLES.pageBreak} />
      </div>
    );
  }
}

export { OutcomingReport };
