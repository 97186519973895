import createTheme from '@mui/material/styles/createTheme';

import { EMIS_BRAND_COLOR } from '@/ui-kit/global-styles';

export const EMIS_THEME = createTheme({
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
  palette: {
    // переопределяем основной цвет приложения (кнопки, лоадеры, хидер, ативные иконки)
    primary: {
      main: EMIS_BRAND_COLOR,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: 14,
        },
      },
    },
    // переопределяем высоту табов
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 48,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: '0 8px',
        },
      },
    },
  },
});
