import React, { memo } from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

import { ContentModal } from '@/ui-kit/components';

interface DifferentDocNumberModalProps {
  open: boolean;
  onConfirm: () => void;
  onCreateNew: () => void;
}

function DifferentDocNumberModal(props: DifferentDocNumberModalProps) {
  const { open, onConfirm, onCreateNew } = props;

  return (
    <ContentModal onClose={() => null} open={open}>
      <ContentModal.Header align="center">Внимание!</ContentModal.Header>

      <ContentModal.Body>
        <DialogContentText>Отсканированные данные отличаются от текущих!</DialogContentText>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onConfirm} type="reset" variant="outlined">
          Продолжить
        </Button>

        <Button color="success" onClick={onCreateNew} type="button" variant="contained">
          Создать новую карточку
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(DifferentDocNumberModal);
