import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { notificationAPI } from '@/api/notificationAPI';
import { Company } from '@/entities/company';
import { Device } from '@/entities/device';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ConfirmModal } from '@/ui-kit/components';

import AboutDevice from './AboutDevice/AboutDevice';
import ConfirmDeactivateModal from './ConfirmDeactivateModal/ConfirmDeactivateModal';
import Users from './Users/Users';

interface DeviceDetailsProps {
  isOpen: boolean;
  device: Device;
  isAdmin?: boolean;
}

function DeviceDetails(props: DeviceDetailsProps) {
  const { isOpen, device, isAdmin = false } = props;
  const { isSaving, activate, getDevices, deleteDeviceById } = useStore('devicesStore');
  const { getCompanyById } = useStore('companiesStore');

  const [isDeactivateConfirmOpen, , , toggleDeactivate] = useToggle();
  const [isConfirmDeleteOpen, , closeConfirmDelete, toggleDelete] = useToggle();

  const [company, setCompany] = useState<Nullable<Company>>(null);

  async function fetchCompany() {
    if (!company && device.companyId) {
      const companyById = await getCompanyById(device.companyId);

      if (companyById) {
        setCompany(companyById);
      }
    }
  }

  async function changeActivityStatus() {
    try {
      await activate(device.id, true);

      notificationAPI.success('Устройство активировано');

      await getDevices(false, device.companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function onDeleteDevice() {
    try {
      await deleteDeviceById(device.id);

      closeConfirmDelete();

      notificationAPI.success('Устройство удалено');

      await getDevices(false, device.companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (isOpen) {
      fetchCompany();
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <Grid alignItems="stretch" container spacing={2}>
      <Grid item xs={5}>
        <AboutDevice company={company} device={device} isAdmin={isAdmin} isOpen={isOpen} />
      </Grid>

      <Grid item xs={5}>
        <Users device={device} />
      </Grid>

      <Grid item xs={2}>
        {isAdmin && (
          <Grid container direction="column" height="100%" justifyContent="space-between" spacing={2}>
            {device.isActive ? (
              <Button color="error" onClick={toggleDeactivate} size="small" variant="contained">
                Деактивировать
              </Button>
            ) : (
              <ButtonLoader isLoading={isSaving} onClick={changeActivityStatus} size="small">
                Активировать
              </ButtonLoader>
            )}

            <Button color="error" disabled={isSaving} onClick={toggleDelete} size="small" variant="contained">
              Удалить
            </Button>
          </Grid>
        )}
      </Grid>

      <ConfirmDeactivateModal device={device} onClose={toggleDeactivate} open={isDeactivateConfirmOpen} />

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={`Вы уверены, что хотите удалить устройство "${device.name}"?`}
        isLoading={isSaving}
        onClose={toggleDelete}
        onConfirm={onDeleteDevice}
        onDecline={toggleDelete}
        open={isConfirmDeleteOpen}
        title="Удалить устройство"
      />
    </Grid>
  );
}

export default memo(observer(DeviceDetails));
