import React, { memo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import { License } from '@/entities/license';

interface DeviceLicenseRowProps {
  license: License;
  onDelete: (license: License) => void;
}

function DeviceLicenseRaw(props: DeviceLicenseRowProps) {
  const { license, onDelete } = props;

  function onDeleteHandler() {
    onDelete(license);
  }

  return (
    <TableRow>
      <TableCell>{license.serial_number}</TableCell>

      <TableCell>{license.serial_code}</TableCell>

      <TableCell align="right">
        <Tooltip title="Удалить серийный номер">
          <IconButton onClick={onDeleteHandler}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

export default memo(DeviceLicenseRaw);
