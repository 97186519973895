import React, { memo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { AUTH_ETOKEN_KEY } from '@/constants';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';
import { useStore } from '@/stores/StoreProvider';

import PersonBreadcrumbs from './PersonBreadcrumbs/PersonBreadcrumbs';
import PersonForm from './PersonForm/PersonForm';
import PersonFormHeader from './PersonFormHeader/PersonFormHeader';
import { PersonPageProps } from './PersonPage.types';
import { usePersonPageStore, withPersonPageStore } from './PersonPageStore';

function PersonPage(props: PersonPageProps) {
  const { id, groupId, navigate } = props;
  const { checkIntegration } = useStore('signInStore');
  const { resetAuthorityOrgans } = useStore('dictionariesStore');
  const { isStoreLoaded, initPersonStore, resetPersonStore } = usePersonPageStore();

  /*
   * Когда уходим со страницы - очищаем `etoken`,
   * если пользователь выполнял операцию с ним, то пользователь более не сможет пользоваться сервисом
   * */
  useEffect(() => {
    checkIntegration();

    initPersonStore(navigate, id, groupId);

    return () => {
      localStorage.removeItem(AUTH_ETOKEN_KEY);

      resetAuthorityOrgans();
      resetPersonStore();
    };
  }, [id, groupId]);

  return (
    <RouteConstructor
      breadcrumbs={<PersonBreadcrumbs />}
      content={isStoreLoaded ? <PersonForm /> : null}
      header={isStoreLoaded ? <PersonFormHeader /> : null}
      isLoading={!isStoreLoaded}
    />
  );
}

export default memo(withPersonPageStore(observer(PersonPage)));
