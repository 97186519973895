import { GetPersonsArguments } from '@/stores/PersonsStore';

export const DEFAULT_FILTER_FORM_VALUES: Partial<GetPersonsArguments> = {
  status: 0,
  name: '',
  room_number: '',
  doc_number: '',
  citizenship: null,
  creationFrom: null,
  creationTo: null,
  stayFrom: null,
  stayTo: null,
};
