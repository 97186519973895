import styled from '@mui/material/styles/styled';

import { SelectField } from '@/ui-kit/components';

export const CompaniesSubHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompaniesFilter = styled('div')`
  display: flex;
  align-items: center;

  & > * {
    flex-shrink: 0;
  }
`;

export const IntegrationFilter = styled(SelectField)`
  width: 160px;
  margin-left: 12px;
`;
