import React, { ChangeEvent, memo, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { notificationAPI } from '@/api/notificationAPI';
import { Device } from '@/entities/device';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, SelectField } from '@/ui-kit/components';

export interface AttachUserModalProps extends ContentModalProps {
  device: Device;
}

function AttachUserModal(props: AttachUserModalProps) {
  const { device, open, onClose } = props;
  const { isAttaching, attach, getDevices } = useStore('devicesStore');
  const { users, getUsers } = useStore('usersStore');

  const [usersToAttach, setUsersToAttach] = useState<number[]>([]);

  async function onSubmit(e: SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();

    if (usersToAttach.length > 0) {
      try {
        await attach(device.id, usersToAttach);

        notificationAPI.success('Пользователи добавлены');

        onClose();

        await getDevices(false, device.companyId);
      } catch (e) {
        notificationAPI.error(e);
      }
    }
  }

  async function fetchUsers() {
    try {
      await getUsers();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function renderValue(selected: unknown) {
    return users
      .filter(({ id }) => (selected as number[]).includes(id as number))
      .filter((u) => Boolean(u))
      .map((user) => user.name)
      .join(', ');
  }

  useEffect(() => {
    if (open && users.length === 0) {
      fetchUsers();
    }

    if (open) {
      setUsersToAttach([]);
    }
  }, [open]);

  const mapOfAttachedUsers = new Map();

  if (device.users) {
    device.users.forEach(({ id }) => mapOfAttachedUsers.set(id, true));
  }

  const filteredUsers = useMemo(
    () =>
      users.filter(
        ({ id, company_id, active }) => active && company_id === device.companyId && !mapOfAttachedUsers.has(id),
      ),
    [device.companyId, mapOfAttachedUsers, JSON.stringify(users)],
  );

  const onUserChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!target.value) {
      setUsersToAttach([]);

      return;
    }

    setUsersToAttach(target.value as unknown as number[]);
  }, []);
  const onCloseWithReset = useCallback((e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setUsersToAttach([]);
    onClose();
  }, []);

  return (
    <ContentModal disableEscapeKeyDown={isAttaching} onClose={onClose} open={open}>
      <form onReset={onCloseWithReset} onSubmit={onSubmit}>
        <ContentModal.Header isCloseDisabled={isAttaching} onClose={onClose}>
          Устройство {device.name}
        </ContentModal.Header>

        <ContentModal.Body>
          <SelectField
            clearable
            disabled={isAttaching}
            label="Пользователи"
            multiple
            name="name"
            onChange={onUserChange}
            renderValue={renderValue}
            value={usersToAttach}
          >
            {filteredUsers.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                <Checkbox checked={usersToAttach.some((id) => user.id === id)} />

                <ListItemText primary={user.name} />
              </MenuItem>
            ))}
          </SelectField>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isAttaching} type="reset">
            Отмена
          </Button>

          <ButtonLoader disabled={usersToAttach.length === 0} isLoading={isAttaching} type="submit">
            Прикрепить
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(AttachUserModal));
