import React, { memo } from 'react';
import Typography from '@mui/material/Typography';

import { DEFAULT_COUNTRY } from '@/constants';
import { Address, Street } from '@/entities/address';
import AddressFormOld from '@/shared/AddressFormOld/AddressFormOld';

import { AddressRoot } from './AddressForm.style';

interface AddressProps {
  regionCode: string;
  fiasCode: string;
  housing: string;
  housing2: string;
  flat: string;
  isStreetEmpty: boolean;
  onChange: (field: string, value: Nullable<Address | Street | string>, isStreetEmpty?: boolean) => void;
}

function AddressForm(props: AddressProps) {
  const { fiasCode, regionCode, isStreetEmpty, housing, housing2, flat, onChange } = props;

  return (
    <AddressRoot>
      <Typography gutterBottom variant="h4">
        Шаг 5: адрес регистрации (прописка)
      </Typography>

      <Typography gutterBottom variant="h5">
        Заполните адрес регистрации (по документу, удостоверяющему личность)
      </Typography>

      <div>
        <AddressFormOld
          fiasCode={fiasCode}
          isStreetEmpty={isStreetEmpty}
          onFieldChange={onChange}
          regionCode={regionCode}
          settings={{
            country: {
              value: { ...DEFAULT_COUNTRY },
              variant: 'outlined',
              margin: 'normal',
            },
            region: {
              variant: 'outlined',
              margin: 'normal',
            },
            city: {
              variant: 'outlined',
              margin: 'normal',
            },
            settlement: {
              variant: 'outlined',
              margin: 'normal',
            },
            place: {
              variant: 'outlined',
              margin: 'normal',
            },
            street: {
              variant: 'outlined',
              margin: 'normal',
            },
            house: {
              name: 'housing',
              value: housing,
              variant: 'outlined',
              margin: 'normal',
            },
            build: {
              name: 'housing2',
              value: housing2,
              variant: 'outlined',
              margin: 'normal',
            },
            flat: {
              name: 'flat',
              value: flat,
              variant: 'outlined',
              margin: 'normal',
            },
          }}
        />
      </div>
    </AddressRoot>
  );
}

export default memo(AddressForm);
