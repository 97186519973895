import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';

export const ElpostPaper = styled(Paper, {
  shouldForwardProp: (name) => name !== 'shouldStyle',
})(({ shouldStyle }: { shouldStyle: boolean }) => {
  const styledElpostPaper = css`
    max-width: 75%;
    margin: 0 auto;
  `;

  return css`
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 155px;

    ${shouldStyle && styledElpostPaper}
  `;
});

export const ElpostFile = styled('div')`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;

  & input {
    position: absolute;
    top: -1000px;
    left: -1000px;
    opacity: 0;
  }
`;

export const ElpostSubmit = styled(Grid)`
  text-align: center;
`;
