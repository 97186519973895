import React, { memo } from 'react';
import Typography from '@mui/material/Typography';

import { FinishRoot } from './Finish.style';

function Finish() {
  return (
    <FinishRoot>
      <div>
        <Typography gutterBottom variant="h4">
          Завершение регистрации
        </Typography>

        <Typography gutterBottom variant="h5">
          Данные успешно заполнены и отправлены на проверку в отель Sokos Olimpia Garden.
        </Typography>

        <Typography gutterBottom variant="h5">
          После успешной проверки Вам будет отправлено подтверждение регистрации и информация о том, как получить ключи
          от Вашего номера.
        </Typography>

        <Typography gutterBottom variant="h5">
          Желаем комфортного пребывания в нашем отеле!
        </Typography>
      </div>
    </FinishRoot>
  );
}

export default memo(Finish);
