import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';

import { notificationAPI } from '@/api/notificationAPI';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { HMSLogusPerson } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { ContentModal, ContentModalProps } from '@/ui-kit/components';

import { usePersonPageStore } from '../../PersonPageStore';

import LogusPersonsRaw from './LogusPersonsRaw/LogusPersonsRaw';

function LogusPersonsModal({ open, onClose }: ContentModalProps) {
  const { logusPersons, personId } = usePersonPageStore();
  const { syncLogusPerson } = useStore('personsStore');

  const [isSyncing, enableSyncing, disableSyncing] = useToggle();

  async function onClick(logusPerson: HMSLogusPerson) {
    enableSyncing();

    try {
      await syncLogusPerson(personId, logusPerson);

      notificationAPI.success('Данные успешно обновлены');

      onClose();
    } catch (e) {
      notificationAPI.error(e);
    }

    disableSyncing();
  }

  return (
    <ContentModal disableEscapeKeyDown={false} isLoading={isSyncing} onClose={onClose} open={open}>
      <ContentModal.Header onClose={onClose}>Найдены совпадения:</ContentModal.Header>

      <ContentModal.Body>
        {logusPersons.map((logusPerson: HMSLogusPerson) => (
          <LogusPersonsRaw
            key={`${logusPerson.LastName}-${logusPerson.BirthDate}-${logusPerson.ArrivalDate}-${logusPerson.DepartureDate}`}
            logusPerson={logusPerson}
            onClick={onClick}
          />
        ))}
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose}>
          Отмена
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(LogusPersonsModal));
