import React, { Fragment, memo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ImageIcon from '@mui/icons-material/Image';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';

import { usePersonPageStore } from '../../PersonPageStore';

import IncomingReportButton from './IncomingReportButton/IncomingReportButton';
import OutcomingReportButton from './OutcomingReportButton/OutcomingReportButton';
import { PrintButtonsControls, PrintButtonsRoot } from './PrintButtons.style';
import PrintEulasModal from './PrintEulasModal/PrintEulasModal';
import PrintRegForm from './PrintRegForm/PrintRegForm';
import QuestionnaireButton from './QuestionnaireButton/QuestionnaireButton';

function PrintButtons() {
  const { stayInfo, printImages, isPersonHasImages } = usePersonPageStore();

  const { isPrinting } = useStore('personsStore');
  const { pdfFormTypes, getPDFFormTypes } = useStore('dictionariesStore');

  const [isPrintButtonOpened, , , onTogglePrintButtons] = useToggle(false);
  const [isPrintEualasModalOpen, openPrintEualasModal, closePrintEualasModal] = useToggle();

  useEffect(() => {
    if (pdfFormTypes.length === 0) {
      getPDFFormTypes();
    }
  }, []);

  return (
    <PrintButtonsRoot>
      <PrintButtonsControls isOpened={isPrintButtonOpened}>
        <Tooltip title={isPersonHasImages ? 'Печать фото' : 'Изображения отсутствуют'}>
          <IconButton disabled={isPrinting} onClick={printImages}>
            <ImageIcon />
          </IconButton>
        </Tooltip>

        {isPrintButtonOpened && (
          <Fragment>
            {pdfFormTypes.map(({ code }) => {
              if (code === 1) {
                return <IncomingReportButton key={code} />;
              }

              if (code === 2) {
                return <OutcomingReportButton key={code} />;
              }

              if (code === 3) {
                return <QuestionnaireButton key={code} />;
              }

              return <PrintRegForm docId={code} key={code} />;
            })}
          </Fragment>
        )}

        <Tooltip title="Шаблоны документов">
          <IconButton onClick={openPrintEualasModal}>
            <LibraryBooksIcon />
          </IconButton>
        </Tooltip>

        {stayInfo?.company_id && (
          <PrintEulasModal
            companyId={stayInfo.company_id}
            onClose={closePrintEualasModal}
            open={isPrintEualasModalOpen}
          />
        )}
      </PrintButtonsControls>

      <Button color="primary" onClick={onTogglePrintButtons} startIcon={<PrintIcon />} variant="contained">
        Печать
      </Button>
    </PrintButtonsRoot>
  );
}

export default memo(observer(PrintButtons));
