import React, { Fragment, memo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { notificationAPI } from '@/api/notificationAPI';
import { Company } from '@/entities/company';
import GeneralCompanyInfo from '@/shared/GeneralCompanyInfo/GeneralCompanyInfo';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

interface AboutCompanyProps {
  companyId: number;
}

function AboutCompany({ companyId }: AboutCompanyProps) {
  const { getCompanyById, companyByIdMap } = useStore('companiesStore');

  const company: Company | undefined = companyByIdMap.get(companyId);

  async function fetchCompanyById() {
    try {
      await getCompanyById(companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (!company) {
      fetchCompanyById();
    }
  }, [company]);

  return (
    <Fragment>
      <BackdropLoader isLoading={!company} />

      {company && <GeneralCompanyInfo company={company} />}
    </Fragment>
  );
}

export default memo(observer(AboutCompany));
