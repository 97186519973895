import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { Company } from '@/entities/company';
import { Face } from '@/entities/face';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import CompanyFaceModal from './CompanyFaceModal/CompanyFaceModal';
import CompanyFaceRow from './CompanyFaceRaw/CompanyFaceRaw';
import { CompanyFacesContent, CompanyFacesHeader, CompanyFacesList, CompanyFacesRoot } from './CompanyFaces.style';

interface CompanyFacesProps {
  companyId: number;
}

function CompanyFaces(props: CompanyFacesProps) {
  const { companyId } = props;
  const { isFetchingCompanyById, companyByIdMap, getCompanyById } = useStore('companiesStore');

  const [isCompanyFaceOpen, openCompanyFace, closeCompanyFace] = useToggle();
  const [faces, setFaces] = useState<Face[]>([]);

  const company: Company | undefined = companyByIdMap.get(companyId);

  async function fetchCompanyById() {
    try {
      await getCompanyById(companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (company) {
      setFaces(company.faces ?? []);
    } else {
      fetchCompanyById();
    }
  }, [JSON.stringify(company)]);

  return (
    <CompanyFacesRoot>
      <BackdropLoader isLoading={isFetchingCompanyById} />

      <CompanyFacesHeader>
        <Typography>Лица ответственные за учёт</Typography>

        <Button color="primary" onClick={openCompanyFace} size="small" variant="contained">
          Добавить ответственного
        </Button>
      </CompanyFacesHeader>

      <CompanyFacesContent>
        <CompanyFacesList>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ФИО</TableCell>

                <TableCell width="20" />
              </TableRow>
            </TableHead>

            <TableBody>
              {faces.length > 0 &&
                faces.map((face: Face) => (
                  <CompanyFaceRow
                    companyId={companyId}
                    face={face}
                    key={`${face.id}${face.face_fname}${face.face_lname}`}
                    onAfterChange={fetchCompanyById}
                  />
                ))}

              {faces.length === 0 && (
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography align="center">Лица ответственные за учёт отсутствуют</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <CompanyFaceModal
            companyId={companyId}
            onAfterChange={fetchCompanyById}
            onClose={closeCompanyFace}
            open={isCompanyFaceOpen}
            type="create"
          />
        </CompanyFacesList>
      </CompanyFacesContent>
    </CompanyFacesRoot>
  );
}

export default memo(observer(CompanyFaces));
