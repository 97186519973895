import { pureStructure } from '@/utils';

export class GroupModel {
  id!: number;
  name!: string;
  parent_id!: number;
  default_order!: number;
  created_by!: number;
  created_at!: string;
  persons_count!: number;
  has_child_groups!: boolean;
}

interface MergedGroup {
  children?: GroupModel[];
  collapsed?: boolean;
}

export class Group extends GroupModel {
  collapsed = false;
  children: Group[] = [];
  has_child_groups = false;
  persons_count = 0;

  constructor(model: GroupModel & MergedGroup) {
    super();

    Object.keys(model).forEach((key) => {
      switch (key) {
        case 'children':
          if (Array.isArray(model[key])) {
            this[key] = model[key]?.filter((group) => !!group).map((child) => new Group(child)) ?? [];
          }

          break;
        default:
          this[key] = model[key];

          break;
      }
    });

    Object.assign(this, model);
  }

  get title() {
    return `(${this.persons_count}) ${this.name}`;
  }

  toJS(): GroupModel & MergedGroup {
    return pureStructure({ ...this });
  }
}
