import React, { Fragment, memo, SyntheticEvent, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { Face } from '@/entities/face';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';

import CompanyFaceModal from '../CompanyFaceModal/CompanyFaceModal';

interface IpAddressRowProps {
  face: Face;
  companyId: number;
  onAfterChange: () => void;
}

function CompanyFaceRaw(props: IpAddressRowProps) {
  const { face, companyId } = props;
  const { getCompanyById, removeCompanyFace, isSavingFace } = useStore('companiesStore');

  const [isCompanyFaceOpen, , closeCompanyFace, toggleCompanyFaceOpen] = useToggle();
  const [isDeleteFaceModalOpen, , closeDeleteFaceOpen, toggleDeleteFaceOpen] = useToggle();

  async function fetchCompanyById() {
    try {
      await getCompanyById(companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function onDeleteHandler() {
    try {
      if (face?.id) {
        await removeCompanyFace(companyId, face.id);

        notificationAPI.success('Ответственное лицо удалено');

        await fetchCompanyById();
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onToggleCompanyFaceModal = useCallback((e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    toggleCompanyFaceOpen();
  }, []);
  const onToggleDeleteFaceModal = useCallback((e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    toggleDeleteFaceOpen();
  }, []);

  return (
    <Fragment>
      <TableRow hover onClick={onToggleCompanyFaceModal}>
        <TableCell>
          <span>{face.face_lname} </span>

          <span>{face.face_fname} </span>

          <span>{face.face_mname} </span>
        </TableCell>

        <TableCell align="center">
          <Tooltip title="Удалить ответственное лицо">
            <IconButton onClick={onToggleDeleteFaceModal} size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <CompanyFaceModal
        companyFace={face}
        companyId={companyId}
        onAfterChange={fetchCompanyById}
        onClose={closeCompanyFace}
        open={isCompanyFaceOpen}
        type="edit"
      />

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={
          <Typography variant="body1">
            <span>Вы действительно хотите удалить </span>

            <span>&quot;{face.face_fname} </span>

            <span>{face.face_lname}&quot;?</span>
          </Typography>
        }
        isLoading={isSavingFace}
        onClose={closeDeleteFaceOpen}
        onConfirm={onDeleteHandler}
        onDecline={closeDeleteFaceOpen}
        open={isDeleteFaceModalOpen}
        title="Удалить ответственное лицо"
      />
    </Fragment>
  );
}

export default memo(observer(CompanyFaceRaw));
