import React, { memo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Retention } from '@/entities/retention';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import RetentionConfirmModal from './RetentionConfirmModal/RetentionConfirmModal';
import {
  RetentionContent,
  RetentionContentWrapper,
  RetentionDataRoot,
  RetentionHeader,
  RetentionHeaderControl,
  RetentionHeaderStart,
} from './RetentionData.styles';
import RetentionRow from './RetentionRow/RetentionRow';

interface RetentionDataProps {
  companyId: number;
}

function RetentionData({ companyId }: RetentionDataProps) {
  const [isRetentionModalOpen, openRetentionModal, closeRetentionModal] = useToggle();
  const { isFetchingRetentions, retentions, getRetentionList } = useStore('reportsStore');

  useEffect(() => {
    getRetentionList(companyId);
  }, []);

  return (
    <RetentionDataRoot>
      <RetentionHeader item xs={12}>
        <RetentionHeaderControl>
          <Typography>Уничтожение персональных данных гостей</Typography>

          <RetentionHeaderStart color="error" onClick={openRetentionModal} variant="contained">
            Начать
          </RetentionHeaderStart>
        </RetentionHeaderControl>

        <Typography variant="h6">История уничтожений</Typography>
      </RetentionHeader>

      <RetentionContentWrapper>
        <BackdropLoader isLoading={isFetchingRetentions} />

        <RetentionContent>
          <Table data-testid="person-table" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Пользователь</TableCell>

                <TableCell>Дата время</TableCell>

                <TableCell>Срок удаления, месяцев</TableCell>

                <TableCell>Уничтоженные карточки гостей</TableCell>

                <TableCell>Акт об уничтожении ПДн</TableCell>

                <TableCell>Выгрузка из журнала</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!isFetchingRetentions &&
                retentions.length > 0 &&
                retentions.map((r: Retention) => <RetentionRow key={r.id} retention={r} />)}

              {!isFetchingRetentions && retentions.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography align="center">Данные отсутствуют</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </RetentionContent>
      </RetentionContentWrapper>

      <RetentionConfirmModal companyId={companyId} onClose={closeRetentionModal} open={isRetentionModalOpen} />
    </RetentionDataRoot>
  );
}

export default memo(observer(RetentionData));
