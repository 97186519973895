import React, { Fragment, memo } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { DATE_HUMAN_FORMAT } from '@/constants';
import { Company } from '@/entities/company';
import { Device } from '@/entities/device';
import { useToggle } from '@/hooks/useToggle/useToggle';

import DeviceSettingsModal from './DeviceSettingsModal/DeviceSettingsModal';

interface AboutDeviceProps {
  isOpen: boolean;
  device: Device;
  company: Nullable<Company>;
  isAdmin?: boolean;
}

function AboutDevice(props: AboutDeviceProps) {
  const { isOpen, device, company, isAdmin = false } = props;
  const [isSettingsOpen, openSettings, closeSettings] = useToggle();

  return (
    <Fragment>
      <Typography variant="subtitle2">Об устройстве</Typography>

      <p>
        <b>Создано: </b>

        <span>{format(new Date(device.created_at), DATE_HUMAN_FORMAT)}</span>
      </p>

      <p>
        <b>Серийный номер: </b>

        <span>{isOpen && device.serialNumber}</span>
      </p>

      {device.serialCode && device.serialCode.length > 0 && (
        <p>
          <b>Серийный код: </b>

          <span>{isOpen && device.serialCode}</span>
        </p>
      )}

      <p>
        <b>Версия SDK: </b>

        <span>{device.version}</span>
      </p>

      <p>
        <b>Версия БД: </b>

        <span>{device.dataBaseVersion}</span>
      </p>

      {device.appVersion && (
        <p>
          <b>Версия EMIS Service: </b>

          <span>{device.appVersion}</span>
        </p>
      )}

      {company?.name && (
        <p>
          <b>Компания: </b>

          <span>{company.name}</span>
        </p>
      )}

      {isAdmin && (
        <p>
          <Button onClick={openSettings} size="small" variant="outlined">
            Подробнее об устройстве
          </Button>
        </p>
      )}

      <DeviceSettingsModal device={device} onClose={closeSettings} open={isSettingsOpen} />
    </Fragment>
  );
}

export default memo(observer(AboutDevice));
