import React, { ChangeEvent, KeyboardEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { notificationAPI } from '@/api/notificationAPI';
import { User } from '@/entities/user';
import { useToggle } from '@/hooks/useToggle/useToggle';
import UserFormModal, { DEFAULT_USER_FORM } from '@/shared/UserFormModal/UserFormModal';
import UserTableRow from '@/shared/Users/UserTableRaw/UserTableRaw';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, InputField } from '@/ui-kit/components';

import { UsersContent, UsersFilter, UsersHeader, UsersList, UsersRoot } from './Users.style';

interface UsersProps {
  companyId: number;
  isAdmin?: boolean;
}

function Users(props: UsersProps) {
  const { companyId, isAdmin = false } = props;
  const { roles } = useStore('rolesStore');
  const { isFetchingUsersByCompany, usersByCompany, getUsersByCompany } = useStore('usersStore');

  const [filter, setFilter] = useState('');

  const [isAddUserOpen, openAddUserModal, closeAddUserModal] = useToggle(false);
  const [isActive, , , toggleIsActive] = useToggle(true);

  async function fetchUsers() {
    try {
      await getUsersByCompany(companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const onChangeFilter = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => setFilter(currentTarget.value),
    [],
  );

  const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Escape') {
      setFilter('');
    }
  }, []);

  const filteredUsers = useMemo(
    () =>
      usersByCompany
        .filter(
          ({ active, email, name }) =>
            `${name} ${email}`.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) && active === isActive,
        )
        .sort((a, b) => {
          if (!a.id || !b.id) {
            return 0;
          }

          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;

          return 0;
        }),
    [JSON.stringify(usersByCompany), filter, isActive],
  );

  return (
    <UsersRoot>
      <BackdropLoader isLoading={isFetchingUsersByCompany} />

      <UsersHeader>
        <UsersFilter>
          <InputField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="inherit" />
                </InputAdornment>
              ),
            }}
            clearable
            name="filter"
            onChange={onChangeFilter}
            onKeyDown={onKeyDown}
            size="small"
            value={filter}
          />

          <FormControlLabel
            control={<Switch checked={isActive} color="primary" name="active" onChange={toggleIsActive} size="small" />}
            label="Активен"
          />
        </UsersFilter>

        <Button color="primary" onClick={openAddUserModal} size="small" variant="contained">
          Добавить пользователя
        </Button>
      </UsersHeader>

      <UsersContent>
        <UsersList>
          {filteredUsers.length > 0 && (
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>

                  <TableCell>Фамилия</TableCell>

                  <TableCell>Имя</TableCell>

                  <TableCell>E-mail</TableCell>

                  <TableCell>Статус</TableCell>

                  <TableCell>Роль</TableCell>

                  <TableCell>ID</TableCell>

                  <TableCell>Последний вход</TableCell>

                  <TableCell>Состояние</TableCell>

                  {isAdmin && <TableCell />}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredUsers.map((user: User, index: number) => (
                  <UserTableRow companyId={companyId} index={index} key={user.id} readonly={!isAdmin} user={user} />
                ))}
              </TableBody>
            </Table>
          )}

          <UserFormModal
            companyId={companyId}
            disableExternalAccess={!isAdmin}
            disableSuperUserSelect={!isAdmin}
            onClose={closeAddUserModal}
            open={isAddUserOpen}
            type="create"
            user={
              new User({
                ...DEFAULT_USER_FORM,
                role: roles.length > 0 ? roles[0] : undefined,
              })
            }
          />
        </UsersList>
      </UsersContent>
    </UsersRoot>
  );
}

export default memo(observer(Users));
