import React, { createContext, FunctionComponent, PropsWithChildren, useContext } from 'react';
import { makeAutoObservable, reaction } from 'mobx';

import { notificationAPI } from '@/api/notificationAPI';
import { Person } from '@/stores/PersonsStore';
import { store } from '@/stores/Store';

const FETCH_TIMEOUT = 1000;

export class BreakfastStore {
  isFetching = false;
  roomNumber = '';
  persons: Person[] = [];
  selectedPersons: number[] = [];

  private fetchTimeout: NodeJS.Timeout | undefined = undefined;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.roomNumber,
      () => {
        clearTimeout(this.fetchTimeout);
        this.fetchTimeout = setTimeout(() => this.fetchPersonsByRoomNumber(), FETCH_TIMEOUT);
      },
    );
    reaction(
      () => this.persons,
      (persons) => {
        if (persons.length === 0) {
          this.selectedPersons = [];
        }
      },
    );
  }

  private readonly fetchPersonsByRoomNumber = async () => {
    if (!Number(this.roomNumber)) {
      this.persons = [];

      return;
    }

    this.isFetching = true;

    try {
      const persons = await store.personsStore.getPersonsPure({ room_number: this.roomNumber });

      this.setPersons(persons.data);
    } catch (e) {
      notificationAPI.error(e);
    }

    this.isFetching = false;
  };

  setPersons = (persons: Person[]) => {
    this.persons = persons;
  };

  setRoomNumber = (value: string) => {
    this.roomNumber = value;
  };

  selectPerson = (personId: number) => {
    if (this.selectedPersons.some((id) => id === personId)) {
      const idIndex = this.selectedPersons.indexOf(personId);

      if (idIndex > -1) {
        this.selectedPersons.splice(idIndex, 1);
      }
    } else {
      this.selectedPersons.push(personId);
    }
  };
}

const breakfastStore = new BreakfastStore();
const BreakfastStoreContext = createContext(breakfastStore);

export function BreakfastStoreProvider({ children }: PropsWithChildren<unknown>) {
  return <BreakfastStoreContext.Provider value={breakfastStore}>{children}</BreakfastStoreContext.Provider>;
}

export function useBreakfastStore(): BreakfastStore {
  return useContext(BreakfastStoreContext);
}

export function withBreakfastStore(Component: FunctionComponent<NonNullable<unknown>>) {
  return function withBreakfastStoreComponent() {
    return (
      <BreakfastStoreProvider>
        <Component />
      </BreakfastStoreProvider>
    );
  };
}
