export class KSReasonModel {
  id!: number;
  name!: string;
  short_name!: string;
}

export class KSReasonDocModel {
  id!: number;
  reason_id!: number;
  reason!: KSReasonModel;
  name!: string;
  description!: string;
}

export class KSReason extends KSReasonModel {
  constructor(ksReason: KSReasonModel) {
    super();

    Object.keys(ksReason).forEach((key) => {
      this[key] = ksReason[key];
    });
  }
}

export class KSReasonDoc extends KSReasonDocModel {
  constructor(ksReasonDoc: KSReasonDocModel) {
    super();

    Object.keys(ksReasonDoc).forEach((key) => {
      switch (key) {
        case 'reason':
          if (ksReasonDoc.reason) {
            this.reason = new KSReason(ksReasonDoc.reason);
          }

          break;
        default:
          this[key] = ksReasonDoc[key];

          break;
      }
    });
  }
}
