export enum SRMStatus {
  Reserved = 1,
  DueIn = 2,
  CheckedIn = 3,
  DueOut = 4,
  CheckedOut = 5,
  Cancelled = 6,
}

export const SRM_STATUS_MAP = {
  [SRMStatus.Reserved]: 'reserved',
  [SRMStatus.DueIn]: 'due in',
  [SRMStatus.CheckedIn]: 'checked in',
  [SRMStatus.DueOut]: 'due out',
  [SRMStatus.CheckedOut]: 'checked out',
  [SRMStatus.Cancelled]: 'cancelled',
};
