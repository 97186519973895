import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';

export const RetentionDataRoot = styled('div')`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const RetentionHeader = styled(Grid)`
  flex-shrink: 0;
`;

export const RetentionHeaderControl = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const RetentionHeaderStart = styled(Button)`
  margin-left: 48px;
`;

export const RetentionContentWrapper = styled('div')`
  flex-grow: 1;
  overflow: hidden;
  position: relative;
`;

export const RetentionContent = styled(Grid)`
  overflow: auto;
  height: 100%;
`;
