import React, { memo } from 'react';
import Button from '@mui/material/Button';

import RolesTable from '@/shared/RolesTable/RolesTable';
import { ContentModal, ContentModalProps } from '@/ui-kit/components';

function UserRolesModal(props: ContentModalProps) {
  const { open, onClose } = props;

  return (
    <ContentModal maxWidth="lg" onClose={onClose} open={open}>
      <ContentModal.Header onClose={onClose}>Доступные роли</ContentModal.Header>

      <ContentModal.Body>
        <RolesTable />
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose}>
          Закрыть
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(UserRolesModal);
