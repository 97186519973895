import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import { css, styled } from '@mui/material/styles';

export const GroupItemRoot = styled('div')(
  ({ isSelected }: { isSelected?: boolean }) => css`
    border-top: 1px solid ${grey['200']};
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    background-color: ${isSelected ? grey['200'] : 'initial'};

    &:hover {
      background-color: ${grey['100']};

      .groupItemToolbar {
        background-color: ${grey['100']};
        display: inline-flex;
        align-items: center;

        &:after {
          background-image: linear-gradient(to right, transparent 0, ${grey['100']} 100%);
        }
      }
    }

    & a {
      width: 100%;
      display: inline-flex;
      align-items: center;
      color: inherit;
      text-decoration: none;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: none;
      }
    }
  `,
);

export const GroupItemLink = styled('div')`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
`;

export const GroupItemIcon = styled('div')`
  width: 28px;
  height: 48px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  flex-shrink: 0;

  & svg {
    fill: ${grey['500']}
  },
`;

export const GroupItemCollapse = styled('div')(
  ({ isCollapsed }: { isCollapsed?: boolean }) => css`
    transform: rotate(${isCollapsed ? 180 : 0}deg);
    transition: transform 300ms ease-in-out;

    & svg {
      fill: ${grey['800']};
    }
  `,
);

export const GroupItemName = styled('div')`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  padding: 12px 0;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const GroupItemToolbar = styled('div')`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  align-items: flex-start;
  background-color: ${common.white};

  &:after {
    content: '';
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -5px;
    background-image: linear-gradient(to right, transparent 0, #fff 100%);
  }
`;

export const GroupItemLoader = styled('div')`
  margin-right: 5px;
  margin-top: 5px;
`;

export const GroupItemChildren = styled('div')(
  ({ isCollapsed }: { isCollapsed: boolean }) => css`
    padding-left: 24px;
    overflow: hidden;
    height: ${isCollapsed ? 'auto' : 0};
  `,
);
