import { NEW_PERSON_ID } from '@/constants';
import { DEFAULT_EMPTY_PERSON } from '@/stores/PersonsStore';

import { PersonPageStore } from './PersonPage.types';

export enum PersonPageType {
  Create = 'create',
  Edit = 'edit',
}

export const DEFAULT_PERSON_PAGE_STORE: PersonPageStore = {
  personId: NEW_PERSON_ID,
  groupId: undefined,
  errors: {},
  isPersonValuesValid: true,
  personPageType: PersonPageType.Create,
  person: {
    ...DEFAULT_EMPTY_PERSON,
  },
  personAge: null,
  navigate: () => undefined,
  setPersonValues: () => undefined,
  onRefetchPersonData: () => new Promise(() => undefined),
  onResetPersonData: () => undefined,
  imagesToRemove: [],
  updateImagesToRemove: () => undefined,
};

export const AUTOSAVE_TIMEOUT = 1000;

export const MIN_PERSON_AGE = 18;
