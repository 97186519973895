import { ROUTE_FALLBACK_LABEL, ROUTE_SIGNIN } from '@/constants';

export function getSignInPath() {
  /*
   * Формируем `pathname` на случай, если пользователь был разавторизован находясь на определенной странице,
   * эта страница попадет в параметр fallback и при следующей авторизации пользователь перейдет на нее
   *
   * Конкретно это может применяться при выполнении интеграции с отельными системами (HMS - Hotel Management System)
   * */

  let pathname = ROUTE_SIGNIN;

  if (window.location.search) {
    pathname += `?${ROUTE_FALLBACK_LABEL}=${encodeURIComponent(
      `${window.location.pathname}${window.location.search}`,
    )}`;
  }

  return pathname;
}
