import { Company, CompanyModel } from '@/entities/company';
import { IpAddress, IpAddressModel } from '@/entities/ipAddress';
import { Role, RoleModel } from '@/entities/role';

export class UserModel {
  id?: number;
  fname!: string;
  lname!: string;
  email!: string;
  position!: string;
  password!: string;
  active!: boolean;
  company_superuser!: boolean;
  use_ip_whitelist!: boolean;
  external_access_only!: boolean;
  token_keep!: boolean;
  rwr!: boolean;
  company_id!: number;
  token_lifetime_min!: StringOrNumber;
  last_seen_at?: string;
  default_group_id?: number;
  online?: boolean;
  company?: CompanyModel;
  ip?: IpAddressModel[];
  role?: RoleModel;
}

export class User extends UserModel {
  constructor(user: UserModel) {
    super();

    Object.keys(user).forEach((key) => {
      switch (key) {
        case 'role':
          if (user.role) {
            this.role = new Role(user.role);
          }

          break;
        case 'company':
          if (user.company) {
            this.company = new Company(user.company);
          }

          break;
        case 'ip':
          if (Array.isArray(user.ip)) {
            this.ip = user.ip.map((ipAddress) => new IpAddress(ipAddress));
          }

          break;
        default:
          this[key] = user[key];

          break;
      }
    });
  }

  get name() {
    return `${this.fname} ${this.lname}`;
  }
}
