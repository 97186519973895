import { formatAmountRUS } from '@/utils/formatMoney';

export enum TransactionType {
  Pay = 1,
  Refund = 2,
}

export class KSTransactionModel {
  amount!: number;
  created_at!: string;
  id!: string;
  person_id!: number;
  transaction_type!: TransactionType;
  user_id!: number;
}

export class KSTransaction extends KSTransactionModel {
  constructor(ksTransaction: KSTransactionModel) {
    super();

    Object.keys(ksTransaction).forEach((key) => {
      this[key] = ksTransaction[key];
    });
  }

  get paidAmount() {
    return formatAmountRUS(this.amount);
  }

  get type() {
    if (this.transaction_type === TransactionType.Pay) {
      return 'Оплата';
    }

    return 'Возврат';
  }
}
