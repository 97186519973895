import React, { createContext, FunctionComponent, MouseEvent, PropsWithChildren, useContext } from 'react';
import { addDays, differenceInCalendarDays, differenceInYears, format, isFuture, isValid } from 'date-fns';
import { computed, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import QrScanner from 'qr-scanner';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import {
  AUTH_TOKEN_KEY,
  Citizen,
  DATE_HUMAN_FORMAT,
  DEPRECATED_DEFAULT_DATE,
  DocumentType,
  DocumentTypeEnumId,
  FIRST_NAME_IS_EMPTY_LABEL,
  INTEGRATION_DATA_KEY,
  LAST_NAME_IS_EMPTY_LABEL,
  ROUTE_BOOKINGS,
  ROUTE_KS_LIST,
  ROUTE_PERSON,
} from '@/constants';
import { KSTransaction } from '@/entities/KSTransaction';
import { UserDevice } from '@/entities/userDevice';
import {
  DEFAULT_EMPTY_PERSON,
  DEFAULT_ID_CARD,
  DEFAULT_MIGRATION_CARD,
  DEFAULT_RIGHT_OF_STAY,
  Gender,
  HMSLogusPerson,
  KSPaidStatus,
  OperationType,
  Person,
  PersonDetail,
  PersonDocument,
  PersonStatus,
  RegInfo,
  SocketOperation,
  StayInfo,
} from '@/stores/PersonsStore';
import { store } from '@/stores/Store';
import { base64toBlob, convertToClearBase64, getDocumentByType, getValidDateValue, pureStructure } from '@/utils';
import { deepMerge } from '@/utils/deepMerge';
import { dotStringToObject } from '@/utils/dotStringToObject';
import { formatAmountRUS } from '@/utils/formatMoney';

import { getDocumentLabel, getKSAmount } from './PersonForm/PersonForm.utils';
import { MIN_PERSON_AGE, PersonPageType } from './PersonPage.constant';
import {
  IntegrationIncomingQuery,
  PersonFormRouteState,
  PersonPageProps,
  PersonPollingEvent,
} from './PersonPage.types';
import { getPersonPageType, serializePersonFormValues } from './PersonPage.utils';

export class PersonPageStore {
  private personData: Nullable<PersonDetail> = null;

  isStoreLoaded = false;
  isSaving = false;
  isFetchingTransactions = false;

  id: Nullable<string> = null;
  navigate!: PersonPageProps['navigate'];
  personPageType!: PersonPageType;
  groupId: Nullable<string> = null;
  imagesToRemove: [number, number][] = [];
  logusPersons: HMSLogusPerson[] = [];
  relatedPersons: Person[] = [];
  transactions: KSTransaction[] = [];
  /*
   * `initialNameValues` хранит в себе записи по имени и фамилии
   * они нужны на случай, если пользователь выбирает чекбоксы Фамилия отсутствует или Имя отсутсвует
   *
   * Если чекбоксы выбраны - в сторе мы стираем сами значения, если нет - то в стор складываем значения из `initialNameValues`
   * Так же нзачения `initialNameValues` обновляются когда обновляем значения в сторе
   * */
  initialNameValues: Record<string, string> = {};
  tab: Nullable<DocumentType> = null;
  pendingScanId: Nullable<number> = null;

  isKSModalOpen = false;
  isKSPaidStatusModalOpen = false;
  shouldShowStayTill = false;

  get isNewPerson() {
    return this.personPageType === PersonPageType.Create;
  }

  get isIntegration() {
    return store.signInStore.isIntegration;
  }

  get isBooking() {
    return window.location.pathname.includes(ROUTE_BOOKINGS);
  }

  get isKSListItem() {
    return window.location.pathname.includes(ROUTE_KS_LIST);
  }

  get isKSEnabled() {
    return Boolean(store.signInStore.userCompany?.company.ks);
  }

  // общая информация
  get person(): PersonDetail {
    return this.personData!;
  }

  get personId() {
    return `${this.personData?.id}`;
  }

  get personGroupId() {
    return this.personData?.group_id;
  }

  get lastName() {
    return this.personData?.lname ?? '';
  }

  get lastNameLat() {
    return this.personData?.lname_lat ?? '';
  }

  get firstName() {
    return this.personData?.fname ?? '';
  }

  get firstNameLat() {
    return this.personData?.fname_lat ?? '';
  }

  get middleName() {
    return this.personData?.mname ?? '';
  }

  get middleNameLat() {
    return this.personData?.mname_lat ?? '';
  }

  get personName() {
    if (!this.person) {
      return '';
    }

    const { fname_lat, lname_lat, mname_lat, birth_date } = this.person;

    let result = '';

    if (fname_lat) {
      result += `${fname_lat}`;
    }

    if (mname_lat) {
      result += ` ${mname_lat}`;
    }

    if (lname_lat) {
      result += ` ${lname_lat}`;
    }

    if (birth_date && isValid(new Date(birth_date))) {
      result += `, ${format(new Date(birth_date), DATE_HUMAN_FORMAT)}`;
    }

    return result.trim();
  }

  get birthDate() {
    return this.personData?.birth_date;
  }

  get birthDatePart() {
    return this.personData?.birth_date_part;
  }

  get isBirthDatePartValid(): boolean {
    // 10 - минимальная длинна заполненной даты '00.00.0000'
    // на сервере она хранится строкой, не типа Date
    return (this.birthDatePart ?? '').replace(/\s/g, '').length === 10;
  }

  get personAge(): Nullable<number> {
    return this.birthDate ? differenceInYears(new Date(), new Date(this.birthDate)) : null;
  }

  get isUnderAge() {
    return Boolean(this.personAge !== null && this.personAge < MIN_PERSON_AGE);
  }

  get isBirthdate() {
    if (!this.birthDate) {
      return false;
    }

    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth() + 1;

    const birthday = new Date(this.birthDate);
    const birthdayDay = birthday.getDate();
    const birthdayMonth = birthday.getMonth() + 1;

    return todayDay === birthdayDay && todayMonth === birthdayMonth;
  }

  get citizenship() {
    return this.person?.citizenship;
  }

  get isRussianCitizenship(): boolean {
    return this.person?.citizenship === Citizen.Russia || this.person?.citizenship === Citizen.USSR;
  }

  get gender() {
    return this.person?.gender ?? Gender.Unknown;
  }

  get visitPurposeId() {
    return this.stayInfo?.visit_purpose_id;
  }

  get dateEntryRussia() {
    return this.stayInfo?.date_entry_rf;
  }

  get hotelPersonId() {
    return this.personData?.hotel_person_id ?? '';
  }

  get email() {
    return this.person?.email ?? '';
  }

  get phone() {
    return this.person?.tel ?? '';
  }

  get description() {
    return this.personData?.descr;
  }

  get category() {
    return this.personData?.category_id;
  }

  get arrivalFormNumber() {
    return this.person?.arrival_form_number ?? '';
  }

  get ksPaidStatus() {
    return this.person?.ks_info?.is_paid ?? KSPaidStatus.Unknown;
  }

  get aisReportId() {
    return this.person?.ks_info?.ais_report_id;
  }

  get aisPersonId() {
    return this.person?.ks_info?.ais_person_id;
  }

  get ksPaidAmount() {
    return this.personData?.ks_info?.amount ?? 0;
  }

  get ksCalculatedAmount() {
    if (this.stayDays !== null && this.stayDays > 1) {
      return formatAmountRUS(getKSAmount(this.stayInfo));
    }

    return null;
  }

  get ksReasonId() {
    return this.person?.ks_info?.reason_id;
  }

  get ksReason() {
    return store.dictionariesStore.ksReasons?.find(({ id }) => id === this.ksReasonId);
  }

  get ksImages() {
    return this.person?.ks_images ?? [];
  }

  get isValidKSAmount() {
    let isValid = true;

    if (this.ksPaidStatus === KSPaidStatus.Unknown) {
      return isValid;
    }

    const calculateAmount = getKSAmount(this.stayInfo);
    const paidAmount = this.personData?.ks_info.amount;

    if (typeof paidAmount === 'number' && typeof calculateAmount === 'number') {
      isValid = calculateAmount === paidAmount;
    }

    if (this.stayDays === null) {
      isValid = false;
    }

    return isValid;
  }

  // законный представитель
  get isLegalRepresentative() {
    return Boolean(this.person?.legal_representative);
  }

  get legalRepresentativeId() {
    return this.personData?.legal_representative_id;
  }

  get legalRepresentativeTypeId() {
    return this.personData?.legal_representative_type_id;
  }

  get representPerson() {
    return this.personData?.represent_person;
  }

  // stay info
  get stayInfo() {
    return this.personData?.stay_info ?? ({} as StayInfo);
  }

  get roomNumber() {
    return this.stayInfo?.room_number ?? '';
  }

  get stayFrom() {
    return this.stayInfo?.stay_from;
  }

  get stayTill() {
    return this.stayInfo?.stay_till ?? null;
  }

  get stayDays() {
    if (!this.stayFrom || !this.stayTill) {
      return null;
    }

    return differenceInCalendarDays(new Date(this.stayTill), new Date(this.stayFrom));
  }

  get hmsStatus() {
    return this.personData?.stay_info?.hms_status ?? 0;
  }

  get srmStatus() {
    return this.personData?.srm_info?.status;
  }

  // регистрация
  get regInfo() {
    return this.personData?.reg_info ?? ({} as RegInfo);
  }

  get regionCode() {
    return this.regInfo?.region_code ?? '';
  }

  get fiasCode() {
    return this.regInfo?.fias_code ?? '';
  }

  get fiasCode2() {
    return this.regInfo?.fias_code2 ?? '';
  }

  get housing() {
    return this.regInfo?.housing ?? '';
  }

  get housing2() {
    return this.regInfo?.housing2 ?? '';
  }

  get flat() {
    return this.regInfo?.flat ?? '';
  }

  get regFrom() {
    return this.regInfo?.reg_from;
  }

  get regTill() {
    return this.regInfo?.reg_till;
  }

  get prevRegionCode() {
    return this.regInfo?.prev_region_code ?? '';
  }

  get prevFiasCode() {
    return this.regInfo?.prev_fias_code ?? '';
  }

  get prevFiasCode2() {
    return this.regInfo?.prev_fias_code2 ?? '';
  }

  get prevHousing() {
    return this.regInfo?.prev_housing ?? '';
  }

  get prevHousing2() {
    return this.regInfo?.prev_housing2 ?? '';
  }

  get prevFlat() {
    return this.regInfo?.prev_flat ?? '';
  }

  get prevRegFrom() {
    return this.regInfo?.prev_reg_from;
  }

  get prevRegTill() {
    return this.regInfo?.prev_reg_till;
  }

  get isHomeless() {
    return Boolean(this.regInfo?.homeless);
  }

  // документы
  get documents() {
    return this.personData?.documents ?? [];
  }

  get idCard(): PersonDocument | undefined {
    return getDocumentByType(this.documents, DocumentType.IdCard)?.[0];
  }

  get idCardIndex(): number {
    return getDocumentByType(this.documents, DocumentType.IdCard)?.[1] ?? -1;
  }

  get rightOfStay(): PersonDocument | undefined {
    return getDocumentByType(this.documents, DocumentType.RightOfStay)?.[0];
  }

  get rightOfStayIndex(): number {
    return getDocumentByType(this.documents, DocumentType.RightOfStay)?.[1] ?? -1;
  }

  get migrationCard(): PersonDocument | undefined {
    return getDocumentByType(this.documents, DocumentType.Migration)?.[0];
  }

  get migrationCardIndex(): number {
    return getDocumentByType(this.documents, DocumentType.Migration)?.[1] ?? -1;
  }

  get isPersonHasImages() {
    return (this.person?.documents || []).filter(({ images }: PersonDocument) => images?.length > 0).length > 0;
  }

  // статусы
  get status() {
    return this.person?.status;
  }

  get isForbidden(): boolean {
    return this.person?.status === PersonStatus.Forbidden;
  }

  get isExported(): boolean {
    return this.person?.status === PersonStatus.Exported || this.isForbidden;
  }

  get isAccepted(): boolean {
    return this.person?.status === PersonStatus.Accepted;
  }

  get isDeleted(): boolean {
    return Boolean(this.person?.deleted);
  }

  get errors(): Record<string, boolean> {
    const errors: Record<string, boolean> = {};

    if (
      this.firstName?.length === 0 &&
      (this.firstNameLat?.length === 0 || this.firstNameLat === FIRST_NAME_IS_EMPTY_LABEL) &&
      this.lastName?.length === 0 &&
      (this.lastNameLat?.length === 0 || this.lastNameLat === LAST_NAME_IS_EMPTY_LABEL)
    ) {
      errors.fname = this.firstNameLat !== FIRST_NAME_IS_EMPTY_LABEL;
      errors.fname_lat = this.firstNameLat !== FIRST_NAME_IS_EMPTY_LABEL;
      errors.lname = this.lastNameLat !== LAST_NAME_IS_EMPTY_LABEL;
      errors.lname_lat = this.lastNameLat !== LAST_NAME_IS_EMPTY_LABEL;
    }

    if (this.isLegalRepresentative) {
      if (!this.legalRepresentativeTypeId) {
        errors.legal_representative_type_id = true;
      }
    }

    return errors;
  }

  get warnings(): Record<string, string> {
    const warnings: Record<string, string> = {};

    if (this.isExported) {
      return warnings;
    }

    if (this.birthDate) {
      if (isFuture(new Date(this.birthDate))) {
        warnings.birth_date = 'Дата рождения не может быть позднее текущей даты';
      } else if (this.isUnderAge) {
        warnings.birth_date = 'Обратите внимание! Несовершеннолетний гость';
      }
    }

    if (this.stayFrom) {
      if (isFuture(new Date(this.stayFrom))) {
        warnings.stay_from = 'Дата не может быть позднее текущей даты';
      }

      if (differenceInCalendarDays(new Date(), new Date(this.stayFrom)) > 2) {
        warnings.stay_from = 'Убедитесь, что вы соблюдаете установленный срок постановки на учет';
      }

      if (this.stayTill) {
        if (differenceInCalendarDays(new Date(this.stayTill), new Date(this.stayFrom)) < 0) {
          warnings.stay_till = 'Дата не может быть ранее даты заезда';
        }
      }
    }

    if (this.dateEntryRussia) {
      if (isFuture(new Date(this.dateEntryRussia))) {
        warnings.date_entry_rf = 'Дата не может быть позднее текущей даты';
      }
    }

    return warnings;
  }

  get isPersonValuesValid(): boolean {
    return Object.keys(this.errors).length === 0;
  }

  get breadcrumbTitle() {
    if (this.relatedPersons.length > 1) {
      return '';
    }

    if (this.personPageType === PersonPageType.Create) {
      return this.isBooking ? 'Новое бронирование' : 'Новая регистрация';
    }

    if (this.personPageType === PersonPageType.Edit && this.person) {
      let result = '';

      if (this.person?.fname_lat || this.person?.lname_lat) {
        if (this.person?.fname_lat && this.person?.fname_lat !== FIRST_NAME_IS_EMPTY_LABEL) {
          result += this.person?.fname_lat + ' ';
        }

        if (this.person?.lname_lat && this.person?.lname_lat !== LAST_NAME_IS_EMPTY_LABEL) {
          result += this.person?.lname_lat + ' ';
        }
      }

      if (result.length === 0 && (this.person?.fname || this.person?.lname)) {
        if (this.person?.fname) {
          result += this.person?.fname + ' ';
        }

        if (this.person?.lname) {
          result += this.person?.lname + ' ';
        }
      }

      if (result.length === 0) {
        result = 'Карта гостя';
      }

      return result;
    }

    return 'Карта гостя';
  }

  constructor() {
    makeAutoObservable(this, {
      ...[
        'idCard',
        'idCardIndex',
        'rightOfStay',
        'rightOfStayIndex',
        'migrationCard',
        'migrationCardIndex',
        'documents',
      ].reduce(
        (settings, key) => ({
          ...settings,
          [key]: computed({
            equals: (a, b) => JSON.stringify(a) === JSON.stringify(b),
          }),
        }),
        {},
      ),
    });

    reaction(
      () => ({ isKSPaidStatusModalOpen: this.isKSPaidStatusModalOpen, stayDays: this.stayDays }),
      (state) => {
        if (state.isKSPaidStatusModalOpen) {
          // может быть случай, когда дата выезда меньше даты заезда, это ошибка
          // в этом случае показываем модалку с вводом валидной даты выезда
          if (state.stayDays === null || state.stayDays < 0) {
            this.shouldShowStayTill = true;
          } else {
            this.shouldShowStayTill = false;
          }
        }
      },
    );
  }

  initPersonStore = (navigate: PersonPageProps['navigate'], id?: string, groupId?: string) => {
    runInAction(() => {
      this.isStoreLoaded = false;
    });

    this.navigate = navigate;

    this.id = id ?? null;
    this.groupId = groupId ?? null;
    this.personPageType = getPersonPageType(id);

    /*
     * Когда пользователь зашел на страницу регистрации, не сохранил персона и пытается закрыть браузер или таб,
     * вызываться alert который будет требовать подтверждения закрытия
     *
     * Реализация взята отсюда: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
     * */
    window.addEventListener('beforeunload', this.onConfirmClose);

    return this.fetchPersonData();
  };

  resetPersonStore = () => {
    runInAction(() => {
      this.isStoreLoaded = false;
    });

    this.id = null;
    this.personData = null;
    this.groupId = null;
    this.imagesToRemove = [];
    this.logusPersons = [];
    this.initialNameValues = {};

    window.removeEventListener('beforeunload', this.onConfirmClose);
  };

  onRefetchPersonData = () => this.fetchPersonData();

  onCreatePerson = async () => {
    if (!this.personData) {
      return null;
    }

    this.validateKSStatus();

    if (window.location.pathname.includes(ROUTE_BOOKINGS)) {
      this.personData.stay_info.hms_status = 1;
    }

    const newPerson = await store.personsStore.createPerson(this.personData);

    this.id = `${newPerson.id}`;
    this.setPersonValues({
      id: newPerson.id,
    });

    const targetGroupId = this.personData.group_id ?? this.groupId;

    if (targetGroupId) {
      await store.groupsStore.reloadGroupData(+targetGroupId);
    }

    if (!this.isIntegration) {
      notificationAPI.success('Карта гостя была создана');
    }

    if (this.isKSEnabled && this.personData?.ks_info?.is_paid === KSPaidStatus.Unknown) {
      this.openKSPaidStatusModalOpen();
    } else {
      // if (this.isIntegration) {
      //   notificationAPI.success('Сохранено успешно, страница будет закрыта автоматически');
      //
      //   setTimeout(() => window.close(), 1000);
      // }
    }

    this.personPageType = PersonPageType.Edit;

    return newPerson;
  };

  onUpdatePerson = async () => {
    if (!this.personData) {
      return;
    }

    this.validateKSStatus();

    await store.personsStore.updatePerson(this.personId, this.personData);

    if (this.isKSEnabled && this.personData?.ks_info?.is_paid === KSPaidStatus.Unknown) {
      this.openKSPaidStatusModalOpen();
    } else {
      // if (this.isIntegration) {
      //   notificationAPI.success('Сохранено успешно, страница будет закрыта автоматически');
      //
      //   setTimeout(() => window.close(), 1000);
      // }
    }
  };

  setPersonValues = (values: Partial<PersonDetail> & Record<string, unknown>, state?: PersonFormRouteState) => {
    const { userData } = store.signInStore;

    let nextValues = {};

    /*
     * Код ниже позволяет обновлять персона, передавая вложенные свойства в виде цепочки
     *
     * Например:
     *   {
     *     "reg_info.stay_from": new Date().toIsoString()
     *   }
     * */
    Object.keys(values).forEach((key) => {
      nextValues = deepMerge(nextValues, dotStringToObject(key, values[key]));

      if (['lname', 'lname_lat', 'fname', 'fname_lat', 'mname', 'mname_lat'].includes(key)) {
        if (typeof values[key] === 'string') {
          nextValues[key] = (values[key] as string).toUpperCase();
        }
      }
    });

    // берем текущие данные персона
    const person = toJS(this.person ?? {});

    // мержим текущие данные с новыми и заодно сериализуем
    const nextPersonData = serializePersonFormValues(
      deepMerge(person, nextValues),
      this.groupId,
      userData?.default_group_id,
      state,
    );

    runInAction(() => {
      this.personData = nextPersonData;

      /*
       * Логика обновления следующая:
       * если значение в сторе не пустое, то обновляем `initialNameValues`,
       * если значение в сторе пустое, то перезаписываем на значение, которое уже есть
       * */
      if (this.person?.fname || this.person?.fname_lat || this.person?.lname || this.person?.lname_lat) {
        this.initialNameValues = {
          fname: person.fname?.length ? person.fname : this.initialNameValues.fname,
          fname_lat: person.fname_lat?.length ? person.fname_lat : this.initialNameValues.fname_lat,
          lname: person.lname?.length ? person.lname : this.initialNameValues.lname,
          lname_lat: person.lname_lat?.length ? person.lname_lat : this.initialNameValues.lname_lat,
        };
      }
    });

    /*
     * Ниже реализовано автоосхранение персона после внесения любых изменений
     * */
    // if (personPageType === PersonPageType.Edit) {
    //   updateRequestTimeout && clearTimeout(updateRequestTimeout);
    //   updateRequestTimeout = setTimeout(() => {
    //     updatePerson(
    //       id,
    //       serializePersonFormValues(
    //         {
    //           ...(person ?? {}),
    //           ...(newPersonValues as PersonDetail),
    //         },
    //         groupId,
    //         userData?.default_group_id,
    //       ),
    //     )
    //       .then(() => {
    //         setImagesToRemove([]);
    //       })
    //       .catch(showErrorSnackbar);
    //   }, AUTOSAVE_TIMEOUT);
    // }
  };

  setIdCard = (values: Partial<PersonDocument>) => {
    const nextDocuments = [...toJS(this.documents)];

    const [currentIdCard, idCardIndex] = getDocumentByType(nextDocuments, DocumentType.IdCard);
    const nextIdCard = Object.assign(currentIdCard ?? {}, values);

    if (idCardIndex === -1) {
      nextDocuments.push(nextIdCard);
    } else {
      nextDocuments[idCardIndex] = nextIdCard;
    }

    this.setPersonValues({
      documents: nextDocuments,
    });
  };

  setRightOfStay = (values: Partial<PersonDocument>) => {
    const nextDocuments = [...toJS(this.documents)];

    const [currentRightOfStay, rightOfStayIndex] = getDocumentByType(nextDocuments, DocumentType.RightOfStay);
    const nextRightOfStay = Object.assign(currentRightOfStay ?? {}, values);

    if (rightOfStayIndex === -1) {
      nextDocuments.push(nextRightOfStay);
    } else {
      nextDocuments[rightOfStayIndex] = nextRightOfStay;
    }

    this.setPersonValues({
      documents: nextDocuments,
    });
  };

  setMigrationCard = (values: Partial<PersonDocument>) => {
    const nextDocuments = [...toJS(this.documents)];

    const [currentMigrationCard, migrationIndex] = getDocumentByType(nextDocuments, DocumentType.Migration);
    const nextMigrationCard = Object.assign(currentMigrationCard ?? {}, values);

    if (migrationIndex === -1) {
      nextDocuments.push(nextMigrationCard);
    } else {
      nextDocuments[migrationIndex] = nextMigrationCard;
    }

    this.setPersonValues({
      documents: nextDocuments,
    });
  };

  addDocument = (target: Nullable<DocumentType>) => {
    const nextDocuments = [...this.documents];

    if (target === DocumentType.IdCard) {
      nextDocuments.push({
        ...DEFAULT_ID_CARD,
      });
    }

    if (target === DocumentType.RightOfStay) {
      nextDocuments.push({
        ...DEFAULT_RIGHT_OF_STAY,
      });
    }

    if (target === DocumentType.Migration) {
      nextDocuments.push({
        ...DEFAULT_MIGRATION_CARD,
      });
    }

    this.setPersonValues({
      documents: nextDocuments,
    });
  };

  deleteDocument = (target: Nullable<DocumentType>) => {
    const nextDocuments = [...this.documents];

    if (target === DocumentType.IdCard) {
      const [, idCardIndex] = getDocumentByType(nextDocuments, DocumentType.IdCard);

      nextDocuments.splice(idCardIndex, 1);
    }

    if (target === DocumentType.RightOfStay) {
      const [, rightOfIndex] = getDocumentByType(nextDocuments, DocumentType.RightOfStay);

      nextDocuments.splice(rightOfIndex, 1);
    }

    if (target === DocumentType.Migration) {
      const [, migrationCardIndex] = getDocumentByType(nextDocuments, DocumentType.Migration);

      nextDocuments.splice(migrationCardIndex, 1);
    }

    this.setPersonValues({
      documents: nextDocuments,
    });
  };

  onResetPersonData = () => {
    this.setPersonValues({
      ...DEFAULT_EMPTY_PERSON,
    });

    this.personPageType = PersonPageType.Create;
  };

  updateImagesToRemove = (docIndex: DocumentType, id: number) => {
    if (this.person?.documents) {
      const [document] = getDocumentByType(this.person.documents, docIndex);

      if (document.id) {
        this.imagesToRemove = [...this.imagesToRemove, [document.id as number, id]];
      }
    }
  };

  toggleEmptyNames = (nextFirstNameStateDisabled: boolean, nextLastNameStateDisabled: boolean) => {
    const nextValues = {
      ...toJS(this.person),
    };

    if (nextFirstNameStateDisabled) {
      nextValues.fname = '';
      nextValues.fname_lat = FIRST_NAME_IS_EMPTY_LABEL;
    } else {
      if (this.initialNameValues.fname && this.initialNameValues.fname.length > 0) {
        nextValues.fname = this.initialNameValues.fname;
      }

      if (this.initialNameValues.fname_lat && this.initialNameValues.fname_lat.length > 0) {
        nextValues.fname_lat =
          this.initialNameValues.fname_lat === FIRST_NAME_IS_EMPTY_LABEL ? '' : this.initialNameValues.fname_lat;
      }
    }

    if (nextLastNameStateDisabled) {
      nextValues.lname = '';
      nextValues.lname_lat = LAST_NAME_IS_EMPTY_LABEL;
    } else {
      if (this.initialNameValues.lname && this.initialNameValues.lname.length > 0) {
        nextValues.lname = this.initialNameValues.lname;
      }

      if (this.initialNameValues.lname_lat && this.initialNameValues.lname_lat.length > 0) {
        nextValues.lname_lat =
          this.initialNameValues.lname_lat === LAST_NAME_IS_EMPTY_LABEL ? '' : this.initialNameValues.lname_lat;
      }
    }

    this.setPersonValues(nextValues);
  };

  selectStatus = async ({ currentTarget }: MouseEvent<HTMLLIElement>) => {
    try {
      if (this.personPageType === PersonPageType.Edit && this.personId) {
        await store.personsStore.setStatus(+this.personId, currentTarget.value);

        this.onRefetchPersonData();
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  };

  fetchLogusPersons = async () => {
    try {
      const logusPersons = await store.personsStore.getLogusPerson(this.personId);

      if (!logusPersons) {
        notificationAPI.warning('Совпадения не найдены');

        return;
      }

      if (logusPersons.length > 0) {
        this.logusPersons = logusPersons.reduce<HMSLogusPerson[]>((previousValue, currentValue) => {
          // сначала удаляем дубликаты (их присылает бэк)
          const isDuplicate = previousValue.some(
            (logusPerson) =>
              logusPerson.GenericNo === currentValue.GenericNo &&
              logusPerson.BirthDate === currentValue.BirthDate &&
              logusPerson.ArrivalDate === currentValue.ArrivalDate &&
              logusPerson.DepartureDate === currentValue.DepartureDate,
          );

          if (isDuplicate) {
            return previousValue;
          }

          return previousValue.concat([currentValue]);
        }, []);
      }
    } catch (e) {
      notificationAPI.error('Недостаточно данных для поиска');
    }
  };

  onResetLogusPersons = () => {
    this.logusPersons = [];
  };

  onRepeatRegistration = async () => {
    try {
      if (this.personId) {
        const newPerson = await store.personsStore.copyPerson(+this.personId);

        if (newPerson?.id) {
          const { pathname } = window.location;
          const searchQuery = queryAPI.params;

          this.navigate(
            `${pathname.replace(`${this.personId}`, `${newPerson.id}`)}${queryAPI.generateQuery({
              ...searchQuery,
              page: 0, // при копировании всегда сбрасывать страницу
            })}`,
          );

          // показать уведомления при повторном заезде
          this.checkForBirthdate();
          this.checkForExpiration();

          notificationAPI.success('Карточка гостя успешно скопирована');
        }
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  };

  printImages = async () => {
    if (!this.isPersonHasImages) {
      return;
    }

    store.signInStore.setGlobalFetchingState(true);

    try {
      await store.personsStore.printImagesByPersonId(this.personId);
    } catch (e) {
      notificationAPI.error(e);
    }

    store.signInStore.setGlobalFetchingState(false);
  };

  printDocById = async (docId: StringOrNumber) => {
    store.signInStore.setGlobalFetchingState(true);

    try {
      await store.personsStore.printDocByPersonId(this.personId, docId);
    } catch (e) {
      notificationAPI.error(e);
    }

    store.signInStore.setGlobalFetchingState(false);
  };

  scanSingleImage = async () => {
    try {
      store.personsStore.setScanningState(true);

      const operationId = await store.personsStore.scan(OperationType.ScanSingleImage);

      this.setPendingScanId(operationId);

      // Ниже реализована работа с поллингом, на случай если вебсокет не работает или отвалился
      // эта реализация временная и нужна скорее для подстраховки
      // в ней я не забочусь о типизации и оптимизации работы скрипта.
      // в будущем планируется выпилить поллинг
      // дублируется в файлах:
      // PersonalGuestModal.tsx
      // DocumentImages.tsx
      // ImageBlock.tsx
      if (store.socket && store.socket.readyState !== 1 && operationId) {
        const operation = await store.personsStore.operationPolling(operationId, OperationType.ScanSingleImage);

        const imageEvent = new CustomEvent('polling-event', {
          detail: JSON.stringify({
            id: operation.id,
            type: SocketOperation.Img,
            op_type: OperationType.ScanSingleImage,
            error: '',
          }),
        } as PersonPollingEvent);

        window.dispatchEvent(imageEvent);
      }
    } catch (e) {
      store.personsStore.setScanningState(false);

      notificationAPI.error(e);
    }
  };

  scanQrCode = async (imageBase64: string) => {
    try {
      const blob = await base64toBlob(imageBase64);

      if (blob) {
        const result = await QrScanner.scanImage(blob, {
          returnDetailedScanResult: true,
        });

        if (result.data.includes('http://') || result.data.includes('https://')) {
          window.open(result.data, '_blank');
        }
      }
    } catch (e) {
      notificationAPI.error('QR-код не распознан');
    }
  };

  recognizeImage = async (imageBase64: string) => {
    try {
      const personData = await store.personsStore.recognizeImage(imageBase64);

      if (!personData) {
        return;
      }

      const [IdCardValues, IdCardIndex] = getDocumentByType(this.documents, DocumentType.IdCard);
      const [RightOfStayValues, RightOfStayIndex] = getDocumentByType(this.documents, DocumentType.RightOfStay);

      const documents = this.documents ? [...this.documents] : [];

      const {
        birth_place,
        doc_issue_code,
        doc_issue_authority,
        doc_serial,
        doc_number,
        doc_issue_date,
        images,
        document_type_enum_id,
        doc_start_date,
        doc_id,
        // visit_purpose_id,
        doc_visa_type_id,
        doc_visa_multi_type_id,
        visit_days,
        ...rest
      } = personData;

      let newDocument: Partial<PersonDocument> = IdCardValues ? { ...IdCardValues } : { ...DEFAULT_ID_CARD };

      if (personData.document_type_enum_id === DocumentTypeEnumId.DigitalVisa) {
        newDocument = RightOfStayValues ? { ...RightOfStayValues } : { ...DEFAULT_RIGHT_OF_STAY };
      }

      if (document_type_enum_id) {
        newDocument.document_type_enum_id = document_type_enum_id;
      }

      if (birth_place) {
        newDocument.birth_place = birth_place;
      }

      if (doc_issue_code) {
        newDocument.doc_issue_code = doc_issue_code;
      }

      if (doc_issue_authority) {
        newDocument.doc_issue_authority = doc_issue_authority;
      }

      if (doc_serial) {
        newDocument.doc_serial = doc_serial;
      }

      if (doc_number) {
        // От сервиса может приходить некорректное значение по номеру, поэтому сделали простую логику - взять только последние 6 символов
        newDocument.doc_number = doc_number.substring(doc_number.length - 6, doc_number.length);
      }

      if (doc_issue_date) {
        newDocument.doc_issue_date = doc_issue_date;
      }

      if (doc_start_date) {
        newDocument.doc_start_date = doc_start_date;
      }

      if (doc_id) {
        newDocument.doc_id = doc_id;
      }

      // if (visit_purpose_id) {
      //   newDocument.visit_purpose_id = visit_purpose_id;
      // }

      if (doc_visa_type_id) {
        newDocument.doc_visa_type_id = doc_visa_type_id;
      }

      if (doc_visa_multi_type_id) {
        newDocument.doc_visa_multi_type_id = doc_visa_multi_type_id;
      }

      if (visit_days) {
        newDocument.visit_days = visit_days;
      }

      if (images && images.length > 0) {
        newDocument.images = [
          ...(newDocument.images ?? []),
          ...images.map((image: string) => ({
            data: convertToClearBase64(image),
            not_export: false,
            image_upload_type: 3,
          })),
        ];
      }

      switch (personData.document_type_enum_id) {
        case DocumentTypeEnumId.DigitalVisa:
          if (RightOfStayValues) {
            documents[RightOfStayIndex] = newDocument as PersonDocument;
          } else {
            documents.push(newDocument as PersonDocument);
          }

          break;
        default:
          if (IdCardValues) {
            documents[IdCardIndex] = newDocument as PersonDocument;
          } else {
            documents.push(newDocument as PersonDocument);
          }

          break;
      }

      this.setPersonValues({
        ...rest,
        documents,
      });
    } catch (e) {
      notificationAPI.error('Ошибка');
    }
  };

  setSavingState = (state: boolean) => {
    runInAction(() => {
      this.isSaving = state;
    });
  };

  getValuesFromDocument = (document: Partial<PersonDocument>): Partial<PersonDetail> => {
    const formValues: Partial<Omit<PersonDetail, 'documents'>> = {};

    if (!this.personData?.fname) {
      if (document.fname_loc) {
        formValues.fname = document.fname_loc;
      } else if (document.document_type_enum?.id === 20) {
        // если иностранный паспорт и национальные имя и отчество в personal отсутсвуют
        // то подставляем с латиницы
        formValues.fname = document.fname_lat ?? '';
      }
    } else {
      if (document.document_type_enum && document.fname_loc) {
        // если fname_loc уже заполнен, но был получен "паспорт", "виза" или "электронная виза"
        // то перезаписать fname_loc
        if (
          document.document_type_enum.id === 4 ||
          document.document_type_enum.id === 33 ||
          document.document_type_enum.id === 43
        ) {
          formValues.fname = document.fname_loc;
        }
      }
    }

    if (!this.personData?.lname) {
      if (document.lname_loc) {
        formValues.lname = document.lname_loc;
      } else if (document.document_type_enum && document.document_type_enum.id === 20 && document.lname_lat) {
        // если иностранный паспорт и национальные имя и отчество в personal отсутсвуют
        // то подставляем с латиницы
        formValues.lname = document.lname_lat;
      }
    } else {
      if (document.document_type_enum && document.lname_loc) {
        // если lname_loc уже заполнен, но был получен "паспорт", "виза" или "электронная виза"
        // то перезаписать lname_loc
        if (
          document.document_type_enum.id === 4 ||
          document.document_type_enum.id === 33 ||
          document.document_type_enum.id === 43
        ) {
          formValues.lname = document.lname_loc;
        }
      }
    }

    if (!this.personData?.mname && document.mname_loc) {
      if (document.fname_loc) {
        formValues.mname = document.mname_loc;
      } else if (document.document_type_enum && document.document_type_enum.id === 20 && document.mname_lat) {
        // если иностранный паспорт и национальные имя и отчество в personal отсутсвуют
        // то подставляем с латиницы
        formValues.mname = document.mname_lat;
      }
    } else {
      if (document.document_type_enum && document.mname_loc) {
        // если mname_loc уже заполнен, но был получен "паспорт", "виза" или "электронная виза"
        // то перезаписать mname_loc
        if (
          document.document_type_enum.id === 4 ||
          document.document_type_enum.id === 33 ||
          document.document_type_enum.id === 43
        ) {
          formValues.mname = document.mname_loc;
        }
      }
    }

    if (!this.personData?.fname_lat && document.fname_lat) {
      formValues.fname_lat = document.fname_lat;
    }

    if (!this.personData?.lname_lat && document.lname_lat) {
      formValues.lname_lat = document.lname_lat;
    }

    if (!this.personData?.mname_lat && document.mname_lat) {
      formValues.mname_lat = document.mname_lat;
    }

    if (document.gender) {
      formValues.gender = document.gender;
    }

    if (!this.personData?.birth_date && document.birth_date) {
      formValues.birth_date = document.birth_date !== DEPRECATED_DEFAULT_DATE ? document.birth_date : null;
    }

    if (document.citizenship && this.personData?.citizenship !== document.citizenship) {
      formValues.citizenship = document.citizenship;
    }

    return formValues;
  };

  checkInPerson = async () => {
    if (this.personData) {
      this.setPersonValues({
        stay_info: {
          ...toJS(this.stayInfo),
          hms_status: 3,
        },
      });

      await this.onUpdatePerson();
    }
  };

  getPersonUrl = (id: number) => {
    let uri = `${ROUTE_PERSON}/${id}`;

    const targetGroupId = this.personGroupId ?? this.groupId;

    if (targetGroupId) {
      uri += `/${targetGroupId}`;
    }

    return uri;
  };

  setActiveTab = (tab: Nullable<DocumentType>) => {
    this.tab = tab;
  };

  checkForBirthdate = () => {
    if (this.isUnderAge) {
      notificationAPI.warning('Обратите внимание. Несовершеннолетний гость');
    }

    if (
      this.isBirthdate ||
      (this.birthDate && differenceInCalendarDays(new Date(this.birthDate), addDays(new Date(), -3)) >= 0)
    ) {
      notificationAPI.warning('Обратите внимание! День рождения гостя');
    }
  };

  checkForExpiration = (id?: DocumentTypeEnumId) => {
    let documentToCheck: PersonDocument | undefined;

    this.documents.forEach((document) => {
      const shouldShowNotification =
        document.doc_expire_date &&
        isValid(new Date(document.doc_expire_date)) &&
        new Date(document.doc_expire_date).toISOString() !== DEPRECATED_DEFAULT_DATE;

      if (!documentToCheck && shouldShowNotification) {
        if (typeof id !== 'undefined' && document.id === id) {
          documentToCheck = document;
        } else {
          documentToCheck = document;
        }
      }
    });

    if (!documentToCheck?.doc_expire_date) {
      return;
    }

    const label = getDocumentLabel(documentToCheck.document_type_enum.category);

    // если срок действия истек
    if (differenceInCalendarDays(new Date(documentToCheck.doc_expire_date), new Date()) < 0) {
      notificationAPI.error(`Обратите внимание! Срок действия документа${!id ? ` "${label}"` : ''} истек`, {
        persist: true,
      });
    } else if (differenceInCalendarDays(new Date(documentToCheck.doc_expire_date), addDays(new Date(), -3)) <= 0) {
      // если до истечения срока действия документа осталось менее 3 дней
      notificationAPI.warning(`Обратите внимание! Короткий срок действия${!id ? ` "${label}"` : ''}`, {
        persist: true,
      });
    }
  };

  setPendingScanId = (id: Nullable<number>) => {
    this.pendingScanId = id;
  };

  openKSPaidStatusModalOpen = () => {
    this.isKSPaidStatusModalOpen = true;
  };

  closeKSPaidStatusModalOpen = () => {
    this.isKSPaidStatusModalOpen = false;
  };

  openKSModalOpen = () => {
    this.isKSModalOpen = true;
  };

  closeKSModalOpen = () => {
    this.isKSModalOpen = false;
  };

  getPersonsTransactions = async () => {
    try {
      this.isFetchingTransactions = true;

      const transactions = await store.personsStore.getTransactions(this.personId);

      runInAction(() => {
        if (Array.isArray(transactions)) {
          this.transactions = transactions.map((ksTransaction) => new KSTransaction(ksTransaction));
        }
      });
    } catch (e) {
      notificationAPI.error(e);
    }

    this.isFetchingTransactions = false;
  };

  private readonly fetchPersonData = async () => {
    const { isIntegration, userData, signIn, getUserCompany, subscribeToSocket } = store.signInStore;
    const { getUserDevices } = store.devicesStore;
    const { setSelectedDevice } = store.personsStore;
    const { getPersonById, getPersonsPure } = store.personsStore;
    const {
      countries,
      getCountries,
      personCategories,
      getPersonCategories,
      docTypes,
      getDocTypes,
      entryGates,
      getEntryGates,
      visitGoals,
      getVisitGoals,
      docVisaTypes,
      getDocVisaTypes,
      docVisaVisitTypes,
      getDocVisaVisitTypes,
      docVisaMultiTypes,
      getDocVisaMultiTypes,
      ksReasons,
      getKSReasons,
      ksReasonsDocs,
      getKSReasonsDocs,
    } = store.dictionariesStore;

    const query = queryAPI.params as Partial<IntegrationIncomingQuery>;
    const { password, login, ...rest } = query;

    // переписываем урл без параметров "login" и "password", потому что после авторизации они нам более в урле не нужны
    queryAPI.replaceQueryParams(rest);

    try {
      // сохраняем данные интеграции в хранилище, чтобы достать их позже
      if (isIntegration && Object.keys(query).length > 0) {
        sessionStorage.removeItem(INTEGRATION_DATA_KEY);
        sessionStorage.setItem(INTEGRATION_DATA_KEY, JSON.stringify(query));
      }

      /*
       * В рамках интеграции пользователь может зарегистрировать персона без прохождения авторизации и навигации до страницы
       *
       * В этом случае, авторизуемся используя логин и пароль, если они переданы
       * */
      if (password && login) {
        /*
         * Проверяем, что токена в localStorage нет, иначе авторизация будет происходить при каждой перезагрузке страницы,
         * однако если пользователь уже авторизован и пытается зарегать персона по интеграции,
         * но с другим логином - снова авторизуемся с новым логином
         * */
        if (!localStorage.getItem(AUTH_TOKEN_KEY) || userData?.email !== login) {
          await signIn({
            email: login,
            password: password,
          });
        }
      }

      /*
       * Если при интеграции передается пустой логин и пароль, то мы должны разавторизовать пользователя
       * и попросить его авторизоваться, а после авторизации направить на страницу создания персона
       * */
      if (password === '' && login === '' && localStorage.getItem(AUTH_TOKEN_KEY)) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
      }

      await getUserCompany();

      if (countries.length === 0) {
        await getCountries();
      }

      if (personCategories.length === 0) {
        await getPersonCategories();
      }

      if (docTypes.length === 0) {
        await getDocTypes();
      }

      if (entryGates.length === 0) {
        await getEntryGates();
      }

      if (visitGoals.length === 0) {
        await getVisitGoals();
      }

      if (docVisaTypes.length === 0) {
        await getDocVisaTypes();
      }

      if (docVisaVisitTypes.length === 0) {
        await getDocVisaVisitTypes();
      }

      if (docVisaMultiTypes.length === 0) {
        await getDocVisaMultiTypes();
      }

      if (ksReasonsDocs.length === 0) {
        await getKSReasonsDocs();
      }

      if (this.isIntegration) {
        if (ksReasons.length === 0) {
          await getKSReasons();
        }
      }

      subscribeToSocket();
    } catch (e) {
      return;
    }

    try {
      const fetchedDevices: UserDevice[] = await getUserDevices();

      if (fetchedDevices && fetchedDevices.length > 0) {
        let defaultDeviceId = fetchedDevices[0].id;

        fetchedDevices.forEach(({ is_default, id }: UserDevice) => {
          if (is_default) {
            defaultDeviceId = id;
          }
        });

        setSelectedDevice(defaultDeviceId);
      }

      // Если персон существует и в урле есть `hp_id` и `stay_till`, то проставляем эти данные в соответсвующее поля
      const { stay_till, hp_id, ...other } = rest;

      /*
       * В урле нам надо сохранить `limit` и `page`, потому что после выхода со страницы
       * нужно вернуться на то же состояние таблицы персонов
       * */

      if (this.id && this.personPageType === PersonPageType.Edit) {
        const person: PersonDetail = (await getPersonById(+this.id)) as PersonDetail;

        const stayTill = stay_till?.split('-');
        const stayTillDate = stayTill
          ? getValidDateValue(new Date(`${stayTill[1]}-${stayTill[0]}-${stayTill[2]}`))
          : null;

        let newPerson = {
          ...DEFAULT_EMPTY_PERSON,
          ...person,
          hotel_person_id: hp_id ?? person.hotel_person_id,
          stay_info: {
            ...person.stay_info,
            stay_till: stayTillDate ?? person.stay_info.stay_till,
          },
        };

        const integrationData = sessionStorage.getItem(INTEGRATION_DATA_KEY);

        sessionStorage.removeItem(INTEGRATION_DATA_KEY);

        if (typeof integrationData === 'string') {
          newPerson = {
            ...JSON.parse(integrationData),
            ...newPerson,
          };
        }

        this.setPersonValues(newPerson);

        runInAction(() => {
          this.imagesToRemove = [];
        });

        if (newPerson.shared_group_id !== 0) {
          const response = await getPersonsPure({
            shared_group_id: newPerson.shared_group_id,
          });

          if (response.data) {
            runInAction(() => {
              this.relatedPersons = response.data;
            });
          }
        } else if (
          newPerson?.stay_info?.room_number &&
          newPerson?.stay_info?.stay_from &&
          newPerson?.stay_info?.stay_till
        ) {
          const response = await getPersonsPure({
            stayFrom: newPerson.stay_info.stay_from,
            stayTo: newPerson.stay_info.stay_till,
            room_number: newPerson.stay_info.room_number,
            page: 0,
            limit: 1000,
          });

          if (response.data) {
            runInAction(() => {
              this.relatedPersons = response.data;
            });
          }
        } else {
          runInAction(() => {
            this.relatedPersons = [];
          });
        }

        queryAPI.replaceQueryParams(other);
      }

      /*
       * условие ниже реализует интеграция со сторонними сервисами для создания персонов в нашей системе
       * для этого нужно запускать URL `/hms/person/new?hp_id=123...`
       * если в URL нет `hp_id`, то просто устанавливаем значения по умолчанию для нового персона
       * потому что тогда интеграция не имеет смысла
       * пример полной строки: `/hms/person/new?hp_id=123&birth_date=12-05-2020&stay_from=10-05-2020&stay_till=10-07-2020&room_number=255&lname=Иванов&lname_lat=Ivanov&fname=Иван&fname_lat=Ivan&tel=0-000-000-00-00`
       * */
      if (this.personPageType === PersonPageType.Create) {
        runInAction(() => {
          this.relatedPersons = [];
        });

        if (query.hp_id) {
          this.fetchAndFillPersonDataFromIntegration(query.hp_id);
        } else {
          this.setPersonValues({
            ...DEFAULT_EMPTY_PERSON,
            documents: [pureStructure(DEFAULT_ID_CARD)],
          });

          runInAction(() => {
            this.imagesToRemove = [];
          });

          queryAPI.replaceQueryParams(other);
        }
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    runInAction(() => {
      this.tab =
        this.idCardIndex !== -1
          ? DocumentType.IdCard
          : this.rightOfStayIndex !== -1
            ? DocumentType.RightOfStay
            : this.migrationCardIndex !== -1
              ? DocumentType.Migration
              : null;

      this.isStoreLoaded = true;
    });
  };

  private readonly fetchAndFillPersonDataFromIntegration = async (hotelPersonId: string) => {
    const { getPersonsByHisIdInHotel } = store.personsStore;

    // ищем персона в нашей системе отеля, на случай если такой айди уже существует
    const person: Person | undefined = await getPersonsByHisIdInHotel(hotelPersonId);

    // если существует, то открываем его страницу
    if (person) {
      // удаляем логин и пароль, чтобы сформировать урл без этих данных
      // delete query.login;
      // delete query.password;

      this.navigate(`${ROUTE_PERSON}/${person.id}`);
    } else {
      try {
        const integration = sessionStorage.getItem(INTEGRATION_DATA_KEY);

        if (integration) {
          // если персона нет, проверяем наличие query параметров, чтобы проставить значения новому персону
          const { birth_date, stay_from, stay_till, lname, lname_lat, fname, fname_lat, room_number, email, tel } =
            JSON.parse(integration);

          /*
           * тут поймали небольшой баг, если дата в формате `день-месяц-год`, то new Date преобразует ее в
           * `месяц-день-год`, для этого парсим строку ручками и преобразуем в нужны для Date формат
           * */
          const birthDay = birth_date?.split('-');
          const stayFrom = stay_from?.split('-');
          const stayTill = stay_till?.split('-');

          const stayFromDate = stayFrom
            ? getValidDateValue(new Date(`${stayFrom[1]}-${stayFrom[0]}-${stayFrom[2]}`))
            : null;
          const stayTillDate = stayTill
            ? getValidDateValue(new Date(`${stayTill[1]}-${stayTill[0]}-${stayTill[2]}`))
            : null;
          const birthDayDate = birthDay
            ? getValidDateValue(new Date(`${birthDay[1]}-${birthDay[0]}-${birthDay[2]}`))
            : null;

          this.setPersonValues({
            ...DEFAULT_EMPTY_PERSON,
            hotel_person_id: hotelPersonId,
            birth_date: birthDayDate ?? null,
            stay_info: {
              room_number: room_number ?? '',
              stay_from: stayFromDate ?? null,
              stay_till: stayTillDate ?? null,
            },
            lname: lname ?? '',
            lname_lat: lname_lat ?? '',
            fname: fname ?? '',
            fname_lat: fname_lat ?? '',
            tel: tel ?? '',
            email: email ?? '',
          });
        }
      } catch (e) {
        this.setPersonValues({
          ...DEFAULT_EMPTY_PERSON,
        });
      }

      queryAPI.replacePath('/');
    }
  };

  private readonly onConfirmClose = (e: BeforeUnloadEvent) => {
    if (this.personPageType === PersonPageType.Create && !store.signInStore.isIntegration) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  private readonly validateKSStatus = () => {
    const isUnderAge = this.isUnderAge;

    // может быть случай, когда дата выезда меньше даты заезда, это ошибка
    // в этом случае показываем модалку с вводом валидной даты выезда
    const isShortStay = this.stayDays !== null ? this.stayDays >= 0 && this.stayDays < 2 : false;

    if (isUnderAge) {
      this.setPersonValues({
        'ks_info.reason_id': 2,
      });
    } else if (isShortStay) {
      this.setPersonValues({
        'ks_info.reason_id': 3,
      });
    }

    if (isUnderAge && isShortStay) {
      this.setPersonValues({
        'ks_info.reason_id': 3,
      });
    }

    if (isUnderAge || isShortStay) {
      this.setPersonValues({
        'ks_info.is_paid': KSPaidStatus.NotPaid,
      });
    }

    if (this.ksPaidStatus === KSPaidStatus.NotPaid && this.ksReasonId === 3 && !isShortStay) {
      this.setPersonValues({
        ks_info: {
          is_paid: KSPaidStatus.Unknown,
          reason_id: KSPaidStatus.Unknown,
        },
      });
    }

    if (this.ksPaidStatus === KSPaidStatus.Paid) {
      this.setPersonValues({
        'ks_info.reason_id': 0,
      });
    }

    if (this.personPageType === PersonPageType.Create && this.isKSEnabled && (isUnderAge || isShortStay)) {
      const reason = store.dictionariesStore.ksReasons.find(({ id }) => id === this.ksReasonId);

      if (reason?.short_name) {
        notificationAPI.success(`Установлен льготный статус: "${reason?.short_name}"`);
      }
    }
  };
}

const personStore = new PersonPageStore();
const PersonPageStoreContext = createContext(personStore);

export function PersonPageStoreProvider({ children }: PropsWithChildren<unknown>) {
  return <PersonPageStoreContext.Provider value={personStore}>{children}</PersonPageStoreContext.Provider>;
}

export function usePersonPageStore(): PersonPageStore {
  return useContext(PersonPageStoreContext);
}

export function withPersonPageStore(Component: FunctionComponent<PersonPageProps>) {
  return function withPersonPageStoreComponent(props: PersonPageProps) {
    return (
      <PersonPageStoreProvider>
        <Component {...props} />
      </PersonPageStoreProvider>
    );
  };
}
