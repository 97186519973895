import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const DeviceRawPanel = styled(Accordion)`
  box-shadow: none;

  &.Mui-expanded {
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

export const DeviceRawPanelSummary = styled(AccordionSummary)`
  padding-left: 8px;
`;

export const DeviceRawStatus = styled('div')(
  ({ isActive }: { isActive?: boolean }) => css`
    margin-right: 24px;
    color: ${isActive ? green['700'] : grey['500']};
  `,
);

export const DeviceRawHeading = styled(Typography)`
  flex-basis: 50%;
  flex-shrink: 0;
`;

export const DeviceRawSecondaryHeading = styled(Typography)`
  color: ${grey['500']};
`;
