import { BIRTH_DATE_PART_DEFAULT, Citizen, DEFAULT_COUNTRY, DocumentType, DocumentTypeEnumId } from '@/constants';

import { PersonDetail, PersonDocument } from './PersonsStore.types';

export const EXPORT_POLLING_TIMEOUT = 1000;
export const SOCKET_PING_PONG_TIMEOUT = 5000;

export const SAVING_TIMEOUT = 100;
export const SCAN_TIMEOUT = 30000;

export enum Gender {
  Unknown = 0,
  Male = 1,
  Female = 2,
}

export enum DocumentImageStatus {
  New = 'new',
  Deleted = 'deleted',
  Exporting = 'exporting',
}

export enum SocketOperation {
  Dop = 'dop',
  Img = 'img',
  Exp = 'exp',
  Pdf = 'pdf',
}

export enum OperationType {
  Scan = 1,
  ScanSingleImage = 2,
  Recognize = 3,
}

export enum SocketOperationError {
  Timeout = 'Operation timeout',
  NotDetected = 'PersonDocument not detected',
}

export enum PersonStatus {
  All = 0,
  Registered = 1,
  Pending = 2,
  Accepted = 3,
  Exported = 4, // выгружен
  Forbidden = 5,
  AwaitingRegistration = 6,
}

export enum PrintImageType {
  IdCard = 2,
  RightOfStay = 4,
  Migration = 8,
}

export enum KSImageType {
  Agreement = 1,
  LegalConfirm = 3,
  Decline = 4,
  Notice = 5,
}

export const KS_IMAGE_TYPE_NAME_MAP = {
  [KSImageType.Agreement]: 'Согласие на обработку данных',
  [KSImageType.LegalConfirm]: 'Документ, подтверждающий льготу',
  [KSImageType.Decline]: 'Отказ от полаты',
  [KSImageType.Notice]: 'Уведомление о невозможности удержания КС',
};

export const CLEARABLE_FIELDS = new Set([
  'descr',
  'lname',
  'lname_lat',
  'fname',
  'fname_lat',
  'mname',
  'mname_lat',
  'legal_representative_id',
  'hotel_person_id',
  'arrival_form_number',
  'represent_person',
  'tel',
  'email',
]);

export enum NotificationDelivery {
  Email = 'email',
  Phone = 'sms',
}

export enum NotificationType {
  Inform = 'inform',
  Confirm = 'confirm',
  Confirm2 = 'confirm2',
}

export enum SRMInfoStatus {
  Waiting = 0,
  SentByEmail = 1,
  SentBySMS = 2,
  WaitingForConfirm = 3,
  ConfirmByEmail = 4,
  ConfirmBySMS = 5,
  Manual = 6,
}

export enum NotificationDecision {
  Success,
  Decline,
}

export enum KSPaidStatus {
  Unknown = 0,
  NotPaid = 1,
  Paid = 2,
}

export const KS_PAID_STATUS_MAP = {
  [KSPaidStatus.Unknown]: 'значение не указано',
  [KSPaidStatus.NotPaid]: 'не оплачено',
  [KSPaidStatus.Paid]: 'оплачено',
};

export const DEFAULT_EMPTY_PERSON: PersonDetail = {
  id: 0,
  hotel_person_id: '',
  arrival_form_number: '',
  lname: '',
  fname: '',
  mname: '',
  gender: Gender.Unknown,
  lname_lat: '',
  fname_lat: '',
  mname_lat: '',
  citizenship: DEFAULT_COUNTRY.id,
  country: DEFAULT_COUNTRY,
  birth_date: null,
  birth_date_part: BIRTH_DATE_PART_DEFAULT,
  category_id: 1,
  descr: '',
  status: PersonStatus.Pending,
  documents: [],
  created_at: new Date().toISOString(),
  legal_representative: false,
  legal_representative_id: 0,
  legal_representative_type_id: 0,
  represent_person: null,
  profession: '',
  email: '',
  tel: '',
  reg_info: {
    empty_street: false,
    region_code: '',
    fias_code: '',
    fias_code2: '',
    reg_from: null,
    reg_till: null,
    housing: '',
    housing2: '',
    flat: '',
    prev_empty_street: false,
    prev_region_code: '',
    prev_fias_code: '',
    prev_fias_code2: '',
    prev_housing: '',
    prev_housing2: '',
    prev_flat: '',
    prev_reg_from: null,
    prev_reg_till: null,
    homeless: false,
  },
  stay_info: {
    stay_from: null,
    stay_till: null,
    room_number: '',
    date_entry_rf: null,
  },
  ks_info: {
    is_paid: KSPaidStatus.Unknown,
    reason_id: 0,
  },
  ks_images: [],
};

const DEFAULT_EMPTY_DOCUMENT = {
  document_type_enum_id: '',
  doc_number: '',
  doc_decision_number: '',
  doc_start_date: null,
  doc_issue_code: '',
  device_op_id: 0,
  doc_organ_id: 0,
  doc_issue_date: null,
  doc_serial: '',
  doc_issue_authority: '',
  doc_expire_date: null,
  doc_issue_authority_organ_id: 0,
  doc_issue_authority_organ: null,
  images: [],
  entry_point_id: null,
  visit_purpose_id: +'',
  doc_id: '',
  doc_visa_type_id: +'',
  doc_visa_visit_type_id: +'',
  doc_visa_multi_type_id: +'',
  other: '',
  birth_place: '',
  birth_country_id: null,
  birth_place_region2: '',
  birth_place_region: '',
  birth_place2: '',
  birth_date: null,
  citizenship: Citizen.Russia,
  fname_lat: '',
  fname_loc: '',
  gender: Gender.Unknown,
  lname_lat: '',
  lname_loc: '',
  mname_lat: '',
  mname_loc: '',
  visit_days: '',
};

export const DEFAULT_ID_CARD: PersonDocument = {
  ...DEFAULT_EMPTY_DOCUMENT,
  document_type_enum_id: DocumentTypeEnumId.RusPassport,
  document_type_enum: {
    id: DocumentTypeEnumId.RusPassport,
    category: DocumentType.IdCard,
  },
};

export const DEFAULT_RIGHT_OF_STAY: PersonDocument = {
  ...DEFAULT_EMPTY_DOCUMENT,
  document_type_enum_id: DocumentTypeEnumId.Visa,
  document_type_enum: {
    id: DocumentTypeEnumId.Visa,
    category: DocumentType.RightOfStay,
  },
};

export const DEFAULT_MIGRATION_CARD: PersonDocument = {
  ...DEFAULT_EMPTY_DOCUMENT,
  document_type_enum_id: DocumentTypeEnumId.MigrationCard,
  document_type_enum: {
    id: DocumentTypeEnumId.MigrationCard,
    category: DocumentType.Migration,
  },
};

// когда нужно скрыть "срок действия по"
export const ID_CARD_DOCS_IDS_1 = [DocumentTypeEnumId.BirthCertificate, DocumentTypeEnumId.RusPassport];
// когда нужно показать "код подразделения"
export const ID_CARD_DOCS_IDS_2 = [DocumentTypeEnumId.RusPassport, DocumentTypeEnumId.TempShelterInRus];
// когда нужно показать "номер решения" и "дата решения"
export const ID_CARD_DOCS_IDS_3 = [
  DocumentTypeEnumId.ResidentCardID,
  DocumentTypeEnumId.ResidentCardNoCitizenID,
  DocumentTypeEnumId.ResidentCardNoCitizenBio,
  DocumentTypeEnumId.TempResidentPermitId,
];
// нет валидации у поля "дата выдачи"
export const ID_CARD_DOCS_IDS_4 = [
  DocumentTypeEnumId.TempCertificate,
  DocumentTypeEnumId.CertificateOfIdentification,
  DocumentTypeEnumId.Other,
];
// нет валидации у поля "серия" и "кем выдан"
export const ID_CARD_DOCS_IDS_5 = [
  DocumentTypeEnumId.RusShelterRef,
  DocumentTypeEnumId.ForeignBirthCertificate,
  DocumentTypeEnumId.ForeignPassport,
  DocumentTypeEnumId.USSRPassport,
  DocumentTypeEnumId.BirthCertificateOutsideRus,
  DocumentTypeEnumId.TempCertificate,
  DocumentTypeEnumId.DiplomatPassport,
  DocumentTypeEnumId.CertificateOfReturn,
  DocumentTypeEnumId.ServicePassport,
  DocumentTypeEnumId.PrisonReleaseRef,
  DocumentTypeEnumId.CertificateOfMigrant,
  DocumentTypeEnumId.SNGCertificate,
  DocumentTypeEnumId.KazakhstanCitizen,
  DocumentTypeEnumId.NationalForeignPassport,
  DocumentTypeEnumId.NonCitizenPassport,
  DocumentTypeEnumId.NonCitizenCertificate,
  DocumentTypeEnumId.CertificateOfIdentification,
  DocumentTypeEnumId.Other,
];
// нет валидации только у поля "серия"
export const ID_CARD_DOCS_IDS_6 = [
  DocumentTypeEnumId.DiplomatPassport,
  DocumentTypeEnumId.CertificateOfReturn,
  DocumentTypeEnumId.ServicePassport,
  DocumentTypeEnumId.PrisonReleaseRef,
  DocumentTypeEnumId.CertificateOfMigrant,
  DocumentTypeEnumId.KazakhstanCitizen,
  DocumentTypeEnumId.SNGCertificate,
];
// нет валидации только у поля "кем выдан"
export const ID_CARD_DOCS_IDS_7 = [
  DocumentTypeEnumId.ChildTravelDocument,
  DocumentTypeEnumId.BirthCertificate,
  DocumentTypeEnumId.DiplomatPassport,
];

export enum RecognizeImageFieldName {
  DateOfBirth = 'Date of Birth', // Дата рождения
  DocumentNumber = 'PersonDocument Number', // Номер документа (первые 4 цифры не читать – это серия)
  DocumentSeries = 'PersonDocument Series', // Серия документа
  GivenNames = 'Given Names', // Имя (+ lcidName: “Russian”)
  FathersName = 'Fathers Name', // Отчество (+ lcidName: “Russian”)
  Surname = 'Surname', // Фамилия (+ lcidName: “Russian”)
  Sex = 'Sex', // Пол
  Nationality = 'Nationality', // Национальность
  NationalityCode = 'Nationality Code', // Код национальности
  PlaceOfIssue = 'Place of Issue',
  PlaceOfRegistration = 'Place of Registration',
  PlaceOfBirth = 'Place of Birth', // Место рождения (только с lcidName: “Russian”)
  DateOfIssue = 'Date of Issue', // Дата выдачи
  Authority = 'Authority', // Название места выдачи
  AuthorityCode = 'Authority Code', // Код органа, выдавшего документ
  DocumentFrontSide = 'PersonDocument front side', // Прочие изображения
  VisaNumber = 'Visa Number', // Прочие изображения
  VisaValidFrom = 'Visa Valid From', // Номер документа
  DurationOfStay = 'Duration of Stay', // Срок пребывания
  VisaID = 'Visa ID', // Идентификатор
  Other = 'Other', // Ссылка проверки электронной визы
  PurposeOfEntry = 'Purpose of Entry', // Цель поездки
  VisaType = 'Visa Type', // Вид (категория)
  NumberOfEntries = 'Number of Entries', // Кратность визы
}

export enum HMSStatus {
  Reserved = 1,
  DueIn = 2,
  CheckedIn = 3,
  DueOut = 4,
  CheckedOut = 5,
  Cancelled = 6,
}
