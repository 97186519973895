import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

import { notificationAPI } from '@/api/notificationAPI';
import { useMount } from '@/hooks/useMount/useMount';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, InputField, SwitchField } from '@/ui-kit/components';

import DeviceRaw from './DeviceRaw/DeviceRaw';
import { DevicesListContent, DevicesListHeader, DevicesListList, DevicesListRoot } from './DevicesList.style';

interface DevicesListProps {
  companyId?: number;
  isAdmin?: boolean;
}

function DevicesList(props: DevicesListProps) {
  const { companyId, isAdmin = false } = props;
  const { isFetching, devices, getDevices } = useStore('devicesStore');

  const [filter, setFilter] = useState('');
  const [active, , , toggleActiveFilter] = useToggle(true);
  const [unActive, , , toggleUnActiveFilter] = useToggle(true);

  const onChangeFilter = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => setFilter(currentTarget.value),
    [],
  );

  const filteredDevices = useMemo(() => {
    const result = devices.filter(({ isActive }) => (isActive && active) || (!isActive && unActive));

    if (!filter) {
      return result;
    }

    const filterValue = filter.toLocaleLowerCase();

    return result.filter(
      ({ name, serialNumber }) =>
        name.toLocaleLowerCase().includes(filterValue) || serialNumber?.toLocaleLowerCase().includes(filterValue),
    );
  }, [filter, active, unActive, JSON.stringify(devices)]);

  async function fetchDevices() {
    try {
      await getDevices(true, companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useMount(fetchDevices);

  return (
    <DevicesListRoot>
      <BackdropLoader isLoading={isFetching} />

      <DevicesListHeader>
        <InputField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="inherit" />
              </InputAdornment>
            ),
          }}
          clearable
          name="filter"
          onChange={onChangeFilter}
          size="small"
          value={filter}
        />

        <SwitchField checked={active} label="Активные" onChange={toggleActiveFilter} size="small" />

        <SwitchField checked={unActive} label="Не активные" onChange={toggleUnActiveFilter} size="small" />
      </DevicesListHeader>

      <DevicesListContent>
        <DevicesListList>
          {filteredDevices.map((device) => (
            <DeviceRaw device={device} isAdmin={isAdmin} key={device.id} />
          ))}
        </DevicesListList>
      </DevicesListContent>
    </DevicesListRoot>
  );
}

export default memo(observer(DevicesList));
