import React, { ChangeEvent, Fragment, memo, useCallback } from 'react';
import { FormikState } from 'formik';
import { v4 as uuid } from 'uuid';

import { GroupTemplateValues } from '@/stores/PersonsStore';
import { FileField } from '@/ui-kit/components';

import { GroupFormImages } from './GroupForm.style';
import GroupFormImage from './GroupFormImage/GroupFormImage';

interface GroupFormProps {
  form: FormikState<GroupTemplateValues>;
  onChange: (field: string, value: string[]) => void;
}

export interface ImageWithId {
  image: string;
  id: string;
}

function GroupForm({ form, onChange }: GroupFormProps) {
  const onUploadHandler = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    let imageCounter = 0;
    const images: string[] = [];

    if (currentTarget.value) {
      Object.keys(currentTarget.value).forEach((key: string) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          const image = reader.result as string;

          images.push(image.substring(image.search(',') + 1));

          imageCounter += 1;

          if (currentTarget.value && imageCounter === currentTarget.value.length) {
            onChange('images', images);
          }
        };

        currentTarget.value && reader.readAsDataURL(currentTarget.value[key]);
      });
    }
  }, []);

  const onDeleteHandler = useCallback((i: number) => {
    const images = [...form.values.images];

    images.splice(i, 1);

    onChange('images', images);
  }, []);

  const imagesWithId: ImageWithId[] = form.values.images.map((image: string) => ({
    image,
    id: uuid(),
  }));

  return (
    <Fragment>
      <FileField accept="image/jpeg" label="Добавить изображение" multiple name="images" onChange={onUploadHandler} />

      <GroupFormImages>
        {imagesWithId.length > 0 && (
          <Fragment>
            {imagesWithId.map(({ image, id }: ImageWithId, i: number) => (
              <GroupFormImage image={image} index={i} key={id} onDelete={onDeleteHandler} />
            ))}
          </Fragment>
        )}
      </GroupFormImages>
    </Fragment>
  );
}

export default memo(GroupForm);
