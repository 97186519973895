import React, { ChangeEvent, memo, RefObject, useRef } from 'react';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';

import passportExamplePage1 from '@/assets/passport-example-page-1.png';
import passportExamplePage2 from '@/assets/passport-example-page-2.png';
import { FileField } from '@/ui-kit/components';
import { convertToImageBase64, generateBase64FromBlob } from '@/utils';

import { HELP_MESSAGE, IMAGE_MAX_SIZE_IN_MB } from './DocImages.constants';
import {
  DocImagesContainer,
  DocImagesContent,
  DocImagesContentHeader,
  DocImagesExample,
  DocImagesRoot,
  DocImagesUpload,
} from './DocImages.style';

interface DocImagesProps {
  mode: 'first-page' | 'second-page';
  subTitle: string;
  passportPage: Nullable<string>;
  onUpload: (value: Nullable<string>) => void;
}

function DocImages(props: DocImagesProps) {
  const { passportPage, onUpload, subTitle, mode } = props;

  const passportPageRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  async function onAttachPassportPage({ currentTarget }: ChangeEvent<HTMLInputElement>) {
    onUpload(null);

    if (passportPageRef.current && currentTarget.value) {
      const file = currentTarget.value as unknown as Blob;

      // взято отсюда: https://stackoverflow.com/questions/8471418/how-do-i-convert-file-size-to-mb-only-in-javascript
      const imageSize = file.size / (1024 * 1024);

      if (imageSize > IMAGE_MAX_SIZE_IN_MB) {
        passportPageRef.current.value = '';

        return onShowHelp();
      }

      const imageBase64String = await generateBase64FromBlob(file);

      onUpload(imageBase64String);
    }
  }

  function onShowHelp() {
    alert(HELP_MESSAGE);
  }

  return (
    <DocImagesRoot>
      <div>
        <Typography gutterBottom variant="h6">
          Шаг 2: Изображение документа
        </Typography>

        <Typography variant="subtitle2">{subTitle}</Typography>

        <DocImagesUpload>
          <FileField
            accept="image/png, image/jpeg, image/jpg"
            hideList
            label={passportPage ? 'Заменить изображение' : 'Добавить изображение'}
            name="passportPage"
            onChange={onAttachPassportPage}
            ref={passportPageRef}
            size="large"
            startIcon={passportPage ? <RepeatIcon /> : <PhotoLibraryIcon />}
            variant="outlined"
          />
        </DocImagesUpload>
      </div>

      <DocImagesContainer>
        {!passportPage && (
          <DocImagesContent>
            <DocImagesContentHeader>
              <Typography gutterBottom variant="h6">
                ПРИМЕР
              </Typography>
            </DocImagesContentHeader>

            <DocImagesExample>
              <img
                alt="passport page 1 example"
                onClick={onShowHelp}
                src={mode === 'first-page' ? passportExamplePage1 : passportExamplePage2}
              />
            </DocImagesExample>
          </DocImagesContent>
        )}

        {passportPage && (
          <DocImagesContent>
            <DocImagesContentHeader>
              <Typography gutterBottom variant="h6">
                Ваше изображение
              </Typography>
            </DocImagesContentHeader>

            <DocImagesExample>
              <img alt="passport page 2" src={convertToImageBase64(passportPage)} />
            </DocImagesExample>
          </DocImagesContent>
        )}
      </DocImagesContainer>
    </DocImagesRoot>
  );
}

export default memo(DocImages);
