import React, { Fragment, memo, PropsWithChildren, SyntheticEvent, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import green from '@mui/material/colors/green';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';

import { queryAPI } from '@/api/queryAPI';
import { ROUTE_FALLBACK_LABEL, ROUTE_KS_LIST, ROUTE_PERSON } from '@/constants';
import { KSListItem } from '@/entities/KSListItem';
import { KSPaidStatus } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { pureStructure } from '@/utils';
import { formatAmountRUS } from '@/utils/formatMoney';

import { BookingRowRoot, KSListRowRowName, KSListRowSubName, KSListStayTill } from './KSListRow.style';

interface KSListRowProps {
  ksItem: KSListItem;
}

function KSListRow(props: KSListRowProps) {
  const navigate = useNavigate();
  const { ksItem } = props;
  const { hash } = useLocation();
  const { userCompany } = useStore('signInStore');
  const { ksReasons } = useStore('dictionariesStore');
  const { selectedKSItem, selectKSItem } = useStore('reportsStore');

  const onNavigateToPersonPage = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();

    const params = pureStructure(queryAPI.params);

    params[ROUTE_FALLBACK_LABEL] = ROUTE_KS_LIST;

    navigate(`${ROUTE_PERSON}/${ksItem.id}${queryAPI.generateQuery(params)}`);
  }, []);

  function onSelectKSItem() {
    selectKSItem(ksItem.id);
  }

  // TODO: https://github.com/mui-org/material-ui/issues/4535
  // если вешать клик на строку и при это иметь клики внутри отдельных ячеек,
  // то не будет работать stopPropagation и будут вызваны оба хандлера (на TableRow и на кнопки внутри ячеек)
  function TableCellOpener({ children }: PropsWithChildren<unknown>) {
    return <TableCell onClick={onNavigateToPersonPage}>{children}</TableCell>;
  }

  return (
    <BookingRowRoot hover id={`#person-${ksItem.id}`} isHighlighted={hash === `#person-${ksItem.id}`}>
      <TableCell padding="none">
        <Checkbox checked={selectedKSItem.has(ksItem.id)} color="primary" onChange={onSelectKSItem} />
      </TableCell>

      <TableCellOpener>
        <KSListRowRowName>
          <div>{ksItem.lname}</div>

          <KSListRowSubName>{ksItem.lname_lat}</KSListRowSubName>
        </KSListRowRowName>
      </TableCellOpener>

      <TableCellOpener>
        <KSListRowRowName>
          <div>{ksItem.fname}</div>

          <KSListRowSubName>{ksItem.fname_lat}</KSListRowSubName>
        </KSListRowRowName>
      </TableCellOpener>

      <TableCellOpener>
        <KSListRowRowName>
          <div>{ksItem.mname}</div>

          <KSListRowSubName>{ksItem.mname_lat}</KSListRowSubName>
        </KSListRowRowName>
      </TableCellOpener>

      <TableCellOpener>
        {ksItem.stayFrom && (
          <Fragment>
            <div>{ksItem.stayFrom}</div>

            {ksItem.stayTill && <KSListStayTill>{ksItem.stayTill}</KSListStayTill>}
          </Fragment>
        )}
      </TableCellOpener>

      <TableCellOpener>
        {ksItem.is_paid === KSPaidStatus.Unknown && 'Значение не указано'}

        {ksItem.is_paid === KSPaidStatus.Paid && 'Плательщик'}

        {!ksItem.reason_id && ksItem.is_paid === KSPaidStatus.NotPaid && 'Отказ от оплаты'}

        {Boolean(ksItem.reason_id) &&
          ksItem.is_paid === KSPaidStatus.NotPaid &&
          ksReasons?.find(({ id }) => id === ksItem.reason_id)?.short_name}
      </TableCellOpener>

      <TableCellOpener>{formatAmountRUS(ksItem.fact)}</TableCellOpener>

      <TableCellOpener>{formatAmountRUS(ksItem.expect)}</TableCellOpener>

      <TableCell>
        {userCompany?.company && ksItem.ais_person_id && ksItem.ais_report_id && (
          <Tooltip title="Выгружен в АИС КС">
            <a
              href={`https://spbtursbor.visit-petersburg.ru/guests/edit/${ksItem.ais_report_id}/${userCompany?.company.id}/${ksItem.ais_person_id}`}
              rel="noreferrer"
              target="_blank"
            >
              <CheckCircleIcon fontSize="small" htmlColor={green['500']} />
            </a>
          </Tooltip>
        )}
      </TableCell>
    </BookingRowRoot>
  );
}

export default memo(observer(KSListRow));
