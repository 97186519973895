import { DASHBOARD_ROUTES_MAP } from './AdminPanelTabs.constant';

export function getActiveTabIndex(pathname: string) {
  let index = 0;

  DASHBOARD_ROUTES_MAP.forEach((value: string, i: number) => {
    if (value === pathname) {
      index = i;
    }
  });

  return index;
}
