import { enqueueSnackbar, OptionsObject } from 'notistack';

class NotificationAPI {
  success(message: string, options: Omit<OptionsObject, 'variant'> = {}) {
    enqueueSnackbar(message, { variant: 'success', ...options });
  }

  error(message: Error | string, options: Omit<OptionsObject, 'variant'> = {}) {
    enqueueSnackbar(
      message instanceof Error || (message as unknown as Error)?.message ? (message as Error)?.message : message,
      {
        variant: 'error',
        ...options,
      },
    );

    console.error(message);
  }

  warning(message: string, options: Omit<OptionsObject, 'variant'> = {}) {
    enqueueSnackbar(message, { variant: 'warning', ...options });
  }
}

export const notificationAPI = new NotificationAPI();
