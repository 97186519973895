import styled from '@mui/material/styles/styled';
import Table from '@mui/material/Table';

export const KSListRoot = styled('div')`
  display: flex;
  overflow: hidden;
  height: 100%;
`;

export const KSListContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const KSListWrapper = styled('div')`
  overflow: auto;
  flex-grow: 1;
`;

export const KSListTableContent = styled(Table)`
  table-layout: fixed;
`;

export const TablePaginationContent = styled('div')`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
