import React, { memo } from 'react';
import Grid from '@mui/material/Grid';

import DeviceLicenses from './DeviceLicenses/DeviceLicenses';
import { DevicesRoot } from './Devices.style';
import DevicesList from './DevicesList/DevicesList';

interface DevicesProps {
  companyId?: number;
  isAdmin?: boolean;
}

function Devices(props: DevicesProps) {
  const { companyId, isAdmin = false } = props;

  return (
    <DevicesRoot>
      <Grid container>
        <Grid height="100%" item xs={isAdmin ? 8 : 12}>
          <DevicesList companyId={companyId} isAdmin />
        </Grid>

        {isAdmin && companyId && (
          <Grid height="100%" item xs={4}>
            <DeviceLicenses companyId={companyId} />
          </Grid>
        )}
      </Grid>
    </DevicesRoot>
  );
}

export default memo(Devices);
