import React, { memo } from 'react';
import Typography from '@mui/material/Typography';

import g1logo from '@/assets/g1-logo.png';

import { FinishContent, FinishFooter, FinishIcon, FinishRoot, FinishTitle } from './Finish.style';

function Finish() {
  return (
    <FinishRoot>
      <FinishContent>
        <Typography align="center" gutterBottom>
          <FinishIcon color="success" />
        </Typography>

        <FinishTitle align="center" gutterBottom variant="h6">
          Данные успешно отправлены
        </FinishTitle>

        <Typography align="center" gutterBottom variant="subtitle1">
          После проверки Вам будет отправлено подтверждение и информацияо пребывании.
        </Typography>

        <Typography align="center" gutterBottom variant="subtitle1">
          Желаем комфортного пребывания в нашем отеле!
        </Typography>
      </FinishContent>

      <FinishFooter>
        <img alt="emis logo" height="30" src={g1logo} width="150" />
      </FinishFooter>
    </FinishRoot>
  );
}

export default memo(Finish);
