export const ROUTE_MAIN = '/';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_SIGNIN = '/signin';
export const ROUTE_RESTORE_PASSWORD = '/restore-password';
export const ROUTE_PASSWORD_RECOVERY = '/auth/forgot';
export const ROUTE_SELF_CHEKIN = '/checkin';
export const ROUTE_SELF_CHEKIN_MOBILE = '/srm';
export const ROUTE_VERIFICATION = '/verification';
export const ROUTE_REMOVED = '/removed';
export const ROUTE_REGISTRATIONS = '/registration';
export const ROUTE_BOOKINGS = '/bookings';
export const ROUTE_KS_LIST = '/ks-list';
export const ROUTE_PERSON = '/person';
export const ROUTE_HMS_PERSON = '/hms/person';
export const ROUTE_ROLES = '/roles';
export const ROUTE_REPORTS = '/reports';
export const ROUTE_COMPANIES = '/companies';
export const ROUTE_ALL_DEVICES = '/devices';
export const ROUTE_KS_REPORT = '/ks-report';
export const ROUTE_UPLOAD_IMAGES = '/upload-images';
export const ROUTE_RETENTION = '/retention';
export const ROUTE_BREAKFAST = '/breakfast';

export const ROUTE_USER_DATA = 'user';
export const ROUTE_COMPANY = 'company';
export const ROUTE_COMPANY_GENERAL = 'general';
export const ROUTE_COMPANY_DEVICES = 'devices';
export const ROUTE_COMPANY_USERS = 'users';
export const ROUTE_COMPANY_SETTINGS = 'settings';
export const ROUTE_COMPANY_DATA = 'data';

export const ROUTE_COMPANY_GENERAL_PATH = `${ROUTE_PROFILE}/${ROUTE_COMPANY}/${ROUTE_COMPANY_GENERAL}`;
export const ROUTE_COMPANY_DEVICES_PATH = `${ROUTE_PROFILE}/${ROUTE_COMPANY}/${ROUTE_COMPANY_DEVICES}`;
export const ROUTE_COMPANY_USERS_PATH = `${ROUTE_PROFILE}/${ROUTE_COMPANY}/${ROUTE_COMPANY_USERS}`;
export const ROUTE_COMPANY_SETTINGS_PATH = `${ROUTE_PROFILE}/${ROUTE_COMPANY}/${ROUTE_COMPANY_SETTINGS}`;
export const ROUTE_COMPANY_DATA_PATH = `${ROUTE_PROFILE}/${ROUTE_COMPANY}/${ROUTE_COMPANY_DATA}`;

export const ROUTE_FALLBACK_LABEL = 'fallback';
