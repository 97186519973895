import { css, styled } from '@mui/material/styles';

export const ContentModalHeaderContent = styled('div')(
  ({ align, hasCloseButton }: { align: 'left' | 'center' | 'right'; hasCloseButton: boolean }) => css`
    text-align: ${align};
    padding-right: ${hasCloseButton ? '24px' : 0};
  `,
);

export const ContentModalHeaderClose = styled('div')`
  position: absolute;
  top: 11px;
  right: 7px;
`;
