export const VIDEO_WRAPPER_SIZE_VALUE = 490;

export enum ScaleFactor {
  X1 = 1,
  X2 = 1.5,
  X3 = 2,
}

export const QUALITY_OPTIONS = [
  {
    value: 1,
    label: '100%',
  },
  {
    value: 0.95,
    label: '95%',
  },
  {
    value: 0.9,
    label: '90%',
  },
  {
    value: 0.85,
    label: '85%',
  },
  {
    value: 0.8,
    label: '80%',
  },
  {
    value: 0.75,
    label: '75%',
  },
  {
    value: 0.7,
    label: '70%',
  },
  {
    value: 0.65,
    label: '65%',
  },
  {
    value: 0.6,
    label: '60%',
  },
  {
    value: 0.55,
    label: '55%',
  },
  {
    value: 0.5,
    label: '50%',
  },
  {
    value: 0.45,
    label: '45%',
  },
  {
    value: 0.4,
    label: '40%',
  },
  {
    value: 0.35,
    label: '35%',
  },
  {
    value: 0.3,
    label: '30%',
  },
  {
    value: 0.25,
    label: '25%',
  },
  {
    value: 0.2,
    label: '20%',
  },
  {
    value: 0.15,
    label: '15%',
  },
  {
    value: 0.1,
    label: '10%',
  },
  {
    value: 0.05,
    label: '5%',
  },
];
