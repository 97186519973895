import React, { createRef, memo, useState } from 'react';

import { AnimatedLongValueContent, AnimatedLongValueRoot } from './AnimatedLongValue.styles';

interface AnimatedLongValueProps {
  title?: string;
}

function AnimatedLongValue(props: AnimatedLongValueProps) {
  const { title } = props;

  const rootRef = createRef<HTMLDivElement>();
  const wrapperRef = createRef<HTMLDivElement>();

  const [animateValue, setAnimated] = useState<Nullable<number>>(null);

  if (!title) {
    return null;
  }

  function onMouseEnterHandler() {
    if (wrapperRef.current && rootRef.current) {
      if (wrapperRef.current?.scrollWidth > rootRef.current?.offsetWidth) {
        setAnimated(rootRef.current.offsetWidth - wrapperRef.current.scrollWidth);
      }
    }
  }

  function onMouseLeaveHandler() {
    setAnimated(null);
  }

  return (
    <AnimatedLongValueRoot onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler} ref={rootRef}>
      <AnimatedLongValueContent
        ref={wrapperRef}
        style={{
          transform: animateValue ? `translateX(${animateValue}px)` : 'translateX(0)',
        }}
      >
        {title}
      </AnimatedLongValueContent>
    </AnimatedLongValueRoot>
  );
}

export default memo(AnimatedLongValue);
