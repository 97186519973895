import React, { Fragment, memo, SyntheticEvent, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { IpAddress } from '@/entities/ipAddress';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';

interface IpAddressRowProps {
  ipAddress: IpAddress;
  onChange: (id: number, checked: boolean) => void;
  companyId: number;
  checked: boolean;
  onAfterChange?: () => void;
}

function CompanyIpAddressRow(props: IpAddressRowProps) {
  const { ipAddress, companyId, checked, onAfterChange, onChange } = props;
  const { removeIpAddress, isSavingIpAddress } = useStore('companiesStore');

  const [isDeleteIpAddressModalOpen, setDeleteIpAddressModalOpen] = useState(false);

  async function onDeleteHandler() {
    try {
      if (ipAddress?.id) {
        await removeIpAddress(companyId, ipAddress.id);

        notificationAPI.success('IP-адрес удален');

        onAfterChange?.();

        setDeleteIpAddressModalOpen(false);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function onChangeHandler(e: SyntheticEvent) {
    e.preventDefault();
    onChange(ipAddress.id, !checked);
  }

  const onToggleDeleteIpAddressModal = useCallback(() => {
    setDeleteIpAddressModalOpen((isDeleteIpAddressModalOpen: boolean) => !isDeleteIpAddressModalOpen);
  }, []);

  return (
    <Fragment>
      <Grid alignItems="center" container justifyContent="space-between" onClick={onChangeHandler} spacing={0}>
        <Grid item xs="auto">
          <Checkbox checked={checked} color="primary" />
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap>{ipAddress.name}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Typography noWrap>{ipAddress.ip}</Typography>
        </Grid>

        <Grid item xs="auto">
          <IconButton onClick={onToggleDeleteIpAddressModal}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={
          <Typography variant="body1">
            <span>Вы действительно хотите удалить IP-адрес </span>

            <span>&quot;{ipAddress.name}&quot; </span>

            <span>({ipAddress.ip})?</span>
          </Typography>
        }
        isLoading={isSavingIpAddress}
        onClose={onToggleDeleteIpAddressModal}
        onConfirm={onDeleteHandler}
        onDecline={onToggleDeleteIpAddressModal}
        open={isDeleteIpAddressModalOpen}
        title="Удалить IP-адрес"
      />
    </Fragment>
  );
}

export default memo(observer(CompanyIpAddressRow));
