import { format } from 'date-fns';

import { DATE_HUMAN_FORMAT, DEPRECATED_DEFAULT_DATE } from '@/constants';
import { KSPaidStatus } from '@/stores/PersonsStore';

export class KSListItemModel {
  id!: number;
  fname!: string;
  fname_lat!: string;
  mname!: string;
  mname_lat!: string;
  lname!: string;
  lname_lat!: string;
  fact!: number;
  expect!: number;
  is_paid!: KSPaidStatus;
  reason_id!: number;
  ais_person_id!: string;
  ais_report_id!: string;
  stay_from!: string;
  stay_till!: string;
}

export class KSListItem extends KSListItemModel {
  constructor(ksListItem: KSListItemModel) {
    super();

    Object.keys(ksListItem).forEach((key) => {
      this[key] = ksListItem[key];
    });
  }

  get fullName() {
    let fullName = '';
    let fullNameLat = '';

    if (this.fname) {
      fullName += this.fname;
    }

    if (this.mname) {
      fullName += ` ${this.mname}`;
    }

    if (this.lname) {
      fullName += ` ${this.lname}`;
    }

    if (this.fname_lat) {
      fullNameLat += this.fname_lat;
    }

    if (this.mname_lat) {
      fullNameLat += ` ${this.mname_lat}`;
    }

    if (this.lname_lat) {
      fullNameLat += ` ${this.lname_lat}`;
    }

    return `${fullName}${fullNameLat ? `(${fullNameLat.trim()})` : ''}`.trim();
  }

  get stayFrom() {
    if (typeof this.stay_from !== 'string' || this.stay_from === DEPRECATED_DEFAULT_DATE) {
      return null;
    }

    return format(new Date(this.stay_from), DATE_HUMAN_FORMAT);
  }

  get stayTill() {
    if (typeof this.stay_till !== 'string' || this.stay_till === DEPRECATED_DEFAULT_DATE) {
      return null;
    }

    return format(new Date(this.stay_till), DATE_HUMAN_FORMAT);
  }
}
