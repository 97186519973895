import React, { Fragment, memo } from 'react';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { DATE_HUMAN_FORMAT, TIME_HUMAN_FORMAT_FULL } from '@/constants';
import { Person } from '@/stores/PersonsStore';
import { ContentModal, ContentModalProps } from '@/ui-kit/components';

interface SRMInfoModalProps extends ContentModalProps {
  person: Person;
}

function NotificationHistory(props: SRMInfoModalProps) {
  const { person, open, onClose } = props;

  const { notification_send_at, notification_send_by, confirmation_send_at, confirmation_send_by } =
    person.srm_info ?? {};

  return (
    <ContentModal disableEscapeKeyDown={false} onClose={onClose} open={open}>
      <ContentModal.Header onClose={onClose}>История</ContentModal.Header>

      <ContentModal.Body>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />

              <TableCell>Уведомление</TableCell>

              <TableCell>Подтверждение</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>Пользователь</TableCell>

              <TableCell>{notification_send_by ?? '-'}</TableCell>

              <TableCell>{confirmation_send_by ?? '-'}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Дата</TableCell>

              <TableCell>
                {notification_send_at ? (
                  <Fragment>
                    <div>{format(new Date(notification_send_at), DATE_HUMAN_FORMAT)}</div>

                    <div>{format(new Date(notification_send_at), TIME_HUMAN_FORMAT_FULL)}</div>
                  </Fragment>
                ) : (
                  '-'
                )}
              </TableCell>

              <TableCell>
                {confirmation_send_at ? (
                  <Fragment>
                    <div>{format(new Date(confirmation_send_at), DATE_HUMAN_FORMAT)}</div>

                    <div>{format(new Date(confirmation_send_at), TIME_HUMAN_FORMAT_FULL)}</div>
                  </Fragment>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose}>
          Закрыть
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(NotificationHistory);
