import React, { memo } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs/Breadcrumbs';
import Typography from '@mui/material/Typography/Typography';

import { ROUTE_MAIN } from '@/constants';

import type { Crumbs } from '../RouteConstructor';

import { BreadcrumbsLink } from './BreadcrumbsWrapper.style';

interface BreadcrumbsWrapperProps {
  crumbs?: Nullable<Crumbs[]>;
  homeLinkUri?: string;
}

function BreadcrumbsWrapper(props: BreadcrumbsWrapperProps) {
  const { crumbs, homeLinkUri = ROUTE_MAIN } = props;

  if (!crumbs) {
    return null;
  }

  return (
    <Breadcrumbs separator="›">
      <BreadcrumbsLink to={homeLinkUri}>
        <Typography variant="caption">EMIS Service</Typography>
      </BreadcrumbsLink>

      {crumbs.map((crumb: Crumbs | string) => {
        if (typeof crumb === 'string') {
          return (
            <Typography key={crumb} variant="caption">
              {crumb}
            </Typography>
          );
        }

        if (crumb.path) {
          return (
            <BreadcrumbsLink key={crumb.title} to={crumb.path}>
              <Typography variant="caption">{crumb.title}</Typography>
            </BreadcrumbsLink>
          );
        }

        return (
          <Typography key={crumb.title} variant="caption">
            {crumb.title}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
}

export default memo(BreadcrumbsWrapper);
