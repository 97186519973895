export class UserDeviceModel {
  id!: number;
  name!: string;
  model!: string;
  created_at!: string;
  is_default!: boolean;
  is_connected!: boolean;
  is_operating!: boolean;
  info!: unknown;
}

export class UserDevice extends UserDeviceModel {
  constructor(userDevice: UserDeviceModel) {
    super();

    Object.keys(userDevice).forEach((key) => {
      this[key] = userDevice[key];
    });
  }
}
