import React, { memo, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal, ContentModal, ContentModalProps, SelectField } from '@/ui-kit/components';

interface RetentionConfirmModalProps {
  companyId: number;
}

function RetentionConfirmModal(props: ContentModalProps<RetentionConfirmModalProps>) {
  const { open, onClose, companyId } = props;
  const { clearData, isClearing } = useStore('reportsStore');

  const options = useMemo(() => {
    const result: number[] = [];

    for (let i = 12, max = 24; i <= max; i++) {
      result.push(i);
    }

    return result;
  }, []);

  const [activeOption, setActiveOption] = useState(options[0]);
  const [isConfirmModalOpen, openConfirmModal, closeConfirmModal] = useToggle();

  async function onSubmit() {
    try {
      await clearData(activeOption, companyId);
    } catch (e) {
      notificationAPI.error(e);
    }

    closeConfirmModal();

    onClose();
  }

  return (
    <ContentModal disableEscapeKeyDown={isClearing} maxWidth="xs" onClose={onClose} open={open}>
      <form onReset={onClose}>
        <ContentModal.Header isCloseDisabled={isClearing} onClose={onClose}>
          ВНИМАНИЕ!
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>Персональные данные гостей сроком более</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <SelectField name="option" onChange={(e) => setActiveOption(+e.target.value)} value={activeOption}>
                    {options.map((option: number) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>

                <Grid item xs={8}>
                  <Typography>месяцев</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <span>будут уничтожены </span>

                <strong>
                  <u>без возможности повторного использования или восстановления</u>
                </strong>

                <span>. Продолжить?</span>
              </Typography>
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" type="reset">
            Отмена
          </Button>

          <Button color="error" onClick={openConfirmModal} type="button" variant="contained">
            Уничтожить
          </Button>
        </ContentModal.Footer>
      </form>

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        confirmLabel="Уничтожить"
        description={
          <div>
            <Typography gutterBottom>
              <strong>Уничтожить персональные данные гостей?</strong>

              <br />

              <span>(процесс может занять до нескольких минут)</span>
            </Typography>

            <Typography>По завершении будет сформирован Акт об уничтожении ПДн</Typography>
          </div>
        }
        isLoading={isClearing}
        onClose={closeConfirmModal}
        onConfirm={onSubmit}
        onDecline={closeConfirmModal}
        open={isConfirmModalOpen}
        title="ВНИМАНИЕ!"
      />
    </ContentModal>
  );
}

export default memo(observer(RetentionConfirmModal));
