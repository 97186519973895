import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import QRCode from 'qrcode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';

import { notificationAPI } from '@/api/notificationAPI';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { ContentModal, ContentModalProps } from '@/ui-kit/components';

import { QRCodeModalImage, QRCodeModalLink, QRCodeModalLinkCopy, QRCodeModalLinkUrl } from './QRCodeModal.style';

interface QRCodeModalProps extends ContentModalProps {
  url: string;
  onSuccess: () => void;
}

function QRCodeModal(props: QRCodeModalProps) {
  const { url, open, onClose, onSuccess } = props;

  const [qrCodeBase64, setQRrCodeBase64] = useState<Nullable<string>>(null);
  const [isGenerating, startGeneration, stopGeneration] = useToggle(true);

  const generateQR = async () => {
    startGeneration();

    try {
      setQRrCodeBase64(await QRCode.toDataURL(url));
    } catch (e) {
      notificationAPI.error(e);
    }

    stopGeneration();
  };

  useEffect(() => {
    if (open && url) {
      generateQR();
    }
  }, [open, url]);

  return (
    <ContentModal disableEscapeKeyDown={false} isLoading={isGenerating} onClose={onClose} open={open}>
      <ContentModal.Header onClose={onClose}>QR код</ContentModal.Header>

      <ContentModal.Body>
        <QRCodeModalImage>{qrCodeBase64 && <img alt="" src={qrCodeBase64} width="350" />}</QRCodeModalImage>

        <QRCodeModalLink>
          <QRCodeModalLinkUrl>{url}</QRCodeModalLinkUrl>

          <QRCodeModalLinkCopy>
            <Tooltip title="Скопировать ссылку">
              <IconButton onClick={() => navigator.clipboard.writeText(url)}>
                <ContentCopyIcon color="inherit" />
              </IconButton>
            </Tooltip>
          </QRCodeModalLinkCopy>
        </QRCodeModalLink>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onSuccess}>
          Готово
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(QRCodeModal));
