import React, { Fragment, memo, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { observer } from 'mobx-react-lite';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { Address } from '@/entities/address';
import { Face } from '@/entities/face';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { usePersonPageStore } from '@/routes/Main/OperatorPanel/PersonPage/PersonPageStore';
import { IncomingReport } from '@/shared/PrintForms/IncomingReport/IncomingReport';
import { useStore } from '@/stores/StoreProvider';

import { IncomingReportControl } from './IncomingReportButton.style';
import SelectFaceModal from './SelectFaceModal/SelectFaceModal';

function IncomingReportButton() {
  const { person, regInfo, stayInfo, citizenship, isRussianCitizenship } = usePersonPageStore();
  const { userCompany } = useStore('signInStore');
  const { getStreetObjectById, countries } = useStore('dictionariesStore');

  const componentRef = useRef(null);
  const onPrintHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [factAddress, setFactAddress] = useState<Nullable<Address>>(null);
  const [urAddress, setUrAddress] = useState<Nullable<Address>>(null);
  const [prevAddress, setPrevAddress] = useState<Nullable<Address>>(null);
  const [face, setFace] = useState<Nullable<Face>>(null);
  const [isSelectFaceModalOpen, openFaceModalOpen, closeFaceModalOpen] = useToggle();

  const country = useMemo(() => countries.find(({ id }) => citizenship === id), [citizenship]);

  function onConfirmFace(faceId: number) {
    const face = userCompany?.company?.faces?.find(({ id }) => id === faceId);

    if (face) {
      setFace(face);

      closeFaceModalOpen();

      setTimeout(() => onPrintHandler?.(), 0);
    }
  }

  async function onPrint() {
    if (isRussianCitizenship) {
      return;
    }

    setIsLoading(true);

    try {
      if (regInfo?.prev_fias_code2 && regInfo?.prev_region_code) {
        const prevAddress = await getStreetObjectById(regInfo.prev_fias_code2, regInfo.prev_region_code);

        if (prevAddress) {
          setPrevAddress(prevAddress);
        }
      }

      if (userCompany?.company && stayInfo?.company_id) {
        if (regInfo?.prev_fias_code2 && regInfo?.prev_region_code) {
          const prevAddress = await getStreetObjectById(regInfo.prev_fias_code2, regInfo.prev_region_code);

          if (prevAddress) {
            setPrevAddress(prevAddress);
          }
        }

        const urAddress = await getStreetObjectById(
          userCompany.company.ur_fias_code2,
          userCompany.company.ur_region_code,
        );

        if (urAddress) {
          setUrAddress(urAddress);
        }

        const factAddress = await getStreetObjectById(userCompany.company.fias_code2, userCompany.company.region_code);

        if (factAddress) {
          setFactAddress(factAddress);
        }

        if (userCompany.company.faces && userCompany.company.faces.length > 1) {
          openFaceModalOpen();
        } else {
          if (userCompany.company.faces && userCompany.company.faces.length === 1) {
            setFace(userCompany.company.faces[0]);
          }

          setTimeout(() => {
            onPrintHandler?.();

            // ждем пока проставятся все стейты и только потом запускаем принт
          }, 0);
        }
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    setIsLoading(false);
  }

  return (
    <Fragment>
      <IncomingReportControl>
        <Tooltip
          title={isRussianCitizenship ? 'Доступно только для иностранных граждан' : 'Печать уведомления о прибытии'}
        >
          <IconButton disabled={isLoading} onClick={onPrint}>
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </IncomingReportControl>

      <div style={{ display: 'none' }}>
        <IncomingReport
          citizenship={country}
          company={userCompany?.company ?? null}
          face={face}
          factAddress={factAddress}
          prevAddress={prevAddress}
          ref={componentRef}
          urAddress={urAddress}
          values={person}
        />
      </div>

      {userCompany?.company?.faces && (
        <SelectFaceModal
          faces={userCompany.company.faces}
          onCancel={closeFaceModalOpen}
          onConfirm={onConfirmFace}
          open={isSelectFaceModalOpen}
        />
      )}
    </Fragment>
  );
}

export default memo(observer(IncomingReportButton));
