import React, { memo, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';

import { notificationAPI } from '@/api/notificationAPI';
import { IpAddress } from '@/entities/ipAddress';
import { User } from '@/entities/user';
import { useStore } from '@/stores/StoreProvider';
import { SelectField } from '@/ui-kit/components';

import UserIpAddressesModal from '../UserIpAddressesModal/UserIpAddressesModal';

interface IpWhitelistElementProps {
  userId: number;
  companyId: number;
}

function IpWhitelistElement(props: IpWhitelistElementProps) {
  const { userId, companyId } = props;
  const { getUserById } = useStore('usersStore');

  const [userIpAddresses, setUserIpAddresses] = useState<IpAddress[]>([]);
  const [isUserIpAddressesOpen, setUserIpAddressesOpen] = useState(false);

  async function fetchIpAddressesByUserId() {
    try {
      const userById: User = await getUserById(userId);

      setUserIpAddresses(userById.ip ?? []);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    fetchIpAddressesByUserId();
  }, []);

  const onToggleUserIpAddresses = useCallback(() => {
    setUserIpAddressesOpen((isUserIpAddressesOpen: boolean) => !isUserIpAddressesOpen);
  }, []);

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={10}>
          <SelectField
            IconComponent={() => <div />}
            id="doc-type-gate"
            label="Разрешенные IP-адреса"
            multiple
            name="document_type_enum_id"
            value={userIpAddresses.map(({ id }: IpAddress) => id)}
          >
            {userIpAddresses.map((ipAddress: IpAddress) => (
              <MenuItem key={ipAddress.id} value={ipAddress.id}>
                {ipAddress.name}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs="auto">
          <IconButton color="primary" onClick={onToggleUserIpAddresses}>
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>

      <UserIpAddressesModal
        companyId={companyId}
        onAfterSave={fetchIpAddressesByUserId}
        onClose={onToggleUserIpAddresses}
        open={isUserIpAddressesOpen}
        userId={userId}
        userIpAddresses={userIpAddresses}
      />
    </Grid>
  );
}

export default memo(observer(IpWhitelistElement));
