import React, { memo } from 'react';
import Typography from '@mui/material/Typography';

import IdCardForm from '@/shared/IdCardForm/IdCardForm';
import { PersonDocument } from '@/stores/PersonsStore';

import { DocInfoControls, DocInfoRoot } from './DocInfo.style';

interface DocInfoProps {
  citizenship: Nullable<number>;
  idCard: PersonDocument;
  setIdCardValues: (idCard: PersonDocument) => void;
}

function DocInfo(props: DocInfoProps) {
  const { idCard, setIdCardValues, citizenship } = props;

  return (
    <DocInfoRoot>
      <Typography gutterBottom variant="h4">
        Шаг 4: Реквизиты документа
      </Typography>

      <Typography gutterBottom variant="h5">
        Заполните данные документа, удостоверяющего Вашу личность (Паспорт и т.д.)
      </Typography>

      <DocInfoControls>
        <IdCardForm
          citizenship={citizenship}
          idCard={idCard}
          mode="create"
          setIdCardValues={setIdCardValues}
          variant="outlined"
        />
      </DocInfoControls>
    </DocInfoRoot>
  );
}

export default memo(DocInfo);
