import React, { memo, SyntheticEvent, useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import BusinessIcon from '@mui/icons-material/Business';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { useStore } from '@/stores/StoreProvider';

import { COMPANY_DATA_ROUTES_MAP } from './CompanyData.constants';
import { CompanyDataRoot, CompanyDataSidebar, CompanyDataTab, CompanyDataWrapper } from './CompanyData.style';
import { getActiveTabIndex } from './CompanyData.utils';

function CompanyData() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userData } = useStore('signInStore');

  const onChangeTab = useCallback((e: SyntheticEvent, newValue: number) => {
    e.preventDefault();

    const targetRoute = COMPANY_DATA_ROUTES_MAP.get(newValue);

    if (targetRoute) {
      navigate(targetRoute);
    }
  }, []);

  return (
    <CompanyDataRoot alignItems="stretch" container spacing={2}>
      <Grid item xs={2}>
        <CompanyDataSidebar>
          <Tabs onChange={onChangeTab} orientation="vertical" value={getActiveTabIndex(pathname)}>
            <CompanyDataTab
              icon={<BusinessIcon />}
              iconPosition="start"
              label={
                <Typography align="left" component="div">
                  <Typography variant="subtitle2">Основное</Typography>

                  <Typography align="left" variant="caption">
                    Информация о компании
                  </Typography>
                </Typography>
              }
            />

            <CompanyDataTab
              icon={<ContentCopyIcon />}
              iconPosition="start"
              label={
                <Typography align="left" component="div">
                  <Typography variant="subtitle2">Устройства</Typography>

                  <Typography align="left" variant="caption">
                    Устройства компании
                  </Typography>
                </Typography>
              }
            />

            <CompanyDataTab
              icon={<GroupIcon />}
              iconPosition="start"
              label={
                <Typography align="left" component="div">
                  <Typography variant="subtitle2">Пользователи</Typography>

                  <Typography align="left" variant="caption">
                    Управление аккаунтами
                  </Typography>
                </Typography>
              }
            />

            <CompanyDataTab
              icon={<SettingsIcon />}
              iconPosition="start"
              label={
                <Typography align="left" component="div">
                  <Typography variant="subtitle2">Настройки</Typography>

                  <Typography align="left" variant="caption">
                    Доп. настройки
                  </Typography>
                </Typography>
              }
            />

            <CompanyDataTab
              icon={<StorageIcon />}
              iconPosition="start"
              label={
                <Typography align="left" variant="subtitle2">
                  Данные
                </Typography>
              }
            />
          </Tabs>
        </CompanyDataSidebar>
      </Grid>

      {userData && (
        <Grid item xs={10}>
          <CompanyDataWrapper>
            <Outlet />
          </CompanyDataWrapper>
        </Grid>
      )}
    </CompanyDataRoot>
  );
}

export default memo(CompanyData);
