import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';

import IdCardForm from '@/shared/IdCardForm/IdCardForm';

import { usePersonPageStore } from '../../../PersonPageStore';

import { validateIdCardValues } from './IdCard.utils';

function IdCard() {
  const { idCard, setIdCard, citizenship, isExported, isDeleted, personPageType, status } = usePersonPageStore();

  if (!idCard) {
    return null;
  }

  return (
    <IdCardForm
      citizenship={citizenship}
      disabled={isExported || isDeleted}
      errors={validateIdCardValues(idCard)}
      idCard={idCard}
      isDeleted={isDeleted}
      mode={personPageType}
      setIdCardValues={setIdCard}
      status={status}
    />
  );
}

export default memo(observer(IdCard));
