import React, { ChangeEvent, memo, SyntheticEvent, useCallback } from 'react';
import { FormikState } from 'formik';
import { observer } from 'mobx-react-lite';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { DictionaryBasic } from '@/entities/dictionary';
import { MigrationTemplateForm } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField, DatepickerField, InputField, SelectField } from '@/ui-kit/components';

interface MigrationFormProps {
  form: FormikState<MigrationTemplateForm>;
  onChange: (field: string, value: unknown) => void;
}

const MigrationForm = observer((props: MigrationFormProps) => {
  const { form, onChange } = props;
  const { entryGates, visitGoals } = useStore('dictionariesStore');

  const onDocIssueChangeHandler = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => onChange('doc_issue_date', target.value),
    [],
  );
  const onDocExpireChangeHandler = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => onChange('doc_expire_date', target.value),
    [],
  );
  const onDocSerialChangeHandler = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => onChange('doc_serial', currentTarget.value),
    [],
  );
  const onVisitGoalsChangeHandler = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => onChange('visit_purpose_id', target.value),
    [],
  );

  const onEntryGateChange = useCallback((entryGate: unknown, e: SyntheticEvent) => {
    e.preventDefault();

    onChange('entry_point_id', entryGate ? (entryGate as DictionaryBasic).id : null);
  }, []);

  const renderOption = useCallback(({ name_ru }: DictionaryBasic) => `${name_ru}`, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DatepickerField
              error={!!form.errors.doc_issue_date}
              helperText={form.errors.doc_issue_date}
              label="Срок пребывания в РФ с"
              onChange={onDocIssueChangeHandler}
              size="small"
              value={form.values.doc_issue_date}
            />
          </Grid>

          <Grid item xs={4}>
            <DatepickerField
              error={!!form.errors.doc_expire_date}
              helperText={form.errors.doc_expire_date}
              label="Срок пребывания в РФ до"
              onChange={onDocExpireChangeHandler}
              size="small"
              value={form.values.doc_expire_date}
            />
          </Grid>

          <Grid item xs={4}>
            <InputField
              error={!!form.errors.doc_serial}
              helperText={form.errors.doc_serial}
              label="Серия"
              onChange={onDocSerialChangeHandler}
              size="small"
              value={form.values.doc_serial}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl disabled={entryGates.length === 0} fullWidth>
              <AutocompleteField
                getOptionLabel={renderOption}
                isError={!!form.errors.entry_point_id}
                label="КПП въезда"
                name="entry_point_id"
                onChange={onEntryGateChange}
                options={[...entryGates]}
                value={entryGates.find(({ id }) => id === form.values.entry_point_id)}
              />
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <SelectField
              disabled={visitGoals.length === 0}
              label="Цель поездки"
              onChange={onVisitGoalsChangeHandler}
              value={form.values.visit_purpose_id}
            >
              {visitGoals.map(({ id, name_ru }) => (
                <MenuItem key={id} value={id}>
                  {name_ru}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default memo(MigrationForm);
