import downloadjs from 'downloadjs';
import printJS from 'print-js';

import { queryAPI } from '@/api/queryAPI';
import { AUTH_ETOKEN_KEY, AUTH_TOKEN_KEY, CONTENT_TYPE_MAP, ROUTE_SIGNIN, StateCode } from '@/constants';
import { getFileNameFromUrl, pureStructure } from '@/utils';
import { getSignInPath } from '@/utils/getSignInPath';
import { isArray } from '@/utils/isArray';

export type FetchApiQuery =
  | Partial<{
      [key: string]: Any;
    }>
  | string;
type FetchApiBody = FormData | unknown;

type RequestOptions = Omit<RequestInit, 'body' | 'headers'> &
  Partial<{
    body: FetchApiBody;
    query: FetchApiQuery;
    group: StringOrNumber;
    headers: Partial<Record<string, Nullable<StringOrNumber>>>;
    base: string;
    responseType: 'json' | 'text' | 'blob' | 'arrayBuffer';
    repeatRequestTimeout: number;
    repeatRequestRetryTimeout: number;
    abortController: AbortController;
  }>;

interface DownloadRequestOptions {
  method?: 'GET' | 'POST';
  base?: string;
  body?: FetchApiBody;
  query?: FetchApiQuery;
}

type MakeRequestOptions = RequestOptions &
  Partial<{
    query: FetchApiQuery;
    body: FetchApiBody;
  }>;

type FetchApiResponse<T = Response> = Promise<T>;

class ResponseError extends Error {
  message: string;
  response?: Response;

  constructor(message: string, response?: Response) {
    super(message);

    this.message = message;
    this.response = response;
  }
}

class FetchRequest<T = Response> {
  url: string;

  private readonly options: MakeRequestOptions;

  constructor(url: string, options: RequestOptions) {
    this.url = url;
    this.options = options;
  }

  get group() {
    return this.options?.group;
  }

  make(): FetchApiResponse<T> {
    return new Promise((resolve, reject) => {
      const { repeatRequestTimeout = 0, repeatRequestRetryTimeout = 0 } = this.options ?? {};

      let requestTimeout: NodeJS.Timeout;
      let retryTimeout: NodeJS.Timeout;

      function proceedError(e: Error) {
        clearTimeout(requestTimeout);
        clearTimeout(retryTimeout);

        reject(new ResponseError(e.message));
      }

      const requestWrapper = () => {
        this.fetchWrapper()
          .then((response) => resolve(response))
          .catch((e) => {
            // If request was aborted - script will stop executing and not success or error result won't be applied
            if (e.code === 20) {
              return;
            }

            if (!repeatRequestTimeout) {
              return proceedError(e);
            }

            retryTimeout = setTimeout(requestWrapper, repeatRequestRetryTimeout);

            // Be sure that timeout for request initialized only once
            if (!requestTimeout) {
              requestTimeout = setTimeout(() => proceedError(e), repeatRequestTimeout);
            }
          });
      };

      requestWrapper();
    });
  }

  private fetchWrapper() {
    const {
      base = `https://${import.meta.env.EMIS_API_DOMAIN}${import.meta.env.EMIS_API_URI}`,
      query,
      headers,
      body,
      responseType,
      abortController,
      ...rest
    } = this.options ?? {};

    const input = `${base}${this.url}${queryAPI.generateQuery(query)}`;

    return fetch(input, {
      method: this.options.method,
      headers: this.prepareHeaders(headers),
      body: this.prepareBody(body),
      signal: abortController?.signal,
      ...rest,
    }).then((response: Response) => {
      if (!response.ok) {
        return this.prepareError(response).then((error: ResponseError) => {
          throw error;
        });
      }

      if (response.status === 204) {
        return response;
      }

      switch (responseType) {
        case 'text':
          return response.text();
        case 'blob':
          return response.blob().then((file) => [file, response]);
        case 'arrayBuffer':
          return response.arrayBuffer();
        case 'json':
        default:
          return response.json();
      }
    });
  }

  private prepareHeaders(headers: RequestOptions['headers'] = {}): HeadersInit {
    const token = localStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_ETOKEN_KEY);
    const requestHeaders: Partial<Record<string, unknown>> = {
      'Content-Type': 'application/json',
      'user-token': token,
      ...headers,
    };

    /*
     * Если токен авторизации есть в localStorage, но нам нужно отправить запрос без него,
     * можно передать
     *   'user-token': null
     * */
    if (!requestHeaders['user-token']) {
      delete requestHeaders['user-token'];
    }

    /*
     * Если передать 'Content-Type': null, заголовок будет удален из запроса
     * */
    if (headers?.['Content-Type'] === null) {
      delete requestHeaders['Content-Type'];
    }

    return requestHeaders as HeadersInit;
  }

  private prepareBody(body?: FetchApiBody): BodyInit | string | undefined {
    if (!body) {
      return undefined;
    }

    if (body instanceof FormData) {
      return body;
    }

    if (isArray(body)) {
      return JSON.stringify(body);
    }

    const result = {};

    Object.keys(pureStructure(body)).forEach((key) => {
      if (
        body[key] === 0 ||
        body[key] === null ||
        body[key] === false ||
        (body[key] !== 0 && body[key]) ||
        (typeof body[key] === 'string' && (body[key] as string).length === 0)
      ) {
        result[key] = body[key];
      }
    });

    return JSON.stringify(result);
  }

  private prepareError(response: Response): Promise<ResponseError> {
    const isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';

    // если ответ 401 и УРЛ запроса содержит текущий origin - значит наш бекенд ответил с ошибкой
    // может быть ситуация, когда мы делаем запрос на сторонний ресурс (интеграция с отелями)
    // и он может ответить 401
    if (response.status === StateCode.Unauthorized && (response.url.includes(location.origin) || isLocalhost)) {
      if (location.pathname !== ROUTE_SIGNIN) {
        localStorage.removeItem('token');
        localStorage.removeItem('etoken');

        window.location.href = getSignInPath();
      }
    }

    if (response?.text) {
      return response.text().then((error) => {
        let message = 'Error';

        try {
          message = JSON.parse(error)?.message ?? message;
        } catch (e) {
          //
        }

        return new ResponseError(message, response);
      });
    }

    if (response?.json) {
      return response.json().then((error) => new ResponseError('Error', error));
    }

    return Promise.resolve(new ResponseError('Failed to fetch', response));
  }
}

class FetchApi {
  private readonly requests: FetchRequest<unknown>[] = [];

  get requestsUrls() {
    return this.requests.map(({ url }) => url);
  }

  get<T>(url: string, query: FetchApiQuery = {}, options: RequestOptions = {}) {
    return this.makeRequest<T>(url, 'GET', {
      ...options,
      query,
    });
  }

  post<T = Response>(url: string, options: RequestOptions = {}) {
    return this.makeRequest<T>(url, 'POST', options);
  }

  put<T>(url: string, options: RequestOptions = {}) {
    return this.makeRequest<T>(url, 'PUT', options);
  }

  remove<T>(url: string, options?: RequestOptions) {
    return this.makeRequest<T>(url, 'DELETE', options);
  }

  patch<T>(url: string, options?: RequestOptions) {
    return this.makeRequest<T>(url, 'PATCH', options);
  }

  download(url: string, options: DownloadRequestOptions = {}) {
    const { method = 'POST', base } = options;

    return this.makeRequest<[Blob, Response]>(url, method, {
      base,
      body: options.body,
      query: options.query,
      headers: {
        'Content-Type': null,
      },
      responseType: 'blob',
    }).then(([file, response]) => {
      const regExp = RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/, 'g');
      const contentDisposition = response.headers.get('Content-Disposition');

      if (contentDisposition) {
        const filename = regExp.exec(contentDisposition);

        filename && downloadjs(file, filename[1].replaceAll('"', ''));
      }

      return response;
    });
  }

  print(url: string, options: DownloadRequestOptions = {}) {
    const { method = 'GET', base } = options;

    return this.makeRequest<[Blob, Response]>(url, method, {
      base,
      body: options.body,
      query: options.query,
      headers: {
        'Content-Type': null,
      },
      responseType: 'blob',
    }).then(([file, response]) => {
      let [filename, extension] = getFileNameFromUrl(url);

      const regExp = RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/, 'g');
      const contentDisposition = response.headers.get('Content-Disposition');

      if (contentDisposition) {
        const filenameFromServer = regExp.exec(contentDisposition);

        if (filenameFromServer) {
          [filename, extension] = getFileNameFromUrl(filenameFromServer[1]);
        }
      }

      if (extension === 'pdf') {
        const blob = new Blob([file], {
          type: CONTENT_TYPE_MAP[extension],
        });

        printJS(URL.createObjectURL(blob));
      } else {
        downloadjs(file, filename);
      }

      return response;
    });
  }

  requestsByGroup(group: FetchRequest['group']) {
    return this.requests.filter((request) => request.group === group);
  }

  private makeRequest<T>(url: string, method: string, options?: MakeRequestOptions): FetchApiResponse<T> {
    const request = new FetchRequest<T>(url, {
      method,
      ...options,
    });

    this.requests.push(request);

    return request.make().finally(() => {
      const requestIndex = this.requests.findIndex((requestElement) => requestElement === request);

      if (requestIndex !== -1) {
        this.requests.splice(requestIndex, 1);
      }
    });
  }
}

export const fetchApi = new FetchApi();
export type { FetchApiResponse, RequestOptions, ResponseError };
