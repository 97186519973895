import { WORKPLACE_ROUTES_MAPPING } from './OperatorPanelTabs.constant';

export function getActiveTabIndex(pathname: string) {
  let index = 0;

  WORKPLACE_ROUTES_MAPPING.forEach((value: string, i: number) => {
    if (value === pathname) {
      index = i;
    }
  });

  return index;
}
