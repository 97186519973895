import React, { ChangeEvent, memo } from 'react';
import Typography from '@mui/material/Typography';

import CitizenshipSelect from '@/shared/CitizenshipSelect/CitizenshipSelect';

import { CitizenshipControls, CitizenshipRoot } from './Citizenship.style';

interface CitizenshipProps {
  citizenship: Nullable<number>;
  onSelectCitizenship: (e: ChangeEvent<HTMLInputElement>) => void;
}

function Citizenship(props: CitizenshipProps) {
  const { citizenship, onSelectCitizenship } = props;

  return (
    <CitizenshipRoot>
      <Typography gutterBottom variant="h4">
        Шаг 1: гражданство
      </Typography>

      <Typography gutterBottom variant="h5">
        Выберите гражданство из списка
      </Typography>

      <CitizenshipControls>
        <CitizenshipSelect isClearable={false} onChange={onSelectCitizenship} value={citizenship} variant="outlined" />
      </CitizenshipControls>
    </CitizenshipRoot>
  );
}

export default memo(Citizenship);
