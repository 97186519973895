import { format, startOfMonth } from 'date-fns';
import { makeAutoObservable, runInAction } from 'mobx';

import { fetchApi, FetchApiQuery } from '@/api/fetchApi';
import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { DATE_FOR_SERVER_FORMAT } from '@/constants';
import { KSListItem } from '@/entities/KSListItem';
import { KSReport, KSReportModel } from '@/entities/KSReport';
import { Retention, RetentionModel } from '@/entities/retention';
import { RetentionPerson, RetentionPersonModel } from '@/entities/retentionPerson';
import { KSPaidStatus } from '@/stores/PersonsStore';
import { store } from '@/stores/Store';
import { pureStructure } from '@/utils';
import { isArray } from '@/utils/isArray';
import { throttle } from '@/utils/throttle';

import { DEFAULT_KS_LIST_FILTER, KSStatusValue, KSVisibleValue, StatisticTimeline } from './ReportsStore.constants';
import { KSListFilter, KSListResponse, StatisticByTimeline, StatisticsResponse } from './ReportsStore.types';

export class ReportsStore {
  isFetching = false;
  isFetchingRetentions = false;
  isFetchingAct = false;
  isFetchingJournal = false;
  isFetchingKSData = false;
  isFetchingKSList = false;
  isClearing = false;
  isClearingAISKS = false;

  statistics: StatisticByTimeline[] = [];
  retentions: Retention[] = [];
  ksReport: Nullable<KSReport> = null;
  totalCount = 0;
  ksList: KSListItem[] = [];

  selectedKSItem = new Map();

  ksListFilter: KSListFilter = pureStructure(DEFAULT_KS_LIST_FILTER);

  private getPersonsAbortController?: AbortController = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get shouldShowKSLoader() {
    return this.isFetchingKSList;
  }

  get total() {
    return this.totalCount;
  }

  getStatisticData = (timeline?: StatisticTimeline, companyId?: number) => {
    this.isFetching = true;

    return fetchApi
      .get<StatisticsResponse>('/statistics/', {
        company_id: companyId,
        timeline,
      })
      .then(({ statistics }: StatisticsResponse) => {
        runInAction(() => {
          this.statistics = statistics;
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  clearData = (option: number, companyId?: number) => {
    this.isClearing = true;

    const query: FetchApiQuery = {
      months: option,
    };

    if (companyId) {
      query.company_id = companyId;
    }

    return fetchApi.remove('/retention/', { query }).finally(() => {
      runInAction(() => {
        this.isClearing = false;
      });
    });
  };

  getRetentionPersonsById = (id: StringOrNumber) =>
    fetchApi
      .get<Nullable<RetentionPersonModel[]>>('/persons-history/', {
        retention_id: id,
      })
      .then((persons) =>
        // на случай если ответ от сервера "null"
        persons && Array.isArray(persons) ? persons.map((person) => new RetentionPerson(person)) : [],
      );

  getRetentionList = (id: StringOrNumber) => {
    this.isFetchingRetentions = true;

    return fetchApi
      .get<RetentionModel[]>('/retention/list/', {
        company_id: id,
      })
      .then((response) => {
        runInAction(() => {
          this.retentions = response.map((retention) => new Retention(retention));
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isFetchingRetentions = false;
        });
      });
  };

  getRetentionAct = (id: number) => {
    this.isFetchingAct = true;

    return fetchApi
      .download('/persons-history/act-docx/', {
        query: {
          retention_id: id,
        },
        method: 'GET',
      })
      .finally(() => {
        runInAction(() => {
          this.isFetchingAct = false;
        });
      });
  };

  getRetentionJournal = (id: number) => {
    this.isFetchingJournal = true;

    return fetchApi
      .download('/persons-history/list-docx/', {
        query: {
          retention_id: id,
        },
        method: 'GET',
      })
      .finally(() => {
        runInAction(() => {
          this.isFetchingJournal = false;
        });
      });
  };

  deletePersonsByToken = (token: string) =>
    fetchApi.put('/retention/', {
      query: {
        token,
      },
    });

  getKSData = (timeline?: string, companyId?: number) => {
    this.isFetchingKSData = true;

    return fetchApi
      .get<KSReportModel>('/statistics/ks/', {
        company_id: companyId,
        timeline,
      })
      .then((data) => {
        runInAction(() => {
          this.ksReport = new KSReport(data);
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isFetchingKSData = false;
        });
      });
  };

  setKSFilter = (ksListFilter: Partial<KSListFilter>, refetchDelay = 0) => {
    const nextFilter = {
      ...this.ksListFilter,
      ...(ksListFilter ?? {}),
    };

    if (!isNaN(+nextFilter.page)) {
      nextFilter.page = +nextFilter.page;
    }

    if (!isNaN(+nextFilter.ais_sync)) {
      nextFilter.ais_sync = +nextFilter.ais_sync;
    }

    if (!isNaN(+nextFilter.limit)) {
      nextFilter.limit = +nextFilter.limit;
    }

    if (!isNaN(+nextFilter.status)) {
      nextFilter.status = +nextFilter.status;
    }

    if (typeof nextFilter.timeline === 'string') {
      nextFilter.timeline = format(startOfMonth(new Date(nextFilter.timeline)), DATE_FOR_SERVER_FORMAT);
    }

    runInAction(() => {
      this.ksListFilter = nextFilter;
    });

    throttle(() => this.getKSList(), refetchDelay);
  };

  resetKSFilter = () => {
    runInAction(() => {
      this.ksListFilter = pureStructure(DEFAULT_KS_LIST_FILTER);
    });
  };

  clearAISKS = () => {
    runInAction(() => {
      this.isClearingAISKS = true;
    });

    return fetchApi
      .post('/persons-ks-clear-sync/', {
        query: {
          id: [...this.selectedKSItem.keys()],
        },
      })
      .then(() => {
        notificationAPI.success('Значения АИС КС выбранных карточек гостей очищены');
        this.resetAllSelectedKSItems();
      })
      .then(() => this.getKSList())
      .catch((e) => notificationAPI.error(e))
      .finally(() => {
        runInAction(() => {
          this.isClearingAISKS = false;
        });
      });
  };

  downloadKSReport = (timeline?: string, companyId?: number) => {
    this.isFetchingKSData = true;

    return fetchApi
      .download('/statistics/ks-operator-report/', {
        method: 'GET',
        query: {
          company_id: companyId,
          timeline,
        },
      })
      .catch(() => notificationAPI.error('Некорректные данные'))
      .finally(() => {
        runInAction(() => {
          this.isFetchingKSData = false;
        });
      });
  };

  getKSList = () => {
    const { timeline, not_equal, status, ais_sync, name } = this.ksListFilter;

    this.isFetchingKSList = true;
    this.getPersonsAbortController = new AbortController();

    const limit = this.ksListFilter.limit;
    const page = this.ksListFilter.page - 1;

    const body: Record<string, Nullable<StringOrNumber | boolean>> = {
      company_id: store.signInStore?.userCompany?.company?.id ?? null,
      timeline,
      not_equal,
      limit,
      offset: page && limit ? page * limit : null,
      name,
    };

    switch (status) {
      case KSStatusValue.Unknown:
        body.is_paid = KSPaidStatus.Unknown;

        break;
      case KSStatusValue.NotPaid:
        body.is_paid = KSPaidStatus.NotPaid;

        break;
      case KSStatusValue.Paid:
        body.is_paid = KSPaidStatus.Paid;

        break;
      case KSStatusValue.UnderAge:
        body.reason_id = 2;

        break;
      case KSStatusValue.ShortStay:
        body.reason_id = 3;

        break;
      case KSStatusValue.Decline:
        body.reason_id = 1;

        break;
      case KSStatusValue.None:
      default:
        break;
    }

    switch (ais_sync) {
      case KSVisibleValue.Exported:
        body.ais_sync = true;

        break;
      case KSVisibleValue.NotExported:
        body.ais_sync = false;

        break;
      case KSVisibleValue.All:
      default:
        break;
    }

    return fetchApi
      .get<KSListResponse>('/statistics/ks-list/', body, { abortController: this.getPersonsAbortController })
      .then((ksList) => {
        queryAPI.replaceQueryParams(this.ksListFilter);

        runInAction(() => {
          this.totalCount = ksList.count;

          if (isArray(ksList?.data)) {
            this.ksList = ksList.data.map((ksListItem) => new KSListItem(ksListItem));
          }
        });

        return ksList;
      })
      .finally(() => {
        runInAction(() => {
          this.isFetchingKSList = false;
        });
      });
  };

  resetKSList = () => {
    runInAction(() => {
      this.ksList = [];
    });
  };

  abortKSList = () => {
    this.getPersonsAbortController?.abort();
  };

  selectKSItem = (id: number) => {
    const newSelected = new Map(this.selectedKSItem);

    newSelected.has(id) ? newSelected.delete(id) : newSelected.set(id, true);

    this.selectedKSItem = newSelected;
  };

  selectAllKSItems = () => {
    const map = new Map();

    if (this.selectedKSItem.size !== 0) {
      this.selectedKSItem = map;

      return;
    } else {
      this.ksList.forEach(({ id }: KSListItem) => {
        map.set(id, true);
      });
    }

    this.selectedKSItem = map;
  };

  resetAllSelectedKSItems = () => {
    this.selectedKSItem = new Map();
  };

  exportSelectedKSItems = (withImages = false) => {
    runInAction(() => {
      this.isFetchingKSList = true;
    });

    return fetchApi
      .post('/persons-ks-sync/', {
        query: {
          id: [...this.selectedKSItem.keys()],
          with_images: withImages,
        },
      })
      .then(() => this.getKSList())
      .catch((e) => notificationAPI.error(e))
      .finally(() => {
        runInAction(() => {
          this.isFetchingKSList = false;
          this.resetAllSelectedKSItems();
        });
      });
  };

  exportSelectedKSItemsWithImages = () => this.exportSelectedKSItems(true);
}
