import React, { memo } from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import logo from '@/assets/big-logo.png';
import { EMAIL_REGEX, ROUTE_MAIN, ROUTE_SIGNIN } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { RestorePasswordForm } from '@/stores/SignInStore';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, InputField } from '@/ui-kit/components';

import {
  RestorePasswordActions,
  RestorePasswordContent,
  RestorePasswordLogo,
  RestorePasswordRoot,
} from './RestorePassword.style';

function RestorePassword() {
  const { isFetching, isSignedIn, restorePassword } = useStore('signInStore');

  const [isShowMessage, showMessage] = useToggle();

  const restoreForm = useFormik<RestorePasswordForm>({
    validateOnMount: false,
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      try {
        await restorePassword(values);

        showMessage();

        notificationAPI.success('Вам было отправлено письмо с инструкциями по восстановлению пароля', {
          persist: true,
        });
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    validate: ({ email }) => {
      const errors: Partial<Record<keyof RestorePasswordForm, string>> = {};

      if (email.length === 0) {
        errors.email = 'Обязательно к заполнению';
      }

      if (email.length > 0 && !EMAIL_REGEX.test(email)) {
        errors.email = 'Неверный формат электронной почты';
      }

      return errors;
    },
  });

  if (isSignedIn) {
    return <Navigate to={{ pathname: ROUTE_MAIN }} />;
  }

  return (
    <RestorePasswordRoot>
      <RestorePasswordContent>
        <header>
          <RestorePasswordLogo>
            <img alt="emis logo" height="65" src={logo} width="210" />
          </RestorePasswordLogo>
        </header>

        <form onSubmit={restoreForm.handleSubmit}>
          <InputField
            autoFocus
            disabled={isFetching || isShowMessage}
            error={!!restoreForm.errors.email}
            helperText={restoreForm.errors.email}
            label="Электронная почта"
            margin="normal"
            name="email"
            onChange={restoreForm.handleChange}
            type="email"
            value={restoreForm.values.email}
          />

          <RestorePasswordActions>
            <Link to={ROUTE_SIGNIN}>Авторизоваться</Link>

            <ButtonLoader disabled={isShowMessage} isLoading={isFetching} type="submit">
              Напомнить пароль
            </ButtonLoader>
          </RestorePasswordActions>
        </form>

        <Typography variant="caption">© {new Date().getFullYear()} G1 SOFTWARE.</Typography>
      </RestorePasswordContent>
    </RestorePasswordRoot>
  );
}

export default memo(observer(RestorePassword));
