import React, { memo, SyntheticEvent, useCallback } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { notificationAPI } from '@/api/notificationAPI';
import { EMAIL_REGEX } from '@/constants';
import { AddCompanyForm } from '@/stores/CompaniesStore';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, InputField, SelectField } from '@/ui-kit/components';

function CreateCompanyModal({ open, onClose }: ContentModalProps) {
  const { isSaving, addCompany, getCompanies } = useStore('companiesStore');
  const { exportTypes } = useStore('dictionariesStore');

  const companyForm = useFormik<AddCompanyForm>({
    validateOnMount: false,
    initialValues: {
      ur_name: '',
      name: '',
      email: '',
      tel: '',
      allow_export: 1,
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await addCompany(values);

        notificationAPI.success('Компания добавлена');

        await getCompanies();

        resetForm();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    onReset: () => onClose(),
    validate: ({ ur_name, email }) => {
      const errors: Partial<Record<keyof AddCompanyForm, string>> = {};

      if (!ur_name) {
        errors.ur_name = 'Обязательно к заполнению';
      }

      if (!email) {
        errors.email = 'Обязательно к заполнению';
      }

      if (!EMAIL_REGEX.test(email)) {
        errors.email = 'Неверный формат';
      }

      return errors;
    },
  });

  const onCloseHandler = useCallback(
    (_?: SyntheticEvent, reason?: string) => {
      if (reason === 'backdropClick' || isSaving) {
        return;
      }

      companyForm.resetForm();
    },
    [isSaving],
  );

  return (
    <ContentModal disableEscapeKeyDown={isSaving} onClose={onCloseHandler} open={open}>
      <form onReset={companyForm.handleReset} onSubmit={companyForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSaving} onClose={companyForm.handleReset}>
          Создать компанию
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                disabled={isSaving}
                error={!!companyForm.errors.ur_name}
                helperText={companyForm.errors.ur_name}
                label="Юридическое наименование"
                name="ur_name"
                onChange={companyForm.handleChange}
                value={companyForm.values.ur_name}
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                disabled={isSaving}
                error={!!companyForm.errors.name}
                helperText={companyForm.errors.name}
                label="Торговое наименование"
                name="name"
                onChange={companyForm.handleChange}
                value={companyForm.values.name}
              />
            </Grid>

            <Grid item xs={6}>
              <InputField
                disabled={isSaving}
                error={!!companyForm.errors.email}
                helperText={companyForm.errors.email}
                label="E-mail"
                name="email"
                onChange={companyForm.handleChange}
                value={companyForm.values.email}
              />
            </Grid>

            <Grid item xs={6}>
              <InputField
                disabled={isSaving}
                error={!!companyForm.errors.tel}
                helperText={companyForm.errors.tel}
                label="Телефон"
                name="tel"
                onChange={companyForm.handleChange}
                value={companyForm.values.tel}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectField
                disabled={isSaving}
                label="Экспорт"
                name="allow_export"
                onChange={companyForm.handleChange}
                value={companyForm.values.allow_export}
              >
                <MenuItem value={0}>Без экспорта</MenuItem>

                {exportTypes.map((exportType) => (
                  <MenuItem key={exportType.id} value={exportType.code}>
                    {exportType.name_ru}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSaving} type="reset">
            Отмена
          </Button>

          <ButtonLoader isLoading={isSaving} type="submit">
            Создать
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(CreateCompanyModal));
