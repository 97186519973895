import React, { ChangeEvent, memo, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { notificationAPI } from '@/api/notificationAPI';
import { License } from '@/entities/license';
import { useMount } from '@/hooks/useMount/useMount';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, ConfirmModal, InputField } from '@/ui-kit/components';

import AddLicenseModal from './AddLicenseModal/AddLicenseModal';
import DeviceLicenseRow from './DeviceLicenseRaw/DeviceLicenseRaw';
import {
  DeviceLicensesContent,
  DeviceLicensesHeader,
  DeviceLicensesList,
  DeviceLicensesRoot,
} from './DeviceLicenses.style';

interface DeviceLicensesProps {
  companyId: number;
}

function DeviceLicenses({ companyId }: DeviceLicensesProps) {
  const { isFetching, isSaving, licenses, getLicenses, deleteLicense } = useStore('deviceLicensesStore');

  const [filter, setFilter] = useState('');
  const [isAddLicenseOpen, , , toggleAddLicenseModal] = useToggle();
  const [licenseToBeDeleted, setLicenseToBeDeleted] = useState<Nullable<License>>(null);

  async function onDeleteLicenseHandler() {
    try {
      if (licenseToBeDeleted) {
        await deleteLicense(licenseToBeDeleted.id);

        onCloseConfirmDeleteModal();

        await getLicenses(companyId);

        notificationAPI.success('Серийный номер удален');
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onCloseConfirmDeleteModal = useCallback(() => setLicenseToBeDeleted(null), []);

  const onChangeFilter = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => setFilter(currentTarget.value),
    [],
  );

  async function fetchLicenses() {
    try {
      await getLicenses(companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useMount(fetchLicenses);

  return (
    <DeviceLicensesRoot>
      <BackdropLoader isLoading={isFetching} />

      <DeviceLicensesHeader>
        <InputField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="inherit" />
              </InputAdornment>
            ),
          }}
          clearable
          name="filter"
          onChange={onChangeFilter}
          size="small"
          value={filter}
        />

        <Button color="primary" onClick={toggleAddLicenseModal} size="small" variant="contained">
          Добавить серийный номер
        </Button>
      </DeviceLicensesHeader>

      <DeviceLicensesContent>
        <DeviceLicensesList>
          {licenses.length > 0 && (
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Серийный номер</TableCell>

                  <TableCell>Серийный код</TableCell>

                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {licenses
                  .filter(({ serial_number }) => serial_number.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
                  .map((license) => (
                    <DeviceLicenseRow key={license.id} license={license} onDelete={setLicenseToBeDeleted} />
                  ))}
              </TableBody>
            </Table>
          )}

          <AddLicenseModal companyId={companyId} onClose={toggleAddLicenseModal} open={isAddLicenseOpen} />

          <ConfirmModal
            confirmButtonProps={{
              color: 'error',
            }}
            description="Вы уверены, что хотите удалить серийный номер?"
            isLoading={isSaving}
            onClose={onCloseConfirmDeleteModal}
            onConfirm={onDeleteLicenseHandler}
            onDecline={onCloseConfirmDeleteModal}
            open={!!licenseToBeDeleted}
            title="Удалить серийный номер"
          />
        </DeviceLicensesList>
      </DeviceLicensesContent>
    </DeviceLicensesRoot>
  );
}

export default memo(observer(DeviceLicenses));
