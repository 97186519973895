import { alpha } from '@mui/material';
import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import styled from '@mui/material/styles/styled';

export const DatepickerFieldRoot = styled('div')`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;

  & input {
    padding-right: 22px;
  }

  // эмулируем ховер, чтобы показать бордер на инпуте, когда наводим на иконку очищения
  &:hover {
    .MuiInputBase-root:not(.Mui-disabled, .Mui-error):before {
      border-bottom: 2px solid ${alpha(common.black, 0.87)};
    }
  }
`;

export const DatepickerFieldResetIcon = styled('div')`
  position: absolute;
  bottom: 1px;
  right: 28px;

  & svg {
    fill: ${grey['500']};
  }
`;
