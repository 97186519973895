import { alpha } from '@mui/material';
import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import yellow from '@mui/material/colors/yellow';
import IconButton from '@mui/material/IconButton';
import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import TableRow from '@mui/material/TableRow';

export const PersonRowRoot = styled(TableRow, {
  shouldForwardProp: (name) => name !== 'isHighlighted',
})(({ isHighlighted }: { isHighlighted?: boolean }) => {
  const highlightStyles = css`
    background-color: ${yellow['100']};
  `;

  return css`
    transition: background-color 3500ms linear;

    ${isHighlighted && highlightStyles}

    &:hover {
      background-color: transparent;

      td {
        background-color: ${alpha(common.black, 0.04)};
      }

      .personRowActionButton {
        opacity: 1;
      }
    }
  `;
});

export const PersonRowActionButton = styled(IconButton)`
  opacity: 0;
`;

export const PersonRowName = styled('div')`
  position: relative;
`;

export const PersonRowLongValueWrapper = styled('div')`
  overflow: hidden;
  white-space: nowrap;
  height: 26px;
`;

export const PersonRowSubName = styled('div')`
  position: absolute;
  font-size: 11px;
  top: 16px;
  color: ${grey['500']};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PersonStayTill = styled('div')`
  text-align: right;
`;
