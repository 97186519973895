import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import { EMIS_THEME } from '@/App.style';

export const FinishRoot = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FinishIcon = styled(CheckCircleIcon)`
  font-size: 40px;
`;

export const FinishTitle = styled(Typography)`
  color: ${EMIS_THEME.palette.success.main};
`;

export const FinishContent = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FinishFooter = styled('div')`
  text-align: center;
`;
