import React, { ChangeEvent, memo, useState } from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { Face } from '@/entities/face';
import { ContentModal, SelectField } from '@/ui-kit/components';

interface SelectFaceModalProps {
  open: boolean;
  faces: Face[];
  onCancel: () => void;
  onConfirm: (id: number) => void;
}

function SelectFaceModal(props: SelectFaceModalProps) {
  const { open, onCancel, faces, onConfirm } = props;

  const [selectedFaceId, setSelectedFace] = useState(faces?.[0].id);

  function onConfirmHandler() {
    if (selectedFaceId) {
      onConfirm(selectedFaceId);
    }
  }

  function onSelectFace(e: ChangeEvent<HTMLInputElement>) {
    setSelectedFace(+e.target.value);
  }

  return (
    <ContentModal onClose={onCancel} open={open}>
      <ContentModal.Header align="center" onClose={onCancel}>
        Выберите ответственного за учет
      </ContentModal.Header>

      <ContentModal.Body>
        {faces && (
          <SelectField onChange={onSelectFace} value={selectedFaceId}>
            {faces.map((face: Face) => (
              <MenuItem key={face.id} value={face.id}>
                <span>{face.face_lname}</span>

                <span> </span>

                <span>{face.face_fname}</span>

                <span> </span>

                <span>{face.face_mname}</span>
              </MenuItem>
            ))}
          </SelectField>
        )}
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onCancel} type="reset" variant="outlined">
          Отмена
        </Button>

        <Button color="primary" onClick={onConfirmHandler} type="button" variant="contained">
          Печать
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(SelectFaceModal);
