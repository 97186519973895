import styled from '@mui/material/styles/styled';

export const HotelReportForm = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const HotelReportActions = styled('div')`
  display: flex;
`;

export const HotelReportSubmitButton = styled('div')`
  margin-right: 24px;
`;
