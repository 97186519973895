import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import { css, styled } from '@mui/material/styles';

export const PersonsList = styled(Grid)`
  position: relative;
  overflow: auto;
  height: 400px;
  width: 100%;
`;

export const PersonsRaw = styled(Grid, {
  shouldForwardProp: (propName) => propName !== 'isActive',
})(
  ({ isActive }: { isActive?: boolean }) => css`
    cursor: pointer;
    background-color: ${isActive ? grey[200] : 'transparent'};
    padding: 10px;
  `,
);
