import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

import { notificationAPI } from '@/api/notificationAPI';
import { Device } from '@/entities/device';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps } from '@/ui-kit/components';

interface ConfirmDeactivateModalProps extends ContentModalProps {
  device: Device;
}

function ConfirmDeactivateModal(props: ConfirmDeactivateModalProps) {
  const { device, open, onClose } = props;
  const { isSaving, activate, getDevices } = useStore('devicesStore');

  async function onDeactivate() {
    try {
      await activate(device.id, false);

      notificationAPI.success('Устройство деактивировано');

      onClose();

      await getDevices(false, device.companyId);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  return (
    <ContentModal disableEscapeKeyDown={isSaving} onClose={onClose} open={open}>
      <ContentModal.Header isCloseDisabled={isSaving} onClose={onClose}>
        Деактивировать устройство
      </ContentModal.Header>

      <ContentModal.Body>
        <DialogContentText>Вы уверены, что хотите деактивировать &quot;{device.name}&quot;?</DialogContentText>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" disabled={isSaving} onClick={onClose}>
          Отмена
        </Button>

        <ButtonLoader color="error" isLoading={isSaving} onClick={onDeactivate}>
          Деактивировать
        </ButtonLoader>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(ConfirmDeactivateModal));
