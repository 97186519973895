import React, { memo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { useStore } from '@/stores/StoreProvider';

import PersonalData from './PersonalData/PersonalData';
import { UserTab } from './UserData.constants';
import { UserDataRoot, UserDataSidebar, UserDataTab, UserDataWrapper } from './UserData.style';

function UserData() {
  const { userData } = useStore('signInStore');

  const [tab, setTab] = useState(UserTab.General);

  function onChangeTab(_: unknown, newTab: UserTab) {
    setTab(newTab);
  }

  return (
    <UserDataRoot alignItems="stretch" container spacing={2}>
      <Grid item xs={2}>
        <UserDataSidebar>
          <Tabs onChange={onChangeTab} orientation="vertical" value={tab}>
            <UserDataTab
              icon={<PersonIcon />}
              iconPosition="start"
              label={
                <div>
                  <Typography align="left" variant="subtitle2">
                    Основное
                  </Typography>

                  <Typography align="left" variant="caption">
                    Настройки профиля
                  </Typography>
                </div>
              }
            />
          </Tabs>
        </UserDataSidebar>
      </Grid>

      {userData && (
        <Grid item xs={10}>
          <UserDataWrapper>{tab === UserTab.General && <PersonalData />}</UserDataWrapper>
        </Grid>
      )}
    </UserDataRoot>
  );
}

export default memo(observer(UserData));
