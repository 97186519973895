import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';

export const PersonFormRoot = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const PersonFormContent = styled('div')`
  padding: 2px;
  overflow: hidden;
  height: 100%;
`;

export const PersonFormContainer = styled(Grid)`
  height: 100%;
  overflow: hidden;
`;

export const PersonFormColumn = styled(Grid)`
  height: 100%;
`;

export const PersonFormData = styled('div')`
  height: 100%;
  overflow: auto;
  padding: 3px;
`;
