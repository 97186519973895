import React, {
  cloneElement,
  Fragment,
  memo,
  MouseEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import Menu from '@mui/material/Menu';
import { MenuProps } from '@mui/material/Menu/Menu';

import { DropdownOptionItem } from './DropdownMenu.styles';

interface DropdownMenuOption {
  label: string;
  onClick: (value: Any) => void;
  value?: Any;
  icon?: ReactElement;
  disabled?: boolean;
}

interface DropdownMenuProps extends Omit<MenuProps, 'open' | 'onClose'> {
  options: DropdownMenuOption[];
  opener?: ReactElement;
  contextOpener?: ReactElement;
}

function DropdownMenu(props: DropdownMenuProps) {
  const { options, opener, contextOpener, ...rest } = props;

  const [docElem, setDocElem] = useState<Nullable<HTMLElement>>(null);

  const onSetMenuElement = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setDocElem(e.currentTarget);
    },
    [setDocElem],
  );
  const onCloseCopyImage = useCallback(() => setDocElem(null), []);

  function onClickOption(option: DropdownMenuOption, e: SyntheticEvent) {
    onCloseCopyImage();
    option.onClick?.(e);
  }

  return (
    <Fragment>
      {opener && cloneElement(opener, { onClick: onSetMenuElement })}

      {contextOpener && cloneElement(contextOpener, { onContextMenu: onSetMenuElement })}

      <Menu anchorEl={docElem} onClose={onCloseCopyImage} open={!!docElem} {...rest}>
        {options.map((option) => (
          <DropdownOptionItem
            disabled={option.disabled}
            key={option.label}
            onClick={(e) => onClickOption(option, e)}
            value={option.value}
          >
            {option.icon}

            <span>{option.label}</span>
          </DropdownOptionItem>
        ))}
      </Menu>
    </Fragment>
  );
}

export type { DropdownMenuOption };
export default memo(DropdownMenu);
