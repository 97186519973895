import common from '@mui/material/colors/common';
import styled from '@mui/material/styles/styled';

export const DocumentImagesHeader = styled('div')`
  position: sticky;
  z-index: 10;
  top: 60px; // отступ сверху от табов (высота + маржин)
  left: 0;
  width: 100%;
  background-color: ${common.white};
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  & input {
    position: absolute;
    top: -1000px;
    left: -1000px;
    opacity: 0;
  },
`;

export const DocumentImagesUploadLabel = styled('label')`
  display: flex;
  align-items: center;
`;
