import { DocumentTypeEnumId } from '@/constants';

export const docsIds1 = [
  DocumentTypeEnumId.ResidentCardRightOfStay,
  DocumentTypeEnumId.ResidentCardNoCitizenRightOfStay,
  DocumentTypeEnumId.Visa,
  DocumentTypeEnumId.TempPermissionNoCitizen,
];
export const docsIds2 = [
  DocumentTypeEnumId.ResidentCardRightOfStay,
  DocumentTypeEnumId.ResidentCardNoCitizenRightOfStay,
  DocumentTypeEnumId.Visa,
  DocumentTypeEnumId.TempPermissionRightOfStay,
  DocumentTypeEnumId.TempPermissionNoCitizen,
  DocumentTypeEnumId.ResidentCardEducation,
];
export const docsIds3 = [
  DocumentTypeEnumId.DigitalVisa,
  DocumentTypeEnumId.ResidentCardRightOfStay,
  DocumentTypeEnumId.ResidentCardNoCitizenRightOfStay,
  DocumentTypeEnumId.Visa,
  DocumentTypeEnumId.TempPermissionRightOfStay,
  DocumentTypeEnumId.TempPermissionNoCitizen,
  DocumentTypeEnumId.ResidentCardEducation,
];
export const docsIds4 = [DocumentTypeEnumId.DigitalVisa, DocumentTypeEnumId.Visa];
export const docsIds5 = [
  DocumentTypeEnumId.ResidentCardRightOfStay,
  DocumentTypeEnumId.ResidentCardNoCitizenRightOfStay,
  DocumentTypeEnumId.TempPermissionRightOfStay,
  DocumentTypeEnumId.TempPermissionNoCitizen,
  DocumentTypeEnumId.ResidentCardEducation,
];
