import React, { memo, useCallback } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { Device } from '@/entities/device';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, InputField } from '@/ui-kit/components';

import { COMPRESSION_MARKS } from './DeviceSettingsModal.constant';

interface DeviceSettingsModalProps extends ContentModalProps {
  device: Device;
}

interface DeviceSettingsForm {
  name: string;
  active: boolean;
  do_barcode: boolean;
  do_mrzocr: boolean;
  do_rfid: boolean;
  do_visualocr: boolean;
  send_photo: boolean;
  compression_level: number;
}

function DeviceSettingsModal(props: DeviceSettingsModalProps) {
  const { device, open, onClose } = props;
  const { isUpdating, getDevices, update } = useStore('devicesStore');

  const form = useFormik<DeviceSettingsForm>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    initialValues: {
      name: device.name,
      active: device.isActive,
      do_barcode: device.info.do_barcode,
      do_mrzocr: device.info.do_mrzocr,
      do_rfid: device.info.do_rfid,
      do_visualocr: device.info.do_visualocr,
      send_photo: device.info.send_photo,
      compression_level: device.info.compression_level,
    },
    onSubmit: async (values) => {
      try {
        await update(device.id, values);

        notificationAPI.success('Устройство обновлено');

        onClose();

        await getDevices(false, device.companyId);
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    onReset: () => onClose(),
    validate: ({ name }) => {
      const errors: Partial<DeviceSettingsForm> = {};

      if (name.length === 0) {
        errors.name = 'Обязательно к заполнению';
      }

      return errors;
    },
  });

  function renderOptionItem(name: string, checked: boolean, label: string) {
    return (
      <FormControlLabel
        control={<Switch checked={checked} color="primary" name={name} onChange={form.handleChange} />}
        disabled={isUpdating}
        label={label}
      />
    );
  }

  const onCompressionLevelChange = useCallback((e: Event, value: number | number[]) => {
    e.preventDefault();
    form.setFieldValue('compression_level', value);
  }, []);

  return (
    <ContentModal disableEscapeKeyDown={isUpdating} onClose={form.handleReset} open={open}>
      <form onReset={form.handleReset} onSubmit={form.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isUpdating} onClose={form.handleReset}>
          Об устройстве
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container direction="row-reverse" spacing={2}>
                <Grid item xs="auto">
                  {renderOptionItem('active', form.values.active, 'Активен')}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <InputField
                autoFocus
                disabled={isUpdating}
                error={!!form.errors.name}
                helperText={form.errors.name}
                label="Название"
                name="name"
                onChange={form.handleChange}
                value={form.values.name}
              />
            </Grid>

            <Grid item xs={6}>
              {renderOptionItem('do_barcode', form.values.do_barcode, 'Штрихкод')}

              {renderOptionItem('do_mrzocr', form.values.do_mrzocr, 'MRZ')}

              {renderOptionItem('send_photo', form.values.send_photo, 'Отправлять фото')}
            </Grid>

            <Grid item xs={6}>
              {renderOptionItem('do_rfid', form.values.do_rfid, 'RFID')}

              {renderOptionItem('do_visualocr', form.values.do_visualocr, 'Визуальную зона')}
            </Grid>

            <Grid item xs={12}>
              <Typography>Уровень компрессии</Typography>

              <Slider
                marks={COMPRESSION_MARKS}
                max={10}
                min={5}
                onChange={onCompressionLevelChange}
                step={1}
                value={form.values.compression_level}
                valueLabelDisplay="auto"
              />
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isUpdating} type="reset">
            Отмена
          </Button>

          <ButtonLoader disabled={!form.dirty} isLoading={isUpdating} type="submit">
            Сохранить
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(DeviceSettingsModal));
