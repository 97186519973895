import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';
import Tab from '@mui/material/Tab';

export const UserDataRoot = styled(Grid)`
  height: 100%;
`;

export const UserDataSidebar = styled('div')`
  border-right: 1px solid ${grey['300']};
  height: 100%;
`;

export const UserDataWrapper = styled(Grid)`
  max-width: 1100px;
  margin: 0 auto;
`;

export const UserDataTab = styled(Tab)`
  &.MuiTab-root {
    justify-content: flex-start;
  }
`;
