import Button from '@mui/material/Button';
import grey from '@mui/material/colors/grey';
import styled from '@mui/material/styles/styled';

export const UserRowRoot = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: ${grey['50']};

    .userRowDetach {
      opacity: 1;
    }
  }
`;

export const UserRowUserName = styled('div')`
  display: flex;
  align-items: center;
  flex-grow: 1;

  & svg {
    color: ${grey['500']};
    margin-right: 8px;
  },
`;

export const UserRowUserDetach = styled(Button)`
  flex-shrink: 0;
  opacity: 0;
`;
