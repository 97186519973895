import styled from '@mui/material/styles/styled';

import { InputField } from '@/ui-kit/components';

export const PasswordWrapper = styled('div')`
  position: relative;
`;

export const PasswordInput = styled(InputField)`
  & input {
    padding-right: 52px
  },
`;

export const ViewPasswordIcon = styled('div')`
  position: absolute;
  top: 17px;
  right: 0;
`;

export const HidePasswordIcon = styled('div')`
  position: absolute;
  top: 17px;
  right: 26px;
`;
