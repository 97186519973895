import grey from '@mui/material/colors/grey';
import red from '@mui/material/colors/red';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';

import { EMIS_BRAND_COLOR } from '@/ui-kit/global-styles';

export const PasswordRecoveryRoot = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`;

export const PasswordRecoveryContent = styled(Paper)`
  width: 350px;
  padding: 16px;
`;

export const PasswordRecoveryLogo = styled('div')`
  padding: 5px 5px 10px;
  border-bottom: 1px solid ${grey['500']};
  text-align: center;
`;

export const PasswordRecoveryError = styled('p')`
  color: ${red['800']};
`;

export const PasswordRecoveryActions = styled('div')`
  margin: 12px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & a {
    color: ${EMIS_BRAND_COLOR};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    },
  },
`;
