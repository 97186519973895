import React, { Fragment, memo, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { NEW_PERSON_ID, ROUTE_BOOKINGS, ROUTE_KS_LIST, ROUTE_REGISTRATIONS, ROUTE_REMOVED } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { CheckInButton } from '@/routes/Main/OperatorPanel/PersonPage/PersonBreadcrumbs/PersonBreadcrumbs.style';
import { usePersonPageStore } from '@/routes/Main/OperatorPanel/PersonPage/PersonPageStore';
import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader } from '@/ui-kit/components';

import { PersonPageType } from '../PersonPage.constant';

import PersonsNavigation from './PersonsNavigation/PersonsNavigation';

function PersonBreadcrumbs() {
  const { isIntegration } = useStore('signInStore');
  const {
    isBooking,
    isKSListItem,
    isDeleted,
    personId,
    groupId,
    relatedPersons,
    personPageType,
    checkInPerson,
    navigate,
    breadcrumbTitle,
    hmsStatus,
  } = usePersonPageStore();

  const [isCheckingIn, enableCheckingIn, disableCheckingIn] = useToggle();

  const breadcrumbsPath = useMemo(() => {
    const { fallback, ...rest } = queryAPI.params;

    let path = ROUTE_REGISTRATIONS;

    if (isBooking) {
      path = ROUTE_BOOKINGS;
    }

    if (isKSListItem) {
      path = ROUTE_KS_LIST;
    }

    if (fallback) {
      path = fallback;
    }

    if (isDeleted) {
      path = ROUTE_REMOVED;
    }

    if (groupId) {
      path += `/${groupId}`;
    }

    path += queryAPI.generateQuery(rest);

    if (personId && personId !== NEW_PERSON_ID && Boolean(+personId)) {
      path += `#person-${personId}`;
    }

    let title = 'Регистрации';

    if (path.includes(ROUTE_BOOKINGS)) {
      title = 'Бронирования';
    }

    if (path.includes(ROUTE_KS_LIST)) {
      title = 'Курортный сбор';
    }

    const result = [{ title, path }, breadcrumbTitle];

    return !isIntegration ? result : null;
  }, [isDeleted, isBooking, groupId, personId, breadcrumbTitle, isIntegration]);

  async function onCheckInPerson() {
    enableCheckingIn();

    try {
      await checkInPerson();

      notificationAPI.success('Гость заселён');
    } catch (e) {
      notificationAPI.error(e);
    }

    disableCheckingIn();
  }

  return (
    <Fragment>
      {personPageType === PersonPageType.Edit && <PersonsNavigation />}

      <BreadcrumbsWrapper crumbs={breadcrumbsPath} />

      {(hmsStatus === 1 || hmsStatus === 2) && (
        <CheckInButton>
          <ButtonLoader isLoading={isCheckingIn} onClick={onCheckInPerson} size="small" variant="contained">
            Check In
          </ButtonLoader>
        </CheckInButton>
      )}

      {relatedPersons.length > 1 && (
        <ButtonGroup disableElevation disableFocusRipple disableRipple size="small" variant="text">
          {relatedPersons.map((relatedPerson) => (
            <Button
              key={relatedPerson.id}
              onClick={() => navigate(`/person/${relatedPerson.id}${window.location.search}`)}
              variant={`${relatedPerson?.id}` === personId ? 'contained' : 'outlined'}
            >
              {relatedPerson.fname || relatedPerson.lname ? (
                <Fragment>
                  <span>{relatedPerson.fname}</span>

                  {relatedPerson.lname && <span>&nbsp;{relatedPerson.lname}</span>}
                </Fragment>
              ) : (
                <Fragment>
                  <span>{relatedPerson.fname_lat}</span>

                  {relatedPerson.lname_lat && <span>&nbsp;{relatedPerson.lname_lat}</span>}
                </Fragment>
              )}
            </Button>
          ))}
        </ButtonGroup>
      )}
    </Fragment>
  );
}

export default memo(observer(PersonBreadcrumbs));
