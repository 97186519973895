import React, { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import { TextFieldProps } from '@mui/material/TextField/TextField';

import { notificationAPI } from '@/api/notificationAPI';
import { Address, AddressLevel, Street } from '@/entities/address';
import { DictionaryBasicWithCode } from '@/entities/dictionary';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, InputField } from '@/ui-kit/components';

import { DEFAULT_ADDRESS_LOCAL } from './AddressFormOld.constant';
import { AddressFormRoot } from './AddressFormOld.style';
import CityControl from './CityControl/CityControl';
import CountryControl from './CountryControl/CountryControl';
import PlaceControl from './PlaceControl/PlaceControl';
import RegionControl from './RegionControl/RegionControl';
import SettlementControl from './SettlementControl/SettlementControl';
import StreetControl from './StreetControl/StreetControl';

interface AddressFormSettings {
  name: string;
  error: string;
  value: Nullable<Any>;
  disabled: boolean;
  readonly: boolean;
  hidden: boolean;
  variant: TextFieldProps['variant'];
  margin: TextFieldProps['margin'];
}

interface AddressLocalData {
  country: Nullable<DictionaryBasicWithCode>;
  region: Nullable<Address>;
  place: Nullable<Address>;
  city: Nullable<Address>;
  settlement: Nullable<Address>;
  street: Nullable<Street>;
  parents: Address[];
}

type SettingKey = 'country' | 'region' | 'place' | 'city' | 'settlement' | 'street' | 'house' | 'build' | 'flat';

interface AddressFormProps {
  fiasCode: string;
  regionCode: string;
  onFieldChange: (field: string, value: Nullable<Address | Street | string>, isStreetEmpty?: boolean) => void;
  settings?: Partial<Record<SettingKey, Partial<AddressFormSettings>>>;
  isStreetEmpty?: boolean;
}

function AddressFormOld(props: AddressFormProps) {
  const { fiasCode, regionCode, onFieldChange, settings, isStreetEmpty = false } = props;

  const {
    resetPlaces,
    resetRegions,
    resetCities,
    resetSettlements,
    resetStreets,
    getStreetObjectById,
    getAddressObjectById,
  } = useStore('dictionariesStore');

  const [addressLocal, setAddressLocal] = useState<AddressLocalData>({
    ...DEFAULT_ADDRESS_LOCAL,
    country: settings?.country?.value ?? null,
    parents: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  function updateFormWithValues(streetObjectParents: Address[]) {
    const addressesBuffer: Record<string, Address> = {};

    streetObjectParents.forEach((address: Address) => {
      if (address.ao_level === AddressLevel.Region) {
        addressesBuffer.region = address;
      }

      if (address.ao_level === AddressLevel.Area) {
        addressesBuffer.place = address;
      }

      if (address.ao_level === AddressLevel.City) {
        addressesBuffer.city = address;
      }

      if (address.ao_level === AddressLevel.Place) {
        addressesBuffer.settlement = address;
      }

      if (address.ao_level === AddressLevel.Street) {
        addressesBuffer.street = address;
      }
    });

    setAddressLocal((prevState: AddressLocalData) => ({
      ...prevState,
      ...addressesBuffer,
      parents: streetObjectParents,
    }));
  }

  async function fetchStreetData(fias: string, region: string) {
    try {
      let address: Address;

      if (isStreetEmpty) {
        address = await getAddressObjectById(fias);
      } else {
        address = await getStreetObjectById(fias, region);
      }

      if (address?.parents) {
        setAddressLocal({
          ...DEFAULT_ADDRESS_LOCAL,
          country: settings?.country?.value ?? null,
        });

        updateFormWithValues([...address.parents, address]);
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    // если у сущности нет fias_code или region_code, значит адрес не заполнен
    if (fiasCode && regionCode) {
      if (addressLocal.street?.ao_id !== fiasCode || addressLocal.region?.region_code !== regionCode) {
        setIsLoading(true);

        fetchStreetData(fiasCode, regionCode);
      }
    } else {
      // а если адрес не заполнен нам нужно сбросить локальный стейт, хранящий данные адреса
      setAddressLocal({
        ...DEFAULT_ADDRESS_LOCAL,
        country: settings?.country?.value ?? null,
      });
    }
  }, [fiasCode, regionCode]);

  async function onAutocompleteChangeHandler(name: string, value: Nullable<Any>) {
    const values = {
      [name]: value,
    };

    if (name === 'country') {
      resetRegions();
      resetPlaces();
      resetCities();
      resetSettlements();
      resetStreets();

      if (!value) {
        values.region = null;
        values.place = null;
        values.city = null;
        values.settlement = null;
        values.street = null;
      }
    }

    if (name === 'region') {
      resetPlaces();
      resetCities();
      resetSettlements();
      resetStreets();

      if (!value) {
        values.place = null;
        values.city = null;
        values.settlement = null;
        values.street = null;
      }
    }

    if (name === 'place') {
      resetCities();
      resetSettlements();
      resetStreets();

      if (!value) {
        values.city = null;
        values.settlement = null;
        values.street = null;
      }
    }

    if (name === 'city') {
      resetSettlements();
      resetStreets();

      if (!value) {
        values.settlement = null;
        values.street = null;
      }
    }

    if (name === 'settlement') {
      resetStreets();

      if (!value) {
        values.street = null;
      }
    }

    setAddressLocal((prevState: AddressLocalData) => ({
      ...prevState,
      ...values,
    }));

    if (value) {
      let address: Address | undefined;

      if (name !== 'country' && name !== 'region' && name !== 'street') {
        address = await getAddressObjectById(value.id);
      }

      if (name === 'street') {
        address = await getStreetObjectById((value as Street).ao_guid, value.region_code);
      }

      if (address?.parents && address?.parents.length > 0) {
        const addressValues = [...address.parents, value];

        updateFormWithValues(addressValues);

        const addressObject = addressValues[addressValues.length - 1];

        if (addressObject.ao_level === AddressLevel.Street) {
          onFieldChange('street', addressObject, false);
        } else {
          onFieldChange('street', addressObject, true);
        }
      }
    }
  }

  const onFormSelectChange = useCallback(
    (name: string, value: Nullable<Any>) => {
      onAutocompleteChangeHandler(name, value);
    },
    [onAutocompleteChangeHandler],
  );

  const onFormInputChange = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      onFieldChange(currentTarget.name, currentTarget.value, isStreetEmpty);
    },
    [onFieldChange, isStreetEmpty],
  );

  const isFlatHidden = useMemo(() => settings?.flat?.hidden ?? false, [settings?.flat?.hidden]);

  return (
    <AddressFormRoot container spacing={2}>
      <BackdropLoader isLoading={isLoading} />

      <Grid item xs={6}>
        <CountryControl
          country={addressLocal.country}
          disabled
          margin={settings?.country?.margin}
          onChange={onFormSelectChange}
          readonly={settings?.country?.readonly ?? false}
          variant={settings?.country?.variant}
        />
      </Grid>

      <Grid item xs={6}>
        <RegionControl
          disabled={settings?.region?.disabled ?? false}
          margin={settings?.region?.margin}
          onChange={onFormSelectChange}
          readonly={settings?.region?.readonly ?? false}
          region={addressLocal.region}
          variant={settings?.region?.variant}
        />
      </Grid>

      <Grid item xs={6}>
        <PlaceControl
          disabled={settings?.place?.disabled ?? false}
          margin={settings?.place?.margin}
          onChange={onFormSelectChange}
          place={addressLocal.place}
          readonly={settings?.place?.readonly ?? false}
          region={addressLocal.region}
          variant={settings?.place?.variant}
        />
      </Grid>

      <Grid item xs={6}>
        <CityControl
          city={addressLocal.city}
          disabled={settings?.city?.disabled ?? false}
          margin={settings?.city?.margin}
          onChange={onFormSelectChange}
          place={addressLocal.place}
          readonly={settings?.city?.readonly ?? false}
          region={addressLocal.region}
          variant={settings?.city?.variant}
        />
      </Grid>

      <Grid item xs={6}>
        <SettlementControl
          disabled={settings?.settlement?.disabled ?? false}
          margin={settings?.settlement?.margin}
          onChange={onFormSelectChange}
          place={addressLocal.place}
          readonly={settings?.settlement?.readonly ?? false}
          region={addressLocal.region}
          settlement={addressLocal.settlement}
          variant={settings?.settlement?.variant}
        />
      </Grid>

      <Grid item xs={6}>
        <StreetControl
          city={addressLocal.city}
          disabled={settings?.street?.disabled ?? false}
          margin={settings?.street?.margin}
          onChange={onFormSelectChange}
          readonly={settings?.street?.readonly ?? false}
          region={addressLocal.region}
          settlement={addressLocal.settlement}
          street={addressLocal.street}
          variant={settings?.street?.variant}
        />
      </Grid>

      <Grid item xs={isFlatHidden ? 6 : 4}>
        <InputField
          disabled={settings?.house?.disabled ?? false}
          error={!!settings?.house?.error}
          helperText={settings?.house?.error}
          label="Дом"
          margin={settings?.house?.margin}
          name={settings?.house?.name ?? 'housing'}
          onChange={onFormInputChange}
          readOnly={settings?.house?.readonly ?? false}
          value={settings?.house?.value}
          variant={settings?.house?.variant}
        />
      </Grid>

      <Grid item xs={isFlatHidden ? 6 : 4}>
        <InputField
          disabled={settings?.build?.disabled ?? false}
          error={!!settings?.build?.error}
          helperText={settings?.build?.error}
          label="Корпус"
          margin={settings?.build?.margin}
          name={settings?.build?.name ?? 'housing2'}
          onChange={onFormInputChange}
          readOnly={settings?.build?.readonly ?? false}
          value={settings?.build?.value}
          variant={settings?.build?.variant}
        />
      </Grid>

      {!isFlatHidden && (
        <Grid item xs={4}>
          <InputField
            disabled={settings?.flat?.disabled ?? false}
            error={!!settings?.flat?.error}
            helperText={settings?.flat?.error}
            label="Квартира/офис"
            margin={settings?.flat?.margin}
            name={settings?.flat?.name ?? 'flat'}
            onChange={onFormInputChange}
            readOnly={settings?.flat?.readonly ?? false}
            value={settings?.flat?.value}
            variant={settings?.flat?.variant}
          />
        </Grid>
      )}
    </AddressFormRoot>
  );
}

export default memo(observer(AddressFormOld));
