import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';

export const PrintButtonsRoot = styled('div')`
  margin-right: 82px;
  display: flex;
  align-items: center;
`;

export const PrintButtonsControls = styled('div')(
  ({ isOpened }: { isOpened: boolean }) => css`
    display: flex;
    align-items: center;
    overflow: hidden;
    width: ${isOpened ? 240 : 0}px;
    transition: width 200ms ease-in;
  `,
);
