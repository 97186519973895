import React, { Fragment, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import green from '@mui/material/colors/green';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { ROUTE_COMPANIES } from '@/constants';
import { Company } from '@/entities/company';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { ConfirmModal } from '@/ui-kit/components';

import { CompanyTableRawRoot } from './CompanyTableRaw.style';

interface CompanyTableRowProps {
  company: Company;
}

function CompanyTableRaw({ company }: CompanyTableRowProps) {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { exportTypes } = useStore('dictionariesStore');
  const { removeCompany, getCompanies } = useStore('companiesStore');

  const [isDeleteCompanyModalOpen, openDeleteCompanyModal, closeDeleteCompanyModal] = useToggle();

  async function onDeleteHandler() {
    if (company?.id) {
      await removeCompany(company.id);

      notificationAPI.success('Компания удалена');

      await getCompanies();
    }
  }

  const onNavigateToCompany = useCallback(
    () => navigate(`${ROUTE_COMPANIES}/${company.id}${queryAPI.getQuery()}`),
    [company.id],
  );
  const onOpenDeleteCompanyModal = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();

    openDeleteCompanyModal();
  }, []);

  const companyExportType = useMemo(
    () => exportTypes.find(({ code }) => code === company.allow_export),
    [JSON.stringify(exportTypes)],
  );

  return (
    <Fragment>
      <CompanyTableRawRoot
        hover
        id={`#company-${company.id}`}
        isActive={hash === `#company-${company.id}`}
        onClick={onNavigateToCompany}
      >
        <TableCell>{company.ur_name}</TableCell>

        <TableCell>{company.name}</TableCell>

        <TableCell>{companyExportType?.name_ru ?? null}</TableCell>

        <TableCell align="center">
          {company.testing && <CheckCircleIcon fontSize="small" htmlColor={green['700']} />}
        </TableCell>

        <TableCell align="center">
          {company.payment_debt && <CheckCircleIcon fontSize="small" htmlColor={green['700']} />}
        </TableCell>

        <TableCell align="right">
          <Tooltip title="Удалить компанию">
            <IconButton onClick={onOpenDeleteCompanyModal}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </CompanyTableRawRoot>

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        description={
          <Typography variant="body1">Вы действительно хотите удалить компанию &quot;{company.name}&quot;?</Typography>
        }
        onClose={closeDeleteCompanyModal}
        onConfirm={onDeleteHandler}
        onDecline={closeDeleteCompanyModal}
        open={isDeleteCompanyModalOpen}
        title="Удалить компанию"
      />
    </Fragment>
  );
}

export default memo(CompanyTableRaw);
