import React, { ChangeEvent, Fragment, memo, useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import CameraIcon from '@mui/icons-material/Camera';
import CloseIcon from '@mui/icons-material/Close';
import FastForwardIcon from '@mui/icons-material/FastForward';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { storageAPI } from '@/api/storageAPI';
import { NEW_PERSON_ID, ROUTE_BOOKINGS, ROUTE_PERSON, ROUTE_REGISTRATIONS, ROUTE_UPLOAD_IMAGES } from '@/constants';
import { UserDevice } from '@/entities/userDevice';
import { useToggle } from '@/hooks/useToggle/useToggle';
import ChangePersonStatusIcon from '@/shared/ChangePersonStatusIcon/ChangePersonStatusIcon';
import QRCodeModal from '@/shared/QRCodeModal/QRCodeModal';
import { OperationType, PersonStatus, SCAN_TIMEOUT, SocketOperation } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ConfirmModal, SelectField } from '@/ui-kit/components';

import { PersonPageType } from '../PersonPage.constant';
import { PersonPollingEvent } from '../PersonPage.types';
import { usePersonPageStore } from '../PersonPageStore';

import ConfirmCloseModal from './ConfirmCloseModal/ConfirmCloseModal';
import LogusPersonsModal from './LogusPersonsModal/LogusPersonsModal';
import {
  IconButtonWrapper,
  PersonFormHeaderClose,
  PersonFormHeaderContinue,
  PersonFormHeaderControls,
  PersonFormHeaderDevices,
  PersonFormHeaderLogus,
  PersonFormHeaderRoot,
  PersonFormHeaderScan,
} from './PersonFormHeader.style';
import PrintButtons from './PrintButtons/PrintButtons';
import UseCameraModal from './UseCameraModal/UseCameraModal';

let scanTimeout: NodeJS.Timeout | undefined;

function PersonFormHeader() {
  const {
    isNewPerson,
    isBooking,
    isExported,
    isDeleted,
    isAccepted,
    personId,
    groupId,
    status,
    onCreatePerson,
    onUpdatePerson,
    onRepeatRegistration,
    personPageType,
    isPersonValuesValid,
    lastName,
    firstName,
    birthDate,
    setPersonValues,
    navigate,
    selectStatus,
    logusPersons,
    onResetPersonData,
    fetchLogusPersons,
    onResetLogusPersons,
    setSavingState,
    getPersonUrl,
    setPendingScanId,
  } = usePersonPageStore();
  const socket = useStore('socket');
  const { isIntegration, userCompany } = useStore('signInStore');
  const { userDevices, cameras } = useStore('devicesStore');
  const { selectedDevice, setSelectedDevice, isScanning, isSaving, setScanningState, scan, operationPolling } =
    useStore('personsStore');

  const saveButtonRef = useRef(null);

  const [isCloseConfirmModalOpen, openConfirmCloseModal, closeConfirmCloseModal] = useToggle();
  const [isContinueEditingModalOpen, openContinueEditingModal, closeContinueEditingModal] = useToggle();
  const [isQRCodeModalOpen, openQRCodeModal, closeQRCodeModal] = useToggle();
  const [isCreatingPerson, startCreatingPerson, stopCreatingPerson] = useToggle();
  const [isOpenUseCameraModal, openUseCameraModal, closeUseCameraModal] = useToggle();

  const onSelectDevice = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => setSelectedDevice(+target.value),
    [],
  );
  const onDetectStatus = useCallback(
    () =>
      setPersonValues({
        status: isAccepted ? PersonStatus.Pending : PersonStatus.Accepted,
      }),
    [isAccepted],
  );
  const onContinueEditing = useCallback(() => {
    setPersonValues({
      status: PersonStatus.Pending,
    });

    closeContinueEditingModal();
  }, []);

  const onCloseIntegration = useCallback(() => navigate(ROUTE_REGISTRATIONS), []);

  const onSuccessQRModal = useCallback(() => {
    window.location.reload();
  }, []);

  async function onStartScan() {
    scanTimeout && clearTimeout(scanTimeout);

    scanTimeout = setTimeout(() => {
      if (isScanning) {
        setScanningState(false);

        notificationAPI.error('Операция не выполнена! Возможно устройство не подключено!');
      }
    }, SCAN_TIMEOUT);

    try {
      setScanningState(true);

      const operationId = await scan(OperationType.Scan);

      setPendingScanId(operationId);

      // Ниже реализована работа с поллингом, на случай если вебсокет не работает или отвалился
      // эта реализация временная и нужна скорее для подстраховки
      // в ней я не забочусь о типизации и оптимизации работы скрипта.
      // в будущем планируется выпилить поллинг
      // дублируется в файлах:
      // PersonalGuestModal.tsx
      // DocumentImages.tsx
      // ImageBlock.tsx
      if (socket && socket.readyState !== 1 && operationId) {
        const operation = await operationPolling(operationId, OperationType.Scan);

        const docEvent = new CustomEvent('polling-event', {
          detail: JSON.stringify({
            id: operation.id,
            type: SocketOperation.Dop,
            op_type: OperationType.Scan,
            error: operation.op_error,
          }),
        } as PersonPollingEvent);

        window.dispatchEvent(docEvent);

        // эта задержка нужна, чтобы последовательно обработать события
        // сначала должен быть обработан dop, затем img
        setTimeout(() => {
          const imageEvent = new CustomEvent('polling-event', {
            detail: JSON.stringify({
              id: operation.id,
              type: SocketOperation.Img,
              op_type: OperationType.Scan,
              error: operation.op_error,
            }),
          } as PersonPollingEvent);

          window.dispatchEvent(imageEvent);
        }, 500);
      }
    } catch (e) {
      setScanningState(false);
      notificationAPI.error(e);
    }
  }

  function proceedClosePage(personId?: StringOrNumber) {
    const { fallback, ...rest } = queryAPI.params;
    let path = isBooking ? ROUTE_BOOKINGS : ROUTE_REGISTRATIONS;

    if (fallback) {
      path = fallback;
    }

    if (groupId) {
      path += `/${groupId}`;
    }

    path += queryAPI.generateQuery(rest);

    if (personId && personId !== NEW_PERSON_ID && Boolean(+personId)) {
      path += `#person-${personId}`;
    }

    navigate(path);
  }

  async function onConfirmSave() {
    const newPerson = await onCreatePerson();

    if (newPerson) {
      proceedClosePage(newPerson.id);
    }
  }

  async function onSaveAndCreateHandler() {
    try {
      if (personPageType === PersonPageType.Create) {
        await onCreatePerson();
      }

      if (personPageType === PersonPageType.Edit && personId) {
        setSavingState(true);

        await onUpdatePerson();

        notificationAPI.success('Карта гостя была обновлена');
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    setSavingState(false);
  }

  async function onSaveAndCreateNew() {
    await onSaveAndCreateHandler();

    // Делаем задержку, чтобы сначала показать тостер с созданием карты гостя и только затем перейти на новую страницу
    setTimeout(() => {
      onResetPersonData();

      navigate(`${ROUTE_PERSON}/${NEW_PERSON_ID}`);
    }, 1500);
  }

  function proceedNewUserOnOpen() {
    if (isNewPerson) {
      setPersonValues({
        fname: firstName || 'Новый',
        lname: lastName || 'Гость',
        birth_date: birthDate || new Date().toISOString(),
      });
    }
  }

  function onOpenUseCameraModal() {
    proceedNewUserOnOpen();

    openUseCameraModal();
  }

  async function onOpenQRCode() {
    proceedNewUserOnOpen();

    startCreatingPerson();

    try {
      if (isNewPerson) {
        const newPerson = await onCreatePerson();

        if (newPerson) {
          setPersonValues({
            id: newPerson.id,
          });

          queryAPI.replacePath(getPersonUrl(newPerson.id));
        }
      }

      openQRCodeModal();
    } catch (e) {
      notificationAPI.error(e);
    }

    stopCreatingPerson();
  }

  const onCloseHandler = useCallback(() => {
    if (personPageType === PersonPageType.Create && isPersonValuesValid) {
      openConfirmCloseModal();
    } else {
      proceedClosePage(personId);
    }
  }, [personPageType, personId, isPersonValuesValid]);

  const keyboardEventHandler = useCallback(
    ({ key, which, keyCode, altKey }: KeyboardEvent) => {
      if ((key === 's' || which === 83) && altKey && selectedDevice !== 0) {
        onStartScan();
      }

      if (key === 'Escape' && keyCode === 27) {
        onCloseHandler();
      }
    },
    [onStartScan, onCloseHandler],
  );

  useEffect(() => {
    window.addEventListener('keyup', keyboardEventHandler);

    return () => {
      window.removeEventListener('keyup', keyboardEventHandler);
    };
  }, [keyboardEventHandler]);

  return (
    <PersonFormHeaderRoot>
      <PersonFormHeaderControls>
        {!isDeleted && personPageType === PersonPageType.Edit && (
          <ChangePersonStatusIcon onSetPersonStatus={selectStatus} status={status} title="Изменить статус карточки" />
        )}

        {!isDeleted && !isExported && (
          <FormControlLabel
            control={<Switch checked={isAccepted} color="success" name="value" onClick={onDetectStatus} />}
            label="Подтвержден"
          />
        )}
      </PersonFormHeaderControls>

      <PersonFormHeaderControls>
        {personPageType === PersonPageType.Edit && personId && <PrintButtons />}

        {!isDeleted && !isExported && (
          <Fragment>
            <PersonFormHeaderDevices>
              <SelectField onChange={onSelectDevice} value={selectedDevice}>
                <MenuItem value={0}>Выберите сканер</MenuItem>

                {userDevices.map(({ id, name, is_connected }: UserDevice) => (
                  <MenuItem disabled={!is_connected} key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </SelectField>
            </PersonFormHeaderDevices>

            <PersonFormHeaderScan>
              <ButtonGroup variant="contained">
                <Tooltip title="Alt + S">
                  <IconButtonWrapper>
                    <Button disabled={selectedDevice === 0} onClick={onStartScan} type="button">
                      Начать сканирование
                    </Button>
                  </IconButtonWrapper>
                </Tooltip>

                <Tooltip title={cameras.length > 0 ? 'Использовать документ-камеру' : 'Документ-камера не обнаружена'}>
                  <IconButtonWrapper>
                    <Button disabled={cameras.length === 0} onClick={onOpenUseCameraModal} type="button">
                      <CameraIcon />
                    </Button>
                  </IconButtonWrapper>
                </Tooltip>

                <Tooltip title="QR-для загрузки изображений">
                  <IconButtonWrapper>
                    <ButtonLoader isLoading={isCreatingPerson} onClick={onOpenQRCode} type="button">
                      <QrCode2Icon />
                    </ButtonLoader>
                  </IconButtonWrapper>
                </Tooltip>
              </ButtonGroup>

              {userCompany?.company?.hms === 3 && (
                <PersonFormHeaderLogus>
                  <IconButton onClick={fetchLogusPersons} size="small">
                    <Tooltip title="Синхронизировать с Logus HMS">
                      <SyncIcon />
                    </Tooltip>
                  </IconButton>
                </PersonFormHeaderLogus>
              )}
            </PersonFormHeaderScan>

            <ButtonGroup disabled={!isPersonValuesValid} ref={saveButtonRef} variant="contained">
              <ButtonLoader
                color="primary"
                form="person-page-form"
                isLoading={isSaving}
                startIcon={<SaveIcon />}
                type="submit"
                variant="contained"
              >
                Сохранить
              </ButtonLoader>

              {isIntegration ? (
                <ButtonLoader isLoading={isSaving} onClick={onSaveAndCreateHandler} size="small">
                  <Tooltip title="Сохранить и закрыть">
                    <CloseIcon />
                  </Tooltip>
                </ButtonLoader>
              ) : (
                <ButtonLoader isLoading={isSaving} onClick={onSaveAndCreateNew} size="small">
                  <Tooltip title={isPersonValuesValid ? 'Сохранить и создать новую карточку' : ''}>
                    <FastForwardIcon />
                  </Tooltip>
                </ButtonLoader>
              )}
            </ButtonGroup>
          </Fragment>
        )}

        {!isDeleted && isExported && (
          <Fragment>
            <Button color="primary" onClick={onRepeatRegistration} type="button" variant="contained">
              Повторный заезд
            </Button>

            <PersonFormHeaderContinue>
              <Button color="primary" onClick={openContinueEditingModal} type="button" variant="contained">
                Продолжить редактирование
              </Button>
            </PersonFormHeaderContinue>
          </Fragment>
        )}

        <PersonFormHeaderClose>
          {isIntegration ? (
            <Tooltip title="Вернуться к таблице регистраций">
              <IconButton onClick={onCloseIntegration}>
                <SettingsBackupRestoreIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Esc">
              <IconButton onClick={onCloseHandler}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </PersonFormHeaderClose>
      </PersonFormHeaderControls>

      <ConfirmModal
        confirmLabel="Да"
        description="Вы уверены, что хотите продолжить редактирование карточки гостя? В случае продолжения редактирования статус будет изменён на «В ожидании»."
        onClose={closeContinueEditingModal}
        onConfirm={onContinueEditing}
        onDecline={closeContinueEditingModal}
        open={isContinueEditingModalOpen}
        title="Продолжить редактирование"
      />

      <ConfirmCloseModal
        isLoading={isSaving}
        onCancel={closeConfirmCloseModal}
        onConfirm={onConfirmSave}
        onDecline={proceedClosePage}
        open={isCloseConfirmModalOpen}
      />

      <LogusPersonsModal onClose={onResetLogusPersons} open={logusPersons.length > 0} />

      <QRCodeModal
        onClose={closeQRCodeModal}
        onSuccess={onSuccessQRModal}
        open={isQRCodeModalOpen}
        url={`${window.location.origin}${ROUTE_UPLOAD_IMAGES}/${personId}?token=${storageAPI.get('token')}`}
      />

      {cameras.length > 0 && <UseCameraModal onClose={closeUseCameraModal} open={isOpenUseCameraModal} />}
    </PersonFormHeaderRoot>
  );
}

export default memo(observer(PersonFormHeader));
