import { makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import { notificationAPI } from '@/api/notificationAPI';
import { Group, GroupModel } from '@/entities/group';

import { recursiveFindGroup, recursiveUpdateGroup } from './GroupsStore.utils';

export class GroupsStore {
  groups: Group[] = [];
  isFetching = true;
  isSaving = false;
  isRestoring = false;
  isFetchingSingleGroup = true;

  constructor() {
    makeAutoObservable(this);

    // временно отключили, так как есть баг
    // const groupsInStorage = sessionStorage.getItem(GROUPS_STORAGE_KEY);
    //
    // if (groupsInStorage) {
    //   try {
    //     const groups = JSON.parse(groupsInStorage);
    //
    //     this.groups.replace([...groups]);
    //   } catch (e) {
    //     sessionStorage.removeItem(GROUPS_STORAGE_KEY);
    //   }
    // }
  }

  getGroups = (showDeleted = false) => {
    this.isFetching = true;

    let url = '/person-groups/';

    if (showDeleted) {
      url += '?show_deleted=true';
    }

    return fetchApi
      .get<GroupModel[]>(url)
      .then((groups) => {
        const newGroups = groups.map((groupItem) => {
          const existingGroup = recursiveFindGroup(this.groups, groupItem.id);

          return new Group({
            ...groupItem,
            children: existingGroup?.children ?? [],
            collapsed: existingGroup?.collapsed ?? false,
          });
        });

        // временно отключили, так как есть баг
        // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(newGroups));

        runInAction(() => {
          this.groups = newGroups;
        });
      })
      .catch((e) => notificationAPI.error(e))
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  getGroupsById = (id: number) =>
    fetchApi
      .get<GroupModel[]>('/person-groups/', {
        parent_id: id,
      })
      .then((subGroups) => {
        runInAction(() => {
          // временно отключили, так как есть баг
          // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(newGroups));

          const targetGroup = recursiveFindGroup(this.groups, id);

          if (targetGroup) {
            targetGroup.children = subGroups.map((group) => new Group(group));
          }
        });
      });

  getSingleGroupById = (id: number) => {
    this.isFetchingSingleGroup = true;

    return fetchApi.get<Group>(`/person-groups/${id}/`).finally(() => {
      runInAction(() => {
        this.isFetchingSingleGroup = false;
      });
    });
  };

  addGroup = (name: string, parentId: number) => {
    this.isSaving = true;

    return fetchApi
      .post<Group>('/person-groups/', {
        body: {
          name,
          parent_id: parentId,
          default_order: 1,
        },
      })
      .then((group: Group) => {
        const newGroup = new Group({
          ...group,
          id: group.id,
          name,
          parent_id: parentId,
        });

        // 1 - айди группы по умолчанию, значит новая группа должна быть добавлена в this.groups
        if (parentId === 1) {
          this.groups.push(newGroup);

          // временно отключили, так как есть баг
          // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(this.groups));
        } else {
          runInAction(() => {
            this.groups = recursiveUpdateGroup(this.groups, parentId, (group: Group) => {
              group.children.push(newGroup);
              group.has_child_groups = group.children.length > 0;

              return group;
            });
            // временно отключили, так как есть баг
            // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(this.groups));
          });
        }
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  updateGroup = (groupId: number, oldParentId: number, { name, parent_id }: Partial<Group>) => {
    this.isSaving = true;

    if (parent_id) {
      return fetchApi
        .put(`/person-groups/${groupId}/`, {
          body: {
            name,
            parent_id: +parent_id,
            default_order: 1,
          },
        })
        .then(async () => {
          const updatedGroup = await this.getSingleGroupById(groupId);

          /*
           * при изменении имени группы
           * */
          if (oldParentId === parent_id) {
            /*
             * 1 - айди группы по умолчанию, значит изменяемая группы находилась в this.groups
             * */
            if (parent_id === 1) {
              const groups = [...this.groups];

              const targetIndex = groups.findIndex(({ id }) => id === groupId);

              groups[targetIndex] = new Group({
                ...groups[targetIndex],
                ...updatedGroup,
              });

              // временно отключили, так как есть баг
              // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(groups));

              runInAction(() => {
                this.groups = [...groups];
              });
            } else {
              const updatedGroups = recursiveUpdateGroup(this.groups, oldParentId, (group: Group) => {
                const children = [...group.children];

                const targetIndex = children.findIndex(({ id }) => id === groupId);

                children[targetIndex] = new Group({
                  ...children[targetIndex],
                  ...updatedGroup,
                });

                return new Group({
                  ...group,
                  has_child_groups: children.length > 0,
                  children,
                });
              });

              // временно отключили, так как есть баг
              // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(updatedGroups));

              runInAction(() => {
                this.groups = [...updatedGroups];
              });
            }

            return;
          }

          /*
           * 1 - айди группы по умолчанию, значит изменяемая группы находилась в this.groups,
           * при перемещении группы из группы по умолчанию в другую
           * */
          if (oldParentId === 1) {
            const groups = [...this.groups];

            /*
             * удаляем изменяемую группу из this.groups
             * */
            const targetIndex = groups.findIndex(({ id }) => id === groupId);

            if (targetIndex !== -1) {
              groups.splice(targetIndex, 1);
            }

            /*
             * обновляем группу куда изменяемая группа была перенесена, чтобы добавить ей в `children` группу которую меняли
             * */
            const updatedGroups = recursiveUpdateGroup(groups, parent_id, (group: Group) => {
              const children = [...group.children];

              children.push(
                new Group({
                  ...updatedGroup,
                  collapsed: false,
                  children: [],
                }),
              );

              return new Group({
                ...group,
                has_child_groups: children.length > 0,
                collapsed: children.length > 0 ? group.collapsed : false,
                children,
              });
            });

            // временно отключили, так как есть баг
            // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(updatedGroups));

            runInAction(() => {
              this.groups = [...updatedGroups];
            });

            return;
          }

          /*
           * при перемещении группы в группу по умолчанию
           * */
          if (parent_id === 1) {
            const groups = [...this.groups];

            /*
             * добавляем изменяемую группу в this.groups
             * */
            groups.push(new Group({ ...updatedGroup, collapsed: false, children: [] }));

            /*
             * удаляем из старой родительской группы группу которую изменили
             * */
            const updatedGroups = recursiveUpdateGroup(groups, oldParentId, (group: Group) => {
              const children = [...group.children];

              const targetIndex = children.findIndex(({ id }) => id === groupId);

              children.splice(targetIndex, 1);

              return new Group({
                ...group,
                has_child_groups: children.length > 0,
                collapsed: children.length > 0 ? group.collapsed : false,
                children,
              });
            });

            // временно отключили, так как есть баг
            // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(updatedGroups));

            runInAction(() => {
              this.groups = [...updatedGroups];
            });

            return;
          }

          /*
           * при перемещении между группами, которые не вхоядт в группу по умолчнию
           * */
          if (oldParentId !== 1 && parent_id !== 1) {
            /*
             * удаляем из старой родительской группы группу которую изменили
             * */
            const groups = recursiveUpdateGroup(this.groups, oldParentId, (group: Group) => {
              const children = [...group.children];

              const targetIndex = children.findIndex(({ id }) => id === groupId);

              children.splice(targetIndex, 1);

              return new Group({
                ...group,
                has_child_groups: children.length > 0,
                collapsed: children.length > 0 ? group.collapsed : false,
                children,
              });
            });

            /*
             * обновляем группу куда изменяемая группа была перенесена, чтобы добавить ей в `children` группу которую меняли
             * */
            const updatedGroups = recursiveUpdateGroup(groups, parent_id, (group: Group) => {
              const children = [...group.children];

              children.push(
                new Group({
                  ...updatedGroup,
                  collapsed: false,
                  children: [],
                }),
              );

              return new Group({
                ...group,
                has_child_groups: children.length > 0,
                collapsed: children.length > 0 ? group.collapsed : false,
                children,
              });
            });

            // временно отключили, так как есть баг
            // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(updatedGroups));

            runInAction(() => {
              this.groups = [...updatedGroups];
            });
          }
        })
        .finally(() => {
          runInAction(() => {
            this.isSaving = false;
          });
        });
    }

    return undefined;
  };

  deleteGroup = (groupId: number, parentId: number) => {
    this.isSaving = true;

    return fetchApi
      .remove(`/person-groups/${groupId}/`, {})
      .then(() => {
        runInAction(() => {
          // 1 - айди группы по умолчанию, значит новая группа должна быть добавлена в this.groups
          if (parentId === 1) {
            const newGroups = [...this.groups];

            const targetIndex = newGroups.findIndex(({ id }) => id === groupId);

            if (targetIndex !== -1) {
              newGroups.splice(targetIndex, 1);
            }

            // временно отключили, так как есть баг
            // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(newGroups));

            runInAction(() => {
              this.groups = [...newGroups];
            });
          } else {
            const newGroups = recursiveUpdateGroup(this.groups, parentId, (group: Group) => {
              const children = [...group.children];

              const targetIndex = children.findIndex(({ id }) => id === groupId);

              children.splice(targetIndex, 1);

              return new Group({
                ...group,
                has_child_groups: children.length > 0,
                collapsed: children.length > 0 ? group.collapsed : false,
                children,
              });
            });

            // временно отключили, так как есть баг
            // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(newGroups));

            runInAction(() => {
              this.groups = [...newGroups];
            });
          }
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  replaceInGroup = (groupId: number, selected: Map<number, boolean>, parentGroupId: number) => {
    this.isSaving = true;

    const selectedKeys = [...selected.keys()];
    let url = `/persons-group/${groupId}/`;

    selectedKeys.forEach((id: number, index: number) => {
      if (index === 0) {
        url += `?id=${id}`;
      } else {
        url += `&id=${id}`;
      }
    });

    return fetchApi
      .put(url)
      .then(async () => {
        await this.reloadGroupData(groupId);
        await this.reloadGroupData(parentGroupId);
      })
      .finally(() => {
        runInAction(() => {
          this.isSaving = false;
        });
      });
  };

  collapseGroup = (groupId: number) => {
    const newGroups = recursiveUpdateGroup(
      this.groups,
      groupId,
      (group: Group) =>
        new Group({
          ...group,
          collapsed: group.id === groupId ? !group.collapsed : group.collapsed,
        }),
    );

    // временно отключили, так как есть баг
    // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(newGroups));

    this.groups = [...newGroups];
  };

  reloadGroupData = async (groupId: number) => {
    const updatedGroup = await this.getSingleGroupById(groupId);

    const newGroups = recursiveUpdateGroup(
      this.groups,
      groupId,
      (group: Group) =>
        new Group({
          ...group,
          ...updatedGroup,
        }),
    );

    // временно отключили, так как есть баг
    // sessionStorage.setItem(GROUPS_STORAGE_KEY, JSON.stringify(newGroups));

    this.groups = [...newGroups];
  };

  restoreGroup = async (groupId: number) => {
    this.isRestoring = true;

    return fetchApi.patch(`/person-groups/${groupId}/`).finally(() => {
      runInAction(() => {
        this.isRestoring = false;
      });
    });
  };
}
