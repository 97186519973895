import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { KS_IMAGE_TYPE_NAME_MAP, KSImageType } from '@/stores/PersonsStore';
import { ContentModal, ContentModalProps } from '@/ui-kit/components';

interface ImageTypeModalProps {
  reasonId?: number;
  onSelectType: (type: KSImageType) => void;
}

function KSImageTypeModal(props: ContentModalProps<ImageTypeModalProps>) {
  const { reasonId, onSelectType, open, onClose } = props;

  return (
    <ContentModal disableEscapeKeyDown={false} maxWidth="sm" onClose={onClose} open={open}>
      <ContentModal.Header>Выберите категорию</ContentModal.Header>

      <ContentModal.Body>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button fullWidth onClick={() => onSelectType(KSImageType.Agreement)} variant="contained">
              {KS_IMAGE_TYPE_NAME_MAP[KSImageType.Agreement]}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={!(reasonId && reasonId >= 4 && reasonId <= 24)}
              fullWidth
              onClick={() => onSelectType(KSImageType.LegalConfirm)}
              variant="contained"
            >
              {KS_IMAGE_TYPE_NAME_MAP[KSImageType.LegalConfirm]}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={reasonId !== 1}
              fullWidth
              onClick={() => onSelectType(KSImageType.Decline)}
              variant="contained"
            >
              {KS_IMAGE_TYPE_NAME_MAP[KSImageType.Decline]}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={reasonId !== 1}
              fullWidth
              onClick={() => onSelectType(KSImageType.Notice)}
              variant="contained"
            >
              {KS_IMAGE_TYPE_NAME_MAP[KSImageType.Notice]}
            </Button>
          </Grid>
        </Grid>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button onClick={onClose}>Закрыть</Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(KSImageTypeModal));
