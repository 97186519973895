import Backdrop from '@mui/material/Backdrop';
import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import styled from '@mui/material/styles/styled';

export const RouteRoot = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${grey[200]};
`;

export const RouteWrapper = styled('div')`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
`;

export const RouteContainer = styled(Container)`
  max-width: 1920px;
  min-width: 768px;
  height: 100%;
  overflow: hidden;
`;

export const RouteContainerInner = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RouteBreadcrumbs = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px 16px 0;
`;

export const RouteContentWrapper = styled('div')`
  position: relative;
  padding: 8px 16px 8px;
  overflow: hidden;
  flex: 1;
`;

export const RouteContentPaper = styled(Paper)`
  height: 100%;
  overflow: hidden;
`;

export const RouteContentPaperInner = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RouteContentHeader = styled('div')`
  min-height: 48px;
`;

export const RouteContentSubHeader = styled('div')`
  margin-bottom: 16px;
  flex-shrink: 0;
`;

export const RouteContentBox = styled('div')`
  position: relative;
  padding: 8px;
  flex: 1;
  overflow: hidden;
`;

export const RouteContentBoxInner = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RouteContentBoxWrapper = styled('div')`
  flex: 1;
  overflow: hidden;
`;

export const RouteContentBoxMain = styled('div')`
  overflow: auto;
  height: 100%;
`;

export const RouteBackdrop = styled(Backdrop)`
  // "!important" потому что по умолчанию в Backdrop z-index равен -1, нужно его перекрыть
  // @ts-ignore
  z-index: 10000 !important;
`;

export const RouteBackdropContent = styled('div')`
  text-align: center;
  color: ${common.white};
`;
