import React, { ForwardedRef, forwardRef, memo } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';

interface SwitchFieldProps extends SwitchProps {
  disabled?: boolean;
  label?: string;
}

const SwitchField = forwardRef((props: SwitchFieldProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const { disabled, label, color = 'primary' } = props;

  return <FormControlLabel control={<Switch ref={ref} {...props} color={color} />} disabled={disabled} label={label} />;
});

export default memo(SwitchField);
