import React, { ChangeEvent, Fragment, memo, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { DictionaryBasicWithCode, DocIssueAuthority } from '@/entities/dictionary';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { PersonDocument } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField, InputField } from '@/ui-kit/components';

import { usePersonPageStore } from '../../../PersonPageStore';

import { BirthplaceSummary, BirthplaceSummaryCaption } from './Birthplace.style';

function Birthplace() {
  const { idCard, setIdCard, isRussianCitizenship, isExported, isDeleted } = usePersonPageStore();
  const { countries } = useStore('dictionariesStore');

  const [expanded, , , onToggleExpanded] = useToggle(true);

  const filteredCountries = useMemo(() => countries.filter(({ id }) => ![253, 254, 255].includes(id)), []);

  const getBirthPlace = useMemo(() => {
    let result = '';

    if (!idCard) {
      return result;
    }

    const { birth_country_id, birth_place_region2, birth_place_region, birth_place, birth_place2 } = idCard;

    if (birth_country_id) {
      result += `${countries.find((c) => c.id === birth_country_id)?.name_ru}, `;
    }

    if (birth_place_region2) {
      result += `${birth_place_region2}, `;
    }

    if (birth_place_region) {
      result += `${birth_place_region}, `;
    }

    if (birth_place) {
      result += `${birth_place}, `;
    }

    if (birth_place2) {
      result += `${birth_place2}, `;
    }

    return result.trim().slice(0, -1);
  }, [JSON.stringify(idCard)]);

  const onCountryChange = useCallback((country: unknown) => {
    setIdCard({
      birth_country_id: country ? (country as DictionaryBasicWithCode).id : null,
    });
  }, []);

  const onChangeBirthPlaceValue = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;

    setIdCard({
      [name as keyof PersonDocument]: value,
    });
  }, []);

  const getOptionLabel = useCallback(({ name_ru }: DocIssueAuthority) => `${name_ru}`, []);

  if (!idCard) {
    return null;
  }

  return (
    <Accordion expanded={expanded} onChange={onToggleExpanded}>
      <BirthplaceSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>Место рождения</Typography>

        {!expanded && getBirthPlace.length > 0 && (
          <BirthplaceSummaryCaption>
            <Typography variant="caption">{getBirthPlace}</Typography>
          </BirthplaceSummaryCaption>
        )}
      </BirthplaceSummary>

      <AccordionDetails>
        <fieldset disabled={isExported || isDeleted}>
          <Grid container spacing={2} wrap="wrap">
            <Grid item xs={3}>
              <FormControl fullWidth>
                <AutocompleteField
                  disabled={isExported || isDeleted}
                  getOptionLabel={getOptionLabel}
                  isError={!idCard.birth_country_id}
                  label="Страна"
                  name="birth_country_id"
                  onChange={onCountryChange}
                  options={[...filteredCountries]}
                  value={filteredCountries.find(({ id }) => id === idCard.birth_country_id) ?? null}
                />
              </FormControl>
            </Grid>

            {isRussianCitizenship && (
              <Fragment>
                <Grid item xs={3}>
                  <InputField
                    disabled={!isRussianCitizenship}
                    label="Регион (область)"
                    name="birth_place_region2"
                    onChange={onChangeBirthPlaceValue}
                    value={idCard.birth_place_region2}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    disabled={!isRussianCitizenship}
                    label="Район"
                    name="birth_place_region"
                    onChange={onChangeBirthPlaceValue}
                    value={idCard.birth_place_region}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    label="Город"
                    name="birth_place"
                    onChange={onChangeBirthPlaceValue}
                    value={idCard.birth_place}
                  />
                </Grid>

                <Grid item xs={3}>
                  <InputField
                    disabled={!isRussianCitizenship}
                    label="Населенный пункт"
                    name="birth_place2"
                    onChange={onChangeBirthPlaceValue}
                    value={idCard.birth_place2}
                  />
                </Grid>
              </Fragment>
            )}

            {!isRussianCitizenship && (
              <Grid item xs={9}>
                <InputField
                  label="Место рождения"
                  name="birth_place"
                  onChange={onChangeBirthPlaceValue}
                  value={idCard.birth_place}
                />
              </Grid>
            )}
          </Grid>
        </fieldset>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(observer(Birthplace));
