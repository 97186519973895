export function getFileNameFromUrl(url: string): [string, string] {
  if (!url || typeof url !== 'string') {
    return ['', ''];
  }

  let parsedUrl = url;

  /*
   * remove everything from start to the last slash in URL
   * so this: `https://example.com/my/page#hash?key=value`
   * will become this: `page#hash?key=value`
   * */
  parsedUrl = parsedUrl.substr(1 + url.lastIndexOf('/'));

  /*
   * break URL at ? and take first part
   * so this: `page#hash?key=value`
   * will become this: `page#hash`
   * */
  parsedUrl = parsedUrl.split('?')[0];

  /*
   * break URL at # and take first part
   * so this: `page#hash`
   * will become this: `page`
   * */
  parsedUrl = parsedUrl.split('#')[0];

  if (!parsedUrl.includes('.')) {
    return ['', ''];
  }

  /*
   * in case if filename will contain several dots (.),
   * we will split string to array and take only last element
   * so this: `very-long.pdf.filename.jpg`
   * will become this: `.jpg`
   * */
  const filenameSplitByDots = parsedUrl.replaceAll('"', '').split('.');

  const [extension, ...filename] = filenameSplitByDots.reverse();

  return [filename.reverse().join(), extension.toLocaleLowerCase()];
}
