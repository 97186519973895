import React, { ChangeEvent, memo, useCallback, useEffect } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { GetPersonsArguments } from '@/stores/PersonsStore';
import { DatepickerField, InputField } from '@/ui-kit/components';

import { SRMStatus } from '../BookingsTable.constants';

import { DEFAULT_FILTER_FORM_VALUES } from './FilterRow.constants';
import { FilterRowCell, FilterRowDatepicker, FilterRowStatus } from './FilterRow.style';

interface FilterProps {
  filterData: Partial<GetPersonsArguments>;
  onFilter: (values: Partial<GetPersonsArguments>) => void;
}

function FilterRow(props: FilterProps) {
  const { filterData, onFilter } = props;

  const filterForm = useFormik<Partial<GetPersonsArguments>>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: { ...DEFAULT_FILTER_FORM_VALUES, ...filterData },
    onSubmit: onFilter,
    onReset: () => onFilter(DEFAULT_FILTER_FORM_VALUES),
  });

  useEffect(() => {
    filterForm.setValues({ ...DEFAULT_FILTER_FORM_VALUES, ...filterData });
  }, [JSON.stringify(filterData)]);

  const onChangeCreationFrom = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      filterForm.setFieldValue('creationFrom', startOfDay(new Date(target.value)));
    }
  }, []);
  const onChangeCreationTo = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      filterForm.setFieldValue('creationTo', endOfDay(new Date(target.value)));
    }
  }, []);
  const onChangeStayFrom = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      filterForm.setFieldValue('stayFrom', startOfDay(new Date(target.value)));
    }
  }, []);
  const onChangeStayTo = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.value) {
      filterForm.setFieldValue('stayTo', endOfDay(new Date(target.value)));
    }
  }, []);

  return (
    <TableRow>
      <FilterRowCell colSpan={9} padding="none">
        <form onReset={filterForm.handleReset} onSubmit={filterForm.handleSubmit}>
          <Table size="small" stickyHeader>
            <TableBody>
              <TableRow>
                <TableCell width="8%">
                  <FilterRowStatus
                    name="hms_status"
                    onChange={filterForm.handleChange}
                    value={filterForm.values.hms_status}
                  >
                    <MenuItem value={SRMStatus.Reserved}>Reserved</MenuItem>

                    <MenuItem value={SRMStatus.DueIn}>Due In</MenuItem>

                    <MenuItem value={SRMStatus.CheckedIn}>Checked In</MenuItem>

                    <MenuItem value={SRMStatus.DueOut}>Due Out</MenuItem>

                    <MenuItem value={SRMStatus.CheckedOut}>Checked Out</MenuItem>

                    <MenuItem value={SRMStatus.Cancelled}>Cancelled</MenuItem>
                  </FilterRowStatus>
                </TableCell>

                <TableCell width="30%">
                  <InputField
                    clearable
                    label="Фамилия Имя"
                    name="name"
                    onChange={filterForm.handleChange}
                    size="small"
                    value={filterForm.values.name}
                  />
                </TableCell>

                <TableCell width="25%">
                  <FilterRowDatepicker
                    disableFuture
                    label="Дата создания, от"
                    name="creationFrom"
                    onChange={onChangeCreationFrom}
                    value={filterForm.values.creationFrom}
                  />

                  <DatepickerField
                    disableFuture
                    label="Дата создания, до"
                    minDate={filterForm.values.creationFrom ? filterForm.values.creationFrom : undefined}
                    name="creationTo"
                    onChange={onChangeCreationTo}
                    value={filterForm.values.creationTo}
                  />
                </TableCell>

                <TableCell width="25%">
                  <FilterRowDatepicker
                    disableFuture
                    label="Срок пребывания, от"
                    name="stayFrom"
                    onChange={onChangeStayFrom}
                    value={filterForm.values.stayFrom}
                  />

                  <DatepickerField
                    label="Срок пребывания, до"
                    minDate={filterForm.values.stayFrom ? filterForm.values.stayFrom : undefined}
                    name="stayTo"
                    onChange={onChangeStayTo}
                    value={filterForm.values.stayTo}
                  />
                </TableCell>

                <TableCell width="25%">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>

                  <IconButton type="reset">
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </FilterRowCell>
    </TableRow>
  );
}

export default memo(observer(FilterRow));
