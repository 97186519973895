import React, { memo } from 'react';
import { format, isToday } from 'date-fns';
import { observer } from 'mobx-react-lite';
import CakeIcon from '@mui/icons-material/Cake';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import man from '@/assets/man.png';
import woman from '@/assets/woman.png';
import { DATE_HUMAN_FORMAT } from '@/constants';
import { useBreakfastStore } from '@/routes/Main/OperatorPanel/Breakfast/BreakfastStore';
import { Gender, Person } from '@/stores/PersonsStore';

import {
  BirthdayBlock,
  BirthdayBlockContent,
  PersonBlockContent,
  PersonBlockDetails,
  PersonBlockPhoto,
  PersonBlockRoot,
} from './PersonBlock.style';

interface PersonBlockProps {
  person: Person;
}

function PersonBlock(props: PersonBlockProps) {
  const { person } = props;
  const { selectedPersons, selectPerson } = useBreakfastStore();

  return (
    <PersonBlockRoot
      onClick={() => selectPerson(person.id)}
      selected={selectedPersons.some((id) => id === person.id)}
      variant="outlined"
    >
      <PersonBlockContent>
        <PersonBlockPhoto>
          {(person.gender === Gender.Unknown || person.gender === Gender.Male) && (
            <img alt="male" height="250" src={man} />
          )}

          {person.gender === Gender.Female && <img alt="female" height="250" src={woman} />}
        </PersonBlockPhoto>

        <PersonBlockDetails>
          <Typography gutterBottom variant="subtitle1">
            {(person.fname || person.lname) && (
              <strong>
                {person.lname && <span>{person.lname} </span>}

                {person.fname && <span>{person.fname}</span>}
              </strong>
            )}

            {(person.lname_lat || person.fname_lat) && (
              <div>
                {person.lname_lat && <span>{person.lname_lat} </span>}

                {person.fname_lat && <span>{person.fname_lat}</span>}
              </div>
            )}
          </Typography>

          <BirthdayBlock>
            {person.birth_date && isToday(new Date(person.birth_date)) && (
              <BirthdayBlockContent>
                <Grid container>
                  <Grid item textAlign="center" xs={2}>
                    <CakeIcon color="success" fontSize="small" />
                  </Grid>

                  <Grid item xs={10}>
                    <Typography variant="body2">У гостя сегодня день рождения</Typography>
                  </Grid>
                </Grid>
              </BirthdayBlockContent>
            )}
          </BirthdayBlock>

          <Typography variant="caption">Проживание</Typography>

          <Typography variant="body2">
            <span>
              {person.stay_info?.stay_from && format(new Date(person.stay_info?.stay_from), DATE_HUMAN_FORMAT)}
            </span>

            <span> - </span>

            <span>
              {person.stay_info?.stay_till && format(new Date(person.stay_info?.stay_till), DATE_HUMAN_FORMAT)}
            </span>
          </Typography>

          <Typography variant="caption">Завтрак</Typography>

          <Typography variant="body2">Не отмечен</Typography>
        </PersonBlockDetails>
      </PersonBlockContent>
    </PersonBlockRoot>
  );
}

export default memo(observer(PersonBlock));
