import React, { ChangeEvent, memo } from 'react';
import Typography from '@mui/material/Typography';

import GenderSelect from '@/shared/GenderSelect/GenderSelect';
import { Gender } from '@/stores/PersonsStore';
import { DatepickerField, InputField } from '@/ui-kit/components';

import { BasicInfoControls, BasicInfoRoot } from './BasicInfo.style';

interface BasicInfoProps {
  lname: string;
  fname: string;
  mname: string;
  birthDate: Nullable<string>;
  gender: Gender;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function BasicInfo(props: BasicInfoProps) {
  const { lname, fname, mname, birthDate, gender, onChange } = props;

  return (
    <BasicInfoRoot>
      <Typography gutterBottom variant="h4">
        Шаг 3: общие данные
      </Typography>

      <Typography gutterBottom variant="h5">
        Заполните данные о себе (по документу, удостоверяющему личность)
      </Typography>

      <BasicInfoControls>
        <InputField
          label="Фамилия" margin="normal" name="lname" onChange={onChange} value={lname}
          variant="outlined"
        />

        <InputField
          label="Имя" margin="normal" name="fname" onChange={onChange} value={fname}
          variant="outlined"
        />

        <InputField
          label="Отчество"
          margin="normal"
          name="mname"
          onChange={onChange}
          value={mname}
          variant="outlined"
        />

        <DatepickerField
          disableFuture
          error={false}
          label="Дата рождения"
          margin="normal"
          name="birth_date"
          onChange={onChange}
          value={birthDate}
          variant="outlined"
        />

        <GenderSelect margin="dense" onChange={onChange} value={gender} variant="outlined" />
      </BasicInfoControls>
    </BasicInfoRoot>
  );
}

export default memo(BasicInfo);
