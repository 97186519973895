import React, { forwardRef, memo, Ref } from 'react';
import { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { ButtonLoaderIndicator, ButtonRoot } from './ButtonLoader.style';

interface ButtonLoaderProps extends ButtonProps {
  isLoading: boolean;
}

const ButtonLoader = forwardRef((props: ButtonLoaderProps, ref: Ref<HTMLButtonElement>) => {
  const { children, disabled = false, isLoading, variant = 'contained', color = 'primary', ...rest } = props;

  return (
    <ButtonRoot ref={ref} {...rest} color={color} disabled={disabled || isLoading} variant={variant}>
      {children}

      {isLoading && (
        <ButtonLoaderIndicator>
          <CircularProgress size={24} />
        </ButtonLoaderIndicator>
      )}
    </ButtonRoot>
  );
});

export default memo(ButtonLoader);
