import React, { ChangeEvent, Fragment, memo, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import {
  GetPersonsArguments,
  GroupTemplateValues,
  MigrationTemplateForm,
  Person,
  PersonTemplate,
  VisaTemplateForm,
} from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, DatepickerField, SelectField } from '@/ui-kit/components';

import GroupForm from './GroupForm/GroupForm';
import LegalRepresentativeSelector from './LegalRepresentativeSelector/LegalRepresentativeSelector';
import MigrationForm from './MigrationForm/MigrationForm';
import { TemplateModalContent, TemplateModalTitle } from './TemplateModal.style';
import VisaForm from './VisaForm/VisaForm';

interface StayDateTemplateForm {
  stay_from: Nullable<string>;
  stay_till: Nullable<string>;
  category_id: number;
  legal_representative_id: number;
  visit_purpose_id: StringOrNumber;
  date_entry_rf: Nullable<string>;
}

function TemplateModal() {
  const { groupId } = useParams();
  const {
    selectedPersons,
    isCreatingTemplate,
    updatePersonsFromTemplate,
    createTemplate,
    createGroupTemplate,
    getPersons,
    getValidFilterData,
  } = useStore('personsStore');
  const { personCategories, visitGoals } = useStore('dictionariesStore');
  const { userData } = useStore('signInStore');
  const [isTemplatesOpen, setIsTemplatesOpen] = useState(false);

  async function reloadPersons() {
    try {
      const query = queryAPI.params as Record<keyof GetPersonsArguments, string>;
      const validFilterData = getValidFilterData(query);

      await getPersons({
        ...validFilterData,
        groupId,
      });
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const personForm = useFormik<StayDateTemplateForm>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    initialValues: {
      stay_from: null,
      stay_till: null,
      category_id: 0,
      legal_representative_id: 0,
      visit_purpose_id: '',
      date_entry_rf: null,
    },
    onSubmit: async (values: StayDateTemplateForm, { resetForm }: FormikHelpers<StayDateTemplateForm>) => {
      const { stay_from, stay_till, category_id, legal_representative_id, visit_purpose_id, date_entry_rf } = values;

      try {
        const body: Partial<PersonTemplate> = {};

        if (category_id) {
          body.category_id = category_id;
        }

        if (legal_representative_id) {
          body.legal_representative_id = legal_representative_id;
        }

        if (stay_from) {
          if (body.stay_info) {
            body.stay_info.stay_from = stay_from;
          } else {
            body.stay_info = {
              stay_from: stay_from,
            };
          }
        }

        if (stay_till) {
          if (body.stay_info) {
            body.stay_info.stay_till = stay_till;
          } else {
            body.stay_info = {
              stay_till: stay_till,
            };
          }
        }

        if (visit_purpose_id) {
          if (body.stay_info) {
            body.stay_info.visit_purpose_id = +visit_purpose_id;
          } else {
            body.stay_info = {
              visit_purpose_id: +visit_purpose_id,
            };
          }
        }

        if (date_entry_rf) {
          if (body.stay_info) {
            body.stay_info.date_entry_rf = date_entry_rf;
          } else {
            body.stay_info = {
              date_entry_rf: date_entry_rf,
            };
          }
        }

        await updatePersonsFromTemplate(body);

        notificationAPI.success('Шаблон успешно создан');

        setIsTemplatesOpen(false);

        reloadPersons();

        resetForm();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
  });

  const visaForm = useFormik<VisaTemplateForm>({
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      document_type_enum_id: 33,
      doc_expire_date: null,
      doc_issue_date: null,
      doc_serial: '',
      doc_start_date: null,
      doc_visa_multi_type_id: '',
      doc_visa_type_id: '',
      doc_visa_visit_type_id: '',
      visit_side: '',
    },
    onSubmit: async (values: VisaTemplateForm, { resetForm }: FormikHelpers<VisaTemplateForm>) => {
      try {
        await createTemplate(values);

        notificationAPI.success('Шаблон успешно создан');

        setIsTemplatesOpen(false);

        reloadPersons();

        resetForm();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
  });

  const groupForm = useFormik<GroupTemplateValues>({
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      document_type_enum_id: 33,
      images: [],
    },
    onSubmit: async (values: GroupTemplateValues, { resetForm }: FormikHelpers<GroupTemplateValues>) => {
      try {
        await createGroupTemplate(values);

        notificationAPI.success('Шаблон успешно создан');

        setIsTemplatesOpen(false);

        reloadPersons();

        resetForm();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
  });

  const migrationForm = useFormik<MigrationTemplateForm>({
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: false,
    initialValues: {
      document_type_enum_id: 36,
      doc_serial: '',
      doc_issue_date: null,
      doc_expire_date: null,
      entry_point_id: 0,
      visit_purpose_id: '',
    },
    onSubmit: async (values: MigrationTemplateForm, { resetForm }: FormikHelpers<MigrationTemplateForm>) => {
      try {
        await createTemplate(values);

        notificationAPI.success('Шаблон успешно создан');

        setIsTemplatesOpen(false);

        reloadPersons();

        resetForm();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
  });

  function onSubmitHandler() {
    if (personForm.dirty && personForm.isValid) {
      personForm.handleSubmit();
    }

    if (visaForm.dirty) {
      visaForm.handleSubmit();
    }

    if (groupForm.dirty) {
      groupForm.handleSubmit();
    }

    if (migrationForm.dirty) {
      migrationForm.handleSubmit();
    }
  }

  const onCloseHandler = useCallback(() => {
    personForm.resetForm();
    visaForm.resetForm();
    groupForm.resetForm();
    migrationForm.resetForm();

    setIsTemplatesOpen(false);
  }, []);

  const isSavingAvailable =
    (personForm.dirty && personForm.isValid) ||
    (visaForm.dirty && visaForm.isValid) ||
    (groupForm.dirty && groupForm.isValid) ||
    (migrationForm.dirty && migrationForm.isValid);

  const onToggleTemplateModalsHandler = useCallback(
    () => setIsTemplatesOpen((isTemplatesOpen: boolean) => !isTemplatesOpen),
    [],
  );

  const onStayFromChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    personForm.setFieldValue('stay_from', target.value);
  }, []);
  const onStayTillChangeHandler = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    personForm.setFieldValue('stay_till', target.value);
  }, []);

  const onVisaFormChangeHandler = useCallback(
    (field: string, value: Nullable<string>) => visaForm.setFieldValue(field, value),
    [],
  );
  const onGroupFormChangeHandler = useCallback(
    (field: string, value: string[]) => groupForm.setFieldValue(field, [...groupForm.values.images, ...value]),
    [],
  );
  const onMigrationFormChangeHandler = useCallback(
    (field: string, value: unknown) => migrationForm.setFieldValue(field, value),
    [],
  );

  const onChangeCategoryId = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target;

      personForm.setValues({
        ...personForm.values,
        [name]: value,
      });
    },
    [personForm.values],
  );

  const onChangeLegalRepresentative = useCallback(
    (person: Nullable<Person>) => {
      personForm.setValues({
        ...personForm.values,
        legal_representative_id: person ? person.id : 0,
      });
    },
    [personForm.values],
  );

  return (
    <Fragment>
      <Button
        color="primary"
        disabled={selectedPersons.size === 0}
        onClick={onToggleTemplateModalsHandler}
        size="small"
        variant="outlined"
      >
        Шаблон
      </Button>

      <Dialog fullScreen onClose={onCloseHandler} open={isTemplatesOpen}>
        <BackdropLoader isLoading={isCreatingTemplate} />

        <AppBar>
          <Toolbar>
            <IconButton color="inherit" edge="start" onClick={onCloseHandler}>
              <CloseIcon />
            </IconButton>

            <TemplateModalTitle variant="h6">Шаблоны</TemplateModalTitle>

            <Button
              autoFocus
              color="inherit"
              disabled={isCreatingTemplate || !isSavingAvailable}
              onClick={onSubmitHandler}
            >
              Применить
            </Button>
          </Toolbar>
        </AppBar>

        <TemplateModalContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Общие данные</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid alignItems="flex-start" container spacing={2}>
                    <Grid item xs={2}>
                      <DatepickerField
                        error={!!personForm.errors.stay_from}
                        helperText={personForm.errors.stay_from}
                        label="Дата заезда"
                        onChange={onStayFromChangeHandler}
                        size="small"
                        value={personForm.values.stay_from}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <DatepickerField
                        error={!!personForm.errors.stay_till}
                        helperText={personForm.errors.stay_till}
                        label="Дата выезда"
                        onChange={onStayTillChangeHandler}
                        size="small"
                        value={personForm.values.stay_till}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <SelectField
                        disabled={visitGoals.length === 0}
                        error={!!personForm.errors.visit_purpose_id}
                        label="Цель поездки"
                        name="visit_purpose_id"
                        onChange={personForm.handleChange}
                        value={personForm.values.visit_purpose_id}
                      >
                        {visitGoals.map(({ id, name_ru }) => (
                          <MenuItem key={id} value={id}>
                            {name_ru}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>

                    <Grid item xs={2}>
                      <DatepickerField
                        label="Дата въезда в РФ"
                        name="date_entry_rf"
                        onChange={personForm.handleChange}
                        value={personForm.values.date_entry_rf}
                      />
                    </Grid>

                    {userData?.allow_export === 1 && (
                      <Grid item xs={2}>
                        <SelectField
                          label="Категория гостя"
                          onChange={onChangeCategoryId}
                          value={personForm.values.category_id}
                        >
                          <MenuItem value={0}>Выберите категорию</MenuItem>

                          {personCategories.map(({ id, name_ru }) => (
                            <MenuItem key={id} value={id}>
                              {name_ru}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Виза</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <VisaForm form={visaForm} onChange={onVisaFormChangeHandler} />
                </AccordionDetails>
              </Accordion>

              {/* {userData?.allow_export === 1 && (*/}

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Виза (групповая)</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <GroupForm form={groupForm} onChange={onGroupFormChangeHandler} />
                </AccordionDetails>
              </Accordion>

              {/* )}*/}

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Миграционная карта</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <MigrationForm form={migrationForm} onChange={onMigrationFormChangeHandler} />
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Законный представитель</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <LegalRepresentativeSelector
                    legalRepresentativeId={personForm.values.legal_representative_id}
                    onSelect={onChangeLegalRepresentative}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </TemplateModalContent>
      </Dialog>
    </Fragment>
  );
}

export default memo(observer(TemplateModal));
