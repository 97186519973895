import AccountIcon from '@mui/icons-material/AccountBox';
import common from '@mui/material/colors/common';
import styled from '@mui/material/styles/styled';

export const HeaderRoot = styled('div')`
  padding: 8px 12px;
  height: 70px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  max-width: 1920px;
  margin: 0 auto;
`;

export const HeaderLogo = styled('a')`
  flex-shrink: 0;
  margin-right: 24px;
  display: flex;
`;

export const HeaderUser = styled('div')`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
`;

export const HeaderUserInfo = styled('div')`
  display: flex;
  align-items: center;
  border-right: 1px solid ${common.white};
  margin-right: 8px;
  padding-right: 8px;
`;

export const HeaderUserInfoLink = styled('a')`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  },
`;

export const HeaderUserAccountIcon = styled(AccountIcon)`
  display: inline-flex;
  margin-right: 8px;
`;

export const HeaderUserAccountEmail = styled('span')`
  padding-left: 8px;
`;
