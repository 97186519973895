import React, { memo } from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';

import { ButtonLoader, ContentModal } from '@/ui-kit/components';

interface ConfirmCloseModalProps {
  open: boolean;
  isLoading: boolean;
  onConfirm: () => void;
  onDecline: () => void;
  onCancel: () => void;
}

function ConfirmCloseModal(props: ConfirmCloseModalProps) {
  const { open, isLoading, onConfirm, onCancel, onDecline } = props;

  return (
    <ContentModal onClose={onCancel} open={open}>
      <ContentModal.Header isCloseDisabled={isLoading} onClose={onCancel}>
        Внимание! Отсканированные данные не сохранены.
      </ContentModal.Header>

      <ContentModal.Body>
        <DialogContentText>Сохранить?</DialogContentText>
      </ContentModal.Body>

      <ContentModal.Footer>
        <ButtonLoader color="success" isLoading={isLoading} onClick={onConfirm} type="button">
          Сохранить
        </ButtonLoader>

        <Button color="primary" disabled={isLoading} onClick={onDecline} type="button" variant="contained">
          Нет
        </Button>

        <Button color="primary" disabled={isLoading} onClick={onCancel} type="reset" variant="outlined">
          Отмена
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(ConfirmCloseModal);
