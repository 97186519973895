import React, { ChangeEvent, memo, useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import Checkbox from '@mui/material/Checkbox/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { queryAPI } from '@/api/queryAPI';
import { useMount } from '@/hooks/useMount/useMount';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useUnmount } from '@/hooks/useUnmount/useUnmount';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, ConfirmModal, SelectField } from '@/ui-kit/components';
import DropdownMenu from '@/ui-kit/components/DropdownMenu/DropdownMenu';

import KSListRow from './KSListRow/KSListRow';
import {
  KSListContent,
  KSListRoot,
  KSListTableContent,
  KSListWrapper,
  TablePaginationContent,
} from './KSListTable.style';

function KSListTable() {
  const { search, hash, pathname } = useLocation();
  const navigate = useNavigate();
  const {
    shouldShowKSLoader,
    ksList,
    total,
    ksListFilter,
    setKSFilter,
    resetKSList,
    resetKSFilter,
    resetAllSelectedKSItems,
    abortKSList,
    selectedKSItem,
    selectAllKSItems,
    isClearingAISKS,
    isFetchingKSList,
    clearAISKS,
  } = useStore('reportsStore');

  const containerRef = useRef<Nullable<HTMLDivElement>>(null);

  const [isConfirmClearOpen, openConfirmClear, closeConfirmClear] = useToggle();

  const onConfirmAISKS = useCallback(() => {
    closeConfirmClear();
    clearAISKS();
  }, []);

  useMount(() => {
    setKSFilter(queryAPI.params);
  });

  useEffect(() => {
    if (!isFetchingKSList) {
      containerRef.current?.scrollTo({ top: 0, left: 0 });

      // Ждем пока отрендерится экран с персонами и только потом ищем ноду с айди
      setTimeout(() => {
        const targetNode = document.getElementById(hash);

        if (targetNode) {
          targetNode?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });

          navigate(`${pathname}${search}`);
        }
      }, 200);
    }
  }, [isFetchingKSList]);

  useUnmount(() => {
    resetKSList();
    abortKSList();
    resetKSFilter();
    resetAllSelectedKSItems();
  });

  function onChangePage(e: ChangeEvent<unknown>, newPage: number) {
    e?.preventDefault();

    setKSFilter({
      page: newPage,
    });

    navigate({
      pathname,
      search: queryAPI.generateQuery({
        ...ksListFilter,
        page: newPage,
      }),
    });
  }

  function onChangeLimit(e: ChangeEvent<HTMLInputElement>) {
    const limit = +e.target.value;

    setKSFilter({
      limit,
    });

    navigate({
      pathname,
      search: queryAPI.generateQuery({
        ...ksListFilter,
        limit: limit > 0 ? limit : undefined,
        page: 0, // при смене количество записей всегда сбрасывать страницу
      }),
    });
  }

  const pages = Math.round(total / ksListFilter.limit);

  return (
    <KSListRoot>
      <BackdropLoader isLoading={shouldShowKSLoader} />

      <KSListContent>
        <KSListWrapper ref={containerRef}>
          <KSListTableContent size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="none" width="30">
                  <Tooltip title="Выделить все карточки">
                    <Checkbox
                      checked={selectedKSItem.size === ksList.length && ksList.length > 0}
                      color="primary"
                      onChange={selectAllKSItems}
                    />
                  </Tooltip>
                </TableCell>

                <TableCell width="12%">Фамилия</TableCell>

                <TableCell width="12%">Имя</TableCell>

                <TableCell width="12%">Отчество</TableCell>

                <TableCell width="10%">Сроки пребывания</TableCell>

                <TableCell>Статус</TableCell>

                <TableCell width="10%">Баланс КС</TableCell>

                <TableCell width="10%">Расчётная сумма КС</TableCell>

                <TableCell width="10%">
                  {selectedKSItem.size > 0 && (
                    <DropdownMenu
                      contextOpener={<span>Выгружен в АИС КС</span>}
                      options={[
                        {
                          label: 'Очистить идентификаторы АИС КС',
                          onClick: openConfirmClear,
                        },
                      ]}
                    />
                  )}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {ksList.map((ksItem) => (
                <KSListRow key={ksItem.id} ksItem={ksItem} />
              ))}

              {!shouldShowKSLoader && ksList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={9}>
                    <Typography align="center">Данные отсутствуют</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </KSListTableContent>
        </KSListWrapper>

        {pages > 0 && (
          <TablePaginationContent>
            <span>Записей на странице:&nbsp;&nbsp;&nbsp;</span>

            <div>
              <SelectField onChange={onChangeLimit} value={ksListFilter.limit}>
                {[10, 25, 50, 75, 100].map((value) => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField>
            </div>

            <Pagination
              boundaryCount={2}
              count={Math.ceil(total / ksListFilter.limit)}
              onChange={onChangePage}
              page={ksListFilter.page}
              siblingCount={0}
            />
          </TablePaginationContent>
        )}
      </KSListContent>

      <ConfirmModal
        confirmButtonProps={{
          color: 'error',
        }}
        confirmLabel="Очистить"
        description="Использовать с осторожностью! Вы действительно хотите очистить значения АИС КС у выбранных карточек гостей?"
        isLoading={isClearingAISKS}
        onClose={closeConfirmClear}
        onConfirm={onConfirmAISKS}
        onDecline={closeConfirmClear}
        open={isConfirmClearOpen}
        title="Очистка значений АИС КС"
      />
    </KSListRoot>
  );
}

export default memo(observer(KSListTable));
