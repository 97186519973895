import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ContentModal } from '@/ui-kit/components';

interface SRMInfoModalProps {
  open: boolean;
  onClose: () => void;
}

function SRMInfoModal(props: SRMInfoModalProps) {
  const { open, onClose } = props;

  return (
    <ContentModal disableEscapeKeyDown={false} onClose={onClose} open={open}>
      <ContentModal.Header onClose={onClose}>Подробнее о статусах</ContentModal.Header>

      <ContentModal.Body>
        <Typography gutterBottom>
          <strong>Ожидается отправка уведомления</strong> - гостю еще не отправлялось уведомление о прохождении
          самостоятельной регистрации.
        </Typography>

        <Typography gutterBottom>
          <strong>Уведомление отправлено</strong> - гостю отправлено уведомление выбранным способом (email или смс).
        </Typography>

        <Typography gutterBottom>
          <strong>Ожидает подтверждения</strong> - гость воспользовался самостоятельной регистрацией, необходимо
          проверить изображения документов.
        </Typography>

        <Typography gutterBottom>
          <strong>Подтверждение отправлено</strong> - гостю отправлено подтверждение указанным способом (email или смс)
          после проверки изображений документов.
        </Typography>

        <Typography>
          <strong>Требуется обработка документов вручную при заезде</strong> - гостем прикреплены неверные изображения
          или они нечитаемы (плохое качество, блики и т.п.). Необходима повторная проверка документов гостя при заезде
          документов.
        </Typography>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose}>
          Закрыть
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(SRMInfoModal));
