import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { RetentionPerson } from '@/entities/retentionPerson';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { ContentModal, ContentModalProps } from '@/ui-kit/components';

interface RetentionPersonsModalProps {
  retentionId: number;
}

function RetentionPersonsModal(props: ContentModalProps<RetentionPersonsModalProps>) {
  const { open, onClose, retentionId } = props;
  const { getRetentionPersonsById } = useStore('reportsStore');

  const [isFetching, startFetching, stopFetching] = useToggle();
  const [retentionPersons, setRetentionPersons] = useState<RetentionPerson[]>([]);

  async function fetchRetentionPersons() {
    startFetching();

    try {
      const persons = await getRetentionPersonsById(retentionId);

      setRetentionPersons(persons);

      stopFetching();
    } catch (e) {
      notificationAPI.error(e);

      stopFetching();

      onClose();
    }
  }

  useEffect(() => {
    if (open) {
      fetchRetentionPersons();
    }

    return () => {
      setRetentionPersons([]);
    };
  }, [open]);

  return (
    <ContentModal disableEscapeKeyDown={isFetching} fullWidth maxWidth="sm" onClose={onClose} open={open}>
      <ContentModal.Header isCloseDisabled={isFetching} onClose={onClose}>
        Уничтоженные карточки гостей
      </ContentModal.Header>

      <ContentModal.Body>
        <Table size="small" stickyHeader sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell>ФИО</TableCell>

              <TableCell width="35%">Сроки проживания</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!isFetching &&
              retentionPersons.length > 0 &&
              retentionPersons.map((p: RetentionPerson) => (
                <TableRow key={p.person_id}>
                  <TableCell>
                    <Typography>{p.personName}</Typography>

                    <Typography variant="caption">{p.personNameLat}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography>{p.stayFrom}</Typography>

                    <Typography align="right">{p.stayTill}</Typography>
                  </TableCell>
                </TableRow>
              ))}

            {!isFetching && retentionPersons.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography align="center">Данные отсутствуют</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" onClick={onClose} type="button">
          Закрыть
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(RetentionPersonsModal));
