import React, { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import { differenceInBusinessDays, format } from 'date-fns';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { DATE_FOR_SERVER_FORMAT } from '@/constants';
import { DictionaryBasicWithCode, DocIssueAuthority } from '@/entities/dictionary';
import { ExportToXlsxValues } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField, ButtonLoader, DatepickerField } from '@/ui-kit/components';

import { DEFAULT_STATISTICS_FORM } from './ReportForm.constants';
import { HotelReportActions, HotelReportForm, HotelReportSubmitButton } from './ReportForm.style';

function ReportForm() {
  const { countries } = useStore('dictionariesStore');
  const { exportPersonsToXlsx, isExporting } = useStore('personsStore');

  const preparedCountries = useMemo(
    () =>
      countries.concat([
        {
          id: -171,
          name_ru: 'Кроме России',
          name_en: '',
          code: '',
        },
      ]),
    [],
  );

  const statisticsForm = useFormik({
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    initialValues: DEFAULT_STATISTICS_FORM,
    onSubmit: async (values: ExportToXlsxValues) => {
      const body = Object.keys(values).reduce<Partial<ExportToXlsxValues>>((previousValue, currentValue) => {
        const result = {
          ...previousValue,
        };

        if (values[currentValue]) {
          switch (currentValue) {
            case 'stay_from':
            case 'stay_to':
            case 'created_from':
            case 'created_to':
              result[currentValue] = format(new Date(values[currentValue] as string), DATE_FOR_SERVER_FORMAT);

              break;
            default:
              result[currentValue] = values[currentValue];

              break;
          }
        }

        return result;
      }, {});

      try {
        await exportPersonsToXlsx(body as ExportToXlsxValues);
      } catch (e) {
        notificationAPI.error('Некорректные данные');
      }
    },
    validate: (values: ExportToXlsxValues) => {
      const { stay_to, stay_from } = values;
      const errors: Record<string, string> = {};

      if (!stay_from) {
        errors.stay_from = 'Обязательно к заполнению';
      }

      if (!stay_to) {
        errors.stay_to = 'Обязательно к заполнению';
      }

      if (stay_from && stay_to && differenceInBusinessDays(new Date(stay_to), new Date(stay_from)) > 30) {
        errors.stay_to = 'Срок проживания не может быть более 30 дней';
      }

      return errors;
    },
  });

  const renderOption = useCallback(({ name_ru }: DocIssueAuthority) => `${name_ru}`, []);

  const onCountryChange = useCallback((country: unknown, e: SyntheticEvent) => {
    e.preventDefault();

    statisticsForm.setFieldValue('country_id', country ? (country as DictionaryBasicWithCode).id : null);
  }, []);

  return (
    <HotelReportForm onReset={statisticsForm.handleReset} onSubmit={statisticsForm.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Выгрузка отчета</Typography>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatepickerField
                error={!!statisticsForm.errors.stay_from}
                helperText={statisticsForm.errors.stay_from}
                label="Срок пребывания, от"
                name="stay_from"
                onChange={statisticsForm.handleChange}
                value={statisticsForm.values.stay_from}
              />
            </Grid>

            <Grid item xs={6}>
              <DatepickerField
                error={!!statisticsForm.errors.stay_to}
                helperText={statisticsForm.errors.stay_to}
                label="Срок пребывания, до"
                name="stay_to"
                onChange={statisticsForm.handleChange}
                value={statisticsForm.values.stay_to}
              />
            </Grid>

            <Grid item xs={6}>
              <DatepickerField
                error={!!statisticsForm.errors.created_from}
                helperText={statisticsForm.errors.created_from}
                label="Дата создания, от"
                name="created_from"
                onChange={statisticsForm.handleChange}
                value={statisticsForm.values.created_from}
              />
            </Grid>

            <Grid item xs={6}>
              <DatepickerField
                error={!!statisticsForm.errors.created_to}
                helperText={statisticsForm.errors.created_to}
                label="Дата создания, до"
                name="created_to"
                onChange={statisticsForm.handleChange}
                value={statisticsForm.values.created_to}
              />
            </Grid>

            <Grid item xs={12}>
              <AutocompleteField
                getOptionLabel={renderOption}
                label="Гражданство"
                name="citizenship"
                onChange={onCountryChange}
                options={preparedCountries}
                value={preparedCountries.find(({ id }) => id === statisticsForm.values.country_id) ?? null}
              />
            </Grid>

            <Grid item xs={12}>
              <HotelReportActions>
                <HotelReportSubmitButton>
                  <ButtonLoader isLoading={isExporting} type="submit">
                    Сформировать отчет
                  </ButtonLoader>
                </HotelReportSubmitButton>

                <Button type="reset" variant="outlined">
                  Очистить
                </Button>
              </HotelReportActions>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                <b>Сортировать данные по:</b>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset" error={!!statisticsForm.errors.order}>
                <RadioGroup name="order" onChange={statisticsForm.handleChange} value={statisticsForm.values.order}>
                  <FormControlLabel control={<Radio color="primary" />} label="Фамилия (рус)" value="lname" />

                  <FormControlLabel control={<Radio color="primary" />} label="Фамилия (лат)" value="lname_lat" />

                  <FormControlLabel control={<Radio color="primary" />} label="Пол" value="gender" />

                  <FormControlLabel control={<Radio color="primary" />} label="Дата рождения" value="birth_date" />

                  <FormControlLabel control={<Radio color="primary" />} label="Дата создания" value="created_at" />

                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="Дата начала проживания"
                    value="stay_from"
                  />

                  <FormControlLabel control={<Radio color="primary" />} label="Гражданство" value="citizenship" />
                </RadioGroup>

                <FormHelperText>{statisticsForm.errors.order}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HotelReportForm>
  );
}

export default memo(ReportForm);
