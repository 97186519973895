import React, { memo, useCallback, useEffect } from 'react';
import { addHours } from 'date-fns';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { DEFAULT_COUNTRY } from '@/constants';
import { Address, Street } from '@/entities/address';
import { Company, CompanyModel } from '@/entities/company';
import { useUnmount } from '@/hooks/useUnmount/useUnmount';
import AddressFormOld from '@/shared/AddressFormOld/AddressFormOld';
import { TIMEZONES } from '@/shared/GeneralCompanyInfo/GeneralCompanyInfo.constants';
import { useStore } from '@/stores/StoreProvider';
import {
  BackdropLoader,
  CheckboxField,
  InputField,
  SelectField,
  SwitchField,
  TimepickerField,
} from '@/ui-kit/components';

import {
  AddressesWrapper,
  GeneralCompanyInfoContent,
  GeneralCompanyInfoForm,
  GeneralCompanyInfoHeader,
  GeneralCompanyInfoWrapper,
} from './GeneralCompanyInfo.style';

interface GeneralCompanyInfoProps {
  company: Company;
  onToggleCompanyFormTouched?: (isTouched: boolean) => void;
  isAdmin?: boolean;
}

function GeneralCompanyInfo(props: GeneralCompanyInfoProps) {
  const { company, isAdmin = false, onToggleCompanyFormTouched } = props;
  const { updateCompany, getCompanyById, isSaving } = useStore('companiesStore');
  const { exportTypes, hmsList } = useStore('dictionariesStore');

  const companyForm = useFormik<CompanyModel>({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    initialValues: company,
    onSubmit: async (values, { resetForm }) => {
      try {
        // изза валидации на бекенде значения `max_users` и `max_online_users` должны быть цифры
        const body = {
          ...values,
          max_users: +values.max_users,
          max_online_users: +values.max_online_users,
          mail_smtp_port: +values.mail_smtp_port,
        };

        await updateCompany(company.id, body);
        await getCompanyById(company.id);

        notificationAPI.success('Сведения сохранены');

        resetForm({
          values,
        });
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    validate: (values) => {
      const errors: Record<string, string | boolean> = {};

      if (values.ur_name?.length === 0) {
        errors.ur_name = true;
      }

      return errors;
    },
  });

  useEffect(() => {
    onToggleCompanyFormTouched?.(companyForm.dirty);
  }, [companyForm.dirty]);

  useUnmount(() => companyForm.resetForm());

  const onUrAddressFormChange = useCallback(
    (field: string, value: Nullable<Address | Street | string>) => {
      if (field === 'street') {
        companyForm.setValues({
          ...companyForm.values,
          ur_fias_code: (value as Street).ao_id,
          ur_fias_code2: (value as Street).ao_guid,
          ur_region_code: (value as Street).region_code,
        });
      } else {
        companyForm.setValues({
          ...companyForm.values,
          [field]: value,
        });
      }
    },
    [companyForm.values],
  );

  const onAddressFormChange = useCallback(
    (field: string, value: Nullable<Address | Street | string>) => {
      if (field === 'street') {
        companyForm.setValues({
          ...companyForm.values,
          fias_code: (value as Street).ao_id,
          fias_code2: (value as Street).ao_guid,
          region_code: (value as Street).region_code,
        });
      } else {
        companyForm.setValues({
          ...companyForm.values,
          [field]: value,
        });
      }
    },
    [companyForm.values],
  );

  const onAddressEqualityChange = useCallback(() => {
    const formValues = {
      ...companyForm.values,
    };

    formValues.fias_code = companyForm.values.ur_fias_code;
    formValues.fias_code2 = companyForm.values.ur_fias_code2;
    formValues.region_code = companyForm.values.ur_region_code;
    formValues.housing = companyForm.values.ur_housing;
    formValues.housing2 = companyForm.values.ur_housing2;

    companyForm.setValues(formValues);
  }, [companyForm.values]);

  function getCurrentTime() {
    const date = addHours(new Date(), companyForm.values.utc);
    const hours = `${date.getUTCHours()}`;
    const minutes = `${date.getUTCMinutes()}`;

    return `${hours}:${minutes.length === 1 ? `0${minutes}` : minutes}`;
  }

  return (
    <GeneralCompanyInfoForm onSubmit={companyForm.handleSubmit}>
      <BackdropLoader isLoading={isSaving} />

      {isAdmin && (
        <GeneralCompanyInfoHeader>
          <CheckboxField
            checked={companyForm.values.testing}
            color="primary"
            label="Тестирование"
            name="testing"
            onChange={companyForm.handleChange}
          />

          <Button color="primary" size="small" type="submit" variant="contained">
            Сохранить
          </Button>
        </GeneralCompanyInfoHeader>
      )}

      <GeneralCompanyInfoWrapper>
        <GeneralCompanyInfoContent>
          <Grid container spacing={2}>
            {isAdmin && (
              <Grid item xs={6}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Выгрузка</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Интеграция</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <SelectField
                      name="allow_export"
                      onChange={companyForm.handleChange}
                      value={companyForm.values.allow_export}
                    >
                      <MenuItem value={0}>Без экспорта</MenuItem>

                      {exportTypes.map((exportType) => (
                        <MenuItem key={exportType.id} value={exportType.code}>
                          {exportType.name_ru}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>

                  <Grid item xs={6}>
                    <SelectField name="hms" onChange={companyForm.handleChange} value={companyForm.values.hms}>
                      <MenuItem value={0}>Без интеграции</MenuItem>

                      {hmsList.map((hmsType) => (
                        <MenuItem key={hmsType.id} value={hmsType.id}>
                          {hmsType.name}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Параметры интеграции</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="Webhook"
                      name="webhook"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={`${companyForm.values.webhook}`}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="Webhook local"
                      name="webhook_local"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={`${companyForm.values.webhook_local}`}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="Webhook header name"
                      name="webhook_header_name"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.webhook_header_name}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="Webhook header value"
                      name="webhook_header_value"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.webhook_header_value}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Внешняя интеграция</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <InputField
                      label="HMS ссылка"
                      name="hms_link"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.hms_link}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="HMS пользователь"
                      name="hms_user"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.hms_user}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="HMS пароль"
                      name="hms_pass"
                      onChange={companyForm.handleChange}
                      size="small"
                      type="password"
                      value={companyForm.values.hms_pass}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Количество пользователей компании</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="Пользователей всего"
                      name="max_users"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={`${companyForm.values.max_users}`}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="Пользователей онлайн"
                      name="max_online_users"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.max_online_users}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Уведомление в компании</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <InputField
                      maxLength={255}
                      maxRows="4"
                      multiline
                      name="notice"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.notice}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <CheckboxField
                      checked={companyForm.values.payment_debt}
                      color="primary"
                      label="Долг по оплате"
                      name="payment_debt"
                      onChange={companyForm.handleChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">SRM</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel control={<Switch color="primary" name="value" />} label="Автоотправка" />
                  </Grid>

                  <Grid alignItems="center" display="flex" item xs={12}>
                    <span>За&nbsp;&nbsp;</span>

                    <SelectField fullWidth={false} value={1}>
                      <MenuItem value={1}>1</MenuItem>

                      <MenuItem value={2}>2</MenuItem>

                      <MenuItem value={3}>3</MenuItem>
                    </SelectField>

                    <span>&nbsp;&nbsp;суток в&nbsp;&nbsp;</span>

                    <TimepickerField
                      fullWidth={false}
                      onChange={() => {}}
                      style={{ width: 80 }}
                      type="mobile"
                      value={new Date()}
                    />

                    <span>&nbsp;&nbsp;посредством&nbsp;&nbsp;</span>

                    <SelectField fullWidth={false} value={1}>
                      <MenuItem value={1}>только email</MenuItem>

                      <MenuItem value={2}>только смс</MenuItem>

                      <MenuItem value={3}>email + sms</MenuItem>
                    </SelectField>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Другое</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <InputField
                      label="Документ, подтверждающий право пользования помещением"
                      maxRows={4}
                      multiline
                      name="apart_doc"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.apart_doc}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      <span>Курортный сбор</span>

                      <SwitchField
                        checked={companyForm.values.ks}
                        label={companyForm.values.ks ? 'Включен' : 'Выключен'}
                        name="ks"
                        onChange={companyForm.handleChange}
                        size="small"
                      />
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <InputField
                      label="Адрес системы АИС КС"
                      name="ais_url"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.ais_url}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="Логин пользователя"
                      name="ais_user"
                      onChange={companyForm.handleChange}
                      size="small"
                      value={companyForm.values.ais_user}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <InputField
                      label="Пароль пользователя"
                      name="ais_pass"
                      onChange={companyForm.handleChange}
                      size="small"
                      type="password"
                      value={companyForm.values.ais_pass}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item xs={isAdmin ? 6 : 12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Общие сведения</Typography>
                </Grid>

                <Grid item xs={12}>
                  <InputField
                    error={!!companyForm.errors.ur_name}
                    label="Юридическое наименование"
                    name="ur_name"
                    onChange={companyForm.handleChange}
                    placeholder="ООО «Гостиничный комплекс «Прибалтийская»"
                    readOnly={!isAdmin}
                    value={companyForm.values.ur_name}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputField
                    error={!!companyForm.errors.name}
                    label="Торговое наименование"
                    name="name"
                    onChange={companyForm.handleChange}
                    placeholder="Пример: Park Inn by Radisson Pribaltiyskaya"
                    readOnly={!isAdmin}
                    value={companyForm.values.name}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.tel}
                    label="Контактный телефон"
                    name="tel"
                    onChange={companyForm.handleChange}
                    placeholder="+7 (000) 000 00 00"
                    readOnly={!isAdmin}
                    value={companyForm.values.tel}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.email}
                    label="E-mail"
                    name="email"
                    onChange={companyForm.handleChange}
                    placeholder="example@mail.com"
                    readOnly={!isAdmin}
                    value={companyForm.values.email}
                  />
                </Grid>

                <Grid alignItems="center" display="flex" item xs={12}>
                  <span>Часовой пояс:&nbsp;&nbsp;</span>

                  <SelectField
                    fullWidth={false}
                    name="utc"
                    onChange={companyForm.handleChange}
                    value={companyForm.values.utc}
                  >
                    {TIMEZONES.map((timeZone) => (
                      <MenuItem key={timeZone} value={timeZone}>
                        {timeZone}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <span>&nbsp;&nbsp;Текущее время:&nbsp;&nbsp;</span>

                  <span>{getCurrentTime()}</span>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2">Адрес</Typography>
                </Grid>

                <Grid item xs={12}>
                  <AddressesWrapper>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Юридический адрес</Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <AddressFormOld
                          fiasCode={companyForm.values.ur_fias_code2}
                          onFieldChange={onUrAddressFormChange}
                          regionCode={companyForm.values.ur_region_code}
                          settings={{
                            country: {
                              value: { ...DEFAULT_COUNTRY },
                              disabled: true,
                            },
                            house: {
                              name: 'ur_housing',
                              value: companyForm.values.ur_housing,
                              readonly: !isAdmin,
                            },
                            build: {
                              name: 'ur_housing2',
                              value: companyForm.values.ur_housing2,
                              readonly: !isAdmin,
                            },
                            flat: {
                              hidden: true,
                            },
                            place: {
                              readonly: !isAdmin,
                            },
                            settlement: {
                              readonly: !isAdmin,
                            },
                            street: {
                              readonly: !isAdmin,
                            },
                            region: {
                              readonly: !isAdmin,
                            },
                            city: {
                              readonly: !isAdmin,
                            },
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Фактический адрес</Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Grid container spacing={2}>
                          {isAdmin && (
                            <Grid item xs={12}>
                              <Button color="primary" onClick={onAddressEqualityChange} size="small" variant="outlined">
                                <KeyboardArrowDownIcon color="primary" fontSize="small" />
                              </Button>
                            </Grid>
                          )}

                          <Grid item xs={12}>
                            <AddressFormOld
                              fiasCode={companyForm.values.fias_code2}
                              onFieldChange={onAddressFormChange}
                              regionCode={companyForm.values.region_code}
                              settings={{
                                country: {
                                  value: { ...DEFAULT_COUNTRY },
                                  disabled: true,
                                },
                                house: {
                                  name: 'housing',
                                  value: companyForm.values.housing,
                                  readonly: !isAdmin,
                                },
                                build: {
                                  name: 'housing2',
                                  value: companyForm.values.housing2,
                                  readonly: !isAdmin,
                                },
                                flat: {
                                  hidden: true,
                                  readonly: !isAdmin,
                                },
                                place: {
                                  readonly: !isAdmin,
                                },
                                settlement: {
                                  readonly: !isAdmin,
                                },
                                street: {
                                  readonly: !isAdmin,
                                },
                                region: {
                                  readonly: !isAdmin,
                                },
                                city: {
                                  readonly: !isAdmin,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </AddressesWrapper>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle2">Реквизиты</Typography>
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.inn}
                    label="ИНН"
                    name="inn"
                    onChange={companyForm.handleChange}
                    placeholder="1234567890"
                    readOnly={!isAdmin}
                    value={companyForm.values.inn}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.kpp}
                    label="КПП"
                    name="kpp"
                    onChange={companyForm.handleChange}
                    readOnly={!isAdmin}
                    value={companyForm.values.kpp}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.bank}
                    label="Название банка"
                    name="bank"
                    onChange={companyForm.handleChange}
                    readOnly={!isAdmin}
                    value={companyForm.values.bank}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.bik}
                    label="БИК банка"
                    name="bik"
                    onChange={companyForm.handleChange}
                    readOnly={!isAdmin}
                    value={companyForm.values.bik}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.correspondent_account}
                    label="Корр. счет"
                    name="correspondent_account"
                    onChange={companyForm.handleChange}
                    readOnly={!isAdmin}
                    value={companyForm.values.correspondent_account}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.ogrn}
                    label="ОГРН"
                    name="ogrn"
                    onChange={companyForm.handleChange}
                    readOnly={!isAdmin}
                    value={companyForm.values.ogrn}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputField
                    error={!!companyForm.errors.payment_account}
                    label="Расчетный счет"
                    name="payment_account"
                    onChange={companyForm.handleChange}
                    readOnly={!isAdmin}
                    value={companyForm.values.payment_account}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </GeneralCompanyInfoContent>
      </GeneralCompanyInfoWrapper>
    </GeneralCompanyInfoForm>
  );
}

export default memo(observer(GeneralCompanyInfo));
