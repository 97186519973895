import React, { ChangeEvent, Fragment, memo, RefObject, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import passportExamplePage1 from '@/assets/passport-example-page-1.png';
import passportExamplePage2 from '@/assets/passport-example-page-2.png';
import { FileField } from '@/ui-kit/components';
import { convertToImageBase64, generateBase64FromBlob } from '@/utils';

import { PassportKeysType } from '../SelfCheckIn.types';

import {
  DocImagesControls,
  DocImagesDelete,
  DocImagesImage,
  DocImagesImageWrapper,
  DocImagesRoot,
} from './DocImages.style';

interface DocImagesProps extends Record<PassportKeysType, Nullable<string>> {
  onUpload: (key: PassportKeysType, value: Nullable<string>) => void;
}

function DocImages({ passportPage1, passportPage2, onUpload }: DocImagesProps) {
  const passportPage1Ref: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  async function onAttachPassportPage({ currentTarget }: ChangeEvent<HTMLInputElement>) {
    if (currentTarget.value) {
      const imageBase64String = await generateBase64FromBlob(currentTarget.value as unknown as Blob);

      onUpload(currentTarget.name as PassportKeysType, imageBase64String);
    }
  }

  function onDeletePassport1() {
    onUpload('passportPage1', null);
  }

  function onDeletePassport2() {
    onUpload('passportPage2', null);
  }

  return (
    <DocImagesRoot>
      <Typography gutterBottom variant="h4">
        Шаг 2: изображение документа
      </Typography>

      <Typography gutterBottom variant="h5">
        Загрузите изображения документов, как показано в примерах ниже
      </Typography>

      <Typography gutterBottom>
        Разворот паспорта должен быть виден полностью (края документа не должны быть обрезаны) Личные данные, серия и
        номер паспорта должны хорошо читаться <br /> Убедитесь, что на фото нет бликов или затемненных участков
      </Typography>

      <DocImagesControls>
        <DocImagesImage>
          <Typography gutterBottom variant="body2">
            ПРИМЕР
          </Typography>

          <DocImagesImageWrapper>
            <img alt="passport page 1 example" src={passportExamplePage1} width="100%" />
          </DocImagesImageWrapper>
        </DocImagesImage>

        <DocImagesImage isHoverable>
          <Typography gutterBottom variant="body2">
            1 И 2 СТРАНИЦЫ ПАСПОРТА
          </Typography>

          <DocImagesImageWrapper>
            {passportPage1 && (
              <Fragment>
                <img alt="passport page 1" src={convertToImageBase64(passportPage1)} width="100%" />

                <DocImagesDelete>
                  <IconButton color="error" onClick={onDeletePassport1}>
                    <DeleteIcon />
                  </IconButton>
                </DocImagesDelete>
              </Fragment>
            )}

            {!passportPage1 && (
              <FileField
                accept="image/png, image/jpeg, image/jpg"
                hideList
                label="Загрузить фото"
                name="passportPage1"
                onChange={onAttachPassportPage}
                ref={passportPage1Ref}
                size="small"
              />
            )}
          </DocImagesImageWrapper>
        </DocImagesImage>

        <DocImagesImage>
          <Typography gutterBottom variant="body2">
            ПРИМЕР
          </Typography>

          <DocImagesImageWrapper>
            <img alt="passport page 2" src={passportExamplePage2} width="100%" />
          </DocImagesImageWrapper>
        </DocImagesImage>

        <DocImagesImage isHoverable>
          <Typography gutterBottom variant="body2">
            СТРАНИЦА С ПРОПИСКОЙ
          </Typography>

          <DocImagesImageWrapper>
            {passportPage2 && (
              <Fragment>
                <img alt="passport page 2" src={convertToImageBase64(passportPage2)} width="100%" />

                <DocImagesDelete>
                  <IconButton color="error" onClick={onDeletePassport2}>
                    <DeleteIcon />
                  </IconButton>
                </DocImagesDelete>
              </Fragment>
            )}

            {!passportPage2 && (
              <FileField
                accept="image/png, image/jpeg, image/jpg"
                hideList
                label="Загрузить фото"
                name="passportPage2"
                onChange={onAttachPassportPage}
                ref={passportPage1Ref}
                size="small"
              />
            )}
          </DocImagesImageWrapper>
        </DocImagesImage>
      </DocImagesControls>

      <Typography align="center" gutterBottom>
        Допустимый формат файла: jpg, jpeg, png. Максимальный размер: 10 Мб
      </Typography>
    </DocImagesRoot>
  );
}

export default memo(DocImages);
