import { isArray } from './isArray';
import { isMap } from './isMap';
import { isObject } from './isObject';
import { isSet } from './isSet';
import { isString } from './isString';

export function isEmpty(value: unknown) {
  if (isString(value)) {
    return (value as string).length === 0;
  }

  if (isArray(value)) {
    return (value as never[]).length === 0;
  }

  if (isMap(value) || isSet(value)) {
    return (value as Map<never, never>).size === 0;
  }

  if (isObject(value)) {
    return Object.keys(value as never).length === 0;
  }

  return !value;
}
