import React, { memo, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import ExpandIcon from '@mui/icons-material/ExpandLess';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { queryAPI } from '@/api/queryAPI';
import { storageAPI } from '@/api/storageAPI';
import { ROUTE_REGISTRATIONS, ROUTE_REMOVED } from '@/constants';
import { GROUPS_EXTENDED_KEY } from '@/constants/storage';
import { Group } from '@/entities/group';
import { useMount } from '@/hooks/useMount/useMount';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import GroupItem from './GroupItem/GroupItem';
import { GroupExpander, GroupsHeader, GroupsList, GroupsListContent, GroupsRoot, ReplaceButton } from './Groups.style';
import ReplaceGroupModal from './ReplaceGroupModal/ReplaceGroupModal';

interface GroupsProps {
  mode: 'general' | 'deleted';
}

function Groups({ mode }: GroupsProps) {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const { search } = useLocation();
  const { isFetching, groups, getGroups } = useStore('groupsStore');
  const { selectedPersons } = useStore('personsStore');

  const [expanded, , , toggleExpanded] = useToggle(storageAPI.get(GROUPS_EXTENDED_KEY) !== '1');
  const [isReplaceOpen, , , toggleReplaceModal] = useToggle();

  useMount(() => getGroups(mode === 'deleted'));

  const onSelectGroupHandler = useCallback(
    (group: Group) => {
      let path = mode === 'general' ? ROUTE_REGISTRATIONS : ROUTE_REMOVED;

      if (groupId !== `${group.id}`) {
        path += `/${group.id}`;
      }

      const query = queryAPI.params;

      if (query?.count) {
        path += `?count=${query.count}`;
      }

      navigate(path);
    },
    [mode, groupId, search],
  );

  const onExpandHandler = useCallback(() => {
    storageAPI.set(GROUPS_EXTENDED_KEY, expanded ? '1' : '0');

    toggleExpanded();
  }, [expanded]);

  return (
    <GroupsRoot isExpanded={expanded}>
      <BackdropLoader isLoading={isFetching} />

      <GroupExpander isExpanded={expanded}>
        <IconButton onClick={onExpandHandler} size="small">
          <ExpandIcon fontSize="small" />
        </IconButton>
      </GroupExpander>

      {expanded && (
        <GroupsHeader>
          <Typography variant="subtitle2">Группы</Typography>

          {mode === 'general' && (
            <ReplaceButton disabled={selectedPersons.size === 0} onClick={toggleReplaceModal} type="button">
              Переместить в группу
            </ReplaceButton>
          )}
        </GroupsHeader>
      )}

      <GroupsList>
        {!isFetching && groups.length > 0 && (
          <GroupsListContent>
            {groups.map((group) => (
              <GroupItem
                editable={mode === 'general'}
                group={group}
                key={group.id}
                onSelect={onSelectGroupHandler}
                restorable={mode === 'deleted'}
              />
            ))}
          </GroupsListContent>
        )}
      </GroupsList>

      <ReplaceGroupModal onClose={toggleReplaceModal} open={isReplaceOpen} />
    </GroupsRoot>
  );
}

export default memo(observer(Groups));
