import React, { memo } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';

import { notificationAPI } from '@/api/notificationAPI';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, InputField } from '@/ui-kit/components';

interface AddLicenseModalProps extends ContentModalProps {
  companyId: Nullable<number>;
}

interface AddLicenseForm {
  number: string;
  code: string;
}

function AddLicenseModal(props: AddLicenseModalProps) {
  const { companyId, open, onClose } = props;
  const { isSaving, getLicenses, addLicense } = useStore('deviceLicensesStore');

  const licenseForm = useFormik({
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: true,
    initialValues: {
      number: '',
      code: '',
    },
    onSubmit: async ({ number, code }: AddLicenseForm, { resetForm }: FormikHelpers<AddLicenseForm>) => {
      try {
        if (companyId) {
          await addLicense(companyId, number, code);

          resetForm();

          notificationAPI.success('Серийный номер добавлен');

          await getLicenses(companyId);
        }
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    onReset: () => onClose(),
    validate: ({ number }: AddLicenseForm) => {
      const errors: Partial<AddLicenseForm> = {};

      if (number.length === 0) {
        errors.number = 'Обязательно к заполнению';
      }

      return errors;
    },
  });

  return (
    <ContentModal disableEscapeKeyDown={isSaving} onClose={licenseForm.handleReset} open={open}>
      <form onReset={licenseForm.handleReset} onSubmit={licenseForm.handleSubmit}>
        <ContentModal.Header onClose={licenseForm.handleReset}>Добавить серийный номер</ContentModal.Header>

        <ContentModal.Body>
          <InputField
            autoFocus
            disabled={isSaving}
            error={!!licenseForm.errors.number}
            helperText={licenseForm.errors.number}
            label="Серийный номер"
            name="number"
            onChange={licenseForm.handleChange}
            value={licenseForm.values.number}
          />

          <InputField
            disabled={isSaving}
            error={!!licenseForm.errors.code}
            helperText={licenseForm.errors.code}
            label="Код"
            name="code"
            onChange={licenseForm.handleChange}
            value={licenseForm.values.code}
          />
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" type="reset">
            Отмена
          </Button>

          <ButtonLoader disabled={!(licenseForm.dirty && licenseForm.isValid)} isLoading={isSaving} type="submit">
            Добавить
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(AddLicenseModal));
