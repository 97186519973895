import { format, startOfMonth } from 'date-fns';

import { DATE_FOR_SERVER_FORMAT, DEFAULT_PAGINATION_LIMIT } from '@/constants';

export enum StatisticTimeline {
  Hours = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum KSStatusValue {
  None,
  Unknown,
  NotPaid,
  Paid,
  UnderAge,
  ShortStay,
  Decline,
}

export enum KSVisibleValue {
  All,
  Exported,
  NotExported,
}

export const DEFAULT_KS_LIST_FILTER = {
  timeline: format(startOfMonth(new Date()), DATE_FOR_SERVER_FORMAT),
  not_equal: false,
  status: KSStatusValue.None,
  ais_sync: KSVisibleValue.All,
  limit: DEFAULT_PAGINATION_LIMIT,
  page: 1,
  name: '',
};
