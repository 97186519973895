import React, { memo } from 'react';
import { observer } from 'mobx-react-lite';

import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';

import OperatorPanelTabs from '../OperatorPanelTabs/OperatorPanelTabs';

import KSListFilter from './KSListFilter/KSListFilter';
import KSListTable from './KSListTable/KSListTable';

function KSList() {
  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Курортный сбор']} />}
      content={<KSListTable />}
      contentSettings={{
        overflow: 'hidden',
      }}
      header={<OperatorPanelTabs />}
      subHeader={<KSListFilter />}
    />
  );
}

export default memo(observer(KSList));
