import React, { createRef, memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

import { notificationAPI } from '@/api/notificationAPI';
import { DocumentType } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { usePersonPageStore } from '@/routes/Main/OperatorPanel/PersonPage/PersonPageStore';
import { DEFAULT_ID_CARD, DEFAULT_MIGRATION_CARD, DEFAULT_RIGHT_OF_STAY, PersonDocument } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, ContentModal, ContentModalProps, SelectField } from '@/ui-kit/components';
import { convertToClearBase64, getDocumentByType, pureStructure } from '@/utils';

import AttachUnrecognizedDocModal from '../../PersonForm/AttachUnrecognizedDocModal/AttachUnrecognizedDocModal';

import { QUALITY_OPTIONS, ScaleFactor, VIDEO_WRAPPER_SIZE_VALUE } from './UseCameraModal.constants';
import {
  CameraPane,
  CameraSelector,
  CameraToolbar,
  CameraVideoCanvas,
  CameraVideoContainer,
  CameraVideoWrapper,
  CameraZoom,
} from './UseCameraModal.style';

function UseCameraModal(props: ContentModalProps) {
  const { open, onClose } = props;
  const { documents, setActiveTab, setPersonValues } = usePersonPageStore();
  const { cameras } = useStore('devicesStore');

  const [isAttachModelOpen, openAttachModal, closeAttachModal] = useToggle();

  const [image, setImage] = useState<Nullable<string>>(null);

  const [selectedCamera, setSelectedCamera] = useState<MediaDeviceInfo>(cameras[0]);
  const [mediaStream, setMediaStream] = useState<Nullable<MediaStream>>(null);
  const [cameraSettings, setCameraSettings] = useState({
    videoMaxWidth: 1280,
    videoMaxHeight: 720,
    cameraAspectRatio: 1.77,
  });

  const [quality, setQuality] = useState(QUALITY_OPTIONS[0].value);
  const [scale, setScale] = useState(ScaleFactor.X1);

  const refVideo = createRef<HTMLVideoElement>();

  function onClickGetImage() {
    if (refVideo.current) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      /*
       * Ширину изображения высчитываем исходя из `<высота_контейнера> * cameraAspectRatio`,
       * чтобы синхронизировать размеры с элементом `video`
       * */
      const originImageWidth = refVideo.current.height * cameraSettings.cameraAspectRatio;
      const originImageHeight = refVideo.current.height;

      canvas.width = originImageWidth;
      canvas.height = originImageHeight;

      if (ctx) {
        const xOffset = (originImageWidth * scale - originImageWidth) / 2;
        const yOffset = (originImageHeight * scale - originImageHeight) / 2;

        ctx.drawImage(
          refVideo.current,
          /*
           * Сначала проставляем данные для холста
           * */
          0, // смещение по x
          0, // смещение по y
          originImageWidth, // ширина холста
          originImageHeight, // высота холста
          /*
           * Затем начинаем рисовать по холсту изображение с камеры
           *
           * Логика в том, что мы растягиваем картинку с камеры по значению scale,
           * чтобы картинка после растягивания встала по центру, нужно сместить ее с отрицательными значениями по осям x и y
           * */
          -xOffset, // смещение по x
          -yOffset, // смещение по y
          originImageWidth * scale, // ширина картинки
          originImageHeight * scale, // высота картинки
        );

        setImage(convertToClearBase64(canvas.toDataURL('image/jpeg', quality)));

        setTimeout(() => {
          openAttachModal();
        }, 0);
      }
    }
  }

  function onAttachImage(type: DocumentType) {
    if (!image) {
      return;
    }

    const nextDocuments = pureStructure(documents);

    const imageObject = {
      data: image,
      not_export: true,
      image_upload_type: 4,
    };

    const [, index] = getDocumentByType(nextDocuments, type);

    // если выбрали тип документа, которого еще нет
    // нужно создать пустой документ и положить изображение туда
    if (index === -1) {
      let mergedDocument: Partial<PersonDocument> = {};

      if (type === DocumentType.IdCard) {
        mergedDocument = pureStructure(DEFAULT_ID_CARD);
      }

      if (type === DocumentType.RightOfStay) {
        mergedDocument = pureStructure(DEFAULT_RIGHT_OF_STAY);
      }

      if (type === DocumentType.Migration) {
        mergedDocument = pureStructure(DEFAULT_MIGRATION_CARD);
      }

      mergedDocument.images = [imageObject];

      nextDocuments.push(mergedDocument);
    } else {
      nextDocuments[index].images.push(imageObject);
    }

    setPersonValues({
      documents: pureStructure(nextDocuments),
    });

    setActiveTab(type);

    closeAttachModal();
    onClose();
  }

  useEffect(() => {
    /*
     * Устанавливаем значение `srcObject` на `video`, потому что по умолчанию такого свойства в реакте нет,
     * решение взято отсюда: https://github.com/facebook/react/issues/11163#issuecomment-628379291
     *
     * Так же вызываем функцию с таймаутом в 0ms, потому что иначе когда `mediaStream` обновится -
     * `refVideo.current` все еще будет null
     * */
    setTimeout(() => {
      if (refVideo.current) {
        refVideo.current.srcObject = mediaStream;
      }
    }, 0);
  }, [mediaStream?.id]);

  function onSelectCamera({ target }: SelectChangeEvent<unknown>) {
    const camera = cameras.find(({ deviceId }) => deviceId === target.value);

    if (camera) {
      setSelectedCamera(camera);
    }
  }

  function onCloseHandler() {
    /*
     * При закрытии окна сбрасываем камеру до выбранной по умолчанию,
     * нужно на случай, если пользователть поменял камеру и закрыл окно
     * */
    setSelectedCamera(cameras[0]);

    onClose();
  }

  function onSetX1Scale() {
    setScale(ScaleFactor.X1);
  }

  function onSetX2Scale() {
    setScale(ScaleFactor.X2);
  }

  function onSetX3Scale() {
    setScale(ScaleFactor.X3);
  }

  async function initCamera() {
    try {
      /*
       * `cameraCapabilities` будет содержать в себе все данные по возможным настройкам выбранной камеры,
       * нас интересует максимальная ширина и высота, их мы проставим объекту `video`,
       * тогда `refVideo` будет сформирован с максимальными высотой и шириной для картинки
       * */
      const cameraCapabilities = (selectedCamera as unknown as MediaStreamTrack)?.getCapabilities?.();

      if (!cameraCapabilities) {
        return;
      }

      /*
       * по умолчанию устанавливаем максимально доступные значения высоты/ширины, чтобы получить наилучшее качество картинки
       * */
      const videoMaxWidth = cameraCapabilities?.width?.max ?? cameraSettings.videoMaxWidth;
      const videoMaxHeight = cameraCapabilities.height?.max ?? cameraSettings.videoMaxHeight;

      /*
       * `cameraAspectRatio` используется чтобы правильно сформировать соотношение ширины и высоты изображения,
       * потому что у камер могут быть разные соотношения сторон и мы используем фиксированную высоту контейнера, где храним видео
       * а значит ширина должна быть рассчитана как `<высота_контейнера> * cameraAspectRatio`
       * */
      const cameraAspectRatio =
        videoMaxWidth && videoMaxHeight ? videoMaxWidth / videoMaxHeight : cameraSettings.cameraAspectRatio;

      setCameraSettings({
        videoMaxWidth,
        videoMaxHeight,
        cameraAspectRatio,
      });

      const updatedConstraints: MediaStreamConstraints = {
        video: {
          deviceId: selectedCamera.deviceId,
          width: {
            exact: videoMaxWidth,
          },
          height: {
            exact: videoMaxHeight,
          },
        },
      };

      mediaStream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());

      const stream = await navigator.mediaDevices.getUserMedia(updatedConstraints);

      setMediaStream(stream);
    } catch (e) {
      console.error({ e });

      if (e.name === 'NotAllowedError') {
        notificationAPI.error('Доступ к камере запрещен на устройстве! Проверьте настройки доступа в браузере!');

        return;
      }

      if ((e as CloseEventInit).code === 0) {
        notificationAPI.error(
          'Невозможно получить картинку с камеры. Убедитесь, что камера не используется в другом приложении!',
        );
      } else {
        notificationAPI.error('Ошибка при подключении камеры!');
      }
    }
  }

  useEffect(() => {
    if (open) {
      setMediaStream(null);
      setScale(ScaleFactor.X1);

      initCamera();
    } else {
      mediaStream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    }
  }, [open, selectedCamera]);

  useEffect(() => {
    setSelectedCamera(cameras[0]);
  }, [JSON.stringify(cameras)]);

  return (
    <ContentModal fullWidth maxWidth="lg" onClose={onCloseHandler} open={open}>
      <ContentModal.Header onClose={onCloseHandler}>Получить изображение с камеры</ContentModal.Header>

      <ContentModal.Body>
        {cameras.length > 1 && (
          <CameraSelector>
            <SelectField onChange={onSelectCamera} value={selectedCamera.deviceId}>
              {cameras.map(({ deviceId, label }: MediaDeviceInfo) => (
                <MenuItem key={deviceId} value={deviceId}>
                  {label}
                </MenuItem>
              ))}
            </SelectField>
          </CameraSelector>
        )}

        <CameraPane>
          <CameraVideoWrapper
            style={{
              width: VIDEO_WRAPPER_SIZE_VALUE * cameraSettings.cameraAspectRatio,
              height: VIDEO_WRAPPER_SIZE_VALUE,
            }}
          >
            <BackdropLoader isLoading />

            <CameraVideoContainer
              style={{
                transform: `scale(${scale})`,
              }}
            >
              <CameraVideoCanvas
                /*
                 * Проставляем максимальные высоту и ширину объекту `video`, это нужно чтобы правильно рассчитать `ref`,
                 * без этого мы не сможем правильно получить картинку
                 *
                 * Однако фактически высоты объекта `video` будет рассчитана исходя из значения VIDEO_WRAPPER_SIZE_VALUE
                 * */
                autoPlay
                height={cameraSettings.videoMaxHeight ?? undefined}
                ref={refVideo}
                width={cameraSettings.videoMaxWidth ?? undefined}
              />
            </CameraVideoContainer>
          </CameraVideoWrapper>

          {mediaStream && (
            <CameraToolbar>
              <SelectField label="Качество" onChange={(e) => setQuality(+e.target.value)} value={quality}>
                {QUALITY_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </SelectField>

              <CameraZoom orientation="vertical">
                <Button
                  onClick={onSetX1Scale}
                  type="button"
                  variant={scale === ScaleFactor.X1 ? 'contained' : 'outlined'}
                >
                  x1
                </Button>

                <Button
                  onClick={onSetX2Scale}
                  type="button"
                  variant={scale === ScaleFactor.X2 ? 'contained' : 'outlined'}
                >
                  x2
                </Button>

                <Button
                  onClick={onSetX3Scale}
                  type="button"
                  variant={scale === ScaleFactor.X3 ? 'contained' : 'outlined'}
                >
                  x3
                </Button>
              </CameraZoom>
            </CameraToolbar>
          )}
        </CameraPane>
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" disabled={!mediaStream} onClick={onClickGetImage} variant="contained">
          Получить изображение
        </Button>

        <Button color="primary" onClick={onCloseHandler}>
          Отмена
        </Button>
      </ContentModal.Footer>

      <AttachUnrecognizedDocModal
        onAttach={onAttachImage}
        onDecline={closeAttachModal}
        open={isAttachModelOpen}
        title="Выберите категорию"
      />
    </ContentModal>
  );
}

export default memo(observer(UseCameraModal));
