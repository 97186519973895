import React, { memo } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { CitizenshipActions, CitizenshipButton, CitizenshipRoot } from './Citizenship.style';

interface CitizenshipProps {
  onSelectCitizenship: (nextCitizenship: unknown) => void;
}

function Citizenship(props: CitizenshipProps) {
  const { onSelectCitizenship } = props;

  return (
    <CitizenshipRoot>
      <div>
        <Typography gutterBottom variant="h6">
          Шаг 1: гражданство
        </Typography>

        <Typography variant="subtitle2">Выберите гражданство</Typography>
      </div>

      <CitizenshipActions>
        <CitizenshipButton>
          <Button fullWidth onClick={onSelectCitizenship} size="large" variant="outlined">
            Россия
          </Button>
        </CitizenshipButton>

        <CitizenshipButton>
          <Button fullWidth size="small" variant="outlined">
            Другие страны
          </Button>
        </CitizenshipButton>
      </CitizenshipActions>
    </CitizenshipRoot>
  );
}

export default memo(Citizenship);
