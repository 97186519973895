import { differenceInCalendarDays, isFuture, isPast } from 'date-fns';

import { Citizen, DocumentTypeEnumId } from '@/constants';
import { DocType } from '@/entities/dictionary';
import { PersonDocument } from '@/stores/PersonsStore';

export function filterDocumentsByCitizenship(docs: DocType[], citizenshipId: number): DocType[] {
  const russianIDsMap = new Map([
    [DocumentTypeEnumId.BirthCertificate, true],
    [DocumentTypeEnumId.RusForeignPassport, true],
    [DocumentTypeEnumId.RusPassport, true],
    [DocumentTypeEnumId.BirthCertificateOutsideRus, true],
    [DocumentTypeEnumId.RusForeignPassportBio, true],
    [DocumentTypeEnumId.RusForeignPassportOutsideRus, true],
    [DocumentTypeEnumId.TempCertificate, true],
    [DocumentTypeEnumId.RusTempPassport, true],
  ]);

  const ussrIDsMap = new Map([[DocumentTypeEnumId.USSRPassport, true]]);

  const noCitizenshipIDsMap = new Map([
    [DocumentTypeEnumId.NonCitizenCertificate, true],
    [DocumentTypeEnumId.ResidentCardNoCitizenID, true],
    [DocumentTypeEnumId.ResidentCardNoCitizenBio, true],
    [DocumentTypeEnumId.TempResidentPermitId, true],
  ]);

  const notLatviaCitizenIDsMap = new Map([[DocumentTypeEnumId.NonCitizenPassport, true]]);

  if (citizenshipId === Citizen.Russia) {
    return docs.filter(({ id }) => russianIDsMap.has(id));
  }

  if (citizenshipId === Citizen.USSR) {
    return docs.filter(({ id }) => ussrIDsMap.has(id));
  }

  if (citizenshipId === Citizen.NonCitizen) {
    return docs.filter(({ id }) => noCitizenshipIDsMap.has(id));
  }

  if (citizenshipId === Citizen.NonLatvia || citizenshipId === Citizen.NonEstonia) {
    return docs.filter(({ id }) => notLatviaCitizenIDsMap.has(id));
  }

  return docs.filter(
    ({ id }) =>
      !russianIDsMap.has(id) && !ussrIDsMap.has(id) && !noCitizenshipIDsMap.has(id) && !notLatviaCitizenIDsMap.has(id),
  );
}

export function checkIdCardData(values: PersonDocument): Record<string, string> {
  const newErrors: Record<string, string> = {};

  // свидетельство о рождении
  if (values.document_type_enum_id === 1) {
    if (values.doc_number && (isNaN(+values.doc_number) || `${values.doc_number}`.length !== 6)) {
      newErrors.doc_number = 'Длина поля должна быть равна 6 символам и содержать только цифры';
    }
  }

  // паспорт гражданина рф
  // свидетельство о рождении
  if (values.document_type_enum_id === 4) {
    if (values.doc_serial && `${values.doc_serial}`.length > 5) {
      newErrors.doc_serial = 'Длина поля должна быть не более 5 символов';
    }

    if (values.doc_number && (isNaN(+values.doc_number) || `${values.doc_number}`.length !== 6)) {
      newErrors.doc_number = 'Длина поля должна быть равна 6 символам и содержать только цифры';
    }

    if (values.doc_issue_date) {
      if (isFuture(new Date(values.doc_issue_date))) {
        newErrors.doc_issue_date = 'Дата не может быть позднее текущей даты';
      }
    }
  }

  // загран паспорт
  // загран паспорт с биометрией
  // загран паспорт выданный за пределами РФ
  if (values.document_type_enum_id === 3 || values.document_type_enum_id === 7 || values.document_type_enum_id === 8) {
    // временно отключили, понадобится позже
    // if (values.doc_serial && (isNaN(+values.doc_serial) || `${values.doc_serial}`.length !== 2)) {
    //   newErrors.doc_serial = 'Длина поля должна быть равна 2 символам и содержать только цифры';
    // }

    if (values.doc_number && (isNaN(+values.doc_number) || `${values.doc_number}`.length !== 7)) {
      newErrors.doc_number = 'Длина поля должна быть равна 7 символам и содержать только цифры';
    }

    if (values.doc_issue_date) {
      if (isFuture(new Date(values.doc_issue_date))) {
        newErrors.doc_issue_date = 'Дата выдачи не может быть позднее текущей даты';
      }

      if (values.doc_expire_date) {
        if (isPast(new Date(values.doc_expire_date))) {
          newErrors.doc_expire_date = 'Срок действия документа истек';
        }

        if (differenceInCalendarDays(new Date(values.doc_issue_date), new Date(values.doc_expire_date)) > 0) {
          newErrors.doc_expire_date = 'Срок действия не может быть ранее даты выдачи';
        }
      }
    }
  }

  // иностранный паспорт
  // дипломатический паспорт ИГ
  // иностранное свидетельство о рождении
  // национальный заграничный паспорт
  // служебный паспорт
  // справка о рассмотрении заявления о предоставлении временного убежища на территории РФ
  // удостоверение личности гражданина государств-участников СНГ
  // удостоверение личности гражданина Республики Казахстан
  if (
    values.document_type_enum_id === 20 ||
    values.document_type_enum_id === 27 ||
    values.document_type_enum_id === 5 ||
    values.document_type_enum_id === 18 ||
    values.document_type_enum_id === 19 ||
    values.document_type_enum_id === 9 ||
    values.document_type_enum_id === 15 ||
    values.document_type_enum_id === 30
  ) {
    if (values.doc_issue_date) {
      if (isFuture(new Date(values.doc_issue_date))) {
        newErrors.doc_issue_date = 'Дата не может быть позднее текущей даты';
      }
    }
  }

  // свидетельство о предоставлении временного убежища на территории РФ
  if (values.document_type_enum_id === 12) {
    if (values.doc_serial && values.doc_serial !== 'ВУ') {
      newErrors.doc_serial = 'Значение поля должно быть равным ВУ';
    }

    if (values.doc_number && (isNaN(+values.doc_number) || `${values.doc_number}`.length !== 7)) {
      newErrors.doc_number = 'Длина поля должна быть равна 7 символам и содержать только цифры';
    }
  }

  return newErrors;
}
