import blue from '@mui/material/colors/blue';
import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import indigo from '@mui/material/colors/indigo';
import { css, styled } from '@mui/material/styles';

export const GroupsRoot = styled('aside', {
  shouldForwardProp: (name) => name !== 'isExpanded',
})(
  ({ isExpanded }: { isExpanded?: boolean }) => css`
    position: relative;
    height: 100%;
    flex-shrink: 0;
    width: 35%;
    max-width: ${isExpanded ? '300px' : '14px'};
    margin-right: 14px;
    padding-right: 14px;
    border-right: 1px solid ${grey['500']};
    display: flex;
    flex-direction: column;
  `,
);

export const GroupExpander = styled('div', {
  shouldForwardProp: (name) => name !== 'isExpanded',
})(
  ({ isExpanded }: { isExpanded?: boolean }) => css`
  position: absolute;
  z-index: 1001;
  top: 14px;
  right: -14px;
  background-color: ${common.white};
  border: 1px solid ${indigo['300']};
  border-radius: 100%;
  transform: rotate(${isExpanded ? -90 : 90}deg);

  &:hover {
    border-color: ${indigo['500']},
  },
`,
);

export const GroupsHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const GroupsList = styled('div')`
  flex: 1;
  overflow: hidden;
`;

export const GroupsListContent = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const ReplaceButton = styled('button')`
  padding: 0;
  background-color: transparent;
  font-size: 12px;
  border: 0;
  margin-right: 12px;
  color: ${blue['500']};
  cursor: pointer;

  &:disabled {
    color: ${grey['500']};
    cursor: not-allowed;
  }
`;
