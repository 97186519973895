export enum DocumentType {
  IdCard = 1,
  RightOfStay = 2,
  Migration = 3,
}

export enum DocumentTypeEnumId {
  BirthCertificate = 1, // 1 - Свидетельство о рождении
  USSRPassport = 2, // 2 - Паспорт гражданина СССР
  RusForeignPassport = 3, // 3 - Заграничный паспорт гражданина РФ
  RusPassport = 4, // 4 - Паспорт гражданина Российской Федерации
  ForeignBirthCertificate = 5, // 5 - Иностранное свидетельство о рождении
  BirthCertificateOutsideRus = 6, // 6 - Свидетельство о рождении, выданное за пределами РФ
  RusForeignPassportBio = 7, // 7 - Загранпаспорт нового поколения (с биометрией)
  RusForeignPassportOutsideRus = 8, // 8 - Загранпаспорт, выданный за пределами Российской Федерации
  RusShelterRef = 9, // 9 - Справка о рассмотрении заявления о предоставлении временного убежища на территории РФ
  PrisonReleaseRef = 10, // 10 - Справка об освобождении из места лишения свободы
  NonCitizenPassport = 11, // 11 - Паспорт негражданина
  TempShelterInRus = 12, // 12 - Свидетельство о предоставлении временного убежища на территории Российской Федерации
  CertificateOfReturn = 13, // 13 - Свидетельство о возвращении
  NonCitizenCertificate = 14, // 14 - Удостоверение лица без гражданства
  SNGCertificate = 15, // 15 - Удостоверение личности гражданина государств-участников СНГ
  TempCertificate = 17, // 17 - Временное удостоверение личности
  NationalForeignPassport = 18, // 18 - Национальный заграничный паспорт
  ServicePassport = 19, // 19 - Служебный паспорт
  ForeignPassport = 20, // 20 - Иностранный паспорт
  ResidentCardID = 21, // 21 - Вид на жительство ИГ, удостоверение личности
  ResidentCardNoCitizenID = 22, // 22 - Вид на жительство ЛБГ, удостоверение личности
  ResidentCardNoCitizenBio = 23, // 23 - Вид на жительство ЛБГ (с биометрией), удостоверение личности
  CertificateOfMigrant = 24, // 24 - Удостоверение вынужденного переселенца
  CertificateOfMigrantRequest = 25, // 25 - Свидетельство о рассмотрении ходатайства вынужденного переселенца
  CertificateOfMigrantRequestForced = 26, // 26 - Свидетельство о рассмотрении ходатайства о признании беженцем по существу
  DiplomatPassport = 27, // 27 - Дипломатический паспорт иностранного гражданина
  CertificateOfIdentification = 28, // 28 - Справка об установлении личности
  ChildTravelDocument = 29, // 29 - Проездной документ ребенка
  KazakhstanCitizen = 30, // 30 - Удостоверение личности гражданина Республики Казахстан
  ResidentCardRightOfStay = 31, // 31 - Вид на жительство ИГ, право пребывания
  ResidentCardNoCitizenRightOfStay = 32, // 32 - Вид на жительство ЛБГ, право пребывания
  Visa = 33, // 33 - Виза
  TempPermissionRightOfStay = 34, // 34 - Разрешение на временное проживание ИГ
  TempPermissionNoCitizen = 35, // 35 - Разрешение на временное проживание ЛБГ
  MigrationCard = 36, // 36 - Миграционная карта
  TempResidentPermitId = 37, // 37 - Разрешение на временное проживание ЛБГ
  Other = 38, // 38 - Иной документ, удостоверяющий личность
  RusTempPassport = 39, // 39 - Временное удостоверение личности гражданина Российской Федерации
  DigitalVisa = 43, // 43 - Единая электронная виза
  ResidentCardEducation = 44, // 44 - Разрешение на временное проживание в целях получения образования
}

export enum Citizen {
  Empty = 0,
  Russia = 171,
  ExceptRussia = -171,
  USSR = 252,
  NonCitizen = 253,
  NonLatvia = 254,
  NonEstonia = 255,
}

export const DEFAULT_COUNTRY = {
  id: Citizen.Russia,
  name_ru: 'РОССИЯ',
  name_en: '',
  code: 'RUS',
};

export const DEFAULT_EMPTY_CITIZENSHIP = {
  id: Citizen.Empty,
  name_ru: 'Гражданство не определено',
  name_en: '',
  code: '',
};

export const DEFAULT_NOT_RUSSIAN_CITIZENSHIP = {
  id: Citizen.ExceptRussia,
  name_ru: 'Кроме России',
  name_en: '',
};
