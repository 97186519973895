import { makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import { Role, RoleModel } from '@/entities/role';

import { NewRoleModel } from './RolesStore.types';

export class RolesStore {
  roles: Role[] = [];
  isFetching = false;
  isSaving = false;

  constructor() {
    makeAutoObservable(this);
  }

  getRoles = () => {
    this.isFetching = true;

    return fetchApi
      .get<RoleModel[]>('/user-roles/')
      .then((response) => {
        response.sort((a, b) => {
          if (!a.id || !b.id) {
            return 0;
          }

          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;

          return 0;
        });

        runInAction(() => {
          this.roles = response.map((role) => new Role(role));
        });
      })
      .finally(() => {
        runInAction(() => {
          this.isFetching = false;
        });
      });
  };

  createRole = (body: NewRoleModel) => {
    this.isSaving = true;

    return fetchApi.post('/user-roles/', { body }).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };

  updateRole = (body: NewRoleModel, roleId: number) => {
    this.isSaving = true;

    return fetchApi.put(`/user-roles/${roleId}`, { body }).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };

  deleteRole = (roleId: number) => {
    this.isSaving = true;

    return fetchApi.remove(`/user-roles/${roleId}/`).finally(() => {
      runInAction(() => {
        this.isSaving = false;
      });
    });
  };
}
