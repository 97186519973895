import { ExportToXlsxValues } from '@/stores/PersonsStore';

export const DEFAULT_STATISTICS_FORM: ExportToXlsxValues = {
  country_id: null,
  created_from: null,
  created_to: null,
  stay_from: null,
  stay_to: null,
  order: '',
};
