import React, { ChangeEvent, memo } from 'react';
import { InputBaseProps } from '@mui/material/InputBase/InputBase';
import MenuItem from '@mui/material/MenuItem';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';

import { Gender } from '@/stores/PersonsStore';
import { SelectField } from '@/ui-kit/components';

interface GenderSelectProps {
  value: Gender;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  variant?: BaseTextFieldProps['variant'];
  margin?: InputBaseProps['margin'];
}

function GenderSelect(props: GenderSelectProps) {
  const { value, error, onChange, disabled, margin = 'none', variant = 'standard' } = props;

  return (
    <SelectField
      disabled={disabled}
      error={error}
      label="Пол"
      margin={margin}
      name="gender"
      onChange={onChange}
      value={value}
      variant={variant}
    >
      <MenuItem value={Gender.Unknown}>Не указан</MenuItem>

      <MenuItem value={Gender.Male}>Мужской</MenuItem>

      <MenuItem value={Gender.Female}>Женский</MenuItem>
    </SelectField>
  );
}

export default memo(GenderSelect);
