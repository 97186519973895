import { makeAutoObservable, runInAction } from 'mobx';

import { fetchApi } from '@/api/fetchApi';
import { Address, AddressModel, Street, StreetModel } from '@/entities/address';
import {
  DictionaryBasic,
  DictionaryBasicModel,
  DictionaryBasicWithCode,
  DictionaryBasicWithCodeModel,
  DocIssueAuthority,
  DocType,
  DocTypeModel,
  DocVisaVisitType,
  DocVisaVisitTypeModel,
} from '@/entities/dictionary';
import { HMSType, HMSTypeModel } from '@/entities/hmsType';
import { KSReason, KSReasonDoc, KSReasonDocModel, KSReasonModel } from '@/entities/KSReason';

export class DictionariesStore {
  countries: DictionaryBasicWithCode[] = [];
  regions: Address[] = [];
  places: Address[] = [];
  cities: Address[] = [];
  settlements: Address[] = [];
  streets: Street[] = [];
  exportTypes: DictionaryBasicWithCode[] = [];
  pdfFormTypes: DictionaryBasicWithCode[] = [];
  personCategories: DictionaryBasic[] = [];
  docTypes: DocType[] = [];
  docVisaTypes: DictionaryBasic[] = [];
  docVisaMultiTypes: DictionaryBasic[] = [];
  docVisaVisitTypes: DocVisaVisitType[] = [];
  docIssueAuthorityOrgans: DocIssueAuthority[] = [];
  hmsList: HMSType[] = [];
  entryGates: DictionaryBasic[] = [];
  visitGoals: DictionaryBasic[] = [];
  ksReasons: KSReason[] = [];
  ksReasonsDocs: KSReasonDoc[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getHMSList = () =>
    fetchApi.get<HMSTypeModel[]>('/hms/').then((hmsList) => {
      runInAction(() => {
        this.hmsList = hmsList.map((hms) => new HMSType(hms));
      });
    });

  getRegions = (name: string) =>
    fetchApi.get<AddressModel[]>('/address-objects/', { name }).then((regions) => {
      runInAction(() => {
        this.regions = regions.map((region) => new Address(region));
      });

      return regions;
    });

  getStreets = (name: string, region_code: string, parent_id: number) => {
    const query: { [key: string]: StringOrNumber } = {};

    if (name) {
      query.name = name;
    }

    if (region_code) {
      query.region_code = region_code;
    }

    if (parent_id) {
      query.parent_id = parent_id;
    }

    return fetchApi.get<StreetModel[]>('/street-address-objects/', query).then((streets: StreetModel[]) => {
      runInAction(() => {
        this.streets = streets.map((street) => new Street(street));
      });

      return streets;
    });
  };

  resetRegions = () => {
    runInAction(() => {
      this.regions = [];
    });
  };

  resetStreets = () => {
    runInAction(() => {
      this.streets = [];
    });
  };

  getStreetObjectById = (id: string, region_code: string) => {
    const query: Record<string, StringOrNumber> = {};

    if (region_code) {
      query.region_code = region_code;
    }

    return fetchApi.get<AddressModel>(`/street-address-objects/${id}/`, query).then((address) => new Address(address));
  };

  getAddressObjectById = (id: StringOrNumber) =>
    fetchApi.get<AddressModel>(`/address-objects/${id}/`).then((address) => new Address(address));

  getExportTypes = () =>
    fetchApi.get<DictionaryBasicWithCodeModel[]>('/export-types/').then((exportTypes) => {
      runInAction(() => {
        this.exportTypes = exportTypes.map((exportType) => new DictionaryBasicWithCode(exportType));
      });
    });

  resetAuthorityOrgans = () => {
    runInAction(() => {
      this.docIssueAuthorityOrgans = [];
    });
  };

  getPDFFormTypes = () =>
    fetchApi.get<DictionaryBasicWithCodeModel[]>('/pdf-form-types/').then((pdfFormTypes) => {
      runInAction(() => {
        this.pdfFormTypes = pdfFormTypes.map((pdfFormType) => new DictionaryBasicWithCode(pdfFormType));
      });
    });

  getPersonCategories = () =>
    fetchApi.get<DictionaryBasicModel[]>('/person-categories/').then((categories) => {
      runInAction(() => {
        this.personCategories = categories.map((category) => new DictionaryBasic(category));
      });
    });

  getDocTypes = () =>
    fetchApi.get<DocTypeModel[]>('/document-type-enums/').then((docTypes) => {
      runInAction(() => {
        this.docTypes = docTypes.map((docType) => new DocType(docType));
      });
    });

  getDocVisaTypes = () =>
    fetchApi.get<DictionaryBasicModel[]>('/doc-visa-types/').then((docVisaTypes) => {
      runInAction(() => {
        this.docVisaTypes = docVisaTypes.map((visaType) => new DictionaryBasic(visaType));
      });
    });

  getDocVisaMultiTypes = () =>
    fetchApi.get<DictionaryBasicModel[]>('/doc-visa-multi-types/').then((docVisaMultiTypes) => {
      runInAction(() => {
        this.docVisaMultiTypes = docVisaMultiTypes.map((visaType) => new DictionaryBasic(visaType));
      });
    });

  getDocVisaVisitTypes = () =>
    fetchApi.get<DocVisaVisitTypeModel[]>('/doc-visa-visit-types/').then((docVisaVisitTypes) => {
      runInAction(() => {
        this.docVisaVisitTypes = docVisaVisitTypes.map((visaVisitType) => new DocVisaVisitType(visaVisitType));
      });
    });

  getDocIssueAuthorityOrgans = (code: string, name?: string) =>
    fetchApi.get<DocIssueAuthority[]>('/doc-issue-authority-organs/', { code, name }).then((docIssueAuthorities) => {
      runInAction(() => {
        this.docIssueAuthorityOrgans =
          docIssueAuthorities?.map((docIssueAuthority) => new DocIssueAuthority(docIssueAuthority)) ?? [];
      });

      return this.docIssueAuthorityOrgans;
    });

  getEntryGates = () =>
    fetchApi.get<DictionaryBasicModel[]>('/doc-entry-points/').then((entryGates) => {
      runInAction(() => {
        this.entryGates = entryGates.map((gate) => new DictionaryBasic(gate));
      });
    });

  getVisitGoals = () =>
    fetchApi.get<DictionaryBasicModel[]>('/doc-visit-purposes/').then((visitGoals) => {
      runInAction(() => {
        this.visitGoals = visitGoals.map((goal) => new DictionaryBasic(goal));
      });
    });

  getKSImageById = (personId: string, imageId: number) =>
    fetchApi
      .get<[Blob, Response]>(`/persons/${personId}/ks_images/${imageId}/`, {}, { responseType: 'blob' })
      .then(([image]: [Blob, Response]) => image);

  getKSReasons = () =>
    fetchApi.get<KSReasonModel[]>('/ks-reasons/').then((ksReasons) => {
      runInAction(() => {
        this.ksReasons = ksReasons.map((ksReason) => new KSReason(ksReason));
      });
    });

  getKSReasonsDocs = () =>
    fetchApi.get<KSReasonDocModel[]>('/ks-reason-docs/').then((ksReasonsDocs) => {
      runInAction(() => {
        this.ksReasonsDocs = ksReasonsDocs.map((ksReasonsDoc) => new KSReasonDoc(ksReasonsDoc));
      });
    });

  getKSReasonDocImageById = (reasonDocId: number) =>
    fetchApi
      .get<[Blob, Response]>(`/ks-reason-docs/${reasonDocId}/`, {}, { responseType: 'blob' })
      .then(([image]: [Blob, Response]) => image);

  getCountries = () =>
    fetchApi.get<DictionaryBasicWithCodeModel[]>('/countries/').then((countries) => {
      runInAction(() => {
        this.countries = countries.map((country) => new DictionaryBasicWithCode(country));
      });
    });

  /* --- OLD, REMOVE LATER ---*/
  getPlaces = (name: string, region_code: Nullable<string>) => {
    const query: { [key: string]: string } = {};

    if (name) {
      query.name = name;
    }

    if (region_code) {
      query.region_code = region_code;
    }

    return fetchApi.get<AddressModel[]>('/area-address-objects/', query).then((places) => {
      runInAction(() => {
        this.places = places.map((place) => new Address(place));
      });
    });
  };

  getCities = (name: string, region_code: Nullable<string>, parent_id: Nullable<number>) => {
    const query: { [key: string]: StringOrNumber } = {};

    if (name) {
      query.name = name;
    }

    if (region_code) {
      query.region_code = region_code;
    }

    if (parent_id) {
      query.parent_id = parent_id;
    }

    return fetchApi.get<AddressModel[]>('/city-address-objects/', query).then((cities) => {
      runInAction(() => {
        this.cities = cities.map((city) => new Address(city));
      });
    });
  };

  getSettlements = (name: string, region_code: Nullable<string>, parent_id: Nullable<number>) => {
    const query: { [key: string]: StringOrNumber } = {};

    if (name) {
      query.name = name;
    }

    if (region_code) {
      query.region_code = region_code;
    }

    if (parent_id) {
      query.parent_id = parent_id;
    }

    return fetchApi.get<AddressModel[]>('/place-address-objects/', query).then((settlements) => {
      runInAction(() => {
        this.settlements = settlements.map((settlement) => new Address(settlement));
      });
    });
  };

  resetPlaces = () => {
    runInAction(() => {
      this.places = [];
    });
  };

  resetCities = () => {
    runInAction(() => {
      this.cities = [];
    });
  };

  resetSettlements = () => {
    runInAction(() => {
      this.settlements = [];
    });
  };
}
