import React, { memo, MouseEvent } from 'react';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Launch';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import blue from '@mui/material/colors/blue';
import deepPurple from '@mui/material/colors/deepPurple';
import green from '@mui/material/colors/green';
import orange from '@mui/material/colors/orange';
import red from '@mui/material/colors/red';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { PersonStatus } from '@/stores/PersonsStore';
import DropdownMenu from '@/ui-kit/components/DropdownMenu/DropdownMenu';

interface ChangePersonStatusIconProps {
  title: string;
  status?: PersonStatus;
  onSetPersonStatus: (e: MouseEvent<HTMLLIElement>) => void;
}

function ChangePersonStatusIcon(props: ChangePersonStatusIconProps) {
  const { title, status, onSetPersonStatus } = props;

  return (
    <DropdownMenu
      opener={
        <Tooltip title={title}>
          <IconButton>
            {status === PersonStatus.Registered && <CheckCircleOutlineIcon htmlColor={deepPurple['500']} />}

            {status === PersonStatus.Pending && <EditIcon htmlColor={red['500']} />}

            {status === PersonStatus.Accepted && <CheckIcon htmlColor={green['500']} />}

            {status === PersonStatus.Exported && <ShareIcon htmlColor={blue['500']} />}

            {status === PersonStatus.Forbidden && <RemoveCircleOutlineIcon htmlColor={red['500']} />}

            {status === PersonStatus.AwaitingRegistration && <AccessAlarmIcon htmlColor={orange['500']} />}

            {!status && <EditIcon />}
          </IconButton>
        </Tooltip>
      }
      options={[
        {
          label: 'В ожидании',
          onClick: onSetPersonStatus,
          value: PersonStatus.Pending,
          icon: <EditIcon fontSize="large" htmlColor={red['500']} />,
        },
        {
          label: 'Подтвержден',
          onClick: onSetPersonStatus,
          value: PersonStatus.Accepted,
          icon: <CheckIcon fontSize="large" htmlColor={green['500']} />,
        },
        {
          label: 'Выгружен',
          onClick: onSetPersonStatus,
          value: PersonStatus.Exported,
          icon: <ShareIcon fontSize="large" htmlColor={blue['500']} />,
        },
        {
          label: 'Запрет регистрации',
          onClick: onSetPersonStatus,
          value: PersonStatus.Forbidden,
          icon: <RemoveCircleOutlineIcon fontSize="large" htmlColor={red['500']} />,
        },
      ]}
    />
  );
}

export default memo(ChangePersonStatusIcon);
