import React, { memo, useEffect } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { notificationAPI } from '@/api/notificationAPI';
import { Role } from '@/entities/role';
import { NewRoleModel } from '@/stores/RolesStore/RolesStore.types';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, InputField, SwitchField } from '@/ui-kit/components';

interface RoleFormModalProps extends ContentModalProps {
  type: 'create' | 'edit';
  role?: Role;
}

function RoleFormModal(props: RoleFormModalProps) {
  const { role, type, open, onClose } = props;
  const { isSaving, createRole, updateRole, getRoles } = useStore('rolesStore');

  const roleForm = useFormik<NewRoleModel>({
    validateOnMount: false,
    initialValues: {
      name: '',
      allow_scan: false,
      allow_create: false,
      allow_edit: false,
      allow_delete: false,
      allow_manage_groups: false,
    },
    onSubmit: async (values) => {
      try {
        if (type === 'create') {
          await createRole(values);
        }

        if (type === 'edit' && role?.id) {
          await updateRole(values, role.id);
        }

        notificationAPI.success(`Роль ${type === 'edit' ? 'обновлена' : 'добавлена'}`);

        await getRoles();

        onClose();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(2, 'Не менее 2-х символов').required('Обязательно к заполнению'),
    }),
  });

  function renderOptionItem(name: keyof NewRoleModel, label: string) {
    return (
      <SwitchField
        checked={Boolean(roleForm.values[name])}
        disabled={isSaving}
        label={label}
        name={name}
        onChange={roleForm.handleChange}
      />
    );
  }

  useEffect(() => {
    roleForm.resetForm({
      values: {
        name: role?.name ?? '',
        allow_scan: role?.allow_scan ?? false,
        allow_create: role?.allow_create ?? false,
        allow_edit: role?.allow_edit ?? false,
        allow_delete: role?.allow_delete ?? false,
        allow_manage_groups: role?.allow_manage_groups ?? false,
      },
    });
  }, [open]);

  return (
    <ContentModal disableEscapeKeyDown={isSaving} onClose={onClose} open={open}>
      <form onReset={onClose} onSubmit={roleForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSaving} onClose={onClose}>
          <span>{type === 'edit' ? 'Обновить ' : 'Создать '}</span>

          <span>роль</span>
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputField
                autoFocus
                disabled={isSaving}
                error={!!roleForm.errors.name}
                helperText={roleForm.errors.name}
                label="Название"
                name="name"
                onChange={roleForm.handleChange}
                value={roleForm.values.name}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {renderOptionItem('allow_scan', 'Сканирование')}

                  {renderOptionItem('allow_create', 'Создание')}

                  {renderOptionItem('allow_edit', 'Редактирование')}

                  {renderOptionItem('allow_delete', 'Удаление')}
                </Grid>

                <Grid item xs={6}>
                  {renderOptionItem('allow_manage_groups', 'Управление группами')}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSaving} type="reset">
            Отмена
          </Button>

          <ButtonLoader isLoading={isSaving} type="submit">
            {type === 'edit' && 'Обновить'}

            {type === 'create' && 'Создать'}
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(RoleFormModal));
