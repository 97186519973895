import React, { Fragment, memo, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { observer } from 'mobx-react-lite';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { Address } from '@/entities/address';
import { QuestionnaireReport } from '@/shared/PrintForms/QuestionnaireReport/QuestionnaireReport';
import { PersonDetail } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';

import { usePersonPageStore } from '../../../PersonPageStore';

import { QuestionnaireButtonControl } from './QuestionnaireButton.style';

function QuestionnaireButton() {
  const { person, regInfo, stayInfo, legalRepresentativeId, documents, citizenship, isRussianCitizenship } =
    usePersonPageStore();

  const { userCompany } = useStore('signInStore');
  const { getStreetObjectById, countries } = useStore('dictionariesStore');
  const { getPersonById } = useStore('personsStore');

  const componentRef = useRef(null);
  const onPrintHandler = useReactToPrint({
    content: () => componentRef.current,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [urAddress, setUrAddress] = useState<Nullable<Address>>(null);
  const [regAddress, setRegAddress] = useState<Nullable<Address>>(null);
  const [legalRepresentative, setLegalRepresentative] = useState<Nullable<PersonDetail>>(null);

  const country = useMemo(() => countries.find(({ id }) => citizenship === id), [citizenship]);
  const birthCountry = useMemo(() => countries.find(({ id }) => documents?.[0]?.birth_country_id === id), []);

  async function onPrint() {
    if (!isRussianCitizenship) {
      return;
    }

    setIsLoading(true);

    try {
      if (userCompany?.company && stayInfo?.company_id) {
        setUrAddress(await getStreetObjectById(userCompany.company.ur_fias_code2, userCompany.company.ur_region_code));

        if (legalRepresentativeId) {
          setLegalRepresentative(await getPersonById(legalRepresentativeId));
        }

        if (regInfo?.fias_code2) {
          setRegAddress(await getStreetObjectById(regInfo.fias_code2, regInfo.region_code));
        } else if (regInfo?.prev_fias_code2) {
          setRegAddress(await getStreetObjectById(regInfo.prev_fias_code2, regInfo.prev_region_code));
        }

        setTimeout(() => {
          onPrintHandler?.();

          // ждем пока проставятся все стейты и только потом запускаем принт
        }, 150);
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    setIsLoading(false);
  }

  return (
    <Fragment>
      <QuestionnaireButtonControl>
        <Tooltip title={isRussianCitizenship ? 'Печать анкеты' : 'Доступно только для граждан РФ'}>
          <IconButton disabled={isLoading} onClick={onPrint}>
            <AssignmentIndIcon />
          </IconButton>
        </Tooltip>
      </QuestionnaireButtonControl>

      <div style={{ display: 'none' }}>
        <QuestionnaireReport
          birthCountry={birthCountry}
          citizenship={country}
          company={userCompany?.company ?? null}
          legalRepresentative={legalRepresentative}
          ref={componentRef}
          regAddress={regAddress}
          urAddress={urAddress}
          values={person}
        />
      </div>
    </Fragment>
  );
}

export default memo(observer(QuestionnaireButton));
