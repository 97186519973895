import React, { ChangeEvent, Fragment, memo, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FilterIcon from '@mui/icons-material/FilterList';
import RestoreIcon from '@mui/icons-material/Restore';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import { LabelDisplayedRowsArgs } from '@mui/material/TablePagination/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import ChangePersonStatusIcon from '@/shared/ChangePersonStatusIcon/ChangePersonStatusIcon';
import FilterRow from '@/shared/Persons/FilterRaw/FilterRaw';
import PersonRow from '@/shared/Persons/PersonRaw/PersonRaw';
import { GetPersonsArguments, Person } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader, ConfirmModal } from '@/ui-kit/components';

import {
  PersonsContent,
  PersonsPagination,
  PersonsPaginationSelected,
  PersonsSortButton,
  PersonsTable,
  PersonsTableWrapper,
} from './Persons.style';

interface ReservationsProps {
  mode: 'general' | 'deleted';
}

interface SortSettings {
  field: 'created_at' | 'stay_from' | 'lname' | 'fname';
  direction: 'ASC' | 'DESC';
}

function Persons(props: ReservationsProps) {
  const { mode } = props;
  const { groupId } = useParams();
  const { search, hash, pathname } = useLocation();
  const { userCompany } = useStore('signInStore');
  const navigate = useNavigate();
  const {
    isFetching,
    isSaving,
    isRestoring,
    total,
    persons,
    resetAllSelectedPersons,
    selectAllReservation,
    selectedPersons,
    getPersons,
    abortGetPersons,
    resetPersons,
    restoreSelectedPersons,
    setSelectedStatus,
    getValidFilterData,
  } = useStore('personsStore');

  const query = queryAPI.params;

  const [sort, setSorting] = useState<SortSettings>({
    field: query.field ?? 'created_at',
    direction: query.direction ?? 'DESC',
  });
  const [isFilterRow, setFilterRowOpen] = useState(false);
  const [isRestoreOpen, setIsRestoreOpen] = useState(false);

  const filterData: GetPersonsArguments = useMemo(() => {
    const validFilterData = getValidFilterData(queryAPI.params);

    if (Object.keys(validFilterData).length > 0) {
      const filterOnlyKeys = Object.keys(validFilterData).filter(
        (key: string) => key !== 'limit' && key !== 'page' && key !== 'groupId',
      );

      if (filterOnlyKeys.length > 0) {
        setFilterRowOpen(true);
      }
    }

    return {
      ...validFilterData,
      groupId,
    };
  }, [groupId, search]);

  async function fetchPersons(args: GetPersonsArguments) {
    try {
      await getPersons({
        show_deleted: mode === 'deleted',
        hms_status_not_in: false,
        ...args,
      });

      // Ждем пока отрендерится экран с персонами и только потом ищем ноду с айди
      setTimeout(() => {
        const targetNode = document.getElementById(hash);

        if (targetNode) {
          targetNode?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });

          navigate(`${pathname}${search}`);
        }
      }, 200);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    resetAllSelectedPersons();

    fetchPersons(filterData);
  }, [filterData]);

  useEffect(
    () => () => {
      resetPersons();
      abortGetPersons();
      resetAllSelectedPersons();
    },
    [],
  );

  function onChangePage(e: Nullable<MouseEvent>, newPage: number) {
    e?.preventDefault();

    navigate({
      pathname,
      search: queryAPI.generateQuery({
        ...filterData,
        page: newPage,
      }),
    });
  }

  function onChangeCount(e: ChangeEvent<HTMLInputElement>) {
    e?.preventDefault();

    const limit = +e.target.value;

    navigate({
      pathname,
      search: queryAPI.generateQuery({
        ...filterData,
        limit: limit > 0 ? limit : undefined,
        page: 0, // при смене количество записей всегда сбрасывать страницу
      }),
    });
  }

  function onFilter(filter: Partial<GetPersonsArguments>) {
    navigate({
      pathname,
      search: queryAPI.generateQuery({
        ...filterData,
        ...filter,
        page: 0, // при фильтрации всегда сбрасывать страницу
      }),
    });
  }

  function renderSortingIcon(field: SortSettings['field']) {
    if (sort.field !== field) {
      return null;
    }

    return sort.direction === 'ASC' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;
  }

  function onSort(field: SortSettings['field']) {
    let nextSort: SortSettings = {
      field,
      direction: 'DESC',
    };

    if (sort.field === field) {
      nextSort = {
        field,
        direction: sort.direction === 'DESC' ? 'ASC' : 'DESC',
      };
    }

    setSorting(nextSort);

    queryAPI.replaceQueryParams({
      ...filterData,
      ...nextSort,
    });
  }

  const onSetPersonStatus = useCallback(async ({ currentTarget }: MouseEvent<HTMLLIElement>) => {
    try {
      await setSelectedStatus(currentTarget.value);
    } catch (e) {
      notificationAPI.error(e);
    }
  }, []);

  async function onRestorePersons() {
    try {
      await restoreSelectedPersons();

      notificationAPI.success('Карточки гостя восстановлены');

      onToggleRestoreModal();

      fetchPersons(filterData);
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onToggleFilterRowOpen = useCallback(() => setFilterRowOpen((isFilterRow: boolean) => !isFilterRow), []);
  const renderPaginationLabel = useCallback(({ from, to, count }: LabelDisplayedRowsArgs) => {
    if (!from || !to || !count) {
      return null;
    }

    return `${from}-${to} из ${count}`;
  }, []);
  const onToggleRestoreModal = useCallback(() => setIsRestoreOpen((isRestoreOpen: boolean) => !isRestoreOpen), []);

  function sortingFunction(a: Person, b: Person) {
    let leftItem = a[sort.field];
    let rightItem = b[sort.field];

    if (sort.field === 'stay_from') {
      leftItem = a.stay_info?.stay_till ?? a.created_at;
      rightItem = b.stay_info?.stay_till ?? b.created_at;
    }

    if (sort.field === 'lname' || sort.field === 'fname') {
      leftItem = leftItem.toLocaleLowerCase();
      rightItem = rightItem.toLocaleLowerCase();
    }

    if (sort.direction === 'ASC') {
      return leftItem > rightItem ? 1 : -1;
    }

    if (sort.direction === 'DESC') {
      return leftItem > rightItem ? -1 : 1;
    }

    return 0;
  }

  const sortedPersons = useMemo(() => {
    const nextPersons = persons.slice();

    if (sort.field === 'lname' || sort.field === 'fname') {
      const cyrillic: Person[] = [];
      const english: Person[] = [];

      nextPersons.forEach((person) => {
        if (/[а-яА-ЯЁё0-9]/.test(person[sort.field])) {
          cyrillic.push(person);
        } else {
          english.push(person);
        }
      });

      return [...cyrillic.sort(sortingFunction), ...english.sort(sortingFunction)];
    }

    return nextPersons.sort(sortingFunction);
  }, [JSON.stringify(persons), sort.field, sort.direction]);

  const tableColumnsCount = userCompany?.company?.hasHMSStatus ? 11 : 10;

  return (
    <Fragment>
      <BackdropLoader isLoading={isFetching || isSaving} />

      <PersonsContent>
        <PersonsTableWrapper>
          <PersonsTable data-testid="person-table" size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell padding="none" width="3%">
                  <Tooltip title="Выделить все карточки">
                    <Checkbox
                      checked={selectedPersons.size === sortedPersons.length && sortedPersons.length > 0}
                      color="primary"
                      onChange={() => selectAllReservation()}
                    />
                  </Tooltip>
                </TableCell>

                <TableCell padding="none" width="3%">
                  {mode === 'general' && selectedPersons.size > 0 && (
                    <ChangePersonStatusIcon
                      onSetPersonStatus={onSetPersonStatus}
                      title="Изменить статус выделенных карточек"
                    />
                  )}
                </TableCell>

                <TableCell width="12%">
                  <PersonsSortButton
                    component="span"
                    onClick={() => onSort('lname')}
                    size="small"
                    startIcon={renderSortingIcon('lname')}
                    variant="text"
                  >
                    Фамилия
                  </PersonsSortButton>
                </TableCell>

                <TableCell width="12%">
                  <PersonsSortButton
                    component="span"
                    onClick={() => onSort('fname')}
                    size="small"
                    startIcon={renderSortingIcon('fname')}
                    variant="text"
                  >
                    Имя
                  </PersonsSortButton>
                </TableCell>

                <TableCell width="9%">Комната</TableCell>

                <TableCell width="9%">Гражданство</TableCell>

                <TableCell width="11%">Номер документа</TableCell>

                <TableCell width="11%">
                  <PersonsSortButton
                    component="span"
                    onClick={() => onSort('created_at')}
                    size="small"
                    startIcon={renderSortingIcon('created_at')}
                    variant="text"
                  >
                    Дата создания
                  </PersonsSortButton>
                </TableCell>

                <TableCell width="11%">Сроки пребывания</TableCell>

                {userCompany?.company?.hasHMSStatus && (
                  <TableCell width="11%">
                    <Tooltip title="Текущий статус устанавливается отельной системой атоматически">
                      <span>Статус</span>
                    </Tooltip>
                  </TableCell>
                )}

                <TableCell align="center" padding="none" width="9%">
                  {mode === 'general' && (
                    <Tooltip title="Поиск">
                      <IconButton onClick={onToggleFilterRowOpen}>
                        <FilterIcon />
                      </IconButton>
                    </Tooltip>
                  )}

                  {mode === 'deleted' && selectedPersons.size > 0 && (
                    <Tooltip title="Восстановить группу">
                      <IconButton onClick={onToggleRestoreModal}>
                        <RestoreIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {isFilterRow && <FilterRow filterData={filterData} onFilter={onFilter} />}

              {!isFetching && (
                <Fragment>
                  {sortedPersons.length > 0 &&
                    sortedPersons.map((person: Person) => <PersonRow key={person.id} mode={mode} person={person} />)}

                  {sortedPersons.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={tableColumnsCount}>
                        <Typography align="center">Регистрации отсутствуют</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )}
            </TableBody>
          </PersonsTable>
        </PersonsTableWrapper>

        <PersonsPagination>
          <PersonsPaginationSelected>
            {selectedPersons.size > 0 && (
              <Fragment>
                <span>Выбрано:&nbsp;</span>

                <span>{selectedPersons.size}</span>
              </Fragment>
            )}
          </PersonsPaginationSelected>

          <TablePagination
            component="div"
            count={total}
            labelDisplayedRows={renderPaginationLabel}
            labelRowsPerPage="Записей на странице"
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeCount}
            page={filterData.page}
            rowsPerPage={filterData.limit}
            rowsPerPageOptions={[10, 25, 50, 75, 100]}
          />
        </PersonsPagination>

        <ConfirmModal
          confirmLabel="Восстановить"
          description="Вы уверены, что хотите восстановить выбранные карточки гостей?"
          isLoading={isRestoring}
          onClose={onToggleRestoreModal}
          onConfirm={onRestorePersons}
          onDecline={onToggleRestoreModal}
          open={isRestoreOpen}
          title="Восстановление карточек гостей"
        />
      </PersonsContent>
    </Fragment>
  );
}

export default memo(observer(Persons));
