import React, { SyntheticEvent, useCallback } from 'react';
import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { closeSnackbar, SnackbarKey, SnackbarProvider } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import 'react-18-image-lightbox/style.css';
import '@/ui-kit/index.css';

import { storageAPI } from '@/api/storageAPI';
import {
  INACTIVITY_STORAGE_KEY,
  NEW_PERSON_ID,
  ROUTE_MAIN,
  ROUTE_PASSWORD_RECOVERY,
  ROUTE_RESTORE_PASSWORD,
  ROUTE_RETENTION,
  ROUTE_SELF_CHEKIN,
  ROUTE_SELF_CHEKIN_MOBILE,
  ROUTE_SIGNIN,
  ROUTE_UPLOAD_IMAGES,
} from '@/constants';
import {
  Main,
  NotFound,
  PasswordRecovery,
  RestorePassword,
  Retention,
  SelfCheckIn,
  SelfCheckInMobile,
  SignIn,
  UploadImages,
} from '@/routes';
import PersonPage from '@/routes/Main/OperatorPanel/PersonPage/PersonPage';
import { useStore } from '@/stores/StoreProvider';

/*
 * код ниже будет перехватывать все `fetch` запросы и перезаписывать время каждого запроса в sessionStorage,
 * это нужно, чтобы отслеживать активность пользователя, если пользователь не будет делать никаких запросов в течении 30 минут,
 * то его сессию нужно сбросить
 *
 * это не будет работать с `axios` или `XHR`, поэтому нужно использовать только `fetch`
 * */
const fetch = window.fetch;

window.fetch = function() {
  return new Promise((resolve, reject) => {
    fetch
      // @ts-ignore
      // eslint-disable-next-line
      .apply(this, arguments)
      .then((response) => {
        storageAPI.set(INACTIVITY_STORAGE_KEY, new Date().toISOString(), 'session');

        resolve(response);
      })
      .catch(reject);
  });
};

/*
 * В приложении есть библиотеки, которые зависят от `global`,
 * но `global` по умолчанию не встроен в vite, поэтому в vite.config.ts мы определяем `global`,
 * а здесь перезаписываем значение
 * */
// eslint-disable-next-line no-global-assign
window.global = window;

export function App() {
  const { isIntegration } = useStore('signInStore');

  const onActionHandler = useCallback((key: SnackbarKey) => {
    function onClickHandler(e: SyntheticEvent) {
      e.stopPropagation();
      e.preventDefault();

      closeSnackbar(key);
    }

    return (
      <IconButton onClick={onClickHandler} size="small">
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  }, []);

  return (
    <SnackbarProvider
      action={onActionHandler}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      maxSnack={4}
    >
      {/*
        Если пользователь посещает роут `/hms/person/new?...`, значит он использует интеграцию с отельными системами

        Пример строки:
        `/hms/person/new?login=&password=&hp_id=123&birth_date=12-05-2020&stay_from=10-05-2020&stay_till=10-07-2020&room_number=255&lname=Иванов&lname_lat=Ivanov&fname=Иван&fname_lat=Ivan&tel=0-000-000-00-00`
      */}

      {isIntegration && (
        /*
         * Если пользователь использует URL интеграции, мы должны отрисовать страницу ВНЕ `BrowserRouter`,
         * потому что `BrowserRouter` мутирует объект `history` и мы не сможем закрыть вкладку браузера, используя `window.close()`,
         * потому что `window.close` из-за правил безопасности сработает только если history не содержит данных
         * */
        <PersonPage
          id={NEW_PERSON_ID}
          navigate={(url) => {
            window.location.href = url;
          }}
        />
      )}

      {!isIntegration && (
        <BrowserRouter>
          <Routes>
            <Route element={<SignIn />} path={ROUTE_SIGNIN} />

            <Route element={<RestorePassword />} path={ROUTE_RESTORE_PASSWORD} />

            <Route element={<PasswordRecovery />} path={ROUTE_PASSWORD_RECOVERY} />

            <Route element={<SelfCheckIn />} path={ROUTE_SELF_CHEKIN} />

            <Route element={<SelfCheckInMobile />} path={ROUTE_SELF_CHEKIN_MOBILE} />

            <Route element={<UploadImages />} path={`${ROUTE_UPLOAD_IMAGES}/:personId`} />

            <Route element={<Main />} path={`${ROUTE_MAIN}*`} />

            <Route element={<Retention />} path={ROUTE_RETENTION} />

            <Route element={<NotFound />} path="*" />
          </Routes>
        </BrowserRouter>
      )}
    </SnackbarProvider>
  );
}
