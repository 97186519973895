import React from 'react';
import { createRoot } from 'react-dom/client';
import ruLocale from 'date-fns/locale/ru';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from '@sentry/react';

import { initSentry } from '@/services/sentry';
import { StoreProvider } from '@/stores/StoreProvider';

import { App } from './App';
import { EMIS_THEME } from './App.style';

if (import.meta.env.MODE === 'production') {
  initSentry();
}

const GlobalStylesSet = (
  <GlobalStyles
    styles={{
      fieldset: {
        border: 0,
        padding: '0px',
        margin: '0px',
      },
      /*
       * Скрываем попап с кнопками манипуляции видео в Opera
       * https://stackoverflow.com/questions/38207495/disable-operas-html-video-pop-out-button
       * */
      '#detach-button-host': {
        display: 'none',
      },
    }}
  />
);

const container = document.getElementById('root') as HTMLElement;

if (container) {
  container.style.height = '100%';

  createRoot(container)?.render(
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
      <LocalizationProvider adapterLocale={ruLocale} dateAdapter={AdapterDateFns}>
        <StoreProvider>
          <ThemeProvider theme={EMIS_THEME}>
            <CssBaseline />

            {GlobalStylesSet}

            <App />
          </ThemeProvider>
        </StoreProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>,
  );
}
