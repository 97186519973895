import React, { ForwardedRef, forwardRef, memo } from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

interface SwitchFieldProps extends CheckboxProps {
  disabled?: boolean;
  label?: string;
}

const CheckboxField = forwardRef((props: SwitchFieldProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const { disabled, label, color = 'primary' } = props;

  return (
    <FormControlLabel control={<Checkbox color={color} ref={ref} {...props} />} disabled={disabled} label={label} />
  );
});

export default memo(CheckboxField);
