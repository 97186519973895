import grey from '@mui/material/colors/grey';
import Grid from '@mui/material/Grid';
import styled from '@mui/material/styles/styled';

export const HotelReportContent = styled(Grid)`
  height: 100%;
  align-items: stretch;
`;

export const HotelReportSidebar = styled('div')`
  border-right: 1px solid ${grey['300']};
  height: 100%;
`;
