import { BIRTH_DATE_PART_DEFAULT, Citizen, DEPRECATED_DEFAULT_DATE, DocumentType, NEW_PERSON_ID } from '@/constants';
import {
  DEFAULT_ID_CARD,
  DEFAULT_MIGRATION_CARD,
  DEFAULT_RIGHT_OF_STAY,
  PersonDetail,
  PersonDocument,
} from '@/stores/PersonsStore';
import { getDocumentByType, getValidDateValue } from '@/utils';

import { PersonPageType } from './PersonPage.constant';
import { PersonFormRouteState } from './PersonPage.types';

export function isBirthDatePartValid(birthDatePart?: Nullable<string>): boolean {
  // 10 - минимальная длинна заполненной даты '00.00.0000'
  // на сервере она хранится строкой, не типа Date
  return (birthDatePart ?? '').replace(/\s/g, '').length === 10;
}

export function serializePersonFormValues(
  person: PersonDetail,
  groupId?: Nullable<string>,
  defaultGroupId?: number,
  routeState?: PersonFormRouteState,
): PersonDetail {
  let personGroupId;

  // если `person.group_id` заполнен, значит мы редактируем данные персона, то есть ему группа уже назначена
  // если же нет, то проверяем условие:
  // если `groupId` заполнен, значит мы создаем пользователя по прямому пути /person/new/:groupId`, но не группа с айди 1, так как это группа в системе по умолчанию
  // если нет то - `defaultGroupId` не равно 0, значит пользователь имеет закрепленную группу по умолчанию, в этом случае используем ее
  if (person.group_id) {
    personGroupId = person.group_id;
  } else if (groupId && groupId !== '1') {
    personGroupId = +groupId;
  } else if (defaultGroupId !== 0) {
    personGroupId = defaultGroupId;
  }

  const documents = [...person.documents];

  const idCardTemp = routeState?.usr?.idCardTemp ?? {};

  if (Object.keys(idCardTemp).length > 0) {
    documents[0] = {
      ...idCardTemp,
    } as PersonDocument;
  }

  const [IdCardValues] = getDocumentByType(documents, DocumentType.IdCard);

  return {
    ...person,
    category_id: person.category_id === 0 ? 1 : person.category_id,
    group_id: personGroupId,
    birth_date: person.birth_date === DEPRECATED_DEFAULT_DATE ? null : getValidDateValue(person.birth_date),
    birth_date_part: person.birth_date_part ?? BIRTH_DATE_PART_DEFAULT,
    hotel_person_id: person?.hotel_person_id ?? '',
    doc_expire_date: IdCardValues?.doc_expire_date ?? person.doc_expire_date,
    doc_issue_date: IdCardValues?.doc_issue_date ?? person.doc_issue_date,
    doc_number: IdCardValues?.doc_number ?? person.doc_number,
    doc_serial: IdCardValues?.doc_serial ?? person.doc_serial,
    citizenship: person.citizenship === Citizen.USSR ? Citizen.Russia : person.citizenship,
    documents: documents.map((document: PersonDocument): PersonDocument => {
      if (document.document_type_enum.category === DocumentType.IdCard) {
        return {
          ...DEFAULT_ID_CARD,
          ...document,
          ...idCardTemp,
        };
      }

      if (document.document_type_enum.category === DocumentType.RightOfStay) {
        return {
          ...DEFAULT_RIGHT_OF_STAY,
          ...document,
          visit_days: document.visit_days ? +document.visit_days : '',
          doc_id: typeof document.doc_id === 'string' ? document.doc_id.toUpperCase() : document.doc_id,
        };
      }

      return {
        ...DEFAULT_MIGRATION_CARD,
        ...document,
      };
    }),
    reg_info: {
      ...person.reg_info,
      reg_from: person.reg_info?.reg_from !== DEPRECATED_DEFAULT_DATE ? person.reg_info?.reg_from ?? null : null,
      reg_till: person.reg_info?.reg_till !== DEPRECATED_DEFAULT_DATE ? person.reg_info?.reg_till ?? null : null,
    },
    stay_info: {
      ...person.stay_info,
      stay_from:
        person.stay_info?.stay_from !== DEPRECATED_DEFAULT_DATE
          ? person.stay_info?.stay_from ?? person.created_at
          : person.created_at,
      stay_till: person.stay_info?.stay_till !== DEPRECATED_DEFAULT_DATE ? person.stay_info?.stay_till ?? null : null,
    },
  };
}

export function getPersonPageType(id?: string): PersonPageType {
  return id === NEW_PERSON_ID ? PersonPageType.Create : PersonPageType.Edit;
}
