import styled from '@mui/material/styles/styled';

export const DevicesListRoot = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DevicesListHeader = styled('div')`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const DevicesListContent = styled('div')`
  flex: 1;
  overflow: hidden;
`;

export const DevicesListList = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
