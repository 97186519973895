import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';

import { queryAPI } from '@/api/queryAPI';
import { ROUTE_PERSON } from '@/constants';
import { Person } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';

import { usePersonPageStore } from '../../PersonPageStore';

import { PersonsNavigationRoot } from './PersonsNavigation.style';

function PersonsNavigation() {
  const { isFetching, getPersonsPure, getValidFilterData } = useStore('personsStore');
  const { personId, navigate, groupId, isStoreLoaded } = usePersonPageStore();

  const [persons, setPersons] = useState<Person[]>([]);

  function navigateToPerson(id: number, query: string = queryAPI.getQuery()) {
    let path = `${ROUTE_PERSON}/${id}`;

    if (groupId) {
      path += `/${groupId}`;
    }

    navigate(`${path}${query}`);
  }

  async function onNavigate(direction: 'next' | 'prev') {
    const page = queryAPI.getKey('page');

    let nextPerson;

    const personIndex = persons.findIndex((person) => Number(person.id) === Number(personId));

    if (direction === 'prev') {
      nextPerson = persons[personIndex - 1];
    }

    if (direction === 'next') {
      nextPerson = persons[personIndex + 1];
    }

    if (nextPerson) {
      navigateToPerson(nextPerson.id);
    } else {
      let nextPage = 0;

      if (direction === 'prev') {
        // Если текущая страница это нулевой персон в массиве и страница не определена - не делать ничего
        if (!page) {
          return;
        }

        nextPage = +page - 1;
      }

      if (direction === 'next') {
        nextPage = page ? +page + 1 : 1;
      }

      const data = await getPersonsPure({
        ...getValidFilterData(queryAPI.params),
        groupId,
        limit: queryAPI.has('limit') ? Number(queryAPI.params.limit) : undefined,
        page: nextPage,
      });

      const nextQuery = queryAPI.generateQuery({
        ...queryAPI.params,
        page: nextPage || null,
      });

      if (data.data.length > 0) {
        navigateToPerson(direction === 'next' ? data.data[0].id : data.data[data.data.length - 1].id, nextQuery);

        setPersons(data.data);
      }
    }
  }

  async function initPersonsNavigation() {
    const data = await getPersonsPure({
      ...getValidFilterData(queryAPI.params),
      groupId,
      limit: queryAPI.has('limit') ? Number(queryAPI.params.limit) : undefined,
      page: queryAPI.has('page') ? Number(queryAPI.params.page) : undefined,
    });

    setPersons(data.data);
  }

  useEffect(() => {
    if (isStoreLoaded) {
      initPersonsNavigation();
    }
  }, [isStoreLoaded]);

  return (
    <PersonsNavigationRoot>
      <IconButton disabled={isFetching || !isStoreLoaded} onClick={() => onNavigate('prev')}>
        <KeyboardArrowLeftIcon fontSize="inherit" />
      </IconButton>

      <IconButton disabled={isFetching || !isStoreLoaded} onClick={() => onNavigate('next')}>
        <KeyboardArrowRightIcon fontSize="inherit" />
      </IconButton>
    </PersonsNavigationRoot>
  );
}

export default memo(observer(PersonsNavigation));
