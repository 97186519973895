import React, { memo, SyntheticEvent } from 'react';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { notificationAPI } from '@/api/notificationAPI';
import { CreateEulaFormValuesType } from '@/stores/CompaniesStore';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps, FileField, InputField } from '@/ui-kit/components';

import { EulaFileWrapper } from './EulaCardModal.style';

interface EulaCardsModalProps extends ContentModalProps {
  companyId: number;
  onAfterChange: () => void;
}

function EulaCardModal(props: EulaCardsModalProps) {
  const { open, onClose, companyId, onAfterChange } = props;
  const { addCompanyEulaCard, isSavingEulaCard } = useStore('companiesStore');

  const eulaForm = useFormik<CreateEulaFormValuesType>({
    initialValues: {
      name: '',
      file: null,
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        await addCompanyEulaCard(companyId, values);

        notificationAPI.success('Регистрационная карта добавлена');

        resetForm();
        onClose();

        onAfterChange();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    validate: ({ name, file }) => {
      const errors: Partial<Record<keyof CreateEulaFormValuesType, string>> = {};

      if (name.length === 0) {
        errors.name = 'Обязательно к заполнению';
      }

      if (!file || (file as unknown as File[]).length === 0) {
        errors.file = 'Выберите файл';
      }

      return errors;
    },
  });

  function onCloseWithReset(e?: SyntheticEvent) {
    e?.stopPropagation();
    e?.preventDefault();

    eulaForm.resetForm();
    onClose();
  }

  return (
    <ContentModal disableEscapeKeyDown={isSavingEulaCard} maxWidth="md" onClose={onClose} open={open}>
      <form onReset={onCloseWithReset} onSubmit={eulaForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSavingEulaCard} onClose={onCloseWithReset}>
          Регистрационная карта
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid alignItems="flex-start" container spacing={2}>
            <Grid item xs={12}>
              <InputField
                autoFocus
                disabled={isSavingEulaCard}
                error={!!eulaForm.errors.name}
                helperText={eulaForm.errors.name}
                label="Наименование"
                name="name"
                onChange={eulaForm.handleChange}
                value={eulaForm.values.name}
              />
            </Grid>

            <Grid item xs={12}>
              <EulaFileWrapper>
                <FileField
                  accept="application/pdf"
                  label="Прикрепить карту"
                  name="file"
                  onChange={eulaForm.handleChange}
                  value={eulaForm.values.file}
                />
              </EulaFileWrapper>
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSavingEulaCard} type="reset">
            Отмена
          </Button>

          <ButtonLoader isLoading={isSavingEulaCard} type="submit">
            Добавить
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(EulaCardModal));
