import { COMPANY_DATA_ROUTES_MAP } from './CompanyData.constants';

export function getActiveTabIndex(pathname: string) {
  let index = 0;

  COMPANY_DATA_ROUTES_MAP.forEach((value: string, key: number) => {
    if (pathname.includes(value)) {
      index = key;
    }
  });

  return index;
}
