import React, { PureComponent } from 'react';

import { Address, AddressLevel } from '@/entities/address';
import { Company } from '@/entities/company';
import { DictionaryBasicWithCode } from '@/entities/dictionary';
import { Gender, PersonDetail } from '@/stores/PersonsStore';

import { DOCS_NAME_MAP, PRINT_STYLES } from './QuestionnaireReport.constants';

interface ReportToPrintContentProps {
  values: Nullable<PersonDetail>;
  company?: Nullable<Company>;
  urAddress?: Nullable<Address>;
  regAddress?: Nullable<Address>;
  prevAddress?: Nullable<Address>;
  citizenship?: DictionaryBasicWithCode;
  birthCountry?: DictionaryBasicWithCode;
  legalRepresentative?: Nullable<PersonDetail>;
}

type DateItem = StringOrNumber | Function;

// библиотека `react-to-print` работает только с классами, поэтому отключаем тут правило линтера
// eslint-disable-next-line react/prefer-stateless-function
class QuestionnaireReport extends PureComponent<ReportToPrintContentProps> {
  renderDateBlock(day: DateItem, month: DateItem, year: DateItem) {
    return (
      <div style={PRINT_STYLES.line}>
        <div style={PRINT_STYLES.line}>
          <div>&laquo;</div>

          <div
            style={{
              ...PRINT_STYLES.borderField,
              width: 20,
              textAlign: 'center',
            }}
          >
            {typeof day === 'function' ? day() : day}
          </div>

          <div>&raquo;</div>
        </div>

        <span>&nbsp;&nbsp;</span>

        <div
          style={{
            ...PRINT_STYLES.borderField,
            width: 70,
            textAlign: 'center',
          }}
        >
          {typeof month === 'function' ? month() : month}
        </div>

        <span>&nbsp;&nbsp;</span>

        <div style={PRINT_STYLES.line}>
          <div
            style={{
              ...PRINT_STYLES.borderField,
              width: 40,
              textAlign: 'center',
            }}
          >
            {typeof year === 'function' ? year() : year}
          </div>

          <div>г.</div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { values, company, urAddress, regAddress, birthCountry, citizenship, legalRepresentative } = this.props;

    if (!values) {
      return null;
    }

    const { birth_date, birth_date_part } = values;

    const dateOfBirth = birth_date ? new Date(birth_date) : null;

    const region = regAddress?.parents?.find((address: Address) => {
      if (address.ao_level === AddressLevel.Region) {
        return address;
      }

      return undefined;
    });
    const regionValue = region ? `${region.short_name} ${region.name}` : null;

    const area = regAddress?.parents?.find((address: Address) => {
      if (address.ao_level === AddressLevel.Area) {
        return address;
      }

      return undefined;
    });
    const areaValue = area ? `${area.short_name} ${area.name}` : null;

    const settlement = regAddress?.parents?.find((address: Address) => {
      if (address.ao_level === AddressLevel.Place) {
        return address;
      }

      return undefined;
    });
    const settlementValue = settlement ? `${settlement.short_name} ${settlement.name}` : null;

    const city = regAddress?.parents?.find((address: Address) => {
      if (address.ao_level === AddressLevel.City) {
        return address;
      }

      return undefined;
    });
    const cityValue = city ? `${city.short_name} ${city.name}` : null;

    const streetValue = regAddress ? `${regAddress.short_name} ${regAddress.name}` : null;

    function fullAddressValue() {
      let result = '';

      if (urAddress?.full_name) {
        result += `${urAddress?.full_name} `;
      }

      if (company?.ur_housing) {
        result += `Д. ${company?.ur_housing} `;
      }

      if (company?.ur_housing2) {
        result += `, КОР. ${company?.ur_housing2} `;
      }

      if (company?.housing3) {
        result += `, СТР. ${company?.housing3} `;
      }

      return result.trim();
    }

    return (
      <div
        style={{
          width: 450,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* заголовки */}

        <h2 style={PRINT_STYLES.title1}>
          <span>АНКЕТА</span>
        </h2>

        <div
          style={{
            ...PRINT_STYLES.pageContent,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* наименование гостиницы */}

          <div style={PRINT_STYLES.line}>
            <div
              style={{
                ...PRINT_STYLES.borderField,
                width: '100%',
                textAlign: 'center',
              }}
            >
              <span>{company?.ur_name}</span>
            </div>
          </div>

          <div
            style={{
              textAlign: 'center',
            }}
          >
            (наименование гостиницы, санатория, дома отдыха, пансионата, кемпинга, туристской базы или другого подобного
            учреждения)
          </div>

          {/* адрес гостиницы */}

          <div style={PRINT_STYLES.line}>
            <div
              style={{
                ...PRINT_STYLES.borderField,
                width: '100%',
                textAlign: 'center',
              }}
            >
              {fullAddressValue()}
            </div>
          </div>

          <div
            style={{
              textAlign: 'center',
            }}
          >
            (адрес (регион, район, город, населенный пункт))
          </div>

          {/* комната */}

          <div style={PRINT_STYLES.line}>
            <div
              style={{
                marginLeft: 'auto',
              }}
            >
              комн.
            </div>

            <div style={PRINT_STYLES.borderField}>{values.stay_info.room_number}</div>
          </div>

          {/* фамилия */}

          <div style={PRINT_STYLES.line}>
            <div>1. Фамилия (при наличии)</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {values.lname}
            </div>
          </div>

          {/* имя */}

          <div style={PRINT_STYLES.line}>
            <div>2. Имя (при наличии)</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {values.fname}
            </div>
          </div>

          {/* отчество */}

          <div style={PRINT_STYLES.line}>
            <div>3. Отчество (при наличии)</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {values.mname}
            </div>
          </div>

          {/* дата рождения / пол */}

          <div style={PRINT_STYLES.line}>
            <div style={PRINT_STYLES.line}>
              <div>4. Дата рождения&nbsp;&nbsp;</div>

              {this.renderDateBlock(
                () => {
                  const date = dateOfBirth?.getDate() ?? null;

                  return date ? `${date && date < 10 ? 0 : ''}${date}` : '';
                },
                () => {
                  // если dateOfBirth не указан, проверяем наличие birth_date_part
                  // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                  // на символы 3 и 4 мы берем значение месяца
                  let month: Nullable<number> = null;

                  if (dateOfBirth) {
                    month = dateOfBirth.getMonth();
                  } else if (birth_date_part) {
                    month = parseInt(`${birth_date_part[3]}${birth_date_part[4]}`) - 1;
                  }

                  return month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}` : '';
                },
                () => {
                  // если dateOfBirth не указан, проверяем наличие birth_date_part
                  // birth_date_part имеет заданный фиксированный формат, значит когда мы явно указываем
                  // на символы 6, 7, 8, и 9 мы берем значение года
                  let year: Nullable<number> = null;

                  if (dateOfBirth) {
                    year = dateOfBirth.getFullYear();
                  } else if (birth_date_part) {
                    year = parseInt(
                      `${birth_date_part[6]}${birth_date_part[7]}${birth_date_part[8]}${birth_date_part[9]}`,
                    );
                  }

                  return year ? `${year}` : '';
                },
              )}
            </div>

            <div style={{ marginLeft: 'auto' }}>
              <span>5. Пол </span>

              <span
                style={{
                  textDecoration: values.gender === Gender.Male ? 'underline' : undefined,
                }}
              >
                муж.
              </span>

              <span>/</span>

              <span
                style={{
                  textDecoration: values.gender === Gender.Female ? 'underline' : undefined,
                }}
              >
                жен.
              </span>
            </div>
          </div>

          {/* место рождения */}

          <div style={PRINT_STYLES.line}>
            <div>6. Место рождения: страна</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                fontSize: 6,
                flexGrow: 1,
              }}
            >
              {birthCountry?.name_ru}
            </div>
          </div>

          {/* регион */}

          <div style={PRINT_STYLES.line}>
            <div>регион</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                fontSize: 6,
                flexGrow: 1,
              }}
            >
              {values.documents?.[0]?.birth_place_region2}
            </div>
          </div>

          {/* район */}

          <div style={PRINT_STYLES.line}>
            <div>район</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                fontSize: 6,
                flexGrow: 1,
              }}
            >
              {values.documents?.[0]?.birth_place_region}
            </div>
          </div>

          {/* город */}

          <div style={PRINT_STYLES.line}>
            <div>город</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                fontSize: 6,
                flexGrow: 1,
              }}
            >
              {values.documents?.[0]?.birth_place}
            </div>
          </div>

          {/* населенный пункт */}

          <div style={PRINT_STYLES.line}>
            <div>населенный пункт</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                fontSize: 6,
                flexGrow: 1,
              }}
            >
              {values.documents?.[0]?.birth_place2}
            </div>
          </div>

          {/* гражданство */}

          <div style={PRINT_STYLES.line}>
            <div>7. Гражданство</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {citizenship?.name_ru}
            </div>
          </div>

          {/* документ, удостоверяющий личность */}

          <div style={PRINT_STYLES.line}>
            <div>8. Документ, удостоверяющий личность: вид</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {DOCS_NAME_MAP.has(values.documents?.[0]?.document_type_enum.id)
                ? DOCS_NAME_MAP.get(values.documents?.[0]?.document_type_enum.id)
                : values.documents?.[0]?.document_type_enum.name_ru}
            </div>
          </div>

          <div style={PRINT_STYLES.line}>
            <div style={PRINT_STYLES.line}>
              <div>серия&nbsp;&nbsp;</div>

              <div
                style={{
                  ...PRINT_STYLES.borderField,
                  width: 90,
                }}
              >
                {values.documents?.[0]?.doc_serial}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div>номер&nbsp;&nbsp;</div>

              <div
                style={{
                  ...PRINT_STYLES.borderField,
                  width: 90,
                }}
              >
                {values.documents?.[0]?.doc_number}
              </div>
            </div>

            <div
              style={{
                ...PRINT_STYLES.line,
                marginLeft: 'auto',
              }}
            >
              <div>выдан&nbsp;&nbsp;</div>

              {this.renderDateBlock(
                () => {
                  const date = values.documents?.[0]?.doc_issue_date
                    ? new Date(values.documents?.[0]?.doc_issue_date)?.getDate()
                    : null;

                  return date ? `${date && date < 10 ? 0 : ''}${date}` : '';
                },
                () => {
                  const month = values.documents?.[0]?.doc_issue_date
                    ? new Date(values.documents?.[0]?.doc_issue_date).getMonth()
                    : null;

                  return month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}` : '';
                },
                () => {
                  const year = values.documents?.[0]?.doc_issue_date
                    ? new Date(values.documents?.[0]?.doc_issue_date).getFullYear()
                    : null;

                  return year ? `${year}` : '';
                },
              )}
            </div>
          </div>

          {/* срок действия */}

          <div style={PRINT_STYLES.line}>
            <div>Срок действия (для заграничного паспорта)</div>

            <div
              style={{
                marginLeft: 'auto',
              }}
            >
              {this.renderDateBlock(
                () => {
                  const date = values.documents?.[0]?.doc_expire_date
                    ? new Date(values.documents?.[0]?.doc_expire_date)?.getDate()
                    : null;

                  return date ? `${date && date < 10 ? 0 : ''}${date}` : '';
                },
                () => {
                  const month = values.documents?.[0]?.doc_expire_date
                    ? new Date(values.documents?.[0]?.doc_expire_date).getMonth()
                    : null;

                  return month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}` : '';
                },
                () => {
                  const year = values.documents?.[0]?.doc_expire_date
                    ? new Date(values.documents?.[0]?.doc_expire_date).getFullYear()
                    : null;

                  return year ? `${year}` : '';
                },
              )}
            </div>
          </div>

          {/* орган, выдавший документ */}

          <div style={PRINT_STYLES.line}>
            <div>орган, выдавший документ</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                fontSize: 8,
                flexGrow: 1,
              }}
            >
              {`${values.documents?.[0]?.doc_issue_authority_organ?.name_ru ?? ''}`.substring(0, 55)}
            </div>
          </div>

          <div style={PRINT_STYLES.line}>
            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
                fontSize: 8,
              }}
            >
              {`${values.documents?.[0]?.doc_issue_authority_organ?.name_ru ?? ''}`.substring(55)}
            </div>

            <div style={PRINT_STYLES.line}>
              <div>&nbsp;&nbsp;код&nbsp;&nbsp;</div>

              <div
                style={{
                  ...PRINT_STYLES.borderField,
                  flexGrow: 1,
                }}
              >
                {values.documents?.[0]?.doc_issue_code}
              </div>
            </div>
          </div>

          {/* законный представитель */}

          <div style={PRINT_STYLES.line}>
            <div>
              <sup>1</sup> 9. Законный представитель
            </div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {`${legalRepresentative?.lname ?? ''} ${legalRepresentative?.mname ?? ''} ${
                legalRepresentative?.fname ?? ''
              }`.substring(0, 30)}
            </div>
          </div>

          <div
            style={{
              textAlign: 'center',
            }}
          >
            (фамилия, имя, отчество (при наличиии))
          </div>

          <div
            style={{
              ...PRINT_STYLES.borderField,
              width: '100%',
            }}
          >
            {`${legalRepresentative?.lname ?? ''} ${legalRepresentative?.mname ?? ''} ${
              legalRepresentative?.fname ?? ''
            }`.substring(30)}
          </div>

          {/* место жительства */}

          <div>10. Место жительства (при отсутствии места жительства - место пребывания):</div>

          {/* страна */}

          <div style={PRINT_STYLES.line}>
            <div>страна</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              Россия
            </div>
          </div>

          {/* регион */}

          <div style={PRINT_STYLES.line}>
            <div>регион</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {regionValue}
            </div>
          </div>

          {/* район */}

          <div style={PRINT_STYLES.line}>
            <div>район</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {areaValue}
            </div>
          </div>

          {/* город */}

          <div style={PRINT_STYLES.line}>
            <div>город</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {cityValue}
            </div>
          </div>

          {/* населенный пункт */}

          <div style={PRINT_STYLES.line}>
            <div>населенный пункт</div>

            <div
              style={{
                ...PRINT_STYLES.borderField,
                flexGrow: 1,
              }}
            >
              {settlementValue}
            </div>
          </div>

          {/* улица, дом, корпус, кв */}

          <div style={PRINT_STYLES.line}>
            <div
              style={{
                ...PRINT_STYLES.line,
                flexGrow: 1,
              }}
            >
              <div>ул.&nbsp;&nbsp;</div>

              <div
                style={{
                  ...PRINT_STYLES.borderField,
                  fontSize: 8,
                  flexGrow: 1,
                }}
              >
                {streetValue}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div>дом&nbsp;&nbsp;</div>

              <div
                style={{
                  ...PRINT_STYLES.borderField,
                  width: 60,
                }}
              >
                {values.reg_info?.housing ?? values.reg_info?.prev_housing}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div>корп.&nbsp;&nbsp;</div>

              <div
                style={{
                  ...PRINT_STYLES.borderField,
                  width: 60,
                }}
              >
                {values.reg_info?.housing2 ?? values.reg_info?.prev_housing2}
              </div>
            </div>

            <div style={PRINT_STYLES.line}>
              <div>кв.&nbsp;&nbsp;</div>

              <div
                style={{
                  ...PRINT_STYLES.borderField,
                  width: 60,
                }}
              >
                {values.reg_info?.flat ?? values.reg_info?.prev_flat}
              </div>
            </div>
          </div>

          {/* прибыл, выбыл */}

          <div style={PRINT_STYLES.line}>
            <div style={PRINT_STYLES.line}>
              <div>11. Прибыл&nbsp;&nbsp;</div>

              {this.renderDateBlock(
                () => {
                  const date = values.stay_info.stay_from ? new Date(values.stay_info.stay_from)?.getDate() : null;

                  return date ? `${date && date < 10 ? 0 : ''}${date}` : '';
                },
                () => {
                  const month = values.stay_info.stay_from ? new Date(values.stay_info.stay_from).getMonth() : null;

                  return month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}` : '';
                },
                () => {
                  const year = values.stay_info.stay_from ? new Date(values.stay_info.stay_from).getFullYear() : null;

                  return year ? `${year}` : '';
                },
              )}
            </div>

            <div
              style={{
                ...PRINT_STYLES.line,
                marginLeft: 'auto',
              }}
            >
              <div>Выбыл&nbsp;&nbsp;</div>

              {this.renderDateBlock(
                () => {
                  const date = values.stay_info.stay_till ? new Date(values.stay_info.stay_till)?.getDate() : null;

                  return date ? `${date && date < 10 ? 0 : ''}${date}` : '';
                },
                () => {
                  const month = values.stay_info.stay_till ? new Date(values.stay_info.stay_till).getMonth() : null;

                  return month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}` : '';
                },
                () => {
                  const year = values.stay_info.stay_till ? new Date(values.stay_info.stay_till).getFullYear() : null;

                  return year ? `${year}` : '';
                },
              )}
            </div>
          </div>

          {/* зарегистрирован/подпись */}

          <div style={PRINT_STYLES.line}>
            <div style={PRINT_STYLES.line}>
              <div>12. Зарегистрирован&nbsp;&nbsp;</div>

              {this.renderDateBlock(
                () => {
                  const date = values.stay_info.stay_from ? new Date(values.stay_info.stay_from)?.getDate() : null;

                  return date ? `${date && date < 10 ? 0 : ''}${date}` : '';
                },
                () => {
                  const month = values.stay_info.stay_from ? new Date(values.stay_info.stay_from).getMonth() : null;

                  return month || month === 0 ? `${month + 1 < 10 ? 0 : ''}${month + 1}` : '';
                },
                () => {
                  const year = values.stay_info.stay_from ? new Date(values.stay_info.stay_from).getFullYear() : null;

                  return year ? `${year}` : '';
                },
              )}
            </div>

            <div
              style={{
                ...PRINT_STYLES.line,
                marginLeft: 'auto',
                flexGrow: 1,
              }}
            >
              <div>&nbsp;Подпись&nbsp;</div>

              <div
                style={{
                  ...PRINT_STYLES.borderField,
                  flexGrow: 1,
                }}
              />
            </div>
          </div>

          <br />

          <div
            style={{
              ...PRINT_STYLES.borderField,

              width: 120,
            }}
          />

          <div>
            <sup>1</sup>

            <span
              style={{
                fontSize: 7.5,
              }}
            >
              Заполняется при регистрации несовершеннолетних детей и граждан, находящихся под опекой и попечительством.
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        style={{
          padding: '0 0 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div
            style={{
              height: 515,
              flexShrink: 0,
              flexGrow: 0,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                transform: 'rotate(90deg)',
              }}
            >
              {this.renderContent()}
            </div>
          </div>

          <div
            style={{
              height: 515,
              flexShrink: 0,
              flexGrow: 0,
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                transform: 'rotate(90deg)',
              }}
            >
              {this.renderContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { QuestionnaireReport };
