import { differenceInCalendarDays, isFuture } from 'date-fns';

import { PersonDocument } from '@/stores/PersonsStore';

export function validateMigrationCardValues(values: Partial<PersonDocument> = {}): Record<string, boolean> {
  return {
    doc_number: values.doc_number?.length === 0 ?? false,
    doc_issue_date: !values.doc_issue_date,
    doc_expire_date: !values.doc_expire_date,
    entry_point_id: !values.entry_point_id,
    visit_purpose_id: !values.visit_purpose_id,
  };
}

export function checkMigrationCardData(values?: Partial<PersonDocument>): Record<string, string> {
  const newErrors: Record<string, string> = {};

  if (!values) {
    return newErrors;
  }

  if (values.doc_number && isNaN(+values.doc_number)) {
    newErrors.doc_number = 'Поле должно содержать только цифры';
  }

  if (values.doc_issue_date) {
    if (isFuture(new Date(values.doc_issue_date))) {
      newErrors.doc_issue_date = 'Дата въезда в РФ не может быть позднее текущей даты';
    }

    if (values.doc_expire_date) {
      if (differenceInCalendarDays(new Date(values.doc_issue_date), new Date(values.doc_expire_date)) > 0) {
        newErrors.doc_expire_date = 'Срок пребывания не может быть ранее Даты въезда в РФ';
      }
    }
  }

  return newErrors;
}
