import React, { ChangeEvent, Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { notificationAPI } from '@/api/notificationAPI';
import { Citizen } from '@/constants';
import { Address } from '@/entities/address';
import { Face } from '@/entities/face';
import { useToggle } from '@/hooks/useToggle/useToggle';
import { IncomingReport } from '@/shared/PrintForms/IncomingReport/IncomingReport';
import { OutcomingReport } from '@/shared/PrintForms/OutcomingReport/OutcomingReport';
import { QuestionnaireReport } from '@/shared/PrintForms/QuestionnaireReport/QuestionnaireReport';
import { Person, PersonDetail, PersonStatus, PrintImageType } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps } from '@/ui-kit/components';

import PdfFormItem from './PdfFormItem/PdfFormItem';
import { PrintImagesWrapper } from './PrintModal.styles';
import SelectFaceModal from './SelectFaceModal/SelectFaceModal';

type PersonToPrint = Person & {
  legal_representative_person?: Nullable<PersonDetail>;
  regAddress?: Nullable<Address>;
};

function PrintModal(props: ContentModalProps) {
  const { open, onClose } = props;

  const {
    selectedPersons,
    getPersonById,
    setStatusForManyPersons,
    getManyDocumentsByPersonIds,
    isPrinting,
    printSelectedPersonsDocs,
    printSelectedPersonsImages,
    resetAllSelectedPersons,
  } = useStore('personsStore');
  const { countries, pdfFormTypes, getStreetObjectById } = useStore('dictionariesStore');
  const { setGlobalFetchingState, userCompany } = useStore('signInStore');

  const [selectedPdfFormTypes, setSelectedPdfFormTypes] = useState<Set<number>>(new Set());
  const [selectedImageTypes, setSelectedImageTypes] = useState<Set<number>>(new Set());

  // -------------------------------------------------------------------------------------------------------------------
  const [shouldPrintIncomingReport, , disableIncomingReport, toggleShouldRenderIncomingReport] = useToggle();
  const [shouldPrintOutcomingReport, , disableOutcomingReport, toggleShouldRenderOutcomingReport] = useToggle();
  const [shouldPrintQuestionare, , disableQuestionare, toggleShouldRenderQuestionare] = useToggle();
  const [isSelectFaceModalOpen, openFaceModalOpen, closeFaceModalOpen] = useToggle();
  const [face, setFace] = useState<Nullable<Face>>(null);
  const [reportPersons, setReportPersons] = useState<PersonToPrint[]>([]);
  const reportToPrintRef = useRef(null);
  const onPrintReport = useReactToPrint({
    content: () => reportToPrintRef.current,
  });
  // -------------------------------------------------------------------------------------------------------------------

  function onSelectPdfFormType(id: number) {
    const newSelectedPdfFormTypes = new Set(selectedPdfFormTypes);

    if (newSelectedPdfFormTypes.has(id)) {
      newSelectedPdfFormTypes.delete(id);
    } else {
      newSelectedPdfFormTypes.add(id);
    }

    setSelectedPdfFormTypes(newSelectedPdfFormTypes);
  }

  function onSelectImageType(e: ChangeEvent<HTMLInputElement>) {
    const id = +e.target.value;

    const newSelectedImageTypes = new Set(selectedImageTypes);

    if (newSelectedImageTypes.has(id)) {
      newSelectedImageTypes.delete(id);
    } else {
      newSelectedImageTypes.add(id);
    }

    setSelectedImageTypes(newSelectedImageTypes);
  }

  function printReport(personsToPrint: Person[]) {
    setTimeout(() => {
      onPrintReport?.();

      setStatusForManyPersons(
        personsToPrint.map(({ id }) => id),
        PersonStatus.Registered,
      ).then();

      disableIncomingReport();
      disableOutcomingReport();
      disableQuestionare();

      setReportPersons([]);
    }, 0);
  }

  function onConfirmFace(faceId: number) {
    const face = userCompany?.company?.faces?.find(({ id }) => id === faceId);

    if (face) {
      setFace(face);

      closeFaceModalOpen();

      proceedPrint(reportPersons);
    }
  }

  async function proceedPrint(personsToPrint: Person[]) {
    setGlobalFetchingState(true);

    try {
      if (shouldPrintIncomingReport || shouldPrintOutcomingReport || shouldPrintQuestionare) {
        printReport(personsToPrint);
      }

      if (selectedPdfFormTypes.size > 0) {
        const pdfTypesIds = [...selectedPdfFormTypes.values()];

        await printSelectedPersonsDocs(pdfTypesIds);
      }

      if (selectedImageTypes.size > 0) {
        const imageTypesIds = [...selectedImageTypes.values()];

        await printSelectedPersonsImages(imageTypesIds);
      }

      resetAllSelectedPersons();

      onClose();
    } catch (e) {
      notificationAPI.error(e);
    }

    setGlobalFetchingState(false);
  }

  async function onPrintHandler() {
    try {
      if (userCompany) {
        const ids = [...selectedPersons.keys()];
        const persons: PersonDetail[] = await Promise.all(ids.map((id: number) => getPersonById(id))).then();

        const personsDocuments = await getManyDocumentsByPersonIds(persons.map(({ id }: PersonDetail) => id));

        const personsToPrint = await Promise.all(
          persons.map(async (person: PersonDetail) => {
            let regAddress;

            if (person.reg_info?.fias_code2) {
              regAddress = await getStreetObjectById(person.reg_info.fias_code2, person.reg_info.region_code);
            } else if (person.reg_info?.prev_fias_code2) {
              regAddress = await getStreetObjectById(person.reg_info.prev_fias_code2, person.reg_info.prev_region_code);
            }

            return {
              ...person,
              documents: personsDocuments[person.id],
              regAddress,
              legal_representative_person: person.legal_representative_id
                ? await getPersonById(person.legal_representative_id)
                : null,
            };
          }),
        );

        setReportPersons(personsToPrint as unknown as PersonToPrint[]);

        if (shouldPrintIncomingReport && userCompany.company.faces && userCompany.company.faces.length > 1) {
          openFaceModalOpen();

          return;
        } else {
          if (userCompany.company.faces && userCompany.company.faces.length === 1) {
            setFace(userCompany.company.faces[0]);
          }
        }

        proceedPrint(personsToPrint as unknown as PersonToPrint[]);
      }
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  const onCloseWithResetHandler = useCallback(() => {
    setSelectedPdfFormTypes(new Set());
    onClose();
  }, []);

  const onToggleSelectedImages = useCallback(() => {
    setSelectedImageTypes(
      selectedImageTypes.size === 0
        ? new Set([PrintImageType.IdCard, PrintImageType.RightOfStay, PrintImageType.Migration])
        : new Set(),
    );
  }, [selectedImageTypes]);

  const isPrintEnabled = useMemo(
    () =>
      selectedPdfFormTypes.size > 0 ||
      selectedImageTypes.size > 0 ||
      shouldPrintIncomingReport ||
      shouldPrintOutcomingReport ||
      shouldPrintQuestionare,
    [
      selectedPdfFormTypes,
      selectedImageTypes,
      shouldPrintIncomingReport,
      shouldPrintOutcomingReport,
      shouldPrintQuestionare,
    ],
  );

  const pdfFormTypesToPrint = useMemo(
    () => pdfFormTypes.filter((pdfFormType) => pdfFormType.code === 4),
    [JSON.stringify(pdfFormTypes)],
  );

  useEffect(() => {
    if (open) {
      setSelectedPdfFormTypes(new Set());
      setSelectedImageTypes(new Set());
    }
  }, [open]);

  return (
    <ContentModal disableEscapeKeyDown={false} onClose={onCloseWithResetHandler} open={open}>
      <ContentModal.Header onClose={onCloseWithResetHandler}>Выберите типы документов</ContentModal.Header>

      <ContentModal.Body>
        <FormControlLabel
          control={
            <Checkbox checked={selectedImageTypes.size === 3} color="primary" onChange={onToggleSelectedImages} />
          }
          disabled={isPrinting}
          label="Изображения документов"
        />

        <PrintImagesWrapper>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedImageTypes.has(PrintImageType.IdCard)}
                  color="primary"
                  onChange={onSelectImageType}
                  value={PrintImageType.IdCard}
                />
              }
              disabled={isPrinting}
              label="Удостоверение личности"
            />
          </div>

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedImageTypes.has(PrintImageType.RightOfStay)}
                  color="primary"
                  onChange={onSelectImageType}
                  value={PrintImageType.RightOfStay}
                />
              }
              disabled={isPrinting}
              label="Право пребывания"
            />
          </div>

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedImageTypes.has(PrintImageType.Migration)}
                  color="primary"
                  onChange={onSelectImageType}
                  value={PrintImageType.Migration}
                />
              }
              disabled={isPrinting}
              label="Миграционная карта"
            />
          </div>
        </PrintImagesWrapper>

        {pdfFormTypesToPrint.length > 0 &&
          pdfFormTypesToPrint.map((pdfFormType) => (
            <PdfFormItem
              disabled={isPrinting}
              key={pdfFormType.id}
              onChange={onSelectPdfFormType}
              pdfFormType={pdfFormType}
              selected={selectedPdfFormTypes.has(pdfFormType.id)}
            />
          ))}

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldPrintIncomingReport}
                color="primary"
                onChange={toggleShouldRenderIncomingReport}
              />
            }
            disabled={isPrinting}
            label="Уведомление о прибытии ИГ"
          />
        </div>

        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldPrintOutcomingReport}
                color="primary"
                onChange={toggleShouldRenderOutcomingReport}
              />
            }
            disabled={isPrinting}
            label="Уведомление об убытии ИГ"
          />
        </div>

        <div>
          <FormControlLabel
            control={
              <Checkbox checked={shouldPrintQuestionare} color="primary" onChange={toggleShouldRenderQuestionare} />
            }
            disabled={isPrinting}
            label="Анкета гражданина РФ"
          />
        </div>

        {userCompany?.company && (
          <Fragment>
            <div style={{ display: 'none' }}>
              <div ref={reportToPrintRef} style={{ padding: 0 }}>
                {reportPersons.map((person: PersonToPrint, index) => (
                  <div key={person.id}>
                    {shouldPrintIncomingReport &&
                      person.citizenship !== Citizen.Russia &&
                      person.citizenship !== Citizen.USSR && (
                        <IncomingReport
                          citizenship={countries.find(({ id }) => id === person.citizenship)}
                          company={userCompany.company}
                          face={face}
                          factAddress={userCompany.factAddress}
                          urAddress={userCompany.urAddress}
                          values={person as unknown as PersonDetail}
                        />
                      )}

                    {shouldPrintOutcomingReport &&
                      person.citizenship !== Citizen.Russia &&
                      person.citizenship !== Citizen.USSR && (
                        <OutcomingReport
                          company={userCompany.company}
                          factAddress={userCompany.factAddress}
                          urAddress={userCompany.urAddress}
                          values={person as unknown as PersonDetail}
                        />
                      )}

                    {shouldPrintQuestionare &&
                      (person.citizenship === Citizen.Russia || person.citizenship === Citizen.USSR) && (
                        <Fragment>
                          <QuestionnaireReport
                            birthCountry={countries.find(
                              ({ id }) => id === (person as unknown as PersonDetail).documents?.[0]?.birth_country_id,
                            )}
                            citizenship={countries.find(({ id }) => id === person.citizenship)}
                            company={userCompany.company}
                            legalRepresentative={person.legal_representative_person}
                            regAddress={person.regAddress}
                            urAddress={userCompany.urAddress}
                            values={person as unknown as PersonDetail}
                          />

                          {index !== reportPersons.length - 1 && (
                            <div
                              style={{
                                pageBreakAfter: 'always',
                              }}
                            />
                          )}
                        </Fragment>
                      )}
                  </div>
                ))}
              </div>
            </div>

            <SelectFaceModal
              faces={userCompany.company.faces}
              onCancel={closeFaceModalOpen}
              onConfirm={onConfirmFace}
              open={isSelectFaceModalOpen}
            />
          </Fragment>
        )}
      </ContentModal.Body>

      <ContentModal.Footer>
        <Button color="primary" disabled={isPrinting} onClick={onCloseWithResetHandler} type="reset">
          Отмена
        </Button>

        <ButtonLoader disabled={!isPrintEnabled} isLoading={isPrinting} onClick={onPrintHandler} type="submit">
          Печать
        </ButtonLoader>
      </ContentModal.Footer>
    </ContentModal>
  );
}

export default memo(observer(PrintModal));
