import grey from '@mui/material/colors/grey';
import styled from '@mui/material/styles/styled';

export const UsersRoot = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const UsersList = styled('div')`
  position: relative;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${grey['500']};
  flex-grow: 1;
  overflow: hidden;
`;

export const UsersListContent = styled('div')`
  height: 100%;
  overflow: auto;
  max-height: 200px;
`;
