import { alpha } from '@mui/material';
import common from '@mui/material/colors/common';
import grey from '@mui/material/colors/grey';
import yellow from '@mui/material/colors/yellow';
import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';
import TableRow from '@mui/material/TableRow';

export const BookingRowRoot = styled(TableRow, {
  shouldForwardProp: (name) => name !== 'isHighlighted',
})(({ isHighlighted }: { isHighlighted?: boolean }) => {
  const highlightStyles = css`
    background-color: ${yellow['100']};
  `;

  return css`
    height: 52px;
    transition: background-color 3500ms linear;

    ${isHighlighted && highlightStyles}

    &:hover {
      background-color: transparent;

      td {
        background-color: ${alpha(common.black, 0.04)};
      }
    }
  `;
});

export const KSListRowRowName = styled('div')`
  position: relative;
`;

export const KSListRowSubName = styled('div')`
  font-size: 11px;
  top: 16px;
  color: ${grey['500']};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const KSListStayTill = styled('div')`
  text-align: right;
`;
