import { CompaniesStore } from './CompaniesStore';
import { DeviceLicensesStore } from './DeviceLicensesStore';
import { DevicesStore } from './DevicesStore';
import { DictionariesStore } from './DictionariesStore';
import { GroupsStore } from './GroupsStore';
import { PersonsStore } from './PersonsStore';
import { ReportsStore } from './ReportsStore';
import { RolesStore } from './RolesStore';
import { SignInStore } from './SignInStore/SignInStore';
import { UsersStore } from './UsersStore';

export class Store {
  socket: WebSocket;
  readonly signInStore: SignInStore;
  readonly usersStore: UsersStore;
  readonly rolesStore: RolesStore;
  readonly deviceLicensesStore: DeviceLicensesStore;
  readonly devicesStore: DevicesStore;
  readonly companiesStore: CompaniesStore;
  readonly dictionariesStore: DictionariesStore;
  readonly groupsStore: GroupsStore;
  readonly personsStore: PersonsStore;
  readonly reportsStore: ReportsStore;

  constructor() {
    this.socket = new WebSocket(`wss://${import.meta.env.EMIS_API_DOMAIN}${import.meta.env.EMIS_API_WS_URI}`);

    this.signInStore = new SignInStore(this);
    this.usersStore = new UsersStore();
    this.rolesStore = new RolesStore();
    this.deviceLicensesStore = new DeviceLicensesStore();
    this.devicesStore = new DevicesStore();
    this.companiesStore = new CompaniesStore();
    this.dictionariesStore = new DictionariesStore();
    this.groupsStore = new GroupsStore();
    this.personsStore = new PersonsStore(this);
    this.reportsStore = new ReportsStore();
  }
}

export const store = new Store();
