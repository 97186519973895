import common from '@mui/material/colors/common';
import styled from '@mui/material/styles/styled';

export const ImagesPanelRoot = styled('div')`
  position: relative;
  flex-shrink: 0;
  overflow: auto;
  height: 100%;
  padding-right: 32px;
`;

export const ImagesPanelTabs = styled('div')`
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${common.white};
`;
