import { Group } from '@/entities/group';

export function recursiveUpdateGroup(groups: Group[], id: number, callback: (treeNode: Group) => Group): Group[] {
  return [...groups].map((group) => {
    if (group.id === id) {
      return callback(group);
    }

    const newGroup = group.toJS();

    return new Group({
      ...newGroup,
      children: recursiveUpdateGroup(group.children, id, callback),
    });
  });
}

export function recursiveFindGroup(groups: Group[], id: number) {
  let group: Group | undefined;

  groups.forEach((groupItem) => {
    if (groupItem.id === id) {
      group = groupItem;
    }

    if (!group && groupItem.children.length > 0) {
      group = recursiveFindGroup(groupItem.children, id);
    }
  });

  return group;
}
