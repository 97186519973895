import styled from '@mui/material/styles/styled';

import { EMIS_THEME } from '@/App.style';

export const GreetingRoot = styled('div')`
  ${EMIS_THEME.breakpoints.up('md')} {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const GreetingNext = styled('div')`
  text-align: center;

  ${EMIS_THEME.breakpoints.up('md')} {
    margin-top: 72px;
  }

  ${EMIS_THEME.breakpoints.down('md')} {
    margin-top: 16px;
  }
`;

export const GreetingEnglish = styled('div')`
  margin-top: 16px;
`;

export const GreetingFooter = styled('div')`
  bottom: 18px;
  left: 0;
  width: 100%;

  ${EMIS_THEME.breakpoints.down('md')} {
    margin-top: 48px;
  }

  ${EMIS_THEME.breakpoints.up('md')} {
    position: absolute;
  }
`;

export const GreetingFooterDescription = styled('div')`
  max-width: 400px;
  margin: 0 auto 52px;
`;

export const GreetingFooterImages = styled('div')`
  text-align: center;
`;

export const GreetingFooterImage = styled('img')`
  margin: 0 24px;
`;
