import React, { ChangeEvent, Fragment, memo, useCallback, useEffect, useMemo } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { DocumentType } from '@/constants';
import { DocumentImage, PersonDocument, PersonStatus } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { convertToClearBase64 } from '@/utils';

import { usePersonPageStore } from '../../../PersonPageStore';

import { DocumentImagesHeader, DocumentImagesUploadLabel } from './DocumentImages.style';
import ImageBlock from './ImageBlock/ImageBlock';

interface DocumentImagesProps {
  currentTab: Nullable<DocumentType>;
  values: PersonDocument;
  setValues: (values: PersonDocument) => void;
  onCopyImage: (target: DocumentType, image: DocumentImage) => void;
  status: PersonStatus;
  citizenship: Nullable<number>;
}

function DocumentImages(props: DocumentImagesProps) {
  const { currentTab, values, setValues, onCopyImage } = props;
  const { status, isRussianCitizenship, isExported, isDeleted, scanSingleImage } = usePersonPageStore();

  const { selectedDevice } = useStore('personsStore');

  const onUploadHandler = useCallback(
    ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
      const files = {
        ...currentTarget.files,
      };

      if (files) {
        Object.keys(files).forEach((key) => {
          if (files?.[key]) {
            const reader = new FileReader();

            reader.onloadend = () => {
              setValues({
                ...values,
                images: values.images.concat([
                  {
                    data: convertToClearBase64(reader.result as string),
                    not_export: false,
                    image_upload_type: 3,
                  },
                ]),
              });
            };

            reader.readAsDataURL(files[key]);
          }
        });
      }

      currentTarget.value = '';
    },
    [JSON.stringify(values)],
  );

  const onEditImage = useCallback(
    (index: number, imageData: string) => {
      const images = [...toJS(values.images)];

      images[index].data = imageData;

      setValues({
        ...values,
        images,
      });
    },
    [setValues, JSON.stringify(values)],
  );

  const onDeleteImage = useCallback(
    (index: number) => {
      const images = [...toJS(values.images)];

      images.splice(index, 1);

      setValues({
        ...values,
        images,
      });
    },
    [setValues, JSON.stringify(values)],
  );

  const onChangeExport = useCallback(
    (index: number, checked: boolean) => {
      const images = [...toJS(values.images)];

      images[index].not_export = checked;

      setValues({
        ...values,
        images,
      });
    },
    [setValues, JSON.stringify(values)],
  );

  const sortedImages = useMemo(
    () =>
      (values.images ?? [])?.slice().sort((a: DocumentImage, b: DocumentImage) => {
        if (a.id && b.id && a.id < b.id) return -1;
        if (a.id && b.id && a.id > b.id) return 1;

        return 0;
      }),
    [JSON.stringify(values)],
  );

  const onScanFromKeyboard = useCallback(
    ({ key, which, altKey }: KeyboardEvent) => {
      // приходится брать значение "selectedDevice" из стора
      // потому что при "addEventListener" оно записалось с другим this
      if ((key === 'b' || which === 66) && altKey && selectedDevice !== 0) {
        scanSingleImage();
      }
    },
    [selectedDevice],
  );

  useEffect(() => {
    window.addEventListener('keyup', onScanFromKeyboard);

    return () => {
      window.removeEventListener('keyup', onScanFromKeyboard);
    };
  }, [onScanFromKeyboard]);

  return (
    <Fragment>
      {!isDeleted && !isExported && (
        <DocumentImagesHeader>
          <div>
            <input id="image-button-file" multiple name="images" onChange={onUploadHandler} type="file" />

            <DocumentImagesUploadLabel htmlFor="image-button-file">
              <IconButton color="primary" component="span">
                <Tooltip title="Добавить изображение с компьютера">
                  <AttachFileIcon />
                </Tooltip>
              </IconButton>
            </DocumentImagesUploadLabel>
          </div>

          <IconButton color="primary" disabled={selectedDevice === 0} onClick={scanSingleImage}>
            <Tooltip title="Сканировать (Alt + B)">
              <CameraAltIcon />
            </Tooltip>
          </IconButton>
        </DocumentImagesHeader>
      )}

      {sortedImages.map((image: DocumentImage, index: number) => (
        <ImageBlock
          currentTab={currentTab}
          disableImageCopy={isRussianCitizenship}
          image={image}
          index={index}
          key={`${image.id ?? image.data ?? image.device_op_id}`}
          onChangeExport={onChangeExport}
          onCopyImage={onCopyImage}
          onDeleteImage={onDeleteImage}
          onEditImage={onEditImage}
          status={status}
        />
      ))}
    </Fragment>
  );
}

export default memo(observer(DocumentImages));
