import React, { memo, ReactNode, SyntheticEvent } from 'react';

import hiddenOverlay from '@/assets/hidden-overlay.png';
import { useToggle } from '@/hooks/useToggle/useToggle';

import { ProtectedTextOverlay, ProtectedTextRoot } from './ProtectedText.styles';

interface ProtectedTextProps {
  title: ReactNode;
}

function ProtectedText(props: ProtectedTextProps) {
  const { title } = props;

  const [isShowed, shotTitle] = useToggle(false);

  function onClick(e: SyntheticEvent) {
    e.stopPropagation();

    shotTitle();
  }

  return (
    <ProtectedTextRoot>
      {!isShowed && <ProtectedTextOverlay onClick={onClick} style={{ backgroundImage: `url(${hiddenOverlay})` }} />}

      {title}
    </ProtectedTextRoot>
  );
}

export default memo(ProtectedText);
