import { addMinutes, format, isValid } from 'date-fns';

import { DATE_FOR_SERVER_FORMAT } from '@/constants';

export function getValidDateValue(date: Nullable<Date | string>): Nullable<string> {
  if (!date || !isValid(new Date(date))) {
    return null;
  }

  /*
   * когда значение выбирается из дейтпикера, оно выбирается с текущим временем,
   * тут мы сбрасываем значения времени, потому что они нам не важны, но без них
   * мы сможем корректно выставить таймзону
   * */
  const parsedDate = new Date(format(new Date(date), DATE_FOR_SERVER_FORMAT));
  const timezoneOffset = new Date().getTimezoneOffset();

  if (!isValid(parsedDate)) {
    return null;
  }

  /*
   * добавляет смещение по таймзоне, потому что если вводить значение дат руками (не через дейтпикер),
   * то таймзона будет по умолчанию UTC 0, соответсвенно new Date() вернет неверное значение, со смещением назад
   * */
  return addMinutes(parsedDate, Math.abs(timezoneOffset)).toISOString();
}
