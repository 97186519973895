import React, { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { DictionaryBasic } from '@/entities/dictionary';
import { PersonDocument } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField, DatepickerField, InputField, SelectField } from '@/ui-kit/components';

import { usePersonPageStore } from '../../../PersonPageStore';

import { checkMigrationCardData, validateMigrationCardValues } from './MigrationCard.utils';

function MigrationCard() {
  const { migrationCard, setMigrationCard } = usePersonPageStore();
  const { isExported, isDeleted } = usePersonPageStore();
  const { entryGates, visitGoals } = useStore('dictionariesStore');

  const onChangeMigrationCardValue = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;

    setMigrationCard({
      [name]: value,
    } as Partial<PersonDocument>);
  }, []);

  const onChangeDocIssueDate = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setMigrationCard({
      doc_issue_date: target.value,
    });
  }, []);

  const onChangeExpire = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setMigrationCard({
      doc_expire_date: target.value,
    });
  }, []);

  const onEntryGateChangeHandler = useCallback((entryGate: unknown) => {
    setMigrationCard({
      entry_point_id: entryGate ? (entryGate as DictionaryBasic).id : null,
    });
  }, []);

  const renderFilterOption = useCallback(
    (options: DictionaryBasic[], { inputValue }: FilterOptionsState<string>) =>
      options.filter(({ name_ru }) => name_ru.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
    [],
  );

  const getEntryGateOptionLabel = useCallback(({ name_ru }: DictionaryBasic) => name_ru, []);

  const errors = useMemo(() => validateMigrationCardValues(migrationCard), [JSON.stringify(migrationCard)]);
  const warning = useMemo(() => checkMigrationCardData(migrationCard), [JSON.stringify(migrationCard)]);

  if (!migrationCard) {
    return null;
  }

  return (
    <fieldset disabled={isExported || isDeleted}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <InputField
                error={errors.doc_serial}
                label="Серия"
                name="doc_serial"
                onChange={onChangeMigrationCardValue}
                value={migrationCard.doc_serial}
              />
            </Grid>

            <Grid item xs={3}>
              <InputField
                error={errors.doc_number}
                label="Номер"
                name="doc_number"
                onChange={onChangeMigrationCardValue}
                tooltip={warning.doc_number}
                value={migrationCard.doc_number}
              />
            </Grid>

            <Grid item xs={3}>
              <DatepickerField
                disabled={isExported || isDeleted}
                error={errors.doc_issue_date}
                label="Дата въезда в РФ"
                onChange={onChangeDocIssueDate}
                tooltip={warning.doc_issue_date}
                value={migrationCard.doc_issue_date}
              />
            </Grid>

            <Grid item xs={3}>
              <DatepickerField
                disabled={isExported || isDeleted}
                error={errors.doc_expire_date}
                label="Пребывание в РФ до"
                onChange={onChangeExpire}
                tooltip={warning.doc_expire_date}
                value={migrationCard.doc_expire_date}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectField
                disabled={visitGoals.length === 0 || isExported || isDeleted}
                error={errors.visit_purpose_id}
                label="Цель поездки"
                name="visit_purpose_id"
                onChange={onChangeMigrationCardValue}
                value={migrationCard.visit_purpose_id}
              >
                {visitGoals.map(({ id, name_ru }) => (
                  <MenuItem key={id} value={id}>
                    {name_ru}
                  </MenuItem>
                ))}
              </SelectField>
            </Grid>

            <Grid item xs={3}>
              <FormControl disabled={entryGates.length === 0 || isExported || isDeleted} fullWidth>
                <AutocompleteField
                  disabled={isExported || isDeleted}
                  filterOptions={renderFilterOption}
                  getOptionLabel={getEntryGateOptionLabel}
                  isError={errors.entry_point_id}
                  label="КПП въезда"
                  name="entry_point_id"
                  onChange={onEntryGateChangeHandler}
                  options={[...entryGates]}
                  value={entryGates.find(({ id }) => id === migrationCard.entry_point_id) ?? null}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </fieldset>
  );
}

export default memo(observer(MigrationCard));
