import React, { ChangeEvent, memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import { ROUTE_BOOKINGS, ROUTE_PERSON } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import SendNotificationModal from '@/shared/SendNotificationModal/SendNotificationModal';
import { PersonDetail } from '@/stores/PersonsStore';
import { useStore } from '@/stores/StoreProvider';

import { WORKPLACE_ROUTES_MAPPING, WorkplaceRoute } from './OperatorPanelTabs.constant';
import { OperatorButtons, OperatorPanelContent, OperatorTabs } from './OperatorPanelTabs.style';
import { getActiveTabIndex } from './OperatorPanelTabs.utils';
import PrintMenu from './PrintMenu/PrintMenu';
import TemplateModal from './TemplateModal/TemplateModal';

function OperatorPanelTabs() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { groupId } = useParams();
  const { persons, selectedPersons, exportSelections, getPersons } = useStore('personsStore');
  const { setGlobalFetchingState } = useStore('signInStore');

  const [tab, setActiveTab] = useState(() => getActiveTabIndex(pathname));

  const [isNotificationModalOpen, openNotificationModal, closeNotificationModal] = useToggle();

  const onChangeTab = useCallback((e: ChangeEvent<unknown>, value: number) => {
    e.preventDefault();

    const targetRoute = WORKPLACE_ROUTES_MAPPING.get(value);

    if (targetRoute) {
      navigate(targetRoute);
    }

    setActiveTab(value);
  }, []);

  const onNavigateToNewPersonPage = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      let uri = `${ROUTE_PERSON}/new`;

      if (groupId) {
        uri += `/${groupId}`;
      }

      navigate(uri, {
        replace: true,
        state: null,
      });
    },
    [groupId],
  );

  const onNavigateToNewBookingPage = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();

      let uri = `${ROUTE_BOOKINGS}/new`;

      if (groupId) {
        uri += `/${groupId}`;
      }

      navigate(uri, {
        replace: true,
        state: null,
      });
    },
    [groupId],
  );

  async function onExportHandler() {
    setGlobalFetchingState(true);

    try {
      await exportSelections();
    } catch (e) {
      notificationAPI.error(e);
    }

    setGlobalFetchingState(false);
  }

  async function onAfterSendNotification() {
    try {
      await getPersons({
        hms_status_not_in: true,
        ...queryAPI.params,
      });
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  function onOpenRegistrationFromKeyboard(e: KeyboardEvent) {
    const { key, which, altKey } = e;

    if ((key === 'r' || which === 82) && altKey) {
      onNavigateToNewPersonPage(e as unknown as SyntheticEvent);
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', onOpenRegistrationFromKeyboard);

    return () => {
      window.removeEventListener('keyup', onOpenRegistrationFromKeyboard);
    };
  }, []);

  useEffect(() => {
    setActiveTab(getActiveTabIndex(pathname));
  }, [pathname]);

  return (
    <AppBar color="default" position="static">
      <OperatorPanelContent>
        <OperatorTabs indicatorColor="primary" onChange={onChangeTab} textColor="primary" value={tab}>
          <Tab
            icon={<AssignmentIcon />}
            iconPosition="start"
            label="Регистрации"
            value={WorkplaceRoute.Registrations}
          />

          {/* <Tab icon={<BookmarksIcon />} iconPosition="start" label="Бронирования" value={WorkplaceRoute.Bookings} />*/}

          {/* <Tab*/}
          {/*  icon={<VerificationIcon />}*/}
          {/*  iconPosition="start"*/}
          {/*  label="Верификация"*/}
          {/*  value={WorkplaceRoute.Verification}*/}
          {/* />*/}

          {/* <Tab icon={<AssessmentIcon />} iconPosition="start" label="Отчеты" value={WorkplaceRoute.KSReport} />*/}

          {/* {superUser && [*/}
          {/*  <Tab*/}
          {/*    icon={<DeleteForeverIcon />}*/}
          {/*    iconPosition="start"*/}
          {/*    key="removed"*/}
          {/*    label="Удаленные"*/}
          {/*    value={WorkplaceRoute.Removed}*/}
          {/*  />,*/}
          {/* ]}*/}
        </OperatorTabs>

        {tab === 0 && (
          <OperatorButtons>
            <PrintMenu />

            <Button
              color="primary"
              disabled={selectedPersons.size === 0}
              onClick={onExportHandler}
              size="small"
              variant="outlined"
            >
              Выгрузка
            </Button>

            <TemplateModal />

            <Tooltip title="Alt + R">
              <Button color="primary" onClick={onNavigateToNewPersonPage} size="small" variant="contained">
                Зарегистрировать
              </Button>
            </Tooltip>
          </OperatorButtons>
        )}

        {tab === 1 && (
          <OperatorButtons>
            <Button
              color="primary"
              disabled={selectedPersons.size === 0}
              onClick={openNotificationModal}
              size="small"
              variant="contained"
            >
              Отправить уведомление
            </Button>

            <Button color="primary" onClick={onNavigateToNewBookingPage} size="small" variant="contained">
              Забронировать
            </Button>
          </OperatorButtons>
        )}
      </OperatorPanelContent>

      <SendNotificationModal
        onClose={closeNotificationModal}
        onSuccess={onAfterSendNotification}
        open={isNotificationModalOpen}
        persons={
          [...selectedPersons.keys()].map(
            (id) => persons.find((person) => person.id === id) || { id },
          ) as unknown as PersonDetail[]
        }
      />
    </AppBar>
  );
}

export default memo(observer(OperatorPanelTabs));
