import { License, LicenseModel } from '@/entities/license';

export class DeviceInfoModel {
  license!: LicenseModel;
  version!: string;
  db_version!: string;
  app_version!: string;
  do_barcode!: boolean;
  do_mrzocr!: boolean;
  do_rfid!: boolean;
  do_visualocr!: boolean;
  send_photo!: boolean;
  atom_opts!: boolean;
  compression_level!: number;
  active!: boolean;
}

export class DeviceInfo extends DeviceInfoModel {
  constructor(deviceInfo: DeviceInfoModel) {
    super();

    Object.keys(deviceInfo).forEach((key) => {
      switch (key) {
        case 'license':
          if (deviceInfo.license) {
            this.license = new License(deviceInfo.license);
          }

          break;
        default:
          this[key] = deviceInfo[key];

          break;
      }
    });
  }
}
