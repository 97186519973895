import React, { memo, SyntheticEvent, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from '@mui/material/Tabs';

import { DocumentType } from '@/constants';

import { usePersonPageStore } from '../../PersonPageStore';

import DeleteDocumentConfirmModal from './DeleteDocumentConfirmModal/DeleteDocumentConfirmModal';
import { DocumentsTabsContent, DocumentsTabsHeader, DocumentsTabsTab } from './DocumentsTabs.style';

function DocumentsTabs() {
  const { idCard, migrationCard, rightOfStay, isExported, isDeleted, deleteDocument, tab, setActiveTab } =
    usePersonPageStore();

  const [tabForDeletion, setTabForDeletion] = useState<Nullable<DocumentType>>(null);

  const onCloseDeleteConfirmationModal = useCallback(() => setTabForDeletion(null), []);

  const onTabChangeHandler = useCallback((e: SyntheticEvent, tab: DocumentType) => {
    e.preventDefault();

    setActiveTab(tab);
  }, []);

  const onDeleteIDCard = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();

    setTabForDeletion(DocumentType.IdCard);
  }, []);

  const onDeleteDocument = useCallback((target: Nullable<DocumentType>) => {
    let tabAfterClose = tab;

    if (target === tab) {
      if (target === DocumentType.IdCard) {
        if (rightOfStay) {
          tabAfterClose = DocumentType.RightOfStay;
        } else if (migrationCard) {
          tabAfterClose = DocumentType.Migration;
        } else {
          tabAfterClose = null;
        }
      }

      if (target === DocumentType.RightOfStay) {
        if (idCard) {
          tabAfterClose = DocumentType.IdCard;
        } else if (migrationCard) {
          tabAfterClose = DocumentType.Migration;
        } else {
          tabAfterClose = null;
        }
      }

      if (target === DocumentType.Migration) {
        if (idCard) {
          tabAfterClose = DocumentType.IdCard;
        } else if (rightOfStay) {
          tabAfterClose = DocumentType.RightOfStay;
        } else {
          tabAfterClose = null;
        }
      }
    }

    setActiveTab(tabAfterClose);
    setTabForDeletion(null);

    deleteDocument(target);
  }, []);

  return (
    <DocumentsTabsHeader>
      {tab && (
        <Tabs
          indicatorColor="primary"
          onChange={onTabChangeHandler}
          textColor="primary"
          value={tab}
          variant="scrollable"
        >
          {idCard && (
            <DocumentsTabsTab
              label={
                <DocumentsTabsContent>
                  <span>Паспорт</span>

                  {!isDeleted && !isExported && <CloseIcon fontSize="small" onClick={onDeleteIDCard} />}
                </DocumentsTabsContent>
              }
              value={DocumentType.IdCard}
            />
          )}
        </Tabs>
      )}

      <DeleteDocumentConfirmModal
        onClose={onCloseDeleteConfirmationModal}
        onConfirm={onDeleteDocument}
        open={!!tabForDeletion}
        tab={tabForDeletion}
      />
    </DocumentsTabsHeader>
  );
}

export default memo(observer(DocumentsTabs));
