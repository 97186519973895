import React, { memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { notificationAPI } from '@/api/notificationAPI';
import { Company } from '@/entities/company';
import { IpAddress } from '@/entities/ipAddress';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, ContentModal, ContentModalProps } from '@/ui-kit/components';

import IpAddressFormModal from '../../IpAddressFormModal/IpAddressFormModal';

import CompanyIpAddressRow from './CompanyIpAddressRow/CompanyIpAddressRow';

interface UserIpAddressesModalProps extends ContentModalProps {
  onAfterSave: () => void;
  userIpAddresses: IpAddress[];
  companyId: number;
  userId: number;
}

function UserIpAddressesModal(props: UserIpAddressesModalProps) {
  const { open, onClose, companyId, userId, userIpAddresses = [], onAfterSave } = props;
  const {
    getCompanyById,
    attachIpAddressesToUser,
    deAttachIpAddressesFromUser,
    isFetchingCompanyById,
    isAttachingIpAddresses,
  } = useStore('companiesStore');

  const [companyIpAddresses, setCompanyIpAddresses] = useState<IpAddress[]>([]);
  const [selectedIpAddresses, setSelectedIpAddresses] = useState(getSelectedIpAddresses());
  const [isAddIpAddressOpen, setAddIpAddressOpen] = useState(false);

  function getSelectedIpAddresses() {
    return [...userIpAddresses.map(({ id }: IpAddress) => id)];
  }

  async function onSubmit(e: SyntheticEvent) {
    e.preventDefault();
    e.stopPropagation();

    try {
      const defaultUserIpAddressesSet = new Set([...userIpAddresses.map(({ id }: IpAddress) => id)]);
      const selectedUserIpAddressesSet = new Set([...selectedIpAddresses.map((id: number) => id)]);

      const ipAddressesToAttach = selectedIpAddresses.filter((id: number) => !defaultUserIpAddressesSet.has(id));
      const ipAddressesToDeAttach = userIpAddresses
        .filter(({ id }: IpAddress) => !selectedUserIpAddressesSet.has(id))
        .map(({ id }: IpAddress) => id);

      await attachIpAddressesToUser(companyId, userId, ipAddressesToAttach);

      await deAttachIpAddressesFromUser(companyId, userId, ipAddressesToDeAttach);

      onClose();

      notificationAPI.success('IP-адреса были прикреплены');

      onAfterSave();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  async function fetchCompanyById() {
    try {
      const company: Company = await getCompanyById(companyId);

      setCompanyIpAddresses(company.ip ?? []);

      onAfterSave();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  useEffect(() => {
    if (open) {
      fetchCompanyById();
    }
  }, [open]);

  useEffect(() => {
    if (userIpAddresses.length > 0) {
      setSelectedIpAddresses([...userIpAddresses.map(({ id }: IpAddress) => id)]);
    }
  }, [userIpAddresses]);

  const onToggleAddIpAddress = useCallback(() => {
    setAddIpAddressOpen((isAddIpAddressOpen: boolean) => !isAddIpAddressOpen);
  }, []);

  function onChangeHandler(id: number, checked: boolean) {
    const newSelectedIpAddresses = [...selectedIpAddresses];

    if (checked) {
      newSelectedIpAddresses.push(id);
    } else {
      const targetIndex = newSelectedIpAddresses.findIndex((ipAddressId: number) => ipAddressId === id);

      if (targetIndex !== -1) {
        newSelectedIpAddresses.splice(targetIndex, 1);
      }
    }

    setSelectedIpAddresses(newSelectedIpAddresses);
  }

  function onResetAndCloseHandler(e: SyntheticEvent, reason?: string) {
    if (reason === 'backdropClick') {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    setSelectedIpAddresses(getSelectedIpAddresses());
    onClose();
  }

  function onCloseModal(_?: SyntheticEvent, reason?: string) {
    if (reason === 'backdropClick') {
      return;
    }

    setSelectedIpAddresses(getSelectedIpAddresses());
    onClose();
  }

  return (
    <ContentModal
      disableEscapeKeyDown={isAttachingIpAddresses}
      isLoading={isAttachingIpAddresses || isFetchingCompanyById}
      maxWidth="xs"
      onClose={onCloseModal}
      open={open}
    >
      <ContentModal.Header onClose={onClose}>Разрешенные IP-адреса</ContentModal.Header>

      <form onReset={onResetAndCloseHandler} onSubmit={onSubmit}>
        <ContentModal.Body>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              {companyIpAddresses.map((ipAddress: IpAddress) => (
                <CompanyIpAddressRow
                  checked={selectedIpAddresses.some((id: number) => id === ipAddress.id)}
                  companyId={companyId}
                  ipAddress={ipAddress}
                  key={ipAddress.id}
                  onAfterChange={fetchCompanyById}
                  onChange={onChangeHandler}
                />
              ))}
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item xs="auto">
                  <Button color="primary" onClick={onToggleAddIpAddress} size="small" type="button" variant="contained">
                    Добавить новый
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isAttachingIpAddresses} type="reset">
            Отмена
          </Button>

          <ButtonLoader isLoading={isAttachingIpAddresses} type="submit">
            Применить
          </ButtonLoader>
        </ContentModal.Footer>
      </form>

      <IpAddressFormModal
        companyId={companyId}
        onAfterChange={fetchCompanyById}
        onClose={onToggleAddIpAddress}
        open={isAddIpAddressOpen}
        type="create"
      />
    </ContentModal>
  );
}

export default memo(observer(UserIpAddressesModal));
