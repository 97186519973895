import React, { ChangeEvent, memo, SyntheticEvent, useCallback, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { FormikHelpers, useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { notificationAPI } from '@/api/notificationAPI';
import { DOC_ISSUE_CODE_LENGTH } from '@/constants';
import { Face } from '@/entities/face';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField, ButtonLoader, ContentModal, DatepickerField, InputField } from '@/ui-kit/components';

import { DEFAULT_EMPTY_COMPANY_FACE } from './CompanyFaceModal.constant';

interface CompanyFaceModalProps {
  open: boolean;
  companyFace?: Face;
  type: 'create' | 'edit';
  onClose: () => void;
  companyId: number;
  onAfterChange: () => void;
}

function CompanyFaceModal(props: CompanyFaceModalProps) {
  const { open, type, companyFace = { ...DEFAULT_EMPTY_COMPANY_FACE }, onClose, companyId, onAfterChange } = props;
  const { addCompanyFace, updateCompanyFace, isSavingFace } = useStore('companiesStore');
  const { getDocIssueAuthorityOrgans, docIssueAuthorityOrgans } = useStore('dictionariesStore');

  const companyFaceForm = useFormik({
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
    initialValues: {
      ...companyFace,
      face_doc_issue_authority: companyFace.face_doc_issue_authority_organ?.code ?? '',
    },
    onSubmit: async (values: Face, { resetForm }: FormikHelpers<Face>) => {
      try {
        if (type === 'create') {
          await addCompanyFace(companyId, values);
        }

        if (type === 'edit' && companyFace.id) {
          await updateCompanyFace(companyId, companyFace.id, values);
        }

        notificationAPI.success(`Ответственное лицо ${type === 'create' ? 'добавлено' : 'обновлено'}`);

        onClose();

        resetForm({
          values,
        });

        onAfterChange();
      } catch (e) {
        notificationAPI.error(e);
      }
    },
    validate: (values: Face) => {
      const {
        face_fname,
        face_lname,
        face_doc_serial,
        face_doc_number,
        face_doc_issue_authority,
        face_doc_issue_date,
        face_birth_date,
      } = values;
      const errors: Record<string, string> = {};

      if (face_fname.length === 0) {
        errors.face_fname = 'Обязательно к заполнению';
      }

      if (face_lname.length === 0) {
        errors.face_lname = 'Обязательно к заполнению';
      }

      if (!face_birth_date) {
        errors.face_birth_date = 'Обязательно к заполнению';
      }

      if (face_doc_serial.length === 0) {
        errors.face_doc_serial = 'Обязательно к заполнению';
      }

      if (face_doc_number.length === 0) {
        errors.face_doc_number = 'Обязательно к заполнению';
      }

      if (face_doc_issue_authority.length === 0) {
        errors.face_doc_issue_authority = 'Обязательно к заполнению';
      }

      if (!face_doc_issue_date) {
        errors.face_doc_issue_date = 'Обязательно к заполнению';
      }

      return errors;
    },
  });

  function onCloseWithReset(e?: SyntheticEvent) {
    e?.stopPropagation();
    e?.preventDefault();

    onClose();
    companyFaceForm.resetForm();
  }

  const onIssueAuthorityCodeChange = useCallback(
    async ({ target }: ChangeEvent<HTMLInputElement>) => {
      const code = target.value.replace(' - ', '-').replace('_', '').trim();

      if (code.length === DOC_ISSUE_CODE_LENGTH) {
        const organs = await getDocIssueAuthorityOrgans(code);

        if (organs && organs.length > 0) {
          companyFaceForm.setValues({
            ...companyFaceForm.values,
            face_doc_issue_authority_organ_id: organs[0].id,
            face_doc_issue_authority_organ: organs[0],
            face_doc_issue_authority: organs[0].name_ru,
            face_doc_issue_code: organs[0].code,
          });
        }

        return;
      }

      companyFaceForm.setValues({
        ...companyFaceForm.values,
        face_doc_issue_code: code,
      });
    },
    [companyFaceForm.values],
  );

  const onAuthorityOrganChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      companyFaceForm.setValues({
        ...companyFaceForm.values,
        face_doc_issue_authority: target.value as string,
      });
    },
    [companyFaceForm.values],
  );

  function onAuthorityOrganSelect(name: Nullable<string>) {
    const organ = docIssueAuthorityOrgans.find(({ name_ru }) => name_ru === name);

    if (organ) {
      companyFaceForm.setValues({
        ...companyFaceForm.values,
        face_doc_issue_authority_organ_id: organ.id,
        face_doc_issue_authority_organ: organ,
        face_doc_issue_authority: organ.name_ru,
        face_doc_issue_code: organ.code,
      });
    }
  }

  const onAuthorityOrganChangeHandler = useCallback(
    (authorityOrgan: unknown, e: SyntheticEvent) => {
      e.preventDefault();
      onAuthorityOrganSelect(authorityOrgan as string);
    },
    [companyFaceForm.values],
  );

  const filterAuthorityOptions = useCallback(
    (option: string[], { inputValue }: FilterOptionsState<string>) =>
      option.filter((name: string) => name.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
    [],
  );

  useEffect(() => {
    if (open) {
      companyFaceForm.resetForm({
        values: companyFace,
      });
    }
  }, [open]);

  return (
    <ContentModal disableEscapeKeyDown={isSavingFace} maxWidth="md" onClose={onClose} open={open}>
      <form onReset={onCloseWithReset} onSubmit={companyFaceForm.handleSubmit}>
        <ContentModal.Header isCloseDisabled={isSavingFace} onClose={onCloseWithReset}>
          Лицо, ответственное за учет
        </ContentModal.Header>

        <ContentModal.Body>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid alignItems="flex-start" container spacing={2}>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Общие данные</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <InputField
                        autoFocus
                        disabled={isSavingFace}
                        error={!!companyFaceForm.errors.face_lname}
                        helperText={companyFaceForm.errors.face_lname}
                        label="Фамилия"
                        name="face_lname"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_lname}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputField
                        disabled={isSavingFace}
                        error={!!companyFaceForm.errors.face_fname}
                        helperText={companyFaceForm.errors.face_fname}
                        label="Имя"
                        name="face_fname"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_fname}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputField
                        disabled={isSavingFace}
                        label="Отчество (при наличии)"
                        name="face_mname"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_mname}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <DatepickerField
                            disableFuture
                            error={!!companyFaceForm.errors.face_birth_date}
                            label="Дата рождения"
                            name="face_birth_date"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_birth_date}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputField
                            error={!!companyFaceForm.errors.face_position}
                            label="Должность"
                            name="face_position"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_position}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Документ</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <InputField
                            disabled={isSavingFace}
                            error={!!companyFaceForm.errors.face_doc_serial}
                            helperText={companyFaceForm.errors.face_doc_serial}
                            label="Серия"
                            name="face_doc_serial"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_doc_serial}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <InputField
                            disabled={isSavingFace}
                            error={!!companyFaceForm.errors.face_doc_number}
                            helperText={companyFaceForm.errors.face_doc_number}
                            label="Номер"
                            name="face_doc_number"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_doc_number}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <InputMask
                        mask="999 - 999"
                        maskPlaceholder={null}
                        name="face_doc_issue_code"
                        onChange={onIssueAuthorityCodeChange}
                        value={companyFaceForm.values.face_doc_issue_code}
                      >
                        {/* @ts-ignore */}

                        {() => (
                          <InputField
                            error={!!companyFaceForm.errors.face_doc_issue_code}
                            label="Код подразделения"
                            variant="outlined"
                          />
                        )}
                      </InputMask>
                    </Grid>

                    <Grid item xs={12}>
                      <AutocompleteField
                        disableClearable
                        filterOptions={filterAuthorityOptions}
                        isError={!!companyFaceForm.errors.face_doc_issue_authority}
                        label="Кем выдан"
                        name="face_doc_issue_authority"
                        onChange={onAuthorityOrganChangeHandler}
                        onInputChange={onAuthorityOrganChange}
                        options={[...docIssueAuthorityOrgans.map(({ name_ru }) => name_ru)]}
                        popupIcon={null}
                        value={companyFaceForm.values.face_doc_issue_authority_organ?.name_ru ?? ''}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <DatepickerField
                            disableFuture
                            error={!!companyFaceForm.errors.face_doc_issue_date}
                            label="Дата выдачи"
                            name="face_doc_issue_date"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_doc_issue_date}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <DatepickerField
                            error={!!companyFaceForm.errors.face_doc_valid_date}
                            label="Срок действия"
                            name="face_doc_valid_date"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_doc_valid_date}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Место жительства</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputField disabled fullWidth label="Страна" name="face_country" variant="outlined" />
                    </Grid>

                    <Grid item xs={12}>
                      <InputField
                        disabled={isSavingFace}
                        label="Район"
                        name="face_regplace2"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_regplace2}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputField
                        disabled={isSavingFace}
                        label="Улица"
                        name="face_regstreet"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_regstreet}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputField
                        disabled={isSavingFace}
                        label="Регион (область)"
                        name="face_regplace1"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_regplace1}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputField
                        disabled={isSavingFace}
                        fullWidth
                        label="Город, населенный пункт"
                        name="face_regplace3"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_regplace3}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <InputField
                            fullWidth
                            label="Дом"
                            name="face_reghouse1"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_reghouse1}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <InputField
                            fullWidth
                            label="Корпус"
                            name="face_reghouse2"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_reghouse2}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <InputField
                            fullWidth
                            label="Строение"
                            name="face_reghouse3"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_reghouse3}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <InputField
                            fullWidth
                            label="Квартира"
                            name="face_regflat"
                            onChange={companyFaceForm.handleChange}
                            value={companyFaceForm.values.face_regflat}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">Документ, подтверждающий полномочия</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputField
                        disabled={isSavingFace}
                        fullWidth
                        label="Вид документа"
                        name="face_pow_type"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_pow_type}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputField
                        disabled={isSavingFace}
                        fullWidth
                        label="Серия"
                        name="face_pow_serial"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_pow_serial}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <InputField
                        disabled={isSavingFace}
                        fullWidth
                        label="Номер"
                        name="face_pow_number"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_pow_number}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DatepickerField
                        error={!!companyFaceForm.errors.face_pow_issue_date}
                        label="Дата выдачи"
                        name="face_pow_issue_date"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_pow_issue_date}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DatepickerField
                        error={!!companyFaceForm.errors.face_pow_valid_date}
                        label="Срок действия"
                        name="face_pow_valid_date"
                        onChange={companyFaceForm.handleChange}
                        value={companyFaceForm.values.face_pow_valid_date}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContentModal.Body>

        <ContentModal.Footer>
          <Button color="primary" disabled={isSavingFace} type="reset">
            Отмена
          </Button>

          <ButtonLoader
            disabled={!(companyFaceForm.dirty && companyFaceForm.isValid)}
            isLoading={isSavingFace}
            type="submit"
          >
            {type === 'edit' && 'Обновить'}

            {type === 'create' && 'Добавить'}
          </ButtonLoader>
        </ContentModal.Footer>
      </form>
    </ContentModal>
  );
}

export default memo(observer(CompanyFaceModal));
