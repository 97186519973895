import styled from '@mui/material/styles/styled';

import { EMIS_THEME } from '@/App.style';

export const SelfCheckInMobileRoot = styled('div')`
  overflow: hidden;
  margin: 0 auto;
  padding: 10% 16px 8px;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;

  ${EMIS_THEME.breakpoints.up('md')} {
    height: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
  }
`;

export const SelfCheckInMobileSteps = styled('div')`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelfCheckInMobileNext = styled('div')`
  margin-left: 34px;
`;
