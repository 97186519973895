export class FaceModel {
  id?: number;
  face_fname!: string;
  face_mname!: string;
  face_lname!: string;
  face_position!: string;
  face_birth_date!: Nullable<Date>;
  face_birth_place!: string;
  face_doc_serial!: string;
  face_doc_number!: string;
  face_doc_valid_date!: Nullable<Date>;
  face_doc_issue_date!: Nullable<Date>;
  face_doc_issue_code!: string;
  face_doc_issue_authority!: string;
  face_doc_issue_authority_organ_id!: number;
  face_pow_type!: string;
  face_pow_serial!: string;
  face_pow_number!: string;
  face_pow_issue_date!: Nullable<Date>;
  face_pow_valid_date!: Nullable<Date>;
  face_regplace1!: string;
  face_regplace2!: string;
  face_regplace3!: string;
  face_regstreet!: string;
  face_reghouse1!: string;
  face_reghouse2!: string;
  face_reghouse3!: string;
  face_regflat!: string;
  face_doc_issue_authority_organ?: {
    id: number;
    name_ru: string;
    name_en: string;
    code: string;
  };
}

export class Face extends FaceModel {
  constructor(face: FaceModel) {
    super();

    Object.keys(face).forEach((key) => {
      this[key] = face[key];
    });
  }
}
