const timeouts: Record<string, NodeJS.Timeout> = {};

// "throttle" will run callback once even if there were many function calls
export function throttle(callback: Function, ms = 0) {
  if (typeof callback !== 'function') {
    return;
  }

  const func = callback.toString();

  clearTimeout(timeouts[func]);
  timeouts[func] = setTimeout(() => {
    callback();

    clearTimeout(timeouts[func]);
    delete timeouts[func];
  }, ms);
}
