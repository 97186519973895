import { format, isValid } from 'date-fns';

import { DATE_FOR_SERVER_FORMAT, ROUTE_FALLBACK_LABEL, ROUTE_MAIN } from '@/constants';
import { getValidDateValue } from '@/utils';
import { isArray } from '@/utils/isArray';
import { isEmpty } from '@/utils/isEmpty';

type QueryParams = Record<string, Any>;

class QueryAPI {
  getQuery() {
    const search = new URLSearchParams(this.params);

    return search.size > 0 ? `?${search.toString()}` : '';
  }

  getKey(key: string) {
    return this.params?.[key];
  }

  setKey(key: string, value: never) {
    this.set({
      ...this.params,
      [key]: value,
    });
  }

  set(params: Record<string, Nullable<StringOrNumber>>) {
    if (!params) {
      return;
    }

    const search = new URLSearchParams();

    Object.keys(params).forEach((key) => {
      if (params[key] === null) {
        return;
      }

      if (typeof params[key] === 'undefined') {
        return;
      }

      if (typeof params[key] === 'string' && !params[key]) {
        return;
      }

      search.append(key, `${params[key]}`);
    });

    if (search.size > 0) {
      this.replaceQueryParams(search);
    }
  }

  has(key: string) {
    return typeof this.params?.[key] !== 'undefined';
  }

  get params(): QueryParams {
    /*
     * URLSearchParams заменяет символ "+" на пробел " ", об этом написано тут: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#preserving_plus_signs
     *
     * Но "+" у нас могут быть в авторизационном токене, а токен передаваться в урле
     *
     * Поэтому заменяем все плюсы, далее при парсинге JS заменит их обратно
     * https://github.com/angular/angular/issues/11058#issuecomment-292457108
     * */
    const search = new URLSearchParams(location.search.replaceAll(/\+/gi, '%2B'));
    const params = Object.fromEntries(search.entries());

    const result = {};

    Object.keys(params).forEach((key: string) => {
      let value: unknown = decodeURIComponent(params[key]);

      if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      }

      result[decodeURIComponent(key)] = value;
    });

    return result;
  }

  get fallback() {
    /*
     * В случае, если в урле при авторизации присутствует параметр `fallback`,
     * декодим его значение и переходим на указанную страницу
     * */
    if (this.has(ROUTE_FALLBACK_LABEL)) {
      return this.getKey(ROUTE_FALLBACK_LABEL);
    }

    return ROUTE_MAIN;
  }

  generateQuery(params: QueryParams | string = {}) {
    if (typeof params === 'string') {
      return params;
    }

    if (!params || Object.keys(params).length === 0) {
      return '';
    }

    let result = '';

    Object.keys(params)
      .filter((key: string) => {
        if (typeof params[key] === 'boolean') {
          return true;
        }

        if (params[key] === null) {
          return false;
        }

        if (typeof params[key] === 'undefined') {
          return false;
        }

        if (typeof params[key] === 'number') {
          return !isNaN(Number(params[key]));
        }

        return Boolean(params[key]);
      })
      .forEach((key: string, index: number, keys: string[]) => {
        if (index === 0) {
          result = '?';
        }

        if (params[key] instanceof Date) {
          if (isValid(params[key])) {
            const validDateValue = getValidDateValue(params[key] as Date);

            if (validDateValue) {
              result += `${key}=${format(new Date(validDateValue), DATE_FOR_SERVER_FORMAT)}`;
            }
          }
        } else if (typeof params[key] === 'boolean') {
          result += `${key}=${params[key] ? 'true' : 'false'}`;
        } else if (!isEmpty(params[key]) && isArray(params[key])) {
          params[key].forEach((value: StringOrNumber, i: number, arr: StringOrNumber[]) => {
            result += `${key}=${value}`;

            if (arr.length !== i + 1) {
              result += '&';
            }
          });
        } else {
          result += `${key}=${encodeURIComponent(params[key] as string)}`;
        }

        if (keys.length !== index + 1) {
          result += '&';
        }
      });

    return result;
  }

  replaceQueryParams(params: QueryParams) {
    history.replaceState(
      {},
      '',
      `${window.location.origin}${window.location.pathname}${queryAPI.generateQuery(params)}`,
    );
  }

  replacePath(path: string) {
    history.replaceState({}, '', `${window.location.origin}${path}`);
  }
}

export const queryAPI = new QueryAPI();
