import styled from '@mui/material/styles/styled';

export const CitizenshipRoot = styled('div')`
  flex-grow: 1;
`;

export const CitizenshipControls = styled('div')`
  margin-top: 60px;
  width: 100%;
  max-width: 474px;
`;
