import { Card, CardContent } from '@mui/material';
import common from '@mui/material/colors/common';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import { css } from '@mui/material/styles';
import styled from '@mui/material/styles/styled';

import { EMIS_BRAND_COLOR } from '@/ui-kit/global-styles';

export const PersonBlockRoot = styled(Card)(
  ({ selected }: { selected: boolean }) => css`
    background-color: ${selected ? EMIS_BRAND_COLOR : grey['200']};
    height: 265px;
    color: ${selected ? common.white : common.black};
  `,
);

export const PersonBlockContent = styled(CardContent)`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const PersonBlockPhoto = styled('div')`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 24px;
`;

export const PersonBlockDetails = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BirthdayBlock = styled('div')`
  height: 50px;
  margin: auto 0 12px;
`;

export const BirthdayBlockContent = styled('div')`
  height: 100%;
  border-radius: 4px;
  border: 1px solid ${green['500']};
  background-color: ${common.white};
  color: ${common.black};
  display: flex;
  align-items: center;
`;
