import styled from '@mui/material/styles/styled';

export const QRCodeModalImage = styled('div')`
  text-align: center;
`;

export const QRCodeModalLink = styled('div')`
  display: flex;
  align-items: flex-start;
`;

export const QRCodeModalLinkUrl = styled('div')`
  flex-grow: 1;
  word-break: break-all;
`;

export const QRCodeModalLinkCopy = styled('div')`
  flex-shrink: 0;
`;
