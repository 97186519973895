import React, { memo } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { DictionaryBasicWithCode } from '@/entities/dictionary';

interface PdfFormItemProps {
  selected: boolean;
  disabled: boolean;
  pdfFormType: DictionaryBasicWithCode;
  onChange: (id: number) => void;
}

function PdfFormItem(props: PdfFormItemProps) {
  const { selected, pdfFormType, onChange, disabled } = props;

  function onChangeHandler() {
    onChange(pdfFormType.id);
  }

  return (
    <FormControlLabel
      control={<Checkbox checked={selected} color="primary" onClick={onChangeHandler} />}
      disabled={disabled}
      label={pdfFormType.name_ru}
    />
  );
}

export default memo(PdfFormItem);
