import styled from '@mui/material/styles/styled';

export const CompanyFacesRoot = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CompanyFacesHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompanyFacesContent = styled('div')`
  flex: 1;
  overflow: hidden;
`;

export const CompanyFacesList = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;
