import styled from '@mui/material/styles/styled';

export const PersonFormHeaderRoot = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  padding: 0 8px;
`;

export const PersonFormHeaderControls = styled('div')`
  display: flex;
  align-items: center;
`;

export const PersonFormHeaderDevices = styled('div')`
  margin-right: 12px;
  width: 250px;
`;

export const PersonFormHeaderScan = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 82px;

  button {
    border-radius: 0;
  }

  span:first-child {
    button {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  span:last-child {
    button {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export const IconButtonWrapper = styled('span')`
  button {
    border-radius: 0;
  }
`;

export const PersonFormHeaderLogus = styled('div')`
  position: relative;
  margin-left: 12px;
`;

export const PersonFormHeaderContinue = styled('div')`
  margin-left: 12px;
`;

export const PersonFormHeaderClose = styled('div')`
  margin-left: 12px;
`;
