import { FIRST_NAME_IS_EMPTY_LABEL, LAST_NAME_IS_EMPTY_LABEL } from '@/constants';
import { Person } from '@/stores/PersonsStore';

export function getPersonName(person: Person) {
  let result = '';

  if (person.lname) {
    result += `${person.lname}`;
  } else if (person.lname_lat && person.lname_lat !== LAST_NAME_IS_EMPTY_LABEL) {
    result += `${person.lname_lat}`;
  }

  if (person.fname) {
    result += ` ${person.fname}`;
  } else if (person.fname_lat && person.fname_lat !== FIRST_NAME_IS_EMPTY_LABEL) {
    result += ` ${person.fname_lat}`;
  }

  if (person.mname) {
    result += ` ${person.mname}`;
  } else if (person.mname_lat) {
    result += ` ${person.mname_lat}`;
  }

  return result.trim();
}
