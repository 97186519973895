import React, { memo, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import { notificationAPI } from '@/api/notificationAPI';
import { UserDevice } from '@/entities/userDevice';
import { useStore } from '@/stores/StoreProvider';
import { BackdropLoader } from '@/ui-kit/components';

import { UserDevicesRoot } from './UserDevices.style';
import UserDevicesRow from './UserDevicesRow/UserDevicesRow';

function UserDevices() {
  const { isFetching, userDevices, setDefaultDevice, getUserDevices } = useStore('devicesStore');

  useEffect(() => {
    getUserDevices();
  }, []);

  async function setDefaultHandler(id: number) {
    try {
      await setDefaultDevice(id);

      notificationAPI.success('Устройство обновлено');

      await getUserDevices();
    } catch (e) {
      notificationAPI.error(e);
    }
  }

  return (
    <UserDevicesRoot>
      <BackdropLoader isLoading={isFetching} />

      {userDevices.length > 0 && (
        <Table size="small">
          <TableBody>
            {userDevices.map((device: UserDevice) => (
              <UserDevicesRow device={device} key={device.id} setDefault={setDefaultHandler} />
            ))}
          </TableBody>
        </Table>
      )}
    </UserDevicesRoot>
  );
}

export default memo(observer(UserDevices));
