import React, { Fragment, memo } from 'react';
import { observer } from 'mobx-react-lite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { DocumentType } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';

import { usePersonPageStore } from '../../PersonPageStore';
import DocumentsTabs from '../DocumentsTabs/DocumentsTabs';

import Birthplace from './Birthplace/Birthplace';
import { DocumentsPersonContent, DocumentsPersonSummary } from './DocumentsPersonData.style';
import IdCard from './IdCard/IdCard';
import MigrationCard from './MigrationCard/MigrationCard';
import RightOfStay from './RightOfStay/RightOfStay';

function DocumentsPersonData() {
  const { isExported, isDeleted, tab } = usePersonPageStore();

  const [expanded, , , onToggleExpanded] = useToggle(true);

  return (
    <Fragment>
      <Birthplace />

      <Accordion expanded={expanded} onChange={onToggleExpanded}>
        <DocumentsPersonSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Документы</Typography>
        </DocumentsPersonSummary>

        <AccordionDetails>
          {expanded && (
            <DocumentsPersonContent disabled={isExported || isDeleted}>
              <DocumentsTabs />

              {tab === DocumentType.IdCard && <IdCard />}

              {tab === DocumentType.RightOfStay && <RightOfStay />}

              {tab === DocumentType.Migration && <MigrationCard />}
            </DocumentsPersonContent>
          )}
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}

export default memo(observer(DocumentsPersonData));
