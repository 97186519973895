import React, { memo, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

import { DictionaryBasicWithCode } from '@/entities/dictionary';
import { useStore } from '@/stores/StoreProvider';
import { AutocompleteField } from '@/ui-kit/components';

interface CountryControlProps {
  country: Nullable<DictionaryBasicWithCode>;
  error?: string;
  onChange?: (name: string, country: Nullable<DictionaryBasicWithCode>) => void;
  disabled?: boolean;
  readonly?: boolean;
  variant?: TextFieldProps['variant'];
  margin?: TextFieldProps['margin'];
}

function CountryControl(props: CountryControlProps) {
  const { country, error, onChange, disabled = false, readonly = false, variant, margin } = props;
  const { countries } = useStore('dictionariesStore');

  // TODO: на текущий момент страна может быть только Россия, поиск в форме адреса отключен
  // const onChangeRequest = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
  //
  //   // если этот контрол будет нужен, не забыть дописать в сторе поиск по стране
  //   if (currentTarget.value.length > 2) {
  //     dictionariesStore.getCountries(currentTarget.value);
  //   }
  // };

  const value = useMemo(
    () => countries.find(({ id }: DictionaryBasicWithCode) => id === country?.id) ?? null,
    [JSON.stringify(countries), country?.id],
  );

  const onCountryChangeHandler = useCallback(
    (country: unknown) => {
      onChange?.('country', country as DictionaryBasicWithCode);
    },
    [onChange],
  );

  const renderOption = useCallback(({ name_ru }: DictionaryBasicWithCode) => name_ru, []);

  const renderFilterOption = useCallback(
    (countries: DictionaryBasicWithCode[], { inputValue }: FilterOptionsState<DictionaryBasicWithCode>) =>
      countries.filter(({ name_ru }) => name_ru.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
    [],
  );

  return (
    <AutocompleteField
      disabled={disabled || readonly}
      filterOptions={renderFilterOption}
      getOptionLabel={renderOption}
      helperText={error}
      isError={!!error}
      label="Страна"
      margin={margin}
      name="country"
      onChange={onCountryChangeHandler}
      options={[...countries]}
      value={value}
      variant={variant}
    />
  );
}

export default memo(observer(CountryControl));
