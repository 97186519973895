import styled from '@mui/material/styles/styled';

export const GreetingRoot = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const GreetingFooter = styled('div')`
  text-align: center;
`;

export const GreetingFooterDescription = styled('div')`
  max-width: 400px;
  margin: 0 auto 16px;
`;
