import React, { memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SignalActiveIcon from '@mui/icons-material/SignalCellular4Bar';
import SignalNotActiveIcon from '@mui/icons-material/SignalCellularOff';
import AccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';

import { Device } from '@/entities/device';
import { useToggle } from '@/hooks/useToggle/useToggle';

import DeviceDetails from './DeviceDetails/DeviceDetails';
import {
  DeviceRawHeading,
  DeviceRawPanel,
  DeviceRawPanelSummary,
  DeviceRawSecondaryHeading,
  DeviceRawStatus,
} from './DeviceRaw.style';

interface DeviceRowProps {
  device: Device;
  isAdmin?: boolean;
}

function DeviceRaw(props: DeviceRowProps) {
  const { device, isAdmin = false } = props;

  const [isOpen, , , toggleAccordion] = useToggle();

  return (
    <DeviceRawPanel expanded={isOpen} onChange={toggleAccordion}>
      <DeviceRawPanelSummary expandIcon={<ExpandMoreIcon />}>
        <DeviceRawStatus isActive={device.isActive}>
          {device.isActive ? (
            <Tooltip title="Активно">
              <SignalActiveIcon fontSize="small" />
            </Tooltip>
          ) : (
            <Tooltip title="Не активно">
              <SignalNotActiveIcon fontSize="small" />
            </Tooltip>
          )}
        </DeviceRawStatus>

        <DeviceRawHeading>
          <span>{device.name} </span>

          <span>({device.serialNumber})</span>
        </DeviceRawHeading>

        <DeviceRawSecondaryHeading variant="body2">
          {device.users && device.users.length > 0 && (
            <span>
              <span>Пользователи: </span>

              {device.users.filter(({ company_id }) => company_id === device.companyId).length}
            </span>
          )}
        </DeviceRawSecondaryHeading>
      </DeviceRawPanelSummary>

      <AccordionDetails>
        <DeviceDetails device={device} isAdmin={isAdmin} isOpen={isOpen} />
      </AccordionDetails>
    </DeviceRawPanel>
  );
}

export default memo(DeviceRaw);
