export function generateBase64FromBlob(blob: Blob): Promise<string> {
  const reader = new FileReader();

  reader.readAsDataURL(blob);

  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(convertToClearBase64(reader.result as string));
    };
  });
}

export function convertToClearBase64(base64: string) {
  return base64.includes('data:image/') ? base64.split(',')[1] : base64;
}

export function convertToImageBase64(base64: string) {
  return `data:image/jpeg;base64,${base64}`;
}

export function base64toBlob(base64: string): Promise<Nullable<Blob>> {
  const offScreenCanvas = document.createElement('canvas');
  const offScreenCanvasCtx = offScreenCanvas.getContext('2d');

  const img = new Image();

  img.src = convertToImageBase64(base64);

  offScreenCanvas.height = img.height;
  offScreenCanvas.width = img.width;

  if (offScreenCanvasCtx) {
    offScreenCanvasCtx.drawImage(img, 0, 0);
  }

  return new Promise((resolve, reject) => {
    offScreenCanvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Canvas is empty'));

        return;
      }

      resolve(blob);
    }, 'image/jpeg');
  });
}
