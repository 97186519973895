import React, { memo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';

import { useStore } from '@/stores/StoreProvider';
import { getSignInPath } from '@/utils';

import AdminPanel from './AdminPanel/AdminPanel';
import OperatorPanel from './OperatorPanel/OperatorPanel';

function Main() {
  const navigate = useNavigate();
  const { userData, isSignedIn } = useStore('signInStore');

  useEffect(() => {
    if (!isSignedIn) {
      navigate(getSignInPath());
    }
  }, [isSignedIn]);

  if (isSignedIn) {
    return userData?.is_admin ? <AdminPanel /> : <OperatorPanel />;
  }

  return null;
}

export default memo(observer(Main));
