export class RoleModel {
  id!: number;
  name!: string;
  allow_scan!: boolean;
  allow_create!: boolean;
  allow_edit!: boolean;
  allow_delete!: boolean;
  allow_manage_groups!: boolean;
}

export class Role extends RoleModel {
  constructor(role: RoleModel) {
    super();

    this.setValues(role);
  }

  setValues(data: Partial<RoleModel>) {
    Object.keys(data).forEach((key) => {
      this[key] = data[key];
    });
  }
}
