import MenuItem from '@mui/material/MenuItem';
import styled from '@mui/material/styles/styled';

export const DropdownOptionItem = styled(MenuItem)`
  display: flex;
  align-items: center;

  & svg {
    padding-right: 12px;
  },
`;
