import React, { memo, useMemo } from 'react';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { notificationAPI } from '@/api/notificationAPI';
import { queryAPI } from '@/api/queryAPI';
import logo from '@/assets/g1_c.png';
import policy from '@/assets/terms-and-policy.pdf';
import { ROUTE_RESTORE_PASSWORD } from '@/constants';
import { useToggle } from '@/hooks/useToggle/useToggle';
import TechSupport from '@/shared/TechSupport/TechSupport';
import { SignInForm } from '@/stores/SignInStore';
import { useStore } from '@/stores/StoreProvider';
import { ButtonLoader, InputField } from '@/ui-kit/components';

import { SignInActions, SignInContent, SignInLogo, SignInPolicy, SignInRoot, SignInTechSupport } from './SignIn.styles';

function SignIn() {
  const { signIn, isFetching, isSignedIn } = useStore('signInStore');

  const [shouldShowPassword, , , toggleShowPassword] = useToggle();

  const fallbackPath = useMemo(() => queryAPI.fallback, []);

  const sighInForm = useFormik<SignInForm>({
    validateOnMount: false,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values: SignInForm) => {
      try {
        await signIn(values);
      } catch (e) {
        notificationAPI.error(e);
      }
    },
  });

  if (isSignedIn) {
    return <Navigate to={fallbackPath} />;
  }

  return (
    <SignInRoot>
      <SignInContent>
        <header>
          <SignInLogo>
            <img alt="emis logo" height="100" src={logo} />
          </SignInLogo>
        </header>

        <form onSubmit={sighInForm.handleSubmit}>
          <InputField
            autoFocus
            disabled={isFetching}
            label="Электронная почта"
            margin="normal"
            name="email"
            onChange={sighInForm.handleChange}
            type="email"
            value={sighInForm.values.email}
          />

          <InputField
            InputProps={{
              endAdornment: (
                <IconButton onClick={toggleShowPassword} size="small">
                  {shouldShowPassword && <VisibilityOffIcon fontSize="small" />}

                  {!shouldShowPassword && <VisibilityIcon fontSize="small" />}
                </IconButton>
              ),
            }}
            disabled={isFetching}
            label="Пароль"
            margin="normal"
            name="password"
            onChange={sighInForm.handleChange}
            type={shouldShowPassword ? 'text' : 'password'}
            value={sighInForm.values.password}
          />

          <SignInPolicy>
            <span>
              Продолжая использовать данный сервис, Вы даете согласие на обработку файлов Cookies и других
              пользовательских данных, в соответствии с{' '}
            </span>

            <Link target="_blank" to={policy}>
              Политикой обработки персональных данных
            </Link>
          </SignInPolicy>

          <SignInActions>
            <Link to={ROUTE_RESTORE_PASSWORD}>Напомнить пароль</Link>

            <ButtonLoader isLoading={isFetching} type="submit">
              Войти
            </ButtonLoader>
          </SignInActions>
        </form>

        <div>
          <SignInTechSupport>
            <TechSupport />
          </SignInTechSupport>

          <Typography align="center" component="div" variant="caption">
            © 2016 - {new Date().getFullYear()} G1 SOFTWARE.
          </Typography>
        </div>
      </SignInContent>
    </SignInRoot>
  );
}

export default memo(observer(SignIn));
