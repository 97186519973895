import React, { memo, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { notificationAPI } from '@/api/notificationAPI';
import Groups from '@/shared/Groups/Groups';
import Persons from '@/shared/Persons/Persons';
import BreadcrumbsWrapper from '@/shared/RouteConstructor/BreadcrumbsWrapper/BreadcrumbsWrapper';
import RouteConstructor from '@/shared/RouteConstructor/RouteConstructor';
import { useStore } from '@/stores/StoreProvider';

import OperatorPanelTabs from '../OperatorPanelTabs/OperatorPanelTabs';

import { RegistrationRoot } from './Registration.style';

function Registration() {
  const {
    countries,
    getCountries,
    personCategories,
    getPersonCategories,
    docTypes,
    getDocTypes,
    entryGates,
    getEntryGates,
    visitGoals,
    getVisitGoals,
    docVisaTypes,
    getDocVisaTypes,
    docVisaVisitTypes,
    getDocVisaVisitTypes,
    docVisaMultiTypes,
    getDocVisaMultiTypes,
  } = useStore('dictionariesStore');
  const [isFetching, setIsFetching] = useState(false);

  async function fetchDictionaries() {
    try {
      if (countries.length === 0) {
        await getCountries();
      }

      if (personCategories.length === 0) {
        await getPersonCategories();
      }

      if (docTypes.length === 0) {
        await getDocTypes();
      }

      if (entryGates.length === 0) {
        await getEntryGates();
      }

      if (visitGoals.length === 0) {
        await getVisitGoals();
      }

      if (docVisaTypes.length === 0) {
        await getDocVisaTypes();
      }

      if (docVisaVisitTypes.length === 0) {
        await getDocVisaVisitTypes();
      }

      if (docVisaMultiTypes.length === 0) {
        await getDocVisaMultiTypes();
      }
    } catch (e) {
      notificationAPI.error(e);
    }

    setIsFetching(false);
  }

  useEffect(() => {
    fetchDictionaries();
  }, []);

  return (
    <RouteConstructor
      breadcrumbs={<BreadcrumbsWrapper crumbs={['Регистрации']} />}
      content={
        <RegistrationRoot>
          <Groups mode="general" />

          <Persons mode="general" />
        </RegistrationRoot>
      }
      contentSettings={{
        overflow: 'hidden',
      }}
      header={<OperatorPanelTabs />}
      isLoading={isFetching}
    />
  );
}

export default memo(observer(Registration));
