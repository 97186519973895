import React, { Fragment, memo } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { useStore } from '@/stores/StoreProvider';
import { EMIS_BRAND_COLOR } from '@/ui-kit/global-styles';

import UserDevices from './UserDevices/UserDevices';

function PersonalData() {
  const { userData } = useStore('signInStore');

  if (!userData) {
    return null;
  }

  const { fname, lname, email } = userData;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: EMIS_BRAND_COLOR }}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Fragment>
                    <span>{fname} </span>

                    <span>{lname}</span>
                  </Fragment>
                }
                secondary={email}
              />
            </ListItem>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Устройство по умолчанию</Typography>
          </Grid>

          <Grid item xs={12}>
            <UserDevices />
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item xs={12}>*/}
      {/*  <Grid container spacing={2}>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      <Typography variant="h6">Автосохранение</Typography>*/}
      {/*    </Grid>*/}

      {/*    <Grid item xs={12}>*/}
      {/*      <FormControl variant="standard">*/}
      {/*        <FormControlLabel*/}
      {/*          control={<Switch name="autosave" />}*/}
      {/*          label="Использовать автосохранение карточки гостя"*/}
      {/*        />*/}

      {/*        <FormHelperText>*/}
      {/*          <Tooltip*/}
      {/*            title={*/}
      {/*              <span>*/}
      {/*                Автосохранение работает, только когда карточка гостя <strong>создана</strong>*/}
      {/*              </span>*/}
      {/*            }*/}
      {/*          >*/}
      {/*            <AutosaveDescription>*/}
      {/*              <HelpIcon color="primary" fontSize="small" />*/}

      {/*              <span>&nbsp;Как это работает</span>*/}
      {/*            </AutosaveDescription>*/}
      {/*          </Tooltip>*/}
      {/*        </FormHelperText>*/}
      {/*      </FormControl>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/* </Grid>*/}
    </Grid>
  );
}

export default memo(PersonalData);
